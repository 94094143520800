import React from 'react';
import { RouteProp, useIsFocused } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack/src/types';

import { StyleSheet, View } from 'react-native';
import Lobby from '../components/lobby/Lobby';
import { HomeStackParamList, ScheduleStackParamList } from '../routes';

export type LobbyPageProps = {
  navigation: StackNavigationProp<HomeStackParamList | ScheduleStackParamList, 'Lobby'>;
  route: RouteProp<HomeStackParamList | ScheduleStackParamList, 'Lobby'>;
};

const LobbyPage = (props: LobbyPageProps) => {
  const isFocused = useIsFocused();

  return (
    <View style={styles.root}>
      <Lobby navigation={props.navigation} route={props.route} isFocused={isFocused} />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#efeeed'
  }
});

export default LobbyPage;
