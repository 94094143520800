import { Question } from 'nimbly-common';
import { Question as QuestionClass } from '../../../utils/classes';
/**
 * @function validateConditionalQuestion Validate conditional question
 *
 * @param q (Question) Question object
 * @param answerKey (string | number) question answer
 * @returns (boolean)
 */

const validateConditionalQuestion = (
  q: Question | QuestionClass,
  answerKey?: string | number,
  conditionalIndex?: string | number,
): boolean => {
  if (!q.isConditional) {
    return false;
  }

  if (!q.conditionalQuestion) {
    return false;
  }

  if (!q.conditionalQuestion.conditions) {
    return false;
  }

  // validate child question
  if (answerKey && typeof conditionalIndex === 'number' && conditionalIndex > -1) {
    return !!q.conditionalQuestion.conditions[answerKey][conditionalIndex];
  }

  // validate conditions
  if (answerKey) {
    return !!q.conditionalQuestion.conditions[answerKey];
  }

  return true;
};

const getConditions = (q: Question | QuestionClass) => {
  return q.conditionalQuestion?.conditions || {};
};

const getConditionalQuestion = (q: Question | QuestionClass, answerKey: string | number) => {
  return q.conditionalQuestion?.conditions[answerKey] || [];
};

const getConditionalQuestionChild = (
  q: Question | QuestionClass,
  answerKey: string | number,
  conditionalIndex: number,
) => {
  return q.conditionalQuestion?.conditions[answerKey][conditionalIndex] || {};
};

export { validateConditionalQuestion, getConditions, getConditionalQuestion, getConditionalQuestionChild };
