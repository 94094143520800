import React from 'react';
import { Modal, View, Text, StyleSheet, TouchableOpacity } from 'react-native';

import { AppButton } from '../global/Button';

import { SCREEN_HEIGHT } from '../../utils/screensize';

type TutorialModalContainerProps = {
  handleDismissTutorialQuestionModal: () => void;
  handleDismissTutorialMode: () => void;
  isShowQuestionTutorialModal: boolean;
  tutorialQuestionType: string;
  language: 'en' | 'id';
};

const questionType = {
  'binary-with-red-flag': {
    labelEn: 'Traffic light Question Type',
    labelId: 'Tipe Pertanyaan Traffic Light'
  },
  binary: {
    labelEn: 'Yes/No Question Type',
    labelId: 'Tipe Pertanyaan Ya/Tidak'
  },
  open: {
    labelEn: 'Open Question Type',
    labelId: 'Tipe Pertanyaan Terbuka'
  },
  'inventory-v2': {
    labelEn: 'Inventory',
    labelId: 'Inventaris'
  },
  'multiple-choice': {
    labelEn: 'Multiple Choice Question Type',
    labelId: 'Tipe Pertanyaan Pilihan Berganda'
  },
  checklist: {
    labelEn: 'Checklist Question Type',
    labelId: 'Tipe Pertanyaan Daftar Periksa'
  },
  // eslint-disable-next-line id-blacklist
  number: {
    labelEn: 'Number Question Type',
    labelId: 'Tipe Pertanyaan Number'
  },
  score: {
    labelEn: 'Score Question Type',
    labelId: 'Tipe Pertanyaan Skala'
  },
  'range-flag': {
    labelEn: 'Range Flag Question Type',
    labelId: 'Tipe Pertanyaan Range Flag'
  }
};

const _renderTextModal = (tutorialQuestionType: string, language: string) => {
  switch (tutorialQuestionType) {
    case 'binary': {
      return (
        <View testID={'modal-tutorial-binary'}>
          <Text style={styles.text}>
            {language === 'id' ? 'Klik salah satu untuk memilih jawaban' : 'Click on one to choose the answer'}
          </Text>
        </View>
      );
    }
    case 'binary-with-red-flag': {
      return (
        <View>
          <Text style={styles.text}>
            <Text style={[styles.textColor, { color: '#0ABA26' }]}>
              {language === 'id' ? 'Bendera hijau' : 'Green Flag'}
            </Text>{' '}
            - {language === 'id' ? 'tidak ada masalah' : 'no problem'};
          </Text>
          <Text style={styles.text}>
            <Text style={[styles.textColor, { color: '#FFC81F' }]}>
              {language === 'id' ? 'Bendera kuning' : 'Yellow Flag'}
            </Text>{' '}
            - {language === 'id' ? 'perlu perhatian' : 'need attention'}
          </Text>
          <Text style={styles.text}>
            <Text style={[styles.textColor, { color: '#F6001A' }]}>
              {language === 'id' ? 'Bendera merah' : 'Red Flag'}
            </Text>{' '}
            - {language === 'id' ? 'masalah kritis' : 'critical problem'}
          </Text>
          <Text style={[styles.text, { marginVertical: 10 }]}>
            {language === 'id'
              ? 'Bendera kuning dan merah akan dimasukkan ke Issue Tracker secara otomatis, dan wajib menulis komentar'
              : 'Yellow and Red flags will be entered into the Issue Tracker automatically - you will also need to write an explanation in the comments'}
          </Text>
        </View>
      );
    }
    case 'number': {
      return (
        <View>
          <Text style={styles.text}>
            {language === 'id'
              ? 'Jawaban hanya dapat berupa angka'
              : 'For this question, only numeric answers are allowed'}
          </Text>
        </View>
      );
    }
    case 'multiple-choice': {
      return (
        <View>
          <Text style={styles.text}>
            {language === 'id'
              ? 'Klik salah satu untuk memilih jawaban'
              : 'For this question, click on one choice to answer'}
          </Text>
        </View>
      );
    }
    case 'checklist': {
      return (
        <View>
          <Text style={styles.text}>
            {language === 'id'
              ? 'Untuk jenis pertanyaan ini, pilih satu atau beberapa jawaban untuk menjawab.'
              : 'For this type of question, click on one or multiple choice to answer.'}
          </Text>
        </View>
      );
    }
    case 'open': {
      return (
        <View>
          <Text style={styles.text}>
            {language === 'id'
              ? 'Jawab dengan sesuai rentang yang ditentukan'
              : 'For this question, answer with a short paragraph or explanation'}
          </Text>
        </View>
      );
    }
    case 'inventory-v2': {
      return (
        <View>
          <Text style={styles.text}>
            {language === 'id'
              ? 'Jawab dengan angka yang menunjukan jumlah inventaris'
              : 'For this question, with numbers that indicate the amount of inventory'}{' '}
          </Text>
          <Text style={[styles.text, { marginTop: 20 }]}>
            {language === 'id'
              ? 'Terintegrasi dengan fitur manajemen invetaris Nimbly'
              : 'Integrated with Nimbly inventory management features'}
          </Text>
        </View>
      );
    }
    case 'range-flag': {
      return (
        <View>
          <Text style={styles.text}>
            {language === 'id'
              ? 'Jawab dengan menggunakan angka, dan akan diberikan keterangan nilai tersebut pada bendera hijau atau merah'
              : 'For this question, fill it as if you would the number type question - green or red flag will be detected automatically'}
          </Text>
        </View>
      );
    }
    case 'score': {
      return (
        <View>
          <Text style={styles.text}>
            {language === 'id'
              ? 'Jawab sesuai dengan rentang yang ditentukan'
              : 'For this question, select a score according to the specified range'}
          </Text>
        </View>
      );
    }
    default: {
      return null;
    }
  }
};

const QuestionnaireQuestionTutorialModalContainer = (props: TutorialModalContainerProps) => {
  const { language, handleDismissTutorialQuestionModal, isShowQuestionTutorialModal, tutorialQuestionType } = props;

  return (
    <Modal
      transparent={true}
      onRequestClose={() => handleDismissTutorialQuestionModal()}
      visible={isShowQuestionTutorialModal}
      animated={false}
    >
      <View style={styles.root}>
        <View style={styles.container}>
          {questionType[tutorialQuestionType] ? (
            <Text style={styles.title}>
              {language === 'en'
                ? questionType[tutorialQuestionType].labelEn
                : questionType[tutorialQuestionType].labelId}
            </Text>
          ) : null}
          {_renderTextModal(tutorialQuestionType, language)}
          <View style={styles.footerButton}>
            <AppButton
              title="OK"
              onPress={() => props.handleDismissTutorialQuestionModal()}
              testID={'dismiss-tutorial-modal'}
            />
            <TouchableOpacity
              onPress={() => {
                props.handleDismissTutorialMode();
              }}
              testID={'dismiss-mode-tutorial'}
            >
              <View style={styles.endTutorialContainer}>
                <Text style={styles.endTutorialTitle}> {language === 'id' ? 'Akhiri tutorial' : 'End Tutorial'}</Text>
                <Text style={styles.endTutorialSubtitle}>
                  {language === 'id' ? 'Akhiri tutorial dan kembali ke halaman utama' : 'End tutorial and back to home'}
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  text: { color: '#535353' },
  textColor: {
    fontWeight: 'bold'
  },
  footerButton: {
    marginTop: 40
  },
  title: {
    fontWeight: 'bold',
    marginBottom: 20,
    fontSize: 18,
    color: '#535353'
  },
  root: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    height: SCREEN_HEIGHT,
    width: '100%'
  },
  container: {
    backgroundColor: '#ffffff',
    marginTop: 0.3 * SCREEN_HEIGHT,
    width: '95%',
    marginHorizontal: '2.5%',
    padding: 32,
    borderRadius: 2,
    overflow: 'hidden'
  },
  endTutorialContainer: {
    borderWidth: 1,
    borderRadius: 5,
    borderColor: '#C4C4C4',

    overflow: 'hidden',

    paddingVertical: 5,

    justifyContent: 'center',
    alignItems: 'center',

    marginTop: 10
  },
  endTutorialTitle: {
    fontSize: 16,
    lineHeight: 24,
    color: '#C4C4C4'
  },
  endTutorialSubtitle: {
    fontSize: 12,
    color: '#C4C4C4'
  }
});

export default QuestionnaireQuestionTutorialModalContainer;
