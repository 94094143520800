import React from 'react';

import { Platform, StyleSheet, View, ScrollView, Linking, TextStyle } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Text from '../components/global/Text';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const licenses = require('../assets/licenses');

type License = {
  title: string;
  url: string;
  license?: string;
  licenseUrl?: string;
};

const LicensesPage = React.memo(() => {
  const handleUrlPress = (url: string) => {
    Linking.openURL(url);
  };

  const handleLicensePress = (licenseURL: string) => {
    Linking.openURL(licenseURL);
  };

  const _renderLicenses = () => {
    const allLicenses: License[] = licenses.licenses;
    return allLicenses
      .sort((a, b) => (a.title < b.title ? -1 : 1))
      .map(({ title, url, license, licenseUrl }, i) => {
        return (
          <View key={`title-${i}`} style={{ marginBottom: 10 }}>
            <View style={styles.licenseContainer}>
              <View style={styles.licenseTitleContainer}>
                <Text style={styles.sourceTitle}>{title}</Text>
                <Icon name="open-in-new" color="#3366BB" style={styles.icon} onPress={() => handleUrlPress(url)} />
              </View>
              {license && licenseUrl && (
                <Text style={styles.sourceLicense} onPress={() => handleLicensePress(licenseUrl)}>
                  {license}
                </Text>
              )}
            </View>
            <View style={styles.rowSeparator} />
          </View>
        );
      });
  };

  return (
    <ScrollView style={styles.root}>
      <View style={styles.content}>
        <Text>
          This screen displays the third party tools that have contributed in making the Nimbly experience. A big thanks
          from our team to the developers, contributors and the people behind working on these fantastic programs.
        </Text>
      </View>
      {_renderLicenses()}
    </ScrollView>
  );
});

export default LicensesPage;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#fff'
  },
  title: Platform.select({
    ios: {
      fontSize: 17,
      lineHeight: 20,
      marginHorizontal: 16
    },
    android: {
      fontSize: 20,
      lineHeight: 22,
      marginHorizontal: 16
    }
  }) as TextStyle,
  content: {
    marginHorizontal: 16,
    marginVertical: 15
  },
  sourceTitle: {
    fontSize: 14,
    marginLeft: 16,
    color: 'black'
  },
  sourceLicense: {
    fontSize: 13,
    marginLeft: 16,
    opacity: 0.5,
    color: 'black',
    textDecorationLine: 'underline'
  },
  licenseTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 2
  },
  licenseContainer: {
    marginVertical: 5,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  rowSeparator: {
    height: 1,
    borderRadius: 10,
    backgroundColor: '#e0e0e0',
    marginHorizontal: 18
  },
  icon: {
    marginLeft: 3
  }
});
