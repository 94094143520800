const defaultMaxTimeout = 10000;

/**
 * When executing an async process, a timeout will be put.
 * If the process is executed before timeout, execute then()
 * If the timeout expires first before the process, execute catch()
 *
 * Even though the timeout is fired, the process will be executed
 * regardless.
 *
 * @param maxTimeout Maximum timeout (in mili seconds), default is
 * 6.000 (6 seconds)
 */
function handleConnectionTimeout(promiseFunction: any, maxTimeout?: number | undefined) {
  if (maxTimeout === undefined) {
    maxTimeout = defaultMaxTimeout;
  }

  const timeout = new Promise((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject({ connected: false });
    }, maxTimeout);
  });

  return Promise.race([promiseFunction, timeout]);
}

export { handleConnectionTimeout };
