import firebase from 'firebase';

export default class API {
  private headers: { [key: string]: string } | null = null;

  constructor(headers?: { [key: string]: string }) {
    this.headers = headers || null;

    this.getJSON = this.getJSON.bind(this);
    this.setHeaders = this.setHeaders.bind(this);
  }

  static async getToken() {
    const user = firebase.auth().currentUser;
    if (!user) {
      throw new Error('unauthorized');
    }

    const token = await user.getIdToken();

    return token;
  }

  static makeQueryString(obj: { [k: string]: string }) {
    let qs = '';
    for (const k of Object.keys(obj)) {
      const v = obj[k];
      qs = `${k}=${v}&${qs}`;
    }

    return qs;
  }

  setHeaders(headers: { [key: string]: string }) {
    this.headers = Object.assign({}, this.headers, headers);
  }

  async getJSON(url: string) {
    const res = await fetch(url, {
      headers: this.headers || undefined
    });

    const contentType = res.headers.get('Content-Type');

    if (res.status >= 400) {
      // TODO: Throw
      return null;
    }

    if (!contentType?.includes('application/json')) {
      return null;
    }

    const json = await res.json();

    return json;
  }
}
