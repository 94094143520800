import React, { useState } from 'react';
import { Image, Modal, StyleSheet, View } from 'react-native';

import Button from '../global/Button';
import Text from '../global/Text';
import fontMaker from '../../utils/font';

import { SCREEN_WIDTH, heightPercentageToDP as hp, widthPercentageToDP as wp } from '../../utils/screensize';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const HeaderImage = require('../../assets/img/welcome-offday-header.png');

const WelcomeOffDayModal = (props: any) => {
  const { t } = useTranslation(['home,common']);
  const { visible } = props;
  const [isVisible, setIsVisible] = useState(visible || false);

  const handleCloseModal = () => setIsVisible(false);

  return (
    <Modal animationType="slide" transparent={true} visible={isVisible} onRequestClose={handleCloseModal}>
      <View style={styles.overlay}>
        <View style={styles.container}>
          <View style={styles.header}>
            <Image source={HeaderImage} style={styles.image}></Image>
          </View>
          <View style={styles.textContainer}>
            <Text style={styles.title}>{t('home:offDay.title')}</Text>
            <Text style={styles.subtitle}>{t('home:offDay.description')}</Text>
            <Button style={styles.button} color="#5049B2" onPress={handleCloseModal} title="OK" />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default WelcomeOffDayModal;

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH * 0.8,
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    paddingBottom: 15,
    borderRadius: 16,
    // Shadow
    shadowColor: '#000',
    elevation: 5,
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.15,
    shadowRadius: 15
  },
  header: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'visible',
    width: wp(50),
    maxHeight: wp(40),
    top: hp(-4)
  },
  image: {
    width: '100%',
    height: '100%'
  },
  textContainer: {
    paddingHorizontal: 18,
    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 16,
    textAlign: 'center'
  },
  subtitle: {
    textAlign: 'center',
    paddingVertical: 20,
    fontSize: 12
  },
  button: {
    paddingVertical: -2,
    paddingHorizontal: 25,
    borderRadius: 25
  }
});
