import React from 'react';
import { StyleSheet, View, TextStyle, Platform, TextInput, Dimensions } from 'react-native';
import Text from '../global/Text';
import { QuestionnaireQuestionProps } from './QuestionnaireQuestion';
import fontMaker from '../../utils/font';
import { questionNumberInvalid, questionTextInvalid } from '../../utils/report';
import theme from '../../constants/theme';
import { useTranslation } from 'react-i18next';
export type QuestionnaireQuestionInventoryPropsExtra = {
  styles: any;
  inventoryOutNumber: number;
  startInventory: number;
};

const screen = Dimensions.get('screen');
const { width } = screen;

export type QuestionnaireQuestionInventoryProps = Pick<
  QuestionnaireQuestionProps,
  | 'question'
  | 'index'
  | 'language'
  | 'inventoryIn'
  | 'inventoryFinal'
  | 'numberInputRef'
  | 'commentInputRef'
  | 'handleInputInventory'
  | 'handleInputComment'
  | 'handleTextInputFocused'
  | 'handleTextInputBlurred'
  | 'handleNumberInputFocused'
  | 'handleNumberInputBlurred'
>;

const QuestionnaireQuestionInventory = (
  props: QuestionnaireQuestionInventoryProps & QuestionnaireQuestionInventoryPropsExtra
) => {
  const { t } = useTranslation(['questionnaire', 'common']);
  return (
    <View>
      <View style={styles.inventoryRowOld}>
        <Text>{t('questionnaire:question.inventory.previous')}</Text>
        <Text style={styles.inventoryCurrentOld}>{props.startInventory}</Text>
      </View>
      <View style={styles.inventoryRowOld}>
        <Text>{t('questionnaire:question.inventory.stockIn')}</Text>
        <View
          style={[
            styles.inventoryInputContainerOld,
            {
              borderColor: questionNumberInvalid(
                props.question,
                t,
                'in',
                props.inventoryIn[props.index],
                props.inventoryOutNumber
              )
                ? theme.colors.secondary
                : 'rgba(0, 0, 0, 0.05)'
            }
          ]}
        >
          <TextInput
            style={styles.inventoryInputOld}
            maxLength={20}
            editable={props.question.answer !== 'not-applicable'}
            onChangeText={text => props.handleInputInventory(props.index, text, 'in', props.question)}
            value={props.inventoryIn[props.index]}
            keyboardType="numeric"
            ref={props.numberInputRef}
            // onFocus={() => props.handleNumberInputFocused(props.index)}
            // onBlur={() => props.handleNumberInputBlurred(props.index)}
            testID="inventory-in"
          />
        </View>
      </View>
      <View style={styles.inventoryRowOld}>
        <Text>{t('questionnaire:question.inventory.salesOut')}</Text>
        <Text style={props.inventoryOutNumber >= 0 ? styles.inventoryOutOld : styles.inventoryOutInvalidOld}>
          {props.inventoryOutNumber}
        </Text>
      </View>
      <View style={[styles.inventoryTotalLine, theme.styles.backgroundDark]} />
      <View style={[styles.inventoryRowOld, styles.inventoryRowFinalOld]}>
        <Text>{t('questionnaire:question.inventory.onHand')}</Text>
        <View
          style={[
            styles.inventoryInputContainerOld,
            {
              borderColor: questionNumberInvalid(
                props.question,
                t,
                'final',
                props.inventoryFinal[props.index],
                props.inventoryOutNumber
              )
                ? theme.colors.secondary
                : 'rgba(0, 0, 0, 0.05)'
            }
          ]}
        >
          <TextInput
            style={styles.inventoryInputOld}
            maxLength={20}
            editable={props.question.answer !== 'not-applicable'}
            onChangeText={text => props.handleInputInventory(props.index, text, 'final', props.question)}
            value={props.inventoryFinal[props.index]}
            keyboardType="numeric"
            // onFocus={() => props.handleNumberInputFocused(props.index)}
            // onBlur={() => props.handleNumberInputBlurred(props.index)}
            testID="inventory-final"
          />
        </View>
      </View>
      <View style={[props.styles.validationContainer, props.styles.inventoryValidationContainer]}>
        <Text style={props.styles.textValidation}>
          {questionNumberInvalid(
            props.question,
            t,
            'final',
            props.inventoryFinal[props.index],
            props.inventoryOutNumber
          ) || ''}
        </Text>
      </View>
      <View
        style={[
          props.styles.openAnswerInputContainer,
          {
            borderColor: questionTextInvalid(props.question, t) ? theme.colors.secondary : 'rgba(0, 0, 0, 0.05)'
          }
        ]}
      >
        <TextInput
          style={props.styles.openCommentInput}
          maxLength={2500}
          multiline={true}
          numberOfLines={4}
          onChangeText={text => props.handleInputComment(props.index, text)}
          value={props.question.comment}
          placeholder={`${t('questionnaire:question.score.comments')}${
            props.question.answer === 'green-flag' ? t('questionnaire:question.score.optional') : ''
          }`}
          ref={props.commentInputRef}
          // onFocus={() => props.handleTextInputFocused(props.index)}
          // onBlur={() => props.handleTextInputBlurred(props.index)}
          testID={'inventory-comment'}
        />
      </View>
      {!questionTextInvalid(props.question, t) ? (
        <View style={props.styles.validationContainer}>
          <Text style={props.styles.textValidation}>&zwnj;</Text>
        </View>
      ) : (
        <View style={props.styles.validationContainer}>
          <Text style={props.styles.textValidation}> {questionTextInvalid(props.question, t)} </Text>
        </View>
      )}
    </View>
  );
};

export default QuestionnaireQuestionInventory;

const styles = StyleSheet.create({
  inventoryCurrentOld: {
    paddingRight: 11,
    paddingVertical: Platform.OS === 'android' ? 5 : 10
  },
  inventoryRowOld: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 7.5,
    paddingHorizontal: 20
  },
  inventoryRowFinalOld: {
    marginBottom: 2.5
  },
  inventoryOutOld: {
    paddingRight: 11,
    paddingVertical: 11
  },
  inventoryOutInvalidOld: {
    paddingRight: 11,
    paddingVertical: 11,
    color: '#f93c1c'
  },
  inventoryInputContainerOld: {
    justifyContent: 'flex-start',
    paddingVertical: Platform.OS === 'android' ? 0 : 10,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 6
  },
  inventoryInputOld: {
    width: 150,
    ...fontMaker({ weight: 'Regular' }),
    color: '#535353',
    textAlign: 'right',
    textAlignVertical: 'top',
    paddingTop: 0,
    paddingHorizontal: 10
  },
  inventoryTotalLine: {
    width: width - 32,
    height: 2,
    marginTop: -3,
    marginBottom: 5,
    marginHorizontal: 16
  }
});
