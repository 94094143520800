import AsyncStorage from '@react-native-community/async-storage';
import { useNetInfo } from '@react-native-community/netinfo';
import { NavigationContainer, NavigationState } from '@react-navigation/native';
import firebase from 'firebase';
import 'firebase/auth';
import debounce from 'lodash/debounce';
import { Activity, enums } from 'nimbly-common';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Alert, StyleSheet, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { FirebaseReducer, useFirebase, useFirebaseConnect } from 'react-redux-firebase';
import FeatureAccessModal from './components/featureAccess/FeatureAccessModal';
import ConfirmModal, { ModalConfig } from './components/global/ConfirmModal';
import FreezeAccountModal from './components/global/FreezeAccountModal';
import AppStatusBar from './components/global/StatusBar';
import Toast from './components/global/Toast';
import * as nimblyApi from './constants/api';
import { appversion } from './constants/appversion';
import theme from './constants/theme';
import getDeviceProfile from './helpers/getDeviceProfile';
import usePrevious from './hooks/usePrevious';
import i18n from './i18n';
import { navigationRef } from './RootNavigation';
import { AppTabsNavigator, AuthStackNavigator } from './routes';
import { RootState } from './store/reducers';
import { accountActions } from './store/reducers/account/account';
import { fetchFeatureAccessAsync } from './store/reducers/featureAccess/featureAccess.action';
import { networkActions } from './store/reducers/network';
import { fetchMyOrganizationAsync } from './store/reducers/organization/organization';
import { profileThemeActions } from './store/reducers/profiletheme';
import { toastActions } from './store/reducers/toast';
import { persistor } from './store/store';
import { User } from './utils/classes';

const Loading = () => {
  return (
    <View style={styles.loading}>
      <ActivityIndicator size="large" color={theme.colors.primary} />
    </View>
  );
};

export const App = () => {
  const [freezeAccountModalVisible, setFreezeModalVisible] = useState<boolean>(false);
  const auth = useSelector<RootState, FirebaseReducer.AuthState>(state => state.firebase.auth);
  const profile = useSelector<RootState, FirebaseReducer.Profile<User>>(state => state.firebase.profile as any);
  const profileProcessed = useSelector<RootState, boolean>(state => state.account.profileProcessed);
  const landingLanguage = useSelector<RootState, 'en' | 'id'>(state => state.landing.language);
  const uuid = useSelector<RootState, string>(state => state.account.uuid);
  const organization = useSelector<RootState, any>(state => state.organization.myOrganization);
  const profileColor = useSelector<RootState, string>(state => state.profiletheme.color);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const showToast = useSelector<RootState, boolean>(state => state.toast.show);
  useFirebaseConnect([{ path: '/version' }]);
  const firebaseRedux = useFirebase();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(['profile']);
  const [_showModal, setShowModal] = useState<boolean>(false);
  const [_modalConfig, setModalConfig] = useState<ModalConfig>({
    title: '',
    subtitle: '',
    options: [{ text: '', action: () => null, style: 'cancel' }]
  });

  useEffect(() => {
    dispatch(fetchFeatureAccessAsync.request());
  }, [profile]);

  const handleLogout = async () => {
    try {
      setFreezeModalVisible(false);
      setIsLoading(true);
      await firebaseRedux.updateProfile({ uuid: '' });
      await firebaseRedux.set(`/userPushToken/${auth.uid}`, '');
      await firebaseRedux.logout();
      await persistor.purge();
      await AsyncStorage.clear();
      location.reload();

      setIsLoading(false);
    } catch (error) {
      // await CodePush.restartApp();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    i18n.changeLanguage(profile?.language || 'en');
  }, [profile?.language]);

  // Initialize analytics and Device effect
  useEffect(() => {
    dispatch(accountActions.resetProfileState());

    const setDevice = async () => {
      const deviceProfile = await getDeviceProfile();

      dispatch(accountActions.setDeviceUniqueId(deviceProfile.uuid));
      dispatch(accountActions.setDeviceHasNotch(deviceProfile.hasNotch));
      dispatch(accountActions.setDeviceApiLevel(deviceProfile.apiLevel));
      dispatch(accountActions.setDeviceProfile(deviceProfile));
    };

    setDevice();
  }, []);

  useEffect(() => {
    if (profile && profile.organization && !organization) {
      dispatch(fetchMyOrganizationAsync.request(undefined));
    }
  }, [profile, organization]);

  useEffect(() => {
    if (organization && organization.clientStatus === 'frozen') {
      setFreezeModalVisible(true);
    }
  }, [organization]);

  const netInfo = useNetInfo();
  const warnPoorConnection = debounce(() => {
    const message = t('profile:toast.message');
    dispatch(toastActions.createToast(message, true));
  }, 200);

  // Connection side effect
  useEffect(() => {
    const setConnection = () => {
      dispatch(networkActions.setConnectionStatus(netInfo.isConnected));
    };

    const goodConnection: { [cell: string]: boolean } = {
      '5g': true,
      '4g': true,
      '3g': true
    };

    if (netInfo.type !== 'unknown') {
      setConnection();
    }
    if (
      profile &&
      netInfo.type === 'cellular' &&
      netInfo.details &&
      netInfo.details.cellularGeneration &&
      !goodConnection[netInfo.details.cellularGeneration]
    ) {
      warnPoorConnection();
    }
  }, [netInfo, profile, warnPoorConnection]);

  // User side effect
  const prevProfile = usePrevious(profile);
  const prevUuid = usePrevious(uuid);

  useEffect(() => {
    const user = firebase.auth().currentUser;

    // set color based on organization
    if (profile.organization === 'kfc') {
      dispatch(profileThemeActions.setProfileColor('#a6192e'));
    }

    // if user is not registered in any organization log this user out
    const checkUserOrganization = () => {
      if (profile.organization) {
        return;
      }
      Alert.alert(
        t('profile:auth.recognizedAlert.title'),
        t('profile:auth.recognizedAlert.message'),
        [
          {
            text: t('profile:auth.recognizedAlert.buttonOK'),
            onPress: async () => {
              setIsLoading(true);
              logUserActivity('no-organization');
              await firebaseRedux.logout();
              await AsyncStorage.clear();
              setIsLoading(false);
              // CodePush.restartApp();
            }
          }
        ],
        { cancelable: false }
      );
    };

    if (user && profile.isLoaded && !profile.isEmpty) {
      checkUserOrganization();
    }
  }, [profile, uuid, prevProfile, profileProcessed, prevUuid]);

  const checkUserBlocked = () => {
    if (profile.status !== 'disabled') {
      return;
    }
    Alert.alert(
      t('profile:auth.blockedAlert.title'),
      t('profile:auth.blockedAlert.message'),
      [
        {
          text: t('profile:auth.blockedAlert.buttonOK'),
          onPress: async () => {
            setIsLoading(true);
            logUserActivity('user-blocked');
            await firebaseRedux.logout();
            await AsyncStorage.clear();
            setIsLoading(false);
            // CodePush.restartApp();
          },
          style: 'destructive'
        }
      ],
      { cancelable: false }
    );
  };

  const logUserActivity = async (forceLogout: 'concurrent' | 'no-organization' | 'user-blocked') => {
    try {
      let message = `User Logout: ${profile.displayName}`;
      switch (forceLogout) {
        case 'concurrent':
          message = `${message} because of Concurrent Login profile.uuid: ${profile.uuid} and account.uuid: ${uuid}`;
          break;
        case 'no-organization':
          message = `${message} because user doesn't has organization`;
          break;
        case 'user-blocked':
          message = `${message} because user has been blocked`;
        default:
          message = `${message}`;
      }

      const activity = new Activity({
        entity: enums.Entity.USER,
        entityID: profile.uuid,
        permission: enums.Permission.View,
        type: enums.ActivityType.LOGOUT_USER,
        message,
        timestamp: new Date(),
        device: {
          appversion: appversion,
          ...(await getDeviceProfile())
        },
        snapshot: {
          userData: { email: profile.email, userID: profile.uuid, displayName: profile.displayName },
          lastSignInTime: new Date((auth as any).metadata.lastSignInTime),
          platform: 'app'
        }
      });

      const idToken = await firebase.auth().currentUser!.getIdToken();

      fetch(`${nimblyApi.NIMBLY_API_CREATE_ACTIVITY}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: idToken
        },
        body: JSON.stringify(activity)
      });
    } catch (e) {
      // handle error while save user activity
    }
  };

  const clearLogoutData = () => {
    setIsLoading(true);
    firebase
      .auth()
      .signOut()
      .then(() => {
        setIsAuthenticated(false);
        dispatch(accountActions.isForceLogoutStatus(true));
        dispatch(accountActions.resetProfileState());
        AsyncStorage.clear();
        setIsLoading(false);
      });
  };

  const checkConcurrentLogin = () => {
    if (isLoading) {
      return;
    }
    // ignore first login
    if (!prevProfile || prevProfile?.isEmpty || !prevUuid) {
      return;
    }
    if (!profile?.uuid) {
      return;
    }

    if (!navigationRef?.current?.dangerouslyGetState()?.routes) {
      return;
    }

    if (profile?.organization === 'nimbly' || profile?.organization === 'sustainnovation') {
      return;
    }

    /**
     * @Description using dangerouslyGetState for getting inner state change from beginning
     * if using getRootState we will get all the init routes on routes.tsx even not yet login
     */
    if (profile?.uuid !== uuid) {
      // log activity after account detect concurrent

      Alert.alert(
        t('profile:auth.alert.title'),
        t('profile:auth.alert.message'),
        [
          {
            text: t('profile:auth.alert.buttonOK'),
            onPress: () => clearLogoutData(),
            style: 'destructive'
          }
        ],
        { cancelable: false }
      );
    }
  };

  const setFirebaseAuthListener = () => {
    // handle auth if the app offline
    if (profileProcessed) {
      setIsAuthenticated(true);
      return;
    }

    const subscriber = firebase.auth().onAuthStateChanged(authState => {
      if (!profileProcessed) {
        setIsAuthenticated(false);
        return;
      }

      if (!authState && !profileProcessed) {
        setIsAuthenticated(false);
        return;
      }
      const getCurrentRoutes = navigationRef!.current!.getCurrentRoute()?.name;
      if (getCurrentRoutes !== 'Landing') {
        checkUserBlocked();
        checkConcurrentLogin();
      }
      setIsAuthenticated(true);
    });
    return subscriber; // unsubscribe on unmount
  };
  useEffect(setFirebaseAuthListener, [profileProcessed, profile.uuid]);

  // Gets the current screen from navigation state
  const getActiveRouteName: any = (state: any) => {
    const route = state.routes[state.index];
    if (route.state) {
      // Dive into nested navigators
      return getActiveRouteName(route.state);
    }
    return route.name;
  };

  const routeNameRef = React.useRef();

  const handleNavigationStateChanged = (state: NavigationState | undefined) => {
    const previousRouteName = routeNameRef.current;
    const currentRouteName = getActiveRouteName(state);
    if (previousRouteName !== currentRouteName) {
      // firebase.analytics().logScreenView({ screen_name: currentRouteName });
    }
    routeNameRef.current = currentRouteName;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <View style={styles.root}>
      <ConfirmModal
        isVisible={_showModal}
        language={landingLanguage}
        title={_modalConfig.title}
        subtitle={_modalConfig.subtitle}
        options={_modalConfig.options}
      />
      {showToast ? <Toast /> : null}
      <AppStatusBar backgroundColor={profileColor} barStyle="light-content" />
      <FeatureAccessModal />
      <FreezeAccountModal handleLogout={handleLogout} freezeAccountModalVisible={freezeAccountModalVisible} />
      <NavigationContainer ref={navigationRef} onStateChange={handleNavigationStateChanged}>
        {isAuthenticated ? <AppTabsNavigator /> : <AuthStackNavigator />}
      </NavigationContainer>
    </View>
  );
};

const NimblyApp = App;

// const STORYBOOK_START = nimblyApi.STORYBOOK === 'true';
export default NimblyApp;

const styles = StyleSheet.create({
  root: {
    backgroundColor: '#fff',
    height: '100vh'
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center'
  }
});
