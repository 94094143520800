import { call, put, takeLatest, select } from 'redux-saga/effects';

import { apiProdURL } from '../../../constants/api';
import * as actions from './featureAccess.action';
import { RootState } from '../../../store/reducers';
import { getFirebase } from 'react-redux-firebase';
import { enums } from 'nimbly-common';

export function* fetchFeatureAccess() {
  try {
    const firebase = getFirebase();
    if (firebase.auth) {
      const authToken = (yield firebase.auth().currentUser!.getIdToken()) as string;
      const getState = (states: RootState) => states;
      const state: RootState = yield select(getState);

      const featState: any = state.featureAccess;
      const options = {
        method: 'GET',
        headers: {
          Authorization: authToken
        }
      };
      const fetchFeatureAccessURL = `${apiProdURL}/organizations/features/user-auth`;
      const request = () => fetch(fetchFeatureAccessURL, options);
      const response = yield call(request);
      if (response && response.status === 200) {
        const responseData = yield response.json();
        const resultFeatures = Object.keys(responseData.data.features) as enums.FeaturesType[];
        const resultPackage: enums.Package = responseData.data.package;
        resultFeatures.forEach((f: enums.FeaturesType) => {
          featState.features[f] = responseData.data.features[f];
        });
        yield put(actions.fetchFeatureAccessAsync.success({ data: featState.features, type: resultPackage }));
      }
      yield put(actions.fetchFeatureAccessAsync.failure({ error: 'Failed to Fetch Feature Access' }));
    }
  } catch (e) {
    yield put(actions.fetchFeatureAccessAsync.failure({ error: 'Failed to Fetch Feature Access' }));
  }
}
