import React from 'react';
import { Platform, StyleSheet, TouchableOpacity, StyleProp, ViewStyle, ButtonProps, TextStyle } from 'react-native';

import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Text from './Text';
import fontMaker from '../../utils/font';

export type AppButtonProps = {
  title: string;
  style?: StyleProp<ViewStyle>;
  icon?: string;
  backgroundColor?: string;
  fontSize?: number;
  color?: string;
  onPress?: (event?: any) => any;
  disabled?: boolean;
  accessibilityLabel?: string;
  testID?: string;
  underlayColor?: string;
  inverse?: boolean;
} & ButtonProps;

export const AppButton: React.SFC<AppButtonProps> = props => {
  const {
    accessibilityLabel,
    backgroundColor,
    fontSize,
    color,
    onPress,
    title,
    disabled,
    testID,
    style,
    icon,
    inverse
  } = props;
  const buttonStyles: any = [styles.button];
  const textStyles: any = [styles.text];

  if (style) {
    buttonStyles.push(style);
  }

  if (backgroundColor && !inverse) {
    buttonStyles.push({ backgroundColor });
  }

  if (fontSize) {
    textStyles.push({ fontSize });
    if (fontSize < 14) {
      textStyles.push({ paddingVertical: 4 });
    }
  }

  if (color) {
    textStyles.push({ color });
  }

  const accessibilityStates: any = [];
  if (disabled) {
    buttonStyles.push(styles.buttonDisabled);
    textStyles.push(styles.textDisabled);
    accessibilityStates.push('disabled');
  }

  if (inverse) {
    buttonStyles.push({
      backgroundColor: '#ffffff',
      borderWidth: 1,
      borderColor: backgroundColor
    });

    textStyles.push({ color: backgroundColor });
  }

  return (
    <TouchableOpacity
      accessibilityRole="button"
      accessibilityLabel={accessibilityLabel}
      accessibilityStates={accessibilityStates}
      testID={testID}
      disabled={disabled}
      onPress={onPress}
      style={buttonStyles}
    >
      {icon ? <Icon name={icon} size={16} color="white" /> : null}
      <Text allowFontScaling={false} style={textStyles}>
        {title}
      </Text>
    </TouchableOpacity>
  );
};

export default AppButton;

const styles = StyleSheet.create({
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 4,
    borderRadius: 30,
    backgroundColor: '#3cd070'
  },
  text: {
    ...fontMaker({ weight: 'SemiBold' }),
    textAlign: 'center',
    color: '#fff',
    padding: 8
  },
  buttonDisabled: {
    elevation: 0,
    backgroundColor: 'rgba(86, 199, 104, 0.3)',
    borderColor: 'rgba(86, 199, 104, 0.3)'
  },
  textDisabled: {
    color: '#fff'
  }
});
