import React, { useMemo } from 'react';
import moment from 'moment';
import firebase from 'firebase';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, FlatList, StyleSheet, Text, View } from 'react-native';
import { useSelector } from 'react-redux';
import { SCREEN_HEIGHT } from '../../../../utils/screensize';
// constant
import theme from '../../../../constants/theme';
import { RootState } from '../../../../store/reducers';
// components
import ScheduleQuestionnaireCard from '../../components/ScheduleQuestionnaireCard';

// types
import { SiteCalendarDetailsProps } from './type';
import { ScheduleOption } from '../../../../typing/types';

const SiteCalendarDetails = (props: SiteCalendarDetailsProps) => {
  const { t } = useTranslation(['site']);
  const { date, schedules, handleSelectSchedule, siteReports } = props;

  const handleSelectCalendarCard = (s: Partial<ScheduleOption>) => {
    firebase
      .analytics()
      .logEvent('click_questionnaire_card', { category: 'site_detail_page', questionnaire: s.title || 'unknown' });
    handleSelectSchedule(s);
  };

  const isBeforeCurrentDate = useMemo(() => {
    return moment().isAfter(date, 'day');
  }, [date]);

  const isAfterCurrentDate = useMemo(() => {
    return moment().isBefore(date, 'day');
  }, [date]);

  const selectedSiteKey = useSelector((state: RootState) => state.site.selectedSiteKey!);
  const isLoadingSchedule = useSelector((state: RootState) => state.siteschedule[selectedSiteKey ?? ''].isLoading);

  return (
    <View style={styles.container} testID="calendar-details">
      <View style={styles.dateContainer} testID={'calendar-details--title'}>
        <Text style={styles.date}>{moment(date).format('dddd, DD MMMM YYYY')}</Text>
      </View>

      <View style={styles.container}>
        {isLoadingSchedule ? (
          <ActivityIndicator style={styles.loading} size="large" color={theme.colors.primary} />
        ) : (
          <FlatList
            data={schedules || []}
            contentContainerStyle={styles.flatListContentContainer}
            renderItem={({ item, index }) => (
              <ScheduleQuestionnaireCard
                key={`calendar-detail-${index}`}
                overdueCalendar={isBeforeCurrentDate}
                isAfterCurrentDate={isAfterCurrentDate}
                schedule={item!}
                handleSelectSchedule={item => handleSelectCalendarCard(item)}
                selectedCalendarDate={date}
              />
            )}
            ListEmptyComponent={() => (
              <View style={styles.emptyContainer}>
                <Text style={styles.emptyText}>{t('calendar.emptySchedules')}</Text>
                <Text style={styles.emptySubtitle}>{t('calendar.emptySchedulesSubtitle')}</Text>
              </View>
            )}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  dateContainer: {
    paddingVertical: 15,
    paddingHorizontal: 10
  },
  date: {
    color: theme.styles.fontColor.headline,
    fontSize: 14,
    lineHeight: 18,
    fontWeight: '600'
  },
  flatListContentContainer: { paddingLeft: 18, paddingBottom: 50, flexGrow: 1 },
  emptyContainer: { justifyContent: 'center', alignItems: 'center', flex: 1 },
  emptyText: { color: theme.styles.fontColor.headline, fontSize: 20, lineHeight: 26, marginBottom: 5 },
  emptySubtitle: { color: theme.styles.fontColor.basic },
  loading: {
    paddingVertical: SCREEN_HEIGHT / 5,
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default SiteCalendarDetails;
