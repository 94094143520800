import React from 'react';

import {
  Platform,
  StyleSheet,
  View,
  TextInput,
  RegisteredStyle,
  TextInputProps,
  TextStyle,
  ViewStyle,
  TouchableOpacity,
  TouchableNativeFeedback,
  TouchableWithoutFeedbackProps
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import fontMaker from '../../utils/font';

type AuthFieldProps = {
  /** If true, the form field is marked as valid (with accompanying styling) */
  isValid: boolean;
  /** If true, the password will be shown */
  isPasswordVisible?: boolean;
  /** The icon on the left side for styling */
  icon: string;
  /** Additional style object to override default style */
  style?: RegisteredStyle<any>;
  /** If true, no input will be allowed on the field */
  disabled: boolean;
  /** Handle - show or hide  */
  onTogglePasswordVisibility?: () => void;
} & TextInputProps;

/**
 * A form field specifically for Auth flow.
 * Most functionality is mimicked from TextInput.
 */
const AuthField = React.forwardRef((props: AuthFieldProps, ref: any) => {
  const iconStyles = [];
  if (props.icon === 'lock' || props.icon === 'email' || props.icon === 'phone') {
    iconStyles.push({ marginHorizontal: 4 });
  }

  const containerStyles = [styles.authFieldContainer];
  if (props.isValid) {
    containerStyles.push({ borderColor: '#cacaca' } as any);
  } else {
    containerStyles.push({ borderColor: '#FF354A' } as any);
  }

  const showIcon = props.icon !== 'lock' && props.icon !== 'blank';

  return (
    <View style={[containerStyles]}>
      {showIcon && <Icon name={props.icon} size={16} color="#cacaca" style={iconStyles} />}
      {props.icon === 'lock' ? (
        <View style={styles.eyeContainer}>
          <Touchable onPress={props.onTogglePasswordVisibility}>
            <Icon
              name={props.isPasswordVisible ? 'eye' : 'eye-off'}
              color="#cacaca"
              size={16}
              style={{ position: 'absolute', right: 10 }}
            />
          </Touchable>
        </View>
      ) : null}
      <TextInput {...props} ref={ref} style={styles.input} />
    </View>
  );
});

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
  Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

const styles = StyleSheet.create({
  eyeContainer: {
    position: 'absolute',
    right: 0,
    backgroundColor: '#fff',
    width: 36,
    height: 20,
    zIndex: 2
  },
  authFieldContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 260,
    borderWidth: 0.55,
    borderRadius: 7.5,
    paddingVertical: 12.5,
    paddingHorizontal: 10,
    marginBottom: 10
  },
  input: {
    ...fontMaker({ weight: 'Regular' }),
    width: 200,
    marginLeft: 10,
    // paddingRight: 20,
    paddingLeft: 20,
    height: 30,
    fontSize: 14
  }
});

export default AuthField;
