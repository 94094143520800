import firebase from 'firebase';
import moment from 'moment-timezone';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Dimensions, StyleSheet, TextStyle, View } from 'react-native';

// components
import Button from '../../global/RoundedButton';
import Text from '../../global/Text';

// utils
import theme from '../../../constants/theme';
import fontMaker from '../../../utils/font';

// types
import { ScheduleOption } from '../../../typing/types';
import { ScheduleStatusEnum } from '../../../utils/classes';
import useScheduleCheckInOutPermission from '../utils/useScheduleCheckInOutPermission';
import ActionButtonDraft from './ActionButtonDraft';
import { SiteActionButtonProps } from './type';

function SiteActionButton(props: SiteActionButtonProps) {
  const {
    disabled,
    status,
    isBusy,
    locationPermissionGranted,
    hasCheckedIn,
    roleAccessAdhoc,
    featureAccessAdhoc,
    isLeader,
    isAdhoc,
    isOffline,
    isGPSMocked,
    selectedSchedule,
    makeupReason,
    selectedSite,
    selectedDateCalendar,
    onCheckIn,
    setActiveTab,
    setShowFooter,
    handleMakeupReport,
    onReviewPendingUpload,
    onConfirmCheckIn,
    onCalibrateLocation
  } = props;
  const { isCheckoutAllowed, isPassedCheckOutTime, isBetweenCheckInAndCheckOutTime, enforceCheckIn } =
    useScheduleCheckInOutPermission({
      isAdhoc: isAdhoc,
      selectedCalendarDate: String(selectedDateCalendar),
      selectedSchedule: selectedSchedule
    });

  const firebaseAnalyticsParams = {
    category: 'site_details_page',
    siteName: selectedSite?.name || 'unknown',
    questionnaire: selectedSchedule?.title || 'unknown'
  };
  const handleCalibrateLocation = () => onCalibrateLocation();

  const handleDeleteDraftReport = (schedule: ScheduleOption) => {
    firebase.analytics().logEvent('click_reset', firebaseAnalyticsParams);

    const isMakeup = moment().isAfter(selectedDateCalendar, 'day');

    props.handleDeleteDraftReport(schedule, isMakeup);
  };

  const handleCheckIn = () => {
    if (status === 'draft') {
      firebase.analytics().logEvent('click_continue', firebaseAnalyticsParams);
    } else {
      firebase.analytics().logEvent('click_check_in', firebaseAnalyticsParams);
    }

    onCheckIn();
    setShowFooter(false);
  };

  const handleConfirmCheckIn = () => onConfirmCheckIn();

  const handleReviewPendingUpload = () => {
    firebase.analytics().logEvent('upload_pending_report', firebaseAnalyticsParams);
    onReviewPendingUpload();
  };

  const onMakeupReport = () => {
    firebase.analytics().logEvent('click_make_up', firebaseAnalyticsParams);
    handleMakeupReport(makeupReason, selectedDateCalendar);
  };

  const handelNavViewReport = () => {
    firebase.analytics().logEvent('click_view_report', firebaseAnalyticsParams);
    setShowFooter(false);
    setActiveTab('reports');
  };

  const renderEnfoceCheckInOutMessage = () => {
    if (!isCheckoutAllowed) {
      return <Text style={styles.footerText}>{t('site:outlet:overdueReport')}</Text>;
    }

    if (enforceCheckIn && !isBetweenCheckInAndCheckOutTime) {
      if (isPassedCheckOutTime) {
        if (isCheckoutAllowed) {
          return null;
        }

        return <Text style={styles.footerText}>{t('site:outlet:checkInTimePassed')}</Text>;
      }

      return <Text style={styles.footerText}>{t('site:scheduleNotStartedYet')}</Text>;
    }

    return null;
  };
  const renderDeadlineStatusText = () => {
    const RederEnforce = renderEnfoceCheckInOutMessage();
    if (RederEnforce) {
      return RederEnforce;
    }
    return <Text style={styles.footerText}>{t('site:outlet.overdueReport')}</Text>;
  };

  const renderReadyButton = () => {
    const RederEnforce = renderEnfoceCheckInOutMessage();

    if (RederEnforce) {
      return RederEnforce;
    }
    return (
      <Button
        disabled={isBusy || disabled}
        onPress={onConfirmCheckIn}
        backgroundColor={props.profileColor}
        title={t('common:checkIn')}
      />
    );
  };

  const { t } = useTranslation(['site', 'common']);

  if (isOffline) {
    return <Text style={styles.footerTextOffline}>{t('site:outlet.offlineInfo')}</Text>;
  }

  if (isBusy) {
    return (
      <View style={styles.loading}>
        <ActivityIndicator size="small" color="#3cd070" />
      </View>
    );
  }

  if (status === 'error-location-not-found') {
    return (
      <Button
        disabled={isBusy}
        onPress={handleCalibrateLocation}
        backgroundColor={props.profileColor}
        title={t('site:outlet.button.setLocation').toUpperCase()}
        testID="outlet-calibrate-location"
      />
    );
  }

  // show check in button when disabled state
  if (disabled) {
    return <Button disabled={disabled} title="CHECK-IN" backgroundColor={props.profileColor} onPress={() => {}} />;
  }

  if (hasCheckedIn && !selectedSchedule?.isMultiSite) {
    if (status === 'draft') {
      return (
        <ActionButtonDraft
          disabled={isBusy || !locationPermissionGranted || disabled}
          resetTitle={t('common:reset')}
          onPressReset={() => handleDeleteDraftReport(props.selectedSchedule!)}
          continueTitle={t('common:continue')}
          onPressContinue={handleCheckIn}
        />
      );
    }

    return (
      <Button
        disabled={isBusy || disabled}
        onPress={handleCheckIn}
        title={t('site:outlet.button.enterLobby')}
        backgroundColor={props.profileColor}
      />
    );
  } else if (
    !isLeader ||
    (selectedSchedule?.isMultiSite &&
      status !== ScheduleStatusEnum.DRAFT &&
      status !== ScheduleStatusEnum.PENDING_UPLOAD &&
      status !== ScheduleStatusEnum.FINISHED_PERIOD &&
      status !== ScheduleStatusEnum.FINISHED_TODAY)
  ) {
    return (
      <Button
        disabled={isBusy || disabled}
        onPress={onConfirmCheckIn}
        title={t('common:checkIn')}
        backgroundColor={props.profileColor}
      />
    );
  }

  if (isAdhoc && status !== 'draft' && status !== ScheduleStatusEnum.PENDING_UPLOAD) {
    return (
      <Button
        disabled={
          isBusy || isGPSMocked || !featureAccessAdhoc || !roleAccessAdhoc || selectedSchedule?.isAdhocOnly
            ? false
            : !selectedSchedule?.allowAdhoc
        }
        onPress={onConfirmCheckIn}
        backgroundColor={props.profileColor}
        title={t('site:outlet.button.startAdhoc')}
        testID="outlet-start-adhoc-report"
      />
    );
  }

  const statusExtended =
    status === ScheduleStatusEnum.OVERDUE && renderDeadlineStatusText() ? ScheduleStatusEnum.READY : status;

  switch (statusExtended) {
    case ScheduleStatusEnum.PENDING_UPLOAD:
      return (
        <Button
          disabled={isBusy || disabled}
          onPress={handleReviewPendingUpload}
          backgroundColor="#3cd070"
          title={t('site:outlet.button.viewDetails')}
          testID="outlet-pending-upload-button"
        />
      );
    case ScheduleStatusEnum.ERROR_QUESTIONNAIRE_NOT_FOUND:
      return <Text style={styles.footerText}>{t('site:outlet.error.noQuestionnaireFound')}</Text>;
    case ScheduleStatusEnum.ERROR_ORGANIZATION_SCHEDULE_INVALID:
      return <Text style={styles.footerText}>{t('site:outlet.error.noScheduleFound')}</Text>;
    case ScheduleStatusEnum.NOT_ALLOWED_MAKEUP:
      return <Text style={styles.footerText}>{t('site:notAllowedMakeUpReport')}</Text>;
    case ScheduleStatusEnum.OVERDUE:
      return renderDeadlineStatusText();
    case ScheduleStatusEnum.NONE_DUE:
      return <Text style={styles.footerText}>{t('site:outlet.noReportDue')}</Text>;
    case ScheduleStatusEnum.ADHOC:
      return <Text style={styles.footerText}>{t('site:outlet.noReportDue')}</Text>;
    case ScheduleStatusEnum.DRAFT: {
      const RederEnforce = renderEnfoceCheckInOutMessage();
      if (RederEnforce) {
        return RederEnforce;
      }

      return (
        <ActionButtonDraft
          disabled={isBusy || !locationPermissionGranted || disabled}
          resetTitle={t('common:reset')}
          onPressReset={() => handleDeleteDraftReport(props.selectedSchedule!)}
          continueTitle={t('common:continue')}
          onPressContinue={handleCheckIn}
        />
      );
    }
    case ScheduleStatusEnum.MAKEUP:
      return (
        <Button
          disabled={isBusy || disabled}
          onPress={onMakeupReport}
          backgroundColor={props.profileColor}
          title={t('site:outlet.button.makeUpReport')}
        />
      );
    case ScheduleStatusEnum.READY:
      return renderReadyButton();
    case ScheduleStatusEnum.FINISHED_TODAY:
    case ScheduleStatusEnum.FINISHED_PERIOD:
      return (
        <Button
          disabled={isBusy || disabled}
          onPress={handelNavViewReport}
          backgroundColor={props.profileColor}
          title={t('site:outlet.button.viewReport')}
        />
      );
    default:
      return null;
  }
}

export default SiteActionButton;

const styles = StyleSheet.create({
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 11
  },
  footerText: {
    marginHorizontal: Dimensions.get('window').width * 0.05,
    ...fontMaker({ weight: 'SemiBold' }),
    textAlign: 'center',
    color: '#ed5565'
  } as TextStyle,
  footerContainer: {},
  footerTextOffline: {
    marginHorizontal: Dimensions.get('window').width * 0.05,
    ...fontMaker({ weight: 'SemiBold' }),
    textAlign: 'center',
    color: '#ed5565'
  } as TextStyle,
  inactiveText: {
    ...fontMaker({ weight: 'Light' }),
    fontSize: 14
  },
  questionnaireContainer: {
    width: '100%',
    borderRadius: 4,
    backgroundColor: theme.colors.light,
    borderColor: theme.colors.light,
    borderWidth: StyleSheet.hairlineWidth,
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconContainer: {
    paddingHorizontal: 8
  },
  button: {
    width: '49%'
  }
});
