import React, { useState } from 'react';
import { StyleSheet, View, TouchableNativeFeedback, TouchableOpacity, Platform, Dimensions } from 'react-native';
import Text from '../../../global/Text';
import { useSelector, useDispatch } from 'react-redux';
import firebase from 'firebase';
import { toastActions } from '../../../../store/reducers/toast';
import { Linking } from 'react-native';
import { FirebaseReducer } from 'react-redux-firebase';

// types
import { Organization, SiteReportSummary, Questionnaire } from 'nimbly-common';
import { User, Report } from '../../../../utils/classes';
import { RootState } from '../../../../store/reducers';
import { SelectedSite } from '../Outlet/type';

// component
import fontMaker from '../../../../utils/font';

// utils
import moment from 'moment';
import { useTranslation } from 'react-i18next';

type ReportsItemProps = {
  item: SiteReportSummary & { questionnaire: Questionnaire };
  selectedSite: SelectedSite;
};

const ReportsItem = (props: ReportsItemProps) => {
  const { item, selectedSite } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation(['site']);
  const [reportKeyRetrieving, setReportKeyRetrieving] = useState<string | null>(null);
  const organization = useSelector<RootState, Organization | null | undefined>(
    state => state.organization.myOrganization
  );
  const selectedSiteKey = useSelector<RootState, string | null>(state => state.site.selectedSiteKey);
  const profile = useSelector<RootState, FirebaseReducer.Profile<User>>(state => state.firebase.profile);

  const displayErrorMessage = (error: Error) => {
    dispatch(toastActions.createToast(error.message, true, 5000));
  };

  const reportKeyFormatter = (key: string) => {
    const finalKey = key.replace(`${selectedSiteKey}_`, '');
    return finalKey;
  };

  const getReportDownloadURL = (reportRef: string): Promise<string> => {
    return firebase.storage().ref(reportRef).getDownloadURL();
  };
  const handleDownloadReport = async (reportKeyData: string, isJourney: boolean, journeyCode: string) => {
    firebase.analytics().logEvent('click_view_report_card', {
      category: 'site_detail_page',
      siteName: selectedSite?.name,
      reportName: reportKeyData
    });
    // Only fire if there isn't another report being retrieved
    if (!reportKeyRetrieving && selectedSiteKey) {
      const reportKey = reportKeyFormatter(reportKeyData);
      setReportKeyRetrieving(reportKey);

      const isNewKey = reportKey.startsWith(selectedSiteKey);
      const mutatedReportKey = isNewKey ? reportKey.replace(`${selectedSiteKey}_`, '') : reportKey;

      // Retrieve a download link for the report and open in the browser
      let pdfRef = `/report/${profile.organization}/${selectedSiteKey}/${reportKey}.pdf`;
      let xlsxRef = `/report/${profile.organization}/${selectedSiteKey}/${reportKey}.xlsx`;

      //handle path if site is in a journey
      if (isJourney) {
        pdfRef = `/reportJourney/${profile.organization}/${journeyCode}/${selectedSiteKey}/${reportKey}.pdf`;
        xlsxRef = `/reportJourney/${profile.organization}/${journeyCode}/${selectedSiteKey}/${reportKey}.xlsx`;
      }
      let url = '';

      const selectedRef = organization!.reportFormat === 'pdf' ? pdfRef : xlsxRef;

      type Data = {
        report: Report;
        organizationKey: string;
        siteKey: string;
        reportKey: string;
      };
      const generateReportOnCall = firebase.functions().httpsCallable('generateReportOnCall');

      try {
        try {
          url = await getReportDownloadURL(selectedRef);
        } catch (error) {
          if (error.code !== 'storage/object-not-found') {
            throw error;
          }

          const reportSnap = await firebase
            .database()
            .ref(`/report/${profile.organization}/${selectedSiteKey}/${reportKey}`)
            .once('value');
          const report = reportSnap.val();
          if (!report) {
            throw new Error('Internal server error. Please contact support.');
          }

          const data: Data = {
            reportKey: mutatedReportKey,
            siteKey: selectedSiteKey,
            organizationKey: profile.organization,
            report
          };
          const res = await generateReportOnCall(data);

          if (res && res.data) {
            url = res.data;
          }
        }

        if (!url) {
          throw new Error('Internal server error. Please contact support.');
        }
        firebase.analytics().logEvent('report_download');

        setReportKeyRetrieving(null);
        Linking.openURL(url);
        return url;
      } catch (error) {
        setReportKeyRetrieving(null);
        displayErrorMessage(error);
        return error;
      }
    }
  };

  return (
    <View style={styles.root} testID="siteReportItem-container">
      <View style={styles.firstColumn}>
        <Text style={styles.dateStyle}>
          {moment(item.key.replace(`${item.siteID}_`, '').split('_')[0]).format('DD MMM YYYY')}
        </Text>
      </View>
      <View style={styles.secondColumn}>
        <Text style={styles.titleStyle} numberOfLines={3}>
          {item.questionnaire?.title || ''}
        </Text>
      </View>
      <View style={styles.thirdColumn}>
        <View style={styles.rangeFlag}>
          <View style={styles.redFlag}>
            <Text style={styles.flagText} numberOfLines={1}>
              {item.flagCount!.red}
            </Text>
          </View>
          <View style={styles.yellowFlag}>
            <Text style={styles.flagText} numberOfLines={1}>
              {item.flagCount!.yellow}
            </Text>
          </View>
          <View style={styles.greenFlag}>
            <Text style={styles.flagText} numberOfLines={1}>
              {item.flagCount!.green}
            </Text>
          </View>
        </View>
        <Touchable>
          <Text
            style={styles.downloadButton}
            onPress={() =>
              handleDownloadReport(
                item.isJourney ? item.journeyReportCode! : item.key,
                item.isJourney!,
                item.journeyKey as any
              )
            }
          >
            {t('site:siteReports.download')}
          </Text>
        </Touchable>
      </View>
    </View>
  );
};

export default ReportsItem;
const Touchable: any = Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;
const styles = StyleSheet.create({
  root: {
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    marginBottom: 20,
    padding: 15
  },
  dateStyle: {
    fontSize: 10,
    lineHeight: 15,
    color: '#A0A4A8'
  },
  titleStyle: {
    fontWeight: 'bold'
  },
  thirdColumn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  rangeFlag: {
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  greenFlag: {
    backgroundColor: '#0ABA26',
    width: 32,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3
  },
  yellowFlag: {
    backgroundColor: '#FFC81F',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    borderRadius: 3,
    marginRight: 5
  },
  redFlag: {
    backgroundColor: '#F7001A',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    borderRadius: 3,
    marginRight: 5
  },
  downloadButton: {
    color: '#56C768',
    ...fontMaker({ weight: 'Bold' })
  },
  firstColumn: {
    marginBottom: 10
  },
  secondColumn: {
    marginBottom: 10
  },
  flagText: {
    color: '#FFFFFF'
  }
});
