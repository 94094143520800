import React from 'react';

import { View, TouchableOpacity, StyleSheet } from 'react-native';
import Text from '../Text';
import { TOOLTIP_SKIP_BTN_COLOR, TOOLTIP_MAIN_BTN_COLOR, DIVIDER_COLOR } from '../../../constants/theme';
import { SCREEN_WIDTH } from '../../../utils/screensize';

type TooltipBtn = {
  title: string;
  onPress: () => void;
};

export type TooltipBtnContent = {
  mainBtn?: TooltipBtn;
  skipBtn?: TooltipBtn;
};

export type TooltipContentProps = TooltipBtnContent & {
  text: string;
};

function TooltipContent(props: TooltipContentProps) {
  const { text, mainBtn, skipBtn } = props;
  return (
    <View style={styles.contentContainer}>
      <View style={styles.rowContainer}>
        <Text style={[styles.flex, !mainBtn && !skipBtn && styles.centeredText]}>{text}</Text>
        {mainBtn && (
          <TouchableOpacity activeOpacity={0.6} style={styles.mainBtn} onPress={mainBtn.onPress}>
            <Text style={styles.mainBtnText}>{mainBtn.title}</Text>
          </TouchableOpacity>
        )}
      </View>

      {skipBtn ? (
        <>
          <View style={styles.divider} />
          <TouchableOpacity activeOpacity={0.6} onPress={skipBtn.onPress}>
            <Text style={styles.skipBtnText}>{skipBtn.title}</Text>
          </TouchableOpacity>
        </>
      ) : null}
    </View>
  );
}

export default TooltipContent;

const styles = StyleSheet.create({
  flex: {
    flex: 1
  },
  centeredText: {
    textAlign: 'center'
  },
  contentContainer: {
    padding: 10,
    minWidth: SCREEN_WIDTH * 0.7
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: SCREEN_WIDTH * 0.7
  },
  mainBtn: {
    width: 65,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10
  },
  mainBtnText: {
    color: TOOLTIP_MAIN_BTN_COLOR
  },
  skipBtnText: {
    color: TOOLTIP_SKIP_BTN_COLOR,
    fontSize: 12
  },
  divider: {
    height: 1,
    width: '100%',
    backgroundColor: DIVIDER_COLOR,
    marginVertical: 15
  }
});
