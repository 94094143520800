import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { View, StyleSheet, Dimensions } from 'react-native';
import { RootState } from '../../store/reducers';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import moment from 'moment';
import { ProductCategory, Product, CompanyTargetMarket } from 'nimbly-common';
import {
  TextInput,
  Platform,
  TextStyle,
  TouchableOpacity,
  TouchableWithoutFeedbackProps,
  TouchableNativeFeedback,
  Image
} from 'react-native';

import Text from '../global/Text';
import SelectorPopup, { Option } from '../global/SelectorPopupNew';
import ModalInput from '../global/Modal';
import Switch from '../global/Switch';
import { QuestionnaireQuestionCompetitorAnalysisProps } from './QuestionnaireQuestionCompetitorAnalysis';
import fontMaker from '../../utils/font';
import theme from '../../constants/theme';
import { toastActions } from '../../store/reducers/toast';
import { ScrollView } from 'react-native-gesture-handler';
import { companyActions } from '../../store/reducers/company/company';
import { promotionTypeOptions } from '../../constants/options';
import ImagePicker from 'react-native-everywhere-image-picker';
import { useTranslation } from 'react-i18next';
const screen = Dimensions.get('screen');
const { width } = screen;

export type QuestionnaireQuestionCompetitorAnalysisFormPropsExtra = {
  styles: any;
};

export type QuestionnaireQuestionCompetitorAnalysisFormProps = QuestionnaireQuestionCompetitorAnalysisFormPropsExtra &
  Pick<
    QuestionnaireQuestionCompetitorAnalysisProps,
    | 'question'
    | 'index'
    | 'language'
    | 'query'
    | 'competitorAnalysisInputRef'
    | 'handleInputCompetitorAnalysis'
    | 'handleCompetitorAnalysisInputFocused'
    | 'handleCompetitorAnalysisInputBlurred'
    | 'handleActivateImagePicker'
    | 'handleConfirmEditPhoto'
  >;

const QuestionnaireQuestionCompetitorAnalysisForm = (props: QuestionnaireQuestionCompetitorAnalysisFormProps) => {
  const { t } = useTranslation(['questionnaire', 'common']);
  const {
    question,
    index,
    competitorAnalysisInputRef,
    handleInputCompetitorAnalysis,
    handleCompetitorAnalysisInputFocused,
    handleCompetitorAnalysisInputBlurred,
    handleConfirmEditPhoto
  } = props;

  const dispatch = useDispatch();

  const errorCompany = useSelector<RootState, string | null>(state => state.company.error);
  const isLoading = useSelector((state: RootState) => state.company.isLoading);

  //error toast
  useEffect(() => {
    if (errorCompany) {
      dispatch(toastActions.createToast(errorCompany, true));
    }
  }, [errorCompany]);

  const [currentMonth, setCurrentMonth] = useState<number>(moment().valueOf());
  const handleChangeNextMonth = () => {
    const nextMonth = moment(currentMonth).add(1, 'month').valueOf();
    setCurrentMonth(nextMonth);
  };
  const handleChangePreviousMonth = () => {
    const prevMonth = moment(currentMonth).subtract(1, 'month').valueOf();
    setCurrentMonth(prevMonth);
  };
  const handleSelectDate = (date: string) => {
    if (question.competitorAnalysis?.promotionStartDate && question.competitorAnalysis?.promotionEndDate) {
      handleInputCompetitorAnalysis(index, '', 'promotionEndDate');
      handleInputCompetitorAnalysis(index, '', 'promotionStartDate');
    } else if (question.competitorAnalysis?.promotionStartDate) {
      if (!question.competitorAnalysis.promotionIndefiniteEndDate) {
        handleInputCompetitorAnalysis(index, date, 'promotionEndDate');
      }
    } else {
      handleInputCompetitorAnalysis(index, date, 'promotionStartDate');
    }
  };

  const handleChangeIndefiniteEndDate = () => {
    if (question.competitorAnalysis?.promotionIndefiniteEndDate) {
      handleInputCompetitorAnalysis(index, 0, 'promotionIndefiniteEndDate');
    } else {
      handleInputCompetitorAnalysis(index, '', 'promotionEndDate');
      handleInputCompetitorAnalysis(index, 1, 'promotionIndefiniteEndDate');
    }
  };

  // add category state and function
  const [newCategory, setNewCategory] = useState<string>('');
  const [showAddCategoryModal, setShowAddCategoryModal] = useState<boolean>(false);
  const [newTargetMarket, setNewTargetMarket] = useState<string>('');
  const [showAddTargetMarketModal, setShowAddTargetMarketModal] = useState<boolean>(false);
  const handleAddCategory = async () => {
    if (!newCategory || newCategory === '') {
      dispatch(toastActions.createToast(t('questionnaire:question.competitorAnalysis.categoryEmpty'), true));
      return;
    }
    await dispatch(
      companyActions.createProductCategoryAsync.request({
        categoryName: newCategory
      })
    );

    setShowAddCategoryModal(false);
  };

  const handleAddTargetMarket = async () => {
    if (!newTargetMarket || newTargetMarket === '') {
      dispatch(toastActions.createToast(t('questionnaire:question.competitorAnalysis.targetMarketEmpty'), true));
      return;
    }
    await dispatch(
      companyActions.createProductTargetMarketAsync.request({
        targetMarketName: newTargetMarket
      })
    );

    setShowAddTargetMarketModal(false);
  };

  // product categories states
  const productCategories = useSelector((state: RootState) => state.company.productCategories);
  const productStores = useSelector((state: RootState) => state.site.sites);
  const productTargetMarket = useSelector((state: RootState) => state.company.productTargetMarket);
  const products = useSelector((state: RootState) => state.company.products);
  const [productCategoriesOptions, setProductCategoriesOptions] = useState<Option[]>([]);
  const [productOptions, setProductOptions] = useState<Option[]>([]);
  const [optionalProductOptions, setOptionalProductOptions] = useState<Option[]>([]);
  const [targetmarketOptions, setProductTargetMarketOptions] = useState<Option[]>([]);
  const [storeOptions, setStoretOptions] = useState<Option[]>([]);

  // fetch categories and put it in options, and select the first item
  useEffect(() => {
    if (products.length > 0 && !isLoading && question.competitorAnalysis?.company) {
      const newProductsOptions: Option[] = [];
      products.forEach((product: Product) => {
        if (product.company.code === question.competitorAnalysis?.company?.value) {
          newProductsOptions.push({ label: product.name, value: product.code });
        }
      });
      setProductOptions(newProductsOptions);
      setOptionalProductOptions([{ label: 'none', value: '' }, ...newProductsOptions]);
      if (newProductsOptions.length > 0) {
        handleInputCompetitorAnalysis(index, newProductsOptions[0], 'productVariant');
        handleInputCompetitorAnalysis(index, { label: 'none', value: '' }, 'promotionProduct');
      }
    }
  }, [products, isLoading, question.competitorAnalysis?.company]);

  useEffect(() => {
    if (productCategories.length > 0 && !isLoading) {
      const newProductCategoriesOptions: Option[] = [];
      productCategories.forEach((product: Partial<ProductCategory>) => {
        newProductCategoriesOptions.push({ label: product.categoryName!, value: product.code! });
      });
      setProductCategoriesOptions(newProductCategoriesOptions);
      handleInputCompetitorAnalysis(index, newProductCategoriesOptions[0], 'productCategory');
    }
  }, [productCategories, isLoading]);

  useEffect(() => {
    if (productTargetMarket.length > 0 && !isLoading) {
      const newProductTargetMarketOptions: Option[] = [];
      productTargetMarket.forEach((targetMarket: Partial<CompanyTargetMarket>) => {
        newProductTargetMarketOptions.push({ label: targetMarket.targetMarketName!, value: targetMarket.code! });
      });
      setProductTargetMarketOptions(newProductTargetMarketOptions);
      handleInputCompetitorAnalysis(index, newProductTargetMarketOptions[0], 'productTargetMarket');
    }
  }, [productTargetMarket]);
  useEffect(() => {
    if (productStores && Object.keys(productStores as any).length > 0) {
      const newStoreOptions: Option[] = [];
      Object.keys(productStores as any).forEach((site: string) => {
        newStoreOptions.push({ label: productStores![site].name, value: site });
      });
      setStoretOptions(newStoreOptions);
      handleInputCompetitorAnalysis(index, newStoreOptions[0], 'productStores');
    }
  }, [productStores]);

  //initiate selected promotion type
  useEffect(() => {
    handleInputCompetitorAnalysis(index, promotionTypeOptions[0], 'promotionType');
  }, []);

  if (!question.competitorAnalysis || isLoading || question.answer === 'not-applicable') {
    return null;
  }

  if (question.competitorAnalysis.form === 'promotion') {
    return (
      <ScrollView
        style={[
          styles.competitorAnalysisFormContainer,
          question.photos && question.photos.length > 0 ? { marginTop: 40 } : { marginTop: 0 }
        ]}
      >
        <Text style={styles.label}>{t('questionnaire:question.competitorAnalysis.promotion.title')}</Text>
        <View
          style={[
            styles.answerInputContainer,
            !question.competitorAnalysis?.promotionTitle && { borderColor: '#ffa040' }
          ]}
        >
          <TextInput
            style={styles.answerInput}
            maxLength={20}
            onChangeText={text => handleInputCompetitorAnalysis(index, text, 'promotionTitle')}
            value={question.competitorAnalysis?.promotionTitle}
            // onFocus={() => handleCompetitorAnalysisInputFocused(index, 'promotionTitle')}
            // onBlur={() => handleCompetitorAnalysisInputBlurred(index)}
            ref={competitorAnalysisInputRef[`${index}_promotionTitle`]}
            placeholder={t('questionnaire:question.competitorAnalysis.promotion.name')}
          />
        </View>

        <Text style={styles.label}>{t('questionnaire:question.competitorAnalysis.promotion.product')}</Text>
        <SelectorPopup
          title={t('questionnaire:question.competitorAnalysis.promotion.selectProduct')}
          options={optionalProductOptions}
          onChangeSelected={option => {
            handleInputCompetitorAnalysis(index, option, 'promotionProduct');
          }}
          selected={question.competitorAnalysis.promotionProduct}
        />

        <Text style={styles.label}>{t('questionnaire:question.competitorAnalysis.promotion.promotionType')}</Text>
        <SelectorPopup
          title={t('questionnaire:question.competitorAnalysis.promotion.choosePromotionType')}
          options={promotionTypeOptions}
          onChangeSelected={option => {
            handleInputCompetitorAnalysis(index, option, 'promotionType');
          }}
          selected={question.competitorAnalysis.promotionType}
        />

        <Text style={styles.label}>{t('questionnaire:question.competitorAnalysis.promotion.promotionImage')}</Text>
        <View style={styles.rowInputContainer}>
          {!(question.photos && question.photos.length > 0) && (
            <>
              <ImagePicker onComplete={imageProps => props.handleActivateImagePicker(undefined, imageProps)}>
                <View style={styles.button}>
                  <Icon name="image" color={'#3cd070'} size={20} />
                  <Text>{t('questionnaire:question.competitorAnalysis.promotion.fromGallery')}</Text>
                </View>
              </ImagePicker>
            </>
          )}
        </View>

        {question.photos && question.photos.length > 0
          ? question.photos.map((photo, photoIndex) => (
              <ImagePicker
                key={`image-${photoIndex}`}
                onComplete={imageProps => props.handleActivateImagePicker(undefined, imageProps)}
              >
                <View style={styles.imageContainer}>
                  <Image style={styles.image} resizeMode="cover" source={{ uri: photo.split('/fileName/')[0] }} />
                </View>
              </ImagePicker>
            ))
          : null}

        <Text style={styles.label}>{t('questionnaire:question.competitorAnalysis.promotion.displayLocation')}</Text>
        <View style={[styles.answerInputContainer, styles.answerInputContainerMultiline]}>
          <TextInput
            style={[styles.answerInput, { height: 110 }]}
            onChangeText={text => handleInputCompetitorAnalysis(index, text, 'promotionDisplayLocation')}
            value={question.competitorAnalysis?.promotionDisplayLocation}
            // onFocus={() => handleCompetitorAnalysisInputFocused(index, 'promotionDisplayLocation')}
            // onBlur={() => handleCompetitorAnalysisInputBlurred(index)}
            multiline={true}
            ref={competitorAnalysisInputRef[`${index}_promotionDisplayLocation`]}
            placeholder={t('questionnaire:question.competitorAnalysis.promotion.enterLocation')}
          />
        </View>

        <Text style={styles.label}>{t('questionnaire:question.competitorAnalysis.promotion.detailPromotion')}</Text>
        <View
          style={[
            styles.answerInputContainer,
            styles.answerInputContainerMultiline,
            !question.competitorAnalysis?.promotionDetail && { borderColor: '#ffa040' }
          ]}
        >
          <TextInput
            style={[styles.answerInput, { height: 110 }]}
            onChangeText={text => handleInputCompetitorAnalysis(index, text, 'promotionDetail')}
            value={question.competitorAnalysis?.promotionDetail}
            // onFocus={() => handleCompetitorAnalysisInputFocused(index, 'promotionDetail')}
            // onBlur={() => handleCompetitorAnalysisInputBlurred(index)}
            multiline={true}
            ref={competitorAnalysisInputRef[`${index}_promotionDetail`]}
            placeholder={t('questionnaire:question.competitorAnalysis.promotion.enterDetail')}
          />
        </View>

        <Text style={styles.label}>{t('questionnaire:question.competitorAnalysis.promotion.promotionPeriod')}</Text>
        <View style={[styles.rowInputContainerColumn]}>
          <Touchable>
            <View
              style={[
                styles.button,
                { width: 650 / 2 },
                !question.competitorAnalysis?.promotionStartDate && { borderColor: '#ffa040' }
              ]}
            >
              <Text>
                {question.competitorAnalysis.promotionStartDate
                  ? moment(question.competitorAnalysis.promotionStartDate).format('DD MMM YYYY')
                  : t('questionnaire:question.competitorAnalysis.promotion.startDate')}
              </Text>
              <Icon name="calendar" color={'#3cd070'} size={20} />
            </View>
          </Touchable>
          <Text> {t('questionnaire:question.competitorAnalysis.promotion.to')} </Text>
          <Touchable disabled={!!question.competitorAnalysis.promotionIndefiniteEndDate}>
            <View style={[styles.button, { width: 650 / 2 }]}>
              <Text>
                {question.competitorAnalysis.promotionEndDate
                  ? moment(question.competitorAnalysis.promotionEndDate).format('DD MMM YYYY')
                  : t('questionnaire:question.competitorAnalysis.promotion.endDate')}
              </Text>
              <Icon name="calendar" color={'#3cd070'} size={20} />
            </View>
          </Touchable>
        </View>
        <Text style={styles.label}>Indefinite End Date</Text>
        <Switch
          active={!!question.competitorAnalysis.promotionIndefiniteEndDate}
          onChange={handleChangeIndefiniteEndDate}
        />
      </ScrollView>
    );
  }

  if (question.competitorAnalysis.form === 'product') {
    return (
      <ScrollView
        style={[
          styles.competitorAnalysisFormContainer,
          question.photos && question.photos.length > 0 ? { marginTop: 40 } : { marginTop: 0 }
        ]}
      >
        <Text style={styles.label}>{t('questionnaire:question.competitorAnalysis.product.name')}</Text>
        <View
          style={[styles.answerInputContainer, !question.competitorAnalysis?.productName && { borderColor: '#ffa040' }]}
        >
          <TextInput
            style={styles.answerInput}
            maxLength={20}
            onChangeText={text => handleInputCompetitorAnalysis(index, text, 'productName')}
            value={question.competitorAnalysis?.productName}
            // onFocus={() => handleCompetitorAnalysisInputFocused(index, 'productName')}
            // onBlur={() => handleCompetitorAnalysisInputBlurred(index)}
            ref={competitorAnalysisInputRef[`${index}_productName`]}
            placeholder={t('questionnaire:question.competitorAnalysis.product.enterName')}
          />
        </View>

        <Text style={styles.label}>{t('questionnaire:question.competitorAnalysis.product.category')}</Text>
        <SelectorPopup
          title={t('questionnaire:question.competitorAnalysis.product.selectCategory')}
          options={productCategoriesOptions}
          onChangeSelected={option => {
            handleInputCompetitorAnalysis(index, option, 'productCategory');
          }}
          selected={question.competitorAnalysis.productCategory}
          addNewText={t('questionnaire:question.competitorAnalysis.product.addNewCategory')}
          onClickAddNew={() => setShowAddCategoryModal(true)}
        />

        <Text style={styles.label}>{t('questionnaire:question.competitorAnalysis.product.productImage')}</Text>
        <View style={styles.rowInputContainer}>
          {!(question.photos && question.photos.length > 0) && (
            <>
              <ImagePicker onComplete={imageProps => props.handleActivateImagePicker(undefined, imageProps)}>
                <View style={styles.button}>
                  <Icon name="image" color={'#3cd070'} size={20} />
                  <Text>{t('questionnaire:question.competitorAnalysis.product.fromGallery')}</Text>
                </View>
              </ImagePicker>
            </>
          )}
        </View>

        {question.photos && question.photos.length > 0
          ? question.photos.map((photo, photoIndex) => (
              <ImagePicker
                key={`image-${photoIndex}`}
                onComplete={imageProps => props.handleActivateImagePicker(undefined, imageProps)}
              >
                <View style={styles.imageContainer}>
                  <Image style={styles.image} resizeMode="cover" source={{ uri: photo.split('/fileName/')[0] }} />
                </View>
              </ImagePicker>
            ))
          : null}

        <Text style={styles.label}>{t('questionnaire:question.competitorAnalysis.product.productPrice')}</Text>
        <View style={styles.priceContainer}>
          <View
            style={[
              styles.answerInputContainerPrice,
              !question.competitorAnalysis?.productPriceFrom && { borderColor: '#ffa040' }
            ]}
          >
            <TextInput
              style={styles.answerInputPrice}
              keyboardType="numeric"
              onChangeText={text => {
                const numberValue = text.replace('Rp. ', '').split(',').join('');
                if (!isNaN(Number(numberValue))) {
                  handleInputCompetitorAnalysis(index, numberValue, 'productPriceFrom');
                }
              }}
              value={
                question.competitorAnalysis?.productPriceFrom
                  ? `Rp. ${Number(question.competitorAnalysis?.productPriceFrom).toLocaleString()}`
                  : ''
              }
              // onFocus={() => handleCompetitorAnalysisInputFocused(index, 'productPriceFrom')}
              // onBlur={() => handleCompetitorAnalysisInputBlurred(index)}
              ref={competitorAnalysisInputRef[`${index}_productPriceFrom`]}
              placeholder={'0'}
            />
          </View>
          <Text>-</Text>
          <View
            style={[
              styles.answerInputContainerPrice,
              !question.competitorAnalysis?.productPriceTo && { borderColor: '#ffa040' }
            ]}
          >
            <TextInput
              style={styles.answerInputPrice}
              keyboardType="numeric"
              onChangeText={text => {
                const numberValue = text.replace('Rp. ', '').split(',').join('');
                if (!isNaN(Number(numberValue))) {
                  handleInputCompetitorAnalysis(index, numberValue, 'productPriceTo');
                }
              }}
              value={
                question.competitorAnalysis?.productPriceTo
                  ? `Rp. ${Number(question.competitorAnalysis?.productPriceTo).toLocaleString()}`
                  : ''
              }
              // onFocus={() => handleCompetitorAnalysisInputFocused(index, 'productPriceTo')}
              // onBlur={() => handleCompetitorAnalysisInputBlurred(index)}
              ref={competitorAnalysisInputRef[`${index}_productPriceTo`]}
              placeholder={'0'}
            />
          </View>
        </View>

        <ModalInput
          title={t('questionnaire:question.competitorAnalysis.product.addNewCategory')}
          visible={showAddCategoryModal}
          submitText={t('questionnaire:question.competitorAnalysis.product.add')}
          onSubmit={handleAddCategory}
        >
          <View style={[styles.answerInputContainer, { margin: 10 }]}>
            <TextInput
              style={styles.answerInput}
              maxLength={20}
              onChangeText={text => {
                setNewCategory(text);
              }}
              value={newCategory}
            />
          </View>
        </ModalInput>

        <ModalInput
          title={t('questionnaire:question.competitorAnalysis.product.addNewTargetMarket')}
          visible={showAddTargetMarketModal}
          submitText={t('questionnaire:question.competitorAnalysis.product.add')}
          onSubmit={handleAddTargetMarket}
        >
          <View style={[styles.answerInputContainer, { margin: 10 }]}>
            <TextInput
              style={styles.answerInput}
              maxLength={20}
              onChangeText={text => {
                setNewTargetMarket(text);
              }}
              value={newTargetMarket}
            />
          </View>
        </ModalInput>

        <Text style={styles.label}>{t('questionnaire:question.competitorAnalysis.product.description')}</Text>
        <View
          style={[
            styles.answerInputContainer,
            styles.answerInputContainerMultiline,
            !question.competitorAnalysis?.productDescription && { borderColor: '#ffa040' }
          ]}
        >
          <TextInput
            style={[styles.answerInput, { height: 110 }]}
            onChangeText={text => handleInputCompetitorAnalysis(index, text, 'productDescription')}
            value={question.competitorAnalysis?.productDescription}
            // onFocus={() => handleCompetitorAnalysisInputFocused(index, 'productDescription')}
            // onBlur={() => handleCompetitorAnalysisInputBlurred(index)}
            multiline={true}
            ref={competitorAnalysisInputRef[`${index}_productDescription`]}
            placeholder={t('questionnaire:question.competitorAnalysis.product.targetMarket')}
          />
        </View>

        <Text style={styles.label}>{t('questionnaire:question.competitorAnalysis.product.name')}</Text>
        <SelectorPopup
          title={t('questionnaire:question.competitorAnalysis.product.selectTargetMarket')}
          options={targetmarketOptions}
          onChangeSelected={option => {
            handleInputCompetitorAnalysis(index, option, 'productTargetMarket');
          }}
          selected={question.competitorAnalysis.productTargetMarket}
          addNewText={t('questionnaire:question.competitorAnalysis.product.addNewTargetMarket')}
          onClickAddNew={() => setShowAddTargetMarketModal(true)}
        />

        <Text style={styles.label}>{t('questionnaire:question.competitorAnalysis.product.variantOf')}</Text>

        <SelectorPopup
          title={t('questionnaire:question.competitorAnalysis.product.selectProduct')}
          options={productOptions}
          onChangeSelected={option => {
            handleInputCompetitorAnalysis(index, option, 'productVariant');
          }}
          selected={question.competitorAnalysis.productVariant}
        />

        <Text style={styles.label}>{t('questionnaire:question.competitorAnalysis.product.store')}</Text>
        <SelectorPopup
          title={t('questionnaire:question.competitorAnalysis.product.selectStore')}
          options={storeOptions}
          onChangeSelected={option => {
            handleInputCompetitorAnalysis(index, option, 'productStores');
          }}
          selected={question.competitorAnalysis.productStores}
        />
      </ScrollView>
    );
  }

  return null;
};

const KEYBOARD_SHOWN_LIST_BOTTOM_INSET = 175;
const DEFAULT_LIST_BOTTOM_INSET = width <= 360 ? 52 : 24;

export default QuestionnaireQuestionCompetitorAnalysisForm;

const styles = StyleSheet.create({
  competitorAnalysisFormContainer: {
    padding: 20
  },
  label: {
    fontWeight: 'bold',
    fontSize: 15,
    marginBottom: 5
  },
  answerInputContainer: {
    borderRadius: 4,
    backgroundColor: theme.colors.light,
    height: 40,
    flexDirection: 'row',
    paddingHorizontal: 10,
    marginBottom: 10,
    borderColor: 'white',
    borderWidth: 1
  },
  answerInputContainerPrice: {
    borderRadius: 4,
    backgroundColor: theme.colors.light,
    height: 40,
    flexDirection: 'row',
    paddingHorizontal: 10,
    marginBottom: 10,
    borderColor: 'white',
    borderWidth: 1,
    width: '40%'
  },
  answerInput: {
    ...fontMaker({ weight: 'Regular' }),
    color: '#535353',
    textAlignVertical: 'top',
    paddingTop: 0,
    paddingHorizontal: 10,
    width: '100%'
  },
  answerInputPrice: {
    ...fontMaker({ weight: 'Regular' }),
    color: '#535353',
    textAlignVertical: 'top',
    paddingTop: 0,
    paddingHorizontal: 10,
    width: '100%'
  },
  answerInputContainerMultiline: {
    height: 120,
    paddingVertical: 10
  },
  disabledContainer: {
    backgroundColor: 'grey'
  },
  rowInputContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 900
  },
  rowInputContainerColumn: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  button: {
    borderRadius: 4,
    backgroundColor: theme.colors.light,
    flexDirection: 'row',
    height: 40,
    alignItems: 'center',
    paddingHorizontal: 20,
    width: 710,
    justifyContent: 'space-evenly',
    marginBottom: 10,
    borderWidth: 1,
    borderColor: 'white'
  },
  selectBox: {
    borderRadius: 4,
    backgroundColor: theme.colors.light,
    width: '100%',
    height: 40,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 20,
    marginBottom: 10
  },
  imageContainer: {
    overflow: 'hidden',
    width: 60,
    height: 60,
    marginTop: 5,
    marginHorizontal: 20,
    borderRadius: 4,
    backgroundColor: '#a8a8aa',
    marginBottom: 20
  },
  image: {
    width: '100%',
    height: '100%'
  },
  priceContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center'
  }
});

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
  Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;
