import { action, ActionType } from 'typesafe-actions';
import update from 'immutability-helper';

const SET_UPLOADING_REPORT_KEY = '@uploadqueue/SET_UPLOADING_REPORT_KEY';
const REMOVE_UPLOADING_REPORT_KEY = '@uploadqueue/REMOVE_UPLOADING_REPORT_KEY';

export const setUploadingReportKey = (reportKey: string) => action(SET_UPLOADING_REPORT_KEY, { reportKey });

export const removeUploadingReportKey = (reportKey: string) => action(REMOVE_UPLOADING_REPORT_KEY, { reportKey });

export const uploadQueueActions = {
  setUploadingReportKey,
  removeUploadingReportKey
};

export type UploadQueueAction = ActionType<typeof uploadQueueActions>;

export type UploadQueueState = {
  uploadQueueKeys: {
    [reportKey: string]: boolean;
  };
};

const initialState: UploadQueueState = {
  uploadQueueKeys: {}
};

export function uploadqueueReducer(
  state: UploadQueueState = initialState,
  incomingAction: UploadQueueAction
): UploadQueueState {
  switch (incomingAction.type) {
    case SET_UPLOADING_REPORT_KEY:
      return update(state, {
        uploadQueueKeys: {
          [incomingAction.payload.reportKey]: (bool: boolean) => update(bool, { $set: true })
        }
      });
    case REMOVE_UPLOADING_REPORT_KEY:
      return update(state, {
        uploadQueueKeys: {
          $unset: [incomingAction.payload.reportKey]
        }
      });
    default:
      return state;
  }
}
