/* eslint-disable complexity */
import React, { useMemo } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Text, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
// types
import { ScheduleOption } from '../../../typing/types';
import { RootState } from '../../../store/reducers';
// utils
import theme from '../../../constants/theme';
import fontMaker from '../../../utils/font';
import getScheduleDateTime from '../utils/getScheduleDateTime';
import { ScheduleStatusEnum } from '../../../utils/classes';

type TimeStatusProps = {
  schedule: Partial<ScheduleOption>;
  isDateBefore?: boolean;
  isDateAfter?: boolean;
  fontSize?: number;
  calenderDate?: string | null;
  isOverdueVisible?: boolean;
  selectedCalendarDate?: string | null;
};

const RenderTimeSchedule = (props: TimeStatusProps) => {
  const { t } = useTranslation(['common', 'site']);
  const { schedule, fontSize, isDateBefore, isDateAfter, isOverdueVisible, selectedCalendarDate } = props;

  const siteTimeZone = useSelector((state: RootState) => state.site.selectedSite?.timezone);

  const isDeadlineOverdue = useMemo(() => {
    if (schedule.hasStrictTime && !isDateAfter) {
      const currentTimeInMinutes = moment().diff(moment().startOf('day'), 'minutes');
      return currentTimeInMinutes > Number(schedule.endTime);
    }

    return false;
  }, [schedule, isDateAfter]);

  const renderDeadlineOverdue = useMemo(() => {
    if (!isOverdueVisible) {
      return null;
    }

    if (
      (isDeadlineOverdue ||
        schedule.status === ScheduleStatusEnum.MAKEUP ||
        isDateBefore ||
        schedule.status === ScheduleStatusEnum.NOT_ALLOWED_MAKEUP) &&
      !(
        schedule.status === ScheduleStatusEnum.FINISHED_TODAY ||
        schedule.status === ScheduleStatusEnum.FINISHED_PERIOD ||
        schedule.status === ScheduleStatusEnum.ADHOC
      )
    ) {
      return <Text style={[styles.text, styles.overdue]}>{t('site:overdue')}</Text>;
    }
    return null;
  }, [schedule.status, isDateBefore, isDeadlineOverdue]);

  const renderTimeStatus = useMemo(() => {
    if (schedule?.isAdhocOnly || schedule.isMultiSite) {
      return (
        <Text>
          <Text>{`${t('site:startAnytime')}\n`}</Text>
          {renderDeadlineOverdue}
        </Text>
      );
    } else {
      if (!siteTimeZone) {
        return null;
      }

      const siteDateTime = moment(schedule.siteDateTime);

      const yesterday = moment().subtract(1, 'day').startOf('day').toISOString();
      const scheduleInDate = schedule.isYesterdaySchedule ? yesterday : selectedCalendarDate;
      const { startTime, endTime } = getScheduleDateTime({
        schedule,
        siteTimeZone,
        siteDateTime,
        selectedCalendarDate: scheduleInDate,
        serverDateTime: siteDateTime.toISOString()
      });

      return (
        <View>
          <Text style={styles.text}>
            <Text>{`${t('start')}: `}</Text>
            <Text>{startTime}</Text>
          </Text>
          <Text style={styles.text}>
            <Text>{`${t('end')}: `}</Text>
            <Text>{endTime}</Text>
          </Text>
          {renderDeadlineOverdue}
        </View>
      );
    }
  }, [schedule, isDateAfter]);

  return (
    <Text style={[styles.text, fontSize ? { fontSize } : {}]} testID="time-schedule">
      {renderTimeStatus}
    </Text>
  );
};

const styles = StyleSheet.create({
  text: { fontSize: 12, ...fontMaker({ weight: 'SemiBold' }) },
  overdue: {
    color: theme.colors.danger,
    ...fontMaker({ weight: 'SemiBold' })
  }
});

export default RenderTimeSchedule;
