import { action, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';
import { ThunkResult } from '../../typing/types';
import Geolocation from 'react-native-geolocation-service';
import OldGeolocation from '@react-native-community/geolocation';
import moment from 'moment';

import { isIOS } from '../../constants/Devices';

export const SET_CACHE_LOCATION = '@geolocation/SET_CACHE_LOCATION';
export const RESET = '@geolocation/RESET';

export type CurrentLocation = {
  mocked: boolean;
  coords: { latitude: number; longitude: number; altitude: number; speed: number };
  timestamp: number;
  isFetched: boolean;
};

/**
 * Sets the Cache Location
 * @param location
 */
export const setCacheLocation = (location: CurrentLocation) => action(SET_CACHE_LOCATION, { location });

/**
 * Reset (clear) Cache Location data
 */
const reset = () => action(RESET);

export const geolocationActions = { setCacheLocation, reset };

export type GeolocationAction = ActionType<typeof geolocationActions>;
export type GeolocationState = {
  readonly cacheLocation: CurrentLocation;
};

const initialState: GeolocationState = {
  cacheLocation: {
    mocked: false,
    coords: { latitude: 0, longitude: 0, altitude: 0, speed: -1 },
    timestamp: moment().valueOf(),
    isFetched: false
  }
};

/**
 * Store the location from geolocation to the redux state
 */
export const updateCacheLocation = (): ThunkResult => (dispatch, getState) => {
  let _watchIDNetwork: any;
  let _watchIDGPS: any;

  Geolocation.getCurrentPosition(
    (data: any) => {
      const altitude = data.coords.altitude ? data.coords.altitude : 0;
      const speed = data.coords.speed ? data.coords.speed : -1;
      const location: CurrentLocation = {
        mocked: data && data.mocked ? data.mocked : false,
        coords: { latitude: data.coords.latitude, longitude: data.coords.longitude, altitude, speed },
        timestamp: data.timestamp,
        isFetched: true
      };
      dispatch(setCacheLocation(location));
    },
    (error: any) => {
      if (error.code === 1) {
        // PERMISSION_DENIED
        // if (isIOS()) {
        //   Geolocation.requestAuthorization('whenInUse').then();
        // }
      } else if (error.code === 3 || error.code === 5) {
        // TIMEOUT or SETTINGS_NOT_SATISFIED
        // Fallback to android.location API if react-native-geolocation-service fails for some reason
        // Timeout does not work, so we build our own.
        // TODO: Check this implementation with hooks
        Geolocation.getCurrentPosition(
          (data: any) => {
            const altitude = data.coords.altitude ? data.coords.altitude : 0;
            const speed = data.coords.speed ? data.coords.speed : -1;
            const location: CurrentLocation = {
              mocked: data && data.mocked ? data.mocked : false,
              coords: { latitude: data.coords.latitude, longitude: data.coords.longitude, altitude, speed },
              timestamp: data.timestamp,
              isFetched: true
            };
            dispatch(setCacheLocation(location));
          },
          (error1: any) => {
            if (error1.code === 1) {
              // PERMISSION_DENIED
              if (isIOS()) {
                Geolocation.requestAuthorization('whenInUse').then();
              }
            } else if (error1.code === 3 || error1.code === 5) {
              const timeout = setTimeout(() => {
                OldGeolocation.clearWatch(_watchIDNetwork!);
                OldGeolocation.clearWatch(_watchIDGPS!);
                dispatch(reset());
              }, 7500);
              _watchIDNetwork = OldGeolocation.watchPosition(
                (data: any) => {
                  clearTimeout(timeout);
                  OldGeolocation.clearWatch(_watchIDNetwork!);
                  OldGeolocation.clearWatch(_watchIDGPS!);

                  const altitude = data.coords.altitude ? data.coords.altitude : 0;
                  const speed = data.coords.speed ? data.coords.speed : -1;
                  const location: CurrentLocation = {
                    mocked: data && data.mocked ? data.mocked : false,
                    coords: { latitude: data.coords.latitude, longitude: data.coords.longitude, altitude, speed },
                    timestamp: data.timestamp,
                    isFetched: true
                  };
                  dispatch(setCacheLocation(location));
                },
                (err: any) => {
                  OldGeolocation.clearWatch(_watchIDNetwork!);
                  OldGeolocation.clearWatch(_watchIDGPS!);
                },
                {
                  maximumAge: 1,
                  enableHighAccuracy: false
                }
              );

              _watchIDGPS = OldGeolocation.watchPosition(
                (data: any) => {
                  clearTimeout(timeout);
                  OldGeolocation.clearWatch(_watchIDNetwork!);
                  OldGeolocation.clearWatch(_watchIDGPS!);

                  const altitude = data.coords.altitude ? data.coords.altitude : 0;
                  const speed = data.coords.speed ? data.coords.speed : -1;
                  const location: CurrentLocation = {
                    mocked: data && data.mocked ? data.mocked : false,
                    coords: { latitude: data.coords.latitude, longitude: data.coords.longitude, altitude, speed },
                    timestamp: data.timestamp,
                    isFetched: true
                  };
                  dispatch(setCacheLocation(location));
                },
                (err: any) => {
                  OldGeolocation.clearWatch(_watchIDNetwork!);
                  OldGeolocation.clearWatch(_watchIDGPS!);
                },
                {
                  maximumAge: 1,
                  enableHighAccuracy: false
                }
              );
            }
          },
          {
            timeout: 7500,
            maximumAge: 1,
            enableHighAccuracy: false
          }
        );
      }
    },
    {
      timeout: 7500,
      maximumAge: 1,
      enableHighAccuracy: true
    }
  );
};
export const geolocationThunk = { updateCacheLocation };

export const geolocationReducer: Reducer<GeolocationState, GeolocationAction> = (
  state = initialState,
  incomingAction
) => {
  switch (incomingAction.type) {
    case SET_CACHE_LOCATION: {
      const data = incomingAction.payload.location;
      const altitude = data.coords.altitude ? data.coords.altitude : 0;
      const speed = data.coords.speed ? data.coords.speed : -1;
      const location: CurrentLocation = {
        mocked: data && data.mocked ? data.mocked : false,
        coords: { latitude: data.coords.latitude, longitude: data.coords.longitude, altitude, speed },
        timestamp: data.timestamp,
        isFetched: true
      };
      return {
        ...state,
        cacheLocation: location
      };
    }
    case RESET:
      return state;
    default:
      return state;
  }
};
