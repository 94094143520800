import React from 'react';
import {
  StyleSheet,
  View,
  Platform,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableWithoutFeedbackProps,
  TextInput
} from 'react-native';
import Text from '../global/Text';
import { QuestionnaireQuestionProps } from './QuestionnaireQuestion';
import { questionTextInvalid } from '../../utils/report';
import theme from '../../constants/theme';
import { useTranslation } from 'react-i18next';
export type QuestionnaireQuestionMultipleChoiceProps = Pick<
  QuestionnaireQuestionProps,
  | 'question'
  | 'questions'
  | 'index'
  | 'currentConditionIndex'
  | 'language'
  | 'commentInputRef'
  | 'currentQuestionIndex'
  | 'isTutorialMode'
  | 'handleSelectChoice'
  | 'handleTextInputFocused'
  | 'handleTextInputBlurred'
  | 'handleInputComment'
  | 'handleShowTutorialQuestionModal'
  | 'handleChangeTutorialQuestionType'
>;

export type QuestionnaireQuestionMultipleChoicePropsExtra = {
  styles: any;
};

const QuestionnaireQuestionMultipleChoice = (
  props: QuestionnaireQuestionMultipleChoiceProps & QuestionnaireQuestionMultipleChoicePropsExtra
) => {
  const { t } = useTranslation(['questionnaire', 'common']);
  React.useEffect(() => {
    if (props.isTutorialMode && props.index === props.currentQuestionIndex) {
      props.handleChangeTutorialQuestionType(props.question.type);
      props.handleShowTutorialQuestionModal(true);
    }
  }, [props.index, props.currentQuestionIndex]);

  React.useEffect(() => {
    setComment(props.question.comment);
  }, [props.question.answer]);

  // temporary fix to handle input flicker and moving cursor in RN 0.63.3
  // TODO: revert this once the bug is handled, or there is a permanent fix
  const [comment, setComment] = React.useState<string>(props.question.comment);
  const handleInputComment = (text: string) => {
    setComment(text);
    props.handleInputComment(props.index, text, props.currentConditionIndex!);
  };

  return (
    <View>
      <View style={props.styles.choicesList}>
        {props.question.multipleChoices.map((choice: string, choiceIndex: number) => (
          <Touchable
            key={`mcq-${props.index}-${choiceIndex}`}
            onPress={() => props.handleSelectChoice(props.index, choice, props.currentConditionIndex!)}
            testID={`multiple-choice-choices-${choiceIndex}`}
          >
            <View style={[props.styles.choice, props.question.answer === choice ? props.styles.choiceSelected : {}]}>
              <View style={props.styles.choiceFlag}>
                <Text
                  style={[
                    props.styles.choiceText,
                    props.question.answer === choice ? props.styles.choiceSelectedText : {}
                  ]}
                >
                  {/* eslint-disable-next-line id-blacklist */}
                  {String.fromCharCode(65 + choiceIndex)}
                </Text>
              </View>
              <Text
                style={[
                  props.styles.choiceText,
                  props.question.answer === choice ? props.styles.choiceSelectedText : {}
                ]}
              >
                {choice}
              </Text>
            </View>
          </Touchable>
        ))}
        {!props.question.answerRequired ? (
          <View style={props.styles.choicesList}>
            <Touchable
              onPress={() => props.handleSelectChoice(props.index, 'not-applicable', props.currentConditionIndex!)}
              testID="number-na"
            >
              <View
                style={
                  props.question.answer === 'not-applicable'
                    ? [props.styles.choice, props.styles.choiceSelected]
                    : props.styles.choice
                }
              >
                <View style={props.styles.choiceFlag}>
                  <View style={props.styles.noFlag} />
                </View>
                <Text
                  style={
                    props.question.answer === 'not-applicable'
                      ? [props.styles.choiceText, props.styles.choiceSelectedText]
                      : props.styles.choiceText
                  }
                >
                  N/A
                </Text>
              </View>
            </Touchable>
          </View>
        ) : null}
      </View>
      <View>
        <View
          style={[
            props.styles.openAnswerInputContainer,
            props.question.answer === 'not-applicable' && props.styles.commentDisabled,
            {
              borderColor: questionTextInvalid(props.question, t)
                ? theme.colors.secondary
                : props.question.answer &&
                  (props.question.answer === 'red-flag' || props.question.answer === 'yellow-flag') &&
                  !props.question.comment
                ? theme.colors.secondary
                : 'rgba(0, 0, 0, 0.05)'
            }
          ]}
        >
          <TextInput
            testID={'multiple-choice-comment'}
            style={props.styles.openCommentInput}
            maxLength={2500}
            multiline={true}
            numberOfLines={4}
            editable={props.question.answer !== 'not-applicable'}
            onChangeText={handleInputComment}
            value={comment}
            placeholder={
              props.question.answer === 'not-applicable'
                ? `${t('questionnaire:question.multipleChoice.notApplicable')}`
                : `${t('questionnaire:question.multipleChoice.comments')}${
                    props.question.answer === 'green-flag' ? t('questionnaire:question.multipleChoice.optional') : ''
                  }`
            }
            ref={props.commentInputRef}
            onFocus={() =>
              props.handleTextInputFocused(
                typeof props.currentConditionIndex! === 'number' ? props.currentConditionIndex! : props.index
              )
            }
            onBlur={() =>
              props.handleTextInputBlurred(
                typeof props.currentConditionIndex! === 'number' ? props.currentConditionIndex! : props.index
              )
            }
          />
        </View>
        {!questionTextInvalid(props.question, t) ? (
          <View style={props.styles.validationContainer}>
            <Text style={props.styles.textValidation}>&zwnj;</Text>
          </View>
        ) : (
          <View style={props.styles.validationContainer}>
            <Text style={props.styles.textValidation}> {questionTextInvalid(props.question, t)} </Text>
          </View>
        )}
      </View>
    </View>
  );
};

export default QuestionnaireQuestionMultipleChoice;

const styles = StyleSheet.create({});

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
  Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;
