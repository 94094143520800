import { getFirebase } from 'react-redux-firebase';
import { act } from 'react-test-renderer';
import { call, put } from 'redux-saga/effects';
import { NIMBLY_API_NPS_IS_ELIGIBLE, NIMBLY_API_NPS_SUBMITTED } from '../../../constants/api';
import * as actions from './nps.action';

export function* fetchNPSSaga() {
  try {
    const firebase = getFirebase();
    if (firebase.auth) {
      yield put(actions.setLoading(true));
      const token = (yield firebase.auth().currentUser!.getIdToken()) as string;
      const options = {
        method: 'GET',
        headers: {
          authorization: token
        }
      };

      const url = `${NIMBLY_API_NPS_IS_ELIGIBLE}`;
      const request = () => fetch(url, options);
      const response = yield call(request);

      if (response && response.status === 200) {
        const res = yield response.json();

        const data: { isEligible: boolean; reason: string } = res.data;
        yield put(actions.fetchNPSUserAsync.success(data));
        return;
      }

      const result = yield response.json();
      yield put(actions.fetchNPSUserAsync.failure({ error: result.message }));
      return;
    }
  } catch (err) {
    yield put(actions.fetchNPSUserAsync.failure({ error: 'fail to fetch nps user' }));
    return;
  }
}

export function* createNPSIsSubmittedSaga(action: { payload: { timeZone: string } }): Generator {
  const firebase = getFirebase();

  try {
    if (firebase.auth) {
      const idToken = (yield firebase.auth().currentUser!.getIdToken()) as string;
      const request = () =>
        fetch(`${NIMBLY_API_NPS_SUBMITTED}?timezone=${action.payload.timeZone}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            authorization: idToken
          }
        });

      const response = (yield call(request)) as any;
      if (response && response.status === 200) {
        yield put(actions.createNPSIsSubmittedAsync.success());
        return;
      }
    }
  } catch (error) {
    put(actions.createNPSIsSubmittedAsync.failure({ error: 'fail to set as submit nps user' }));
  }
}
