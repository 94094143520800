import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useTranslation } from 'react-i18next';

// utils
import Text from '../../../global/Text';
import theme from '../../../../constants/theme';
import fontMaker from '../../../../utils/font';

// type
import { OutletDetailProps } from './type';

const OutletDetail = (props: OutletDetailProps) => {
  const { isAllowCalibration, siteName, siteAddress, onCalibrateLocation, featureAccessGeo } = props;

  const { t } = useTranslation('site');

  return (
    <View style={styles.container} testID="outlet-detail">
      {/* {featureAccessGeo && isAllowCalibration ? (
        <TouchableOpacity
          style={styles.containerEditButton}
          onPress={() => onCalibrateLocation()}
          testID="outlet-detail-calibration"
        >
          <Text numberOfLines={2} style={styles.editButton}>
            {t('site:outlet.seeMap').toLowerCase()}
          </Text>
        </TouchableOpacity>
      ) : null} */}
      <Text style={[styles.text, styles.siteName]}>{siteName}</Text>
      <View style={styles.containerSiteAddress}>
        <View>
          <Text numberOfLines={3} style={styles.text}>
            {siteAddress}
          </Text>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 15,
    paddingHorizontal: 20,
    backgroundColor: '#ffffff',

    shadowColor: '#696969',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,

    elevation: 4
  },
  containerEditButton: {
    alignItems: 'flex-end'
  },
  editButton: {
    ...fontMaker({ weight: 'SemiBold' }),
    color: theme.colors.lightgreen
  },
  text: {
    fontSize: 12,
    lineHeight: 18,
    color: theme.styles.fontColor.other
  },
  siteName: {
    fontSize: 14,
    lineHeight: 19,
    color: theme.colors.dark,
    marginTop: 5,
    marginBottom: 12
  },
  containerSiteAddress: { width: '100%', flexDirection: 'row' }
});

export default OutletDetail;
