import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// redux
import * as siteReportActions from '../../../../store/reducers/siteReport/siteReport.action';

// types
import { RootState } from '../../../../store/reducers';
import { toastActions } from '../../../../store/reducers/toast';
import { Moment } from 'moment';
import * as Common from 'nimbly-common';
import { SelectedSite } from '../Outlet/type';

// components
import ReportsContainer from './ReportsContainer';

// utils
import { useTranslation } from 'react-i18next';

type ReportComponentProps = {
  selectedSite: SelectedSite;
};

const ReportsComponent = (props: ReportComponentProps) => {
  const dispatch = useDispatch();
  const { selectedSite } = props;
  const { t } = useTranslation('site');
  // redux state
  const organization = useSelector<RootState, Common.Organization | null | undefined>(
    state => state.organization.myOrganization
  );
  const selectedSiteKey = useSelector<RootState, string | null>(state => state.site.selectedSiteKey);
  const customStart = useSelector<RootState, Moment | null>(state => state.siteReport.customStart);
  const customEnd = useSelector<RootState, Moment | null>(state => state.siteReport.customEnd);
  const startDate = useSelector<RootState, Moment | null>(state => state.siteReport.startDate);
  const query = useSelector<RootState, string>(state => state.siteReport.filterQuery);
  const endDate = useSelector<RootState, Moment | null>(state => state.siteReport.endDate);
  const sortDirection = useSelector<RootState, 'asc' | 'desc'>(state => state.siteReport.sortDirection);
  const dateType = useSelector<RootState, string>(state => state.siteReport.dateType);
  const siteReports = useSelector<RootState, (Common.SiteReportSummary & { questionnaire: Common.Questionnaire })[]>(
    state => state.siteReport.reports
  );
  const fetchLoading = useSelector<RootState, boolean>(state => state.siteReport.siteReportLoading);
  const totalReports = useSelector<RootState, number>(state => state.siteReport.totalReports);
  const pageIndex = useSelector<RootState, number>(state => state.siteReport.pageIndex);
  const profileColor = useSelector((state: RootState) => state.profiletheme.color);
  const isConnected = useSelector<RootState, boolean>(state => state.network.isConnected);

  const displayOfflineMessage = (message: string) => {
    dispatch(toastActions.createToast(message, true));
  };

  useEffect(() => {
    if (!isConnected) {
      displayOfflineMessage(t('outlet.offlineInfo'));
    }
  }, [isConnected]);

  useEffect(() => {
    if (selectedSiteKey) {
      fetchSiteReports(true);
    }
  }, [selectedSiteKey, customStart, customEnd, dateType, startDate, endDate, sortDirection, query]);

  const fetchSiteReports = async (clearList: boolean) => {
    await dispatch(siteReportActions.fetchSiteReportsAsync.request({ siteID: selectedSiteKey!, clearList }));
  };

  return (
    <ReportsContainer
      siteReports={siteReports}
      refreshReports={fetchSiteReports}
      isRefreshLoading={fetchLoading}
      selectedSiteKey={selectedSiteKey}
      totalReports={totalReports}
      pageIndex={pageIndex}
      profileColor={profileColor}
      customStart={customStart}
      customEnd={customEnd}
      dateType={dateType}
      organization={organization}
      selectedSite={selectedSite}
    />
  );
};

export default ReportsComponent;
