import { createAsyncAction } from 'typesafe-actions';

//types
import { Activity } from 'nimbly-common';
import * as types from './activity.actionType';

export const logActivityAsync = createAsyncAction(
  types.LOG_ACTIVITY_REQUEST,
  types.LOG_ACTIVITY_SUCCESS,
  types.LOG_ACTIVITY_FAILED
)<Activity, undefined, Error>();
