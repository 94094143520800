import React from 'react';
import { connect } from 'react-redux';

import { Modal, Platform, StyleSheet, TouchableNativeFeedback, TouchableOpacity, View } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import theme from '../../constants/theme';
import Text from './Text';
import { RootState } from '../../store/reducers';
import { ConnectedDispatch } from '../../typing/types';
import { SCREEN_WIDTH } from '../../utils/screensize';

type SelectorPopupProps = {
  popUpVisible: boolean;
  onChangeSelected: (option: string) => void;
  onClosePopUp: () => void;
  onClickAddNew: () => void;
  selected: string;
  title: string;
  options: string[];
  cancelText: string;
  addNewText: string;
} & ConnectedDispatch;

export class SelectorPopup extends React.Component<SelectorPopupProps> {
  handleClosePopUp = () => this.props.onClosePopUp();
  handleClickAddNew = () => this.props.onClickAddNew();

  handleSelect = (option: string) => this.props.onChangeSelected(option);

  render() {
    const { selected, title, options, cancelText, addNewText } = this.props;
    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={this.props.popUpVisible}
        onRequestClose={this.handleClosePopUp}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalView}>
            <View style={styles.modalHeading}>
              <Text>{title}</Text>
            </View>
            {addNewText && this.handleClickAddNew && (
              <Touchable onPress={this.handleClickAddNew} style={styles.row} delayPressIn={0}>
                <View style={[styles.tabRow, styles.tabRowCenter]}>
                  <Text style={theme.styles.textSecondary}>{addNewText}</Text>
                </View>
              </Touchable>
            )}
            {options.map((option, i) => (
              <Touchable
                key={`option-${option}-${i}`}
                onPress={() => this.handleSelect(option)}
                style={styles.row}
                delayPressIn={0}
              >
                <View style={styles.tabRow}>
                  <Text>{option}</Text>
                  {option === selected ? <Icon name="check" size={16} color="#3cd070" /> : null}
                </View>
              </Touchable>
            ))}
            <Touchable onPress={this.handleClosePopUp} style={styles.row} delayPressIn={0}>
              <View style={[styles.tabRow, styles.tabRowCenter]}>
                <Text style={theme.styles.textSecondary}>{cancelText}</Text>
              </View>
            </Touchable>
          </View>
        </View>
      </Modal>
    );
  }
}

const connectedSelectorPopup = connect((state: RootState) => ({
  language: state.firebase.profile.language || 'en'
}))(SelectorPopup);

export default connectedSelectorPopup;

const Touchable: any = Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

const styles = StyleSheet.create({
  row: {
    width: '100%'
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  modalView: {
    alignItems: 'center',
    backgroundColor: '#fff',
    width: SCREEN_WIDTH > 768 ? '30%' : '90%',
    borderRadius: 5
  },
  modalHeading: {
    width: '100%',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 10,
    backgroundColor: 'rgba(27, 33, 51, 0.1)'
  },
  tabRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 15,
    paddingHorizontal: 10,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: 'rgba(27, 33, 51, 0.3)'
  },
  tabRowCenter: {
    justifyContent: 'center'
  }
});
