import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, Text } from 'react-native';

// types
import { ScheduleStatusEnum, ScheduleStatus } from '../../../utils/classes';

// constant
import theme, { colors } from '../../../constants/theme';
import fontMaker from '../../../utils/font';

export type ScheduleStatusProps = {
  status: Partial<ScheduleStatus>;
  isOverdue: boolean;
};

const ScheduleStatusComponent = (props: ScheduleStatusProps) => {
  const { t } = useTranslation('common');
  const { status, isOverdue } = props;

  const renderText = useMemo(() => {
    switch (status) {
      case ScheduleStatusEnum.ADHOC:
        return 'Ad hoc';
      case ScheduleStatusEnum.READY:
        return t('notStarted');
      case ScheduleStatusEnum.DRAFT:
        return t('inProgress');
      case ScheduleStatusEnum.PENDING_UPLOAD:
        return t('pending');
      case ScheduleStatusEnum.FINISHED_TODAY:
        return t('completed');
      case ScheduleStatusEnum.FINISHED_PERIOD:
        return t('completed');
      default: {
        if (isOverdue) {
          return t('overdue');
        }
        return t('notStarted');
      }
    }
  }, [status, isOverdue]);

  const backgroundColor = useMemo(() => {
    switch (status) {
      case 'ready':
        return theme.colors.secondarylightgray;
      case 'draft':
        return theme.colors.lightyellow;
      case 'pending-upload':
        return theme.colors.secondarylightpurple;
      case 'finished-today':
        return theme.colors.darkgreen;
      case 'finished-period':
        return theme.colors.darkgreen;
      case 'adhoc':
        return theme.colors.lightBlue;
      case 'overdue':
        return colors.semanticError;
      default:
        return theme.colors.secondarylightgray;
    }
  }, [status]);

  return (
    <View style={[styles.container, { backgroundColor: backgroundColor }]}>
      <Text
        style={[
          styles.text,
          backgroundColor === theme.colors.secondarylightpurple ||
          backgroundColor === theme.colors.lightBlue ||
          backgroundColor === theme.colors.darkgreen ||
          backgroundColor === colors.semanticError
            ? { color: '#fff' }
            : null
        ]}
      >
        {renderText}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',

    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
    paddingHorizontal: 10,
    paddingVertical: 5
  },
  text: {
    fontSize: 10,
    lineHeight: 14,
    color: '#25282B',
    ...fontMaker({ weight: 'SemiBold' })
  }
});

export default ScheduleStatusComponent;
