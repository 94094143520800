import React from 'react';
import { Animated, StyleSheet, View, TouchableWithoutFeedback } from 'react-native';

import { SCREEN_WIDTH, SCREEN_HEIGHT } from '../../utils/screensize';
import Text from '../global/Text';
import fontMaker from '../../utils/font';
import { ActiveLanguageName, LanguagesSupport } from '../../constants/Languages';
import { LandingContainerProps } from './LandingContainer';
import { useDispatch } from 'react-redux';
import { landingActions } from '../../store/reducers/landing';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const logoUrlwithText = require('../../assets/img/logo-nimbly-with-text.png');

type LandingHeaderProps = Pick<LandingContainerProps, 'language' | 'onLanguageChange'>;

const LandingHeader = (props: LandingHeaderProps) => {
  const dispatch = useDispatch();

  const handleShowLanguageSelectorModal = () => {
    dispatch(landingActions.setModalLanguageVisible(true));
    return;
  };

  return (
    <View style={styles.landingHeader}>
      <Animated.Image source={logoUrlwithText} style={[styles.logo]} />
      <View style={styles.languageContainer}>
        <TouchableWithoutFeedback onPress={() => handleShowLanguageSelectorModal()}>
          <View style={[styles.select]}>
            <Text allowFontScaling={false} style={[styles.languageText]}>
              {ActiveLanguageName(props.language)}
            </Text>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  landingHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  languageContainer: {
    flexDirection: 'row',
    marginTop: 30,
    marginRight: 15,
    alignSelf: 'flex-start',
    height: 33
  },
  select: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
    borderColor: '#fff',
    borderWidth: 1,
    padding: 8
  },
  selectCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#fff',
    borderWidth: 1,
    padding: 8
  },
  activeSelect: {
    backgroundColor: '#fff'
  },
  languageText: {
    paddingHorizontal: 10,
    height: 20,
    fontSize: 14,
    textAlign: 'center',
    color: '#fff'
  },
  activeText: {
    color: '#000'
  },
  logo: {
    resizeMode: 'contain',
    marginLeft: SCREEN_WIDTH * 0.04,
    marginTop: SCREEN_HEIGHT * 0.04,
    width: 73,
    height: 68
  }
});

export default LandingHeader;
