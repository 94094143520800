import { Question } from '../../../utils/classes';
// import RNFS from 'react-native-fs';

/**
 * Pre-fill and assign default answer to open-ended in open type question, and
 * detect for any invalid photos when questionnaire is resumed.
 *
 * @param questions List of questions (array)
 * @param handleInputAnswer Function to handle question input field
 */
async function retrieveInvalidPhotos(
  questions: Question[],
  handleInputAnswer: (index: number, text: string) => void
): Promise<{ invalidPhotos: number[][]; invalidPhotoCount: number; invalidConditionalPhotos: number[][][][] }> {
  let invalidPhotoCount = 0;
  const invalidPhotos: number[][] = [];
  const invalidConditionalPhotos: number[][][][] = [];

  for (let questionIndex = 0; questionIndex < questions.length; questionIndex++) {
    const question = questions[questionIndex];
    // Pre-fill and assign default answer to 'open-ended' in 'open' type questions
    if (question.type === 'open' && !question.answer) {
      handleInputAnswer(questionIndex, 'open-ended');
    }
    invalidPhotos.push([]);
    invalidConditionalPhotos.push([]);
    if (question.photos) {
      for (let photoIndex = 0; photoIndex < question.photos.length; photoIndex++) {
        const photoURI = question.photos[photoIndex];
        // const fileExists = await RNFS.exists(photoURI);
        // if (!fileExists) {
        //   invalidPhotos[questionIndex].push(photoIndex);
        //   invalidPhotoCount += 1;
        // }
      }
    }

    if (question.isConditional) {
      const conditionalQuestions = question.conditionalQuestion!.conditions;
      const conditionalAnswerKeys = Object.keys(conditionalQuestions || {});

      for (const [conditionalIndex, conditionalAnswerKey] of conditionalAnswerKeys.entries()) {
        if (conditionalQuestions[conditionalAnswerKey]) {
          const conditionalQuestion = conditionalQuestions[conditionalAnswerKey];
          invalidConditionalPhotos[questionIndex].push([]);
          for (let indexConditional = 0; indexConditional < conditionalQuestion.length; indexConditional++) {
            invalidConditionalPhotos[questionIndex][conditionalIndex].push([]);
            if (conditionalQuestion[indexConditional].photos) {
              const conditionalPhotos = conditionalQuestion[indexConditional].photos;
              for (
                let conditionalPhotoIndex = 0;
                conditionalPhotoIndex < conditionalPhotos.length;
                conditionalPhotoIndex++
              ) {
                const conditionalPhotoURI = conditionalPhotos[conditionalPhotoIndex];
                // const fileExists = await RNFS.exists(conditionalPhotoURI);

                // if (!fileExists) {
                //   invalidConditionalPhotos[questionIndex][conditionalPhotoIndex][conditionalIndex].push(
                //     conditionalPhotoIndex
                //   );
                //   invalidPhotoCount += 1;
                // }
              }
            }
          }
        }
      }
    }
  }

  return {
    invalidPhotos: invalidPhotos,
    invalidPhotoCount: invalidPhotoCount,
    invalidConditionalPhotos: invalidConditionalPhotos
  };
}

export default retrieveInvalidPhotos;
