import { getFirebase } from 'react-redux-firebase';
import { apiProdURL } from '../constants/api';
export const getLogos = async (org: string) => {
  try {
    const firebase = getFirebase();
    if (!firebase?.auth()?.currentUser) return;
    const authToken = (await firebase.auth().currentUser!.getIdToken()) as string;

    const options = {
      method: 'GET',
      headers: {
        Authorization: authToken
      }
    };

    const getQuestionairesIndexURL = `${apiProdURL}/organizations/${org}/branding-settings`;

    const request = await fetch(getQuestionairesIndexURL, options);
    const responseBody: any = (await request.json()) as any;

    if (responseBody?.data) {
      return responseBody?.data;
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
