import React, { useState, useEffect, useRef } from 'react';
import { useSelector, connect } from 'react-redux';
import firebase from 'firebase';
import { FirebaseReducer, useFirebase } from 'react-redux-firebase';
import AsyncStorage from '@react-native-community/async-storage';

import SetPasswordContainer from './SetPasswordContainer';
import { RootState } from '../../store/reducers';
import { User } from '../../utils/classes';
import { LanguageType } from '../../constants/Languages';
import { landingActions, setAuthMode } from '../../store/reducers/landing';
import { AUTH_MODE } from '../../constants/Constants';

export type SetPasswordFormInput = {
  /** Value of the first Password input field */
  password1: string;
  /** Value of the second Password input field */
  password2: string;
};

export type SetPasswordFormValidation = {
  /** True if email is valid */
  password1: boolean;
  /** True if password is valid */
  password2: boolean;
  /** Error text related to issues during setting password - empty if there are no issues */
  errorText: string;
};

const SetPassword = (props: any) => {
  /** The user's Firebase profile */
  const profile = useSelector<RootState, FirebaseReducer.Profile<User>>(state => state.firebase.profile);

  /** String code for the current app language set in Landing - takes either 'en' or 'id' */
  const language = useSelector<RootState, LanguageType>(state => state.landing.language);
  const authMode = useSelector<RootState, AUTH_MODE>(state => state.landing.authMode);
  const firebaseRedux = useFirebase();

  const [form, setForm] = useState<SetPasswordFormInput>({
    password1: '',
    password2: ''
  });
  const [formValidation, setFormValidation] = useState<SetPasswordFormValidation>({
    password1: true,
    password2: true,
    errorText: ''
  });
  const [_isBusy, setIsBusy] = useState<boolean>(false);
  const [_passwordSucess, setPasswordSuccess] = useState<boolean>(false);
  const [_sessionExpired, setSessionExpired] = useState<boolean>(false);

  // Log out if user tries to press back button or terminate the app
  // before they set their password
  const profileRef: any = useRef(profile);
  useEffect(() => {
    profileRef.current = profile;
  }, [profile]);
  useEffect(() => {
    return () => {
      if (profileRef.current.status === 'fresh') {
        handleAbort();
      }
    };
  }, []);

  const clearFields = () => {
    setForm({
      password1: '',
      password2: ''
    });
  };
  const isValidPassword = (password: string) => {
    const defaultValidation = {
      valid: true,
      errorText: ''
    };
    const hasLetterRegexExp = /^.*([A-Za-z]+).*$/;
    const hasDigitRegexExp = /^.*([0-9]+).*$/;
    if (password) {
      if (!hasLetterRegexExp.test(password)) {
        return { ...defaultValidation, valid: false, errorText: 'must be at least 1 letter' };
      } else if (!hasDigitRegexExp.test(password)) {
        return { ...defaultValidation, valid: false, errorText: 'must be at least 1 digit' };
      } else if (password.length < 6) {
        return { ...defaultValidation, valid: false, errorText: 'must be at least 6 character' };
      }
    }
    return defaultValidation;
  };
  const validateConfirmPassword = (password1: string, password2: string) => {
    const firstPassword = isValidPassword(password1);
    const secondPassword = isValidPassword(password2);

    if (password1 && password2 && password1 !== password2) {
      setFormValidation({
        password1: false,
        password2: false,
        errorText: 'password not match'
      });
    } else if (!firstPassword.valid) {
      setFormValidation(prevState => ({
        ...prevState,
        password1: firstPassword.valid,
        errorText: firstPassword.errorText
      }));
    } else if (!secondPassword.valid) {
      setFormValidation(prevState => ({
        ...prevState,
        password2: secondPassword.valid,
        errorText: secondPassword.errorText
      }));
    } else {
      setFormValidation({
        password1: true,
        password2: true,
        errorText: ''
      });
    }
  };
  const handlePassword1Change = (text: string) => {
    setForm((prevState: SetPasswordFormInput) => ({
      ...prevState,
      password1: text
    }));
  };

  const handlePassword2Change = (text: string) => {
    setForm((prevState: SetPasswordFormInput) => ({
      ...prevState,
      password2: text
    }));
  };
  const handleSubmitFormError = (error: any) => {
    if (error.code === 'auth/requires-recent-login') {
      const errorMessage =
        language === 'en'
          ? 'Session has expired.\nPlease go back to the sign up page'
          : 'Sesi telah berakhir.\nSilahkan kembali ke halaman pendaftaran';
      setFormValidation((prevState: SetPasswordFormValidation) => ({
        ...prevState,
        errorMessage
      }));
      setSessionExpired(true);
      setIsBusy(false);
    } else {
      setFormValidation((prevState: SetPasswordFormValidation) => ({
        ...prevState,
        errorMessage: error.message
      }));
      setIsBusy(false);
    }
  };

  const handleSubmitForm = async () => {
    try {
      if (form.password1 && form.password2) {
        // if using OTP to submit form
        if (authMode === AUTH_MODE.VERIFIED) {
          setIsBusy(true);
          const user = await firebase.auth().currentUser;
          await user!.updatePassword(form.password1);
          clearFields();
          setPasswordSuccess(true);
        } else {
          // if using Email Deeplink to submit form (need oobCode) and email
          // TODO: This is not best practice, need to find other solution.
          const email = await AsyncStorage.getItem('email');
          const oobCode = await AsyncStorage.getItem('oobCode');
          if (email && oobCode) {
            setIsBusy(true);
            await firebase.auth().confirmPasswordReset(oobCode, form.password1);
            await firebaseRedux.login({ email, password: form.password1 });
            clearFields();
            setPasswordSuccess(true);
          } else {
            setFormValidation(prevState => ({
              ...prevState,
              errorText: "There's something wrong with the link please try again."
            }));
          }
          await AsyncStorage.removeItem('oobCode');
        }
        props.dispatch(landingActions.setAuthMode(AUTH_MODE.LOGIN));
      }
    } catch (error) {
      handleSubmitFormError(error);
    }
  };

  const handleAbort = async () => {
    const user = await firebase.auth().currentUser;
    if (user) {
      await firebaseRedux.logout();
    }
    await AsyncStorage.clear();
    // CodePush.restartApp();
  };
  const navigateToLogin = async () => {
    await firebaseRedux.updateProfile({ status: 'active' });
    await firebaseRedux.logout();
    await AsyncStorage.clear();
    props.setAuthMode(AUTH_MODE.LOGIN);
    props.navigation.navigate('Landing');
  };
  useEffect(() => validateConfirmPassword(form.password1, form.password2), [form.password1, form.password2]);
  return (
    <SetPasswordContainer
      language={language}
      form={form}
      formValidation={formValidation}
      _isBusy={_isBusy}
      _passwordSucess={_passwordSucess}
      _sessionExpired={_sessionExpired}
      onPassword1Change={handlePassword1Change}
      onPassword2Change={handlePassword2Change}
      onSubmitForm={handleSubmitForm}
      navigateToLogin={navigateToLogin}
      onAbort={handleAbort}
    />
  );
};
const mapStateToProps = (state: RootState) => ({
  authMode: state.landing.authMode
});

const mapDispatchToProps = (dispatch: any) => ({
  setAuthMode: (authMode: AUTH_MODE) => dispatch(landingActions.setAuthMode(authMode))
});

export default connect(mapStateToProps, mapDispatchToProps)(SetPassword);
