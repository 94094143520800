import { createAsyncAction, createReducer, ActionType, createAction } from 'typesafe-actions';
import { put, call, select } from 'redux-saga/effects';
import firebase from 'firebase';

import { RootState } from '../../reducers';

// helpers
import BackgroundTimer from '../../../helpers/BackgroundTimer';
import { JourneySchedule as Journey, JourneyReport } from 'nimbly-common';

// constant
import { MONGO_API } from '../../../constants/api';

// utils
import i18n from 'i18next';

type CreateJourneyReportSagaPayload = {
  journeyID: string;
  reportDate: string;
};

type JourneyReportSummary = {
  [siteID: string]: { qIndex: string; questionnaireID: string }[];
};

export const FETCH_JOURNEY_REPORT_REQUEST = '@journey/FETCH_JOURNEY_REPORT_REQUEST';
export const FETCH_JOURNEY_REPORT_SUCCESS = '@journey/FETCH_JOURNEY_REPORT_SUCCESS';
export const FETCH_JOURNEY_REPORT_FAILURE = '@journey/FETCH_JOURNEY_REPORT_FAILURE';
export const CREATE_JOURNEY_REPORT_REQUEST = '@journey/CREATE_JOURNEY_REPORT_REQUEST';
export const CREATE_JOURNEY_REPORT_SUCCESS = '@journey/CREATE_JOURNEY_REPORT_SUCCESS';
export const CREATE_JOURNEY_REPORT_FAILURE = '@journey/CREATE_JOURNEY_REPORT_FAILURE';
export const UPDATE_JOURNEY_REPORT_REQUEST = '@journey/UPDATE_JOURNEY_REPORT_REQUEST';
export const UPDATE_JOURNEY_REPORT_SUCCESS = '@journey/UPDATE_JOURNEY_REPORT_SUCCESS';
export const UPDATE_JOURNEY_REPORT_FAILURE = '@journey/UPDATE_JOURNEY_REPORT_FAILURE';
export const GET_JOURNEY_REPORT_SUMMARY_REQUEST = '@journey/GET_JOURNEY_REPORT_SUMMARY_REQUEST';
export const GET_JOURNEY_REPORT_SUMMARY_SUCCESS = '@journey/GET_JOURNEY_REPORT_SUMMARY_SUCCESS';
export const GET_JOURNEY_REPORT_SUMMARY_FAILURE = '@journey/GET_JOURNEY_REPORT_SUMMARY_FAILURE';

export const SET_JOURNEY = '@journey/SET_JOURNEY';
export const SET_JOURNEY_REPORT = '@journey/SET_JOURNEY_REPORT';
export const SET_ONGOING_JOURNEY_REPORT_KEY = '@journey/SET_ONGOING_JOURNEY_REPORT_KEY';
export const SET_JOURNEY_ERROR = '@journey/SET_JOURNEY_ERROR';
export const SET_REPORT_SUMMARY = '@journey/SET_REPORT_SUMMARY';

export const createJourneyReportAsync = createAsyncAction(
  CREATE_JOURNEY_REPORT_REQUEST,
  CREATE_JOURNEY_REPORT_SUCCESS,
  CREATE_JOURNEY_REPORT_FAILURE
)<CreateJourneyReportSagaPayload, JourneyReport, string>();

export const updateJourneyReportAsync = createAsyncAction(
  UPDATE_JOURNEY_REPORT_REQUEST,
  UPDATE_JOURNEY_REPORT_SUCCESS,
  UPDATE_JOURNEY_REPORT_FAILURE
)<JourneyReport, JourneyReport | null, string>();

export const getJourneyReportSummaryAsync = createAsyncAction(
  GET_JOURNEY_REPORT_SUMMARY_REQUEST,
  GET_JOURNEY_REPORT_SUMMARY_SUCCESS,
  GET_JOURNEY_REPORT_SUMMARY_FAILURE
)<undefined, JourneyReportSummary, string>();

export const setJourney = createAction(SET_JOURNEY, (journey: Journey | null) => journey)();
export const setJourneyReport = createAction(
  SET_JOURNEY_REPORT,
  (journeyReport: JourneyReport | null) => journeyReport
)();
export const setOngoingJourneyReportKey = createAction(
  SET_ONGOING_JOURNEY_REPORT_KEY,
  (ongoingJourneyReportKey: string | null) => ongoingJourneyReportKey
)();
export const setJourneyError = createAction(SET_JOURNEY_ERROR, (error: string | null) => error)();
export const setReportSummary = createAction(SET_REPORT_SUMMARY, (summary: JourneyReportSummary) => summary)();

export const journeyActions = {
  createJourneyReportAsync,
  updateJourneyReportAsync,
  getJourneyReportSummaryAsync,
  setJourney,
  setJourneyReport,
  setOngoingJourneyReportKey,
  setJourneyError,
  setReportSummary
};

const api = MONGO_API;

export function* getJourneyReportSummarySaga(action: any): Generator {
  try {
    const stateSelector = (state: RootState) => state;
    const currentState: RootState = (yield select(stateSelector)) as RootState;

    const journey = currentState.journey.journey;
    const journeyReport = currentState.journey.journeyReport;
    const uid = currentState.firebase.auth.uid;
    const organizationID = currentState.firebase.profile.organization;

    const summary: JourneyReportSummary = {};

    if (journey) {
      const db = firebase.database();
      const reportData = yield db.ref(`/reportJourney/${organizationID}/${journey.code}`).once('value');

      const journeyReportSites = reportData!.val();

      if (journeyReportSites) {
        for (const site of Object.keys(journeyReportSites)) {
          for (const report of Object.keys(journeyReportSites[site])) {
            if (
              journeyReportSites[site][report].status === 'complete' &&
              journeyReportSites[site][report].auditor === uid &&
              journeyReportSites[site][report].journeyReportCode &&
              journeyReport?.code &&
              journeyReportSites[site][report].journeyReportCode === journeyReport?.code
            ) {
              if (!summary[site]) {
                summary[site] = [];
              }

              const siteKey = journeyReportSites[site][report].site;

              const qIndex = journeyReportSites[site][report].scheduleKey.split(siteKey)[1];
              const newQIndex = qIndex.substring(1, qIndex.length);
              const questionnaireID = journeyReportSites[site][report].questionnaire;

              summary[site].push({ qIndex: newQIndex, questionnaireID });
            }
          }
        }
      }
    }

    yield put(getJourneyReportSummaryAsync.success(summary));
  } catch (error) {
    yield put(getJourneyReportSummaryAsync.failure(error.message));
  }
}

export function* createJourneyReportSaga(action: { payload: CreateJourneyReportSagaPayload }): Generator {
  try {
    const reqBody = action.payload;
    const token = (yield firebase.auth().currentUser!.getIdToken()) as string;

    const request = () =>
      fetch(`${api}/journey/reports`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        },
        method: 'POST',
        body: JSON.stringify(reqBody)
      });
    const response: any = yield call(request);

    if (response.status !== 200) {
      throw new Error(i18n.t('saga:journey.create.error'));
    }

    const body = (yield response.json()) as any;
    const createdJourneyReport: JourneyReport = body.data;

    //start journey tracking and set ongoing journey report key
    BackgroundTimer.startJourneyTracking(createdJourneyReport.code);
    yield put(setOngoingJourneyReportKey(createdJourneyReport.code));
    yield put(createJourneyReportAsync.success(createdJourneyReport));
  } catch (err) {
    yield put(createJourneyReportAsync.failure(err.message));
  }
}

export function* updateJourneyReportSaga(action: { payload: JourneyReport }): Generator {
  try {
    const reqBody = action.payload;
    const token = (yield firebase.auth().currentUser!.getIdToken()) as string;

    const state: any = yield select();
    const journeyReport: JourneyReport = state.journey.journeyReport;
    const stopJourney = journeyReport.end !== reqBody.end;

    const request = () =>
      fetch(`${api}/journey/reports/${reqBody.code}`, {
        headers: {
          authorization: token
        },
        method: 'PUT',
        body: JSON.stringify(reqBody)
      });
    const response = (yield call(request)) as any;
    const body = (yield response.json()) as any;

    if (response.status !== 200) {
      throw new Error(i18n.t('saga:journey.update.error'));
    }

    //handle stop journey
    if (stopJourney) {
      //stop ongoing journey tracking and clear ongoingJourneyReportKey
      BackgroundTimer.stopJourneyTracking();

      if (reqBody.hasSubmitQuestionnaire) {
        // trigger create journey report
        try {
          yield firebase.database().ref('/queue/journeyReportCompleted').push(reqBody);
        } catch (error) {
          //
        }
      }

      // remove journey report on redux after success update to database
      yield put(setReportSummary({}));
      yield put(setJourneyReport(null));
      yield put(setOngoingJourneyReportKey(null));
      yield put(updateJourneyReportAsync.success(null));
      return;
    }

    yield put(updateJourneyReportAsync.success(body.data));
  } catch (err) {
    yield put(updateJourneyReportAsync.failure(err.message));
  }
}

export type JourneyAction = ActionType<typeof journeyActions>;
export type JourneyState = {
  journey: Journey | null; //selected journey
  journeyReport: JourneyReport | null; //selected journey report
  ongoingJourneyReportKey: string | null;
  isLoading: boolean;
  error: string | null;
  reportSummary: JourneyReportSummary;
};

const initialState: JourneyState = {
  journey: null,
  journeyReport: null,
  ongoingJourneyReportKey: null,
  isLoading: false,
  error: null,
  reportSummary: {}
};

export const journeyReducer = createReducer<JourneyState, JourneyAction>(initialState)
  .handleAction(createJourneyReportAsync.request, state => ({
    ...state,
    isLoading: true,
    error: null
  }))
  .handleAction(createJourneyReportAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload
  }))
  .handleAction(createJourneyReportAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    journeyReport: action.payload
  }))
  .handleAction(updateJourneyReportAsync.request, state => ({
    ...state,
    isLoading: true,
    error: null
  }))
  .handleAction(updateJourneyReportAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload
  }))
  .handleAction(updateJourneyReportAsync.success, (state, action) => ({
    ...state,
    journeyReport: action.payload,
    isLoading: false
  }))
  .handleAction(getJourneyReportSummaryAsync.request, state => ({
    ...state,
    reportSummary: {},
    isLoading: true,
    error: null
  }))
  .handleAction(getJourneyReportSummaryAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    reportSummary: {},
    error: action.payload
  }))
  .handleAction(getJourneyReportSummaryAsync.success, (state, action) => ({
    ...state,
    reportSummary: action.payload,
    isLoading: false
  }))
  .handleAction(setJourney, (state, action) => ({
    ...state,
    journey: action.payload
  }))
  .handleAction(setJourneyReport, (state, action) => ({
    ...state,
    journeyReport: action.payload
  }))
  .handleAction(setOngoingJourneyReportKey, (state, action) => ({
    ...state,
    ongoingJourneyReportKey: action.payload
  }))
  .handleAction(setJourneyError, (state, action) => ({
    ...state,
    error: action.payload
  }))
  .handleAction(setReportSummary, (state, action) => ({
    ...state,
    reportSummary: action.payload
  }));
