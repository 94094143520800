import moment from 'moment';
import firebase from 'firebase';

import {
  OrganizationSchedule,
  SiteSchedule,
  Site,
  ReportIndex,
  Schedule,
  AllQuestionnaireIndex,
  SiteQuestionnaireSchedule,
  QuestionnaireSchedule
} from './classes';
import { InjectedSite } from '../typing/types';

const isScheduleFromQuestionnaire = (
  site: Site | InjectedSite,
  siteQuestionnaireSchedules: SiteQuestionnaireSchedule | null
) => {
  let isQuestionnaireSchedule = false;
  if (!site.isMultiSite && siteQuestionnaireSchedules) {
    if (site.children) {
      const index = site.children.findIndex(child => !!siteQuestionnaireSchedules[child.assignedQuestionnaire]);
      isQuestionnaireSchedule = index > -1;
    }
  }
  return isQuestionnaireSchedule;
};

// Returns a date map showing how many questionnaires are due per day
const getQuestionnaireDateCount = (
  schedule: QuestionnaireSchedule,
  mappedDateCount: { [date: string]: number },
  // Start of period
  start: moment.Moment,
  // End of period - null means same day as start
  end: moment.Moment | null,
  untilEndOfMonth?: boolean
): { [date: string]: number } => {
  const newMappedDateCount = { ...mappedDateCount };
  const addMappedDates = (date: string): void => {
    if (newMappedDateCount[date] == null) {
      newMappedDateCount[date] = schedule.dayLimit || 1;
    } else {
      newMappedDateCount[date] += schedule.dayLimit || 1;
    }
  };
  switch (schedule.type) {
    case 'daily':
      if (end) {
        for (const m = start.clone(); m.isSameOrBefore(end, 'd'); m.add(1, 'day')) {
          const currentDate = m.format('YYYY-MM-DD');
          if (newMappedDateCount[currentDate] === undefined) {
            newMappedDateCount[currentDate] = schedule.dayLimit || 1;
          } else {
            newMappedDateCount[currentDate] += schedule.dayLimit || 1;
          }
        }
      } else {
        const currentDate = start.format('YYYY-MM-DD');
        if (newMappedDateCount[currentDate] === undefined) {
          newMappedDateCount[currentDate] = schedule.dayLimit || 1;
        } else {
          newMappedDateCount[currentDate] += schedule.dayLimit || 1;
        }
      }
      break;
    case 'weekly':
      if (!schedule.daysOfWeek || schedule.daysOfWeek.length === 0 || !end) {
        break;
      }
      const startDay = start.isoWeekday();
      const endDay = end.isoWeekday();
      if (untilEndOfMonth) {
        const mappedDay: { [d: number]: boolean } = {};
        for (const day of schedule.daysOfWeek) {
          mappedDay[day] = true;
        }
        for (const m = start.clone(); m.isSameOrBefore(end, 'd'); m.add(1, 'day')) {
          if (mappedDay[m.isoWeekday()]) {
            newMappedDateCount[m.format('YYYY-MM-DD')] = schedule.dayLimit || 1;
          }
        }
      } else {
        schedule.daysOfWeek.forEach(day => {
          if (day >= startDay && day <= endDay) {
            const stringDate = moment().isoWeekday(day).format('YYYY-MM-DD');
            addMappedDates(stringDate);
          }
        });
      }
      break;
    case 'monthly':
      if (!schedule.datesOfMonth || schedule.datesOfMonth.length === 0 || !end) {
        break;
      }
      const startDate = start.date();
      const endDate = end.date();
      schedule.datesOfMonth.forEach(date => {
        if (date >= startDate && date <= endDate) {
          const stringDate = moment().date(date).format('YYYY-MM-DD');
          addMappedDates(stringDate);
        }
      });
      break;
    case 'custom':
      if (!schedule.datesCustom || !end) {
        break;
      }
      Object.keys(schedule.datesCustom).forEach(stringDate => {
        const m = moment(stringDate);
        if (m.isSameOrAfter(start, 'd') && m.isSameOrBefore(end, 'd')) {
          addMappedDates(stringDate);
        }
      });
      break;
    default:
      break;
  }
  return newMappedDateCount;
};

// For manual organization schedules, returns the next due date for individual site as a string
function siteNextDueDate(
  orgSchedule: OrganizationSchedule,
  site: InjectedSite,
  siteQuestionnaireSchedules: SiteQuestionnaireSchedule | null,
  siteSchedule: SiteSchedule | Schedule | null | undefined,
  reportIndex: ReportIndex | null,
  uid: string,
  allQuestionnaireIndex?: AllQuestionnaireIndex
) {
  // const serverTime = firebase.database().getServerTime();
  // Subtract 4 hours from the now comparison, effectively allowing reports for day x to be done till x + 1 @ 4:00am
  const now: moment.Moment = moment().subtract(4, 'hours');
  const end: moment.Moment | null = currentPeriodEndDate(orgSchedule);

  const { isQuestionnaireSchedule } = site;

  let mappedDates: { [date: string]: number } = {};
  let dates: string[] = [];

  if (!isQuestionnaireSchedule) {
    dates = siteSchedule
      ? Object.keys(siteSchedule).filter(
          key =>
            key !== 'assignedAuditor' &&
            moment(key).isSameOrAfter(now, 'day') &&
            end &&
            moment(key).isSameOrBefore(end, 'day')
        )
      : [];
    dates.forEach(date => {
      if (mappedDates[date] === undefined) {
        mappedDates[date] = 1;
      } else {
        mappedDates[date]++;
      }
    });
  } else {
    if (site.children) {
      site.children.forEach(({ assignedQuestionnaire }) => {
        if (siteQuestionnaireSchedules![assignedQuestionnaire]) {
          const schedule = siteQuestionnaireSchedules![assignedQuestionnaire];
          mappedDates = getQuestionnaireDateCount(schedule, mappedDates, now, end);
        }
      });
    }
  }

  const datesCount: { date: string; count: number }[] = Object.keys(mappedDates)
    .map(date => ({
      date,
      count: mappedDates[date]
    }))
    .sort((a, b) => (a.date < b.date ? -1 : 1));

  const questionnaireKeys = Object.keys(siteQuestionnaireSchedules || {});
  const selectedQuestionnaireIndexKey = questionnaireKeys.length === 1 ? questionnaireKeys[0] : '';
  const questionnaireVersions: string[] | null =
    selectedQuestionnaireIndexKey && allQuestionnaireIndex && allQuestionnaireIndex[selectedQuestionnaireIndexKey]
      ? allQuestionnaireIndex[selectedQuestionnaireIndexKey].versions
      : null;

  const reportKeys = reportIndex ? Object.keys(reportIndex).sort() : [];
  for (const { date, count } of datesCount) {
    const filteredKeys = reportIndex
      ? reportKeys.filter(key => {
          return (
            key.includes(date) &&
            reportIndex[key].status === 'complete' &&
            reportIndex[key].status !== 'expired' &&
            // (!site.isMultiSite ? reportIndex[key].auditor === uid : true) &&
            !reportIndex[key].isAdhoc &&
            (questionnaireVersions
              ? reportIndex[key].questionnaire && questionnaireVersions.indexOf(reportIndex[key].questionnaire!) > -1
              : true)
          );
        })
      : [];

    const isAllDone = filteredKeys.length >= count;
    if (!isAllDone) {
      return date;
    }
  }
  return null;
}

// For manual organization schedules, returns array of dates if site has an undone report within period
// If true, it means that the undone report can be 'made up', or done now but with a red mark
function siteUndoneReportDates(
  orgSchedule: OrganizationSchedule,
  siteSchedule: SiteSchedule | Schedule | null | undefined,
  reportIndex: ReportIndex | null,
  site: Site | InjectedSite,
  siteQuestionnaireSchedules: SiteQuestionnaireSchedule | null,
  allQuestionnaireIndex?: AllQuestionnaireIndex
): string[] | null {
  // const serverTime = firebase.database().getServerTime();

  const start: moment.Moment = currentPeriodStartDate(orgSchedule) || moment();
  // Subtract 4 hours from the now comparison, effectively allowing reports for day x to be done till x + 1 @ 4:00am
  const now: moment.Moment = moment().subtract(4, 'hours');

  const isQuestionnaireSchedule = (site as InjectedSite).isQuestionnaireSchedule;
  let dates: string[] = [];
  let mappedDates: { [date: string]: number } = {};
  const reportKeys = reportIndex ? Object.keys(reportIndex) : [];
  if (!isQuestionnaireSchedule) {
    dates = siteSchedule
      ? Object.keys(siteSchedule).filter(
          date =>
            date !== 'assignedAuditor' &&
            moment(date).isSameOrAfter(start, 'day') &&
            moment(date).isBefore(now, 'day') &&
            reportKeys.findIndex(key => key.includes(date)) === -1
        )
      : [];
  } else {
    if (site.children) {
      site.children.forEach(({ assignedQuestionnaire }) => {
        if (siteQuestionnaireSchedules) {
          const schedule = siteQuestionnaireSchedules[assignedQuestionnaire];
          if (schedule) {
            mappedDates = getQuestionnaireDateCount(schedule, mappedDates, start, now);
          }
        }
      });
    }

    if (isQuestionnaireSchedule) {
      const questionnaireKeys = Object.keys(siteQuestionnaireSchedules || {});
      const selectedQuestionnaireIndexKey = questionnaireKeys.length === 1 ? questionnaireKeys[0] : '';
      const questionnaireVersions: string[] | null =
        selectedQuestionnaireIndexKey && allQuestionnaireIndex && allQuestionnaireIndex[selectedQuestionnaireIndexKey]
          ? allQuestionnaireIndex[selectedQuestionnaireIndexKey].versions
          : null;

      dates = Object.keys(mappedDates).filter(date => {
        const count = mappedDates[date];
        const isAllDone =
          reportKeys.filter(key => {
            let isQuestionnaireMatch = !questionnaireVersions;
            if (questionnaireVersions && reportIndex) {
              isQuestionnaireMatch = questionnaireVersions.includes(reportIndex[key].questionnaire!);
            }
            return (
              key.includes(date) &&
              reportIndex &&
              reportIndex[key].status === 'complete' &&
              !reportIndex[key].isAdhoc &&
              isQuestionnaireMatch
            );
          }).length >= count;
        const dateMoment = moment(date);
        return dateMoment.isSameOrAfter(start, 'day') && dateMoment.isBefore(now, 'day') && !isAllDone;
      });
    }
  }
  dates.sort();
  return dates;
}

// Returns the current period's start date as a moment object
function currentPeriodStartDate(orgSchedule: OrganizationSchedule): moment.Moment | null {
  // const serverTime = firebase.database().getServerTime();
  const now = moment();
  let start: moment.Moment;
  if (orgSchedule && orgSchedule.periodStart) {
    switch (orgSchedule.periodUnit) {
      case 'week':
        start =
          now.isoWeekday() >= orgSchedule.periodStart
            ? now.startOf('isoWeek')
            : now.subtract(1, 'week').startOf('isoWeek');
        return start.isoWeekday(orgSchedule.periodStart);
      case 'month':
        start =
          now.date() >= orgSchedule.periodStart ? now.startOf('month') : now.subtract(1, 'month').startOf('month');
        return start.date(orgSchedule.periodStart);
      case 'year':
        start = now.date() >= orgSchedule.periodStart ? now.startOf('year') : now.subtract(1, 'year').startOf('year');
        return start.date(orgSchedule.periodStart);
      default:
        return null;
    }
  }
  return null;
}

// Returns the current period's end date as a moment object
function currentPeriodEndDate(
  orgSchedule: OrganizationSchedule,
  initStart?: moment.Moment | null
): moment.Moment | null {
  // const serverTime = firebase.database().getServerTime();

  const start: moment.Moment = (initStart && initStart.clone()) || currentPeriodStartDate(orgSchedule) || moment();
  if (orgSchedule) {
    switch (orgSchedule.periodUnit) {
      case 'week':
        return start.add(7, 'days').subtract(1, 'ms');
      case 'month':
        return start.add(start.daysInMonth(), 'days').subtract(1, 'ms');
      case 'year':
        return start.add(1, 'year').subtract(1, 'ms');
      default:
        return null;
    }
  }
  return null;
}

// Returns the previous period's end date as a moment object
function previousPeriodEndDate(orgSchedule: OrganizationSchedule): moment.Moment | null {
  const start: moment.Moment | null = currentPeriodStartDate(orgSchedule);
  return start ? start.subtract(1, 'ms') : null;
}

function reportsDueThisPeriod(
  orgSchedule: OrganizationSchedule,
  siteSchedule: SiteSchedule | Schedule | null | undefined,
  site: Site,
  siteQuestionnaireSchedules: SiteQuestionnaireSchedule | null
): number {
  // const serverTime = firebase.database().getServerTime();
  const startDate: moment.Moment | null = currentPeriodStartDate(orgSchedule) || moment();
  const startDateString: string = startDate ? startDate.format('YYYY-MM-DD') : '';
  const endDate: moment.Moment | null = currentPeriodEndDate(orgSchedule);
  const endDateString: string = endDate ? endDate.format('YYYY-MM-DD') : '';

  const isQuestionnaireSchedule = isScheduleFromQuestionnaire(site, siteQuestionnaireSchedules);

  if (!isQuestionnaireSchedule) {
    return siteSchedule
      ? Object.keys(siteSchedule).filter(
          date => date !== 'assignedAuditor' && date >= startDateString && date <= endDateString
        ).length
      : 0;
  } else {
    let mappedDates: { [date: string]: number } = {};
    if (site.children) {
      site.children.forEach(({ assignedQuestionnaire }) => {
        const schedule = siteQuestionnaireSchedules![assignedQuestionnaire];
        if (schedule) {
          mappedDates = getQuestionnaireDateCount(schedule, mappedDates, startDate, endDate);
        }
      });
    }
    const count = Object.values(mappedDates).reduce((sum, current) => sum + current, 0);
    return count;
  }
}

// Takes a report index and Schedule
// Returns number of completed reports in the period (0 means fully completed)
function reportsCompletedInPeriod(
  orgSchedule: OrganizationSchedule,
  reportIndex: ReportIndex | null,
  siteQuestionnaireSchedules?: SiteQuestionnaireSchedule | null,
  allQuestionnaireIndex?: AllQuestionnaireIndex
): number {
  if (!reportIndex) {
    return 0;
  }

  // Filter the reports by completed
  const filteredReportIndex = Object.assign({}, reportIndex);
  Object.keys(filteredReportIndex).forEach(reportKey => {
    if (filteredReportIndex[reportKey].status !== 'complete' || filteredReportIndex[reportKey].isAdhoc) {
      delete filteredReportIndex[reportKey];
    }
  });

  // Get the current period's dates
  const periodStartDate: moment.Moment | null = currentPeriodStartDate(orgSchedule);
  const periodEndDate: moment.Moment | null = currentPeriodEndDate(orgSchedule);

  const questionnaireKeys = Object.keys(siteQuestionnaireSchedules || {});
  const selectedQuestionnaireIndexKey = questionnaireKeys.length === 1 ? questionnaireKeys[0] : '';
  const questionnaireVersions: string[] | null =
    selectedQuestionnaireIndexKey && allQuestionnaireIndex && allQuestionnaireIndex[selectedQuestionnaireIndexKey]
      ? allQuestionnaireIndex[selectedQuestionnaireIndexKey].versions
      : null;

  // Count number of completed reports in the period
  let count = 0;
  if (periodStartDate && periodEndDate) {
    const periodStartDateString: string = periodStartDate.toISOString(true).slice(0, 10);
    const periodEndDateString: string = periodEndDate.toISOString(true).slice(0, 10);
    Object.keys(filteredReportIndex).forEach((reportKey: string) => {
      const date = reportKey.split('_')[0];
      if (date >= periodStartDateString && date <= periodEndDateString) {
        if (questionnaireVersions && reportIndex[reportKey].questionnaire) {
          if (questionnaireVersions.indexOf(reportIndex[reportKey].questionnaire!) > -1) {
            count++;
          }
        } else {
          count++;
        }
      }
    });
  }

  return count;
}

function reportsDueToday(
  site: InjectedSite,
  siteSchedules: SiteSchedule | Schedule | null,
  siteQuestionnaireSchedules: SiteQuestionnaireSchedule | null,
  uid: string
): number {
  // const serverTime = firebase.database().getServerTime();
  const todayMoment = moment().subtract(4, 'hours');
  const todayString = todayMoment.format('YYYY-MM-DD');
  const isoWeekday = todayMoment.isoWeekday();
  const date = todayMoment.date();
  const { isQuestionnaireSchedule, isMultiSite } = site;
  if (isMultiSite) {
    if (!siteSchedules || !siteSchedules[todayString]) {
      return 0;
    }
    return 1;
  }
  if (!isQuestionnaireSchedule) {
    if (!siteSchedules || !siteSchedules[todayString]) {
      return 0;
    }
    return 1;
  }
  if (!siteQuestionnaireSchedules) {
    return 0;
  }
  let count = 0;
  for (const questionnaireKey of Object.keys(siteQuestionnaireSchedules)) {
    const questionnaireSchedule = siteQuestionnaireSchedules[questionnaireKey];
    const { dayLimit, assignedAuditor, type, datesCustom, datesOfMonth, daysOfWeek } = questionnaireSchedule;
    if (assignedAuditor && assignedAuditor !== uid) {
      continue;
    }
    switch (type) {
      case 'daily':
        count += dayLimit || 1;
        break;
      case 'weekly':
        if (daysOfWeek && daysOfWeek.indexOf(isoWeekday) > -1) {
          count += dayLimit || 1;
        }
        break;
      case 'monthly':
        if (datesOfMonth && datesOfMonth.indexOf(date) > -1) {
          count += 1;
        }
        break;
      case 'custom':
        if (datesCustom && datesCustom[todayString]) {
          count += 1;
        }
      default:
        break;
    }
  }

  return count;
}

function reportsDoneToday(
  reportIndex: ReportIndex | null,
  siteQuestionnaireSchedules: SiteQuestionnaireSchedule | null,
  allQuestionnaireIndex?: AllQuestionnaireIndex
): number {
  if (!reportIndex) {
    return 0;
  }

  // const serverTime = firebase.database().getServerTime();
  const todayMoment = moment().subtract(4, 'hours');
  const todayString = todayMoment.format('YYYY-MM-DD');

  const questionnaireKeys = Object.keys(siteQuestionnaireSchedules || {});
  const selectedQuestionnaireIndexKey = questionnaireKeys.length === 1 ? questionnaireKeys[0] : '';
  const questionnaireVersions: string[] | null =
    selectedQuestionnaireIndexKey && allQuestionnaireIndex && allQuestionnaireIndex[selectedQuestionnaireIndexKey]
      ? allQuestionnaireIndex[selectedQuestionnaireIndexKey].versions
      : null;

  let count = 0;
  for (const reportKey of Object.keys(reportIndex)) {
    if (
      reportKey.indexOf(todayString) === -1 ||
      reportIndex[reportKey].isAdhoc ||
      reportIndex[reportKey].status !== 'complete'
    ) {
      continue;
    }
    if (questionnaireVersions && reportIndex[reportKey].questionnaire) {
      if (questionnaireVersions.indexOf(reportIndex[reportKey].questionnaire!) > -1) {
        count++;
      }
    } else {
      count++;
    }
  }
  return count;
}

const GetOrgSchedulePeriodUnit = (orgSchedule: OrganizationSchedule): string => {
  if (orgSchedule?.periodUnit) {
    switch (orgSchedule?.periodUnit) {
      case 'week':
        return 'home:progress.thisWeek';
      case 'month':
        return 'home:progress.thisMonth';
      case 'year':
        return 'home:progress.thisYear';
      default:
        return 'home:progress.thisWeek';
    }
  }
  return 'home:progress.thisWeek';
};

export {
  siteNextDueDate,
  siteUndoneReportDates,
  currentPeriodStartDate,
  currentPeriodEndDate,
  previousPeriodEndDate,
  reportsDueThisPeriod,
  reportsCompletedInPeriod,
  isScheduleFromQuestionnaire,
  getQuestionnaireDateCount,
  reportsDueToday,
  reportsDoneToday,
  GetOrgSchedulePeriodUnit
};
