import { useIsFocused } from '@react-navigation/native';
import React, { useEffect, useRef, useState } from 'react';
// import { FirebaseDynamicLinksTypes } from '@react-native-firebase/dynamic-links';
import { useTranslation } from 'react-i18next';
import { Alert, Animated, Keyboard } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { FirebaseReducer, getVal, useFirebase } from 'react-redux-firebase';
import { AUTH_MODE } from '../../constants/Constants';
import { LanguageType } from '../../constants/Languages';
import I18n from '../../i18n';
import { LandingPageProps } from '../../routes/landing';
import { RootState } from '../../store/reducers';
import { accountActions, DeviceProfile } from '../../store/reducers/account/account';
import { landingActions } from '../../store/reducers/landing';
import { toastActions } from '../../store/reducers/toast';
import { User } from '../../utils/classes';
import { ModalConfig } from '../global/ConfirmModal';
import LandingContainer from './LandingContainer';
import checkForUpdates from './utils/checkForUpdates';
// import getInitialUrl from './utils/getInitialURL';
import saveSignaturePlaceholder from './utils/saveSignaturePlaceholder';

type LandingProps = {
  onLogout: () => void;
} & LandingPageProps;

// type DynamicLink = FirebaseDynamicLinksTypes.DynamicLink;
/**
 * Landing is used as a launchpad to either show the Auth component or fast-forward the user to the Home page.
 *
 * It will show a loading placeholder till Firebase auth is initialized and loaded, after which it checks for a user.
 *
 * It also catches some other use-cases:
 * - user opens app from dynamic link
 * - user currently logged in on another device
 * - sets app language based on user locale
 */
const Landing = (props: LandingProps) => {
  const auth = useSelector<RootState, FirebaseReducer.AuthState>(state => state.firebase.auth);
  const profile = useSelector<RootState, FirebaseReducer.Profile<User>>(state => state.firebase.profile);
  const profileProcessed = useSelector<RootState, boolean>(state => state.account.profileProcessed);
  const authMode = useSelector<RootState, AUTH_MODE>(state => state.landing.authMode);
  const language = useSelector<RootState, LanguageType>(state =>
    state.firebase.profile && state.firebase.profile.isLoaded && !state.firebase.profile.isEmpty
      ? state.firebase.profile.language
      : state.landing.language
  );
  const latestVersion = useSelector<RootState, string>(state => getVal(state.firebase.data, '/version'));
  const deviceProfile = useSelector<RootState, DeviceProfile>(state => state.account.deviceProfile!);
  const hasNotch = useSelector<RootState, boolean>(state => state.account.hasNotch);
  const uuid = useSelector<RootState, string>(state => state.account.uuid);
  const isLoadingRole = useSelector<RootState, boolean>(state => state.account.isLoadingRole);
  const errorMessageRole = useSelector<RootState, string | null>(state => state.account.errorMessageRole);
  const isFocused = useIsFocused();
  const firebaseRedux = useFirebase();
  const dispatch = useDispatch();
  const { t } = useTranslation(['landing', 'profile']);

  const [keyboardHeight] = useState<Animated.Value>(new Animated.Value(0));
  const [_isLoading, setIsLoading] = useState<boolean>(false);
  const [_showModal, setShowModal] = useState<boolean>(false);
  const [_modalConfig, setModalConfig] = useState<ModalConfig>({
    title: '',
    subtitle: '',
    options: [{ text: t('landing:modal.buttonCancel'), action: () => null, style: 'cancel' }]
  });
  const [_isTimedOut, setIsTimedOut] = useState<boolean>(false);

  let authTimeout: any;
  let keyboardWillShowListener: any;
  let keyboardWillHideListener: any;
  let dynamicLinkUnsubscribe: any;

  // Keyboard listeners
  useEffect(() => {
    keyboardWillShowListener = Keyboard.addListener('keyboardWillShow', handleKeyboardWillShow);
    keyboardWillHideListener = Keyboard.addListener('keyboardWillHide', handleKeyboardWillHide);
    return () => {
      keyboardWillShowListener.remove();
      keyboardWillHideListener.remove();
    };
  }, []);

  const handleKeyboardWillShow = (event: any) => {
    Animated.parallel([
      Animated.timing(keyboardHeight, {
        duration: event.duration,
        toValue: event.endCoordinates.height,
        useNativeDriver: true
      })
    ]).start();
  };

  const handleKeyboardWillHide = (event: any) => {
    Animated.parallel([
      Animated.timing(keyboardHeight, {
        duration: event.duration,
        toValue: 0,
        useNativeDriver: true
      })
    ]).start();
  };

  // Check if the app was opened from a dynamic link - if so, attempt to sign in using the dynamic link

  // Sets app language based on locale detected by react-native-device-info
  useEffect(() => {
    if (deviceProfile && deviceProfile.locale.includes('en')) {
      dispatch(landingActions.setLanguage('en'));
      I18n.changeLanguage('en');
    }
  }, []);

  const handleLanguageChange = (lang: LanguageType) => {
    dispatch(landingActions.setLanguage(lang));
    I18n.changeLanguage(lang);
  };

  // Start a timer for 10 seconds - if auth gets stuck in an infinite load, run handleAuthTimeout()
  useEffect(() => {
    authTimeout = setTimeout(handleAuthTimeout, 10000);
    return () => {
      clearTimeout(authTimeout);
    };
  }, []);

  const authLoadedRef = useRef(auth.isLoaded);
  authLoadedRef.current = auth.isLoaded;
  const handleAuthTimeout = () => {
    if (!authLoadedRef.current) {
      setIsTimedOut(true);
      dispatch(toastActions.createToast(t('landing:toast.concurrent'), false, 5000));
    }
    clearTimeout(authTimeout);
  };

  const checkConcurrentLogin = async () => {
    if (profile.status === 'fresh') {
      processProfile();
    }

    if (profile.uuid && profile.uuid !== uuid) {
      const concurrentModalConfig: ModalConfig = {
        title: t('landing:modal.title'),
        subtitle: t('landing:modal.message'),
        options: [
          {
            text: t('landing:modal.buttonOK'),
            action: () => {
              setShowModal((prevState: boolean) => !prevState);
              processProfile();
            },
            style: 'confirm'
          },
          {
            text: t('landing:modal.buttonCancel'),
            action: () => {
              firebaseRedux.logout().then(() => {
                props.onLogout();
              });
              setShowModal((prevState: boolean) => !prevState);
            },
            style: 'cancel'
          }
        ]
      };
      setModalConfig(concurrentModalConfig);
      setShowModal(true);
    } else {
      processProfile();
    }
  };

  const processProfile = async () => {
    // Set dummy account status based on auth.uid
    dispatch(accountActions.setDummyState(auth.uid));
    // Retrieve user role

    // Save selected language to profile
    if (!profile.language || profile.language !== language) {
      firebaseRedux.updateProfile({ language });
    }

    // Save device info to profile
    firebaseRedux.updateProfile({ deviceInfo: deviceProfile, uuid });

    navigateForward();
  };

  /**
   * Navigates forward to SetPassword only if the user profile is fresh
   * For existing accounts, the flow is handled in the App component
   */
  const navigateForward = () => {
    // profile.status active email-sent
    if (profile.status === 'fresh' && (authMode === AUTH_MODE.VERIFIED || authMode === AUTH_MODE.EMAIL_SENT)) {
      props.navigation.navigate('SetPassword');
      return;
    }

    if (authMode === AUTH_MODE.VERIFY_OTP) {
      return;
    }

    if (profile.status !== 'active') {
      return;
    }
    dispatch(accountActions.setProfileProcessed());
  };

  useEffect(() => {
    if (profile.status === 'fresh' && authMode === AUTH_MODE.VERIFIED) {
      props.navigation.navigate('SetPassword');
      return;
    }

    if (profile.status === 'fresh' && auth.isLoaded && !profile.isEmpty) {
      processProfile();
      return;
    }

    if (profile.isLoaded && !profile.isEmpty && !profileProcessed && auth.isLoaded) {
      setIsLoading(true);
      if (profile.status !== 'disabled') {
        checkConcurrentLogin();
      } else {
        Alert.alert(
          t('profile:auth.blockedAlert.title'),
          t('profile:auth.blockedAlert.message'),
          [
            {
              text: t('profile:auth.blockedAlert.buttonOK'),
              onPress: () => firebaseRedux.logout()
            }
          ],
          { cancelable: false }
        );
      }
      return;
    }
  }, [profile, profileProcessed, authMode, auth]);

  // Check app version and stop app from proceeding if the version is too far behind
  useEffect(() => {
    if (latestVersion) {
      checkForUpdates(latestVersion);
    }
  }, [latestVersion]);

  const handleDismissModal = () => {
    setShowModal(false);
  };

  return (
    <LandingContainer
      _showModal={_showModal}
      _isLoading={_isLoading}
      _isTimedOut={_isTimedOut}
      _modalConfig={_modalConfig}
      language={language}
      hasNotch={hasNotch}
      auth={auth}
      profile={profile}
      latestVersion={latestVersion}
      uuid={uuid}
      onLanguageChange={handleLanguageChange}
      onDismissModal={handleDismissModal}
    />
  );
};

export default Landing;
