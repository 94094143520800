import React, { useMemo } from 'react';
import { Image, View, TextStyle, StyleSheet, Platform, TextInput, TouchableOpacity } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import Text from '../global/Text';
import theme from '../../constants/theme';

import fontMaker from '../../utils/font';
import { OrganizationSKU } from '../../utils/classes';
import { questionTextInvalid, questionNumberInvalid, questionComplete } from '../../utils/report';
import getSKUItemHeightByState from './utils/getSKUItemHeightByState';

import { QuestionnaireQuestionInventoryNewCardProps } from './QuestionnaireQuestionInventoryNewCard';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';
type OwnProps = {
  containerRef:
    | React.ClassAttributes<View>['ref']
    | ((instance: View | null) => void)
    | React.RefObject<View>
    | null
    | undefined;
  skuIndex: number;
  skuItemName: string;
  skuItemUnit: string;
  skuItemDesc: string;
  skuKey: string;
  inOrigin: OrganizationSKU['inOrigin'];
  startNumber: number;
  outNumber: number;
  validateIn: boolean;
  skuInValue: string;
  skuFinalValue: string;
  skuItemCommentVisible: boolean;
  skuItemComment: string;
  skuItemPhotos: string[];
};
type Props = OwnProps &
  Pick<
    QuestionnaireQuestionInventoryNewCardProps,
    | 'language'
    | 'question'
    | 'allowAuditorStockIn'
    | 'handleInputSKUInventory'
    | 'handleInventoryInputFocused'
    | 'handleInventoryInputBlurred'
    | 'handleActivateImagePicker'
    | 'handleToggleSKUComment'
    | 'handleConfirmEditPhoto'
    | 'index'
  >;

export function QuestionnaireQuestionInventoryNewCardItem(props: Props) {
  const {
    language,
    question,
    allowAuditorStockIn,
    index,
    // handler function
    handleInputSKUInventory,
    handleInventoryInputFocused,
    handleInventoryInputBlurred,
    handleActivateImagePicker,
    handleConfirmEditPhoto,
    handleToggleSKUComment,
    // own props
    containerRef,
    inOrigin,
    skuKey,
    skuIndex,
    startNumber,
    outNumber,
    validateIn,
    skuItemName,
    skuItemUnit,
    skuItemDesc,
    skuInValue,
    skuFinalValue,
    skuItemCommentVisible,
    skuItemComment,
    skuItemPhotos
  } = props;
  const { t } = useTranslation(['questionnaire', 'common']);
  const dynamicStyle = useMemo(
    () => ({
      height: getSKUItemHeightByState(skuItemCommentVisible, skuItemPhotos)
    }),
    [getSKUItemHeightByState, skuItemCommentVisible, skuItemPhotos]
  );

  return (
    <View style={[styles.inventoryCard, dynamicStyle]} ref={containerRef} testID={`test-${skuIndex}`}>
      <View style={styles.inventorySKUHeader}>
        <Text style={styles.inventorySKUHeaderText} numberOfLines={1} ellipsizeMode="tail">
          {skuIndex + 1}. {skuItemName}
          {skuItemUnit}
        </Text>

        {skuFinalValue !== '' && questionComplete(question, outNumber, skuKey, validateIn) ? (
          <Icon name="check" size={20} color={'#009e43'} />
        ) : (
          <Icon name="close" size={20} color={'#f93c1c'} />
        )}
      </View>
      <View style={styles.inventoryRow}>
        <Text style={styles.inventoryDesc} numberOfLines={1} ellipsizeMode="tail">
          {skuItemDesc}
        </Text>
      </View>
      <View style={styles.inventoryRow}>
        {inOrigin !== 'none' && (
          <View style={styles.inventoryCol}>
            <Text style={[styles.inventoryLabel, styles.inventoryLabelCurrent]}>
              {t('questionnaire:question.newInventory.previous')}
            </Text>
            <Text style={[styles.inventoryNumber, styles.inventoryNumberCurrent]}>
              {startNumber ? startNumber : '0'}
            </Text>
          </View>
        )}
        {allowAuditorStockIn || inOrigin === 'onsite' ? (
          <View style={styles.inventoryCol}>
            <Text style={styles.inventoryLabel}>{t('questionnaire:question.inventory.stockIn')}</Text>
            <View
              style={[
                styles.inventoryInputContainer,
                questionNumberInvalid(question, t, 'in', skuInValue, outNumber) && styles.inventoryInputContainerInvalid
              ]}
            >
              <TextInput
                style={styles.inventoryInput}
                maxLength={20}
                onChangeText={text => handleInputSKUInventory(index, skuKey, text || '0', 'in', question)}
                value={skuInValue}
                keyboardType="numeric"
                onFocus={() => handleInventoryInputFocused(index, skuKey, 'in', skuIndex)}
                onBlur={() => handleInventoryInputBlurred(index, skuKey, 'in', skuIndex)}
                testID="inventory-new-in"
              />
            </View>
          </View>
        ) : null}
        {inOrigin !== 'none' && (
          <View style={styles.inventoryCol}>
            <Text style={styles.inventoryLabel}>{t('questionnaire:question.inventory.salesOut')}</Text>
            <Text style={outNumber >= 0 ? styles.inventoryNumber : styles.inventoryNumberInvalid}>{outNumber}</Text>
          </View>
        )}
        <View style={[styles.inventoryCol, inOrigin === 'none' ? styles.inventoryColFinalOnly : {}]}>
          <Text style={[styles.inventoryLabel, inOrigin === 'none' ? styles.inventoryLabelFinalOnly : {}]}>
            {inOrigin !== 'none'
              ? t('questionnaire:question.newInventory.onHand')
              : t('questionnaire:question.newInventory.currentStock')}
          </Text>
          <View
            style={[
              styles.inventoryInputContainer,
              questionNumberInvalid(question, t, 'final', skuFinalValue, outNumber) &&
                styles.inventoryInputContainerInvalid,
              inOrigin === 'none' ? { flex: 1 } : {}
            ]}
          >
            <TextInput
              style={styles.inventoryInput}
              maxLength={20}
              onChangeText={text => handleInputSKUInventory(index, skuKey, text || '0', 'final', question)}
              value={skuFinalValue}
              keyboardType="numeric"
              onFocus={() => handleInventoryInputFocused(index, skuKey, 'final', skuIndex)}
              onBlur={() => handleInventoryInputBlurred(index, skuKey, 'final', skuIndex)}
              testID="inventory-new-final"
            />
          </View>
        </View>
      </View>
      {skuItemCommentVisible ? (
        <View
          style={[
            styles.openAnswerInputContainer,
            questionTextInvalid(question, t) && styles.inventoryInputContainerInvalid
          ]}
        >
          <TextInput
            style={styles.openSKUCommentInput}
            maxLength={2500}
            multiline={true}
            numberOfLines={2}
            onChangeText={text => handleInputSKUInventory(index, skuKey, text || '0', 'comment', question)}
            value={skuItemComment}
            placeholder={`${t('questionnaire:question.newInventory.comments')}${
              question.answer === 'green-flag' ? t('questionnaire:question.newInventory.optional') : ''
            }`}
            onFocus={() => handleInventoryInputFocused(index, skuKey, 'comment', skuIndex)}
            onBlur={() => handleInventoryInputBlurred(index, skuKey, 'comment', skuIndex)}
            testID="inventory-new-comment"
          />
        </View>
      ) : null}

      {skuItemPhotos && skuItemPhotos.length > 0
        ? skuItemPhotos.map((photo, photoIndex) => (
            <TouchableOpacity
              key={`${skuKey}-image-${photoIndex}`}
              onPress={() => handleConfirmEditPhoto(photoIndex, skuKey)}
              testID={`inventory-edit-photo-${photoIndex}`}
            >
              <View style={styles.imageContainer}>
                <Image style={styles.image} resizeMode="cover" source={{ uri: photo || '' }} />
              </View>
            </TouchableOpacity>
          ))
        : null}
      <View style={styles.inventorySKUButtonContainer}>
        {skuItemCommentVisible ? null : (
          <TouchableOpacity onPress={() => handleToggleSKUComment(skuKey)} testID="inventory-toggle-comment">
            <View style={styles.inventorySKUButton}>
              <Text style={styles.inventorySKUButtonText}>{t('questionnaire:question.newInventory.comment')}</Text>
              <Icon name="message" size={18} />
            </View>
          </TouchableOpacity>
        )}
        {skuItemPhotos && skuItemPhotos.length > 0 ? null : (
          <TouchableOpacity onPress={() => handleActivateImagePicker(skuKey)} testID="inventory-image-picker">
            <View style={styles.inventorySKUButton}>
              <Text style={styles.inventorySKUButtonText}>{t('questionnaire:question.newInventory.takePhoto')}</Text>
              <Icon name="camera" size={20} />
            </View>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
}

/**
 * ONLY UPDATE COMPONENT IF
 *
 * 1. `TextInput`s' value changed
 *
 * 2. Item's details changed
 *
 * 3. Item's `photos` changed
 */
function shouldItemSkipUpdate(prevProps: Props, nextProps: Props) {
  // TextInput's value changed
  if (prevProps.skuInValue !== nextProps.skuInValue) {
    return false;
  }
  if (prevProps.skuFinalValue !== nextProps.skuFinalValue) {
    return false;
  }
  if (prevProps.skuItemComment !== nextProps.skuItemComment) {
    return false;
  }

  // Item's details changed
  if (prevProps.skuKey !== nextProps.skuKey) {
    return false;
  }
  if (prevProps.skuIndex !== nextProps.skuIndex) {
    return false;
  }
  if (prevProps.inOrigin !== nextProps.inOrigin) {
    return false;
  }
  if (prevProps.startNumber !== nextProps.startNumber) {
    return false;
  }
  if (prevProps.outNumber !== nextProps.outNumber) {
    return false;
  }
  if (prevProps.skuItemName !== nextProps.skuItemName) {
    return false;
  }
  if (prevProps.skuItemUnit !== nextProps.skuItemUnit) {
    return false;
  }
  if (prevProps.skuItemDesc !== nextProps.skuItemDesc) {
    return false;
  }
  if (prevProps.skuItemCommentVisible !== nextProps.skuItemCommentVisible) {
    return false;
  }
  if (prevProps.validateIn !== nextProps.validateIn) {
    return false;
  }
  if (!isEqual(prevProps.question, nextProps.question)) {
    return false;
  }

  // Item's photo changed
  if (prevProps.skuItemPhotos.length !== nextProps.skuItemPhotos.length) {
    return false;
  }
  if (prevProps.skuItemPhotos.length && prevProps.skuItemPhotos[0] !== nextProps.skuItemPhotos[0]) {
    return false;
  }

  return true;
}

export default React.memo(QuestionnaireQuestionInventoryNewCardItem, shouldItemSkipUpdate);

const styles = StyleSheet.create({
  inventoryRow: {
    flexDirection: 'row',
    marginTop: 5,
    marginBottom: 7.5,
    paddingBottom: 10,
    marginHorizontal: 12,
    borderBottomWidth: 0.5,
    borderBottomColor: theme.colors.light
  },
  inventoryDesc: {
    ...fontMaker({ weight: 'light' }),
    textAlign: 'left',
    paddingVertical: 3,
    paddingHorizontal: 5
  },
  inventoryLabel: {
    ...fontMaker({ weight: 'SemiBold' }),
    textAlign: 'right',
    paddingVertical: 3,
    paddingHorizontal: 5
  },
  inventoryLabelFinalOnly: {
    flex: 2,
    textAlign: 'center'
  },
  inventoryLabelCurrent: {
    paddingHorizontal: 0,
    paddingLeft: 0,
    paddingRight: 5
  },
  inventoryNumber: {
    textAlign: 'right',
    paddingVertical: 4,
    paddingHorizontal: 5
  },
  inventoryNumberInvalid: {
    textAlign: 'right',
    paddingVertical: 4,
    paddingHorizontal: 5,
    color: '#f93c1c'
  },
  inventoryNumberCurrent: {
    paddingHorizontal: 0,
    paddingLeft: 0,
    paddingRight: 5
  },
  inventoryInputContainer: {
    justifyContent: 'flex-start',
    paddingVertical: Platform.OS === 'android' ? 0 : 3,
    marginLeft: 12.5,
    borderWidth: 1,
    borderColor: '#a8a8aa',
    borderRadius: 6,
    backgroundColor: '#fff'
  },
  inventoryInputContainerInvalid: {
    borderColor: theme.colors.secondary
  },
  inventoryInput: {
    ...fontMaker({ weight: 'Regular' }),
    color: '#535353',
    textAlign: 'right',
    textAlignVertical: 'top',
    paddingTop: 0,
    paddingHorizontal: 5
  },
  inventorySKUButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 5,
    marginHorizontal: 20
  },
  inventorySKUButton: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  inventorySKUButtonText: {
    marginRight: 5,
    paddingVertical: 5
  },
  inventoryCol: {
    flex: 1
  },
  inventoryColFinalOnly: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row'
  },
  imageContainer: {
    overflow: 'hidden',
    width: 60,
    height: 60,
    marginTop: 5,
    marginHorizontal: 20,
    borderRadius: 4,
    backgroundColor: '#a8a8aa'
  },
  image: {
    width: '100%',
    height: '100%'
  },
  openSKUCommentInput: {
    height: 40,
    ...fontMaker({ weight: 'Regular' }),
    color: '#535353',
    textAlignVertical: 'top',
    paddingTop: 0,
    paddingHorizontal: 10
  },
  openAnswerInputContainer: {
    justifyContent: 'flex-start',
    marginHorizontal: 20,
    paddingVertical: 10,
    borderWidth: 1,
    borderColor: '#a8a8aa',
    borderRadius: 6,
    backgroundColor: '#fff'
  },
  inventoryCard: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.gray
  },
  inventorySKUHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 14,
    marginHorizontal: 10
  },
  inventorySKUHeaderText: {
    ...fontMaker({ weight: 'SemiBold' }),
    maxWidth: '85%',
    fontSize: 16,
    marginHorizontal: 8
  }
});
