import { action, createAsyncAction } from 'typesafe-actions';

import * as actionTypes from './nps.actionTypes';

export const setLoading = (value: boolean) => action(actionTypes.SET_LOADING, { value });

type CreateNPSSubmittedSagaPayload = {
  timeZone: string;
};

type FetchNPSUserPayload = {
  isEligible: boolean;
  reason: string;
};

export const fetchNPSUserAsync = createAsyncAction(
  actionTypes.FETCH_NPS_USER_REQUEST,
  actionTypes.FETCH_NPS_USER_SUCCESS,
  actionTypes.FETCH_NPS_USER_FAILURE
)<undefined, FetchNPSUserPayload, { error: string }>();

export const createNPSIsSubmittedAsync = createAsyncAction(
  actionTypes.POST_NPS_USER_REQUEST_SUBMITTED,
  actionTypes.POST_NPS_USER_SUCCESS_SUBMITTED,
  actionTypes.POST_NPS_USER_FAILURE_SUBMITTED
)<CreateNPSSubmittedSagaPayload, undefined, { error: string }>();
