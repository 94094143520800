import React, { useEffect } from 'react';
import { RouteProp } from '@react-navigation/native';

import { StyleSheet, View, ActivityIndicator } from 'react-native';
import { WebView, WebViewMessageEvent } from 'react-native-webview';
import { WebViewStackParams } from '../routes';
import { createNPSIsSubmittedAsync } from '../store/reducers/nps/nps.action';
import { connect } from 'react-redux';
import { getTimeZone } from 'react-native-localize';

type WebViewPageProps = {
  route: RouteProp<WebViewStackParams, 'WebView'>;
  postNPSIsSubmitted: ({ timeZone }: { timeZone: string }) => void;
};

const WebViewPage = (props: WebViewPageProps) => {
  /** Simple tracker for page load progress */
  const [_pageLoaded, setPageLoaded] = React.useState(false);

  // When page finishes loading, hide the ActivityIndicator
  const handleLoadEnd = () => {
    if (!_pageLoaded) {
      setPageLoaded(true);
    }
  };

  const _onMessage = (event: WebViewMessageEvent) => {
    const { message } = JSON.parse(event.nativeEvent.data);
    if (message === props.route.params.purpose) {
      const timeZone = getTimeZone();
      props.postNPSIsSubmitted({ timeZone });
    }
  };

  return (
    <View style={styles.root}>
      {!_pageLoaded ? (
        <View style={styles.loadingContainer}>
          <ActivityIndicator color="#3cd070" size="large" />
        </View>
      ) : null}
      <WebView
        incognito={true}
        injectedJavaScript={props.route.params.injectedJsScript}
        androidHardwareAccelerationDisabled={true}
        javaScriptEnabled={true}
        source={{ uri: props.route.params.url }}
        onLoadEnd={handleLoadEnd}
        onMessage={_onMessage}
      />
    </View>
  );
};

const mapDispatchToProps = {
  postNPSIsSubmitted: (payload: { timeZone: string }) => createNPSIsSubmittedAsync.request(payload)
};

const enhance = connect(null, mapDispatchToProps);

export default enhance(WebViewPage) as any;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#efeeed'
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100
  }
});
