/* eslint-disable complexity */
import { enums } from '@nimbly-technologies/nimbly-common';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import { OrganizationSchedule } from 'nimbly-common';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import {
  SiteScheduleDetails,
  UserSingleSiteScheduleInfo
} from '../../../store/reducers/siteSchedule/siteSchedule.reducer';
import { InjectedSite, InjectedSiteStatus, ScheduleOption } from '../../../typing/types';
import { ReportIndex, ScheduleStatus, ScheduleStatusEnum, Site, SiteReport } from '../../../utils/classes';
import { getEndTimeMinutes, getTodayMinutes } from '../../../utils/getTodayMinutes';
import { currentPeriodEndDate, currentPeriodStartDate } from '../../../utils/schedule';
import getScheduleDateTime from '../utils/getScheduleDateTime';
import { getScheduleCheckInOutPermission } from '../utils/useScheduleCheckInOutPermission';

const findNextDue = (schedule: UserSingleSiteScheduleInfo, m: moment.Moment): string => {
  const todayString = moment().startOf('day').format('YYYY-MM-DD');
  const yesterDayString = moment().subtract(1, 'days').format('YYYY-MM-DD');
  let nextDue =
    schedule.unfinishedDates.find(date => {
      if (schedule?.datesCustomInfo?.length) {
        const dateCustomValue = schedule.datesCustomInfo?.filter(
          dateCustom =>
            moment(dateCustom?.startDate).format('YYYY-MM-DD') === date &&
            (schedule.isYesterdaySchedule ? yesterDayString : todayString === date)
        );
        const customStartDate = moment(dateCustomValue?.[0]?.startDate).format('YYYY-MM-DD');
        if (customStartDate) {
          if (customStartDate < (schedule.isYesterdaySchedule ? yesterDayString : todayString)) {
            return false;
          } else if (customStartDate > (schedule.isYesterdaySchedule ? yesterDayString : todayString)) {
            return true;
          } else {
            if (!schedule.hasStrictTime) {
              return true;
            }
            if (schedule.isYesterdaySchedule) {
              return moment().isBetween(moment(dateCustomValue?.[0]?.startDate), moment(dateCustomValue?.[0]?.endDate));
            }
            return moment().isBetween(moment(dateCustomValue?.[0]?.startDate), moment(dateCustomValue?.[0]?.endDate));
          }
        }
        return false;
      } else {
        if (schedule.isYesterdaySchedule) {
          if (!schedule.hasStrictTime) {
            return true;
          }
          if (date < yesterDayString) {
            return false;
          } else if (date > yesterDayString) {
            return true;
          }
          if (date === moment().subtract(1, 'days').format('YYYY-MM-DD')) {
            return moment().isBetween(
              moment().subtract(1, 'days').startOf('day').add(schedule.startTime, 'minutes'),
              moment()
                .startOf('day')
                .add(schedule.endTime - 1440, 'minutes')
            );
          }
          return false;
        } else {
          if (date < todayString) {
            return false;
          } else if (date > todayString) {
            return true;
          } else {
            // date === today
            if (!schedule.hasStrictTime) {
              return true;
            } else if (
              schedule.hasStrictTime &&
              typeof schedule.endTime === 'number' &&
              typeof schedule.startTime === 'number'
            ) {
              return moment().isBetween(
                moment().startOf('day').add(schedule.startTime, 'minutes'),
                moment().startOf('day').add(schedule.endTime, 'minutes')
              );
            }

            return false;
          }
        }
      }
    }) || '';
  return nextDue;
};

const findMakeUpDates = (schedule: UserSingleSiteScheduleInfo, m: moment.Moment): string[] => {
  const todayString = m.format('YYYY-MM-DD');

  const nowDiff = m.diff(moment().startOf('day'), 'minutes');

  const makeUpDates = schedule.unfinishedDates.filter(date => {
    if (date > todayString) {
      return false;
    } else if (date < todayString) {
      return true;
    } else {
      // date === today
      if (!schedule.hasStrictTime) {
        return false;
      }
      if (schedule.hasStrictTime) {
        return nowDiff > schedule?.endTime;
      }

      return false;
    }
  });

  if (makeUpDates.includes(todayString)) {
    return [todayString];
  }

  return makeUpDates;
};

export const sortSchedules = (a: ScheduleOption, b: ScheduleOption, nowDiff: number): number => {
  if (a.pendingReport && !b.pendingReport) {
    return -1;
  } else if (!a.pendingReport && b.pendingReport) {
    return 1;
  }

  if (a.draftReport && !b.draftReport) {
    return -1;
  } else if (!a.draftReport && b.draftReport) {
    return 1;
  }

  if (a.status === 'ready' && b.status === 'ready') {
    if (a.hasStrictTime && !b.hasStrictTime) {
      return -1;
    } else if (!a.hasStrictTime && b.hasStrictTime) {
      return 1;
    } else if (a.hasStrictTime && b.hasStrictTime) {
      // the general idea is to `find the closest end time relative to now, with the earliest start time`
      const aDiff = a.endTime - nowDiff;
      const bDiff = b.endTime - nowDiff;
      if (aDiff === bDiff) {
        return a.startTime - b.startTime;
      }

      return aDiff - bDiff;
    }
    return 0;
  }

  if (a.status === 'ready' && b.status !== 'ready') {
    return -1;
  } else if (a.status !== 'ready' && b.status === 'ready') {
    return 1;
  }

  if (a.status === 'makeup' && b.status !== 'makeup') {
    return -1;
  } else if (a.status !== 'makeup' && b.status === 'makeup') {
    return 1;
  }

  return 0;
};

interface Props {
  siteReports: SiteReport;
  siteSummaries: ReportIndex;
  siteSchedules: SiteScheduleDetails;
  pendingReports: {
    [scheduleKey: string]: string;
  } | null;
  isDummy: boolean;
  selectedSite: Site | InjectedSite | null;
  organizationSchedule: OrganizationSchedule;
  pendingMultiSiteReportKey: string | null;
  selectedDateCalendar: string | null;
}

/**
 * Map schedule options and find any draft or pending report
 */
const useScheduleOptions = (props: Props): ScheduleOption[] => {
  const {
    organizationSchedule,
    siteReports,
    siteSummaries,
    siteSchedules,
    pendingReports,
    selectedSite,
    pendingMultiSiteReportKey,
    selectedDateCalendar
  } = props;

  const roleAccessAdhoc = useSelector(
    (state: RootState) => state.account.role?.resourceMap['application:report:adhoc'].create || false
  );
  const featureAccessAdhoc = useSelector((state: RootState) =>
    Boolean(state.featureAccess.features[enums.Features.ADHOC_REPORTING])
  );

  const organizationID = useSelector((state: RootState) => state.organization.myOrganization?.organizationID) ?? '';

  const [schedules, setSchedules] = useState<ScheduleOption[]>([]);

  const period = useMemo(() => {
    const start = currentPeriodStartDate(organizationSchedule);
    const end = currentPeriodEndDate(organizationSchedule, start);

    return { start, end };
  }, [organizationSchedule]);

  const draftScheduleMap = useMemo(() => {
    const serverTime = moment.utc();
    const m = moment(serverTime);
    const todayDateString = m.format('YYYY-MM-DD');

    const mappedReport: { [scheduleKey: string]: { key: string; date: string } } = {};

    Object.keys(siteReports || {}).forEach(reportKey => {
      const report = siteReports[reportKey];
      const scheduleDate = moment.parseZone(report.datetimeScheduled).format('YYYY-MM-DD');
      const datetimeInDate = moment.parseZone(report.datetimeIn).format('YYYY-MM-DD');

      let isWithinPeriod = false;
      if (selectedSite?.isMultiSite) {
        if (period.start && period.end) {
          isWithinPeriod =
            (period.start.isSameOrBefore(scheduleDate, 'day') && period.end.isSameOrAfter(scheduleDate, 'day')) ||
            (period.start.isSameOrBefore(datetimeInDate, 'day') && period.end.isSameOrAfter(datetimeInDate, 'day'));
        }
      } else {
        // draft schedule for other dates will be available in the Site Calendar tab
        isWithinPeriod = report.hasDeadline
          ? scheduleDate === todayDateString || datetimeInDate === todayDateString
            ? m.isBetween(
                moment(report.datetimeScheduled).startOf('day').add(report.startTime, 'minutes'),
                moment(report.datetimeScheduled).startOf('day').add(report.endTime, 'minutes')
              ) ||
              m.isBetween(
                moment(report.datetimeIn).startOf('day').add(report.startTime, 'minutes'),
                moment(report.datetimeIn).startOf('day').add(report.endTime, 'minutes')
              )
            : false
          : scheduleDate === todayDateString || datetimeInDate === todayDateString;
      }

      if (report.status === 'draft' && report.scheduleKey && isWithinPeriod) {
        mappedReport[report.scheduleKey] = {
          key: reportKey,
          date: moment.parseZone(report.datetimeScheduled).format('YYYY-MM-DD')
        };
      }
    });

    return mappedReport;
  }, [siteReports, selectedSite, period]);

  const findPendingReport = useCallback(
    (reportKey: string) => {
      if (!reportKey || !siteReports || !siteReports[reportKey] || !siteSummaries || !siteSummaries[reportKey]) {
        return null;
      }

      const date = moment.parseZone(siteReports[reportKey].datetimeScheduled).format('YYYY-MM-DD');

      return {
        key: reportKey,
        date
      };
    },
    [siteReports, siteSummaries]
  );

  /** find pending report */
  const findPendingSingleSiteReport = useCallback(
    (scheduleKey: string) => {
      if (!pendingReports) {
        return null;
      }

      const reportKey = pendingReports[scheduleKey];

      return findPendingReport(reportKey);
    },
    [findPendingReport, pendingReports]
  );

  const findPendingMutiSiteReport = useCallback(() => {
    if (!pendingMultiSiteReportKey) {
      return null;
    }

    return findPendingReport(pendingMultiSiteReportKey);
  }, [pendingMultiSiteReportKey, findPendingReport]);

  /**
   * Check completed report
   * to make sure report status isCompleted
   */
  const checkIsAlreadyComplete = (schedule: UserSingleSiteScheduleInfo) => {
    let isComplete = false;
    const today = moment();

    for (const [_, sum] of Object.entries(siteReports)) {
      const scheduleDate = selectedSite!.timezone
        ? moment.tz(sum.datetimeScheduled, selectedSite!.timezone)
        : moment(sum.datetimeScheduled);

      const submittedDate = selectedSite!.timezone
        ? moment.tz(sum.datetimeSubmitted, selectedSite!.timezone)
        : moment(sum.datetimeSubmitted);

      // Offset date because daily scheduled date from BE interpreted as on different day
      const scheduledToday = scheduleDate.add(-1, 'second').isSame(today, 'day');
      const submittedToday = submittedDate.isSame(today, 'day');

      isComplete =
        scheduledToday &&
        submittedToday &&
        sum.scheduleKey === schedule.key &&
        sum.status === 'complete' &&
        !sum.isAdhoc;

      if (isComplete) {
        break;
      }
    }

    return isComplete;
  };
  const findCustomScheduleStartTime = (schedule: UserSingleSiteScheduleInfo): number => {
    const todayString =
      selectedDateCalendar && selectedDateCalendar !== moment().format('YYYY-MM-DD')
        ? selectedDateCalendar
        : schedule?.isYesterdaySchedule
        ? moment().subtract(1, 'days').format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD');
    const dateCustomValue = schedule.datesCustomInfo?.filter(
      dateCustom => moment(dateCustom?.startDate).format('YYYY-MM-DD') === todayString
    );

    return getTodayMinutes(dateCustomValue?.[0]?.startDate) || 0;
  };
  const findCustomScheduleEndTime = (schedule: UserSingleSiteScheduleInfo): number => {
    const todayString =
      selectedDateCalendar && selectedDateCalendar !== moment().format('YYYY-MM-DD')
        ? selectedDateCalendar
        : schedule?.isYesterdaySchedule
        ? moment().subtract(1, 'days').format('YYYY-MM-DD')
        : moment().format('YYYY-MM-DD');
    const dateCustomValue = schedule.datesCustomInfo?.filter(
      dateCustom => moment(dateCustom?.startDate).format('YYYY-MM-DD') === todayString
    );

    return getEndTimeMinutes(dateCustomValue?.[0]?.startDate, dateCustomValue?.[0]?.endDate) || 0;
  };
  useEffect(() => {
    const schedulesData = siteSchedules ? siteSchedules.singleSchedules || [] : [];
    let cloneSchedulesData = cloneDeep(schedulesData);
    const timezone = selectedSite?.timezone ?? '';

    const serverTime = moment.tz(schedulesData?.[0]?.siteDateTime, timezone).toDate();
    const m = moment(serverTime, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
    const todayDateString = m.format('YYYY-MM-DD');
    const yesterDayString = m.clone().subtract(1, 'days').format('YYYY-MM-DD');

    for (const schedule of schedulesData) {
      if (schedule.isYesterdayActiveSchedule && !schedule.isYesterdayActiveScheduleDone) {
        cloneSchedulesData.push({
          ...schedule,
          isYesterdaySchedule: true
        });
      }
    }

    // Filter, only display schedule if today is in schedule date range
    cloneSchedulesData = cloneSchedulesData.filter(sch => {
      if (sch.isMultiSite) return true;

      const siteDateTime = moment.tz(sch.siteDateTime, timezone);

      const { startDateTime, endDateTime } = getScheduleDateTime({
        schedule: {
          ...sch,
          startTime: sch.startTime ?? undefined,
          endTime: sch.endTime ?? undefined
        },
        selectedCalendarDate: selectedDateCalendar ?? '',
        serverDateTime: siteDateTime.toISOString(),
        siteTimeZone: timezone,
        siteDateTime
      });
      if (sch.isAdhocOnly) {
        return true;
      }
      if (!startDateTime || !endDateTime) {
        return false;
      }

      return siteDateTime.isBetween(startDateTime, endDateTime, 'day', '[]');
    });

    cloneSchedulesData.forEach((sch, index) => {
      if (sch.isMultiSite) {
        const extractedSchedule: UserSingleSiteScheduleInfo[] = [];

        Object.entries(sch.scheduledDates).forEach(([date, scheduleStatus]) => {
          const siteDateTime = moment.tz(serverTime, timezone);

          const isCompleted = scheduleStatus.isComplete;
          const isSameOrBeforeToday = moment(date).isSameOrBefore(siteDateTime, 'day');

          if (!isCompleted && isSameOrBeforeToday) {
            extractedSchedule.push({
              ...sch,
              scheduledDates: {
                [date]: scheduleStatus
              },
              startDate: date,
              totalScheduled: 1,
              totalCompleted: 0,
              isAdhocOnly: true
            });
            return;
          }

          const isScheduledToday = moment(date).isSame(todayDateString, 'day');
          if (isCompleted && isScheduledToday) {
            extractedSchedule.push({
              ...sch,
              scheduledDates: {
                [date]: scheduleStatus
              },
              startDate: date,
              totalScheduled: 1,
              totalCompleted: 1
            });
            return;
          }
        });

        cloneSchedulesData.splice(index, 1, ...extractedSchedule);
      }
    });

    const mappedData = cloneSchedulesData.map((sch): ScheduleOption => {
      let status: ScheduleStatus = 'ready';
      let draft: null | { key: string; date: string } = null;

      let nextDue = findNextDue(sch, m);
      const makeUpDates = findMakeUpDates(sch, m);
      const pendingReport = selectedSite?.isMultiSite
        ? findPendingMutiSiteReport()
        : findPendingSingleSiteReport(sch.key);
      const isCompletedScheduled = checkIsAlreadyComplete(sch);

      const startTime = sch?.datesCustomInfo?.length ? findCustomScheduleStartTime(sch) : sch?.startTime;
      const endTime = sch?.datesCustomInfo?.length ? findCustomScheduleEndTime(sch) : sch?.endTime;

      const { startDateTime, endDateTime, enforceCheckIn, enforceCheckOut } = getScheduleCheckInOutPermission({
        featureAccessAdhoc,
        isAdhoc: sch.isAdhocOnly,
        roleAccessAdhoc,
        selectedSchedule: {
          ...sch,
          startTime: startTime ?? undefined,
          endTime: endTime ?? undefined
        },
        selectedSite,
        selectedCalendarDate: selectedDateCalendar ?? ''
      });

      const isCompleted = isCompletedScheduled;
      const isScheduledToday = sch.isScheduledToday;

      const now = moment(serverTime);
      const afterCheckInTime = now.isSameOrAfter(startDateTime);
      const isPassedCheckOutTime = now.isAfter(endDateTime);

      const notCompletedToday = isScheduledToday && !isCompleted;
      const overCheckIn = isPassedCheckOutTime && afterCheckInTime && enforceCheckIn;
      const overCheckOut = isPassedCheckOutTime && enforceCheckOut;
      const isOverdue = notCompletedToday && (overCheckOut || overCheckIn);

      let isDraftStatus = !!draftScheduleMap[sch.key];

      const isTeamAudit = sch.isMultiSite;
      if (isTeamAudit) {
        const siteReportsKey = Object.keys(siteReports ?? {})
          .reverse()
          .find(key => {
            return key.includes(sch?.startDate ?? '');
          });

        const siteReport = siteReports[siteReportsKey ?? ''];

        draft = siteReport && siteReportsKey ? { key: siteReportsKey, date: sch.startDate } : null;

        const isCountCompleted = sch.totalScheduled > 0 && sch.totalCompleted === sch.totalScheduled;

        isDraftStatus = !isCountCompleted && siteReport ? true : false;
      }

      switch (true) {
        case !!pendingReport:
          status = ScheduleStatusEnum.PENDING_UPLOAD;
          break;
        case isDraftStatus:
          status = ScheduleStatusEnum.DRAFT;
          draft = isTeamAudit ? draft : draftScheduleMap[sch.key];
          break;
        case sch.isAdhocOnly:
          status = ScheduleStatusEnum.ADHOC;
          break;
        case !selectedSite?.isMultiSite && !sch.isQuestionnaireExists:
          // multisite may not have its questionnaire profile listed
          status = ScheduleStatusEnum.ERROR_QUESTIONNAIRE_NOT_FOUND;
          break;
        case makeUpDates.length > 0 && !sch.isScheduledToday && !isTeamAudit:
          status = ScheduleStatusEnum.MAKEUP;
          break;
        case sch.totalScheduled === 0 && sch.type === 'custom' && !sch?.hasAdhocCustom && sch.isAdhocToday:
          status = ScheduleStatusEnum.ADHOC;
          break;
        case (!sch.isScheduledToday && makeUpDates.length !== 0) || !sch.isScheduledToday:
          status = ScheduleStatusEnum.NONE_DUE;
          break;
        case sch.totalScheduled === sch.totalCompleted && sch.totalScheduled !== 0:
          status = ScheduleStatusEnum.FINISHED_PERIOD;
          break;
        case sch.scheduledDates[todayDateString]?.isComplete:
          status = ScheduleStatusEnum.FINISHED_TODAY;
          break;
        case isCompletedScheduled:
          status = ScheduleStatusEnum.FINISHED_TODAY;
          break;
        case sch.hasStrictTime:
          // set deadline schedule is for today only
          status =
            nextDue !== (sch.isYesterdaySchedule ? yesterDayString : todayDateString)
              ? ScheduleStatusEnum.OVERDUE
              : ScheduleStatusEnum.READY;
          break;

        default:
          break;
      }

      // override value if demo
      if (props.isDummy) {
        nextDue = todayDateString;
        const dummyStatus: Partial<{ [K in InjectedSiteStatus]: boolean }> = {
          ready: true,
          'pending-upload': true,
          draft: true,
          overdue: true
        };
        if (!dummyStatus[status]) {
          status = ScheduleStatusEnum.READY;
        }
      }

      return {
        ...sch,
        key: sch.key,
        title: sch.questionnaireTitle,
        hasDeadline: sch.hasDeadline,
        startTime: startTime ?? 0,
        endTime: endTime ?? 0,
        status,
        draftReport: draft,
        nextDue,
        makeUpDates,
        pendingReport,
        totalScheduled: sch.totalScheduled,
        totalCompleted: sch.totalCompleted,
        type: sch.type,
        scheduledDates: sch.scheduledDates,
        questionnaire: sch.questionnaireDetails ? sch.questionnaireDetails.value : null,
        emailTargets: sch.emailTargets,
        signatures: sch.signatures,
        selfieSignatures: sch.selfieSignatures,
        hasAdhocCustom: sch?.hasAdhocCustom,
        allowAdhoc: sch?.allowAdhoc,
        hasStrictTime: sch?.hasStrictTime,
        datesCustomInfo: sch?.datesCustomInfo,
        isAdhocOnly: sch?.isAdhocOnly,
        isYesterdayActiveSchedule: sch?.isYesterdayActiveSchedule,
        isYesterdayActiveScheduleDone: sch?.isYesterdayActiveScheduleDone,
        isYesterdaySchedule: !!sch?.isYesterdaySchedule
      };
    });

    const nowDiff = m.diff(moment().startOf('day'), 'minutes');
    const options = mappedData
      .sort((a, b) => sortSchedules(a, b, nowDiff))
      .filter(s => {
        if (!selectedSite?.isMultiSite) {
          // for single site makeup and none due schedule will not be displayed right away
          if (s.status === 'none-due') {
            return false;
          }
        }

        return true;
      });

    setSchedules(options);
  }, [
    findPendingSingleSiteReport,
    findPendingMutiSiteReport,
    draftScheduleMap,
    siteSchedules,
    selectedSite,
    organizationID
  ]);

  return schedules;
};

export default useScheduleOptions;
