import { action, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';

export const SET_GPS_LOCATION = '@calibration/SET_GPS_LOCATION';
export const SET_PIN_LOCATION = '@calibration/SET_PIN_LOCATION';
export const RESET = '@calibration/RESET';

export type MapLocation = {
  coordinates: { latitude: number; longitude: number };
  address: string;
  locationName: string;
  country: string;
  province: string;
  city: string;
};

/**
 * Sets the GPS Location
 * @param location
 */
export const setGPSLocation = (location: MapLocation) => action(SET_GPS_LOCATION, { location });

/**
 * Sets the Map Pin location
 * @param location
 */
export const setPinLocation = (location: MapLocation) => action(SET_PIN_LOCATION, { location });

/**
 * Reset (clear) calibration data
 */
const reset = () => action(RESET);

export const calibrationActions = { setGPSLocation, setPinLocation, reset };

export type CalibrationAction = ActionType<typeof calibrationActions>;
export type CalibrationState = {
  readonly gpsLocation: MapLocation;
  readonly pinLocation: MapLocation;
};

const initialState: CalibrationState = {
  gpsLocation: {
    coordinates: { latitude: 0, longitude: 0 },
    locationName: '',
    address: '',
    country: '',
    province: '',
    city: ''
  },
  pinLocation: {
    coordinates: { latitude: 0, longitude: 0 },
    locationName: '',
    address: '',
    country: '',
    province: '',
    city: ''
  }
};

export const calibrationReducer: Reducer<CalibrationState, CalibrationAction> = (
  state = initialState,
  incomingAction
) => {
  switch (incomingAction.type) {
    case SET_GPS_LOCATION:
      return {
        ...state,
        gpsLocation: incomingAction.payload.location
      };
    case SET_PIN_LOCATION:
      return {
        ...state,
        pinLocation: incomingAction.payload.location
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};
