import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack/src/types';
import firebase from 'firebase';
import { cloneDeep, debounce } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActivityIndicator,
  Alert,
  InteractionManager,
  Platform,
  RefreshControl,
  SectionList,
  StyleSheet,
  TextInput,
  View
} from 'react-native';
import Geolocation from 'react-native-geolocation-service';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { connect, useSelector } from 'react-redux';
import { firebaseConnect, isLoaded, populate } from 'react-redux-firebase';
import { compose } from 'redux';
import theme from '../../constants/theme';
import { ScheduleStackParamList } from '../../routes';
import { RootState } from '../../store/reducers';
import { CurrentLocation, geolocationThunk } from '../../store/reducers/geolocation';
import { journeyActions } from '../../store/reducers/journey/journey';
import { ScheduledJourneyInfo } from '../../store/reducers/schedulejourneys';
import { ScheduledSiteInfo, scheduleSitesThunk } from '../../store/reducers/schedulesites';
import { siteActions } from '../../store/reducers/site/site';
import { toastActions } from '../../store/reducers/toast';
import { ConnectedDispatch, FirebaseConnect, PopulatedQuestionnaireIndex } from '../../typing/types';
import { Language, ReportSummary, Site } from '../../utils/classes';
import { errorLogger } from '../../utils/errorLogger';
import fontMaker from '../../utils/font';
import Text from '../global/Text';
import getJourneyReportStatus from '../schedule/utils/getJourneyReportStatus';
import checkLocationPermission from '../site/utils/checkLocationPermission';
import openSettings from '../site/utils/openSettings';
import SiteListItem from './SiteListItem';
import generateJourneys from './utils/generateJourneys';
import generateSites from './utils/generateSites';
import getJourneyStatus from './utils/getJourneyStatus';
import getSiteStatus from './utils/getSiteStatus';

export const SiteList = (props: SiteListProps) => {
  const navigation = useNavigation<StackNavigationProp<ScheduleStackParamList, 'Schedule'>>();
  const cacheLocation = useSelector<RootState, CurrentLocation>(state => state.geolocation.cacheLocation);
  const [_currentCoordinates, setCurrentCoordinates] = useState<any>(null);
  const [searchText, setSearchText] = useState<string>('');

  const [_locationPermissionGranted, setLocationPermissionGranted] = useState<boolean>(false);
  const [_isReady, setIsReady] = useState<boolean>(false);
  const [_isTransitioned, setIsTransitioned] = useState<boolean>(false);
  const [_sections, setSections] = useState<any>([]);
  const [_lastUpdated, setLastUpdated] = useState<any>();
  const [_now] = useState<any>(moment());
  const [generatedSites, setGeneratedSites] = useState<any[]>([]);
  const { t } = useTranslation('schedule, common');

  /**
   * Check whether location has been enabled for Nimbly
   */
  const checkPermissions = async () => {
    const { profile, auth, language } = props;
    try {
      const promptEN = 'You can enable Location Access for Nimbly in your App Settings later.';
      const promptID = 'Anda dapat mengatur ulang akses lokasi untuk Nimbly pada Pengaturan Aplikasi.';
      const isGranted = await checkLocationPermission();
      if (isGranted) {
        setLocationPermissionGranted(true);
      } else {
        setLocationPermissionGranted(false);
        Alert.alert(language === 'en' ? 'Access Location' : 'Akses Lokasi', language === 'en' ? promptEN : promptID, [
          {
            text: t('common:openSettings'),
            onPress: () => {
              openSettings();
            }
          },
          {
            text: t('common:skip')
          }
        ]);
      }
    } catch (err) {
      if (err) {
        errorLogger(auth.uid, profile.organization, '', '', err, 'SiteList.tsx/checkPermissions');
      }
    }
  };

  /**
   * Get user current location
   */
  const getCurrentLocation = () => {
    const { language, dispatch } = props;
    Geolocation.getCurrentPosition(
      (data: any) => {
        if (data && data.coords) {
          setCurrentCoordinates(data.coords);
        }
      },
      () => {
        const errorEN = 'Something went wrong while retrieving your location. Please try again.';
        const errorID = 'Terjadi kendala dalam menentukan lokasi Anda. Coba ulangi kembali.';
        dispatch(toastActions.createToast(language === 'en' ? errorEN : errorID, true, 5000));
      },
      { timeout: 10000, maximumAge: 3000, enableHighAccuracy: true }
    );
  };

  /**
   * Get location when permission granted
   */
  useEffect(() => {
    const currentTimestamp = moment().valueOf();

    if (_locationPermissionGranted) {
      if (!props.cacheLocation.isFetched || currentTimestamp - props.cacheLocation.timestamp > 15 * 60 * 1000) {
        props.dispatch(geolocationThunk.updateCacheLocation());
      }
    }

    getCurrentLocation();
  }, [props.isFocused]);

  /**
   * Checks whether the component is ready or not
   */
  useEffect(() => {
    if (!props.isFocused) {
      return;
    }

    setIsReady(
      isLoaded(props.organization) &&
        _isTransitioned &&
        !props.isFetchingMySites &&
        !props.isFetchingMyJourneys &&
        props.cacheReady &&
        !!_currentCoordinates
    );
  }, [
    props.organization,
    _isTransitioned,
    props.isFetchingMySites,
    props.isFetchingMyJourneys,
    props.cacheReady,
    props.allQuestionnaireIndex,
    _currentCoordinates
  ]);

  /**
   * Component focus
   */
  useEffect(() => {
    if (!props.isFocused) {
      return;
    }

    InteractionManager.runAfterInteractions(() => {
      if (props.isFocused) {
        setTimeout(() => {
          checkPermissions();
        }, 1000);

        setIsTransitioned(true);
        firebase.analytics().logEvent('site_list_view');
      }
    });

    const { dispatch, organization } = props;

    dispatch(journeyActions.setJourney(null));
    dispatch(journeyActions.setJourneyReport(null));
  }, [props.isFocused]);

  /**
   * Retrieve site data
   */
  useEffect(() => {
    if (!props.isFocused) {
      return;
    }

    const schedules = cloneDeep(props.mySitesScheduled || []);

    const sites = generateSites(
      schedules,
      props.pendingSites,
      props.cacheReportIndex,
      cacheLocation,
      props.adhocSites,
      props.role,
      t,
      props.isDummy
    );

    const journeySites = generateJourneys(props.myJourneysScheduled, t);
    const allSites = sites.concat(journeySites);
    setGeneratedSites(allSites);
    setSections(allSites);

    setLastUpdated(props.myScheduledSitesLastUpdated ? moment(props.myScheduledSitesLastUpdated) : null);
  }, [props.mySitesScheduled, props.myJourneysScheduled, _currentCoordinates, props.adhocSites]);

  /**
   * Handle site navigation
   */
  const handleNavSite = (
    siteKey: string,
    site: Site,
    siteCompletedReports: number,
    lastReport: ReportSummary | null
  ) => {
    const { auth, dispatch, profileColor } = props;
    const siteSchedule = null;
    dispatch(
      siteActions.selectSite(
        siteKey,
        { ...site, assignedAuditor: !site.isMultiSite ? auth.uid : site.assignedAuditor },
        siteSchedule,
        siteCompletedReports,
        lastReport
      )
    );
    navigation.navigate('Site', {
      profileColor: profileColor,
      originTab: 'ScheduleTab',
      siteName: site.name
    });
  };

  /**
   * Handle journey navigation
   */
  const handleNavJourney = (journey: ScheduledJourneyInfo, isDueToday: boolean) => {
    const { profileColor, dispatch, ongoingJourneyReportKey, language, journeyReport } = props;

    let ongoingKey = ongoingJourneyReportKey;
    const status = getJourneyStatus(journey, ongoingJourneyReportKey);

    // journeyReport is from redux if already create journey
    // journey.report from journey schedule
    const report = journey.report || journeyReport;

    // handle draft journey
    if (status === 'draft') {
      ongoingKey = report?.code || null;
      props.dispatch(journeyActions.setOngoingJourneyReportKey(report?.code || null));
    }

    if (ongoingJourneyReportKey && report?.code !== ongoingKey) {
      const promptENOngoing = 'Another journey is ongoing\nYou can not start this journey';
      const promptIDOngoing = 'Journey lain sedang berjalan\nTidak bisa menjalankan journey ini';
      Alert.alert(
        language === 'en' ? 'Reminder' : 'Peringatan',
        language === 'en' ? promptENOngoing : promptIDOngoing,
        [
          {
            text: 'OK'
          }
        ]
      );
      return;
    }

    dispatch(journeyActions.setJourney(journey.journeyProfile));
    dispatch(journeyActions.setJourneyReport(report || null));

    navigation.navigate('Journey', {
      profileColor: profileColor,
      originTab: 'HomeTab',
      isDueToday: isDueToday || getJourneyReportStatus(journey.report, props.ongoingJourneyReportKey) === 'ongoing' //handle completed ongoing journey
    });
  };

  /**
   * Handle pull to refresh
   */
  const handlePullToRefresh = () => {
    const { isFetchingMySites, isFetchingMyJourneys, organization, dispatch, cacheLocation } = props;
    if (!isFetchingMySites && organization) {
      dispatch(scheduleSitesThunk.getMyScheduledSites(organization!.schedule));
    }

    /**
     * this action will trigger location cache update when time difference is more than 15 minutes
     */
    const currentTimestamp = moment().valueOf();
    if (currentTimestamp - cacheLocation.timestamp > 15 * 60 * 1000) {
      dispatch(geolocationThunk.updateCacheLocation());
    }
  };

  const handleFilterChange = debounce((value: string) => {
    if (!value) {
      setSections(generatedSites);
    } else {
      setSections(
        generatedSites.map(({ title, data }) => {
          const filteredData = data.filter(({ siteProfile }) =>
            siteProfile?.name?.toLowerCase().includes(value.toLowerCase())
          );

          return { title, data: filteredData };
        })
      );
    }
  }, 500);

  if (!props.isFocused) {
    return <View />;
  }

  return (
    <View style={styles.root}>
      {props.organizationLoading || props.adhocSitesLoading ? (
        <View style={styles.loading}>
          <ActivityIndicator color={theme.colors.primary} size="large" />
        </View>
      ) : props.mySitesScheduled.length === 0 && !props.adhocSites ? (
        <View style={styles.loading}>
          <Text style={styles.emptyText}>{props.language === 'en' ? 'No sites Found' : 'Situs Tidak Ditemukan'}</Text>
        </View>
      ) : (
        <>
          <View style={styles.searchContainer}>
            <Icon name="magnify" size={16} color={theme.styles.fontColor.other} style={styles.iconMagnifer} />
            <TextInput
              placeholder={t('common:search')}
              style={styles.textInput}
              value={searchText}
              onChangeText={v => {
                setSearchText(v);
                handleFilterChange(v);
              }}
            />
            <Icon
              name="close"
              size={16}
              color={theme.styles.fontColor.other}
              onPress={() => {
                setSearchText('');
                setSections(generatedSites);
              }}
            />
          </View>
          {_lastUpdated ? (
            <Text style={[styles.lastUpdated]}>
              {t('common:lastUpdated')}{' '}
              {_lastUpdated.isSame(_now, 'day') ? _lastUpdated.format('hh:mma') : _lastUpdated.format('D MMM hh:mma')}
            </Text>
          ) : null}
          <SectionList
            stickySectionHeadersEnabled={false}
            keyExtractor={(item, index) => item + index.toString()}
            sections={_sections}
            contentContainerStyle={styles.siteList}
            renderSectionHeader={({ section: { title, data } }) => (
              <Text style={styles.sectionHeader}>
                {data.length < 10 ? `0${data.length}` : data.length} {title.toUpperCase()}
              </Text>
            )}
            refreshControl={
              <RefreshControl
                onRefresh={handlePullToRefresh}
                refreshing={props.isFetchingMySites}
                tintColor="#fff"
                colors={['#3bd070']}
              />
            }
            renderItem={({ item, index }: { item: ScheduledSiteInfo & ScheduledJourneyInfo; index: number }) => (
              <>
                {item.siteKey ? (
                  <SiteListItem
                    siteStatus={getSiteStatus(item)}
                    siteProfile={item.siteProfile}
                    siteKey={item.siteKey}
                    reportScheduled={!props.isDummy ? item.scheduledCount : 7}
                    reportCompleted={!props.isDummy ? item.completedCount : 5}
                    dueToday={!!item.isDueToday || props.isDummy}
                    key={`${item.siteKey}-${index}`}
                    onSitePressed={(siteKey: string, site: Site) =>
                      handleNavSite(siteKey, site, item.completedCount, null)
                    }
                  />
                ) : (
                  //if item is a journey
                  <SiteListItem
                    siteStatus={getJourneyStatus(item)}
                    siteProfile={item.journeyProfile}
                    siteKey={item.journeyKey}
                    reportScheduled={!props.isDummy ? item.scheduledCount : 7}
                    reportCompleted={!props.isDummy ? item.completedCount : 5}
                    dueToday={!!item.isDueToday || props.isDummy}
                    key={`${item.siteKey}-${index}`}
                    onSitePressed={() => handleNavJourney(item, !!item.isDueToday)}
                  />
                )}
              </>
            )}
          />
        </>
      )}
    </View>
  );
};

type OwnProps = {
  isFocused: boolean;
};

type EnhancedProps = StateProps & ConnectedDispatch & FirebaseConnect;

type SiteListProps = OwnProps & EnhancedProps;

type StateProps = ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: RootState) => {
  const organizationKey: string = state.firebase.profile.organization;

  return {
    role: state.account.role,
    auth: state.firebase.auth,
    hasNotch: state.account.hasNotch,
    cameraActive: state.report.cameraActive,
    notification: state.notification,
    language: (state.firebase.profile.language || 'en') as Language,
    pendingSites: state.reportsubmit.pendingSites,
    cacheReportIndex: state.reportcache.summaries,
    multiReports: state.reportcache.multiReports,
    cacheReady: state.reportcache.isReady,
    profile: state.firebase.profile,
    organization: state.organization.myOrganization,
    organizationLoading: state.organization.isLoading,
    allQuestionnaireIndex: populate(state.firebase, 'questionnaireIndex', [
      {
        child: 'latest',
        root: `/questionnaire/${organizationKey}`,
        childAlias: 'questionnaire'
      }
    ]) as { [key: string]: PopulatedQuestionnaireIndex },
    isDummy: state.account.isDummy,
    mySitesScheduled: state.scheduleSites.myScheduledSites,
    myScheduledSitesLastUpdated: state.scheduleSites.timestamp,
    isFetchingMySites: state.scheduleSites.isFetchingMySites,
    myJourneysScheduled: state.scheduleJourneys.myScheduledJourneys,
    isFetchingMyJourneys: state.scheduleJourneys.isFetchingMyJourneys,
    cacheLocation: state.geolocation.cacheLocation,
    adhocSites: state.site.sites,
    adhocSitesLoading: state.site.isLoading,
    profileColor: state.profiletheme.color,
    ongoingJourneyReportKey: state.journey.ongoingJourneyReportKey,
    journeyReport: state.journey.journeyReport
  };
};

const enhance = compose(firebaseConnect(), connect(mapStateToProps));

export default enhance(SiteList) as any;

const styles = StyleSheet.create({
  loadingRoot: {
    flex: 1,
    maxHeight: 900,
    backgroundColor: 'transparent'
  },
  root: {
    flex: 1,
    backgroundColor: 'transparent'
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  emptyText: {
    ...fontMaker({ weight: 'Light' }),
    fontSize: 16
  },
  lastUpdated: {
    marginLeft: 30,
    marginVertical: 5,
    ...fontMaker({ weight: 'Light' }),
    fontSize: 12,
    color: '#a8a8a8'
  },
  siteList: {
    paddingBottom: 8,
    paddingTop: 10,
    marginBottom: 300
  },
  sectionHeader: {
    position: 'relative',
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 15,
    color: '#a8a8a8',
    marginBottom: 5,
    marginLeft: 30
  },
  textInput: {
    flex: 1
  },
  iconMagnifer: {
    marginRight: 10
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.white,
    height: 32,
    borderRadius: 3,
    paddingHorizontal: 10,
    marginTop: 20,
    marginBottom: 10,
    marginHorizontal: 22
  }
});
