import React, { useState, useEffect, useRef } from 'react';
import { Dimensions, StyleSheet, View, TextStyle, KeyboardAvoidingView, Platform, SafeAreaView } from 'react-native';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Text from '../global/Text';
import Button from '../global/Button';
import AuthField from '../landing/AuthField';
import theme from '../../constants/theme';
import { LanguageType } from '../../constants/Languages';
import { SetPasswordFormInput, SetPasswordFormValidation } from './SetPassword';
import LandingHeader from '../landing/LandingHeader';
import { landingActions } from '../../store/reducers/landing';
import i18n from '../../i18n';
import { SCREEN_WIDTH } from '../../utils/screensize';

type SetPasswordContainerProps = {
  /** Language to be used throughout the app */
  language: LanguageType;
  form: SetPasswordFormInput;
  formValidation: SetPasswordFormValidation;
  _isBusy: boolean;
  _passwordSucess: boolean;
  _sessionExpired: boolean;

  onPassword1Change: (text: string) => void;
  onPassword2Change: (text: string) => void;
  onSubmitForm: () => void;
  onAbort: () => void;
  navigateToLogin: () => void;
};

const SetPasswordContainer = (props: SetPasswordContainerProps) => {
  const dispatch = useDispatch();
  const MAX_COUNTDOOWN = 3;
  const [countDown, setCountDown] = useState<number>(MAX_COUNTDOOWN);
  const cdInterval = useRef<any>();
  const { t } = useTranslation(['auth']);

  const handleLanguageChange = (lang: LanguageType) => {
    dispatch(landingActions.setLanguage(lang));
    i18n.changeLanguage(lang);
  };

  const _renderContent = () => {
    if (props._passwordSucess) {
      return (
        <React.Fragment>
          <Text style={styles.bigInstructionTitle}>{t('auth:setPassword.successTitle')}</Text>
          <Text style={styles.instructionDescription}>{t('auth:setPassword.setPasswordSuccess', { countDown })}</Text>
          <Button
            onPress={props.navigateToLogin}
            title={t('auth:setPassword.navigateToLogin')}
            style={styles.buttonSubmit}
          />
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Text style={styles.instructionsTitle}>{t('auth:setPassword.header')}</Text>
          <Text style={styles.instructionDescription}>{t('auth:setPassword.validationInstruction')}</Text>
          <AuthField
            isValid={props.formValidation.password1}
            disabled={props._isBusy}
            icon="lock-outline"
            onChangeText={props.onPassword1Change}
            value={props.form.password1}
            blurOnSubmit={true}
            onSubmitEditing={props.onSubmitForm}
            placeholder={t('auth:setPassword.newPassword')}
            keyboardType="default"
            secureTextEntry={true}
          />
          <AuthField
            isValid={props.formValidation.password2}
            disabled={props._isBusy}
            icon="lock-outline"
            onChangeText={props.onPassword2Change}
            value={props.form.password2}
            blurOnSubmit={true}
            onSubmitEditing={props.onSubmitForm}
            placeholder={t('auth:setPassword.confirmPassword')}
            keyboardType="default"
            secureTextEntry={true}
          />
          {props._sessionExpired ? (
            <Text style={{ ...(styles.errorText as TextStyle), textAlign: 'center' }}>
              {props.formValidation.errorText}
            </Text>
          ) : props.formValidation.errorText ? (
            <Text style={styles.errorText}>{props.formValidation.errorText}</Text>
          ) : null}
          <Button
            disabled={
              !(props.form.password1 && props.form.password2) ||
              props.form.password1 !== props.form.password2 ||
              props._isBusy ||
              props._sessionExpired
            }
            onPress={props.onSubmitForm}
            title={props._isBusy ? t('auth:setPassword.loading') : t('auth:setPassword.submit')}
            style={styles.buttonSubmit}
          />
          <Button onPress={props.onAbort} title={t('auth:setPassword.cancel')} style={styles.buttonCancel} />
        </React.Fragment>
      );
    }
  };

  const startCountdown = () => {
    if (props._passwordSucess) {
      setCountDown(MAX_COUNTDOOWN);
      const interval = setInterval(() => {
        setCountDown(prevState => prevState - 1);
      }, 1000);
      cdInterval.current = interval;
    }
    return () => clearInterval(cdInterval.current);
  };

  const stopCountdown = () => {
    if (countDown <= 0) {
      clearInterval(cdInterval.current);
      props.navigateToLogin();
    }
  };

  useEffect(startCountdown, [props._passwordSucess]);
  useEffect(stopCountdown, [countDown]);
  return (
    <KeyboardAvoidingView
      style={[{ flex: 1, backgroundColor: theme.colors.nimblyGreen }]}
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
    >
      <View style={[styles.root]}>
        <LandingHeader language={props.language} onLanguageChange={handleLanguageChange} />
        <View style={styles.headerContainer}>
          <Text style={styles.headerText}>Setup Password</Text>
        </View>
        <View style={styles.formContainer}>{_renderContent()}</View>
      </View>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  root: {
    alignItems: 'center',
    backgroundColor: theme.colors.nimblyGreen,
    justifyContent: 'flex-end'
  },
  notch: {
    paddingTop: 24
  },
  headerContainer: {
    paddingBottom: 35,
    paddingTop: 50
  },
  headerText: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 31,
    lineHeight: 54,
    fontWeight: 'bold'
  },
  formContainer: {
    width: SCREEN_WIDTH > 500 ? 670 : 350,
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: Dimensions.get('window').width * 0.1,
    backgroundColor: '#ffffff',
    borderRadius: 7.5,
    paddingVertical: 24
  },
  bigInstructionTitle: {
    fontSize: 24,
    lineHeight: 36
  },
  instructionsTitle: {
    fontWeight: '400',
    fontSize: 16
  },
  instructionDescription: {
    marginVertical: 16,
    textAlign: 'center',
    fontWeight: '400'
  },
  errorText: {
    width: 230,
    marginBottom: 10,
    fontSize: 13,
    textAlign: 'left',
    color: '#FA6161'
  },
  buttonSubmit: {
    width: SCREEN_WIDTH > 500 ? 670 * 0.5 : 350,
    marginTop: 10
  },
  buttonCancel: {
    width: SCREEN_WIDTH > 500 ? 670 * 0.5 : 350,
    marginVertical: 10,
    backgroundColor: theme.colors.secondary
  }
});

export default SetPasswordContainer;
