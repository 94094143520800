import React from 'react';
import { StyleSheet } from 'react-native';

import Text from '../global/Text';

import { QuestionnaireQuestionProps } from './QuestionnaireQuestion';
import theme from '../../constants/theme';
import fontMaker from '../../utils/font';
import { useTranslation } from 'react-i18next';

export type QuestionnaireQuestionScoreAnswerProps = Pick<QuestionnaireQuestionProps, 'question' | 'index' | 'language'>;

export const QuestionnaireQuestionScoreAnswer = (props: QuestionnaireQuestionScoreAnswerProps) => {
  const { t } = useTranslation(['questionnaire']);

  if (props.question.answer === 'not-applicable') {
    return (
      <Text testID={'not-applicable-answer'} style={[styles.scoreAnswerText, styles.scoreAnswerDisabled]}>
        Not Applicable
      </Text>
    );
  }
  if (typeof props.question.answer !== 'number') {
    return (
      <Text testID={'initial-instructions'} style={styles.scoreAnswerText}>
        {props.language === 'en' ? 'Slide to set score' : 'Geser untuk menilai'}
      </Text>
    );
  }
  const pct = props.question.answer / props.question.score;
  let scoreStyle: any;
  if (pct < 0.5) {
    scoreStyle = styles.scoreAnswerTextRed;
  } else if (pct < 0.8) {
    scoreStyle = styles.scoreAnswerTextYellow;
  } else {
    scoreStyle = styles.scoreAnswerTextGreen;
  }
  return (
    <Text style={styles.scoreAnswerText}>
      {t('questionnaire:question.score.title')}:{' '}
      <Text testID={'score-answer'} style={scoreStyle}>
        {props.question.answer}/{props.question.score}
      </Text>
    </Text>
  );
};

export default QuestionnaireQuestionScoreAnswer;

const styles = StyleSheet.create({
  scoreAnswerText: {
    marginHorizontal: 10,
    marginBottom: 10,
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 16,
    textAlign: 'center'
  },
  scoreAnswerDisabled: {
    color: theme.colors.gray
  },
  scoreAnswerTextGreen: {
    color: '#009e43'
  },
  scoreAnswerTextYellow: {
    color: '#ffa040'
  },
  scoreAnswerTextRed: {
    color: '#f93c1c'
  },
  scoreAnswerLabel: {
    marginHorizontal: 10,
    ...fontMaker({ weight: 'Regular' }),
    fontSize: 16
  }
});
