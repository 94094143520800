import React, { useMemo } from 'react';
import {
  View,
  Platform,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableWithoutFeedbackProps,
  TextInput,
  Image,
  StyleSheet
} from 'react-native';
import Text from '../global/Text';
import { QuestionnaireQuestionProps } from './QuestionnaireQuestion';
import { questionTextInvalid } from '../../utils/report';
import theme from '../../constants/theme';
import { useTranslation } from 'react-i18next';
const icoTick = require('../../assets/icons/tick.png');
export type QuestionnaireQuestionChecklistProps = Pick<
  QuestionnaireQuestionProps,
  | 'questions'
  | 'question'
  | 'index'
  | 'currentConditionIndex'
  | 'language'
  | 'isTutorialMode'
  | 'commentInputRef'
  | 'currentQuestionIndex'
  | 'handleSelectChecklist'
  | 'handleTextInputFocused'
  | 'handleTextInputBlurred'
  | 'handleInputComment'
  | 'handleShowTutorialQuestionModal'
  | 'handleChangeTutorialQuestionType'
>;

export type QuestionnaireQuestionChecklistPropsExtra = {
  styles: any;
};

const QuestionnaireQuestionChecklist = (
  props: QuestionnaireQuestionChecklistProps & QuestionnaireQuestionChecklistPropsExtra
) => {
  const { t } = useTranslation(['questionnaire', 'common']);
  React.useEffect(() => {
    if (props.isTutorialMode && props.index === props.currentQuestionIndex) {
      props.handleChangeTutorialQuestionType(props.question.type);
      props.handleShowTutorialQuestionModal(true);
    }
  }, [props.index, props.currentQuestionIndex]);

  // temporary fix to handle input flicker and moving cursor in RN 0.63.3
  // TODO: revert this once the bug is handled, or there is a permanent fix
  const [comment, setComment] = React.useState<string>(props.question.comment);
  const handleInputComment = (text: string) => {
    setComment(text);
    props.handleInputComment(props.index, text, props.currentConditionIndex!);
  };

  React.useEffect(() => {
    setComment(props.question.comment);
  }, [props.question.answer]);

  const checklists = useMemo(() => {
    if (!props.question) {
      return [];
    }

    const { checklistsV2, checklists } = props.question;

    if (checklistsV2) {
      return checklistsV2;
    }

    return checklists.map(checklist => ({
      hasRedFlag: false,
      label: checklist
    }));
  }, []);

  const isSelected = (choice: string) => {
    if (typeof props.question.answers !== 'undefined') {
      const recentAnswers = props.question.answers as string[];
      const selectedAnswer = recentAnswers.findIndex(element => element === choice);
      return selectedAnswer > -1;
    }
    return false;
  };

  if (!props.questions) {
    return <View />;
  }

  return (
    <View>
      <View style={props.styles.choicesList}>
        {checklists.map(({ hasRedFlag, label: optionLabel }, choiceIndex: number) => {
          const isActive = isSelected(optionLabel);
          return (
            <Touchable
              delayPressIn={0}
              key={`qcl-${props.index}-${choiceIndex}`}
              onPress={() => props.handleSelectChecklist(props.index, optionLabel, props.currentConditionIndex!)}
            >
              <View style={[styles.choice, isActive ? styles.choiceChecked : {}]}>
                <View
                  style={[
                    styles.choiceContainer,
                    isActive ? styles.choiceContainerChecked : {},
                    hasRedFlag && styles.choiceRedFlag
                  ]}
                >
                  <View style={[styles.choiceTickContainer, isActive ? styles.choiceTickContainerChecked : {}]}>
                    {isActive ? <Image style={styles.choiceTick} source={icoTick} /> : null}
                  </View>
                  <Text style={[props.styles.choiceText, isActive ? props.styles.choiceSelectedText : {}]}>
                    {optionLabel}
                  </Text>
                </View>
              </View>
            </Touchable>
          );
        })}
        {!props.question.answerRequired
          ? (() => {
              const isActive = isSelected('not-applicable');
              return (
                <Touchable
                  delayPressIn={0}
                  key={`qcl-${props.index}-${checklists.length}`}
                  onPress={() =>
                    props.handleSelectChecklist(props.index, 'not-applicable', props.currentConditionIndex!)
                  }
                >
                  <View style={[styles.choice, isActive ? styles.choiceChecked : {}]}>
                    <View style={[styles.choiceContainer, isActive ? styles.choiceContainerChecked : {}]}>
                      <View style={[styles.choiceTickContainer, isActive ? styles.choiceTickContainerChecked : {}]}>
                        {isActive ? <Image style={styles.choiceTick} source={icoTick.default} /> : null}
                      </View>
                      <Text style={[props.styles.choiceText, isActive ? props.styles.choiceSelectedText : {}]}>
                        N/A
                      </Text>
                    </View>
                  </View>
                </Touchable>
              );
            })()
          : null}
      </View>

      {/* Comment area for non-applicable response */}
      <View>
        <View
          style={[
            props.styles.openAnswerInputContainer,
            props.question.answer === 'not-applicable' && props.styles.commentDisabled,
            {
              borderColor: questionTextInvalid(props.question, t)
                ? theme.colors.secondary
                : props.question.answer &&
                  (props.question.answer === 'red-flag' || props.question.answer === 'yellow-flag') &&
                  !props.question.comment
                ? theme.colors.secondary
                : 'rgba(0, 0, 0, 0.05)'
            }
          ]}
        >
          <TextInput
            style={props.styles.openCommentInput}
            maxLength={2500}
            multiline={true}
            numberOfLines={4}
            editable={props.question.answer !== 'not-applicable'}
            onChangeText={handleInputComment}
            value={comment}
            placeholder={
              props.question.answer === 'not-applicable'
                ? `${t('questionnaire:question.checkList.notApplicable')}`
                : `${t('questionnaire:question.checkList.comments')}${
                    props.question.answer === 'green-flag' ? t('questionnaire:question.checkList.optional') : ''
                  }`
            }
            ref={props.commentInputRef}
            onFocus={() =>
              props.handleTextInputFocused(
                typeof props.currentConditionIndex! === 'number' ? props.currentConditionIndex! : props.index
              )
            }
            onBlur={() =>
              props.handleTextInputBlurred(
                typeof props.currentConditionIndex! === 'number' ? props.currentConditionIndex! : props.index
              )
            }
          />
        </View>
        {!questionTextInvalid(props.question, t) ? (
          <View style={props.styles.validationContainer}>
            <Text style={props.styles.textValidation}>&zwnj;</Text>
          </View>
        ) : (
          <View style={props.styles.validationContainer}>
            <Text style={props.styles.textValidation}> {questionTextInvalid(props.question, t)} </Text>
          </View>
        )}
      </View>
    </View>
  );
};

export default QuestionnaireQuestionChecklist;

const styles = StyleSheet.create({
  choice: {
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 2,
    marginBottom: 2,
    backgroundColor: 'white'
  },
  choiceChecked: {
    backgroundColor: '#535353'
  },
  choiceTickContainer: {
    width: 30,
    height: 30,
    borderRadius: 2,
    borderWidth: 1,
    marginRight: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: '#55DA6A'
  },
  choiceTickContainerChecked: {
    backgroundColor: '#55DA6A'
  },
  choiceContainer: {
    paddingTop: 10,
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FAFAFA',
    paddingLeft: 10,
    paddingRight: 10
  },
  choiceContainerChecked: {
    backgroundColor: 'transparent'
  },
  choiceTick: {
    width: 20,
    resizeMode: 'contain'
  },
  choiceRedFlag: {
    borderRightWidth: 8,
    borderRightColor: theme.colors.danger
  }
});

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
  Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;
