/* eslint-disable  */
import { RouteProp, useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack/src/types';
import firebase from 'firebase';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import moment from 'moment-timezone';
import { Activity, enums, JourneySchedule, Organization, QuestionnaireIndex } from 'nimbly-common';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Alert, StyleSheet, Text, View, Linking, Platform } from 'react-native';
// import { PERMISSIONS, request } from 'react-native-permissions';
import { useDispatch, useSelector } from 'react-redux';
import { FirebaseReducer } from 'react-redux-firebase';
import { appversion } from '../../../constants/appversion';
import { LanguageType } from '../../../constants/Languages';
import getDeviceProfile from '../../../helpers/getDeviceProfile';
import { HomeStackParamList, ScheduleStackParamList } from '../../../routes';
import { RootState } from '../../../store/reducers';
import { logActivityAsync } from '../../../store/reducers/activity/activity.action';
import { calibrationActions, MapLocation } from '../../../store/reducers/calibration';
import { CurrentLocation } from '../../../store/reducers/geolocation';
import {
  cancelFetchJourney,
  fetchJourneyScheduleRequest
} from '../../../store/reducers/journeySchedule/journeySchedule.action';
import { JourneyScheduleDetails } from '../../../store/reducers/journeySchedule/journeySchedule.reducer';
// redux
import * as questionnaireIndexActions from '../../../store/reducers/questionnaireIndex/questionnaireIndex.action';
import { reportActions } from '../../../store/reducers/report';
import * as reportcacheActions from '../../../store/reducers/reportcache/reportcache.action';
import * as reportcacheThunks from '../../../store/reducers/reportcache/reportcache.actionThunk';
import { OrganizationReportSections } from '../../../store/reducers/reportcache/reportcache.types';
import { PendingSites, reportsubmitActions } from '../../../store/reducers/reportsubmit';
import { setActiveTab, setSelectedSchedule, siteActions, SiteState } from '../../../store/reducers/site/site';
import { SiteData } from '../../../store/reducers/siteDataset/siteDataset.reducer';
import {
  cancelFetchSiteSchedule,
  fetchSingleSiteScheduleRequest,
  setSingleSiteScheduleData
} from '../../../store/reducers/siteSchedule/siteSchedule.action';
import { fetchMultiSiteSchedule } from '../../../store/reducers/siteSchedule/siteSchedule.actionThunk';
import { SiteScheduleDetails } from '../../../store/reducers/siteSchedule/siteSchedule.reducer';
import { toastActions } from '../../../store/reducers/toast';
import { InjectedSite, InjectedSiteStatus, ScheduleOption } from '../../../typing/types';
import { warnNoConnection } from '../../../utils/alert';
// types
import {
  CompetitorAnalysisQuestion,
  OrganizationReport,
  OrganizationReportIndex,
  Question,
  Report,
  ReportSection,
  ReportSummary,
  Site,
  SiteTabs,
  User,
  ScheduleStatusEnum,
  SiteReport
} from '../../../utils/classes';
import { errorLogger } from '../../../utils/errorLogger';
import { getDistance } from '../../../utils/geolocation';
import { currentPeriodEndDate } from '../../../utils/schedule';
import { ModalConfig } from '../../global/ConfirmModal';
import getDistanceTreshold from '../../submitreport/utils/getDistanceTreshold';
import useDatabaseListener from '../hooks/useDatabaseListener';
import usePeriodDate from '../hooks/usePeriodDate';
import useScheduleOptions from '../hooks/useScheduleOptions';
// hooks
import useSiteReports from '../hooks/useSiteReports';
import useSiteStatus from '../hooks/useSiteStatus';
// components
import SiteContainer from './SiteNewContainer';
import { SelectedSite } from './type';
import { QuestionTypes } from 'nimbly-common/lib/enumerators';
import { ScheduledSiteInfo } from '../../../store/reducers/schedulesites';
import { cloneDeep, set } from 'lodash';
import { SiteScheduleInfo } from '../../../utils/siteSchedule';
import SiteReportDelayAlert from './SiteReportDelayAlert';

const SiteComponent = () => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();
  const { t } = useTranslation(['site, common']);
  const navigation = useNavigation<StackNavigationProp<HomeStackParamList | ScheduleStackParamList>>();
  const route = useRoute<RouteProp<HomeStackParamList | ScheduleStackParamList, 'Site'>>();
  let toastTimeout: any = null;

  // state
  const [isPermissionAsked, setIsPermissionAsked] = useState(false);

  // i set this to true because we cant use the geolocation yet. Need to implement this for report asap
  const [locationPermissionGranted, setLocationPermissionGranted] = useState(true);
  const [isGPSMocked, setIsGPSMocked] = useState(false);
  const [isGPSError, setIsGPSError] = useState(false);
  const [isOffline, setIsOffline] = useState(false);
  const [modalConfirmVisible, setModalConfirmVisible] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [showFooter, setShowFooter] = useState<boolean>(false);
  const [isAdhoc, setIsAdhoc] = useState<boolean>(false);
  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    title: '',
    subtitle: '',
    // eslint-disable-next-line no-empty
    options: [{ text: t('common:cancel'), action: () => null, style: 'cancel' }]
  });

  const [loadingNavQuestionnaire, setLoadingNavQuestionnaire] = useState(false);

  // redux state
  const selectedTab = useSelector<RootState, SiteTabs>(state => state.site.activeTab);
  const cacheLocation = useSelector<RootState, CurrentLocation>(state => state.geolocation.cacheLocation);
  const auth = useSelector<RootState, FirebaseReducer.AuthState>(state => state.firebase.auth);
  const isDummy = useSelector<RootState, boolean>(state => state.account.isDummy);
  const organization = useSelector<RootState, Organization | null | undefined>(
    state => state.organization.myOrganization
  );
  const selectedSiteKey = useSelector<RootState, string | null>(state => state.site.selectedSiteKey!);
  const selectedSite = useSelector<RootState, SelectedSite>(state => state.site.selectedSite!);
  const language = useSelector<RootState, LanguageType>(state => state.firebase.profile.language);
  const profileColor = useSelector<RootState, '#3cd070' | '#a6192e'>(state => state.profiletheme.color);
  const selectedSchedule = useSelector<RootState, Partial<ScheduleOption> | null>(state => state.site.selectedSchedule);
  const profile = useSelector<RootState, FirebaseReducer.Profile<User>>(state => state.firebase.profile);
  const cacheReportIndex = useSelector<RootState, OrganizationReportIndex>(state => state.reportcache.summaries);
  const cacheReports = useSelector<RootState, OrganizationReport>(state => state.reportcache.reports);
  const siteSchedule = useSelector<RootState, SiteScheduleDetails>(state => {
    return state.siteschedule[selectedSiteKey!];
  });
  const isConnected = useSelector<RootState, boolean>(state => state.network.isConnected);
  const journey = useSelector<RootState, JourneySchedule | null>(state => state.journey.journey);
  const journeySchedules = useSelector<RootState, JourneyScheduleDetails | null>(
    state => state.journeyschedule[selectedSiteKey!] || null
  );
  const siteReportSections = useSelector<RootState, OrganizationReportSections>(
    state => selectedSiteKey && state.reportcache.multiReports[selectedSiteKey]
  );
  const selectedSiteData = useSelector<RootState, SiteData | null>(state => {
    if (selectedSiteKey && state.sitedata) {
      return state.sitedata[selectedSiteKey];
    }
    return null;
  });

  const selectedScheduledSite = useSelector<RootState, ScheduledSiteInfo | null>(state => {
    const scheduledSites = state.scheduleSites.myScheduledSites;
    if (!scheduledSites) return null;
    return scheduledSites.find(site => site.siteKey === selectedSite?.siteID) ?? null;
  });

  const questionnaireIndexes = useSelector<RootState, { [key: string]: QuestionnaireIndex } | null>(
    state => state.questionnaireIndex.questionnaireIndex
  );
  const isTutorialMode = useSelector<RootState, boolean>(state => state.tutorial.isTutorialMode);
  const pendingSites = useSelector<RootState, PendingSites>(state => state.reportsubmit.pendingSites);

  // journey should follow single site flow
  const isMultiSite = useMemo(() => selectedSite?.isMultiSite && !journey, [selectedSite, journey]);
  const isLeader = useMemo(() => {
    return selectedSite && selectedSite?.assignedAuditor === auth.uid;
  }, [selectedSite, isMultiSite, auth]);

  // HOOKS
  const { startDate, endDate, dateReady } = usePeriodDate(organization!.schedule);
  useDatabaseListener(organization?.organizationID!, selectedSiteKey!, selectedSite!, startDate, endDate, dispatch);

  const [selectedDateCalendar, setSelectedDateCalendar] = useState<string | null>(null);
  useEffect(() => {
    if (activeTab === 'outlet') {
      setSelectedDateCalendar(prev => {
        const siteSchedules = journey ? (journeySchedules! as SiteScheduleDetails) : siteSchedule || null;

        const timezone = selectedSite?.timezone;
        const siteDateTimeRaw = siteSchedules?.singleSchedules?.[0]?.siteDateTime;

        if (!prev || !timezone || !siteDateTimeRaw) {
          return prev;
        }

        const siteDateTime = moment.tz(siteDateTimeRaw, timezone).format('YYYY-MM-DD');

        return siteDateTime;
      });
    }
  }, [selectedSite, siteSchedule, journeySchedules, journey, selectedDateCalendar]);
  const { mergedSiteReports, mergedSiteSummaries } = useSiteReports(
    cacheReports[selectedSiteKey!],
    cacheReportIndex[selectedSiteKey!],
    !selectedSiteData ? null : selectedSiteData?.report || null,
    selectedSiteData?.summary || null
  );

  const siteStatus = useSiteStatus({
    auth,
    isDummy,
    selectedSite,
    selectedSiteKey,
    selectedSiteData,
    pendingSites,
    startDate,
    endDate,
    mergedSiteSummaries,
    isJourney: !!journey
  });

  const useScheduleOptionsSelector = useMemo(
    () => ({
      siteReports: mergedSiteReports,
      siteSummaries: mergedSiteSummaries,
      siteSchedules: journey ? (journeySchedules! as SiteScheduleDetails) : siteSchedule || null,
      pendingReports:
        selectedSiteKey && pendingSites[selectedSiteKey] ? pendingSites[selectedSiteKey].pendingSingle || null : null,
      selectedSite: selectedSite!,
      isDummy: isDummy,
      organizationSchedule: organization!.schedule,
      pendingMultiSiteReportKey: siteStatus.pendingKey,
      selectedDateCalendar: selectedDateCalendar,
      showFooter
    }),
    [
      selectedSiteData?.schedule,
      mergedSiteReports,
      mergedSiteSummaries,
      selectedSiteKey,
      pendingSites,
      isDummy,
      siteSchedule,
      journeySchedules,
      auth.uid!,
      selectedSite!,
      organization,
      siteStatus,
      selectedDateCalendar,
      showFooter
    ]
  );
  const scheduleOptions = useScheduleOptions(useScheduleOptionsSelector);

  // immediately set schedule if is multisite
  useEffect(() => {
    if (!isMultiSite) {
      return;
    }

    if (scheduleOptions.length === 0) {
      dispatch(setSelectedSchedule(null));
      return;
    }

    // multisite schedule should only be 1 option. change this if that condition is changed
    const schedule = scheduleOptions[0];

    if (schedule.status === ScheduleStatusEnum.MAKEUP && schedule.makeUpDates.length) {
      // select the latest date
      schedule.makeUpDates.sort();

      const selectedMakeUpDate = schedule.makeUpDates[schedule.makeUpDates.length - 1];

      setSelectedDateCalendar(selectedMakeUpDate);
    }

    dispatch(setSelectedSchedule(scheduleOptions[0]));
  }, [isMultiSite, scheduleOptions, dispatch, siteSchedule]);

  const locationValid = selectedSite?.coordinates?.latitude !== 0 && selectedSite?.coordinates?.longitude !== 0;

  let scheduleStatus: InjectedSiteStatus = 'none-due';

  if (!locationValid) {
    scheduleStatus = 'error-location-not-found';
  } else if (selectedSchedule?.status) {
    scheduleStatus = selectedSchedule.status;
  }

  const activeTab = useSelector((state: RootState) => state.site.activeTab);
  useEffect(() => {
    if (activeTab === 'outlet') {
      setSelectedDateCalendar(
        moment.tz(selectedSchedule?.siteDateTime ?? '', selectedSite?.timezone ?? '').format('YYYY-MM-DD')
      );
    }
  }, [selectedSchedule?.siteDateTime, selectedSite?.timezone, activeTab]);
  // redux action
  const getSingleSiteSchedule = () => {
    let start = startDate;
    let end = endDate;

    if (activeTab === 'calendar') {
      const selected = moment(selectedDateCalendar);
      start = selected.add(-30, 'days').format('YYYY-MM-DD');
      end = selected.add(60, 'days').format('YYYY-MM-DD');
    } else {
      const today = moment().startOf('day');
      start = today.add(-1, 'day').format('YYYY-MM-DD');
      end = today.add(2, 'day').format('YYYY-MM-DD');
    }

    if (!isConnected) {
      if (!journey && !isMultiSite) {
        dispatch(
          setSingleSiteScheduleData({
            siteKey: selectedSiteKey!,
            scheduleInfo: cachedSiteSchedules || []
          })
        );
      }
      return;
    }
    if (!journey) {
      dispatch(
        fetchSingleSiteScheduleRequest({
          siteKey: selectedSiteKey!,
          site: selectedSite!,
          startDate: start,
          endDate: end,
          userID: auth.uid,
          organizationID: organization?.organizationID || ''
        })
      );
    } else {
      dispatch(
        fetchJourneyScheduleRequest({
          siteKey: selectedSiteKey!,
          site: selectedSite!,
          startDate: start,
          endDate: end,
          organizationID: organization?.organizationID || ''
        })
      );
    }
  };

  // set active tab to redux state
  const setSiteActiveTab = (tab: SiteTabs) => {
    dispatch(setActiveTab(tab));
  };

  const removeSiteReportSections = (siteKey: string, reportKey: string, backup?: boolean) => {
    dispatch(reportcacheThunks.removeSiteReportSections(siteKey, reportKey, backup));
  };

  const removeCacheReportAndSummary = (siteKey: string, reportKey: string) => {
    dispatch(reportcacheActions.removeCacheReportAndSummary(siteKey, reportKey));
  };

  const getMultiSiteSchedule = async (siteKey: string) => {
    setIsBusy(true);
    await dispatch(fetchMultiSiteSchedule(siteKey));
    setIsBusy(false);
  };

  const createToast = (message: string, showDismissButton?: boolean, duration?: number) => {
    setIsBusy(false);
    setLoadingNavQuestionnaire(false);

    if (showFooter) {
      setShowFooter(false);

      toastTimeout = setTimeout(() => {
        dispatch(toastActions.createToast(message, showDismissButton, duration));
      }, 600);
      return;
    }

    dispatch(toastActions.createToast(message, showDismissButton, duration));
  };

  const setGPSLocation = (location: MapLocation) => {
    dispatch(calibrationActions.setGPSLocation(location));
  };

  const setPinLocation = (location: MapLocation) => {
    dispatch(calibrationActions.setPinLocation(location));
  };

  const logActivity = async (data: Activity) => {
    dispatch(logActivityAsync.request(data));
  };

  const removeDraftReport = async (siteKey: string, reportKey: string, isMakeup: boolean | undefined) => {
    await dispatch(reportcacheThunks.removeDraftReport(siteKey, reportKey));

    const schedule = selectedSchedule;

    schedule!.draftReport = null;
    schedule!.status = 'ready';

    // set status is make up
    if (isMakeup) {
      schedule!.status = 'makeup';
    }

    // if not has schedule status will be none-due
    if (schedule?.nextDue !== moment().format('YYYY-MM-DD') || !schedule.nextDue) {
      schedule!.status = 'none-due';
    }

    if (schedule?.totalScheduled === 0) {
      schedule!.status = 'adhoc';
    }

    await dispatch(setSelectedSchedule(schedule!));

    setIsBusy(false);
  };

  const createOrUpdateReport = async (siteKey: string, reportKey: string, report: Report, summary: ReportSummary) => {
    await dispatch(reportcacheThunks.createOrUpdateReport(siteKey, reportKey, report, summary));
  };

  const siteState = useSelector<RootState, SiteState>(state => state.site);

  const setSiteConfig = (siteKey: string, hasInventory: boolean, emailTargets: string[], signatures: number) => {
    const { selectedSiteCompletedCount, selectedSiteLastReport, selectedSiteSchedule } = siteState;

    dispatch(
      siteActions.selectSite(
        siteKey,
        { ...selectedSite!, hasInventory, emailTargets, signatures },
        selectedSiteSchedule,
        selectedSiteCompletedCount,
        selectedSiteLastReport
      )
    );
  };

  const selectReport = (key: string | null, value: Report | ReportSection | null) =>
    dispatch(reportActions.selectReport(key, value));

  const setReportToUpload = (
    site: Site | InjectedSite,
    siteKey: string,
    questionnaireName: string,
    reportKey: string,
    report: Report,
    reportSummary: ReportSummary
  ) => {
    dispatch(reportsubmitActions.setReportToUpload(site, siteKey, questionnaireName, reportKey, report, reportSummary));
  };

  // immediately set schedule if is multisite
  useEffect(() => {
    if (!isMultiSite || !scheduleOptions?.length) {
      return;
    }
    // multisite schedule should only be 1 option. change this if that condition is changed
    const schedule = scheduleOptions[0];

    if (schedule.status === 'makeup' && schedule.makeUpDates.length) {
      // select the latest date
      schedule.makeUpDates.sort();

      const selectedMakeUpDate = schedule.makeUpDates[schedule.makeUpDates.length - 1];

      setSelectedDateCalendar(selectedMakeUpDate);
    }

    dispatch(setSelectedSchedule(scheduleOptions[0]));
  }, [isMultiSite, scheduleOptions, dispatch, siteSchedule]);

  // fetch questionnaire index if still empty
  useEffect(() => {
    if (!questionnaireIndexes) {
      dispatch(questionnaireIndexActions.fetchQuestionnaireIndex.request());
    }
  }, [questionnaireIndexes]);

  useEffect(() => {
    const cleanReport = () => {
      const reportKey = siteReportSections ? Object.keys(siteReportSections)[0] : null;
      const selectedSiteReportIndex = selectedSiteData?.summary;

      if (
        reportKey &&
        selectedSiteReportIndex &&
        selectedSiteReportIndex[reportKey] &&
        (selectedSiteReportIndex[reportKey].status === 'complete' ||
          selectedSiteReportIndex[reportKey].status === 'expired')
      ) {
        removeSiteReportSections(selectedSiteKey!, reportKey);
      }

      const selectedSiteCacheReport = cacheReportIndex && cacheReportIndex[selectedSiteKey!];
      Object.keys(selectedSiteCacheReport || {}).map(key => {
        if (
          !selectedSiteReportIndex ||
          !selectedSiteReportIndex[key] ||
          selectedSiteReportIndex[key].status === 'draft'
        ) {
          return;
        }
        // 'complete' or 'expired'
        removeCacheReportAndSummary(selectedSiteKey!, key);
      });
    };

    let cleanDebounce: any;
    if (isFocused) {
      // debounce handler to reduce too many render
      cleanDebounce = setTimeout(() => cleanReport(), 150);
    }
    return () => {
      if (cleanDebounce) {
        clearTimeout(cleanDebounce);
        cleanDebounce = null;
      }
    };
  }, [cacheReportIndex, selectedSiteData?.summary, siteReportSections, selectedSiteKey, isFocused]);

  // fetch single audit schedule
  useEffect(() => {
    if (!dateReady || !isFocused) {
      return;
    }

    getSingleSiteSchedule();
  }, [selectedSite, selectedSiteKey, startDate, endDate, dateReady, isFocused, selectedDateCalendar, activeTab]);
  useEffect(() => {
    let timeout: any;
    const monitorOffline = () => {
      timeout = setTimeout(() => {
        setIsOffline(!isConnected);
      }, 10 * 1000);
    };

    const clear = () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    };

    if (isFocused && isBusy) {
      if (isConnected) {
        setIsOffline(false);
      } else {
        monitorOffline();
      }
    } else {
      setIsOffline(false);
      clear();
    }

    return () => {
      clear();
    };
  }, [isFocused, isConnected, isBusy]);

  // immediately set questionnaire if has `scheduleKey` payload from notification
  useEffect(() => {
    if (route.params && route.params.selectedScheduleKey) {
      if (!selectedSchedule) {
        const { selectedScheduleKey } = route.params;

        const selectedScheduleQuestionnaire = scheduleOptions.find(el => el.key === selectedScheduleKey);
        if (selectedScheduleQuestionnaire) {
          debouncedSetQuestionnaire(selectedScheduleQuestionnaire);
        }
      }
    }
  }, [route.params, scheduleOptions]);

  // role access
  const roleAccessAdhoc = useSelector<RootState, boolean>(
    state => state.account.role?.resourceMap['application:report:adhoc'].create || false
  );

  // feature access
  const featureAccessAdhoc = useSelector<RootState, boolean>(
    state => state.featureAccess.features[enums.Features.ADHOC_REPORTING]
  );

  const featureAccessGeo = useSelector<RootState, boolean>(
    state => state.featureAccess.features[enums.Features.GEO_TAGGING]
  );

  const navCalibrate = useCallback(
    async (data: any) => {
      firebase
        .analytics()
        .logEvent('click_see_map', { category: 'site_details_page', siteName: selectedSite?.name || '' });

      const calibrationRef = firebase.database().ref(`/calibration/${organization?.organizationID}/${selectedSiteKey}`);
      calibrationRef.keepSynced(true);
      const editorSnap = await calibrationRef.once('value');
      if (editorSnap.val() && editorSnap.val() !== auth.uid) {
        createToast(t('site:outlet.error.alreadySetLocation'), true, 5000);
      } else {
        const location = {
          coordinates: { latitude: data.coords.latitude, longitude: data.coords.longitude },
          locationName: '',
          address: '',
          country: '',
          province: '',
          city: ''
        };

        setGPSLocation(location);
        setPinLocation(location);
        navigation.navigate('Calibration', {
          originTab: route.params.originTab,
          profileColor: profileColor,
          siteName: selectedSite!.name
        });
      }
    },
    [profile, auth, language, createToast, navigation, selectedSite]
  );

  const handleCalibrateLocation = useCallback(() => {
    if (organization && !organization.allowCalibration && featureAccessGeo) {
      createToast(t('site:outlet.error.notAllowedSetLocation'), true, 5000);
    } else {
      navCalibrate(cacheLocation);
    }
  }, [language, organization, createToast, navCalibrate]);

  const handleSelectReportingType = (value: boolean) => {
    setIsAdhoc(value);
  };

  const deleteDraftReport = async (schedule: ScheduleOption, isMakeup: boolean | undefined) => {
    setIsBusy(true);
    try {
      // track user activity
      try {
        logUserActivity({
          entity: enums.Entity.REPORT,
          entityID: schedule.draftReport!.key,
          permission: enums.Permission.Delete,
          type: enums.ActivityType.DELETE_REPORT,
          message: `Delete report: ${schedule.draftReport!.key}`,
          snapshot: {
            report: mergedSiteReports[schedule.draftReport!.key],
            summary: mergedSiteSummaries[schedule.draftReport!.key]
          }
        });
      } catch (error) {
        //
      }

      await removeDraftReport(selectedSiteKey!, schedule.draftReport!.key, isMakeup);
    } catch (error) {
      setShowFooter(false);
      createToast(error.message, true, 1000);
    } finally {
      setIsBusy(false);
    }
  };

  const handleConfirmDeleteDraftReport = async (schedule: ScheduleOption, isMakeup: boolean | undefined) => {
    const concurrentModalConfig: ModalConfig = {
      title: t('common:reset'),
      subtitle: t('site:outlet.prompt.deleteDraft'),
      options: [
        {
          text: t('common:yes'),
          action: async () => {
            setModalConfirmVisible(false);
            await deleteDraftReport(schedule, isMakeup);
          },
          style: 'confirm'
        },
        {
          text: t('common:cancel'),
          action: () => {
            setModalConfirmVisible(false);
          },
          style: 'cancel'
        }
      ]
    };
    setModalConfig(concurrentModalConfig);
    setModalConfirmVisible(true);
  };

  const navQuestionnaire = (reportKey: string, report: Report, schedule: ScheduleOption) => {
    setIsBusy(false);
    setShowFooter(false);
    setLoadingNavQuestionnaire(false);
    const emailTargets = schedule.emailTargets || [];
    const signatures = schedule.signatures || 0;
    let hasInventory = false;
    const duplicateQuestions = report.questions.slice();
    const updateQuestions = duplicateQuestions.map((question: Question) => {
      const updatedQuestion = Object.assign({}, question);
      if (updatedQuestion.type === 'inventory' || updatedQuestion.type === 'inventory-v2') {
        hasInventory = true;
      }

      if (updatedQuestion.type === 'competitor' && !updatedQuestion.competitorAnalysis) {
        updatedQuestion.competitorAnalysis = new CompetitorAnalysisQuestion();
      }
      return updatedQuestion;
    });
    const duplicateReport = Object.assign({}, report);
    duplicateReport.questions = updateQuestions as Question[];
    selectReport(reportKey, duplicateReport);

    setSiteConfig(selectedSiteKey!, hasInventory, emailTargets, signatures);

    setSelectedSchedule({
      ...schedule,
      status: 'draft',
      draftReport: { key: reportKey, date: moment.parseZone(duplicateReport.datetimeScheduled).format('YYYY-MM-DD') }
    });

    navigation.navigate('Questionnaire', {
      originTab: route.params.originTab,
      siteName: selectedSite!.name,
      profileColor: profileColor
    });
  };

  const handleEnterLobby = (draftReportKey: string | null, newReportKey?: string) => {
    setShowFooter(false);
    const sectionDraftKey = siteReportSections ? Object.keys(siteReportSections).sort()[0] : null;
    const selectedReportKey = draftReportKey || newReportKey || sectionDraftKey;

    // TODO: Handle SiS has inventory question
    selectReport(selectedReportKey, null);
    setIsBusy(false);

    navigation.navigate('Lobby', {
      profileColor: profileColor,
      originTab: route.params.originTab,
      siteName: selectedSite!.name
    });
  };

  const makeNewReport = async (
    now: string,
    date: string,
    isMakeUp: boolean,
    makeUpReason: string,
    schedule: ScheduleOption
  ) => {
    let hasDuplicate = false;
    let hasDraft = false;
    for (const [key, sum] of Object.entries(mergedSiteSummaries)) {
      const datetimeScheduled = moment(sum.datetimeScheduled).format('YYYY-MM-DD');

      hasDuplicate =
        // change this key slicing when report is rewired to new db
        datetimeScheduled === date &&
        sum.scheduleKey === schedule?.key &&
        sum.status === 'complete' &&
        !sum.isAdhoc &&
        !sum &&
        !isDummy;

      hasDraft = datetimeScheduled === date && sum.scheduleKey === schedule?.key && sum.status === 'draft' && !isDummy;

      if (hasDuplicate || hasDraft) {
        setIsBusy(false);
        setLoadingNavQuestionnaire(false);
        alert('alreadyCompletedReport');
      }
    }

    if (hasDuplicate || hasDraft) {
      setTimeout(() => {
        const title = hasDuplicate ? t('site:outlet.alreadyCompletedReport') : t('site:outlet.ongoingDraftFound');

        const message = hasDuplicate
          ? t('site:outlet.alreadyCompletedReport')
          : t('site:outlet.ongoingDraftFound', { title: schedule.title });

        const modalConf: ModalConfig = {
          title: title,
          subtitle: message,
          options: [
            {
              text: t('site:ok'),
              style: 'confirm',
              action: () => {
                setIsBusy(false);
                setLoadingNavQuestionnaire(false);
                setModalConfirmVisible(false);
              }
            }
          ]
        };

        setModalConfig(modalConf);
        setModalConfirmVisible(true);
      }, 500);

      return;
    }

    logUserActivity({
      entity: enums.Entity.REPORT,
      entityID: selectedSchedule?.key,
      permission: enums.Permission.Create,
      type: enums.ActivityType.START_REPORT,
      message: `Start report: ${selectedSchedule?.key}`
    });

    const periodEnd = currentPeriodEndDate(organization!.schedule);
    let scheduledAt = selectedSite?.timezone ? moment.tz(date, selectedSite?.timezone) : moment(date);

    if (schedule && schedule.hasStrictTime && schedule.startTime !== null) {
      scheduledAt = scheduledAt.startOf('day').add(schedule.startTime, 'minutes');
    }

    const report = new Report(
      [],
      [],
      'draft',
      selectedSiteKey!,
      auth.uid,
      [],
      // Due date for report should always be the end of period.
      // This stands even if there is another report due before end of period.
      periodEnd ? periodEnd.toISOString(true) : '',
      now,
      '',
      '',
      // scheduled at
      scheduledAt.toISOString(true),
      isMakeUp || false,
      makeUpReason || '',
      '',
      [],
      [],
      isAdhoc,
      false
    );

    if (schedule) {
      report.scheduleKey = schedule.key;
      report.hasDeadline = schedule.hasStrictTime;
      report.startTime = schedule.startTime;
      report.endTime = schedule.endTime;
      report.selfieSignatures = schedule.selfieSignatures;
    }

    const summary: ReportSummary = {
      auditor: auth.uid,
      datetimeIn: now,
      datetimeOut: '',
      datetimeUpdated: now,
      status: 'draft',
      questionnaire: '',
      isMakeUp,
      datetimeScheduled: scheduledAt.toISOString(true),
      isAdhoc,
      scheduleKey: schedule ? schedule.key : ''
    };

    if (!isMultiSite) {
      if (!selectedSiteData || !selectedSiteData!.siteQuestionnaireSchedule) {
        if (!isTutorialMode) {
          // if (__DEV__) {
          //   //   // eslint-disable-next-line no-console
          //   console.warn(`No questionnaire schedule - ${date}`);
          // }
          // throw new Error('No questionnaire schedule');
        }
      }
      // TODO: Handle missing questionnaire

      if (!isMultiSite && !schedule.questionnaire) {
        if (__DEV__) {
          // eslint-disable-next-line no-console
          console.warn(`No questionnaire selected - ${date}`);
        }
        throw new Error('No questionnaire selected');
      }

      report.questions = schedule.questionnaire.questionnaire.questions;
      report.questionnaire = schedule.questionnaire.latest;
      summary.questionnaire = schedule.questionnaire.latest;

      const siteSKUs = selectedSiteData?.siteSKU;

      if (siteSKUs && Object.keys(siteSKUs).length > 0) {
        const inventoryQuestionIndex = report.questions.findIndex(question => question.type === 'inventory-v2');
        const inventoryQuestion = inventoryQuestionIndex > -1 ? report.questions[inventoryQuestionIndex] : null;
        if (inventoryQuestion) {
          inventoryQuestion.skuInventory = {};
          Object.keys(siteSKUs).forEach(skuKey => {
            const skuProfile = siteSKUs![skuKey].skuDetail;
            if (!skuProfile || skuProfile.disabled || siteSKUs![skuKey].disabled) {
              // skip
              // setIsTransitioned(true);
              return false;
            }
            inventoryQuestion.skuInventory![skuKey] = {
              in: 0,
              out: 0,
              final: -1
            };
          });
          delete inventoryQuestion.inventory;
        }
      }
    }

    const reportKey = `${date}_${moment(now).valueOf()}`;

    try {
      logUserActivity({
        entity: enums.Entity.REPORT,
        entityID: selectedSchedule?.key,
        permission: enums.Permission.Create,
        type: enums.ActivityType.START_REPORT,
        message: `[DEBUG] Create new report (makeNewReport): ${selectedSchedule?.key} - ${reportKey}`
      });
      //function for create report cache -> make draft
      await createOrUpdateReport(selectedSiteKey!, reportKey, report, summary);

      if (isTutorialMode) {
        // do nothing
      } else {
        firebase.analytics().logEvent('report_started');
        firebase.analytics().logEvent(`report_${isAdhoc ? 'adhoc' : 'default'}`);
      }

      if (!isMultiSite) {
        navQuestionnaire(reportKey, report, schedule);
      } else {
        handleEnterLobby(null, reportKey);
      }
    } catch (error) {
      createToast(error.message, true, 1000);
    } finally {
      setLoadingNavQuestionnaire(false);
      setIsBusy(false);
    }
  };

  const resumeReport = async (reportKey: string, schedule: ScheduleOption) => {
    const selectedReport = mergedSiteReports[reportKey];
    const selectedSummary = mergedSiteSummaries[reportKey];

    // track user activity
    try {
      logUserActivity({
        entity: enums.Entity.REPORT,
        entityID: reportKey,
        permission: enums.Permission.Edit,
        type: enums.ActivityType.RESUME_REPORT,
        message: `Resume report: ${reportKey}`
      });
    } catch (error) {
      //
    }

    if (!selectedReport || !selectedSummary) {
      const newError = new Error(
        `Cannot find ${!selectedReport ? 'report; ' : ''} ${
          !selectedSummary ? 'summary' : ''
        } when trying to resume draft report`
      );

      errorLogger(
        auth.uid,
        profile.organization,
        selectedSiteKey!,
        reportKey,
        newError,
        'SiteContainer.tsx/resumeReport'
      );

      createToast(t('site:error.missingDraftReport'), true, 1000);
      setLoadingNavQuestionnaire(false);
      setIsBusy(false);
      return;
    }

    try {
      await createOrUpdateReport(selectedSiteKey!, reportKey, selectedReport, selectedSummary);

      if (!isMultiSite) {
        navQuestionnaire(reportKey, selectedReport, schedule);
      } else {
        handleEnterLobby(null, reportKey);
      }
    } catch (error) {
      createToast(error.message, true, 1000);
    } finally {
      setLoadingNavQuestionnaire(false);
      setIsBusy(false);
    }
  };

  const warnTooFar = useCallback(() => {
    const modalConf: ModalConfig = {
      title: 'Check-In',
      subtitle: t('site:outlet.error.farFromLocation'),
      options: [
        {
          text: t('site:ok'),
          style: 'confirm',
          action: () => {
            setModalConfirmVisible(false);
            setIsBusy(false);
          }
        }
      ]
    };

    setModalConfirmVisible(true);
    setModalConfig(modalConf);

    return;
  }, [language, selectedSite]);

  const confirmLocation = async (data: any, isMakeUp?: boolean, makeUpDate?: string, makeUpReason?: string) => {
    if (!isMultiSite && !selectedSchedule) {
      const newError = new Error('Confirming location without selected schedule');
      errorLogger(auth.uid, profile.organization, selectedSiteKey!, '', newError, 'SiteContainer.tsx/confirmLocation');
      setIsBusy(false);
      createToast(t('site:outlet.error.failedCompilingData'), true, 5000);
      return;
    }

    try {
      const distance = data ? getDistance(cacheLocation, selectedSite!.coordinates) : 9999;
      const geoPrecision =
        selectedSite?.radius && selectedSite.radius !== 'default' ? selectedSite.radius : organization?.geoPrecision;
      const threshold = getDistanceTreshold(geoPrecision as 'low' | 'medium' | 'high' | 'none');
      // Distance threshold for success (in km);
      if (geoPrecision !== 'none' && (distance === null || distance >= threshold) && !isDummy) {
        warnTooFar();
        setIsBusy(false);
        setLoadingNavQuestionnaire(false);
        return;
      }
      setModalConfirmVisible(false);

      if (!siteStatus?.status) {
        setShowFooter(false);
        const modalConf: ModalConfig = {
          title: t('site:outlet.placeholder.noSchedule'),
          subtitle: t('site:outlet.error.failedCompilingData'),
          options: [
            {
              text: t('site:ok'),
              style: 'confirm',
              action: () => {
                setModalConfirmVisible(false);
                setIsBusy(false);
                return navigation.reset({
                  index: 0,
                  routes: [{ name: route.params.originTab || 'HomeTab' }]
                });
              }
            }
          ]
        };

        setModalConfirmVisible(true);
        setModalConfig(modalConf);
        return;
      }

      if (isMultiSite && !isLeader && siteStatus?.status !== 'draft') {
        setShowFooter(false);
        const modalConf: ModalConfig = {
          title: t('site:reportHasNotStarted'),
          subtitle: t('site:leaderMustCheckIn'),
          options: [
            {
              text: t('site:ok'),
              style: 'confirm',
              action: () => {
                setModalConfirmVisible(false);
                setIsBusy(false);
                return navigation.reset({
                  index: 0,
                  routes: [{ name: route.params.originTab || 'HomeTab' }]
                });
              }
            }
          ]
        };

        setModalConfirmVisible(true);
        setModalConfig(modalConf);

        return;
      }

      const now = moment().toISOString(true);

      let date = now.slice(0, 10);

      if (isDummy || isAdhoc) {
        date = now.slice(0, 10);
        if (isMakeUp) {
          date = selectedDateCalendar;
        }
      } else if (isMakeUp) {
        if (makeUpDate) {
          date = selectedDateCalendar;
        } else {
          // most likely wont happen but just in case.
          // TODO: make test to make sure it won't happen
          const message = t('');
          createToast(message, true, 1000);

          errorLogger(
            auth.uid,
            profile.organization,
            selectedSiteKey!,
            '',
            new Error(message),
            'Site.tsx/confirmLocation'
          );

          return;
        }
      } else if (isMultiSite && siteStatus.nextDue) {
        date = siteStatus.nextDue;
      } else if (!isMultiSite && selectedSchedule!.nextDue) {
        date = selectedSchedule!.nextDue;
      }

      const isTeamAudit = isMultiSite;
      if (isTeamAudit && selectedSchedule?.startDate) {
        date = selectedSchedule.startDate;
      }

      if (!isMultiSite) {
        if (selectedSchedule!.status === 'draft' && selectedSchedule!.draftReport) {
          resumeReport(selectedSchedule!.draftReport.key, selectedSchedule! as ScheduleOption);
          return;
        }

        if (selectedSchedule!.status === 'ready' || selectedSchedule!.status === 'makeup' || isAdhoc) {
          logUserActivity({
            entity: enums.Entity.REPORT,
            entityID: selectedSchedule?.key,
            permission: enums.Permission.Create,
            type: enums.ActivityType.START_REPORT,
            message: `[DEBUG] Processing check-in (confirmLocation): ${selectedSchedule?.key}`
          });

          makeNewReport(now, date, !!isMakeUp, makeUpReason || '', selectedSchedule! as ScheduleOption);
          return;
        }

        setIsBusy(false);
        return;
      }

      const siteReportsKey = Object.keys(mergedSiteReports ?? {})
        .reverse()
        .find(key => {
          return key.includes(selectedSchedule?.startDate ?? '');
        });
      const siteReport = mergedSiteReports[siteReportsKey ?? ''];
      const hasCustomTeamAuditPendingReport = isTeamAudit && siteStatus?.status === 'draft' && siteReport;

      const reportKey = isTeamAudit ? selectedSchedule?.draftReport?.key : siteStatus.draftKey;

      if (siteStatus.status === 'draft' && siteStatus.draftKey && hasCustomTeamAuditPendingReport) {
        resumeReport(String(reportKey), selectedSchedule! as ScheduleOption);
      } else if (siteStatus.status === 'ready' || siteStatus.status === 'makeup' || isAdhoc) {
        makeNewReport(now, date, isMakeUp || false, makeUpReason || '', selectedSchedule! as ScheduleOption);
      } else {
        // TODO: Handle out of scope start report
        // eslint-disable-next-line no-console
        console.warn('none-due called');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedSetQuestionnaire = React.useCallback(
    debounce((schedule: ScheduleOption) => {
      if (!isEqual(selectedSchedule, schedule) || !selectedSchedule) {
        dispatch(setSelectedSchedule(schedule));
      }
    }, 300),
    [selectedSchedule]
  );

  useEffect(() => {
    if (route?.params?.selectedScheduleKey && !selectedSchedule) {
      const { selectedScheduleKey } = route.params;
      const selectedScheduleQuestionnaire = scheduleOptions.find(el => el.key === selectedScheduleKey);
      if (selectedScheduleQuestionnaire) {
        debouncedSetQuestionnaire(selectedScheduleQuestionnaire);
      }
    }
  }, [route.params, scheduleOptions, debouncedSetQuestionnaire]);

  const logUserActivity = async ({
    entity,
    entityID,
    permission,
    type,
    message,
    snapshot = {}
  }: {
    entity?: enums.Entity;
    entityID?: string;
    permission?: enums.Permission;
    type?: enums.ActivityType;
    message?: string;
    snapshot?: any;
  }) => {
    // track user activity
    try {
      const activity = new Activity({
        entity,
        entityID,
        permission,
        type,
        message,
        timestamp: new Date(),
        device: {
          appversion: appversion,
          ...(await getDeviceProfile())
        },
        snapshot: {
          userData: {
            email: auth.email,
            userID: auth.uid,
            displayName: auth.displayName
          },
          lastSignInTime: new Date((auth as any).metadata.lastSignInTime),
          platform: 'app',
          ...snapshot
        }
      });

      logActivity(activity);
    } catch (error) {}
  };

  const handleCheckIn = (isMakeUp?: boolean, makeUpDate?: string, makeUpReason?: string) => {
    setLoadingNavQuestionnaire(true);
    logUserActivity({
      entity: enums.Entity.REPORT,
      entityID: selectedSchedule?.key,
      permission: enums.Permission.Create,
      type: enums.ActivityType.START_REPORT,
      message: `[DEBUG] Processing check-in (handleCheckIn): ${selectedSchedule?.key}`
    });

    const locationHandler = (data: any) => confirmLocation(data, isMakeUp, makeUpDate, makeUpReason);
    locationHandler(cacheLocation);
  };

  const handleConfirmCheckIn = useCallback(
    debounce(() => {
      const concurrentModalConfig: ModalConfig = {
        title: t('site:checkIn'),
        subtitle: t('site:confirmCheckIn'),
        options: [
          {
            text: t('common:yes'),
            action: debounce(() => {
              firebase.analytics().logEvent('click_confirm_check_in', {
                category: 'site_details_page',
                siteName: selectedSite?.name || 'unknown',
                questionnaire: selectedSchedule?.title || 'unknown'
              });
              logUserActivity({
                entity: enums.Entity.REPORT,
                entityID: selectedSchedule?.key,
                permission: enums.Permission.Create,
                type: enums.ActivityType.START_REPORT,
                message: `[DEBUG] Check-in confirmed (handleConfirmCheckIn): ${selectedSchedule?.key}`
              });
              handleCheckIn();
              setIsBusy(false);
            }, 100),
            style: t('common:yes')
          },
          {
            text: t('common:cancel'),
            action: () => {
              setIsBusy(false);
              setModalConfirmVisible(false);
            },
            style: t('common:no')
          }
        ]
      };
      setModalConfig(concurrentModalConfig);
      setModalConfirmVisible(true);
    }, 250),
    [handleCheckIn, language]
  );

  const hasCheckedIn = useMemo(() => {
    return (
      isMultiSite &&
      siteStatus.status === 'draft' &&
      !!siteStatus.draftKey &&
      !!siteReportSections &&
      !!siteReportSections[siteStatus.draftKey]
    );
  }, [siteStatus, mergedSiteSummaries, siteReportSections, selectedSite, isMultiSite]);

  const handleReviewPendingUpload = useCallback(async () => {
    setIsBusy(true);
    if (!isConnected) {
      setIsBusy(false);
      warnNoConnection(language);
      return;
    }

    const reportKey = isMultiSite
      ? siteStatus.pendingKey
      : selectedSchedule && selectedSchedule.pendingReport
      ? selectedSchedule.pendingReport.key
      : '';

    if (!reportKey) {
      const error = new Error('cannot find reportkey of pending report');
      errorLogger(
        auth.uid,
        profile.organization,
        selectedSiteKey!,
        '',
        error,
        'SiteContainer.tsx/handleReviewPendingUpload'
      );
      setIsBusy(false);
      // TODO: Handle this
      return;
    }

    const selectedSummary = mergedSiteSummaries && mergedSiteSummaries[reportKey];
    const selectedReport = mergedSiteReports && mergedSiteReports[reportKey];

    if (!selectedSummary || !selectedReport) {
      const error = new Error('cannot find report and/or summary of pending report');
      errorLogger(
        auth.uid,
        profile.organization,
        selectedSiteKey!,
        reportKey,
        error,
        'SiteContainer.tsx/handleReviewPendingUpload'
      );

      // TODO: Handle this
      setIsBusy(false);
      return;
    }

    if (reportKey) {
      selectReport(reportKey, selectedReport);
      setReportToUpload(
        selectedSite!,
        selectedSiteKey!,
        (selectedSchedule as ScheduleOption)?.title,
        reportKey,
        selectedReport,
        selectedSummary
      );

      navigation.navigate('SubmitReport', {
        profileColor: profileColor,
        originTab: route.params.originTab,
        siteName: selectedSite!.name
      });
      return;
    }
    return null;
  }, [
    selectedSchedule,
    siteStatus,
    setReportToUpload,
    navigation,
    selectedSite,
    selectedSiteKey,
    mergedSiteReports,
    mergedSiteSummaries,
    profile,
    auth,
    isConnected
  ]);

  const handleMakeupReport = (makeUpReason: string, makeUpDate: string) => {
    if (!makeUpReason || !makeUpDate) {
      Alert.alert(t('site:makeupReport'), t('site:fillMakeupField'));
      return;
    }
    handleCheckIn(true, makeUpDate, makeUpReason);
  };

  const handleSelectSchedule = useCallback((schedule: Partial<ScheduleOption>) => {
    dispatch(setSelectedSchedule(schedule));
    setShowFooter(true);
  }, []);

  useEffect(() => {
    return () => {
      setLoadingNavQuestionnaire(false);

      // clear toast timeout
      if (toastTimeout) {
        clearTimeout(toastTimeout);
      }

      // cancel fetch schedule process if still on process
      if (siteSchedule?.isLoading) {
        dispatch(cancelFetchJourney());
      }

      if (journeySchedules?.isLoading) {
        dispatch(cancelFetchSiteSchedule());
      }
      setShowFooter(false);
    };
  }, []);

  if (!isFocused || !selectedSiteData || !dateReady) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator color="#3bd070" size="large" />
        <Text>{t('site:loadingSite')}</Text>
      </View>
    );
  }
  return (
    <>
      <SiteReportDelayAlert />
      <SiteContainer
        isLoadingSchedule={(!journey ? siteSchedule?.isLoading : journeySchedules?.isLoading) || false}
        selectedTab={selectedTab}
        featureAccessAdhoc={featureAccessAdhoc}
        featureAccessGeo={featureAccessGeo}
        roleAccessAdhoc={roleAccessAdhoc}
        hasCheckedIn={Boolean(hasCheckedIn)}
        isAllowCalibration={organization?.allowCalibration! || false}
        isAdhoc={isAdhoc}
        isBusy={isBusy || siteSchedule.isLoading || loadingNavQuestionnaire}
        isFocused={isFocused}
        isLeader={isLeader}
        isGPSMocked={isGPSMocked}
        isOffline={isOffline}
        isScheduleSelected={
          selectedSite && !isMultiSite && siteSchedule && !siteSchedule!.isEmpty ? !!selectedSchedule : true
        }
        modalConfirmVisible={modalConfirmVisible}
        modalConfig={modalConfig}
        language={language}
        locationPermissionGranted={locationPermissionGranted}
        profileColor={profileColor}
        selectedDateCalendar={selectedDateCalendar}
        selectedSite={selectedSite}
        siteSchedule={siteSchedule}
        siteReport={cacheReports?.[selectedSiteKey!]}
        selectedSchedule={selectedSchedule! as ScheduleOption}
        showFooter={showFooter}
        status={scheduleStatus}
        siteReports={mergedSiteReports}
        scheduleOptions={scheduleOptions}
        getSingleSiteSchedule={getSingleSiteSchedule}
        handleMakeupReport={handleMakeupReport}
        handleSelectSchedule={handleSelectSchedule}
        handleDeleteDraftReport={handleConfirmDeleteDraftReport}
        handleSelectReportingType={handleSelectReportingType}
        onCalibrateLocation={handleCalibrateLocation}
        onConfirmCheckIn={() => {
          setIsBusy(true);
          handleConfirmCheckIn();
          logUserActivity({
            entity: enums.Entity.REPORT,
            entityID: selectedSchedule?.key,
            permission: enums.Permission.Create,
            type: enums.ActivityType.START_REPORT,
            message: `[DEBUG] Check-in confirmation (onConfirmCheckIn): ${selectedSchedule?.key}`
          });
        }}
        onCheckIn={handleCheckIn}
        onReviewPendingUpload={handleReviewPendingUpload}
        setActiveTab={setSiteActiveTab}
        setShowFooter={setShowFooter}
        setModalConfirmVisible={setModalConfirmVisible}
        setSelectedDateCalendar={setSelectedDateCalendar}
      />
    </>
  );
};

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  textLoading: {
    marginTop: 15,
    marginLeft: 5
  }
});

export default SiteComponent;
