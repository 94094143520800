import { useMemo } from 'react';

import moment from 'moment';

import cloneDeep from 'lodash/cloneDeep';

import { SiteReport, ReportIndex } from '../../../utils/classes';

/**
 * Merged cached report with uploaded reports,
 * place most updated report on top of the other one
 * TODO:
 * - find draft report
 * - find pending upload report
 */
const useSiteReports = (
  cacheSiteReports: SiteReport,
  cacheSiteSummaries: ReportIndex,
  latestReports: SiteReport | null,
  latestSummaries: ReportIndex | null | undefined
): { mergedSiteReports: SiteReport; mergedSiteSummaries: ReportIndex } => {
  const mergedSiteReports = useMemo<SiteReport>(() => {
    const merged: SiteReport = {};

    Object.keys(latestReports || {}).forEach(reportKey => {
      const report = latestReports![reportKey];
      merged[reportKey] = cloneDeep(report);
    });

    Object.keys(cacheSiteReports || {}).forEach(reportKey => {
      const report = cacheSiteReports[reportKey];
      const mergedReport = merged[reportKey];

      if (!mergedReport) {
        merged[reportKey] = cloneDeep(report);
        // continue
        return;
      }

      if (report.datetimeUpdated && !mergedReport.datetimeUpdated) {
        merged[reportKey] = cloneDeep(report);
        // continue
        return;
      }

      if (mergedReport.datetimeUpdated && !report.datetimeUpdated) {
        // continue
        return;
      }

      if (moment(mergedReport.datetimeUpdated).isAfter(report.datetimeUpdated)) {
        // continue
        return;
      }

      merged[reportKey] = cloneDeep(report);
    });

    return merged;
  }, [cacheSiteReports, latestReports]);

  const mergedSiteSummaries = useMemo<ReportIndex>(() => {
    const merged: ReportIndex = {};

    Object.keys(latestSummaries || {}).forEach(reportKey => {
      const summary = latestSummaries![reportKey];
      merged[reportKey] = cloneDeep(summary);
    });

    Object.keys(cacheSiteSummaries || {}).forEach(reportKey => {
      const summary = cacheSiteSummaries[reportKey];
      const mergedReport = merged[reportKey];

      if (!mergedReport) {
        merged[reportKey] = cloneDeep(summary);
        // continue
        return;
      }

      if (summary.datetimeUpdated && !mergedReport.datetimeUpdated) {
        merged[reportKey] = cloneDeep(summary);
        // continue
        return;
      }

      if (mergedReport.datetimeUpdated && !summary.datetimeUpdated) {
        // continue
        return;
      }

      if (moment(mergedReport.datetimeUpdated).isAfter(summary.datetimeUpdated)) {
        // continue
        return;
      }

      merged[reportKey] = cloneDeep(summary);
    });

    return merged;
  }, [cacheSiteSummaries, latestSummaries]);

  return { mergedSiteReports, mergedSiteSummaries };
};

export default useSiteReports;
