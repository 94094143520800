import React from 'react';
import firebase from 'firebase';
import firestore from '@react-native-firebase/firestore';

import TutorialsContainer from './TutorialsContainer';
import { Tutorial } from './Tutorials.type';

export const Tutorials = () => {
  const [dataTutorials, setDataTutorials] = React.useState<Tutorial[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    async function fetchDataTutorials() {
      setIsLoading(true);
      const fetchingData = await firebase.firestore().collection('tutorial').where('isForApp', '==', true).get();

      let documents: Tutorial[] = [];

      if (!fetchingData.empty) {
        documents = fetchingData.docs.map((d: any) => ({ key: d.id, value: d.data() }));
      }

      setDataTutorials(documents);
      setIsLoading(false);
    }

    fetchDataTutorials();
  }, []);

  return <TutorialsContainer tutorials={dataTutorials} isLoading={isLoading} />;
};

export default Tutorials;
