import { createReducer, ActionType } from 'typesafe-actions';
import { enums } from '@nimbly-technologies/nimbly-common';

import * as types from './featureAccess.actionTypes';
import * as actions from './featureAccess.action';
import { featureAccessState } from './type';

export const initialState: featureAccessState = {
  features: {
    [enums.Features.PHOTO_ATTACHMENT_ANNOTATION]: false,
    [enums.Features.AUTO_GENERATED_REPORTS]: false,
    [enums.Features.CUSTOMIZABLE_QUESTIONNAIRE]: false,
    [enums.Features.INDUSTRY_STANDARD_QUESTIONNAIRES]: false,
    [enums.Features.STANDARD_VISUAL_ANALYTICS]: false,
    [enums.Features.MULTI_LANGUAGE_SUPPORT]: false,
    [enums.Features.MESSAGE_BROADCAST]: false,
    [enums.Features.ISSUE_TRACKER]: false,
    [enums.Features.CUSTOMIZED_LOCALIZED_MARKETING]: false,
    [enums.Features.SALES_TARGET_TRACKER]: false,
    [enums.Features.TEAM_INSPECTION_CAPABILITIES]: false,
    [enums.Features.INSIGHTS_NOTIFICATIONS]: false,
    [enums.Features.DIGITAL_SELFIE_SIGNOFFS]: false,
    [enums.Features.INVENTORY_MANAGEMENT]: false,
    [enums.Features.DATA_EXPORT]: false,
    [enums.Features.ISSUE_TRACKER_ESCALATION]: false,
    [enums.Features.WEIGHTED_SCORING]: false,
    [enums.Features.ADHOC_REPORTING]: false,
    [enums.Features.GEO_TAGGING]: false,
    [enums.Features.COMPETITOR_ANALYSIS]: false,
    [enums.Features.JOURNEY_PLAN]: false,
    [enums.Features.IRF_SAT]: false
  },
  type: 'starter',
  isLoading: false,
  error: null,
  modalVisible: false,
  currentOrg: ''
};

export type FeatureAccessAction = ActionType<typeof actions>;
const featureAccessReducer = createReducer<featureAccessState, FeatureAccessAction>(initialState)
  .handleAction(types.SET_TYPE as any, (state, action) => ({
    ...state,
    type: action.payload.text
  }))
  .handleAction(types.FETCH_FEATURE_ACCESS_REQUEST as any, (state, action) => ({
    ...state,
    isLoading: true
  }))
  .handleAction(types.FETCH_FEATURE_ACCESS_SUCCESS as any, (state, action) => ({
    ...state,
    isLoading: false,
    features: action.payload.data,
    type: action.payload.type,
    error: null
  }))
  .handleAction(types.FETCH_FEATURE_ACCESS_FAILURE as any, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload.error
  }))
  .handleAction(types.SET_ORG as any, (state, action) => ({
    ...state,
    currentOrg: action.payload.org
  }))
  .handleAction(types.SHOW_FEATURE_MODAL as any, (state, action) => ({
    ...state,
    modalVisible: true
  }))
  .handleAction(types.DISMISS_FEATURE_MODAL as any, (state, action) => ({
    ...state,
    modalVisible: false
  }));

export { featureAccessReducer };
