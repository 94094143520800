import AsyncStorage from '@react-native-community/async-storage';
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs/src/types';
import { CompositeNavigationProp, useIsFocused, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack/src/types';
import firebase from 'firebase';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/id';
import 'moment/locale/pt';
import 'moment/locale/km';
import 'moment/locale/th';

import 'moment/min/moment-with-locales';
import { Activity, enums } from '@nimbly-technologies/nimbly-common';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActivityIndicator,
  Image,
  InteractionManager,
  Platform,
  ScrollView,
  StyleSheet,
  TouchableNativeFeedback,
  TouchableOpacity,
  View
} from 'react-native';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import * as nimblyApi from '../../constants/api';
import { appversion } from '../../constants/appversion';
import { AUTH_MODE } from '../../constants/Constants';
import { ActiveLanguage, Languages } from '../../constants/Languages';
import getDeviceProfile from '../../helpers/getDeviceProfile';
import PoweredByNimbly from '../../assets/img/nimbly-logo-powered.png';
import I18n from '../../i18n';
import { AppTabsParamList, SettingsStackParamList } from '../../routes';
import { SettingsTabProps } from '../../routes/settings';
import { RootState } from '../../store/reducers';
import { accountActions } from '../../store/reducers/account/account';
import { landingActions } from '../../store/reducers/landing';
import { scheduleJourneysActions } from '../../store/reducers/schedulejourneys';
import { toastActions } from '../../store/reducers/toast';
import { tutorialActions } from '../../store/reducers/tutorial';
import { persistor } from '../../store/store';
import { ConnectedDispatch, FirebaseConnect } from '../../typing/types';
import fontMaker from '../../utils/font';
import { SCREEN_WIDTH } from '../../utils/screensize';
import Button from '../global/Button';
import ConnectionStatus from '../global/ConnectionStatus';
import SelectorPopup from '../global/SelectorPopup';
import Text from '../global/Text';
import { getLogoutOkta } from '../landing/utils/federatedApiUtils';

export function Settings(props: SettingsProps) {
  const { t } = useTranslation(['settings', 'common']);
  const navigation =
    useNavigation<
      CompositeNavigationProp<StackNavigationProp<SettingsStackParamList>, BottomTabNavigationProp<AppTabsParamList>>
    >();
  const isFocused = useIsFocused();

  const [_popUpVisible, setPopUpVisible] = useState<boolean>(false);
  const [_isLoggingOut, setIsLoggingOut] = useState<boolean>(false);

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      if (isFocused) {
        firebase.analytics().logEvent('settings_view');
      }
    });
  }, [isFocused]);

  const handleShowModal = () => {
    setPopUpVisible(true);
  };

  const handleDismissModal = () => {
    setPopUpVisible(false);
  };

  const handleLanguageChange = (languageOption: string) => {
    const selected = ActiveLanguage(languageOption);
    moment.locale(selected);

    props.firebase.updateProfile({ language: selected });
    firebase.analytics().logEvent(`profile_changed_language_to_${selected}`);
    I18n.changeLanguage(selected);
    handleDismissModal();
  };

  const handleLogout = async () => {
    const { auth } = props;

    await props.dispatch(scheduleJourneysActions.setMyScheduledJourneys([]));
    setIsLoggingOut(true);
    try {
      const idToken = await props.firebase.auth().currentUser!.getIdToken();
      getLogoutOkta();

      // Create User Logout Activity
      const activity = new Activity({
        entity: enums.Entity.USER,
        entityID: auth.uid,
        permission: enums.Permission.View,
        type: enums.ActivityType.LOGOUT_USER,
        message: `User Logout: ${auth.displayName}`,
        timestamp: new Date(),
        device: {
          appversion: appversion,
          ...(await getDeviceProfile())
        },
        snapshot: {
          userData: { email: auth.email, userID: auth.uid, displayName: auth.displayName },
          lastSignInTime: new Date(auth.metadata?.lastSignInTime),
          platform: 'app'
        }
      });

      await fetch(`${nimblyApi.NIMBLY_API_CREATE_ACTIVITY}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: idToken
        },
        body: JSON.stringify(activity)
      });

      await props.firebase.updateProfile({ uuid: '' });
      await props.firebase.set(`/userPushToken/${props.firebase.auth().currentUser.uid}`, '');
      await props.firebase.logout();
      await props.dispatch(accountActions.resetProfileState());
      await props.dispatch(landingActions.setAuthMode(AUTH_MODE.LOGIN));
      await AsyncStorage.clear();
      await persistor.purge();
      navigation.reset({
        index: 0,
        routes: [{ name: 'HomeTab' }]
      });
      props.onLogout();
    } catch (error) {
      props.onLogout();
    }
  };

  if (!isFocused) {
    return <View />;
  }

  if (_isLoggingOut) {
    return (
      <View style={styles.logoutRoot}>
        <ConnectionStatus />
        <View style={styles.logoutContainer}>
          <ActivityIndicator size="large" color="#3cd070" />
          <Text style={styles.logoutText}>{t('settings:loggingOut')}</Text>
        </View>
      </View>
    );
  }

  return (
    <View style={styles.root}>
      {/* <UploadReportStatus isFixed={true} fromHome={false} /> */}
      <ConnectionStatus />
      <ScrollView contentContainerStyle={styles.scroll}>
        <SelectorPopup
          popUpVisible={_popUpVisible}
          onChangeSelected={handleLanguageChange}
          onClosePopUp={handleDismissModal}
          selected={t('settings:languageChosen')}
          title={t('settings:selectLanguage')}
          options={Languages}
          cancelText={t('common:cancel')}
        />
        <View>
          <View style={styles.heading}>
            <Text allowFontScaling={false} style={styles.headingText}>
              {t('settings:account')}
            </Text>
          </View>
          <View style={styles.roundedContent}>
            <View style={styles.row}>
              <Text allowFontScaling={false}>{t('settings:email')}:</Text>
              <Text allowFontScaling={false} style={styles.rowRightText}>
                {props.profile ? props.profile.email : ''}
              </Text>
            </View>
            <View style={styles.rowSeparator} />
            <Touchable onPress={handleShowModal}>
              <View style={styles.row}>
                <Text allowFontScaling={false}>{t('settings:language')}:</Text>
                <Text allowFontScaling={false} style={styles.rowRightText}>
                  {t('settings:languageChosen')}
                </Text>
              </View>
            </Touchable>
            <View style={[styles.row, styles.rowButton, { marginTop: 10 }]}>
              <Button
                onPress={handleLogout}
                style={styles.btnLogout}
                underlayColor="rgba(27, 33, 51, 0.25)"
                backgroundColor={props.profileColor}
                title={t('settings:logout')}
              />
            </View>
          </View>
        </View>
        <View style={styles.containerImg}>
          <Image style={styles.imgLogo} source={{ uri: PoweredByNimbly }} />
        </View>
      </ScrollView>
    </View>
  );
}

type StateProps = {
  auth: any;
  profile: any;
  language: string;
  profileColor: string;
};

type OwnProps = {
  onLogout: () => void;
};

type DispatchProps = typeof mapDispatchToProps;

type EnhancedProps = DispatchProps & StateProps & ConnectedDispatch & FirebaseConnect;
type SettingsProps = OwnProps & SettingsTabProps & EnhancedProps;

const mapStateToProps = (state: RootState) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  language: state.firebase.profile.language || 'en',
  profileColor: state.profiletheme.color
});

const mapDispatchToProps = {
  setTutorialMode: tutorialActions.setTutorialMode,
  setToast: toastActions.createToast
};

const enhance = compose(firebaseConnect(), connect(mapStateToProps, mapDispatchToProps));

export default enhance(Settings) as any;

const Touchable: any = Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

const styles = StyleSheet.create({
  logoutRoot: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f5f5f5'
  },
  containerImg: {
    position: 'fixed',
    left: '50%',
    bottom: 75,
    margin: 'auto',
    transform: 'translate(-50%, -50%)'
  },
  imgLogo: {
    width: 162,
    height: 63
  },
  rootSection: {
    marginTop: 20
  },
  logoutContainer: {
    paddingVertical: 25,
    paddingHorizontal: 35,
    backgroundColor: '#fff',
    borderRadius: 5,
    elevation: 5,
    shadowOpacity: 0.15,
    shadowOffset: { height: 5, width: 0 }
  },
  logoutText: {
    marginTop: 10
  },
  root: {
    flex: 1,
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH,
    marginTop: 40,
    height: 750,
    marginHorizontal: 'auto'
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  scroll: {
    flexGrow: 1
  },
  lastSection: {
    marginBottom: 20
  },
  heading: {
    paddingVertical: 10,
    paddingHorizontal: 10
  },
  headingText: {
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 16,
    marginLeft: 18,
    marginTop: 3
  },
  btnLogout: {
    paddingVertical: 0,
    paddingHorizontal: 30,
    borderRadius: 5,
    backgroundColor: '#ccc'
  },
  roundedContent: {
    borderRadius: 5,
    backgroundColor: '#fff',
    marginHorizontal: 15,
    // Android settings
    elevation: 5,
    // iOS settings
    shadowOpacity: 0.15,
    shadowOffset: { height: 5, width: 0 }
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 12.5,
    paddingHorizontal: 15
  },
  rowButton: {
    justifyContent: 'center',
    paddingTop: 0
  },
  rowSeparator: {
    height: 1,
    borderRadius: 10,
    backgroundColor: '#e0e0e0',
    marginHorizontal: 18
  },
  rowRightText: {
    ...fontMaker({ weight: 'Light' })
  },
  textUnderline: {
    textDecorationLine: 'underline'
  },
  priorityRight: {
    opacity: 1,
    flexDirection: 'row',
    alignItems: 'center'
  },
  priorityText: {
    fontSize: 13,
    lineHeight: 15,
    color: '#ED5565'
  },
  sliderContainer: {
    marginLeft: 6,
    position: 'relative',
    height: 16,
    width: 30,
    padding: 2,
    borderRadius: 30 / 2
  },
  sliderCircle: {
    position: 'relative',
    width: 12,
    height: 12,
    borderRadius: 12 / 2,
    backgroundColor: '#fff'
  }
});
