import { cloneDeep } from 'lodash';
import { QuestionTypes } from 'nimbly-common/lib/enumerators';
import { Report } from '../../../utils/classes';
import { destructConditionalQuestions } from '../../../utils/destructConditionalQuestions';
import getRangeFlagColor from '../../questionnaire/utils/getRangeFlagColor';

/**
 * Get Report flag count and score for overview details
 * @param selectedReport - The selected report
 */
export default function getReportScore(selectedReport: Report) {
  const questions = cloneDeep(selectedReport.questions);
  const flagCount = { red: 0, yellow: 0, green: 0 };
  let scoreRaw = 0;
  let scoreTotal = 0;
  const destructQuestion = destructConditionalQuestions(questions);
  destructQuestion.forEach(question => {
    if (question.type === QuestionTypes.MULTIPLE_CHOICE_SCORE) {
      switch (question.flag) {
        case 'green':
          flagCount.green += 1;
          break;
        case 'yellow':
          flagCount.yellow += 1;
          break;
        case 'red':
          flagCount.red += 1;
          break;
        default:
          break;
      }
    } else if (question.type === 'checklist') {
      const { answers, checklistsV2 } = question;
      const answerSet = new Set(answers.map(answer => answer));

      if (checklistsV2) {
        const hasRedFlag = checklistsV2.some(checklist => checklist.hasRedFlag && answerSet.has(checklist.label));

        if (hasRedFlag) {
          flagCount.red += 1;
        }
      }
    } else if (
      question.type !== 'open' &&
      question.type !== 'number' &&
      question.type !== 'inventory' &&
      question.type !== 'inventory-v2' &&
      question.type !== 'score' &&
      question.type !== 'range-flag'
    ) {
      switch (question.answer) {
        case 'green-flag':
          flagCount.green += 1;
          break;
        case 'yellow-flag':
          flagCount.yellow += 1;
          break;
        case 'red-flag':
          flagCount.red += 1;
          break;
        default:
          break;
      }
      question.score = -1;
    } else if (question.type === 'score' && typeof question.answer === 'number') {
      let questionWeight = question.score;
      let score = question.answer;
      const maxScore = question.score;

      if (question.hasOwnProperty('scoreWeight')) {
        questionWeight = +question.scoreWeight;
        score = (score / maxScore) * questionWeight;
      }
      scoreRaw = parseFloat((scoreRaw + score).toFixed(2));
      scoreTotal = parseFloat((scoreTotal + questionWeight).toFixed(2));
    } else if (question.type === 'range-flag' && typeof question.answer === 'number') {
      switch (getRangeFlagColor(question)) {
        case 'green-flag':
          flagCount.green += 1;
          break;
        case 'yellow-flag':
          flagCount.yellow += 1;
          break;
        case 'red-flag':
          flagCount.red += 1;
          break;
        default:
          break;
      }
    } else {
      question.score = -1;
    }
  });
  const scoreWeighted = scoreRaw > 0 && scoreTotal > 0 ? (scoreRaw / scoreTotal) * 100 : -1;

  return { flagCount, scoreRaw, scoreTotal, scoreWeighted };
}
