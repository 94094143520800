/* eslint-disable complexity */
import { FlagCount, SiteSKU, OrganizationSKU, Question } from './classes';
import { countIsInvalid } from './number';
import getRangeFlagColor from '../components/questionnaire/utils/getRangeFlagColor';
import {
  getConditionalQuestion,
  validateConditionalQuestion
} from '../components/questionnaire/utils/conditionalQuestion';
import { TFunction } from 'i18next';
import { QuestionTypes } from 'nimbly-common/lib/enumerators';

const validatePhotos = (question: Question, skuKey?: string) => {
  if (!question.photoLimit || !question.photoMinimum) {
    return true;
  }

  if (question.type === QuestionTypes.INVENTORY_V2) {
    if (skuKey && (question.skuInventory?.[skuKey]?.photos?.length || 0) >= question.photoMinimum) {
      return true;
    }
    return false;
  }

  return question.photos?.length >= question.photoMinimum;
};

const validateVideos = (question: Question, hasVideoAttachment?: boolean) => {
  // hardcoded because in admin there is no minimum for video. confirmed with product team, minimum 1 video.
  // use commented logic below when video minimum is properly implemented

  if (
    !question.videoLimit ||
    !hasVideoAttachment
    // || !question.videoMinimum
  ) {
    return true;
  }

  // return question.videos?.length >= question.videoMinimum;
  return !!question.videoLimit && question.videos?.length > 0;
};

const validateDocuments = (question: Question) => {
  if (!question.documentLimit || !question.documentMinimum) {
    return true;
  }

  return (question.documents?.length || 0) >= question.documentMinimum;
};

const validateComments = (question: Question) => {
  let rangeFlagColor = '';
  switch (question.type) {
    case QuestionTypes.BINARY:
    case QuestionTypes.BINARY_WITH_RED_FLAG:
      if (question.answer === 'red-flag' || question.answer === 'yellow-flag') {
        return !!question.comment;
      }
      return true;

    case QuestionTypes.CHECKLIST:
      if (checkHasRedFlagAndLabelMatch(question?.answers, question?.checklistsV2 || [])) {
        return !!question.comment;
      }
      return true;

    case QuestionTypes.MULTIPLE_CHOICE_SCORE:
      if (question.flag === 'red' || question.flag === 'yellow') {
        return !!question.comment;
      }
      return true;

    case QuestionTypes.SCORE:
      if (Number(question.answer) / question.score < 0.8) {
        return !!question.comment;
      }
      return true;

    case QuestionTypes.RANGE_FLAG:
      rangeFlagColor = getRangeFlagColor(question);
      if (rangeFlagColor === 'red-flag' || rangeFlagColor === 'yellow-flag') {
        return !!question.comment;
      }
      return true;

    case QuestionTypes.OPEN:
    default:
      return !!question.comment;
  }
};

const validateScoreQuestion = (question: Question) => {
  return !countIsInvalid(question.answer.toString()) && validateComments(question);
};

const validateInventoryQuestion = (question: Question, inventoryOutNumber?: number) => {
  if (
    !question.inventory ||
    question.inventory.in === -1 ||
    question.inventory.out === -1 ||
    question.inventory.final === -1
  ) {
    return false;
  }
  return (
    question.inventory?.in >= 0 &&
    question.inventory?.final >= 0 &&
    typeof inventoryOutNumber === 'number' &&
    inventoryOutNumber > 0
  );
};

const validateInventoryV2Question = (
  question: Question,
  inventoryOutNumber?: number,
  skuKey?: string,
  validateIn = true
  // eslint-disable-next-line max-params
) => {
  if (
    !question.skuInventory ||
    !skuKey ||
    question.skuInventory[skuKey].out === -1 ||
    question.skuInventory[skuKey].final === -1
  ) {
    return false;
  }
  /** inbound from app, validate in and stock out */
  if (validateIn) {
    return question.skuInventory[skuKey].in >= 0 && typeof inventoryOutNumber === 'number' && inventoryOutNumber >= 0;
  }
  return typeof inventoryOutNumber === 'number' && inventoryOutNumber >= 0;
};

const validateCompetitorQuestion = (question: Question) => {
  if (!question.competitorAnalysis?.company) {
    return false;
  }
  if (question.competitorAnalysis.form === 'promotion') {
    const { promotionTitle, promotionType, promotionStartDate, promotionDetail } = question.competitorAnalysis;
    return !!(promotionTitle && promotionType && promotionStartDate && promotionDetail);
  } else {
    const {
      productName,
      productCategory,
      productPriceFrom,
      productPriceTo,
      productVariant,
      productStores,
      productDescription,
      productTargetMarket
    } = question.competitorAnalysis;
    return !!(
      productName &&
      productCategory &&
      productPriceFrom &&
      productPriceTo &&
      productVariant &&
      productDescription &&
      productStores &&
      productTargetMarket
    );
  }
};

const validateRangeFlagQuestion = (question: Question) => {
  return (
    !countIsInvalid(question.answer.toString()) &&
    question.answer >= question.minRange &&
    question.answer <= question.maxRange &&
    validateComments(question)
  );
};

const validateConditionalQuestionAnswer = (question: Question) => {
  // not a conditional question, return true
  if (!validateConditionalQuestion(question, question.answer)) {
    return true;
  }

  const conditionalQuestions: boolean[] = getConditionalQuestion(question, question.answer).map(
    (conditionalQuestion: Question) => {
      return questionComplete(conditionalQuestion);
    }
  );
  return conditionalQuestions.every((bool: boolean) => bool === true);
};

const validateQuestionAnswer = (
  question: Question,
  skuParams: { inventoryOutNumber?: number; skuKey?: string; validateIn: boolean }
) => {
  const { inventoryOutNumber, skuKey, validateIn } = skuParams;

  switch (question.type) {
    case QuestionTypes.NUMBER:
      return !countIsInvalid(question.answer.toString());

    case QuestionTypes.SCORE:
      return validateScoreQuestion(question);

    case QuestionTypes.INVENTORY:
      return validateInventoryQuestion(question, inventoryOutNumber);

    case QuestionTypes.INVENTORY_V2:
      return validateInventoryV2Question(question, inventoryOutNumber, skuKey, validateIn);

    case QuestionTypes.MULTIPLE_CHOICE:
      return !!question.answer;

    case QuestionTypes.RANGE_FLAG:
      return validateRangeFlagQuestion(question);

    case QuestionTypes.COMPETITOR:
      return validateCompetitorQuestion(question);

    case QuestionTypes.CHECKLIST:
      return !!question.answers?.length && validateComments(question);

    case QuestionTypes.BINARY_WITH_RED_FLAG:
    case QuestionTypes.MULTIPLE_CHOICE_SCORE:
    case QuestionTypes.BINARY:
      return !!question.answer && validateComments(question);

    case QuestionTypes.OPEN:
      return validateComments(question);
  }

  return !!question.answer;
};

// Returns boolean if question is completed
// eslint-disable-next-line max-params
function questionComplete(
  question: Question,
  inventoryOutNumber?: number,
  skuKey?: string,
  validateIn = true,
  hasVideoAttachment?: boolean
): boolean {
  if (!question) {
    return false;
  }
  if (!question.answerRequired && question.answer === 'not-applicable') {
    return true;
  }
  if (
    !validatePhotos(question, skuKey) ||
    !validateVideos(question, hasVideoAttachment) ||
    !validateDocuments(question)
  ) {
    return false;
  }

  return (
    validateConditionalQuestionAnswer(question) &&
    validateQuestionAnswer(question, { inventoryOutNumber, skuKey, validateIn })
  );
}

function questionSKUComplete(
  question: Question,
  siteSKUs: { [sku: string]: SiteSKU & { skuDetail: OrganizationSKU } },
  calibratingSKU: { [skuKey: string]: boolean }
) {
  if (!question.skuInventory || Object.keys(question.skuInventory).length === 0) {
    return false;
  }

  let output = true;

  for (const skuKey in question.skuInventory) {
    if (skuKey) {
      const currentInventory = !!siteSKUs && siteSKUs[skuKey] ? siteSKUs[skuKey]?.stock : 0;
      const currentSiteSKU = !!siteSKUs && siteSKUs[skuKey] && siteSKUs[skuKey]?.skuDetail ? siteSKUs[skuKey] : null;
      if (!currentSiteSKU) {
        continue;
      }

      const isInCalibratingMap =
        calibratingSKU && Object.keys(calibratingSKU).length ? !!calibratingSKU[skuKey] : false;
      const isCalibrating = currentInventory === 0 && isInCalibratingMap;
      const inboundFromApp = currentSiteSKU.skuDetail && currentSiteSKU.skuDetail.inOrigin === 'onsite';
      const skuInventory = question.skuInventory![skuKey];
      const inventoryOutNumber = isCalibrating && !inboundFromApp ? 0 : skuInventory.out;
      const validateIn = inboundFromApp && !isCalibrating;

      const check = questionComplete(question, inventoryOutNumber, skuKey, validateIn);
      // checked.push({ name: skuName, check });
      if (!check) {
        output = false;
        break;
      }
    }
  }
  return output;
}

function questionTextInvalid(question: Question, t: TFunction) {
  if (question.type === 'open') {
    if (!question.answerRequired && question.answer === 'not-applicable') {
      return null;
    }
    if (!question.comment || question.comment.length === 0) {
      return t('questionnaire:utils.insertComment');
    }
  } else if (question.type === 'number') {
    if (!question.answerRequired && question.answer === 'not-applicable') {
      return null;
    }
  } else if (question.type === 'multiple-choice') {
    if (!question.answerRequired && question.answer === 'not-applicable') {
      return null;
    }
  } else if (question.type === 'score') {
    if (typeof question.answer !== 'number') {
      return null;
    } else {
      const pct = question.answer / question.score;
      if (pct < 0.8 && question.comment.length === 0) {
        return t('questionnaire:utils.insertComment');
      }
      return null;
    }
  } else if (question.type === 'range-flag') {
    if (isNaN(Number(question.answer)) || question.answer === '') {
      return null;
    } else {
      const flag = getRangeFlagColor(question);
      if ((flag === 'red-flag' || flag === 'yellow-flag') && question.comment.length === 0) {
        return t('questionnaire:utils.insertComment');
      }
      return null;
    }
  } else if (question.type === 'checklist') {
    // comment validation for checklist
    if (
      checkHasRedFlagAndLabelMatch(question?.answers, question?.checklistsV2 || []) &&
      question.comment.length === 0
    ) {
      return t('questionnaire:utils.insertComment');
    }
    if (question.comment && question.comment.length >= 2500) {
      return t('questionnaire:utils.commentTooLong');
    }
  } else if (question.answer) {
    if (!question.answerRequired && question.answer === 'not-applicable') {
      return null;
    }
    if (question.comment && question.comment.length >= 2500) {
      return t('questionnaire:utils.commentTooLong');
    }

    if (question.type === 'multiple-choice-score') {
      if (question.flag === 'green' && question.comment.length === 0) {
        return null;
      }
      if (question.comment === '') {
        return t('questionnaire:utils.insertComment');
      }
    }
    if (question.answer !== 'green-flag' && question.comment.length === 0) {
      return t('questionnaire:utils.insertComment');
    }
  }
  return null;
}

function questionNumberInvalid(
  question: Question,
  t: TFunction,
  inventoryType?: 'in' | 'out' | 'final',
  inventoryNumber?: any,
  inventoryOutNumber?: number
) {
  if (question.type === 'number') {
    if (!question.answerRequired && question.answer === 'not-applicable') {
      return null;
    }
    if (countIsInvalid(question.answer.toString())) {
      return t('questionnaire:utils.insertNumber');
    }
  } else if (question.type === 'range-flag') {
    if ((!question.answerRequired && question.answer === 'not-applicable') || question.answer === '') {
      return null;
    }
    if (countIsInvalid(question.answer.toString())) {
      return t('questionnaire:utils.insertNumber');
    }
    if (question.answer > question.maxRange || question.answer < question.minRange) {
      return t('questionnaire:utils.outRange');
    }
  } else if (
    (question.type === 'inventory' || question.type === 'inventory-v2') &&
    (question.inventory || question.skuInventory) &&
    inventoryType
  ) {
    if (countIsInvalid(inventoryNumber)) {
      return t('questionnaire:utils.insertNumber');
    }
    if (inventoryNumber < 0) {
      return t('questionnaire:utils.negative');
    }
    if (
      (question.inventory !== null || question.skuInventory !== null) &&
      (typeof inventoryOutNumber !== 'number' || inventoryOutNumber < 0)
    ) {
      return t('questionnaire:utils.outNumber');
    }
  }
  return null;
}

function getTotalCompleteQuestions(questions: Question[]) {
  let count = 0;
  questions.forEach(question => {
    if (questionComplete(question)) {
      count++;
    }
  });

  return count;
}

function getFlagCount(questions: Question[]): FlagCount {
  const flagCount = { red: 0, yellow: 0, green: 0 };
  questions.forEach(question => {
    if (question.type !== 'open' && question.type !== 'number' && question.type !== 'inventory') {
      switch (question.answer) {
        case 'green-flag':
          flagCount.green += 1;
          break;
        case 'yellow-flag':
          flagCount.yellow += 1;
          break;
        case 'red-flag':
          flagCount.red += 1;
          break;
        default:
          break;
      }
    }
  });

  return flagCount;
}

function checkHasRedFlagAndLabelMatch(answers: Question['answers'], checklist: Question['checklistsV2']): boolean {
  if (checklist?.length && answers?.length) {
    for (const item of checklist) {
      if (item.hasRedFlag === true && answers.includes(item?.label)) {
        return true;
      }
    }
  }

  return false;
}

export {
  questionComplete,
  questionSKUComplete,
  questionTextInvalid,
  questionNumberInvalid,
  getTotalCompleteQuestions,
  getFlagCount
};
