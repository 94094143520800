import React from 'react';
// import * as d3 from 'd3';
// import { Surface, Group, Shape, Path } from '@react-native-community/art';
import { ActivityIndicator, Animated, StyleSheet, View } from 'react-native';
import { OrganizationSchedule } from 'nimbly-common';
import { Path, Circle, Svg, G } from 'react-native-svg';
import Text from '../global/Text';
import fontMaker from '../../utils/font';
import { round } from '../../utils/number';
import { SCREEN_HEIGHT } from '../../utils/screensize';
import { withTranslation, WithTranslation } from 'react-i18next';
import { GetOrgSchedulePeriodUnit } from '../../utils/schedule';

function calcProgressToRadian(progress: number) {
  const degree = (progress / 100) * 360;
  return (degree * Math.PI) / 180;
}

// function progressPath(progress: number, radius: number) {
//   const path = d3
//     .arc()
//     .padAngle(0)
//     .startAngle(0)
//     .endAngle(calcProgressToRadian(progress))
//     .outerRadius(radius)
//     .innerRadius(radius)({} as any);
//   return path;
// }

class WelcomeTrackerProgress extends React.Component<WelcomeTrackerProgressProps, WelcomeTrackerProgressState> {
  _current: any;
  _isMounted: boolean;
  constructor(props: WelcomeTrackerProgressProps) {
    super(props);
    this._isMounted = false;
    this.state = {
      transition: {},
      completed: 0,
      total: 1,
      animationStart: false,
      isUnmounted: false,
      progressLine: []
    };
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.completed !== this.state.completed || this.props.total !== this.state.total) {
      if (this.props.isReady && !this.state.animationStart) {
        let init = 0;
        const paths = [];

        const progress = Math.floor((this.props.completed / this.props.total) * 100);
        while (init < progress && progress !== 0) {
          // accounts in taking 10 svg morph paths
          init += progress / 10;
          // paths.push(progressPath(Math.min(100, init), this.props.radius));
        }

        this.resetAnimation();

        if (!this.state.isUnmounted) {
          this.setState({
            total: this.props.total,
            completed: this.props.completed,
            animationStart: true,
            progressLine: paths as any
          });
        }
      }
    }
  }

  shouldComponentUpdate() {
    if (this._isMounted) {
      if (!this.props.isFocused) {
        return false;
      }

      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps: WelcomeTrackerProgressProps) {
    const { animationStart, progressLine } = this.state;
    if (this._isMounted) {
      if (this.props.completed !== prevProps.completed || this.props.total !== prevProps.total) {
        this.resetAnimation();
      }
      if (animationStart && this._current <= progressLine.length) {
        this.animate(null, this.nextAnimation);
      }
    }
  }

  componentWillUnmount() {
    // cancel all async
    this._isMounted = false;
  }

  resetAnimation = () => {
    this._current = 0;
  };

  animate = (start: number | null, cb: () => any) => {
    requestAnimationFrame(timestamp => {
      if (!start) {
        start = timestamp;
      }
      const delta = (timestamp - start) / 10;

      if (delta > 0.8) {
        return cb();
      }
      this.state.transition.delta = delta;
      this.setState({ ...this.state });
      this.animate(start, cb);
    });
  };

  nextAnimation = () => {
    const { progressLine } = this.state;
    this._current += 1;
    if (this._current >= progressLine.length) {
      return this.setState({ animationStart: false });
    }
  };

  render() {
    if (!this.props.isFocused) {
      return <View />;
    }

    const { total, completed, progressLine } = this.state;
    const { dimension, radius, width, penX, penY, t, isReady } = this.props;
    const progress = round(Math.min(Math.max((completed * 1.0) / (total || 1), 0), 1) * 100);
    const diameter = Math.round(Math.PI * radius * 2);
    const getOffset = (val = 0) => Math.round(((100 - Math.min(val, 100)) / 100) * diameter);
    const newProgress = getOffset(progress);
    // const background = new Path()
    //   .move(radius, 0)
    //   .arc(0, radius * 2, radius)
    //   .arc(0, radius * -2, radius);

    return (
      <Animated.View
        style={[
          styles.root,
          {
            opacity: this.props.opacity
              ? this.props.opacity.interpolate({
                  inputRange: [20, SCREEN_HEIGHT * 0.1],
                  outputRange: [1, 0],
                  extrapolate: 'clamp'
                })
              : 1
          }
        ]}
      >
        <View style={styles.container}>
          {/* <Surface width={dimension} height={dimension}>
            <Group x={penX} y={penY}>
              <Shape d={background} fill={'rgba(0, 0, 0, 0)'} stroke="#fff" strokeWidth={width} />
            </Group>
            <Group x={radius + penX} y={radius + penY}>
              <Shape
                d={isReady ? (progressLine as any) : ''}
                stroke={progress === 100 ? '#009e43' : '#ffa040'}
                strokeWidth={width}
                strokeCap={'round'}
              />
            </Group>
          </Surface> */}
          {/* <Svg width={dimension} height={dimension}>
            <G x={penX} y={penY}>
              <Path
                d={'M25 10 L98 65 L70 25 L16 77 L11 30 L0 4 L90 50 L50 10 L11 22 L77 95 L20 25'}
                fill={'rgba(0, 0, 0, 0)'}
                stroke="#fff"
                strokeWidth={width}
              />
            </G>
            <G x={radius + penX} y={radius + penY}>
              <Path
                d={isReady ? (progressLine as any) : ''}
                stroke={progress === 100 ? '#009e43' : '#ffa040'}
                strokeWidth={width}
                // strokeCap={'round'}
              />
            </G>
          </Svg> */}
          <Svg width={dimension} height={dimension} viewBox="-25 -25 400 400">
            <Circle cx={penX} cy={penY} r={radius} strokeWidth={width} stroke={'#fff'} fill="none" />
            <Circle
              stroke={progress === 100 ? '#009e43' : '#ffa040'}
              transform="rotate(-90 175 175)"
              cx={penX}
              cy={penY}
              r={radius}
              strokeDasharray="1100"
              strokeWidth={width}
              strokeLinecap={'round'}
              fill="none"
              strokeDashoffset={newProgress}
            />
          </Svg>
        </View>
        <View style={styles.textContainer}>
          {isReady ? (
            <Text style={styles.progressText} allowFontScaling={false}>
              {progress}%
            </Text>
          ) : (
            <View style={styles.activityContainer}>
              <ActivityIndicator size="large" color="#efeeed" />
            </View>
          )}
          <Text style={styles.progressSubtitleText} allowFontScaling={false}>
            {t(GetOrgSchedulePeriodUnit(this.props.organizationSchedule))}
          </Text>
        </View>
      </Animated.View>
    );
  }
}

type WelcomeTrackerProgressState = {
  transition: any;
  completed: number;
  total: number;
  animationStart: boolean;
  isUnmounted: boolean;
  progressLine: (() => any)[];
};

type WelcomeTrackerProgressProps = {
  dimension: number;
  radius: number;
  width: number;
  penX: number;
  penY: number;
  completed: number;
  total: number;
  language: string;
  isReady: boolean;
  isFocused: boolean;
  opacity: Animated.Value;
  organizationSchedule: OrganizationSchedule;
} & WithTranslation;

const styles = StyleSheet.create({
  root: {
    flexDirection: 'column'
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
    right: 0,
    margin: 'auto'
  },
  activityContainer: {
    marginVertical: 7
  },
  textContainer: {
    position: 'absolute',
    alignSelf: 'center',
    top: '30%'
  },
  progressText: {
    ...fontMaker({ weight: 'SemiBold' }),
    color: '#fff',
    fontSize: 40,
    lineHeight: 44,
    textAlign: 'center'
  },
  progressSubtitleText: {
    ...fontMaker({ weight: 'Regular' }),
    color: '#fff',
    fontSize: 18,
    lineHeight: 20,
    textAlign: 'center'
  }
});

export default withTranslation(['home, common'])(WelcomeTrackerProgress) as any;
