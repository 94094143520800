import React from 'react';
import { StyleSheet, Dimensions, View } from 'react-native';

import Text from '../global/Text';

import { QuestionnaireSwiperContainerProps } from './QuestionnaireSwiperContainer';
import { questionSKUComplete, questionComplete } from '../../utils/report';
import fontMaker from '../../utils/font';
import { useTranslation } from 'react-i18next';

export type QuestionnaireSwiperHeadingProps = Pick<
  QuestionnaireSwiperContainerProps,
  'language' | 'index' | 'questions' | 'question' | 'handleNavReference' | 'siteSKUs' | 'calibratingSKU'
>;

const screen = Dimensions.get('screen');
const { width } = screen;

const QuestionnaireSwiperHeading = (props: QuestionnaireSwiperHeadingProps) => {
  const { t } = useTranslation(['questionnaire']);
  return (
    <View>
      <Text testID={'completed-question-text'} style={styles.questionHeading}>
        {t('questionnaire:questionnaireSwiper.questionNumber', {
          index: props.index + 1,
          length: props.questions.length
        })}{' '}
        {props.question.type !== 'inventory-v2' && questionComplete(props.question)
          ? t('questionnaire:questionnaireSwiper.done')
          : ''}
        {props.question.type === 'inventory-v2' &&
        props.siteSKUs &&
        props.calibratingSKU !== null &&
        questionSKUComplete(props.question, props.siteSKUs, props.calibratingSKU)
          ? t('questionnaire:questionnaireSwiper.done')
          : ''}
      </Text>
      <Text style={styles.questionText}>
        {props.question.content}
        {props.question.reference ? (
          <Text
            testID={'reference-link'}
            onPress={() => props.handleNavReference(props.question.reference)}
            style={styles.questionReference}
          >
            {' '}
            ({t('questionnaire:reference')})
          </Text>
        ) : null}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  questionHeading: {
    marginBottom: 7.5,
    paddingHorizontal: 10,
    textAlign: 'center'
  },
  questionText: {
    textAlign: 'center',
    fontSize: width <= 320 ? 14 : 16,
    marginBottom: 15,
    paddingHorizontal: 10
  },
  questionReference: {
    ...fontMaker({ weight: 'SemiBold' }),
    color: '#3cd070'
  }
});

export default QuestionnaireSwiperHeading;
