import DeviceInfo from 'react-native-device-info';
import * as RNLocalize from 'react-native-localize';
import { v1 as uuidv1 } from 'uuid';
const publicIp = require('public-ip');
import { Platform } from 'react-native';

/**
 * Retrieve the device profile that has some key functions
 */
export default async function getDeviceProfile() {
  const deviceProfile = {
    uuid: 'web',
    hasNotch: DeviceInfo.hasNotch(),
    apiLevel: Platform.OS === 'android' ? await DeviceInfo.getApiLevel() : -1,
    binaryVersion: DeviceInfo.getVersion(),
    carrier: await DeviceInfo.getCarrier(),
    locale: RNLocalize.getLocales()[0].languageTag,
    phoneBrand: DeviceInfo.getBrand(),
    phoneModel: DeviceInfo.getDeviceId(),
    phoneOS: DeviceInfo.getSystemName(),
    phoneOSVersion: DeviceInfo.getSystemVersion()
  };

  return deviceProfile;
}
