import { useRef, useEffect } from 'react';

export default function usePrevious<T = any>(value: T): T | undefined {
  const ref = useRef<T>();
  useEffect(() => {
    return () => {
      ref.current = value;
    };
  });
  return ref.current;
}
