import { getFirebase } from 'react-redux-firebase';
import { call, put } from 'redux-saga/effects';
import * as actions from './sales.action';
import { NIMBLY_API_SALES_TARGET } from '../../../constants/api';

export function* fetchSiteSalesTarget(action: ReturnType<typeof actions.fetchSiteSalesTargetAsync.request>) {
  const firebase = getFirebase();
  try {
    const token = (yield firebase.auth().currentUser?.getIdToken()) as string;
    const options = {
      method: 'GET',
      headers: {
        Authorization: token
      }
    };
    const url = `${NIMBLY_API_SALES_TARGET}/${action.payload}`;
    const request = () => fetch(url, options);
    const response = yield call(request);
    if (response?.status === 200) {
      const json = yield response.json();
      const { completed, salesTargetTotal, salesTargetToDate } = json.data;
      yield put(actions.fetchSiteSalesTargetAsync.success({ completed, salesTargetTotal, salesTargetToDate }));
      return;
    }
    yield put(actions.fetchSiteSalesTargetAsync.failure({ error: 'Fail to fetch' }));
  } catch (error) {
    yield put(actions.fetchSiteSalesTargetAsync.failure({ error: error.message }));
  }
}
