import React, { useMemo, useState, useEffect } from 'react';
import moment from 'moment-timezone';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useTranslation } from 'react-i18next';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Animated,
  Easing,
  Modal,
  TextInput,
  Keyboard,
  Platform
} from 'react-native';

// utils
import { SCREEN_WIDTH } from '../../../utils/screensize';
import Text from '../../global/Text';
import theme from '../../../constants/theme';
import fontMaker from '../../../utils/font';

// type
import { SiteScheduleModalProps } from './type';

// components
import SiteActionButton from './SiteActionButton';
import RenderTimeSchedule from '../components/RenderTimeSchedule';

import { ScheduleStatusEnum } from '../../../utils/classes';

const SiteScheduleFooterModal = (props: SiteScheduleModalProps) => {
  const {
    featureAccessAdhoc,
    roleAccessAdhoc,
    selectedSchedule,
    siteReport,
    locationPermissionGranted,
    hasCheckedIn,
    isMultisite,
    isAdhoc,
    isBusy,
    isFocused,
    isLeader,
    isScheduleSelected,
    isOffline,
    isGPSMocked,
    profileColor,
    status,
    showFooter,
    selectedDateCalendar,
    selectedSite,
    handleMakeupReport,
    handleDeleteDraftReport,
    onCheckIn,
    onConfirmCheckIn,
    onReviewPendingUpload,
    handleSelectReportingType,
    onCalibrateLocation,
    setActiveTab,
    setShowFooter
  } = props;

  const [slideUpValue] = useState(new Animated.Value(0));
  const [slideDownValue] = useState(new Animated.Value(1));
  const [containerHeight, setContainerHeight] = useState(0);
  const [makeupReason, setMakeupReason] = useState<string>('');
  const [isShowKeyboard, setIsShowKeyboard] = useState(false);

  const keyboardWillShow: any = null;
  const keyboardWillHide: any = null;

  const { t } = useTranslation(['site', 'common']);

  const handleCloseOutletFooter = () => {
    if (isMultisite) {
      setShowFooter(false);
      return;
    }

    setMakeupReason('');
    setShowFooter(false);
    Animated.timing(slideDownValue, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
      easing: Easing.ease
    }).start();
  };

  const getTransformCondition = useMemo(() => {
    if (showFooter) {
      return {
        translateY: slideUpValue.interpolate({
          inputRange: [0, 1],
          outputRange: [200, 0]
        })
      };
    } else {
      return {
        translateY: slideDownValue.interpolate({
          inputRange: [0, 1],
          outputRange: [containerHeight, 0]
        })
      };
    }
  }, [showFooter]);

  const isDraft = useMemo(() => {
    return !!selectedSchedule?.draftReport;
  }, [selectedSchedule?.draftReport]);

  // disable schedule button based on type on draft report
  const isAdhocDraftReport = useMemo(() => {
    if (isDraft) {
      const { draftReport } = selectedSchedule!;

      if (draftReport) {
        const { key } = draftReport!;

        if (key && siteReport && siteReport![key]) {
          return siteReport![key]!.isAdhoc;
        }
      }
    }

    // default is scheduled type
    return false;
  }, [siteReport, selectedSchedule, isDraft, isAdhoc]);

  const isFutureSchedule = useMemo(() => {
    if (!selectedDateCalendar || !selectedSchedule) {
    }
    const today = moment.tz(selectedSchedule?.siteDateTime ?? '', selectedSite?.timezone ?? '');
    if (moment(selectedDateCalendar).isAfter(today)) {
      if (moment(today).isSame(selectedSchedule?.startDate, 'day')) {
        return false;
      }
      return true;
    }
    return moment(selectedDateCalendar).isAfter(today);
  }, [selectedDateCalendar, selectedSchedule]);

  const getLatestMakeupDate = useMemo(() => {
    if (selectedSchedule?.makeUpDates?.length) {
      const makeupDate = selectedSchedule?.makeUpDates[selectedSchedule.makeUpDates.length - 1];
      return makeupDate;
    }

    return '';
  }, [selectedSchedule]);

  useEffect(() => {
    if (Platform.OS === 'ios') {
      Keyboard.addListener('keyboardWillShow', handleKeyboardWillShow);
      Keyboard.addListener('keyboardWillHide', handleKeyboardWillHide);
    }

    return () => {
      if (Platform.OS === 'ios') {
        keyboardWillShow?.remove();
        keyboardWillHide?.remove();
      }
    };
  }, []);

  const handleKeyboardWillShow = () => {
    setIsShowKeyboard(true);
  };

  const handleKeyboardWillHide = () => {
    setIsShowKeyboard(false);
  };

  // handle animation slide up
  useEffect(() => {
    if (showFooter) {
      Animated.timing(slideUpValue, {
        toValue: 1,
        duration: 300,
        useNativeDriver: true,
        easing: Easing.ease
      }).start();
    }
  }, [showFooter]);

  // automatically select report type to adhoc
  // if custom and not have schedule date
  // TODO - force
  useEffect(() => {
    if (selectedSchedule?.status === 'adhoc') {
      handleSelectReportingType(true);
      return;
    } else if (selectedSchedule?.status === 'draft') {
      handleSelectReportingType(isAdhocDraftReport);
    }
    if (!featureAccessAdhoc || !roleAccessAdhoc) {
      // select reporting type to scheduled
      handleSelectReportingType(false);
    }
  }, [selectedSchedule, featureAccessAdhoc, isAdhocDraftReport, isDraft, roleAccessAdhoc]);

  useEffect(() => {
    return function cleanup() {
      handleSelectReportingType(false);
      setMakeupReason('');
    };
  }, []);

  // component
  type TypeButtonProps = {
    onPress: (value?: any) => void;
    active?: boolean;
    disabled?: boolean;
    testID?: string;
    title: string;
  };

  const TypeButton = (typeButtonProps: TypeButtonProps) => {
    const { onPress, active, disabled, testID, title } = typeButtonProps;
    return (
      <TouchableOpacity
        onPress={onPress}
        style={[
          styles.scheduleTypeButton,
          active ? styles.activeSelectedTypeButton : {},
          disabled ? styles.disableBorder : {}
        ]}
        disabled={disabled}
        testID={testID}
      >
        <Text style={[styles.typeText, disabled ? styles.disableText : {}]}>{title}</Text>
      </TouchableOpacity>
    );
  };

  const renderReportStatus = useMemo(() => {
    switch (status) {
      case ScheduleStatusEnum.DRAFT:
        return t('site:continueReport');
      case ScheduleStatusEnum.PENDING_UPLOAD:
        return t('site:pendingUpload');
      case ScheduleStatusEnum.FINISHED_TODAY:
      case ScheduleStatusEnum.FINISHED_PERIOD:
        return t('common:completed');
      default:
        return null;
    }
  }, [status]);
  const renderBodySingleSite = () => {
    return (
      <>
        <View style={styles.timeStatusContainer}>
          <Text style={styles.text} numberOfLines={2}>
            {!selectedSchedule ? t('site:error.selectQuestionnaireFirst') : selectedSchedule?.title}
          </Text>
          {renderReportStatus ? (
            <Text style={styles.boldText}>{renderReportStatus}</Text>
          ) : (
            <RenderTimeSchedule
              schedule={selectedSchedule!}
              fontSize={12}
              isOverdueVisible={true}
              selectedCalendarDate={selectedDateCalendar}
            />
          )}
          {selectedSchedule?.status === 'makeup' ? (
            <Text style={[styles.text, { marginTop: 10 }]}>
              <Text style={{ fontWeight: '500' }}>{t('site:makeUpDate')} : </Text>
              {moment(selectedDateCalendar).format('DD MMMM, YYYY')}
            </Text>
          ) : null}
        </View>

        {/* text box for make up reason 
        currently we only can make up on latest missed date
      */}
        {selectedSchedule?.status === 'makeup' ? (
          <TextInput
            multiline={true}
            textAlignVertical="top"
            placeholder="reason missing report"
            onChangeText={text => {
              setMakeupReason(text);
            }}
            style={styles.makeupReasonContainer}
            value={makeupReason}
          />
        ) : null}

        {!selectedSchedule?.isAdhocOnly && selectedSchedule?.allowAdhoc ? (
          <View style={[styles.containerTitle, { marginBottom: 18 }]}>
            <Text style={styles.titleText}>{t('footer.selectType')}</Text>
          </View>
        ) : null}

        <View style={styles.containerSelectType}>
          {selectedSchedule?.isAdhocOnly || selectedSchedule?.allowAdhoc ? (
            <>
              <TypeButton
                onPress={() => handleSelectReportingType(true)}
                active={isAdhoc && !isFutureSchedule}
                title={t('common:adhoc')}
                disabled={!(selectedSchedule?.isAdhocOnly || selectedSchedule?.allowAdhoc)}
                testID="outlet-footer-adhoc-button"
              />
              <View style={styles.divider} />
            </>
          ) : null}
          {!selectedSchedule?.isAdhocOnly ? (
            <TypeButton
              onPress={() => handleSelectReportingType(false)}
              active={!isAdhoc && !isFutureSchedule}
              title={t('common:scheduled')}
              disabled={
                (isAdhocDraftReport && isDraft) ||
                isFutureSchedule ||
                selectedSchedule?.status === 'makeup' ||
                selectedSchedule?.status === 'pending-upload' ||
                selectedSchedule?.status === 'adhoc'
              }
              testID="outlet-footer-scheduled-button"
            />
          ) : null}
        </View>
      </>
    );
  };

  const renderBodyMultiSite = () => {
    if (isBusy || !selectedSchedule) return null;
    return (
      <>
        <View style={styles.timeStatusContainer}>
          {renderReportStatus ? (
            <Text style={styles.boldText}>{renderReportStatus}</Text>
          ) : (
            <RenderTimeSchedule
              isOverdueVisible={true}
              schedule={selectedSchedule!}
              fontSize={12}
              selectedCalendarDate={selectedDateCalendar}
            />
          )}
          {selectedSchedule?.status === 'makeup' ? (
            <Text style={[styles.text, { marginTop: 10 }]}>
              <Text style={{ fontWeight: '500' }}>{t('site:makeUpDate')} : </Text>
              {moment(selectedDateCalendar).format('DD MMMM, YYYY')}
            </Text>
          ) : null}
        </View>
        {/* text box for make up reason
        currently we only can make up on latest missed date
      */}
        {selectedSchedule?.status === 'makeup' ? (
          <TextInput
            multiline={true}
            textAlignVertical="top"
            placeholder={t('schedule:reason')}
            onChangeText={text => {
              setMakeupReason(text);
            }}
            style={styles.makeupReasonContainer}
            value={makeupReason}
          />
        ) : null}
      </>
    );
  };

  return (
    <Modal visible={showFooter} transparent={true} animationType={'fade'}>
      <View style={styles.root}>
        <Animated.View
          onLayout={(event: any) => {
            const { height } = event.nativeEvent.layout;
            setContainerHeight(height);
          }}
          style={[
            styles.container,
            isShowKeyboard && Platform.OS === 'ios' ? { paddingBottom: 120 } : {},
            {
              transform: [getTransformCondition]
            }
          ]}
          testID="outlet-footer"
        >
          <View style={styles.close}>
            <TouchableOpacity onPress={() => handleCloseOutletFooter()} testID="outlet-footer-close">
              <Icon name={'close'} size={25} />
            </TouchableOpacity>
          </View>
          {isMultisite ? null : (
            <View style={styles.containerTitle}>
              <Text style={styles.titleText}>{t('footer.title')}</Text>
            </View>
          )}

          <View style={styles.body}>
            {isMultisite ? renderBodyMultiSite() : renderBodySingleSite()}

            {isMultisite ? (
              <View style={styles.multsiteContainer}>
                <Text style={styles.multisiteTitle}>{t('footer.multisiteTitle')}</Text>
                <Text style={styles.multisiteSubtitle}>{t('footer.multisiteSubtitle')}</Text>
              </View>
            ) : null}

            <SiteActionButton
              disabled={isFutureSchedule}
              isAdhoc={isAdhoc}
              isBusy={isBusy}
              isGPSMocked={isGPSMocked}
              isLeader={isLeader}
              isScheduleSelected={isScheduleSelected}
              isOffline={isOffline}
              isFocused={isFocused}
              locationPermissionGranted={locationPermissionGranted}
              status={status}
              profileColor={profileColor}
              roleAccessAdhoc={roleAccessAdhoc}
              featureAccessAdhoc={featureAccessAdhoc}
              selectedSite={selectedSite}
              makeupDate={getLatestMakeupDate}
              makeupReason={makeupReason}
              hasCheckedIn={hasCheckedIn}
              selectedSchedule={selectedSchedule}
              selectedDateCalendar={selectedDateCalendar}
              handleMakeupReport={handleMakeupReport}
              onCalibrateLocation={onCalibrateLocation}
              onCheckIn={onCheckIn}
              onConfirmCheckIn={onConfirmCheckIn}
              onReviewPendingUpload={onReviewPendingUpload}
              handleDeleteDraftReport={handleDeleteDraftReport}
              setActiveTab={setActiveTab}
              setShowFooter={setShowFooter}
            />
          </View>
        </Animated.View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  root: {
    backgroundColor: 'rgba(0,0,0,0.4)',
    flex: 1,
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH,
    marginHorizontal: 'auto'
  },
  container: {
    position: 'absolute',
    bottom: 0,
    width: '100%',

    // based on height of container to wrapped all component
    backgroundColor: '#fff',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,

    // box shadow
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5
    },
    shadowOpacity: 0.36,
    shadowRadius: 6.68,

    elevation: 11
  },
  close: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: 15,
    paddingRight: 10,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20
  },
  closeButton: {
    height: 25,
    width: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: 25 / 2,
    borderTopRightRadius: 25 / 2,
    borderBottomLeftRadius: 25 / 2,
    borderColor: theme.styles.fontColor.headline,
    borderWidth: 2
  },
  body: { backgroundColor: '#ffffff', padding: 20, paddingBottom: 38 },
  timeStatusContainer: {
    alignItems: 'flex-start',
    marginBottom: 10
  },
  scheduleTypeOption: {
    // SCREEN WIDTH - padding
    width: (SCREEN_WIDTH - 40 - 4) / 2,
    justifyContent: 'center',
    alignItems: 'center'
  },
  activeScheduleOption: {
    backgroundColor: '#00AB4E',
    borderRadius: 30
  },
  activeText: {
    color: '#ffffff'
  },
  text: {
    fontSize: 14,
    lineHeight: 19,
    color: theme.styles.textDark.color,
    marginBottom: 10
  },
  startButton: {
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.green,
    paddingVertical: 10
  },
  startButtonText: {
    fontSize: 18,
    lineHeight: 27,
    ...fontMaker({ weight: 'Bold' }),
    color: '#ffffff'
  },
  scheduleTypeButton: {
    height: 48,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',

    borderColor: theme.colors.lightgreen,
    borderWidth: 1,
    borderRadius: 5
  },
  activeSelectedTypeButton: {
    backgroundColor: theme.colors.secondarylightgreen
  },
  typeText: {
    fontSize: 18,
    lineHeight: 27,
    ...fontMaker({ weight: 'SemiBold' }),
    color: theme.colors.lightgreen
  },
  containerTitle: { justifyContent: 'center', alignItems: 'center', marginBottom: 12 },
  titleText: {
    fontSize: 14,
    lineHeight: 19,
    color: theme.styles.textDark.color,
    ...fontMaker({ weight: 'SemiBold' })
  },
  containerSelectType: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 50
  },
  disableBorder: {
    borderColor: 'rgba(86, 199, 104, 0.3)'
  },
  disableText: {
    color: 'rgba(86, 199, 104, 0.3)'
  },
  multsiteContainer: {
    marginBottom: 20,
    color: theme.colors.dark
  },
  multisiteTitle: {
    fontSize: 18,
    lineHeight: 25,
    fontWeight: '500'
  },
  multisiteSubtitle: {
    marginTop: 10,
    fontSize: 14,
    lineHeight: 19,
    ...fontMaker({ weight: 'regular' })
  },
  makeupReasonContainer: {
    width: '100%',
    borderWidth: 1,
    borderRadius: 3,
    borderColor: '#CFE3FF',
    marginBottom: 30,
    height: 60,
    padding: 10,
    marginTop: 25
  },
  boldText: {
    ...fontMaker({ weight: 'SemiBold' })
  },
  divider: {
    width: 20
  }
});

export default SiteScheduleFooterModal;
