import React from 'react';
import {
  Platform,
  ScrollView,
  StyleSheet,
  TouchableNativeFeedback,
  TouchableOpacity,
  View,
  TextInput,
  TextStyle,
  Image,
  ActivityIndicator
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

// components
import Text from '../global/Text';
import Button from '../global/Button';
import theme from '../../constants/theme';
import ChipInput from '../global/ChipInput';

// utils
import { SCREEN_WIDTH, SCREEN_HEIGHT } from '../../utils/screensize';
import fontMaker from '../../utils/font';
import { useTranslation } from 'react-i18next';

// types
import { CreateCompanyContainerProps } from './type';

const CreateCompanyContainer = (props: CreateCompanyContainerProps) => {
  const { t } = useTranslation(['company']);
  const {
    hasNotch,
    isLoading,
    saveLoading,
    companyName,
    logoPath,
    language,
    description,
    weakness,
    website,
    established,
    noOfEmployees,
    weaknesses,
    strength,
    strengths,
    activateGallery,
    handleConfirmEditPhoto,
    handleSave,
    handleNavCapturePhoto,
    setCompanyName,
    setWebsite,
    setWeakness,
    setDescription,
    setEstablished,
    setNoOfEmployees,
    setWeaknesses,
    setStrength,
    setStrengths
  } = props;

  if (isLoading || saveLoading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator color={theme.colors.primaryDark} size="large" />
      </View>
    );
  }

  return (
    <View style={{ flex: 1 }} testID="create-company-container">
      <ScrollView style={styles.container} contentContainerStyle={{ paddingBottom: 100, paddingTop: 20 }}>
        <Text style={styles.label}>{t('company:form.companyName')}</Text>
        <View style={[styles.inputContainer, !companyName && { borderColor: '#ffa040' }]}>
          <TextInput
            style={styles.answerInput}
            maxLength={30}
            onChangeText={text => setCompanyName(text)}
            value={companyName}
            placeholder={t('company:placeholder.companyName')}
            testID="company-name"
          />
        </View>

        <Text style={styles.label}>{t('company:form.companyLogo')}</Text>
        {!logoPath ? (
          <View style={styles.photoContainer}>
            <Touchable onPress={handleNavCapturePhoto} testID="capture-photo" delayPressIn={0}>
              <View style={styles.button}>
                <Icon name="camera" color={'#3cd070'} size={20} />
                <Text>{t('company:form.takePicture')}</Text>
              </View>
            </Touchable>
            <Touchable onPress={activateGallery} testID="active-gallery" delayPressIn={0}>
              <View style={styles.button}>
                <Icon name="image" color={'#3cd070'} size={20} />
                <Text>{t('company:form.fromGallery')}</Text>
              </View>
            </Touchable>
          </View>
        ) : null}

        {logoPath ? (
          <TouchableOpacity onPress={handleConfirmEditPhoto} delayPressIn={0}>
            <View style={styles.imageContainer}>
              <Image style={styles.image} resizeMode="cover" source={{ uri: logoPath }} />
            </View>
          </TouchableOpacity>
        ) : null}

        <Text style={styles.label}>{t('company:form.description')}</Text>
        <View style={[styles.inputContainer, styles.inputContainerMultiline]}>
          <TextInput
            style={styles.answerInput}
            multiline={true}
            onChangeText={text => setDescription(text)}
            value={description}
            placeholder={t('company:placeholder.describeCompany')}
            testID="company-description"
          />
        </View>

        <Text style={styles.label}>{t('company:form.website')}</Text>
        <View style={styles.inputContainer}>
          <TextInput
            style={styles.answerInput}
            maxLength={30}
            onChangeText={text => setWebsite(text)}
            value={website}
            placeholder={t('company:form.website')}
          />
        </View>

        <Text style={styles.label}>{t('company:form.established')}</Text>
        <View style={styles.inputContainer}>
          <TextInput
            style={styles.answerInput}
            maxLength={4}
            keyboardType="numeric"
            onChangeText={text => {
              if (!isNaN(Number(text))) {
                setEstablished(text);
              }
            }}
            value={established}
            placeholder={t('company:form.established')}
            testID="company-established"
          />
        </View>

        <Text style={styles.label}>{t('company:form.numEmployees')}</Text>
        <View style={styles.inputContainer}>
          <TextInput
            style={styles.answerInput}
            keyboardType="numeric"
            onChangeText={text => {
              if (!isNaN(Number(text))) {
                setNoOfEmployees(text);
              }
            }}
            value={noOfEmployees}
            placeholder={t('company:form.numEmployees')}
            testID="company-employees"
          />
        </View>

        <Text style={styles.label}>{t('company:form.strength')}</Text>
        <ChipInput
          value={strength}
          values={strengths}
          setValue={setStrength}
          setValues={setStrengths}
          placeholder={t('company:placeholder.strength')}
        />

        <Text style={styles.label}>{t('company:form.weakness')}</Text>
        <ChipInput
          value={weakness}
          values={weaknesses}
          setValue={setWeakness}
          setValues={setWeaknesses}
          placeholder={t('company:placeholder.weakness')}
        />
      </ScrollView>
      <View style={[styles.buttonContainer, hasNotch ? styles.buttonContainerNotch : null]}>
        <Button
          disabled={isLoading || !companyName}
          title={t('company:form.save')}
          onPress={handleSave}
          style={[styles.button, { width: SCREEN_WIDTH - 30 }]}
          testID="save-button"
        />
      </View>
    </View>
  );
};

export default CreateCompanyContainer as any;

const Touchable: any = Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 20
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center'
  },
  label: {
    fontWeight: 'bold',
    fontSize: 15,
    marginBottom: 5
  },
  inputContainer: {
    borderRadius: 4,
    backgroundColor: theme.colors.light,
    height: 40,
    flexDirection: 'row',
    paddingHorizontal: 10,
    marginBottom: 10,
    borderWidth: 1,
    borderColor: 'white'
  },
  inputContainerMultiline: {
    height: 100,
    paddingVertical: 10
  },
  answerInput: {
    ...fontMaker({ weight: 'Regular' }),
    color: '#535353',
    textAlignVertical: 'top',
    paddingTop: 0,
    paddingHorizontal: 10,
    width: '100%'
  },
  button: {
    borderRadius: 4,
    flexDirection: 'row',
    height: 40,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: SCREEN_WIDTH / 2 - 30,
    paddingHorizontal: 5
  },
  photoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  imageContainer: {
    overflow: 'hidden',
    width: 60,
    height: 60,
    marginTop: 5,
    marginHorizontal: 20,
    borderRadius: 4,
    backgroundColor: '#a8a8aa',
    marginBottom: 20
  },
  image: {
    width: '100%',
    height: '100%'
  },
  buttonContainer: {
    width: '100%',
    minHeight: 64,
    height: SCREEN_HEIGHT * 0.1,
    paddingVertical: 12,
    paddingHorizontal: SCREEN_WIDTH * 0.2,
    backgroundColor: '#535353',
    zIndex: 2,
    position: 'absolute',
    bottom: 0,
    alignItems: 'center'
  },
  buttonContainerNotch: {
    height: SCREEN_HEIGHT * 0.1 + 12
  }
});
