/* eslint-disable */
import { Question } from '../../../utils/classes';
import { destructConditionalQuestions } from '../../../utils/destructConditionalQuestions';
import { cloneDeep } from 'lodash';
import _renderFlagText from './renderFlagText';
import getRangeFlagColor from '../../questionnaire/utils/getRangeFlagColor';
import { QuestionTypes } from 'nimbly-common/lib/enumerators';

/**
 * Get report comments for overview details
 * @param selectedReport - The selected report
 */
export default function getSATReportComments(sat_questions: Question[]) {
  const questions = cloneDeep(sat_questions);
  const comments: string[] = [];

  const destuctQuestions = destructConditionalQuestions(questions);
  for (const question of destuctQuestions) {
    if (
      question.type === QuestionTypes.SCORE &&
      typeof question.answer === 'number' &&
      question.answer / question.score < 0.8
    ) {
      comments.push(`${question.content}: ${question.answer}/${question.score}`);
      continue;
    }
    if (question.type === QuestionTypes.MULTIPLE_CHOICE && question.answer !== 'not-applicable') {
      const choiceIndex: number = question.multipleChoices.indexOf(question.answer as string);
      // eslint-disable-next-line id-blacklist
      const alphabet = choiceIndex > -1 ? `${String.fromCharCode(65 + choiceIndex)}.` : '';
      comments.push(`${question.content}: ${alphabet} ${question.answer}`);
      continue;
    }
    if (question.type === QuestionTypes.RANGE_FLAG && question.answer !== 'not-applicable' && comments.length < 5) {
      comments.push(`${question.content}: ${question.comment || _renderFlagText(getRangeFlagColor(question))}`);
      continue;
    }
    if (question.type === QuestionTypes.MULTIPLE_CHOICE_SCORE) {
      comments.push(`${question.content}${question.comment ? ': ' + question.comment : ''}`);
      continue;
    }
    if (question.type === QuestionTypes.CHECKLIST) {
      comments.push(`${question.content} : ${question.answer}`);
      continue;
    }
    if (typeof question.answer === 'number') {
      comments.push(`${question.content}${question.answer ? ': ' + question.answer : ''}`);
      continue;
    }
    if (
      question.type !== QuestionTypes.OPEN &&
      question.type !== QuestionTypes.NUMBER &&
      question.type !== QuestionTypes.INVENTORY &&
      question.type !== QuestionTypes.INVENTORY_V2 &&
      question.type !== QuestionTypes.SCORE &&
      question.type !== QuestionTypes.COMPETITOR &&
      question.answer !== 'yes' &&
      question.answer !== 'not-applicable' &&
      comments.length < 5
    ) {
      comments.push(
        `${question.content}${question.parentIndex ? '-Conditional' : ''}: ${
          question.comment || _renderFlagText(question.answer.toString())
        }`
      );
      continue;
    }
  }
  return comments;
}
