import { QuestionnaireIndex } from 'nimbly-common';
import { takeLatest, put, call } from 'redux-saga/effects';

import { QuestionnaireIndexes } from './type';
import * as actions from './questionnaireIndex.action';
import * as nimblyApi from '../../../constants/api';
import { getFirebase } from 'react-redux-firebase';

export function* fetchQuestionnaireIndexSaga() {
  try {
    const firebase = getFirebase();
    if (firebase.auth) {
      yield put(actions.setLoading(true));
      const token = (yield firebase.auth().currentUser!.getIdToken()) as string;

      const options = {
        method: 'GET',
        headers: {
          authorization: token
        }
      };

      const url = `${nimblyApi.NIMBLY_API_QUESTIONNAIRES_INDEX}`;
      const request = () => fetch(url, options);
      const response = yield call(request);
      let result = null;

      if (response && response.status === 200) {
        result = yield response.json();
        const mappedQuestionnaireIndex: QuestionnaireIndexes = {};

        result.data.forEach((questionnaireIdx: QuestionnaireIndex, i: number) => {
          const { questionnaireIndexID } = questionnaireIdx;
          if (!mappedQuestionnaireIndex.hasOwnProperty(questionnaireIndexID)) {
            mappedQuestionnaireIndex[questionnaireIndexID] = questionnaireIdx;
          }
        });
        yield put(actions.fetchQuestionnaireIndex.success({ data: mappedQuestionnaireIndex }));
        return null;
      }

      result = yield response.json();
      yield put(actions.fetchQuestionnaireIndex.failure({ error: result.message }));
      return null;
    }
  } catch (err) {
    yield put(actions.fetchQuestionnaireIndex.success({ data: null }));
    yield put(actions.fetchQuestionnaireIndex.failure({ error: '' }));
    return null;
  }
}
