import React from 'react';
import { View, Modal, TouchableOpacity, Text, StyleSheet, FlatList, Image, Linking, Dimensions } from 'react-native';
import DeviceInfo from 'react-native-device-info';

import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import { dataTutorials } from './assets/dataTutorials';
import { useTranslation } from 'react-i18next';

type AuthVideoTutorialProps = {
  /** Boolean true if the phone has a notch */
  hasNotch: boolean;
  /** Handle - set visible of modal video tutorial list */
  handleVisibleTutorialModal: any;
  /** Get - state of modal video tutorial */
  visibleTutorialModal: boolean;
  /** Get - status proses loading or not */
};

type Tutorial = {
  videoId: string;
  title: string;
};

// Item video tutorial
export const ItemTutorial = (tutorial: Tutorial) => {
  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() =>
        Linking.openURL(
          `https://www.youtube.com/embed/${tutorial.videoId}?rel=0&autoplay=1&showinfo=1&controls=1&fullscreen=1`
        )
      }
      testID={`button-tutorial-${tutorial.videoId}`}
    >
      <View style={styles.thumbnail}>
        <Image source={require('../../assets/icons/play-button.png').default} style={styles.playButton} />
      </View>
      <Text style={styles.text}>{tutorial.title}</Text>
    </TouchableOpacity>
  );
};

const AuthVideoTutorialModal = (props: AuthVideoTutorialProps) => {
  const { t } = useTranslation(['settings', 'common']);
  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={props.visibleTutorialModal}
      onRequestClose={() => props.handleVisibleTutorialModal(false)}
    >
      <View style={styles.root}>
        <View style={[styles.header, { height: props.hasNotch ? 75 : 50 }]}>
          <TouchableOpacity
            testID="close-button"
            onPress={() => props.handleVisibleTutorialModal(false)}
            style={[styles.close, { top: props.hasNotch ? 40 : 12 }]}
          >
            <Icon name="close" size={25} color="#ffffff" />
          </TouchableOpacity>
          <View style={styles.headerTitle}>
            <Text style={[styles.title, { marginTop: props.hasNotch ? 30 : 0 }]}>
              {t('settings:videoTutorialSmall')}
            </Text>
          </View>
        </View>
        <View>
          <FlatList
            data={dataTutorials}
            renderItem={({ item }) => <ItemTutorial {...item} />}
            keyExtractor={({ videoId }) => videoId}
          />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  root: {
    backgroundColor: '#ffffff',
    height: '100%'
  },
  close: {
    position: 'absolute',
    left: 10
  },
  headerTitle: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  header: {
    flexDirection: 'row',
    height: 50,
    width: '100%',
    backgroundColor: '#3cd070',

    justifyContent: 'center',
    alignItems: 'center'
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    color: '#ffffff'
  },
  playButton: {
    width: 40,
    height: 40
  },
  thumbnail: {
    width: 80,
    height: 60,

    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    color: '#535353',
    marginLeft: 10,
    maxWidth: Dimensions.get('window').width - 80 - 10 - 25
  },
  container: {
    borderBottomWidth: 1,
    borderBottomColor: '#EFEEED',

    paddingVertical: 20,
    paddingHorizontal: 10,

    flexDirection: 'row',
    alignItems: 'center'
  }
});

export default AuthVideoTutorialModal;
