import { action, ActionType } from 'typesafe-actions';

import * as actionTypes from './reportcache.actionTypes';

import * as Classes from '../../../utils/classes';
import { OrganizationReportSections, MultiSummaries } from './reportcache.types';

export const setCacheReport = (siteKey: string, reportKey: string, report: Classes.Report) =>
  action(actionTypes.SET_CACHE_REPORT, {
    siteKey,
    reportKey,
    report
  });
type SetReportAction = ActionType<typeof setCacheReport>;

export const setCacheReportSummary = (siteKey: string, reportKey: string, reportSummary: Classes.ReportSummary) =>
  action(actionTypes.SET_CACHE_REPORT_SUMMARY, {
    siteKey,
    reportKey,
    reportSummary
  });

export const setCacheReportAndSummary = (
  siteKey: string,
  reportKey: string,
  report: Classes.Report,
  reportSummary: Classes.ReportSummary
) => action(actionTypes.SET_CACHE_REPORT_AND_SUMMARY, { siteKey, reportKey, report, reportSummary });

export const removeCacheReportAndSummary = (siteKey: string, reportKey: string) =>
  action(actionTypes.REMOVE_CACHE_REPORT_AND_SUMMARY, {
    siteKey,
    reportKey
  });

export const setReportSectionState = (
  siteKey: string,
  reportKey: string,
  sectionIdx: number,
  report: Classes.ReportSection
) => action(actionTypes.SET_REPORT_SECTION_STATE, { siteKey, reportKey, sectionIdx, report });

// Removes the report section state of all sections in a site
export const removeSiteReportSectionState = (siteKey: string) =>
  action(actionTypes.REMOVE_SITE_REPORT_SECTION_STATE, { siteKey });

// Removes the report section state of one section
export const removeReportSectionState = (siteKey: string, reportKey: string, sectionIdx: number) =>
  action(actionTypes.REMOVE_REPORT_SECTION_STATE, { siteKey, reportKey, sectionIdx });

export const initReportCache = (
  reports: Classes.OrganizationReport,
  summaries: Classes.OrganizationReportIndex,
  multiReports: OrganizationReportSections,
  multiSummaries: MultiSummaries
) => action(actionTypes.INIT_REPORT_CACHE, { reports, summaries, multiReports, multiSummaries });

export const setCacheReady = () => action(actionTypes.SET_CACHE_READY);

export const clearCache = () => action(actionTypes.CLEAR_CACHE);

export const setBackupReport = (reportKey: string, report: Classes.Report, summary: Classes.ReportSummary) =>
  action(actionTypes.SET_BACKUP_REPORT, { reportKey, report, summary });

export const removeBackupReports = (reportKeys: string[]) => action(actionTypes.REMOVE_BACKUP_REPORTS, { reportKeys });

export const clearBackupCache = () => action(actionTypes.CLEAR_BACKUP_CACHE);
