import React from 'react';
import { View } from 'react-native';

import Tutorials from '../components/tutorials/Tutorials';

const TutorialsPage = React.memo(() => (
  <View>
    <Tutorials />
  </View>
));

export default TutorialsPage;
