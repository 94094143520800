import { ActionType, createReducer } from 'typesafe-actions';
import update from 'immutability-helper';
import { statistic } from 'nimbly-common';

import * as actions from './action';

export type ProfileStatisticAction = ActionType<typeof actions>;

type BaseState = {
  isLoaded: boolean;
  isLoading: boolean;
  error: null | string;
};

type CompletionData = BaseState & {
  daily: statistic.UserOneCompletionDaily[];
  overview: statistic.UserCompletionOverview | null;
};

type FlagOverviewData = BaseState & {
  scheduled: null | statistic.QuestionFlagOverview;
  adhoc: null | statistic.QuestionFlagOverview;
};

export type ProfileStatisticState = {
  weeklyCompletion: CompletionData;
  weeklyFlagOverview: FlagOverviewData;

  monthlyCompletion: CompletionData;
  monthlyFlagOverview: FlagOverviewData;
};

const initialState: ProfileStatisticState = {
  weeklyCompletion: {
    isLoaded: false,
    isLoading: false,
    daily: [],
    overview: null,
    error: null
  },
  weeklyFlagOverview: {
    isLoaded: false,
    isLoading: false,
    error: null,
    scheduled: null,
    adhoc: null
  },

  monthlyCompletion: {
    isLoaded: false,
    isLoading: false,
    daily: [],
    overview: null,
    error: null
  },
  monthlyFlagOverview: {
    isLoaded: false,
    isLoading: false,
    error: null,
    scheduled: null,
    adhoc: null
  }
};

export const profileStatisticReducer = createReducer<ProfileStatisticState, ProfileStatisticAction>(initialState)
  // WEEKLY COMPLETION
  .handleAction(actions.fetchWeeklyCompletion.request, state =>
    update(state, {
      weeklyCompletion: {
        isLoading: { $set: true }
      }
    })
  )
  .handleAction(actions.fetchWeeklyCompletion.success, (state, action) =>
    update(state, {
      weeklyCompletion: {
        isLoaded: { $set: true },
        isLoading: { $set: false },
        error: { $set: null },
        daily: { $set: action.payload.daily },
        overview: { $set: action.payload.overview }
      }
    })
  )
  .handleAction(actions.fetchWeeklyCompletion.failure, (state, action) =>
    update(state, {
      weeklyCompletion: {
        isLoading: { $set: false },
        isLoaded: { $set: true },
        error: { $set: action.payload.error }
      }
    })
  )

  // WEEKLY FLAG OVERVIEW
  .handleAction(actions.fetchWeeklyFlagOverview.request, state =>
    update(state, {
      weeklyFlagOverview: {
        isLoading: { $set: true }
      }
    })
  )
  .handleAction(actions.fetchWeeklyFlagOverview.success, (state, action) =>
    update(state, {
      weeklyFlagOverview: {
        isLoaded: { $set: true },
        isLoading: { $set: false },
        error: { $set: null },
        scheduled: { $set: action.payload.scheduled },
        adhoc: { $set: action.payload.adhoc }
      }
    })
  )
  .handleAction(actions.fetchWeeklyFlagOverview.failure, (state, action) =>
    update(state, {
      weeklyFlagOverview: {
        isLoading: { $set: false },
        isLoaded: { $set: true },
        error: { $set: action.payload.error }
      }
    })
  )

  // MONTHLY COMPLETION
  .handleAction(actions.fetchMonthlyCompletion.request, state =>
    update(state, {
      monthlyCompletion: {
        isLoading: { $set: true }
      }
    })
  )
  .handleAction(actions.fetchMonthlyCompletion.success, (state, action) =>
    update(state, {
      monthlyCompletion: {
        isLoaded: { $set: true },
        isLoading: { $set: false },
        error: { $set: null },
        daily: { $set: action.payload.daily },
        overview: { $set: action.payload.overview }
      }
    })
  )
  .handleAction(actions.fetchMonthlyCompletion.failure, (state, action) =>
    update(state, {
      monthlyCompletion: {
        isLoading: { $set: false },
        isLoaded: { $set: true },
        error: { $set: action.payload.error }
      }
    })
  )

  // MONTHLY FLAG OVERVIEW
  .handleAction(actions.fetchMonthlyFlagOverview.request, state =>
    update(state, {
      monthlyFlagOverview: {
        isLoading: { $set: true }
      }
    })
  )
  .handleAction(actions.fetchMonthlyFlagOverview.success, (state, action) =>
    update(state, {
      monthlyFlagOverview: {
        isLoaded: { $set: true },
        isLoading: { $set: false },
        error: { $set: null },
        scheduled: { $set: action.payload.scheduled },
        adhoc: { $set: action.payload.adhoc }
      }
    })
  )
  .handleAction(actions.fetchMonthlyFlagOverview.failure, (state, action) =>
    update(state, {
      monthlyFlagOverview: {
        isLoading: { $set: false },
        isLoaded: { $set: true },
        error: { $set: action.payload.error }
      }
    })
  );
