import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs/src/types';
import { CompositeNavigationProp, useIsFocused, useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack/src/types';
import { cloneDeep } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/id';
import 'moment/locale/pt';
import 'moment/locale/km';
import 'moment/locale/th';

import 'moment/min/moment-with-locales';
import { enums } from 'nimbly-common';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Alert,
  Animated,
  AppState,
  AppStateStatus,
  InteractionManager,
  Platform,
  StyleSheet,
  View
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { connect, useDispatch } from 'react-redux';
import { firebaseConnect, getVal, isLoaded, populate } from 'react-redux-firebase';
import { compose } from 'redux';
import BackgroundTimer from '../../helpers/BackgroundTimer';
import i18n from '../../i18n';
import { AppTabsParamList, HomeStackParamList } from '../../routes';
import { RootState } from '../../store/reducers';
import { geolocationThunk } from '../../store/reducers/geolocation';
import { journeyActions } from '../../store/reducers/journey/journey';
import { setNotificationModal } from '../../store/reducers/notification';
import { organizationActions } from '../../store/reducers/organization/organization';
import { payoffActions } from '../../store/reducers/payoff';
import { getPopulatedQuestionnairesAsync } from '../../store/reducers/questionnaires/questionnaire.action';
import { ScheduledJourneyInfo, scheduleJourneysThunk } from '../../store/reducers/schedulejourneys';
import { ScheduledSiteInfo, scheduleSitesActions, scheduleSitesThunk } from '../../store/reducers/schedulesites';
import { userActions } from '../../store/reducers/user/user';
import { ConnectedDispatch, FirebaseConnect, PopulatedQuestionnaireIndex } from '../../typing/types';
import { OrganizationSchedule, PreGeneratedReport, ReportSummary } from '../../utils/classes';
import { errorLogger } from '../../utils/errorLogger';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../utils/screensize';
import getJourneyReportStatus from '../schedule/utils/getJourneyReportStatus';
import getJourneyStatus from '../schedule/utils/getJourneyStatus';
import checkLocationPermission from '../site/utils/checkLocationPermission';
import openSettings from '../site/utils/openSettings';
import WelcomeDaySchedule from './WelcomeDaySchedule';
import WelcomeOffDayModal from './WelcomeOffDayModal';
import WelcomeSummary from './WelcomeSummary';
import WelcomeTracker from './WelcomeTracker';

const COLLAPSIBLE_HEADER_HEIGHT = SCREEN_WIDTH >= 360 ? SCREEN_HEIGHT * 0.3 : SCREEN_HEIGHT * 0.5;

type WelcomePageNavigationProp = CompositeNavigationProp<
  BottomTabNavigationProp<AppTabsParamList, 'HomeTab'>,
  StackNavigationProp<HomeStackParamList, 'Home'>
>;

const Welcome = (props: WelcomeProps) => {
  const { t } = useTranslation(['home, common']);
  const navigation = useNavigation<WelcomePageNavigationProp>();
  const isFocused = useIsFocused();
  const dispatch = useDispatch();
  const [_authListener, setAuthListener] = useState<any>(null);
  const [_permissionTimeout, setPermissionTimeout] = useState<any>(null);
  const [_scrollY, setScrollY] = useState<Animated.Value>(new Animated.Value(0));
  const [_headerHeight, setHeaderHeight] = useState<Animated.Animated>(
    Animated.multiply(Animated.diffClamp(_scrollY, 0, COLLAPSIBLE_HEADER_HEIGHT), -1)
  );
  const [_showOffDayModal, setShowOffDayModal] = useState<boolean>(false);
  const [_showNotificationModal, setShowNotificationModal] = useState<boolean>(false);
  const [_defaultProgressView, setDefaultProgressView] = useState<boolean>(props.profile.organization !== 'pigeon');
  const [_isTransitioned, setIsTransitioned] = useState<boolean>(false);
  const [_isReady, setIsReady] = useState<boolean>(false);
  const [_sortedSites, setSortedSites] = useState<any>([]);
  const [_sortedJourneys, setSortedJourneys] = useState<any>([]);
  const [_locationPermissionGranted, setLocationPermissionGranted] = useState<boolean>(Platform.OS === 'ios');
  const [appState, setAppState] = useState<AppStateStatus>('active');
  const [_firstVisit, setFirstVisit] = useState<boolean>(true);

  useEffect(() => {
    const { notificationModal } = props.notification;
    if (notificationModal) {
      handleOpenNotificationModal();
      setNotificationModal(false); //rest to false
    }
  }, [props.notification]);

  // Load sagas
  useEffect(() => {
    props.dispatch(userActions.fetchUsersAsync.request(undefined));
  }, []);

  /** Open issue notification in foreground app **/
  useEffect(() => {
    if (!_isTransitioned) {
      return;
    }
  }, [_isTransitioned]);

  /**
   * useEffect
   */
  useEffect(() => {
    // Make sure the page is fully loaded. Parameter used to execute other function
    InteractionManager.runAfterInteractions(() => {
      setIsTransitioned(true);
    });
  }, []);

  /**
   * Component di mount
   */
  useEffect(() => {
    const { language, organization } = props;

    if (!_isTransitioned) {
      return;
    }

    moment.locale(language);
    if (!props.isFetchingMySites && (props.mySitesScheduled.length === 0 || _firstVisit)) {
      dispatch(scheduleSitesThunk.getMyScheduledSites(organization?.schedule as OrganizationSchedule));
    }

    if (!props.questionnaireIsLoading && (Object.keys(props.allQuestionnaireIndex || {}).length === 0 || _firstVisit)) {
      // download this data here but don't prevent the page from show completion
      // this data only appears to be used when submitting a report
      dispatch(getPopulatedQuestionnairesAsync.request());
    }

    if (_firstVisit) {
      setFirstVisit(false);
    }

    // empty selected journey
    dispatch(journeyActions.setJourney(null));
    dispatch(journeyActions.setJourneyReport(null));
    dispatch(journeyActions.setJourneyError(null));

    dispatch(organizationActions.fetchMyOrganizationAsync.request(undefined));
  }, [_isTransitioned]);

  /**
   * Update changes
   */
  useEffect(() => {
    let currentSummary: ReportSummary | null = null;
    let sortedSites: ScheduledSiteInfo[] | null = null;

    if (props.mySitesScheduled) {
      const schedules = cloneDeep(props.mySitesScheduled || []);
      schedules.forEach((siteInfo: ScheduledSiteInfo) => {
        if (
          props?.pendingSites[siteInfo.siteKey] &&
          (!isEmpty(props.pendingSites[siteInfo.siteKey].pendingMulti) ||
            !isEmpty(props.pendingSites[siteInfo.siteKey].pendingSingle))
        ) {
          // intercept local pending-upload
          siteInfo.isPendingUpload = true;
        } else if (props?.cacheReportIndex[siteInfo.siteKey]) {
          // intercept local draft immediately break from inner for loop if found
          for (const reportKey of Object.keys(props.cacheReportIndex[siteInfo.siteKey])) {
            currentSummary = props.cacheReportIndex[siteInfo.siteKey][reportKey];
            if (currentSummary !== null) {
              if (currentSummary.status === 'draft') {
                siteInfo.isDraft = true;
                break;
              }
            }
          }
        }

        if (props.isDummy && !siteInfo.isPendingUpload && !siteInfo.isDraft) {
          siteInfo.isMakeUp = false;
          siteInfo.isCompletedThisPeriod = false;
          siteInfo.isCompletedToday = false;
          siteInfo.isDueToday = true;
        }
      });

      sortedSites =
        schedules
          .filter(
            (siteInfo: any) => siteInfo.isDueToday || siteInfo.isMakeUp || siteInfo.isDraft || siteInfo.isPendingUpload
          )
          ?.sort((a: any, b: any) => {
            if (a.isPendingUpload && !b.isPendingUpload) {
              return -1;
            }

            // the rest already sorted at cloud function
            return 0;
          }) || [];

      setSortedSites(sortedSites);
    }
  }, [props.mySitesScheduled, props.isUploading, props.pendingSites]);

  /**
   * Update changes - journey
   */
  useEffect(() => {
    // feature access on journey plan
    if (props.myJourneysScheduled.length > 0 && props.featureAccessJourney) {
      let sortedJourneys: ScheduledJourneyInfo[] | null = null;

      if (!props.ongoingJourneyReportKey) {
        //get ongoing journey from journey schedules
        const ongoingJourney = props.myJourneysScheduled.find(
          journeySchedule => getJourneyReportStatus(journeySchedule.report, props.ongoingJourneyReportKey) === 'ongoing'
        );

        //start journey tracking if there is an ongoing journey report
        //ongoing journey should be only one item
        if (ongoingJourney) {
          const ongoingJourneyReportKey = ongoingJourney.report!.code;

          dispatch(journeyActions.setOngoingJourneyReportKey(ongoingJourneyReportKey));
          BackgroundTimer.startJourneyTracking(ongoingJourneyReportKey);
        }
      }

      sortedJourneys = props.myJourneysScheduled.filter(
        journeyInfo =>
          (!journeyInfo.report || (journeyInfo.report && !journeyInfo.report.hasEnded)) &&
          journeyInfo.isDueToday &&
          getJourneyStatus(journeyInfo) !== 'finished-today'
      );
      setSortedJourneys(sortedJourneys);
    }
  }, [props.myJourneysScheduled, props.featureAccessJourney]);

  /**
   * Effect to check and set component ready state
   */
  useEffect(() => {
    setIsReady(
      !!(
        props.organization &&
        props.cacheReady &&
        isFocused &&
        !props.isFetchingMySites &&
        !props.isFetchingMyJourneys &&
        _isTransitioned
      )
    );
  }, [
    props.organization,
    props.cacheReady,
    isFocused,
    props.isFetchingMySites,
    props.isFetchingMyJourneys,
    _isTransitioned
  ]);

  /**
   * Component lost focus (unmount)
   */
  useEffect(() => {
    _scrollY.setValue(0);
    InteractionManager.runAfterInteractions(() => {
      setIsTransitioned(true);
    });
  }, [isFocused]);

  /**
   * Update content when origanization is changed
   */
  useEffect(() => {
    if (props.organization && props.organization.schedule) {
      props.dispatch(scheduleSitesThunk.getMyScheduledSites(props.organization.schedule));
    }
  }, [props.organization]);

  /**
   * Update the cache location when permission changes to 'granted' / 'authorize'
   */
  useEffect(() => {
    const currentTimestamp = moment().valueOf();
    if (!props.cacheLocation.isFetched || currentTimestamp - props.cacheLocation.timestamp > 15 * 60 * 1000) {
      props.dispatch(geolocationThunk.updateCacheLocation());
    }
  }, [_locationPermissionGranted]);

  /**
   * Whenever welcome screen is focused, the permission granted, and the cached
   * location time is more than 15 minutes, the location cache will be updated
   */
  useEffect(() => {
    const currentTimestamp = moment().valueOf();
    if (isFocused && _locationPermissionGranted) {
      if (!props.cacheLocation.isFetched || currentTimestamp - props.cacheLocation.timestamp > 15 * 60 * 1000) {
        props.dispatch(geolocationThunk.updateCacheLocation());
      }
    }
  }, [isFocused, _locationPermissionGranted]);

  /**
   * Every time welcome screen is focused, if the location permission is not granted,
   * ask permission box will be shown
   */
  useEffect(() => {
    if (isFocused && !_locationPermissionGranted) {
      clearTimeout(_permissionTimeout);
      setPermissionTimeout(setTimeout(checkPermissions, 1000));
    }
  }, [isFocused, !_locationPermissionGranted]);

  useEffect(() => {
    if (!_showOffDayModal && props.isOffDay) {
      setShowOffDayModal(true);
    }
  }, [_showOffDayModal, props.isOffDay]);

  const checkPermissions = async () => {
    const { auth, profile } = props;

    try {
      const isGranted = await checkLocationPermission();
      if (isGranted) {
        setLocationPermissionGranted(true);
      } else {
        setLocationPermissionGranted(false);
        Alert.alert(t('home:welcome.accessLocation'), t('home:welcome.checkPermission'), [
          {
            text: t('home:welcome.openSettings'),
            onPress: () => {
              openSettings();
            }
          },
          {
            text: 'home:welcome.skip'
          }
        ]);
      }
    } catch (err) {
      if (err) {
        errorLogger(auth.uid, profile.organization, '', '', err, 'SiteContainer.tsx/checkPermissions');
      }
    }
  };

  useEffect(() => {
    AppState.addEventListener('change', handleAppStateChange);
    return () => AppState.removeEventListener('change', handleAppStateChange);
  }, []);

  const handleAppStateChange = (deviceState: AppStateStatus) => {
    setAppState(deviceState);
  };

  /**
   * useEffect for LOCAL NOTIFICATION
   * Should generate local notification when `appState` changes
   * `draftReports` length and `cameraActive` state is checked here
   */
  useEffect(() => {
    const { cameraActive, language, auth, mySitesScheduled, cacheReportIndex, organization, isFetchingMySites } = props;

    if (appState !== 'active' && isFetchingMySites) {
      dispatch(scheduleSitesActions.setIsFetchingMySites(false));
    }

    if (appState === 'active' && !isFetchingMySites) {
      // props.dispatch(scheduleSitesThunk.getMyScheduledSites(organization!.schedule));
      if (_locationPermissionGranted) {
        props.dispatch(geolocationThunk.updateCacheLocation());
      }
      // removeLocalNotification();
      return;
    }

    const draftReports: (ReportSummary & { siteKey: string; index: number })[] = [];

    // Filters site that has reports still in 'draft'
    mySitesScheduled.forEach(({ siteKey }: string, i: number) => {
      const reportKeys = cacheReportIndex[siteKey] ? Object.keys(cacheReportIndex[siteKey]) : [];
      reportKeys.forEach(reportKey => {
        if (
          cacheReportIndex[siteKey][reportKey] &&
          cacheReportIndex[siteKey][reportKey].status &&
          cacheReportIndex[siteKey][reportKey].status === 'draft' &&
          cacheReportIndex[siteKey][reportKey].auditor === auth.uid
        ) {
          draftReports.push({ ...cacheReportIndex[siteKey][reportKey], siteKey, index: i });
        }
      });
    });

    if (draftReports.length === 0) {
      return;
    }

    if (appState === 'active') {
      draftReports.sort((a, b) => (new Date(a.datetimeIn) < new Date(b.datetimeIn) ? -1 : 1));
      i18n.changeLanguage(language || 'en');
    }
  }, [appState]);

  /** Handle animate header **/
  const handleAnimateHeader = (offset: number) => {
    _scrollY.setValue(offset);
  };

  /** Handle on object scroll **/
  const handleScroll = (e: any) => {
    const offsetY = e.nativeEvent.contentOffset.y;
    // pass offset to animate WelcomeSummary component
    if (offsetY >= 0 && offsetY < SCREEN_HEIGHT * 0.25) {
      handleAnimateHeader(offsetY);
    }
  };

  /** Handle toggle progress view **/
  const handleToggleProgressView = () => {
    setDefaultProgressView(!_defaultProgressView);
  };

  const handleOpenNotificationModal = () => {
    setShowNotificationModal(true);
  };

  const handleDismissNotificationModal = () => {
    setShowNotificationModal(false);
  };

  const handleNotificationNavigation = (report: PreGeneratedReport) => {
    // set pregenerated report from broadcast message payload
    // this is necessary to allow data to be displayed correctly in Payoff screen
    props.dispatch(payoffActions.setPreGeneratedReport(report));
    navigation.navigate('Payoff', {
      profileColor: props.profileColor,
      originTab: 'HomeTab',
      siteName: 'Notification Site'
    });
  };

  return (
    <View style={styles.root}>
      <WelcomeOffDayModal language={props.language} visible={_showOffDayModal} />
      <WelcomeTracker
        defaultProgressView={_defaultProgressView}
        isFocused={isFocused}
        isReady={true}
        offset={_scrollY}
      />
      <ScrollView onScroll={handleScroll} scrollEventThrottle={1} nestedScrollEnabled={true}>
        <View style={styles.header}>
          <WelcomeSummary isReady={!props.isFetchingMySites} offset={_scrollY} />
        </View>
        <View>
          <WelcomeDaySchedule
            isFocused={isFocused}
            scrollable={false}
            sortedSites={[..._sortedSites, ..._sortedJourneys]}
            isReady={!props.isFetchingMySites}
          />
        </View>
      </ScrollView>
    </View>
  );
};

type FirebaseProps = ReturnType<typeof mapStateToFirebase>;
type StateProps = ReturnType<typeof mapStateToProps>;
type EnhancedProps = FirebaseProps & StateProps & ConnectedDispatch & FirebaseConnect;

type WelcomeProps = EnhancedProps;

const mapStateToFirebase = (state: RootState) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  fetchLimit: state.broadcast.fetchLimit
});

const mapFirebaseIntoReduxState = (props: FirebaseProps) => {
  if (!props.profile.isEmpty) {
    return [];
  }

  return [
    {
      path: `/organizationOffDay/${props.profile.organization}/${moment().format('YYYY-MM-DD')}`,
      storeAs: `welcomeOrganizationOffDay`
    },
    {
      path: `/userUnreadBroadcast/${props.profile.organization}/${props.auth.uid}`,
      storeAs: 'welcomeNotification/unread'
    },
    {
      path: `/organizationBroadcast/${props.profile.organization}`,
      queryParams: ['orderByChild=status', 'equalTo=complete', `limitToLast=${props.fetchLimit}`],
      storeAs: 'welcomeNotification/messageBroadcast'
    },
    {
      path: `/reportBroadcast/${props.profile.organization}/${props.auth.uid}`,
      storeAs: 'welcomeNotification/reportBroadcast'
    }
  ];
};
const mapStateToProps = (state: RootState) => {
  const organizationKey: string = state.firebase.profile.organization;
  return {
    hasNotch: state.account.hasNotch,
    apiLevel: state.account.apiLevel,
    cameraActive: state.report.cameraActive,
    notification: state.notification,
    language: state.firebase.profile.language || 'en',
    pendingSites: state.reportsubmit.pendingSites,
    cacheReportIndex: state.reportcache.summaries,
    multiReports: state.reportcache.multiReports,
    cacheReady: state.reportcache.isReady,
    organization: state.organization.myOrganization,
    allQuestionnaireIndex: state.questionnaires.data,
    isDummy: state.account.isDummy,
    isUploading: state.uploadreport.isUploading,

    mySitesScheduled: state.scheduleSites.myScheduledSites,
    isFetchingMySites: state.scheduleSites.isFetchingMySites,
    myJourneysScheduled: state.scheduleJourneys.myScheduledJourneys,
    ongoingJourneyReportKey: state.journey.ongoingJourneyReportKey,
    isFetchingMyJourneys: state.scheduleJourneys.isFetchingMyJourneys,
    scheduleTimestamp: state.scheduleSites.timestamp,
    cacheLocation: state.geolocation.cacheLocation,
    questionnaireIsLoading: state.questionnaires.isLoading,

    broadcastUnread: getVal(state.firebase.data, `welcomeNotification/unread`),
    users: state.user.users,
    messageBroadcastList: getVal(state.firebase.ordered, `welcomeNotification/messageBroadcast`, []),
    reportBroadcastList: getVal(state.firebase.ordered, `welcomeNotification/reportBroadcast`, []),

    isOffDay: state.firebase.data.welcomeOrganizationOffDay,
    profileColor: state.profiletheme.color,
    isTutorialMode: state.tutorial.isTutorialMode,
    featureAccessJourney: state.featureAccess.features[enums.Features.JOURNEY_PLAN]
  };
};

const enhance = compose(
  connect(mapStateToFirebase),
  firebaseConnect(mapFirebaseIntoReduxState),
  connect(mapStateToProps)
);

export default enhance(Welcome) as any;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    // width: SCREEN_WIDTH,
    // marginHorizontal: 'auto',
    height: 1000
  },
  loadingRoot: {
    flex: 1,
    maxHeight: 600,
    backgroundColor: 'transparent'
  },
  header: {
    flex: 1,
    height: SCREEN_WIDTH >= 360 ? SCREEN_HEIGHT * 0.3 : SCREEN_HEIGHT * 0.4,
    maxHeight: 400,
    width: '100%'
  }
});
