import React from 'react';
import { useSelector } from 'react-redux';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack/src/types';
// import CodePush from 'react-native-code-push';

import { RootState } from '../store/reducers';

import { StyleSheet, View } from 'react-native';
import Settings from '../components/settings/Settings';
import { SettingsStackParamList } from '../routes';

export type SettingsTabProps = {
  navigation: StackNavigationProp<SettingsStackParamList, 'Settings'>;
  route: RouteProp<SettingsStackParamList, 'Settings'>;
};

const SettingsTab = (props: SettingsTabProps) => {
  const profileColor = useSelector<RootState, string>(state => state.profiletheme.color);

  React.useEffect(() => {
    props.navigation.setParams({ profileColor });
  }, []);

  const handleLogout = () => {
    try {
      // CodePush.restartApp(true);
    } catch {
      //handle err
    }
  };

  return (
    <View style={styles.root}>
      <Settings navigation={props.navigation} route={props.route} onLogout={handleLogout} />
    </View>
  );
};

export default SettingsTab;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#efeeed'
  },
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    fontSize: 20
  }
});
