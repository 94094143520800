import React, { useMemo } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { ScheduleOption } from '../../../typing/types';
import useScheduleCheckInOutPermission from '../utils/useScheduleCheckInOutPermission';
// utils
import fontMaker from '../../../utils/font';
import { ScheduleStatusEnum } from '../../../utils/classes';
// components
import ScheduleStatus from './ScheduleStatus';
import RenderTimeSchedule from './RenderTimeSchedule';

export type ScheduleQuestionnaireCardProps = {
  overdueCalendar?: boolean;
  isAfterCurrentDate?: boolean;
  schedule: Partial<ScheduleOption>;
  handleSelectSchedule: (schedule: Partial<ScheduleOption>) => void;
  selectedCalendarDate?: string | null;
};

const ScheduleQuestionnaireCard = (props: ScheduleQuestionnaireCardProps) => {
  const { schedule, handleSelectSchedule, overdueCalendar, isAfterCurrentDate, selectedCalendarDate } = props;

  const scheduleStatus = schedule.status;
  const { isOverdue } = useScheduleCheckInOutPermission({
    isAdhoc: Boolean(schedule.isAdhocOnly),
    selectedCalendarDate: String(selectedCalendarDate),
    selectedSchedule: schedule
  });

  const statusExtended = useMemo(() => {
    if (
      isOverdue &&
      schedule.status !== ScheduleStatusEnum.FINISHED_PERIOD &&
      schedule.status !== ScheduleStatusEnum.FINISHED_TODAY
    ) {
      return 'overdue';
    }

    return scheduleStatus;
  }, [scheduleStatus, isOverdue]);

  const title = schedule.isMultiSite ? `Team Audit (${schedule.startDate})` : schedule.title;

  return (
    <TouchableOpacity style={styles.container} onPress={() => handleSelectSchedule(schedule)}>
      <Text numberOfLines={3} style={styles.title}>
        {title}
      </Text>
      <View style={styles.timeContainer}>
        {!schedule.isMultiSite ? (
          <RenderTimeSchedule
            schedule={schedule}
            fontSize={12}
            isDateBefore={overdueCalendar}
            isDateAfter={isAfterCurrentDate}
            selectedCalendarDate={selectedCalendarDate}
          />
        ) : null}
      </View>
      <View style={styles.statusContainer}>
        <ScheduleStatus status={statusExtended!} isOverdue={statusExtended === 'overdue'} />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
    paddingHorizontal: 15,
    paddingVertical: 20,
    marginBottom: 7,
    backgroundColor: '#fff',
    color: '#25282B'
  },
  title: {
    fontSize: 14,
    lineHeight: 18,

    marginBottom: 8,
    ...fontMaker({ weight: 'SemiBold' })
  },
  statusContainer: { position: 'absolute', bottom: 17, right: 0 },
  timeContainer: {}
});

export default ScheduleQuestionnaireCard;
