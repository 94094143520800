import Bluebird from 'bluebird';
import moment from 'moment';
import { getFirebase } from 'react-redux-firebase';
import * as nimblyAPI from '../constants/api';
import { padZeroes } from './file';
import { getServerTime } from './getServerTime';

type SectionMeta = {
  section: number;
  uid: string;
};

const uploadReportDocuments = (
  documentURI: string,
  organizationKey: string,
  siteKey: string,
  reportKey: string,
  sectionData: SectionMeta | false,
  cancelHandler: () => any,
  isTutorial?: boolean,
  onSuccess?: (ref: string) => any,
  journey?: any,
  questionIndex?,
  fileIndex?,
  typeFile?
): Promise<string> => {
  return new Bluebird(async (resolve: (T: any) => void, reject: (E: Error) => any, onCancel: (A?: any) => any) => {
    if (typeof documentURI === 'string') {
      return resolve(documentURI);
    } else {
      const formData = new FormData();
      const firebase = getFirebase();
      const timestamp = moment(getServerTime()).format('YYYYMMDD-HHmmss');

      let fileExt = (documentURI as File).name.split('.').pop();
      const blob = (documentURI as File).slice(0, (documentURI as File).size, (documentURI as File).type);

      const questionNumberSerial = padZeroes(questionIndex + 1, 3);

      const newNameFile = new File(
        [blob],
        `Q${questionNumberSerial}${fileIndex !== undefined ? `-${fileIndex + 1}-` : ''}${timestamp}.${fileExt}`,
        {
          type: (documentURI as File).type
        }
      );

      formData.append('file', newNameFile);
      const type = (documentURI as File).type.split('/')[1];
      const token = (await firebase.auth().currentUser?.getIdToken()) as string;

      let fileRef = `report/${organizationKey}/${siteKey}/${typeFile}/${reportKey}`;

      if (sectionData) {
        fileRef = `report/${organizationKey}/${siteKey}/${typeFile}/${reportKey}/${sectionData.section}/${sectionData.uid}`;
      }

      if (journey) {
        if (sectionData) {
          fileRef = `reportJourney/${organizationKey}/${journey.code}/${siteKey}/${typeFile}/${reportKey}/${sectionData.section}/${sectionData.uid}`;
        } else {
          fileRef = `reportJourney/${organizationKey}/${journey.code}/${siteKey}/${typeFile}/${reportKey}`;
        }
      }

      return fetch(`${nimblyAPI.NIMBLY_API_UPLOAD}path=${fileRef}&fileType=${type}`, {
        method: 'POST',
        headers: {
          Authorization: token
        },
        body: formData
      })
        .then(async res => {
          if (res?.status === 200) {
            const json: any = await res.json();
            return resolve(json.data);
          } else {
            return reject(new Error('Fail to upload'));
          }
        })
        .catch((error: any) => {
          return reject(error);
        });
    }
  });
};

export default uploadReportDocuments;
