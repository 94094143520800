import { action } from 'typesafe-actions';

import * as actionTypes from './journeySchedule.actionTypes';
import { UserJourneyScheduleInfo } from './journeySchedule.reducer';

// types
import { Site } from '../../../utils/classes';

export const setIsLoading = (siteKey: string, bool: boolean) => action(actionTypes.SET_IS_LOADING, { siteKey, bool });

export const setIsReady = (siteKey: string, bool: boolean) => action(actionTypes.SET_IS_READY, { siteKey, bool });
export const setJourneyScheduleData = (siteKey: string, scheduleInfo: UserJourneyScheduleInfo[]) =>
  action(actionTypes.SET_JOURNEY_SCHEDULE_DATA, { siteKey, scheduleInfo });

/**
 * Remove schedule info while retaining the existence of site state
 */
// eslint-disable-next-line max-len
export const removeSiteScheduleData = (siteKey: string) =>
  action(actionTypes.REMOVE_JOURNEY_SCHEDULE_DATA, { siteKey });

/**
 * Wipe clean site schedule object
 */
// eslint-disable-next-line max-len
export const clearSiteScheduleData = (siteKeys: string[]) =>
  action(actionTypes.CLEAR_JOURNEY_SCHEDULE_DATA, { siteKeys });

export const fetchJourneyScheduleRequest = (data: {
  siteKey: string;
  site: Site;
  startDate: string;
  endDate: string;
  organizationID: string;
}) =>
  action(actionTypes.FETCH_JOURNEY_SCHEDULE_REQUEST, {
    siteKey: data.siteKey,
    site: data.site,
    startDate: data.startDate,
    endDate: data.endDate,
    organizationID: data.organizationID
  });

export const cancelFetchJourney = () => action(actionTypes.CANCEL_FETCH);
