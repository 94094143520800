import { LatestUploadedSection, UploadedSiteReportSections } from '../LobbyQuestionnaireSection';

const getLatestUploadedSections = (reportSections: UploadedSiteReportSections): LatestUploadedSection => {
  if (!reportSections) {
    return {};
  }

  const latestUploaded: LatestUploadedSection = {};

  for (const sectionIndex in reportSections) {
    if (!reportSections.hasOwnProperty(sectionIndex)) {
      continue;
    }
    const section = reportSections[sectionIndex];
    if (!section) {
      continue;
    }
    const latest = Object.keys(section).sort((a, b) => {
      const userASection = section[a];
      const userBSection = section[b];
      const userASectionTimeUpdated = new Date(userASection.datetimeUpdated).getTime();
      const userBSectionTimeUpdated = new Date(userBSection.datetimeUpdated).getTime();

      if (userASection.status === 'approved' && userBSection.status !== 'approved') return -1;
      if (userASection.status !== 'approved' && userBSection.status === 'approved') return 1;
      if (userASection.status === 'complete' && userBSection.status !== 'complete') return -1;
      if (userASection.status !== 'complete' && userBSection.status === 'complete') return 1;
      return userBSectionTimeUpdated - userASectionTimeUpdated;
    })[0];

    const userSection = section[latest];

    latestUploaded[Number(sectionIndex)] = userSection;
  }
  return latestUploaded;
};

export default getLatestUploadedSections;
