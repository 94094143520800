/* eslint-disable complexity */
import { useIsFocused } from '@react-navigation/native';
import { Question } from 'nimbly-common';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { InjectedSite } from '../../typing/types';
import { AllUsers, ReportSection } from '../../utils/classes';
import { UploadingSections } from './Lobby';
import LobbyQuestionnaire from './LobbyQuestionnaire';
import LobbyQuestionnaireCategory from './LobbyQuestionnaireCategory';
import getCompletedPercentage from './utils/getCompletedPercentage';
import getLatestUploadedSections from './utils/getLatestUploadedSections';

export interface UploadedSiteReportSections {
  [section: number]: { [uid: string]: ReportSection } | null;
}

export interface LatestUploadedSection {
  [sectionIndex: number]: ReportSection | null;
}

const LobbyQuestionnaireSection = (props: LobbyQuestionnaireSectionProps) => {
  const { isBusy, team, isUploading, uploadingSections } = props;
  const isFocused = useIsFocused();
  const { t } = useTranslation('lobby');

  const selectedSite = useSelector(state => state.site.selectedSite as InjectedSite);
  const selectedReportKey = useSelector(state => state.report.selectedReportKey!);
  const siteReportSections = useSelector(state => state.reportcache.multiReports[state.site.selectedSiteKey!]!);
  const language = useSelector(state => state.firebase.profile.language) || 'en';
  const uploadedReportSections = useSelector(
    state =>
      state.firebase.data?.['lobbyReportSection']?.[state.site.selectedSiteKey!]?.[state.report.selectedReportKey!]
  );
  const auth = useSelector(state => state.firebase.auth);
  const questionnaireIndexState = useSelector(state => state.questionnaireIndex);
  const latestUploadedSection = useMemo(
    () => getLatestUploadedSections(uploadedReportSections),
    [uploadedReportSections]
  );

  let overallSection = null;
  const { requiredSection, optionalSection } = useMemo(() => {
    const requiredSection: React.ReactNode[] = [];
    const optionalSection: React.ReactNode[] = [];

    const sections = selectedSite.children;
    sections.forEach((section, sectionIdx) => {
      // Uploaded draft report section
      const latestUploaded = latestUploadedSection[sectionIdx];
      // Local draft report section
      const reportSections = siteReportSections?.[selectedReportKey] || null;
      const localReportSection = reportSections?.[sectionIdx] || null;
      const questionnaire =
        questionnaireIndexState.questionnaireIndex?.[section.assignedQuestionnaire]?.populated?.latest;

      let selectedReportSection: ReportSection | null = null;

      if (latestUploaded) {
        // If only uploaded exists
        selectedReportSection = latestUploaded;
      } else if (localReportSection) {
        selectedReportSection = localReportSection;
      }

      const selectedUserId = selectedReportSection?.doneBy || '';
      const hasCheckedOut = !!selectedReportSection?.datetimeOut;
      const selectedQuestions: Question[] = selectedReportSection
        ? (selectedReportSection.questions as Question[]) || []
        : questionnaire
        ? questionnaire.questions
        : [];
      const sectionStatus = selectedReportSection ? selectedReportSection.status : null;

      // is invalid section if cannot find on progress section and cannot find the questionnaire for this section
      const invalidSection = !selectedReportSection && !questionnaire;
      const disabled = invalidSection || (hasCheckedOut && ['complete', 'approved'].includes(sectionStatus)) || isBusy;

      const selectedSection = section.isRequired ? requiredSection : optionalSection;
      const uploadSection =
        isUploading && uploadingSections && uploadingSections[sectionIdx] ? uploadingSections[sectionIdx] : null;

      selectedSection.push(
        <LobbyQuestionnaire
          key={sectionIdx}
          imageSrc={selectedUserId && team[selectedUserId]?.photoURL ? { uri: team[selectedUserId].photoURL } : null}
          sectionName={section.name}
          username={selectedUserId && team[selectedUserId] ? team[selectedUserId].displayName : ''}
          ownSection={auth.uid === selectedUserId}
          completion={getCompletedPercentage(selectedQuestions)}
          isUploading={isUploading}
          isFinishedUploading={uploadSection ? uploadSection.isFinished : null}
          uploadProgress={
            uploadSection && !isNaN(uploadSection.uploadedCount / uploadSection.total)
              ? uploadSection.uploadedCount / uploadSection.total
              : 0
          }
          onPress={() => props.onNavQuestionnaire(sectionIdx, section.name, section.assignedQuestionnaire)}
          onDelete={() => props.onDeleteSection('delete', sectionIdx)}
          language={language}
          disabled={disabled}
          status={sectionStatus}
          hasCheckedOut={hasCheckedOut}
          isFocused={isFocused}
        />
      );
    });

    return { requiredSection, optionalSection };
  }, [
    questionnaireIndexState,
    selectedSite,
    isUploading,
    latestUploadedSection,
    siteReportSections,
    uploadingSections,
    team,
    auth,
    isFocused,
    language,
    props.onNavQuestionnaire,
    props.onDeleteSection
  ]);

  overallSection = useMemo(() => {
    const all: React.ReactNode[] = [];
    if (requiredSection.length !== 0) {
      all.push(
        <LobbyQuestionnaireCategory key={`required-${requiredSection.length}`} title={t('lobby:requiredSection')}>
          {requiredSection}
        </LobbyQuestionnaireCategory>
      );
    }
    if (optionalSection.length !== 0) {
      all.push(
        <LobbyQuestionnaireCategory key={`optional-${optionalSection.length}`} title={t('lobby:optionalSection')}>
          {optionalSection}
        </LobbyQuestionnaireCategory>
      );
    }

    return all;
  }, [requiredSection, optionalSection, t]);

  return <>{overallSection.map(el => el)}</>;
};

interface OwnProps {
  isBusy: boolean;
  isUploading: boolean;
  team: AllUsers;
  uploadingSections: UploadingSections;
  onNavQuestionnaire: (sectionIdx: number, sectionName: string, questionnaireIndexKey: string) => any;
  onDeleteSection: (action: 'delete', sectionIdx: number) => any;
}

type LobbyQuestionnaireSectionProps = OwnProps;

export default LobbyQuestionnaireSection;
