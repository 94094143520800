import React from 'react';
import { Image, Platform, StyleSheet, TouchableNativeFeedback, TouchableOpacity, View } from 'react-native';

import { SCREEN_WIDTH, widthPercentageToDP as wp } from '../../utils/screensize';
import Text from '../global/Text';
import FilePicker from './FilePicker';
import VideoPicker from './VideoPicker';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { LanguageType } from '../../constants/Languages';
import { withTranslation, WithTranslation } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const FileIcon = require('../../assets/icons/take-file.png');
// eslint-disable-next-line @typescript-eslint/no-var-requires

type AddFileProps = {
  source: any;
  language: LanguageType;
  isValid?: boolean;
  onUploadFile: (props: any) => void;
} & WithTranslation;

// Simple placeholder to add and preview photo
// Also functions as a button to activate the camera
export class AddFile extends React.Component<AddFileProps> {
  state = {
    width: 0,
    height: 0
  };

  handlePressNew = props => {
    this.props.onUploadFile(props);
  };

  handlePressEdit = (source: string) => {
    if (source) {
      this.props.onEditFile(source);
    }
  };

  render() {
    const { source, isValid, t } = this.props;

    return !source ? (
      <FilePicker onComplete={this.handlePressNew} testID="new-file">
        <View style={[styles.portraitBox, !isValid ? styles.invalid : null]}>
          <Image source={FileIcon.default} style={styles.icon} />
          <Text allowFontScaling={false} style={styles.subtitle}>
            {t('questionnaire:addFile')}
          </Text>
        </View>
      </FilePicker>
    ) : (
      <Touchable testID={'edit-video'}>
        <View style={styles.portraitBoxVideo}>
          {source && source !== 'failed' ? (
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
              <View>
                <Text allowFontScaling={false} style={styles.subtitle}>
                  {source.split('/').pop()}
                </Text>
              </View>
              <View style={{ marginLeft: 10 }}>
                <TouchableOpacity onPress={() => this.handlePressEdit(source)}>
                  <Icon name="close" size={19} />
                </TouchableOpacity>
              </View>
            </View>
          ) : null}
        </View>
      </Touchable>
    );
  }
}

const Touchable: any = TouchableOpacity;

const styles = StyleSheet.create({
  portraitBox: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 80,
    height: (62 / 3) * 4 + 4,
    marginVertical: 10,
    marginHorizontal: 2,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: '#efeeed',
    backgroundColor: '#efeeed'
  },
  portraitBoxVideo: {
    height: (62 / 3) * 1 + 4,
    marginVertical: 3,
    marginHorizontal: 2,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: '#FFFF',
    backgroundColor: '#FFFF'
  },
  landscapeBox: {
    justifyContent: 'center',
    alignItems: 'center',
    width: (150 / 3) * 4 + 4,
    height: 80,
    marginVertical: 10,
    marginHorizontal: 2,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: '#efeeed',
    backgroundColor: '#efeeed'
  },
  icon: {
    marginBottom: SCREEN_WIDTH > 500 ? wp(0.1) : wp(2),
    height: SCREEN_WIDTH > 500 ? wp(1.8) : wp(8),
    width: SCREEN_WIDTH > 500 ? wp(1.8) : wp(8)
  },
  subtitle: {
    textAlign: 'center',
    fontSize: SCREEN_WIDTH > 500 ? wp(0.8) : wp(3)
  },
  videoOverlay: {
    position: 'absolute',
    borderRadius: 2,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    // backgroundColor: '#000',
    opacity: 0.5
  },
  playButtonContainer: {
    position: 'absolute',
    borderRadius: 2,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: wp(6),
    zIndex: 10
  },
  playButtonIcon: {
    width: '100%',
    height: '100%',
    overflow: 'visible'
  },
  invalid: {
    borderColor: '#ff6f00'
  },
  photo: {
    flex: 1,
    width: '100%',
    borderRadius: 2
  },
  innerBox: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  errorText: {
    marginTop: 5,
    fontSize: 12,
    lineHeight: 12.5,
    textAlign: 'center',
    color: '#FA6161'
  },
  progressText: {
    marginTop: 5,
    fontSize: 12,
    lineHeight: 12.5,
    textAlign: 'center',
    color: '#3cd070'
  }
});

export default withTranslation(['questionnaire'])(AddFile);
