export const SET_CACHE_REPORT = '@reportcache/SET_CACHE_REPORT';
export const SET_CACHE_REPORT_SUMMARY = '@reportcache/SET_CACHE_REPORT_SUMMARY';
export const SET_CACHE_REPORT_AND_SUMMARY = '@reportcache/SET_CACHE_REPORT_AND_SUMMARY';
export const REMOVE_CACHE_REPORT_AND_SUMMARY = '@reportcache/REMOVE_CACHE_REPORT_AND_SUMMARY';
export const CLEAR_CACHE = '@reportcache/CLEAR_CACHE';
export const SET_REPORT_SECTION_STATE = '@reportcache/SET_REPORT_SECTION_STATE';
export const SET_CACHE_READY = '@reportcache/SET_CACHE_READY';
export const REMOVE_SITE_REPORT_SECTION_STATE = '@reportcache/REMOVE_SITE_REPORT_SECTION';
export const REMOVE_REPORT_SECTION_STATE = '@reportcache/REMOVE_REPORT_SECTION';
export const SET_BACKUP_REPORT = '@reportcache/SET_BACKUP_REPORT';
export const REMOVE_BACKUP_REPORTS = '@reportcache/REMOVE_BACKUP_REPORTS';
export const CLEAR_BACKUP_CACHE = '@reportcache/CLEAR_BACKUP_CACHE';
export const INIT_REPORT_CACHE = '@reportcache/INIT_REPORT_CACHE';
