import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from './questionnaire.action';
import { QuestionnairesState } from './type';

export type QuestionnaireAction = ActionType<typeof actions>;

const initialState: QuestionnairesState = {
  ordered: [],
  data: {},
  index: {},
  isLoading: false,
  questionnaires: null,
  error: null
};

export const questionnaireReducer = createReducer<QuestionnairesState, QuestionnaireAction>(initialState)
  .handleAction(actions.getPopulatedQuestionnairesAsync.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(actions.getPopulatedQuestionnairesAsync.success, (state, action) => ({
    ...state,
    data: action.payload.data,
    ordered: action.payload.ordered,
    index: action.payload.index,
    isLoading: false
  }))
  .handleAction(actions.getPopulatedQuestionnairesAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload.error
  }))
  .handleAction(actions.fetchQuestionnairesAsync.request, state => ({
    ...state,
    error: null,
    isLoading: true
  }))
  .handleAction(actions.fetchQuestionnairesAsync.success, (state, action) => ({
    ...state,
    questionnaires: action.payload.data,
    isLoading: false
  }))
  .handleAction(actions.fetchQuestionnairesAsync.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }));
