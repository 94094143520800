import { ActionType, createReducer } from 'typesafe-actions';

import * as actions from './nps.action';
import { NPSState } from './type';

export type NPSAction = ActionType<typeof actions>;

const initialState: NPSState = {
  isEligible: false,
  reason: '',
  error: ''
};

export const npsReducer = createReducer<NPSState, NPSAction>(initialState)
  .handleAction(actions.fetchNPSUserAsync.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(actions.fetchNPSUserAsync.success, (state, action) => ({
    ...state,
    isEligible: action.payload.isEligible,
    isLoading: false
  }))
  .handleAction(actions.fetchNPSUserAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload.error
  }))
  .handleAction(actions.createNPSIsSubmittedAsync.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(actions.createNPSIsSubmittedAsync.success, (state, action) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(actions.createNPSIsSubmittedAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload.error
  }));
