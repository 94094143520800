import { RootState } from '../../reducers';
import * as nimblyApi from '../../../constants/api';
import * as actions from './siteReport.action';
import { queryStringify } from '../../../utils/string';
import { select, put, call } from 'redux-saga/effects';
import { getFirebase } from 'react-redux-firebase';
import { SiteReportSummary, Questionnaire } from 'nimbly-common';
import { SiteReportState } from '../../reducers/siteReport/type';
import moment from 'moment';

const searchParamsToQuery = (filter: any, limit: number, page: number): any => {
  const postData: any = {
    limit: limit,
    page: page
  };
  postData.sortBy = 'reportID';
  if (filter.sortDirection) {
    postData.sortType = filter.sortDirection;
  }

  if (filter.startDate) {
    postData.startDate = `${moment(filter.startDate).subtract(1, 'days').format('YYYY-MM-DD')} 23:59:00.000Z` || '';
  }
  if (filter.endDate) {
    postData.endDate = `${filter.endDate.format('YYYY-MM-DD')} 23:59:00.000Z` || '';
  }

  if (filter.query && filter.query !== '') {
    postData.search = filter.query;
  }
  return postData;
};

export function* fetchSiteReport(action: ReturnType<typeof actions.fetchSiteReportsAsync.request>) {
  try {
    const stateSelector = (state: RootState) => state;
    const stateCurrent: RootState = yield select(stateSelector);
    const siteReportState: SiteReportState = stateCurrent.siteReport;
    const reduxFirebase = getFirebase();
    const currentPage = action.payload.clearList ? 1 : siteReportState.pageIndex;
    const postData = searchParamsToQuery(
      {
        sortDirection: siteReportState.sortDirection,
        startDate: siteReportState.dateType === 'custom' ? siteReportState.customStart : siteReportState.startDate,
        endDate: siteReportState.dateType === 'custom' ? siteReportState.customEnd : siteReportState.endDate,
        query: siteReportState.filterQuery
      },
      50,
      currentPage
    );
    if (reduxFirebase.auth) {
      const idToken = (yield reduxFirebase.auth().currentUser!.getIdToken()) as string;
      const request = () =>
        fetch(
          `${nimblyApi.NIMBLY_API_SITE_REPORTS}${action.payload.siteID}?${queryStringify(
            JSON.parse(JSON.stringify(postData))
          )}`,
          {
            method: 'GET',
            headers: {
              authorization: idToken
            }
          }
        );
      const responseRaw: any = (yield call(request)) as Body;
      const responseBody: any = (yield responseRaw.json()) as any;
      yield put(actions.setPageIndexReport(currentPage + 1));
      if (responseRaw.status >= 400) {
        const message = responseBody.message;
        yield put(actions.fetchSiteReportsAsync.failure({ error: message }));
      }
      const responseData: { data: (SiteReportSummary & { questionnaire: Questionnaire })[]; total: number } =
        responseBody.data;
      let combinedReports = responseData?.data || [];
      const currentReports = stateCurrent.siteReport.reports;
      if (action.payload.clearList === false && combinedReports) {
        combinedReports = currentReports.concat(combinedReports);
      }
      let totalData: number = 1;
      if (responseData?.total >= 50) {
        totalData = responseData?.total / 50;
      }
      yield put(actions.fetchSiteReportsAsync.success({ reports: combinedReports, total: Math.ceil(totalData) }));
    }
  } catch (error) {
    yield put(actions.fetchSiteReportsAsync.failure({ error: error.message }));
  }
}
