/**
 * React Mandatory
 */
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Platform,
  StyleSheet,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableWithoutFeedbackProps,
  View
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import fontMaker from '../../../utils/font';
import { SCREEN_WIDTH } from '../../../utils/screensize';
import Text from '../../global/Text';

export const NotifSuccessRequest = ({ isVisible, onCloseModalRequest }) => {
  const { t } = useTranslation(['auth']);
  const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
    Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

  if (!isVisible) {
    return <div />;
  }

  return (
    <View style={styles.overlay}>
      <View style={styles.container}>
        <View style={styles.header}>
          <Text style={styles.headerTitle}>
            <Icon name="check-circle" color="#56C768" size={17} /> {t(`auth:toastRequestSuccess:title`)}
          </Text>
          <Touchable onPress={onCloseModalRequest} delayPressIn={0}>
            <Icon name="close" color="#25282B" size={16} />
          </Touchable>
        </View>
        <View style={styles.actionContainer}>
          <View style={Platform.OS === 'android' ? styles.row : {}}>
            <Text style={[styles.option]}>{t(`auth:toastRequestSuccess:body`)}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export interface ConnectionStatusProps {
  isVisible: boolean;
  onCloseModalRequest: () => void;
}

export default NotifSuccessRequest;

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 9999,
    marginTop: 20,
    alignItems: 'center'
  },
  container: {
    alignItems: 'flex-start',
    backgroundColor: '#E6FAED',
    width: SCREEN_WIDTH > 500 ? 270 : 350,
    borderRadius: 5,
    overflow: 'hidden',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5
    },
    shadowOpacity: 0.34,
    shadowRadius: 6.27,
    elevation: 10,
    paddingHorizontal: 15,
    paddingVertical: 15
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingBottom: 12
  },
  headerTitle: { ...fontMaker({ weight: 'SemiBold' }) },
  headerSubtitle: {
    ...fontMaker({ weight: 'Light' }),
    marginVertical: 12
  },
  actionContainer: {
    width: '100%'
  },
  row: {
    width: '100%',
    paddingBottom: 15,
    paddingHorizontal: 10
  },
  option: {
    textAlign: 'left'
  }
});
