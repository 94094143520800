const LanguagesSupport = {
  English: 'en',
  Indonesia: 'id',
  Spanish: 'es',
  Portuguese: 'pt',
  Khmer: 'km',
  Thai: 'th'
};

const LanguagesOptions = [
  { id: 'en', title: 'English' },
  { id: 'id', title: 'Indonesia' },
  { id: 'es', title: 'Spanish' },
  { id: 'km', title: 'Khmer' },
  { id: 'pt', title: 'Portuguese' },
  { id: 'th', title: 'Thai' }
];

const LanguagesName = {
  English: 'English',
  Indonesia: 'Bahasa Indonesia',
  Spanish: 'Spanish',
  Portuguese: 'Portuguese',
  Khmer: 'Khmer',
  Thai: 'Thai'
};

const Languages = ['English', 'Bahasa Indonesia', 'Spanish', 'Khmer', 'Portuguese', 'Thai'];

const ActiveLanguage = (lang: string) => {
  switch (lang) {
    case LanguagesName.English:
      return LanguagesSupport.English;
    case LanguagesName.Indonesia:
      return LanguagesSupport.Indonesia;
    case LanguagesName.Spanish:
      return LanguagesSupport.Spanish;
    case LanguagesName.Portuguese:
      return LanguagesSupport.Portuguese;
    case LanguagesName.Khmer:
      return LanguagesSupport.Khmer;
    case LanguagesName.Thai:
      return LanguagesSupport.Thai;
    default:
      return LanguagesSupport.English;
  }
};

const ActiveLanguageName = (lang: string) => {
  switch (lang) {
    case LanguagesSupport.English:
      return LanguagesName.English;
    case LanguagesSupport.Indonesia:
      return LanguagesName.Indonesia;
    case LanguagesSupport.Spanish:
      return LanguagesName.Spanish;
    case LanguagesSupport.Portuguese:
      return LanguagesName.Portuguese;
    case LanguagesSupport.Khmer:
      return LanguagesName.Khmer;
    case LanguagesSupport.Thai:
      return LanguagesName.Thai;
    default:
      return LanguagesName.English;
  }
};

export type LanguageType = 'en' | 'id' | 'es' | 'pt' | 'km' | 'th';

export type LanguageJsonType = {
  en: string;
  id: string;
  es: string;
  pt: string;
  km: string;
  th: string;
};

export { Languages, ActiveLanguage, ActiveLanguageName, LanguagesSupport, LanguagesOptions };
