import { Question } from 'nimbly-common';
import { getTotalCompleteQuestions } from '../../../utils/report';

const getCompletedPercentage = (questions: Question[]) => {
  const isValidQuestions = questions && Array.isArray(questions);
  const questionLength = isValidQuestions ? questions.length : 0;
  const total = getTotalCompleteQuestions(isValidQuestions ? questions : []);

  const count = Math.floor((total / (questionLength === 0 ? 1 : questionLength)) * 100);
  return count;
};

export default getCompletedPercentage;
