import { InjectedSiteStatus } from '../../../typing/types';
import { ScheduledSiteInfo } from '../../../store/reducers/schedulesites';

/**
 * Generate site status string based on schedulded info
 * @param scheduledSiteInfo
 */
function getSiteStatus(scheduledSiteInfo: ScheduledSiteInfo): InjectedSiteStatus {
  switch (true) {
    case scheduledSiteInfo.isPendingUpload:
      return 'pending-upload';
    case scheduledSiteInfo.isDraft:
      return 'draft';
    case scheduledSiteInfo.isMakeUp:
      return 'makeup';
    case scheduledSiteInfo.isCompletedThisPeriod:
      return 'finished-period';
    case scheduledSiteInfo.isCompletedToday:
      return 'finished-today';
    case !scheduledSiteInfo.isScheduledThisPeriod:
      return 'none-due';
    default:
      return 'ready';
  }
}

export default getSiteStatus;
