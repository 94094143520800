import { createAsyncAction } from 'typesafe-actions';

import * as types from './actionType';
import { statistic } from 'nimbly-common';

export const fetchWeeklyCompletion = createAsyncAction(
  types.FETCH_WEEKLY_COMPLETION_REQUEST,
  types.FETCH_WEEKLY_COMPLETION_SUCCESS,
  types.FETCH_WEEKLY_COMPLETION_FAILURE
)<
  undefined,
  { daily: statistic.UserOneCompletionDaily[]; overview: statistic.UserCompletionOverview },
  { error: string }
>();

export const fetchWeeklyFlagOverview = createAsyncAction(
  types.FETCH_WEEKLY_FLAG_OVERVIEW_REQUEST,
  types.FETCH_WEEKLY_FLAG_OVERVIEW_SUCCESS,
  types.FETCH_WEEKLY_FLAG_OVERVIEW_FAILURE
)<
  undefined,
  {
    scheduled: statistic.QuestionFlagOverview;
    adhoc: statistic.QuestionFlagOverview;
  },
  {
    error: string;
  }
>();

export const fetchMonthlyCompletion = createAsyncAction(
  types.FETCH_MONTHLY_COMPLETION_REQUEST,
  types.FETCH_MONTHLY_COMPLETION_SUCCESS,
  types.FETCH_MONTHLY_COMPLETION_FAILURE
)<
  undefined,
  { daily: statistic.UserOneCompletionDaily[]; overview: statistic.UserCompletionOverview },
  { error: string }
>();

export const fetchMonthlyFlagOverview = createAsyncAction(
  types.FETCH_MONTHLY_FLAG_OVERVIEW_REQUEST,
  types.FETCH_MONTHLY_FLAG_OVERVIEW_SUCCESS,
  types.FETCH_MONTHLY_FLAG_OVERVIEW_FAILURE
)<
  undefined,
  {
    scheduled: statistic.QuestionFlagOverview;
    adhoc: statistic.QuestionFlagOverview;
  },
  {
    error: string;
  }
>();
