import moment from 'moment';
import { ScheduleOption } from '../../../typing/types';

export interface GetScheduleDateTimeArgs {
  schedule: Partial<
    Pick<
      ScheduleOption,
      | 'type'
      | 'startDate'
      | 'endDate'
      | 'startTime'
      | 'endTime'
      | 'datesCustomInfo'
      | 'scheduledDates'
      | 'enforceCheckIn'
      | 'enforceCheckOut'
      | 'isYesterdaySchedule'
      | 'isYesterdayActiveSchedule'
      | 'siteDateTime'
    >
  >;
  siteTimeZone: string;
  siteDateTime: moment.Moment;
  selectedCalendarDate: string | null | undefined;
  dateTimeFormat?: string;
  serverDateTime: string;
}

function getScheduleDateTime({
  schedule,
  selectedCalendarDate,
  siteTimeZone,
  dateTimeFormat = 'D MMM YYYY, HH:mm [GMT]Z',
  serverDateTime
}: GetScheduleDateTimeArgs) {
  if (schedule.type === 'never') {
    const startDateTime = moment.tz(schedule.startDate, siteTimeZone).add(schedule.startTime, 'minutes');
    const endDateTime = moment
      .tz(schedule.endDate || schedule.startDate, siteTimeZone)
      .add(schedule.endTime, 'minutes');

    const startTime = startDateTime.format(dateTimeFormat);
    const endTime = endDateTime.format(dateTimeFormat);

    return {
      startTime,
      endTime,
      startDateTime,
      endDateTime
    };
  }

  if (schedule.type === 'custom') {
    const scheduleDateTime = schedule.datesCustomInfo?.find(item => {
      const startDateTime = moment.tz(item.startDate, siteTimeZone);
      const endDateTime = moment.tz(item.endDate, siteTimeZone);
      const selectedCalendarDateMoment = moment.tz(selectedCalendarDate, siteTimeZone);

      return (
        selectedCalendarDateMoment.isSameOrAfter(startDateTime, 'day') &&
        selectedCalendarDateMoment.isSameOrBefore(endDateTime, 'day')
      );
    });

    const startDateTime = moment.tz(scheduleDateTime?.startDate, siteTimeZone);
    const endDateTime = moment.tz(scheduleDateTime?.endDate, siteTimeZone);

    const startTime = startDateTime.format(dateTimeFormat);
    const endTime = endDateTime.format(dateTimeFormat);

    return {
      startTime,
      endTime,
      startDateTime,
      endDateTime
    };
  }

  const isMultiDaySchedule = schedule?.endTime && schedule?.endTime >= 1399;
  const selectedCalendarDateAsKey = moment.tz(selectedCalendarDate, siteTimeZone).format('YYYY-MM-DD');
  const isScheduledToday = schedule.scheduledDates?.[selectedCalendarDateAsKey];
  if (!isScheduledToday && !isMultiDaySchedule) {
    return {
      startTime: '',
      endTime: '',
      startDateTime: null,
      endDateTime: null
    };
  }

  if (isMultiDaySchedule) {
    const startDateTime = moment.tz(schedule.startDate, siteTimeZone).add(schedule.startTime, 'minutes');
    const endDateTime = moment.tz(schedule.startDate, siteTimeZone).add(schedule.endTime, 'minutes');

    const startTime = startDateTime.format(dateTimeFormat);
    const endTime = endDateTime.format(dateTimeFormat);

    return {
      startTime,
      endTime,
      startDateTime,
      endDateTime
    };
  }

  const startDateTime = moment.tz(selectedCalendarDate, siteTimeZone).add(schedule.startTime, 'minutes');
  const endDateTime = moment.tz(selectedCalendarDate, siteTimeZone).add(schedule.endTime, 'minutes');

  const startDateTimeString = startDateTime.format(dateTimeFormat);
  const endDateTimeString = endDateTime.format(dateTimeFormat);

  const siteDateTime = moment.tz(serverDateTime, siteTimeZone);

  return {
    endTime: endDateTimeString,
    startTime: startDateTimeString,
    siteDateTime,
    startDateTime,
    endDateTime,
    selectedCalendarDate
  };
}

export default getScheduleDateTime;
