import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActivityIndicator,
  Image,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View
} from 'react-native';
import { isLoaded } from 'react-redux-firebase';
import theme from '../../constants/theme';
import { Organization, OrganizationSKU, Question, Report, ReportSection, SiteSKU, UserRole } from '../../utils/classes';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../utils/screensize';
import Button from '../global/Button';
import ConfirmModal, { ModalConfig } from '../global/ConfirmModal';
import LoadingUpload from './LoadingUpload';
import ModalShowBottom from './ModalShowBottom';
import QuestionnaireProgress from './QuestionnaireProgress';
import QuestionnaireSwiper from './QuestionnaireSwiper';
import QuestionnaireUploadCVModal from './QuestionnaireUploadCVModal';
import WarningModal from './WarningModal';

export type QuestionnaireContainerProps = {
  /** Whether is modal shown or not */
  showModal: boolean;

  /** Whether the phone has notch (on the top of the screen) or not */
  hasNotch: boolean;

  /** True if the questionnaire is busy checking with the Computer Vision API */
  isUploadingCV: boolean;

  /** Used for async function. If it's true then system can't be disturbed and async function is possibly running */
  isBusy: boolean;

  /** Check if questionnaire intended for multisite */
  isMultiSite: boolean;

  /**  */
  isTransitioned: boolean;

  /** List of SKUs that has been calibrated */
  calibratingSKU: { [skuKey: string]: boolean } | null;

  /** List of questions in questionnaire that has been completed */
  completedCount: number;

  /** Index of a selected photo that is being edited */
  editingPhotoIndex: number;

  /** Currently used language (en / in) */
  language: string;

  /** Current configuration of a modal shown */
  modalConfig: ModalConfig;

  /** Report data that is currently being selected */
  selectedReport: Report | ReportSection;

  userRole: UserRole | null | undefined;

  /** Organization data that is tied to the report and user */
  organization: Organization | null | undefined;

  /** Currently active inventory that is linked to the selected questionnaire */
  currentInventory: any;

  /** SKU Query */
  skuQuery: string;

  /** Currently selected question that is being shown */
  currentQuestionIndex: number;

  /** Get - profile color of the organization */
  profileColor: string;

  /** True if current organization have access to video feature */
  featureAccessVideo: boolean;

  /** List of SKUs of an inventory for the questionnaire */
  siteSKUs: { [sku: string]: SiteSKU & { skuDetail: OrganizationSKU } } | null | undefined;

  /** Actions when SKU query input field is updated */
  handleSKUQueryChanged: ((text: string) => void) & Cancelable;

  /** Get - is in tutorial mode or not */
  isTutorialMode: boolean;

  /** get - state is show tooltip or not */
  isShowTooltipReview: boolean;

  /** Get - state if NA question is show or not*/
  isShowTooltipNA: boolean;

  /** state for showing tutorial modal on question */
  isShowQuestionTutorialModal: boolean;

  /** active question type , for showing modal based on question type */
  tutorialQuestionType: string;
  isUploading: boolean;

  /** Play a video when activated */
  handleNavPlaybackVideo: (uri: string, videoIndex: number, currentConditionIndex?: number | string) => void;

  /** Save edited photo */
  handleConfirmEditPhoto: (photoIndex: number, skuKey?: string, currentConditionIndex?: number | string) => void;

  /** Close the questionnaire modal */
  handleCloseQuestionnaireModal: () => void;

  /** Submit questionnaire. When internet is inactive, will be moved to async storage when internet is inactive */
  handleSubmitQuestionnaire: () => void;

  /** Increase current question index by 1, thus change the view to the next question in the questionnaire pool */
  handleMoveToNextQuestion: () => void;

  /** Decrease current question index by 1, thus change the view to the previous question in the questionnaire pool */
  handleMoveToPrevQuestion: () => void;

  /** Push a new page */
  handleNavReference: (url: string) => void;

  /** Open record video page */
  handleNavRecordVideo: (currentConditionIndex?: number | string, props?: any) => void;

  /** Navigate to Create Company page */
  handleNavCreateCompany: () => void;

  /** Save the questionnaire. Debounce prevents this function to be called repeatedly. This function can be called again in a moment after the previous requests is complete / error */
  saveQuestionnaireDebounced: () => void;

  /** Go through each question to ensure the photos are saved in the proper array order and save it into the redux */
  handleFinishSection: (isTriggered?: boolean) => void;

  /** Opens image picker for selected SKU */
  handleActivateImagePicker: (skuKey?: string, currentConditionIndex?: number | string) => void;

  /** Set recommendation selected by the user */
  handleSetRemedies: (index: number, text: string, currentConditionIndex?: number | string) => void;

  /** Save questionnaire input field to redux state */
  handleInput: (
    name: string,
    index: number,
    input: any,
    condition?: number | string,
    type?: any,
    question?: Question,
    skuKey?: string
  ) => void;

  /** Show or hide tutorial modal on question */
  handleShowTutorialQuestionModal: (isShow: boolean) => void;

  /** Set question type of tutorial */
  handleChangeTutorialQuestionType: (type: string) => void;

  /** Handle dismis of showing tooltip */
  handleDismissTooltipReview: () => void;

  /** Handle close or open tooltip */
  handleShowTooltipNA: (value: boolean) => void;

  /** close tutorial modal on question */
  handleDismissTutorialQuestionModal: () => void;

  /** end tutorial mode */
  handleDismissTutorialMode: () => void;

  handleUploadDocuments: (currentConditionIndex?: number | string, props?: any) => void;

  handleEditUploadFile: (currentConditionIndex?: number | string, props?: any) => void;

  showCamera: boolean;

  handleTakePhoto: (currentConditionIndex?: number | string, props?: any) => void;

  handleCloseCamera: () => void;
};

export const determineAllowGallery = (
  currentQuestion: Question,
  userRole?: UserRole | null | undefined,
  organization?: Organization | null | undefined
): boolean => {
  const isCameraOnly = userRole?.resourceMap['application:photo-source:live-camera']?.create;
  const isCameraAndGallery = userRole?.resourceMap['application:photo-source:allow-gallery']?.create;
  const userRoleSettings = isCameraAndGallery ?? !isCameraOnly ?? undefined;

  const defaultGalleryPermission = !!(currentQuestion.allowGallery ?? userRoleSettings ?? organization?.allowGallery);
  return organization?.allowGallery ? defaultGalleryPermission : false;
};

export const QuestionnaireContainer = (props: QuestionnaireContainerProps) => {
  const { t } = useTranslation(['questionnaire']);
  if (!props.isTransitioned || !isLoaded(props.calibratingSKU)) {
    return (
      <View style={styles.loading}>
        <ActivityIndicator color={theme.colors.primary} size="large" />
        <Text>{t('questionnaire:container.loadingQuestionnaire')}</Text>
      </View>
    );
  }

  const { selectedReport, userRole, organization, currentQuestionIndex } = props;
  const currentQuestion = selectedReport?.questions[currentQuestionIndex];
  const allowGallery = determineAllowGallery(currentQuestion, userRole, organization);

  const FinishButton = useCallback(() => {
    if (props.completedCount < props.selectedReport.questions.length) return null;

    return (
      <View>
        <Button
          testID={'finish-btn'}
          disabled={props.isBusy || props.completedCount < props.selectedReport.questions.length}
          style={styles.button}
          onPress={!props.isMultiSite ? props.handleSubmitQuestionnaire : () => props.handleFinishSection(false)}
          backgroundColor={props.profileColor}
          title={
            props.isBusy
              ? t('questionnaire:tutorial.loadingCapital')
              : props.isMultiSite
              ? t('questionnaire:tutorial.finishSection')
              : t('questionnaire:tutorial.reviewReport')
          }
        />
      </View>
    );
  }, [
    props.currentQuestionIndex,
    props.completedCount,
    props.isBusy,
    props.isMultiSite,
    props.profileColor,
    props.selectedReport.questions.length
  ]);

  return (
    <View style={styles.rootQusionareContainer}>
      <ConfirmModal
        isVisible={props.showModal}
        language={props.language}
        title={props.modalConfig.title}
        subtitle={props.modalConfig.subtitle}
        options={props.modalConfig.options}
        onCloseModal={props.handleCloseQuestionnaireModal}
      />

      {props.isUploading && <LoadingUpload />}

      <ModalShowBottom
        showFooterModal={props.showFooterModal}
        allowGallery={allowGallery}
        setShowFooter={props.handleCloseQuestionnaireModal}
        handleAcivateCamera={props.handleAcivateCamera}
        _handleFail={props.onFail}
        handlePhotoCaptured={props.handleTakeFromGallery}
        currentConditionIndex={props.currentConditionIndex}
      />

      <WarningModal
        isVisible={props.showWarningModal}
        language={props.language}
        title={props.modalWarningConfig.title}
        subtitle={props.modalWarningConfig.subtitle}
        options={props.modalWarningConfig.options}
        profileColor={props.profileColor}
        onCloseModalWarning={props.onCloseModalWarning}
        currentConditionIndex={props.currentConditionIndex}
        selectNewFile={props.selectNewFile}
        translation={props.translation}
      />

      <QuestionnaireUploadCVModal isVisible={props.isUploadingCV} language={props.language} />
      {/* <UploadReportStatus isFixed={true} fromHome={false} /> */}
      <View style={styles.questionnaireRoot}>
        <View style={styles.questionnaireContainer}>
          <TouchableWithoutFeedback>
            <View style={styles.questionnaireNav}>
              <QuestionnaireProgress
                completed={props.completedCount}
                total={props.selectedReport.questions.length}
                language={props.language}
                isTutorialMode={props.isTutorialMode}
                isShowQuestionTutorialModal={props.isShowQuestionTutorialModal}
              />
            </View>
          </TouchableWithoutFeedback>
          <QuestionnaireSwiper
            handleCloseCamera={props.handleCloseCamera}
            allowAuditorStockIn={props.organization?.allowAuditorStockIn}
            allowRecommendation={props.organization?.allowRecommendations}
            // TODO: remove `organization.features.video` when video and photo is splitted
            hasVideoAttachment={props.featureAccessVideo && props.organization?.features?.video}
            currentInventory={props.currentInventory}
            siteSKUs={props.siteSKUs}
            skuQuery={props.skuQuery}
            editingPhotoIndex={props.editingPhotoIndex}
            questions={props.selectedReport.questions}
            tutorialQuestionType={props.tutorialQuestionType}
            onQuestionIndexChanged={() => props.saveQuestionnaireDebounced()}
            onSKUQueryChanged={props.handleSKUQueryChanged}
            onNavReference={props.handleNavReference}
            onNavRecordVideo={props.handleNavRecordVideo}
            onNavPlaybackVideo={props.handleNavPlaybackVideo}
            onNavCreateCompany={props.handleNavCreateCompany}
            onSelectWeightedChoice={(index: number, text: string, condition?: string | number) => {
              return props.handleInput('weighted-choice', index, text, condition!);
            }}
            onSelectChoice={(index: number, text: string, condition?: string | number) =>
              props.handleInput('choice', index, text, condition!)
            }
            onSelectChecklist={(index: number, text: string, condition?: string | number) =>
              props.handleInput('checklist', index, text, condition!)
            }
            onInputAnswer={(index: number, text: string, condition?: string | number) =>
              props.handleInput('answer', index, text, condition!)
            }
            onInputComment={(index: number, text: string, condition?: string | number) =>
              props.handleInput('comment', index, text, condition!)
            }
            onInputNumber={(index: number, text: string, condition?: string | number) =>
              props.handleInput('number', index, text, condition!)
            }
            onInputScore={(index: number, score: number, condition?: string | number) =>
              props.handleInput('score', index, score, condition!)
            }
            onInputDecimal={(index: number, text: string, condition?: string | number) =>
              props.handleInput('range-flag', index, text, condition!)
            }
            onInputInventory={(index: number, text: string, type: any, question: Question) =>
              props.handleInput('inventory', index, text, '', type, question)
            }
            onInputSKUInventory={(index: number, skuKey: string, input: any, type: any, question: Question) =>
              props.handleInput('inventory-v2', index, input, '', type, question, skuKey)
            }
            onInputCompetitorAnalysis={(index: number, text: string, type: any) =>
              props.handleInput('competitor', index, text, '', type)
            }
            onConfirmEditPhoto={props.handleConfirmEditPhoto}
            onActivateImagePicker={props.handleActivateImagePicker}
            handleUploadDocuments={props.handleUploadDocuments}
            handleEditUploadFile={props.handleEditUploadFile}
            showCamera={props.showCamera}
            handleTakePhoto={props.handleTakePhoto}
            onSetRemedies={props.handleSetRemedies}
            isTutorialMode={props.isTutorialMode}
            isShowTooltipNA={props.isShowTooltipNA}
            isShowQuestionTutorialModal={props.isShowQuestionTutorialModal}
            handleShowTooltipNA={props.handleShowTooltipNA}
            handleShowTutorialQuestionModal={props.handleShowTutorialQuestionModal}
            handleChangeTutorialQuestionType={props.handleChangeTutorialQuestionType}
            handleDismissTutorialQuestionModal={props.handleDismissTutorialQuestionModal}
            handleDismissTutorialMode={props.handleDismissTutorialMode}
          />

          {props.isTutorialMode ? (
            <View style={styles.helpButton}>
              <TouchableOpacity onPress={() => props.handleShowTutorialQuestionModal(true)}>
                <Image
                  source={require('../../assets/icons/question-icon.png')}
                  style={{ width: 30, resizeMode: 'contain' }}
                />
              </TouchableOpacity>
            </View>
          ) : null}
          <View style={[styles.buttonContainer, props.hasNotch ? styles.buttonContainerNotch : null]}>
            {props.isBusy ? (
              <ActivityIndicator size="small" color="#3cd070" />
            ) : (
              <View
                style={{
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-around',
                  margin: 'auto',
                  paddingBottom: 10
                }}
              >
                <View>
                  <Button
                    testID={'next-btn'}
                    style={styles.buttonPrev}
                    disabled={props.currentQuestionIndex === 0 ? true : false}
                    backgroundColor={props.profileColor}
                    onPress={props.handleMoveToPrevQuestion}
                    title={t('questionnaire:container.backQuestion')}
                  />
                </View>
                <FinishButton />
                <View>
                  <Button
                    testID={'next-btn'}
                    style={styles.buttonNext}
                    backgroundColor={props.profileColor}
                    onPress={props.handleMoveToNextQuestion}
                    title={t('questionnaire:container.nextQuestion')}
                    disabled={!(props.currentQuestionIndex < props.selectedReport.questions.length - 1)}
                  />
                </View>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default QuestionnaireContainer;

const styles = StyleSheet.create({
  rootQusionareContainer: {
    flex: 1,
    minHeight: SCREEN_WIDTH >= 768 ? 100 : 1000,
    flexDirection: 'column',
    backgroundColor: '#fff'
  },
  helpButton: {
    position: 'absolute',
    bottom: 90,
    right: 20
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  questionnaireRoot: {
    flex: 1,
    flexDirection: 'column'
  },
  questionnaireContainer: {
    flex: 1,
    zIndex: 1
  },
  headerText: {
    textAlign: 'center',
    fontSize: SCREEN_WIDTH <= 320 ? 14 : 18,
    fontWeight: 'normal',
    marginTop: 25,
    paddingHorizontal: 30
  },
  questionnaireNav: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    marginHorizontal: 'auto',
    paddingTop: 20,
    paddingHorizontal: 5,
    paddingBottom: 15
  },
  buttonContainer: {
    minHeight: 64,
    height: SCREEN_HEIGHT * 0.1,
    backgroundColor: '#535353',
    zIndex: 2,
    bottom: 0
  },
  buttonContainerNotch: {
    height: SCREEN_HEIGHT * 0.1 + 12
  },
  buttonContainerHidden: {
    minHeight: 64,
    height: SCREEN_HEIGHT * 0.1,
    paddingVertical: 12,
    paddingHorizontal: SCREEN_WIDTH * 0.2,
    backgroundColor: '#535353',
    zIndex: -100
  },
  buttonNext: {
    width: 120
    // justifyContent: 'flex-start'
    // marginHorizontal: 'auto'
  },
  buttonPrev: {
    width: 120
    // justifyContent: 'flex-end'
    // marginHorizontal: 'auto'
  },
  button: {
    width: 300,
    marginHorizontal: 'auto'
  }
});
