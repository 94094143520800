import { StyleSheet, Text, View } from 'react-native';
import React, { useState } from 'react';
import ConfirmModal from '../../global/ConfirmModal';
import { useTranslation } from 'react-i18next';

const SiteReportDelayAlert = () => {
  const { t } = useTranslation(['site']);

  const [isVisible, setIsVisible] = useState(true);

  return (
    <ConfirmModal
      isVisible={isVisible}
      options={[
        {
          action: () => setIsVisible(false),
          style: 'confirm',
          text: 'OK'
        }
      ]}
      subtitle={t('site:reportDelayAlert.message')}
      title={t('site:reportDelayAlert.title')}
    />
  );
};

export default SiteReportDelayAlert;
