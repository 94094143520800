import update from 'immutability-helper';

import { ActionType } from 'typesafe-actions';

import * as actionTypes from './journeySchedule.actionTypes';
import * as actions from './journeySchedule.action';

import { PopulatedQuestionnaireIndex } from '../../../typing/types';
import { SelfieSignature } from '../../../utils/classes';

export type UserJourneyScheduleInfo = {
  key: string;
  hasDeadline: boolean;
  startTime: number;
  endTime: number;
  isScheduledToday: boolean;
  isDoneToday: boolean;
  unfinishedDates: string[];
  isQuestionnaireExists: boolean;
  questionnaireTitle: string;
  questionnaireDetails: { key: string; value: PopulatedQuestionnaireIndex };
  scheduledDates: {
    [date: string]: {
      isComplete: boolean;
    };
  };
  totalScheduled: number;
  totalCompleted: number;
  emailTargets: string[];
  signatures: number;
  selfieSignatures: SelfieSignature[];
};

export type JourneyScheduleDetails = {
  singleSchedules: UserJourneyScheduleInfo[];
  isLoading: boolean;
  isReady: boolean;
  isEmpty: boolean;
  lastUpdated: number;
};

export type JourneyScheduleAction = ActionType<typeof actions>;
export type JourneyScheduleState = {
  [siteKey: string]: JourneyScheduleDetails;
};

const initialState: JourneyScheduleState = {};

const journeyScheduleReducer = (state = initialState, action: JourneyScheduleAction): JourneyScheduleState => {
  switch (action.type) {
    case actionTypes.SET_IS_LOADING:
      return update(state, {
        [action.payload.siteKey]: scheduleInfo =>
          update(scheduleInfo || {}, {
            isLoading: { $set: action.payload.bool }
          })
      });
    case actionTypes.SET_IS_READY:
      return update(state, {
        [action.payload.siteKey]: scheduleInfo =>
          update(scheduleInfo || {}, {
            isReady: { $set: action.payload.bool }
          })
      });
    case actionTypes.SET_JOURNEY_SCHEDULE_DATA:
      return update(state, {
        [action.payload.siteKey]: scheduleInfo =>
          update(scheduleInfo || {}, {
            singleSchedules: { $set: action.payload.scheduleInfo || [] },
            isLoading: { $set: false },
            isReady: { $set: true },
            isEmpty: { $set: !action.payload.scheduleInfo || action.payload.scheduleInfo.length === 0 },
            lastUpdated: { $set: Date.now() }
          })
      });
    case actionTypes.CLEAR_JOURNEY_SCHEDULE_DATA:
      return update(state, {
        $unset: action.payload.siteKeys
      });

    default:
      return state;
  }
};

export default journeyScheduleReducer;
