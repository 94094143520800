import { JourneyReport } from 'nimbly-common';

/**
 * function to get journey report status (idle, ongoing, or finished)
 * @param journey
 */
function getJourneyReportStatus(
  journeyReport: JourneyReport | null,
  ongoingJourneyReportKey: string | null
): 'idle' | 'ongoing' | 'finished' {
  if (journeyReport) {
    if (!journeyReport.hasEnded && journeyReport.code === ongoingJourneyReportKey) {
      return 'ongoing';
    } else {
      return 'finished';
    }
  } else {
    return 'idle';
  }
}

export default getJourneyReportStatus;
