import { action, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';
import update from 'immutability-helper';
// import RNFS from 'react-native-fs';
import RNFirebase from 'firebase';
import Bluebird from 'bluebird';
import moment from 'moment';
import { getFirebase, getVal } from 'react-redux-firebase';
import { FirebaseStorageTypes } from 'firebase/storage';

import {
  Broadcast,
  PreGeneratedReport,
  PreGeneratedQuestion,
  Question,
  Report,
  ReportSummary,
  ReportQueueData,
  Signature,
  SelfieSignature,
  Site
} from '../../utils/classes';
import { InjectedSite, ThunkResult } from '../../typing/types';
import uploadReportPhoto from '../../utils/uploadReportPhoto';
import uploadReportVideo from '../../utils/uploadReportVideo';
import { NotificationPayload } from '../../store/reducers/notification';
import { errorLogger } from '../../utils/errorLogger';
import { sleep } from '../../utils/sleep';
import { RootState } from '../../store/reducers';
import { reportsubmitActions } from './reportsubmit';
import { setUploadingReportKey, removeUploadingReportKey } from './uploadqueue';
import { tutorialQuestionnaireData } from '../../components/site/Site/assets/tutorialData';
import { isReportDeviceCheckoutTime } from '../../utils/getReportTime';
export const SET_IS_ANIMATING = '@uploadreport/SET_IS_ANIMATING';
export const SET_IS_UPLOADING = '@uploadreport/SET_IS_UPLOADING';
export const UPDATE_UPLOAD_STATUS = '@uploadreport/UPDATE_UPLOAD_STATUS';
export const SET_CANCEL_STATUS = '@uploadreport/SET_CANCEL_STATUS';

export const UPDATE_UPLOADED_PHOTOS = '@uploadreport/UPDATE_UPLOADED_PHOTOS';
export const UPDATE_UPLOADED_INVENTORY_PHOTOS = '@uploadreport/UPDATE_UPLOADED_INVENTORY_PHOTOS';
export const UPDATE_UPLOADED_VIDEOS = '@uploadreport/UPDATE_UPLOADED_VIDEOS';
export const UPDATE_UPLOADED_SIGNATURES = '@uploadreport/UPDATE_UPLOADED_SIGNATURES';
export const UPDATE_UPLOADED_SELFIE_SIGNATURES = '@uploadreport/UPDATE_UPLOADED_SELFIE_SIGNATURES';

export const SET_UPLOADED_PHOTOS = '@uploadreport/SET_UPLOADED_PHOTOS';
export const SET_UPLOADED_VIDEOS = '@uploadreport/SET_UPLOADED_VIDEOS';
export const SET_UPLOADED_SIGNATURES = '@uploadreport/SET_UPLOADED_SIGNATURES';
export const SET_UPLOADED_SELFIE_SIGNATURES = '@uploadreport/SET_UPLOADED_SELFIE_SIGNATURES';
export const SET_UPLOADED_INVENTORY_PHOTOS = '@uploadreport/SET_UPLOADED_INVENTORY_PHOTOS';
export const SET_PHOTOS_URI = '@uploadreport/SET_PHOTOS_URI';
export const SET_VIDEOS_URI = '@uploadreport/SET_VIDEOS_URI';
export const SET_INVENTORY_PHOTOS_URI = '@uploadreport/SET_INVENTORY_PHOTOS_URI';
export const SET_SIGNATURE_PHOTOS_URI = '@uploadreport/SET_SIGNATURE_PHOTOS_URI';
export const SET_SELFIE_SIGNATURE_PHOTOS_URI = '@uploadreport/SET_SELFIE_SIGNATURE_PHOTOS_URI';

export const RESET_UPLOADED_PHOTOS = '@uploadreport/RESET_UPLOADED_PHOTOS';
export const RESET_UPLOADED_INVENTORY_PHOTOS = '@uploadreport/RESET_UPLOADED_INVENTORY_PHOTOS';
export const RESET_UPLOADED_VIDEOS = '@uploadreport/RESET_UPLOADED_VIDEOS';
export const RESET_UPLOADED_SIGNATURES = '@uploadreport/RESET_UPLOADED_SIGNATURES';
export const RESET_STATE = '@uploadreport/RESET_STATE';

export const UPDATE_UPLOADED_PHOTO_COUNT = '@uploadreport/UPDATE_UPLOADED_PHOTO_COUNT';
export const UPDATE_UPLOADED_VIDEO_COUNT = '@uploadreport/UPDATE_UPLOADED_VIDEO_COUNT';
export const UPDATE_TOTAL_PHOTO_COUNT = '@uploadreport/UPDATE_TOTAL_PHOTO_COUNT';
export const UPDATE_TOTAL_VIDEO_COUNT = '@uploadreport/UPDATE_TOTAL_VIDEO_COUNT';

export const SET_PHOTO_PROMISES = '@uploadreport/SET_PHOTO_PROMISES';
export const SET_SIGNATURE_PROMISES = '@uploadreport/SET_SIGNATURE_PROMISES';
export const SET_SELFIE_SIGNATURE_PROMISES = '@uploadreport/SET_SELFIE_SIGNATURE_PROMISES';
export const SET_VIDEO_PROMISES = '@uploadreport/SET_VIDEO_PROMISES';
export const SET_FIREBASE_PROMISES = '@uploadreport/SET_FIREBASE_PROMISES';

export const SET_IS_TIMEOUT = '@uploadreport/SET_IS_TIMEOUT';
export const SET_TIMER = '@uploadreport/SET_TIMER';
export const SET_TIMEOUT_VALUE = '@uploadreport/SET_TIMEOUT_VALUE';

type UploadTaskSnapshot = FirebaseStorageTypes.TaskSnapshot;

const cancelUpload = (): ThunkResult => (dispatch: Dispatch, getState: () => RootState) => {
  const { firebasePromises, photoPromises, signaturePromises, selfieSignaturePromises, videoPromises, timer } =
    getState().uploadreport as any;
  // each promise consist of Bluebird promise that's able to take a 'cancel' handler
  if (firebasePromises.length) {
    firebasePromises.forEach((prom: any) => {
      try {
        prom.cancel();
      } catch (err) {}
    });
    clearTimeout(timer);
  }
  if (photoPromises.length) {
    photoPromises.forEach((prom: any) => {
      try {
        prom.cancel();
      } catch (err) {}
    });
  }
  if (selfieSignaturePromises.length) {
    selfieSignaturePromises.forEach((prom: any) => {
      try {
        prom.cancel();
      } catch (err) {}
    });
  }
  if (signaturePromises.length) {
    signaturePromises.forEach((prom: any) => {
      try {
        prom.cancel();
      } catch (err) {}
    });
  }
  if (videoPromises.length) {
    videoPromises.forEach((prom: any) => {
      try {
        prom.cancel();
      } catch (err) {}
    });
  }

  dispatch(resetUploadState());
  dispatch(setIsUploading(false));

  // set cancelled state
  dispatch(setCancelStatus(true));
};

//handle generate report in tutorial mode
const handleUploadReportTutorial =
  (reportVal: Report): ThunkResult =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { firebase, reportsubmit } = getState();
    const { auth, profile } = firebase;
    const { uploadingReportKey, uploadingSiteKey } = reportsubmit;
    // const { profile, uploadingSiteKey, uploadingReportKey, auth, navigation } = props;
    const generateTutorialReport = RNFirebase.functions!().httpsCallable('inAppTutorialGenerateReportOnCall');

    try {
      await generateTutorialReport({
        report: reportVal,
        organizationKey: profile.organization,
        questionnaire: tutorialQuestionnaireData,
        reportKey: uploadingReportKey
      });

      await dispatch(setIsUploading(false));
      await dispatch(updateUploadStatus('complete'));
      return;
    } catch (error) {
      if (error) {
        errorLogger(
          auth.uid,
          profile.organization,
          uploadingSiteKey as string,
          uploadingReportKey as string,
          error,
          'uploadreport.ts/uploadReportTutorial'
        );
      }
    }
  };

const uploadPhotos =
  ({
    uploadingReport,
    uploadingReportKey,
    uploadingSiteKey
  }: {
    uploadingReport: Report | null;
    uploadingReportKey: string | null;
    uploadingSiteKey: string | null;
  }): ThunkResult =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { firebase, inAppTutorial } = getState();
    const { profile } = firebase;
    const { isTutorialMode } = inAppTutorial;

    const photoPromises: Promise<any>[] = [];
    const questionPhotos: string[][] = [];
    const questionInventoryPhotos: { [skuKey: string]: any[] } = {};

    if (uploadingReport !== null) {
      // create default value of empty string to prevent missing array index
      uploadingReport.questions.forEach((question: Question) => {
        if (question.type !== 'inventory-v2') {
          const photosArr = question.photos;
          const photos: string[] = Array(photosArr && Array.isArray(photosArr) ? photosArr.length : 0).fill('');
          questionPhotos.push(photos);
        } else if (question.skuInventory && Object.keys(question.skuInventory).length > 0) {
          Object.keys(question.skuInventory!).forEach(skuKey => {
            questionInventoryPhotos[skuKey] =
              question.skuInventory![skuKey].photos && question.skuInventory![skuKey].photos!.length > 0
                ? question.skuInventory![skuKey].photos!
                : [];
          });
          questionPhotos.push([]);
        }
      });

      await dispatch(setUploadedInventoryPhotos(questionInventoryPhotos));
      await dispatch(setUploadedPhotos(questionPhotos));

      // save photos URI for show up in upload process
      const reportPhotosURI: string[][] = [];
      const reportInventoryPhotosURI: { [skuKey: string]: any[] } = {};

      uploadingReport.questions.forEach((question: Question, questionIndex: number) => {
        const questionPhotosURI: string[] = [];
        if (question.type !== 'inventory-v2') {
          if (question.photos) {
            question.photos.forEach((photoURI: string, photoIndex: number) => {
              if (photoURI) {
                questionPhotosURI.push(photoURI);
              } else {
                questionPhotosURI.push('');
              }
            });
          }
        } else if (question.skuInventory && Object.keys(question.skuInventory).length > 0) {
          Object.keys(question.skuInventory!).forEach(skuKey => {
            reportInventoryPhotosURI[skuKey] =
              question.skuInventory![skuKey].photos && question.skuInventory![skuKey].photos!.length > 0
                ? question.skuInventory![skuKey].photos!
                : [];
          });
        }
        reportPhotosURI.push(questionPhotosURI);
      });

      //set photoURI to redux for show up in uploading progress
      await dispatch(setInventoryPhotosURI(reportInventoryPhotosURI));
      await dispatch(setPhotosURI(reportPhotosURI));

      for (const [questionIndex, question] of uploadingReport.questions.entries()) {
        if (question.photos) {
          for (const [photoIndex, photoURI] of question.photos.entries()) {
            if (photoURI) {
              const uploadPromise = () => {
                return uploadReportPhoto(
                  photoURI,
                  profile.organization,
                  uploadingSiteKey as string,
                  uploadingReportKey as string,
                  false,
                  cancelUpload,
                  isTutorialMode
                ).then(async (fileRef: string) => {
                  const { isCancelled } = getState().uploadreport;
                  if (uploadingReport && uploadingReport.questions && !isCancelled) {
                    await dispatch(updateUploadedPhotos(questionIndex, photoIndex, fileRef));
                    await dispatch(updateUploadedPhotoCount(1));
                  }
                });
              };
              photoPromises.push(uploadPromise());
            }
          }
        } else if (
          question.type === 'inventory-v2' &&
          question.skuInventory &&
          Object.keys(question.skuInventory).length > 0
        ) {
          for (const skuKey of Object.keys(question.skuInventory!)) {
            const inventoryPhotos = question.skuInventory![skuKey].photos;
            if (inventoryPhotos && inventoryPhotos.length > 0) {
              for (const [photoIndex, photoURI] of inventoryPhotos.entries()) {
                const uploadPromise = () => {
                  return uploadReportPhoto(
                    photoURI,
                    profile.organization,
                    uploadingSiteKey as string,
                    uploadingReportKey as string,
                    false,
                    cancelUpload,
                    isTutorialMode
                  ).then(async (fileRef: string) => {
                    const { isCancelled } = getState().uploadreport;
                    if (uploadingReport && uploadingReport.questions && !isCancelled) {
                      await dispatch(updateUploadedPhotoCount(1));
                      await dispatch(updateUploadedInventoryPhotos(skuKey, photoIndex, fileRef));
                    }
                  });
                };
                if (question.answer !== 'not-applicable') {
                  photoPromises.push(uploadPromise());
                }
              }
            }
          }
        }
      }
    }
    await dispatch(setPhotoPromises(photoPromises));
    return Promise.resolve(photoPromises);
  };

const uploadVideos =
  ({
    uploadingReport,
    uploadingSiteKey,
    uploadingReportKey
  }: {
    uploadingReport: Report | null;
    uploadingSiteKey: string | null;
    uploadingReportKey: string | null;
  }): ThunkResult =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    // uploadedVideoCount
    const { profile } = getState().firebase;
    const videoPromises: Promise<any>[] = [];
    const questionVideos: string[][] = [];

    // create default value of empty string to prevent missing array index
    if (uploadingReport !== null) {
      uploadingReport.questions.forEach((question: Question) => {
        if (question.type !== 'inventory-v2') {
          const videosArr = question.videos;
          const videos: string[] = Array(videosArr && Array.isArray(videosArr) ? videosArr.length : 0).fill('');
          questionVideos.push(videos);
        }
      });
    }

    dispatch(setUploadedVideos(questionVideos));

    const reportVideosURI: string[][] = [];

    // set videos URI for showing up in uploading process
    uploadingReport!.questions.forEach((question: Question, questionIndex: number) => {
      const questionVideosURI: string[] = [];
      if (question.videos) {
        question.videos.forEach((videoURI: string, videoIndex: number) => {
          if (videoURI) {
            questionVideosURI.push(videoURI);
          }
        });
      }
      reportVideosURI.push(questionVideosURI);
    });

    dispatch(setVideosURI(reportVideosURI));

    for (const [questionIndex, question] of uploadingReport!.questions.entries()) {
      if (question.videos) {
        for (const [videoIndex, videoURI] of question.videos.entries()) {
          if (videoURI) {
            const uploadPromise = () => {
              return uploadReportVideo(
                videoURI,
                profile.organization,
                uploadingSiteKey as string,
                uploadingReportKey as string,
                false,
                cancelUpload
              ).then(async (fileRef: string) => {
                const { isCancelled } = getState().uploadreport;
                if (uploadingReport && uploadingReport.questions && !isCancelled) {
                  await dispatch(updateUploadedVideos(questionIndex, videoIndex, fileRef));
                  await dispatch(updateUploadedVideoCount(1));
                }
              });
            };
            videoPromises.push(uploadPromise());
          }
        }
      }
    }

    await dispatch(setVideoPromises(videoPromises));
    return Promise.resolve(videoPromises);
  };

const uploadSelfieSignatures =
  ({
    uploadingReport,
    uploadingReportKey,
    uploadingSiteKey
  }: {
    uploadingReport: Report | null;
    uploadingSiteKey: string | null;
    uploadingReportKey: string | null;
  }): ThunkResult =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { firebase } = getState();
    const { profile } = firebase;
    const { selfieSignatures } = uploadingReport as Report;

    const selfieSignaturePromises: Promise<any>[] = [];

    if (selfieSignatures && selfieSignatures.length) {
      const selfieSignaturePhotosURI = [];

      dispatch(setUploadedSelfieSignatures(selfieSignatures));
      selfieSignaturePhotosURI.push(selfieSignatures[0].path);
      dispatch(setSelfieSignaturesPhotosURI(selfieSignaturePhotosURI));
    }

    if (selfieSignatures && selfieSignatures.length) {
      for (const [i, selfie] of selfieSignatures.entries()) {
        const { path } = selfie;
        if (path) {
          const selfieSignPromise = () => {
            return new Bluebird(
              (resolve: (T: any) => void, reject: (E: Error) => void, onCancel: (A?: any) => void) => {
                const fileNameReg = /[^/]*$/.exec(path);
                if (!fileNameReg) {
                  return reject(new Error('file name not found'));
                }
                const fileName = fileNameReg[0];

                // eslint-disable-next-line max-len
                const folderRef = `/report/${profile.organization}/${uploadingSiteKey}/selfieSignatures/${uploadingReportKey}/`;

                const reportRef = RNFirebase.storage().ref(folderRef);
                const signRef = reportRef.child(fileName);

                onCancel!(cancelUpload);

                // return RNFS.exists(path).then(isExists => {
                //   if (isExists) {
                //     return resolve(signRef.putFile(path, { contentType: 'image/jpeg' }));
                //   }
                //   return resolve(path);
                // });
              }
            )
              .then((uploadRes: UploadTaskSnapshot) => {
                const selectedRef = uploadRes && uploadRes.ref ? uploadRes.ref : path;
                return selectedRef;
              })
              .then(async (resRef: string) => {
                const { isCancelled } = getState().uploadreport;
                if (uploadingReport && uploadingReport.questions && !isCancelled) {
                  dispatch(updateUploadedPhotoCount(1));
                  // for now selfie signature can only hold up to 1 item in an array
                  dispatch(updateUploadedSelfieSignatures(i, resRef));
                }
              });
          };
          selfieSignaturePromises.push(selfieSignPromise());
        }
      }
    }

    await dispatch(setSelfieSignaturePromises(selfieSignaturePromises));
    return Promise.resolve(selfieSignaturePromises);
  };

const uploadSignatures =
  ({
    uploadingReport,
    uploadingSiteKey,
    uploadingReportKey
  }: {
    uploadingReport: Report | null;
    uploadingSiteKey: string | null;
    uploadingReportKey: string | null;
  }): ThunkResult =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    const { inAppTutorial, firebase } = getState();
    const { profile } = firebase;
    const { isTutorialMode } = inAppTutorial;
    const signaturePromises: any = [];

    if (uploadingReport && uploadingReport.signatures) {
      const { signatures } = uploadingReport;

      if (signatures) {
        const signaturesURI: string[] = [];
        signatures.forEach(({ path }: Signature) => {
          signaturesURI.push(path);
        });

        await dispatch(setSignaturePhotosURI(signaturesURI));
        await dispatch(setUploadedSignatures(signatures));

        for (const [i, signature] of signatures.entries()) {
          if (signature.path && signature.isSigned) {
            const signPromise = () => {
              let fileRef = '';
              return new Bluebird(
                (resolve: (T: any) => void, reject: (E: Error) => void, onCancel: (A?: any) => void) => {
                  const fileNameReg = /[^/]*$/.exec(signature.path);
                  if (!fileNameReg) {
                    return reject(new Error('file name not found'));
                  }
                  const fileName = fileNameReg[0];

                  let folderRef = `/report/${profile.organization}/${uploadingSiteKey}/signatures/${uploadingReportKey}/`;

                  // eslint-disable-next-line max-len
                  fileRef = `/report/${profile.organization}/${uploadingSiteKey}/signatures/${uploadingReportKey}/${fileName}`;

                  if (isTutorialMode) {
                    folderRef = `tutorial/refport/${profile.organization}/signatures/${uploadingReportKey}/`;
                    fileRef = `tutorial/report/${profile.organization}/signatures/${uploadingReportKey}/${fileName}`;
                  }

                  const reportRef = RNFirebase.storage().ref(folderRef);
                  const signRef = reportRef.child(fileName);

                  onCancel!(cancelUpload);

                  // return RNFS.exists(signature.path).then(isExists => {
                  //   if (isExists) {
                  //     return resolve(signRef.putFile(signature.path, { contentType: 'image/png' }));
                  //   }
                  //   return resolve(signature.path);
                  // });
                }
              )
                .then((uploadRes: UploadTaskSnapshot) => {
                  const selectedRef = uploadRes && uploadRes.ref ? uploadRes.ref : '';
                  return selectedRef;
                })
                .then((resRef: string) => {
                  const { isCancelled } = getState().uploadreport;

                  if (!isCancelled) {
                    const selectedFileRef = resRef ? resRef : signature.path;

                    dispatch(updateUploadedPhotoCount(1));
                    dispatch(updateUploadedSignatures(i, selectedFileRef));
                  }
                });
            };
            signaturePromises.push(signPromise());
          }
        }
      }
    }

    dispatch(setSignaturePromises(signaturePromises));
    return Promise.resolve(signaturePromises);
  };

export const uploadReport = (): ThunkResult => async (dispatch: Dispatch, getState: () => RootState) => {
  const { firebase, account, network, inAppTutorial, reportsubmit, uploadqueue } = getState();
  const {
    auth,
    profile,
    profile: { language }
  } = firebase;
  const { uploadQueueKeys } = uploadqueue;
  const { isDummy } = account;
  const { isConnected } = network;
  const { isTutorialMode } = inAppTutorial;
  const {
    uploadingQuestionnaireName,
    uploadingReport,
    uploadingReportKey,
    uploadingReportSummary,
    uploadingSite,
    uploadingSiteKey
  } = reportsubmit;
  const fb = getFirebase();

  Bluebird.config({ cancellation: true });

  try {
    // end upload invocation if reportKey has already set previously
    if (uploadQueueKeys && uploadQueueKeys.hasOwnProperty(uploadingReportKey as string)) {
      dispatch(cancelUpload());
    }
    // this is to ensure to empty the state
    await dispatch(resetUploadState());

    await dispatch(setUploadingReportKey(uploadingReportKey as string));

    const photoPromises = await dispatch(uploadPhotos({ uploadingReport, uploadingReportKey, uploadingSiteKey }));
    const signPromises =
      uploadingReport &&
      uploadingReport.signatures &&
      Array.isArray(uploadingReport.signatures) &&
      uploadingReport.signatures.length
        ? await dispatch(uploadSignatures({ uploadingReport, uploadingReportKey, uploadingSiteKey }))
        : [];
    const videoPromises = await dispatch(uploadVideos({ uploadingReport, uploadingReportKey, uploadingSiteKey }));
    const selfiePromises = await dispatch(
      uploadSelfieSignatures({ uploadingReport, uploadingReportKey, uploadingSiteKey })
    );

    const total = photoPromises.length + signPromises.length + selfiePromises.length;

    await dispatch(updateUploadedPhotoCount(total === 0 ? 1 : 0));
    await dispatch(updateTotalPhotoCount(total === 0 ? 1 : total));
    await dispatch(updateTotalVideoCount(total === 0 ? 1 : videoPromises.length));
    await dispatch(setIsUploading(true));
    await dispatch(updateUploadStatus('uploading'));

    await dispatch(initiateUploadingTimeout());

    await Promise.all([
      ...getState().uploadreport.photoPromises,
      ...getState().uploadreport.signaturePromises,
      ...getState().uploadreport.selfieSignaturePromises,
      ...getState().uploadreport.videoPromises
    ]);

    // Update the state with uploaded photos
    const { questions: prevQuestions } = uploadingReport as Report;
    const { uploadedInventoryPhotos, uploadedPhotos, uploadedVideos, uploadedSignatures, uploadedSelfieSignatures } =
      getState().uploadreport;
    const updatedQuestions = prevQuestions.map((question, questionIndex) => {
      const updatedQuestion = { ...question };
      if (question.type === 'inventory-v2' && question.skuInventory && Object.keys(question.skuInventory).length > 0) {
        Object.keys(question.skuInventory!).forEach(skuKey => {
          if (uploadedInventoryPhotos) {
            question.skuInventory![skuKey].photos = uploadedInventoryPhotos[skuKey];
          }
        });
      } else if (uploadedPhotos && uploadedPhotos[questionIndex] && uploadedPhotos[questionIndex].length > 0) {
        updatedQuestion.photos = uploadedPhotos[questionIndex];
      }
      if (uploadedVideos && uploadedVideos[questionIndex] && uploadedVideos[questionIndex].length > 0) {
        updatedQuestion.videos = uploadedVideos[questionIndex];
      }
      return updatedQuestion;
    });

    await dispatch(
      reportsubmitActions.setReportToUpload(
        uploadingSite as Site | InjectedSite,
        uploadingSiteKey as string,
        uploadingQuestionnaireName as string,
        uploadingReportKey as string,
        {
          ...(uploadingReport as Report),
          questions: [...updatedQuestions],
          signatures: uploadedSignatures,
          selfieSignatures: uploadedSelfieSignatures
        },
        uploadingReportSummary as ReportSummary
      )
    );

    // allow the state to updated first before retrieving it once more on the next block of code
    await sleep(10);

    const serverTime = RNFirebase.database().getServerTime();
    const now = moment(serverTime).toISOString(true);

    const reportRef = `/report/${profile.organization}/${uploadingSiteKey}/${uploadingReportKey}`;
    const reportIndexRef = `/reportIndex/${profile.organization}/${uploadingSiteKey}/${uploadingReportKey}`;
    const reportSectionRef = `/reportSection/${profile.organization}/${uploadingSiteKey}/${uploadingReportKey}`;

    const updatedReport = getState().reportsubmit.uploadingReport;

    // Call this.props explicitly as they have been updated since this.uploadPhotos()
    const reportVal = {
      ...updatedReport,
      datetimeSubmitted: isReportDeviceCheckoutTime(uploadingReport.datetimeOut, now)
    } as Report & { datetimeSubmitted: string };

    //generate report tutorial mode
    if (isTutorialMode && isConnected) {
      await dispatch(handleUploadReportTutorial(reportVal));
      return;
    }

    // Overwrite the status of the report if using dummy account
    // Also delete all sections so that we can start a fresh report again
    if (isDummy) {
      await fb.update(reportRef, { status: 'draft' });
      await fb.set(reportSectionRef, null);
    }

    const reportIndexVal = getState().reportsubmit.uploadingReportSummary;

    const updateReport = (cancelHander: () => any): Promise<any> => {
      return new Bluebird((resolve: (T: any) => void, reject: (E: Error) => any, onCancel: (A?: any) => any) => {
        if (onCancel) {
          onCancel(cancelHander);
        }
        return fb.update(reportRef, reportVal).then(() => resolve(''));
      });
    };

    const updateReportIndex = (cancelHander: () => any): Promise<any> => {
      return new Bluebird((resolve: (T: any) => void, reject: (E: Error) => any, onCancel: (A?: any) => any) => {
        if (onCancel) {
          onCancel(cancelHander);
        }
        return fb.update(reportIndexRef, reportIndexVal!).then(() => resolve(''));
      });
    };

    const createQueue = (cancelHander: () => any): Promise<any> => {
      return new Bluebird((resolve: (T: any) => void, reject: (E: Error) => any, onCancel: (A?: any) => any) => {
        const queueRef = `/queue/reportCompleted`;
        const reportQueueData: ReportQueueData = {
          organizationKey: profile.organization,
          siteKey: uploadingSiteKey as string,
          reportKey: uploadingReportKey as string,
          report: reportVal
        };
        if (onCancel) {
          onCancel(cancelHander);
        }
        return fb.push(queueRef, reportQueueData).then(() => resolve(''));
      });
    };

    await dispatch(setFirebasePromises(updateReport(cancelUpload)));
    await dispatch(setFirebasePromises(updateReportIndex(cancelUpload)));
    await dispatch(setFirebasePromises(createQueue(cancelUpload)));

    await sleep(10);

    const { firebasePromises } = getState().uploadreport;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < firebasePromises.length; i++) {
      await firebasePromises[i];
    }

    await dispatch(removeUploadingReportKey(uploadingReportKey as string));
    const organization = getVal(firebase.data, `/organization/${firebase.profile.organization}`);
    const { timezone } = organization.schedule;
    const message = `${(uploadingSite as Site | InjectedSite).name}\n${uploadingQuestionnaireName}`;
    const ref = `/reportBroadcast/${profile.organization}/${auth.uid}`;

    const preGeneratedQuestions: PreGeneratedQuestion[] = uploadingReport!.questions.map((question: Question) => ({
      type: question.type,
      answer: question.answer,
      score: question.score,
      scoreWeight: question.scoreWeight,
      rangeOptions: question.rangeOptions
    }));

    const preGeneratedReport: PreGeneratedReport = {
      datetimeIn: uploadingReport!.datetimeIn,
      datetimeOut: uploadingReport!.datetimeOut,
      questions: preGeneratedQuestions
    };

    const payload = JSON.stringify({
      title: language === 'en' ? 'Report upload done' : 'Laporan telah selesai diunggah',
      body: message,
      type: 'notification' as Extract<'type', NotificationPayload>,
      value: preGeneratedReport
    });

    const broadcast = new Broadcast(
      message,
      auth.uid,
      true,
      now,
      'report_complete',
      'complete',
      timezone,
      now,
      now,
      'users',
      [auth.uid],
      payload
    );

    await fb.push(ref, broadcast);
    await dispatch(setIsUploading(false));
    await dispatch(updateUploadStatus('complete'));
    // dismiss tray & navigate
  } catch (error) {
    if (error) {
      await dispatch(setIsUploading(false));
      await dispatch(updateUploadStatus('error'));
      await dispatch(removeUploadingReportKey(uploadingReportKey as string));

      errorLogger(
        auth.uid,
        profile.organization,
        uploadingSiteKey as string,
        uploadingReportKey as string,
        error,
        'uploadreport.ts/uploadReport'
      );
    }

    dispatch(cancelUpload());
    return null;
  }
};

export const initiateUploadingTimeout = (): ThunkResult => (dispatch: Dispatch, getState: () => RootState) => {
  const { totalPhotosCount, isUploading, timer } = getState().uploadreport;
  /**
   * if photo count <= 40 items:
   * - will automatically set timeout to 3 minutes
   * photo count > 40
   * - will assume each photo will take 4.5 seconds to upload
   */
  const timeout = totalPhotosCount <= 40 ? 1000 * 60 * 3 : 1000 * 4.5 * totalPhotosCount;

  // clears any timer previously set
  clearTimeout(timer);

  const newTimer = setTimeout(() => {
    if (isUploading) {
      clearTimeout(timer);
      dispatch(setIsTimeout(true));
    }
  }, timeout);

  setTimer(newTimer);
};

export const setIsTimeout = (bool: boolean) => action(SET_IS_TIMEOUT, { bool });

export const setTimer = (timer: ReturnType<typeof setTimeout> | null) => action(SET_TIMER, { timer });

export const setCancelStatus = (bool: boolean) => action(SET_CANCEL_STATUS, { bool });

export const setPhotoPromises = (arrPromise: any[]) => action(SET_PHOTO_PROMISES, { arrPromise });

export const setSignaturePromises = (arrPromise: any[]) => action(SET_SIGNATURE_PROMISES, { arrPromise });

export const setSelfieSignaturePromises = (arrPromise: any[]) => action(SET_SELFIE_SIGNATURE_PROMISES, { arrPromise });

export const setVideoPromises = (arrPromise: any[]) => action(SET_VIDEO_PROMISES, { arrPromise });

export const setFirebasePromises = (arrPromise: any) => action(SET_FIREBASE_PROMISES, { arrPromise });

export const unlinkReportPhotos = (uploadingSiteKey: string) => async (): Promise<void> => {
  // const folderExists = await RNFS.exists(`${RNFS.ExternalDirectoryPath}/${uploadingSiteKey}/`);
  // // To handle error in ios,
  // // check if it existed then unlink/delete the whole folder after the photos are being uploaded with the report
  // if (folderExists) {
  //   // Deleting the folder of the current site so all of the photos are deleted within the folder
  //   await RNFS.unlink(`${RNFS.ExternalDirectoryPath}/${uploadingSiteKey}/`);
  // }
};

export const setIsAnimating = (bool: boolean) => action(SET_IS_ANIMATING, { bool });

export const setIsUploading = (bool: boolean) => action(SET_IS_UPLOADING, { bool });

export const updateUploadStatus = (status: UploadStatusKeys) => action(UPDATE_UPLOAD_STATUS, { status });

export const updateUploadedPhotos = (questionIndex: number, photoIndex: number, fileRef: string) =>
  action(UPDATE_UPLOADED_PHOTOS, { questionIndex, photoIndex, fileRef });

export const updateUploadedInventoryPhotos = (skuKey: string, photoIndex: number, fileRef: string) =>
  action(UPDATE_UPLOADED_INVENTORY_PHOTOS, { skuKey, photoIndex, fileRef });

export const updateUploadedSignatures = (index: number, fileRef: string) =>
  action(UPDATE_UPLOADED_SIGNATURES, { index, fileRef });

export const updateUploadedSelfieSignatures = (index: number, fileRef: string) =>
  action(UPDATE_UPLOADED_SELFIE_SIGNATURES, { index, fileRef });

export const updateUploadedVideos = (questionIndex: number, videoIndex: number, fileRef: string) =>
  action(UPDATE_UPLOADED_VIDEOS, { questionIndex, videoIndex, fileRef });

export const setUploadedPhotos = (questionnairePhotos: string[][]) =>
  action(SET_UPLOADED_PHOTOS, { questionnairePhotos });

export const setUploadedVideos = (questionnaireVideos: string[][]) =>
  action(SET_UPLOADED_VIDEOS, { questionnaireVideos });

export const setUploadedInventoryPhotos = (inventoryPhotos: { [skuKey: string]: any[] }) =>
  action(SET_UPLOADED_INVENTORY_PHOTOS, { inventoryPhotos });

export const setUploadedSignatures = (signatures: Signature[]) => action(SET_UPLOADED_SIGNATURES, { signatures });

export const setUploadedSelfieSignatures = (selfieSignatures: SelfieSignature[]) =>
  action(SET_UPLOADED_SELFIE_SIGNATURES, { selfieSignatures });

export const setSignaturePhotosURI = (signaturePhotosURI: string[]) =>
  action(SET_SIGNATURE_PHOTOS_URI, { signaturePhotosURI });

export const setSelfieSignaturesPhotosURI = (selfieSignaturePhotosURI: string[]) =>
  action(SET_SELFIE_SIGNATURE_PHOTOS_URI, { selfieSignaturePhotosURI });

export const setPhotosURI = (photosURI: string[][]) => action(SET_PHOTOS_URI, { photosURI });

export const setVideosURI = (videosURI: string[][]) => action(SET_VIDEOS_URI, { videosURI });

export const setInventoryPhotosURI = (inventoryPhotosURI: { [skuKey: string]: any[] }) =>
  action(SET_INVENTORY_PHOTOS_URI, { inventoryPhotosURI });

export const updateUploadedPhotoCount = (count: number) => action(UPDATE_UPLOADED_PHOTO_COUNT, { count });

export const updateUploadedVideoCount = (count: number) => action(UPDATE_UPLOADED_VIDEO_COUNT, { count });

export const updateTotalPhotoCount = (sum: number) => action(UPDATE_TOTAL_PHOTO_COUNT, { sum });

export const updateTotalVideoCount = (sum: number) => action(UPDATE_TOTAL_VIDEO_COUNT, { sum });

export const resetUploadedPhotos = () => action(RESET_UPLOADED_PHOTOS);

export const resetUploadedInventoryPhotos = () => action(RESET_UPLOADED_INVENTORY_PHOTOS);

export const resetUploadedVideos = () => action(RESET_UPLOADED_VIDEOS);

export const resetUploadedSignatures = () => action(RESET_UPLOADED_SIGNATURES);

export const resetUploadState = () => action(RESET_STATE);

export const uploadreportThunks = {
  uploadReport,
  cancelUpload,
  initiateUploadingTimeout,
  unlinkReportPhotos
};

export const uploadreportActions = {
  setCancelStatus,
  setIsTimeout,
  setIsAnimating,
  setIsUploading,
  setPhotosURI,
  setVideosURI,
  setSignaturePhotosURI,
  setSelfieSignaturesPhotosURI,
  setInventoryPhotosURI,
  setUploadedPhotos,
  setUploadedVideos,
  setUploadedSignatures,
  setUploadedInventoryPhotos,
  setUploadedSelfieSignatures,
  updateUploadStatus,
  updateUploadedPhotos,
  updateUploadedInventoryPhotos,
  updateUploadedSignatures,
  updateUploadedSelfieSignatures,
  updateUploadedVideos,
  updateUploadedPhotoCount,
  updateUploadedVideoCount,
  updateTotalPhotoCount,
  updateTotalVideoCount,
  resetUploadedPhotos,
  resetUploadedInventoryPhotos,
  resetUploadedVideos,
  resetUploadedSignatures,
  resetUploadState,
  setPhotoPromises,
  setVideoPromises,
  setSignaturePromises,
  setSelfieSignaturePromises,
  setFirebasePromises,
  setTimer
};

export type UploadStatusKeys = 'complete' | 'uploading' | 'error' | 'inactive';

export type UploadReportAction = ActionType<typeof uploadreportActions>;

export type UploadReportState = {
  /**
   * Array of `Bluebird` promise that will be update firebase ref:
   * @name reportRef - `/report/${organization}/${siteKey}/${reportKey}`
   * @name reportIndexRef - `/reportIndex/${organization}/${siteKey}/${reportKey}`
   * @name reportSectionRef - `/reportSection/${organization}/${siteKey}/${reportKey}` (only gets called when isDummy)
   * @name queueRef - `/queue/reportCompleted`
   */
  readonly firebasePromises: any[];
  /**
   * Array of photos `Bluebird` promise that will be uploaded
   * photos include photo of each questions including photos taken from inventory
   * */
  readonly photoPromises: any[];
  /**
   * Array of video `Bluebird` promise that will be uploaded
   * */
  readonly videoPromises: any[];
  /**
   * Array of signature `Bluebird` promise that will be uploaded
   * */
  readonly signaturePromises: any[];
  /**
   * Array of selfie signature `Bluebird` promise that will be uploaded
   * */
  readonly selfieSignaturePromises: any[];
  /**
   * holds setTimeout to trigger whenever an upload has exceed it's timeout value
   * */
  readonly timer: null | ReturnType<typeof setTimeout>;
  /** State to indicate status condition on upload progress */
  readonly status: UploadStatusKeys;
  /** State to indicate whether a tray animation is in progress */
  readonly isAnimating: boolean;
  /** State to indicate whether an upload is in progress */
  readonly isUploading: boolean;
  /** State to indicate whether an upload is in progress */
  readonly isCancelled: boolean;
  /** State to indicated whether the upload progress has exceeded its timeout count */
  readonly isTimedOut: boolean;
  /** Contain photos that has been uploaded */
  readonly uploadedPhotos: string[][];
  /** Contain SKU Inventory photos that has been uploaded */
  readonly uploadedInventoryPhotos: { [skuKey: string]: any[] };
  /** Contain signatures object that has been uploaded */
  readonly uploadedSignatures: Signature[];
  /** Contain  selfie signatures object that has been uploaded */
  readonly uploadedSelfieSignatures: SelfieSignature[];
  /** Contain videos that has been uploaded */
  readonly uploadedVideos: string[][];
  /** Number of successfully uploaded photos */
  readonly uploadedPhotoCount: number;
  /** Number of successfully uploaded videos */
  readonly uploadedVideoCount: number;
  /** Number of photos that will be uploaded */
  readonly totalPhotosCount: number;
  /** Number of videos that will be uploaded */
  readonly totalVideosCount: number;
  /** Contain photos URI for show up in uploading process */
  readonly photosURI: string[][];
  /** Contain videos URI for show up in uploading process */
  readonly videosURI: string[][];
  /** Contain inventory photos URI for show up in uploading process */
  readonly inventoryPhotosURI: { [skuKey: string]: any[] };
  /** Contain signature photos URI for show up in uploading process */
  readonly signaturePhotosURI: string[];
  /** Contain selfie signature photos URI for show up in uploading process */
  readonly selfieSignaturePhotosURI: string[];
};

const initialState: UploadReportState = {
  firebasePromises: [],
  photoPromises: [],
  videoPromises: [],
  signaturePromises: [],
  selfieSignaturePromises: [],
  timer: null,
  status: 'inactive',
  isAnimating: false,
  isUploading: false,
  isCancelled: false,
  isTimedOut: false,
  uploadedPhotos: [],
  uploadedInventoryPhotos: {},
  uploadedSignatures: [],
  uploadedSelfieSignatures: [],
  uploadedVideos: [],
  uploadedPhotoCount: 0,
  uploadedVideoCount: 0,
  totalPhotosCount: 0,
  totalVideosCount: 0,
  photosURI: [],
  videosURI: [],
  inventoryPhotosURI: {},
  signaturePhotosURI: [],
  selfieSignaturePhotosURI: []
};

export function uploadreportReducer(
  state: UploadReportState = initialState,
  incomingAction: UploadReportAction
): UploadReportState {
  switch (incomingAction.type) {
    case SET_PHOTO_PROMISES:
      return {
        ...state,
        photoPromises: incomingAction.payload.arrPromise
      };
    case SET_VIDEO_PROMISES:
      return {
        ...state,
        videoPromises: incomingAction.payload.arrPromise
      };
    case SET_SIGNATURE_PROMISES:
      return {
        ...state,
        signaturePromises: incomingAction.payload.arrPromise
      };
    case SET_SELFIE_SIGNATURE_PROMISES:
      return {
        ...state,
        selfieSignaturePromises: incomingAction.payload.arrPromise
      };
    case SET_FIREBASE_PROMISES:
      return {
        ...state,
        firebasePromises: incomingAction.payload.arrPromise
      };
    case UPDATE_UPLOAD_STATUS:
      return {
        ...state,
        status: incomingAction.payload.status
      };
    case SET_PHOTOS_URI:
      return {
        ...state,
        photosURI: incomingAction.payload.photosURI
      };
    case SET_VIDEOS_URI:
      return {
        ...state,
        videosURI: incomingAction.payload.videosURI
      };
    case SET_INVENTORY_PHOTOS_URI:
      return {
        ...state,
        inventoryPhotosURI: incomingAction.payload.inventoryPhotosURI
      };
    case SET_SIGNATURE_PHOTOS_URI:
      return {
        ...state,
        signaturePhotosURI: incomingAction.payload.signaturePhotosURI
      };
    case SET_SELFIE_SIGNATURE_PHOTOS_URI:
      return {
        ...state,
        selfieSignaturePhotosURI: incomingAction.payload.selfieSignaturePhotosURI
      };
    case SET_IS_ANIMATING:
      return {
        ...state,
        isAnimating: incomingAction.payload.bool
      };
    case SET_IS_UPLOADING:
      return {
        ...state,
        isUploading: incomingAction.payload.bool
      };
    case SET_CANCEL_STATUS:
      return {
        ...state,
        isCancelled: incomingAction.payload.bool
      };
    case SET_IS_TIMEOUT:
      return {
        ...state,
        isTimedOut: incomingAction.payload.bool
      };
    case UPDATE_UPLOADED_PHOTOS:
      return update(state, {
        uploadedPhotos: {
          [incomingAction.payload.questionIndex]: {
            [incomingAction.payload.photoIndex]: { $set: incomingAction.payload.fileRef }
          }
        }
      });
    case UPDATE_UPLOADED_INVENTORY_PHOTOS:
      return update(state, {
        uploadedInventoryPhotos: {
          [incomingAction.payload.skuKey]: {
            [incomingAction.payload.photoIndex]: { $set: incomingAction.payload.fileRef }
          }
        }
      });
    case UPDATE_UPLOADED_VIDEOS:
      return update(state, {
        uploadedVideos: {
          [incomingAction.payload.questionIndex]: {
            [incomingAction.payload.videoIndex]: { $set: incomingAction.payload.fileRef }
          }
        }
      });
    case SET_UPLOADED_VIDEOS:
      return update(state, {
        uploadedVideos: { $set: incomingAction.payload.questionnaireVideos }
      });
    case SET_UPLOADED_PHOTOS:
      return update(state, {
        uploadedPhotos: { $set: incomingAction.payload.questionnairePhotos }
      });
    case SET_UPLOADED_INVENTORY_PHOTOS:
      return update(state, {
        uploadedInventoryPhotos: { $set: incomingAction.payload.inventoryPhotos }
      });
    case SET_UPLOADED_SIGNATURES:
      if (incomingAction.payload.signatures) {
        return {
          ...state,
          uploadedSignatures: incomingAction.payload.signatures
        };
      }
      return state;
    case SET_UPLOADED_SELFIE_SIGNATURES:
      return {
        ...state,
        uploadedSelfieSignatures: incomingAction.payload.selfieSignatures
      };
    case UPDATE_UPLOADED_SELFIE_SIGNATURES:
      return update(state, {
        uploadedSelfieSignatures: {
          [incomingAction.payload.index]: { path: { $set: incomingAction.payload.fileRef } }
        }
      });
    case UPDATE_UPLOADED_SIGNATURES:
      return update(state, {
        uploadedSignatures: {
          [incomingAction.payload.index]: { path: { $set: incomingAction.payload.fileRef } }
        }
      });
    case UPDATE_UPLOADED_PHOTO_COUNT:
      return update(state, {
        uploadedPhotoCount: { $set: Number(state.uploadedPhotoCount) + Number(incomingAction.payload.count) }
      });
    case UPDATE_UPLOADED_VIDEO_COUNT:
      return update(state, {
        uploadedVideoCount: { $set: Number(state.uploadedVideoCount) + Number(incomingAction.payload.count) }
      });
    case UPDATE_TOTAL_PHOTO_COUNT:
      return update(state, { totalPhotosCount: { $set: incomingAction.payload.sum } });
    case UPDATE_TOTAL_VIDEO_COUNT:
      return update(state, {
        totalVideosCount: { $set: incomingAction.payload.sum }
      });
    case RESET_UPLOADED_PHOTOS:
      return update(state, { uploadedPhotos: { $set: [] } });
    case RESET_UPLOADED_INVENTORY_PHOTOS:
      return update(state, { uploadedInventoryPhotos: { $set: {} } });
    case RESET_UPLOADED_VIDEOS:
      return update(state, { uploadedVideos: { $set: [] } });
    case RESET_UPLOADED_SIGNATURES:
      return update(state, { uploadedSignatures: { $set: [] } });
    case RESET_STATE:
      return {
        firebasePromises: [],
        photoPromises: [],
        videoPromises: [],
        signaturePromises: [],
        selfieSignaturePromises: [],
        timer: null,
        status: 'inactive',
        isAnimating: false,
        isUploading: false,
        isCancelled: false,
        isTimedOut: false,
        uploadedPhotos: [],
        uploadedInventoryPhotos: {},
        uploadedSignatures: [],
        uploadedSelfieSignatures: [],
        uploadedVideos: [],
        uploadedPhotoCount: 0,
        uploadedVideoCount: 0,
        totalPhotosCount: 0,
        totalVideosCount: 0,
        photosURI: [],
        videosURI: [],
        inventoryPhotosURI: {},
        signaturePhotosURI: [],
        selfieSignaturePhotosURI: []
      };
    default:
      return state;
  }
}
