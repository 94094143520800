import { action, createAsyncAction } from 'typesafe-actions';
import * as types from './questionnaire.actionType';
import { Questionnaires, QuestionnaireData, QuestionnaireOrdered } from './type';

export const setLoading = (value: boolean) => action(types.SET_LOADING, { value });

export const getPopulatedQuestionnairesAsync = createAsyncAction(
  types.GET_POPULATED_QUESTIONNAIRES_REQUEST,
  types.GET_POPULATED_QUESTIONNAIRES_SUCCESS,
  types.GET_POPULATED_QUESTIONNAIRES_FAILURE
)<undefined, { data: QuestionnaireData | null; ordered: QuestionnaireOrdered | null; index: any }, { error: string }>();

export const fetchQuestionnairesAsync = createAsyncAction(
  types.FETCH_QUESTIONNAIRES_REQUEST,
  types.FETCH_QUESTIONNAIRES_SUCCESS,
  types.FETCH_QUESTIONNAIRES_FAILURE
)<undefined, { data: Questionnaires | null }, { error: string }>();
