import React from 'react';
import { StyleSheet, Platform, TextStyle } from 'react-native';

import { QuestionnaireSwiperContainerProps } from './QuestionnaireSwiperContainer';
import fontMaker from '../../utils/font';
import QuestionnaireQuestionBinary from './QuestionnaireQuestionBinary';
import QuestionnaireQuestionChecklist from './QuestionnaireQuestionChecklist';
import QuestionnaireQuestionMultipleChoice from './QuestionnaireQuestionMultipleChoice';
import QuestionnaireQuestionMultipleChoiceScore from './QuestionnaireQuestionMultipleChoiceScore';
import QuestionnaireQuestionNumber from './QuestionnaireQuestionNumber';
import QuestionnaireQuestionInventory from './QuestionnaireQuestionInventory';
import QuestionnaireQuestionInventoryNew from './QuestionnaireQuestionInventoryNew';
import QuestionnaireQuestionOpen from './QuestionnaireQuestionOpen';
import QuestionnaireQuestionScore from './QuestionnaireQuestionScore';
import QuestionnaireQuestionRangeFlag from './QuestionnaireQuestionRangeFlag';
import QuestionnaireQuestionCompetitorAnalysis from './QuestionnaireQuestionCompetitorAnalysis';

export type QuestionnaireQuestionProps = Pick<
  QuestionnaireSwiperContainerProps,
  | 'query'
  | 'questions'
  | 'question'
  | 'index'
  | 'language'
  | 'siteSKUs'
  | 'skuQuery'
  | 'calibratingSKU'
  | 'inventoryIn'
  | 'inventoryFinal'
  | 'skuFinal'
  | 'skuIn'
  | 'scrollRef'
  | 'currentConditionIndex'
  | 'commentInputRef'
  | 'numberInputRef'
  | 'inventoryCardRefs'
  | 'competitorAnalysisInputRef'
  | 'currentInventory'
  | 'allowAuditorStockIn'
  | 'handleSelectChoice'
  | 'handleSelectWeightedChoice'
  | 'handleSelectChecklist'
  | 'handleInputComment'
  | 'currentQuestionIndex'
  | 'isTutorialMode'
  | 'isShowTooltipNA'
  | 'isShowQuestionTutorialModal'
  | 'skuCommentVisible'
  | 'keyboardShown'
  | 'tutorialQuestionType'
  | 'handleTextInputFocused'
  | 'handleTextInputBlurred'
  | 'handleInputDecimal'
  | 'handleNumberInputFocused'
  | 'handleNumberInputBlurred'
  | 'handleInputScore'
  | 'handleInputInventory'
  | 'handleClearSKUQuery'
  | 'handleInputSKUQuery'
  | 'handleInputSKUInventory'
  | 'handleInventoryInputFocused'
  | 'handleInventoryInputBlurred'
  | 'handleInputCompetitorAnalysis'
  | 'handleCompetitorAnalysisInputFocused'
  | 'handleCompetitorAnalysisInputBlurred'
  | 'handleConfirmEditPhoto'
  | 'handleToggleSKUComment'
  | 'handleActivateImagePicker'
  | 'handleShowTutorialQuestionModal'
  | 'handleChangeTutorialQuestionType'
  | 'handleShowTooltipNA'
  | 'handleNavCreateCompany'
  | 'handleDismissTutorialQuestionModal'
  | 'handleDismissTutorialMode'
>;
const QuestionnaireQuestion = (props: QuestionnaireQuestionProps) => {
  switch (props.question.type) {
    case 'binary':
    case 'binary-with-red-flag':
      return (
        <QuestionnaireQuestionBinary
          styles={styles}
          question={props.question}
          index={props.index}
          currentConditionIndex={props.currentConditionIndex}
          language={props.language}
          questions={props.questions}
          commentInputRef={props.commentInputRef}
          tutorialQuestionType={props.tutorialQuestionType}
          currentQuestionIndex={props.currentQuestionIndex}
          isTutorialMode={props.isTutorialMode}
          isShowQuestionTutorialModal={props.isShowQuestionTutorialModal}
          handleInputComment={props.handleInputComment}
          handleTextInputFocused={props.handleTextInputFocused}
          handleTextInputBlurred={props.handleTextInputBlurred}
          handleSelectChoice={props.handleSelectChoice}
          handleShowTutorialQuestionModal={props.handleShowTutorialQuestionModal}
          handleChangeTutorialQuestionType={props.handleChangeTutorialQuestionType}
          handleDismissTutorialQuestionModal={props.handleDismissTutorialQuestionModal}
          handleDismissTutorialMode={props.handleDismissTutorialMode}
        />
      );
    case 'multiple-choice':
      return (
        <QuestionnaireQuestionMultipleChoice
          styles={styles}
          question={props.question}
          questions={props.questions}
          language={props.language}
          index={props.index}
          currentConditionIndex={props.currentConditionIndex}
          commentInputRef={props.commentInputRef}
          currentQuestionIndex={props.currentQuestionIndex}
          isTutorialMode={props.isTutorialMode}
          handleSelectChoice={props.handleSelectChoice}
          handleTextInputFocused={props.handleTextInputFocused}
          handleTextInputBlurred={props.handleTextInputBlurred}
          handleInputComment={props.handleInputComment}
          handleShowTutorialQuestionModal={props.handleShowTutorialQuestionModal}
          handleChangeTutorialQuestionType={props.handleChangeTutorialQuestionType}
        />
      );
    case 'multiple-choice-score':
      return (
        <QuestionnaireQuestionMultipleChoiceScore
          styles={styles}
          question={props.question}
          questions={props.questions}
          language={props.language}
          index={props.index}
          currentConditionIndex={props.currentConditionIndex}
          commentInputRef={props.commentInputRef}
          currentQuestionIndex={props.currentQuestionIndex}
          isTutorialMode={props.isTutorialMode}
          handleSelectWeightedChoice={props.handleSelectWeightedChoice}
          handleTextInputFocused={props.handleTextInputFocused}
          handleTextInputBlurred={props.handleTextInputBlurred}
          handleInputComment={props.handleInputComment}
          handleShowTutorialQuestionModal={props.handleShowTutorialQuestionModal}
          handleChangeTutorialQuestionType={props.handleChangeTutorialQuestionType}
        />
      );
    case 'checklist':
      return (
        <QuestionnaireQuestionChecklist
          styles={styles}
          question={props.question}
          questions={props.questions}
          language={props.language}
          index={props.index}
          currentConditionIndex={props.currentConditionIndex}
          commentInputRef={props.commentInputRef}
          isTutorialMode={props.isTutorialMode}
          currentQuestionIndex={props.currentQuestionIndex}
          handleSelectChecklist={props.handleSelectChecklist}
          handleTextInputFocused={props.handleTextInputFocused}
          handleTextInputBlurred={props.handleTextInputBlurred}
          handleInputComment={props.handleInputComment}
          handleShowTutorialQuestionModal={props.handleShowTutorialQuestionModal}
          handleChangeTutorialQuestionType={props.handleChangeTutorialQuestionType}
        />
      );
    case 'number':
      return (
        <QuestionnaireQuestionNumber
          styles={styles}
          question={props.question}
          index={props.index}
          currentConditionIndex={props.currentConditionIndex}
          language={props.language}
          numberInputRef={props.numberInputRef}
          commentInputRef={props.commentInputRef}
          handleInputDecimal={props.handleInputDecimal}
          tutorialQuestionType={props.tutorialQuestionType}
          currentQuestionIndex={props.currentQuestionIndex}
          isTutorialMode={props.isTutorialMode}
          isShowQuestionTutorialModal={props.isShowQuestionTutorialModal}
          handleTextInputFocused={props.handleTextInputFocused}
          handleTextInputBlurred={props.handleTextInputBlurred}
          handleSelectChoice={props.handleSelectChoice}
          handleInputComment={props.handleInputComment}
          handleNumberInputFocused={props.handleNumberInputFocused}
          handleNumberInputBlurred={props.handleNumberInputBlurred}
          handleShowTutorialQuestionModal={props.handleShowTutorialQuestionModal}
          handleChangeTutorialQuestionType={props.handleChangeTutorialQuestionType}
          handleDismissTutorialQuestionModal={props.handleDismissTutorialQuestionModal}
          handleDismissTutorialMode={props.handleDismissTutorialMode}
        />
      );
    case 'score':
      return (
        <QuestionnaireQuestionScore
          styles={styles}
          question={props.question}
          index={props.index}
          currentConditionIndex={props.currentConditionIndex}
          language={props.language}
          commentInputRef={props.commentInputRef}
          currentQuestionIndex={props.currentQuestionIndex}
          isTutorialMode={props.isTutorialMode}
          handleInputComment={props.handleInputComment}
          handleTextInputFocused={props.handleTextInputFocused}
          handleTextInputBlurred={props.handleTextInputBlurred}
          handleInputScore={props.handleInputScore}
          handleSelectChoice={props.handleSelectChoice}
          handleShowTutorialQuestionModal={props.handleShowTutorialQuestionModal}
          handleChangeTutorialQuestionType={props.handleChangeTutorialQuestionType}
        />
      );
    case 'range-flag':
      return (
        <QuestionnaireQuestionRangeFlag
          styles={styles}
          question={props.question}
          index={props.index}
          currentConditionIndex={props.currentConditionIndex}
          language={props.language}
          numberInputRef={props.numberInputRef}
          commentInputRef={props.commentInputRef}
          currentQuestionIndex={props.currentQuestionIndex}
          isTutorialMode={props.isTutorialMode}
          handleSelectChoice={props.handleSelectChoice}
          handleInputComment={props.handleInputComment}
          handleTextInputFocused={props.handleTextInputFocused}
          handleTextInputBlurred={props.handleTextInputBlurred}
          handleInputDecimal={props.handleInputDecimal}
          handleNumberInputFocused={props.handleNumberInputFocused}
          handleNumberInputBlurred={props.handleNumberInputBlurred}
          handleShowTutorialQuestionModal={props.handleShowTutorialQuestionModal}
          handleChangeTutorialQuestionType={props.handleChangeTutorialQuestionType}
        />
      );
    case 'inventory':
      const startInventory =
        props.currentInventory && props.currentInventory[props.question.sku]
          ? props.currentInventory[props.question.sku].final
          : 0;
      let inventoryOutNumber = 0;
      if (props.question.inventory) {
        const inInventory = props.question.inventory.in === -1 ? 0 : props.question.inventory.in;
        const finalInventory = props.question.inventory.final === -1 ? 0 : props.question.inventory.final;
        const intermediate = (startInventory + inInventory - finalInventory).toFixed(2);
        inventoryOutNumber = parseFloat(intermediate);
        return (
          <QuestionnaireQuestionInventory
            styles={styles}
            inventoryOutNumber={inventoryOutNumber}
            startInventory={startInventory}
            question={props.question}
            index={props.index}
            language={props.language}
            inventoryIn={props.inventoryIn}
            inventoryFinal={props.inventoryFinal}
            numberInputRef={props.numberInputRef}
            commentInputRef={props.commentInputRef}
            handleInputInventory={props.handleInputInventory}
            handleInputComment={props.handleInputComment}
            handleTextInputFocused={props.handleTextInputFocused}
            handleTextInputBlurred={props.handleTextInputBlurred}
            handleNumberInputFocused={props.handleNumberInputFocused}
            handleNumberInputBlurred={props.handleNumberInputBlurred}
          />
        );
      } else {
        return null;
      }
    case 'inventory-v2':
      return (
        <QuestionnaireQuestionInventoryNew
          question={props.question}
          index={props.index}
          siteSKUs={props.siteSKUs}
          skuQuery={props.skuQuery}
          calibratingSKU={props.calibratingSKU}
          currentQuestionIndex={props.currentQuestionIndex}
          allowAuditorStockIn={props.allowAuditorStockIn}
          keyboardShown={props.keyboardShown}
          language={props.language}
          query={props.query}
          skuFinal={props.skuFinal}
          skuIn={props.skuIn}
          skuCommentVisible={props.skuCommentVisible}
          tutorialQuestionType={props.tutorialQuestionType}
          scrollRef={props.scrollRef}
          inventoryCardRefs={props.inventoryCardRefs}
          isShowQuestionTutorialModal={props.isShowQuestionTutorialModal}
          isTutorialMode={props.isTutorialMode}
          handleConfirmEditPhoto={props.handleConfirmEditPhoto}
          handleToggleSKUComment={props.handleToggleSKUComment}
          handleActivateImagePicker={props.handleActivateImagePicker}
          handleClearSKUQuery={props.handleClearSKUQuery}
          handleInputSKUQuery={props.handleInputSKUQuery}
          handleSelectChoice={props.handleSelectChoice}
          handleInputSKUInventory={props.handleInputSKUInventory}
          handleInventoryInputFocused={props.handleInventoryInputFocused}
          handleInventoryInputBlurred={props.handleInventoryInputBlurred}
          handleShowTutorialQuestionModal={props.handleShowTutorialQuestionModal}
          handleChangeTutorialQuestionType={props.handleChangeTutorialQuestionType}
          handleDismissTutorialQuestionModal={props.handleDismissTutorialQuestionModal}
          handleDismissTutorialMode={props.handleDismissTutorialMode}
        />
      );
    case 'open':
      return (
        <QuestionnaireQuestionOpen
          styles={styles}
          question={props.question}
          index={props.index}
          currentConditionIndex={props.currentConditionIndex}
          handleSelectChoice={props.handleSelectChoice}
          language={props.language}
          handleInputComment={props.handleInputComment}
          commentInputRef={props.commentInputRef}
          tutorialQuestionType={props.tutorialQuestionType}
          currentQuestionIndex={props.currentQuestionIndex}
          isTutorialMode={props.isTutorialMode}
          isShowTooltipNA={props.isShowTooltipNA}
          isShowQuestionTutorialModal={props.isShowQuestionTutorialModal}
          handleTextInputFocused={props.handleTextInputFocused}
          handleTextInputBlurred={props.handleTextInputBlurred}
          handleShowTutorialQuestionModal={props.handleShowTutorialQuestionModal}
          handleChangeTutorialQuestionType={props.handleChangeTutorialQuestionType}
          handleShowTooltipNA={props.handleShowTooltipNA}
          handleDismissTutorialQuestionModal={props.handleDismissTutorialQuestionModal}
          handleDismissTutorialMode={props.handleDismissTutorialMode}
        />
      );
    case 'competitor':
      return (
        <QuestionnaireQuestionCompetitorAnalysis
          question={props.question}
          index={props.index}
          currentQuestionIndex={props.currentQuestionIndex}
          language={props.language}
          query={props.query}
          competitorAnalysisInputRef={props.competitorAnalysisInputRef}
          handleInputCompetitorAnalysis={props.handleInputCompetitorAnalysis}
          handleCompetitorAnalysisInputFocused={props.handleCompetitorAnalysisInputFocused}
          handleCompetitorAnalysisInputBlurred={props.handleCompetitorAnalysisInputBlurred}
          handleActivateImagePicker={props.handleActivateImagePicker}
          handleConfirmEditPhoto={props.handleConfirmEditPhoto}
          handleNavCreateCompany={props.handleNavCreateCompany}
        />
      );
    default:
      return null;
  }
};

export default QuestionnaireQuestion;

const styles = StyleSheet.create({
  choicesList: {
    marginBottom: 15,
    borderTopWidth: 0.5,
    borderTopColor: 'rgba(0, 0, 0, 0.05)'
  },
  choiceFlag: {
    marginRight: 10,
    paddingHorizontal: 15,
    paddingVertical: 10,
    backgroundColor: 'rgba(0, 0, 0, 0.01)'
  },
  noFlag: {
    width: 12.5,
    height: 30,
    backgroundColor: '#fff'
  },
  redFlag: {
    width: 12.5,
    height: 30,
    backgroundColor: '#f93c1c'
  },
  yellowFlag: {
    width: 12.5,
    height: 30,
    backgroundColor: '#ffa040'
  },
  greenFlag: {
    width: 12.5,
    height: 30,
    backgroundColor: '#009e43'
  },
  choiceText: {
    ...fontMaker({ weight: 'SemiBold' }),
    color: '#535353',
    textAlign: 'center'
  },
  choiceSelectedText: {
    color: '#fff'
  },
  choice: {
    paddingRight: 10,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderStyle: 'solid',
    borderBottomWidth: 0.5,
    borderBottomColor: 'rgba(0, 0, 0, 0.05)'
  },
  choiceSelected: {
    backgroundColor: '#535353'
  },
  commentDisabled: {
    backgroundColor: '#efeeed'
  },
  openAnswerInput: {
    maxHeight: 96,
    ...fontMaker({ weight: 'Regular' }),
    color: '#535353',
    textAlignVertical: 'top',
    padding: 10
  },
  openAnswerInputContainer: {
    justifyContent: 'flex-start',
    marginHorizontal: 20,
    paddingVertical: 10,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 6
  },
  validationContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 20,
    paddingVertical: 4
  },
  validationRangeContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 23,
    paddingVertical: 4
  },
  textValidation: {
    color: '#ea3c53'
  },
  openCommentInput: {
    maxHeight: 96,
    ...fontMaker({ weight: 'Regular' }),
    color: '#535353',
    textAlignVertical: 'top',
    padding: 10
  },
  inventoryValidationContainer: {
    marginBottom: 10
  },
  rangeFlagValidationContainer: {
    marginBottom: 10
  },
  questionKeyboardPadding: {
    height: 5,
    paddingVertical: 100
  }
});
