export const FETCH_WEEKLY_COMPLETION_REQUEST = '@profileStatistic/FETCH_WEEKLY_COMPLETION_REQUEST';
export const FETCH_WEEKLY_COMPLETION_SUCCESS = '@profileStatistic/FETCH_WEEKLY_COMPLETION_SUCCESS';
export const FETCH_WEEKLY_COMPLETION_FAILURE = '@profileStatistic/FETCH_WEEKLY_COMPLETION_FAILURE';

export const FETCH_WEEKLY_FLAG_OVERVIEW_REQUEST = '@profileStatistic/FETCH_WEEKLY_FLAG_OVERVIEW_REQUEST';
export const FETCH_WEEKLY_FLAG_OVERVIEW_SUCCESS = '@profileStatistic/FETCH_WEEKLY_FLAG_OVERVIEW_SUCCESS';
export const FETCH_WEEKLY_FLAG_OVERVIEW_FAILURE = '@profileStatistic/FETCH_WEEKLY_FLAG_OVERVIEW_FAILURE';

export const FETCH_MONTHLY_COMPLETION_REQUEST = '@profileStatistic/FETCH_MONTHLY_COMPLETION_REQUEST';
export const FETCH_MONTHLY_COMPLETION_SUCCESS = '@profileStatistic/FETCH_MONTHLY_COMPLETION_SUCCESS';
export const FETCH_MONTHLY_COMPLETION_FAILURE = '@profileStatistic/FETCH_MONTHLY_COMPLETION_FAILURE';

export const FETCH_MONTHLY_FLAG_OVERVIEW_REQUEST = '@profileStatistic/FETCH_MONTHLY_FLAG_OVERVIEW_REQUEST';
export const FETCH_MONTHLY_FLAG_OVERVIEW_SUCCESS = '@profileStatistic/FETCH_MONTHLY_FLAG_OVERVIEW_SUCCESS';
export const FETCH_MONTHLY_FLAG_OVERVIEW_FAILURE = '@profileStatistic/FETCH_MONTHLY_FLAG_OVERVIEW_FAILURE';
