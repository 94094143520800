import { ActionType, createReducer } from 'typesafe-actions';
import * as actions from './messageBoard.action';
import { MessageBoard } from 'nimbly-common';

export type MessageBoardAction = ActionType<typeof actions>;
type SiteMessageBoard = { [siteID: string]: MessageBoard[] | null };

type MessageBoardState = {
  siteMessageBoard: SiteMessageBoard;
  isLoading: boolean;
  error: string | null;
};

const initialState: MessageBoardState = {
  siteMessageBoard: {} as SiteMessageBoard,
  isLoading: false,
  error: null
};

export const messageBoardReducer = createReducer<MessageBoardState, MessageBoardAction>(initialState)
  .handleAction(actions.fetchSiteMessageBoardAsync.request, state => ({
    ...state,
    isLoading: true
  }))
  .handleAction(actions.fetchSiteMessageBoardAsync.success, (state, action) => ({
    ...state,
    siteMessageBoard: { ...state.siteMessageBoard, [action.payload.siteID]: action.payload.data },
    isLoading: false,
    error: null
  }))
  .handleAction(actions.fetchSiteMessageBoardAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload.message
  }));
