import { action, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';

import theme from '../../constants/theme';

export const CREATE = '@toast/CREATE';
export const DISMISS = '@toast/CLOSE';

/**
 * Create a Toast with 3 parameters:
 * @param message as a string
 * @param showDismissButton Toggle showing the 'OK' dismiss button
 * @param duration (in ms, 0 is infinite) to auto-dismiss
 * @param top position
 * @param backgroundColor for background color
 * @param color for text color
 */
export const createToast = (
  message: string,
  showDismissButton?: boolean,
  duration?: number,
  top?: boolean,
  backgroundColor?: string,
  color?: string
) =>
  action(CREATE, {
    message,
    showDismissButton,
    duration,
    top,
    backgroundColor,
    color
  });

/**
 * Close currently active toast
 */
export const closeToast = () => action(DISMISS);

export const toastActions = { createToast, closeToast };

export type ToastAction = ActionType<typeof toastActions>;
export type ToastState = {
  readonly show: boolean;
  readonly message: string;
  readonly showDismissButton: boolean;
  readonly duration: number;
  readonly top: boolean;
  readonly backgroundColor: any;
  readonly color: any;
};

export const initialState: ToastState = {
  show: false,
  message: '',
  showDismissButton: false,
  duration: 0,
  top: false,
  backgroundColor: theme.styles.backgroundDark.backgroundColor,
  color: '#fff'
};

export const toastReducer: Reducer<ToastState, ToastAction> = (
  state: ToastState = initialState,
  incomingAction: ToastAction
) => {
  switch (incomingAction.type) {
    case CREATE:
      const {
        payload: { message, showDismissButton, duration, top, backgroundColor, color }
      } = incomingAction;
      return {
        ...state,
        show: true,
        message: message,
        showDismissButton: showDismissButton || false,
        duration: duration || 0,
        top: !!top,
        backgroundColor: backgroundColor ? backgroundColor : theme.styles.backgroundDark.backgroundColor,
        color: color ? color : '#fff'
      };
    case DISMISS:
      return {
        ...state,
        show: false
      };
    default:
      return state;
  }
};
