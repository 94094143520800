import React from 'react';

import { StyleSheet, Platform, View, StatusBar, ViewStyle } from 'react-native';

export interface AppStatusBarProps {
  backgroundColor: string;
  barStyle?: any;
}

// Cross-platform status bar
const AppStatusBar: React.SFC<AppStatusBarProps> = props => {
  const { backgroundColor } = props;

  const finalStyles: any = [styles.default];
  if (backgroundColor) {
    finalStyles.push({ backgroundColor });
  }

  return (
    <View style={finalStyles}>
      <StatusBar translucent={true} backgroundColor={backgroundColor} {...props} />
    </View>
  );
};

export default AppStatusBar;

const styles = StyleSheet.create({
  default: Platform.select({
    ios: {
      position: 'absolute'
    },
    android: {
      position: undefined
    }
  }) as ViewStyle
});
