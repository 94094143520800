/**
 * Utils used for converting the value of flag into shown words in the view
 */
export default function _renderFlagText(flag: string) {
  switch (flag) {
    case 'green-flag':
      return 'Green Flag';
    case 'yellow-flag':
      return 'Yellow Flag';
    case 'red-flag':
      return 'Red Flag';
    default:
      return 'Error';
  }
}
