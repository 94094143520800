import React from 'react';
import { Dimensions, StyleSheet, View } from 'react-native';
import AppButton from '../../global/RoundedButton';
import fontMaker from '../../../utils/font';
import theme from '../../../constants/theme';
import { SCREEN_WIDTH } from '../../../utils/screensize';

interface Props {
  disabled: boolean;
  /**
   * Text for reset button
   */
  resetTitle: string;
  /**
   * Text for continue button
   */
  continueTitle: string;
  onPressReset: () => void;
  onPressContinue: () => void;
}

export default function ActionButtonDraft(props: Props) {
  return (
    <View style={{ flexDirection: 'row', flex: 1, justifyContent: 'space-between' }}>
      <AppButton
        disabled={props.disabled}
        onPress={props.onPressReset}
        title={props.resetTitle}
        backgroundColor="#56C768"
        style={styles.button}
        inverse={true}
        testID="outlet-delete-draft-report"
      />
      <AppButton
        disabled={props.disabled}
        onPress={props.onPressContinue}
        backgroundColor="#56C768"
        title={props.continueTitle}
        testID="outlet-resume-report"
        style={styles.button}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 11
  },
  footerText: {
    marginHorizontal: Dimensions.get('window').width * 0.05,
    ...fontMaker({ weight: 'Light' }),
    textAlign: 'center'
  },
  footerTextOffline: {
    marginHorizontal: Dimensions.get('window').width * 0.05,
    ...fontMaker({ weight: 'SemiBold' }),
    textAlign: 'center',
    color: '#ed5565'
  },
  inactiveText: {
    ...fontMaker({ weight: 'Light' }),
    fontSize: 14
  },
  questionnaireContainer: {
    width: '100%',
    borderRadius: 4,
    backgroundColor: theme.colors.light,
    borderColor: theme.colors.light,
    borderWidth: StyleSheet.hairlineWidth,
    paddingVertical: 10,
    flexDirection: 'row',
    alignItems: 'center'
  },
  iconContainer: {
    paddingHorizontal: 8
  },
  button: {
    flex: 1,
    marginHorizontal: 4
  }
});
