import React, { PureComponent } from 'react';
import {
  Dimensions,
  Image,
  Modal,
  Platform,
  StyleSheet,
  TouchableNativeFeedback,
  TouchableOpacity,
  View
} from 'react-native';

import CloseButton from '../../assets/icons/close-button.png';
import fontMaker from '../../utils/font';
import { SCREEN_WIDTH, widthPercentageToDP as wp } from '../../utils/screensize';
import Text from '../global/Text';
import FilePicker from './FilePicker';

export type ModalOption = {
  text: { [language: string]: string } | string;
  action: () => any;
  style: 'cancel' | 'destructive' | 'confirm';
};

export type ModalWarningConfig = {
  title: string;
  subtitle: string;
  options: ModalOption[];
};

type ConfirmModalProps = {
  isVisible: boolean;
  language?: string;
  title: string;
  subtitle: string;
  options: ModalOption[];
  onCloseModal?: () => any;
  onConfirmDelete?: () => any;
};

class ConfirmModal extends PureComponent<ConfirmModalProps> {
  handleCloseModal = () => {
    this.props.onCloseModal();
  };

  renderText = (option: any | { [language: string]: string }) => {
    if (typeof option === 'string') {
      return option;
    }
    return option.text;
  };

  render() {
    const { language, title, subtitle, options, translation } = this.props;

    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={this.props.isVisible}
        onRequestClose={this.handleCloseModal!}
      >
        <View style={styles.overlay}>
          <View style={styles.container}>
            <View style={styles.iconContainer}>
              <TouchableOpacity onPress={() => this.props.onCloseModalWarning()} testID="outlet-footer-close">
                <Image source={CloseButton} style={styles.icon} />
              </TouchableOpacity>
            </View>
            <View style={styles.header}>
              <Text style={styles.headerTitle}>{this.renderText(title)}</Text>
              <Text style={styles.headerSubtitle}>{this.renderText(subtitle)}</Text>
            </View>
            <View style={styles.actionContainer}>
              <View style={styles.tab}>
                <Touchable style={styles.row} delayPressIn={0}>
                  <View style={styles.row}>
                    <FilePicker
                      onComplete={props => this.props.selectNewFile(props.currentConditionIndex, props)}
                      testID="new-file"
                    >
                      <TouchableOpacity style={styles.button}>
                        <Text style={styles.fontButton}> {translation('questionnaire:prompt.warningNewFile')} </Text>
                      </TouchableOpacity>
                    </FilePicker>
                  </View>
                </Touchable>
              </View>
              <View style={styles.tab}>
                <Touchable style={styles.row} delayPressIn={0}>
                  <View style={styles.row}>
                    <TouchableOpacity onPress={this.props.onCloseModalWarning} style={styles.buttonCancel}>
                      <Text style={styles.fontCancel}> {translation('questionnaire:prompt.warningCancel')} </Text>
                    </TouchableOpacity>
                  </View>
                </Touchable>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    );
  }
}

const Touchable: any = Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

const { width, height } = Dimensions.get('window');

const styles = StyleSheet.create({
  // Modal
  overlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  container: {
    alignItems: 'flex-end',
    backgroundColor: '#fff',
    width: width * 0.7,
    maxWidth: 450,
    borderRadius: 5,
    maxHeight: height * 0.7,
    overflow: 'hidden'
  },
  header: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 15,
    paddingBottom: 16,
    paddingHorizontal: 25
  },
  headerTitle: { ...fontMaker({ weight: 'SemiBold' }), fontSize: 18, marginBottom: 20, fontWeight: 'bold' },
  headerSubtitle: {
    ...fontMaker({ weight: 'Light' }),
    textAlign: 'center',
    marginVertical: 12
  },
  actionContainer: {
    marginHorizontal: 'auto',
    textAlign: 'center',
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  row: {
    width: '100%'
  },
  tab: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20
  },
  option: {
    width: '100%',
    textAlign: 'center'
  },
  boldOption: {
    ...fontMaker({ weight: 'SemiBold' })
  },
  icon: {
    marginBottom: SCREEN_WIDTH > 500 ? wp(0.1) : wp(2),
    height: SCREEN_WIDTH > 500 ? wp(1.8) : wp(7),
    width: SCREEN_WIDTH > 500 ? wp(1.8) : wp(7)
  },
  iconContainer: {
    paddingRight: 15,
    paddingTop: 10
  },
  button: {
    width: 235,
    height: 48,
    borderRadius: 25,
    backgroundColor: '#56C768',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonCancel: {
    width: 235,
    height: 48,
    borderRadius: 25,
    backgroundColor: '#FFF',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fontButton: {
    fontSize: 16,
    fontWeight: '600',
    color: '#FFF'
  },
  fontCancel: {
    fontSize: 16,
    fontWeight: '600',
    color: '#56C768'
  }
});

export default ConfirmModal;
