import { useState, useEffect } from 'react';
import moment from 'moment';
import firebase from 'firebase';

// utils
import { getTodayString } from '../utils/schedule';
import { currentPeriodStartDate, currentPeriodEndDate } from '../../../utils/schedule';

// types
import { OrganizationSchedule } from '../../../utils/classes';

const usePeriodDate = (organizationSchedule: OrganizationSchedule) => {
  const [startDate, setStartDate] = useState(() => getTodayString());
  const [endDate, setEndDate] = useState(() => moment().isoWeekday(7).format('YYYY-MM-DD'));
  const [dateReady, setDateReady] = useState(false);

  useEffect(() => {
    if (!organizationSchedule) {
      return;
    }

    const start = currentPeriodStartDate(organizationSchedule) || moment();
    const end = currentPeriodEndDate(organizationSchedule, start)! || moment();
    const startString = start.format('YYYY-MM-DD');
    const endString = end.format('YYYY-MM-DD');

    setStartDate(startString);
    setEndDate(endString);
    setDateReady(true);
  }, [organizationSchedule]);

  return { startDate, endDate, dateReady };
};

export default usePeriodDate;
