import { Question, OrganizationSKU, SiteSKU } from '../../../utils/classes';
import { questionComplete, questionSKUComplete } from '../../../utils/report';
import { SKUsTutorialData } from '../assets/SKUsTutorialData';

/**
 * Get questions with type inventory
 *
 * @param questions questions array
 * @param currentInventory currently selected inventory
 * @param siteSKUs
 * @param calibratingSKU
 */
function filterInventoryQuestions(
  questions: Question[],
  currentInventory: any,
  siteSKUs:
    | {
        [sku: string]: SiteSKU & {
          skuDetail: OrganizationSKU;
        };
      }
    | null
    | undefined,
  calibratingSKU: { [skuKey: string]: boolean } | null,
  isTutorialMode: boolean,
  hasVideoAttachment?: boolean
): Question[] {
  const filteredQuestions = questions.filter(question => {
    let inventoryOutNumber = 0;
    if (question.inventory && question.type === 'inventory') {
      const inInventory = question.inventory.in === -1 ? 0 : question.inventory.in;
      const finalInventory = question.inventory.final === -1 ? 0 : question.inventory.final;
      inventoryOutNumber =
        (currentInventory && currentInventory[question.sku] ? currentInventory[question.sku].final : 0) +
        inInventory -
        finalInventory;
    } else if (question.skuInventory && question.type === 'inventory-v2') {
      let selectedSiteSKUs = siteSKUs!;
      if (isTutorialMode) {
        selectedSiteSKUs = SKUsTutorialData;
      }

      const output = questionSKUComplete(question, selectedSiteSKUs, calibratingSKU || {});
      return output;
    }

    return questionComplete(question, inventoryOutNumber, hasVideoAttachment);
  });
  return filteredQuestions;
}

export default filterInventoryQuestions;
