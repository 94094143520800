import { Platform } from 'react-native';
// import Permissions from 'react-native-permissions';

/**
 * Check whether location permission is granted or not according to the permission status
 */
export default async function checkLocationPermission() {
  // const permission1 =
  //   Platform.OS === 'android'
  //     ? await Permissions.request('android.permission.ACCESS_FINE_LOCATION')
  //     : await Permissions.request('ios.permission.LOCATION_ALWAYS');
  // const permission2 =
  //   Platform.OS === 'android'
  //     ? await Permissions.request('android.permission.ACCESS_COARSE_LOCATION')
  //     : await Permissions.request('ios.permission.LOCATION_WHEN_IN_USE');
  // if (permission1 === 'granted' && permission2 === 'granted') {
  //   return true;
  // }
  return false;
}
