import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { BottomTabBarOptions } from '@react-navigation/bottom-tabs/src/types';
import { useNavigation } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { StackNavigationOptions } from '@react-navigation/stack/src/types';
import firebase from 'firebase';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useDispatch } from 'react-redux';
// components
import HeaderBar from './components/global/HeaderBar';
import NavigationTitle from './components/global/NavigationTitle';
import QuestionnaireDrawerButton from './components/questionnaire/QuestionnaireDrawerButton';
// type
import { SelectedSite } from './components/site/Site/Outlet/type';
import NewSiteScreen from './components/site/Site/SiteNew';
import CalibrationPage from './routes/calibration';
import CreateCompanyPage from './routes/createcompany';
import HomeTab from './routes/home';
import JourneyPage from './routes/journey';
import LandingPage from './routes/landing';
import LicensesPage from './routes/licenses';
import LobbyPage from './routes/lobby';
import PayoffPage from './routes/payoff';
import QuestionnairePage from './routes/questionnaire';
import QuestionReferencePage from './routes/questionreference';
import SetPasswordPage from './routes/setpassword';
import SettingsTab from './routes/settings';
import SiteReportFilter from './routes/SiteReportFilter';
import SubmitReportPage from './routes/submitreport';
import TutorialsPage from './routes/tutorials';
import ScheduleTab from './routes/schedule';
import UploadReportPage from './routes/uploadreport';
import WebViewPage from './routes/webview';
import { setActiveTab } from './store/reducers/site/site';
// redux
import * as siteReportActions from './store/reducers/siteReport/siteReport.action';
import { User } from './utils/classes';
import fontMaker from './utils/font';
import { SCREEN_WIDTH } from './utils/screensize';

const getTabBarVisibility = (route: any): boolean => {
  try {
    if (
      !route?.state?.stale &&
      (route?.state?.index > 0 ||
        route?.state?.routes[0]?.name === 'Payoff' ||
        route?.state?.routes[0]?.name === 'SubmitReport')
    ) {
      return false;
    }
    return true;
  } catch {
    return true;
  }
};

const defaultStackOptions: StackNavigationOptions = {
  gestureEnabled: false,
  headerBackTitleVisible: false,
  headerTintColor: '#ffffff',
  headerStyle: {
    borderBottomWidth: 0,
    backgroundColor: '#3cd070',
    /* Shadow property for topTabBar (iOS only) */
    shadowColor: '#919191',
    shadowRadius: 5,
    shadowOpacity: 1,
    shadowOffset: { width: 1, height: 1 }
  },
  headerTitleStyle: {
    color: '#ffffff',
    fontFamily: 'Poppins',
    ...fontMaker({ weight: 'SemiBold' }),
    textAlign: 'center',
    fontSize: 10
  },
  headerTitleAlign: 'center',
  headerRight: () => <View />
};

export type AuthStackParamList = {
  Landing: undefined;
  SetPassword: undefined;
};

const AuthStack = createStackNavigator();

export const AuthStackNavigator: React.ComponentType<any> = () => {
  return (
    <AuthStack.Navigator initialRouteName="Landing" screenOptions={defaultStackOptions}>
      <AuthStack.Screen name="Landing" component={LandingPage} options={{ headerShown: false }} />
      <AuthStack.Screen
        name="SetPassword"
        component={SetPasswordPage}
        options={() => ({
          headerShown: false
        })}
      />
    </AuthStack.Navigator>
  );
};

export type WebViewStackParams = {
  WebView: { profileColor: string; title: string; url: string; purpose: string; injectedJsScript: string };
};

export type HomeStackParamList = {
  Home: undefined;
  Journey: { profileColor: string; originTab: string; isDueToday: boolean };
  Site: { profileColor: string; originTab: string; siteName: string; selectedScheduleKey?: string };
  Calibration: { profileColor: string; originTab: string; siteName: string };
  Lobby: { profileColor: string; originTab: string; siteName: string; backHandler?: () => any };
  Questionnaire: { profileColor: string; originTab: string; siteName: string };
  QuestionReference: { profileColor: string; originTab: string; siteName: string; url: string };
  SubmitReport: { profileColor: string; originTab: string; siteName: string; members?: { [uid: string]: User } };
  UploadReport: { profileColor: string; originTab: string; siteName: string };
  Payoff: { profileColor: string; originTab: string; siteName: string };
  CreateCompany: { profileColor: string; originTab: string; siteName: string };
  ReportFilter: { profileColor: string; selectedSite: SelectedSite };
};

const HomeStack = createStackNavigator<HomeStackParamList & WebViewStackParams>();

const headerLeftIcon = () => (
  <Icon
    name={Platform.OS === 'ios' ? 'chevron-left' : 'arrow-left'}
    size={Platform.OS === 'ios' ? 32 : 24}
    color="white"
  />
);

const HomeStackNavigator: React.ComponentType<any> = props => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { t } = useTranslation(['site', 'common', 'company']);

  const onPressBack = () => {
    navigation.goBack();
    dispatch(setActiveTab('reports'));
    dispatch(siteReportActions.setBackTemporaryReport());
  };

  const onPrevious = () => {
    navigation.goBack();
  };
  const onPressReset = (params: SelectedSite) => {
    firebase.analytics().logEvent('click_reset', {
      category: 'site_detail_page',
      siteName: params?.name
    });
    dispatch(siteReportActions.setResetTemporaryReport());
  };
  return (
    <HomeStack.Navigator initialRouteName="Home" screenOptions={defaultStackOptions}>
      <HomeStack.Screen
        name="Home"
        component={HomeTab}
        options={{
          gestureEnabled: false,
          headerShown: false
        }}
      />
      <HomeStack.Screen
        name="Journey"
        component={JourneyPage}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070',
              height: 60
            },
            headerTitle: () => <NavigationTitle defaultTintColor="white" title="" />,
            headerLeft: () => (
              <TouchableOpacity
                delayPressIn={0}
                onPress={() =>
                  navigation.reset({
                    index: 0,
                    routes: [{ name: 'HomeTab' }]
                  })
                }
                style={[styles.backButton, styles.backButtonLeftPosition]}
              >
                {headerLeftIcon()}
              </TouchableOpacity>
            )
          };
        }}
      />
      <HomeStack.Screen
        name="WebView"
        component={WebViewPage}
        options={({ route }) => ({
          gestureEnabled: false,
          headerStyle: {
            backgroundColor: route.params ? route.params.profileColor : '#3CD070'
          },
          headerTitle: () => {
            if (!route.params.title) {
              return;
            }
            return <NavigationTitle defaultTintColor="white" title={route.params?.title} />;
          }
        })}
      />
      <HomeStack.Screen
        name="Site"
        component={NewSiteScreen}
        options={({ route }) => {
          return {
            tabBarVisible: false,
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerTitle: () => (
              <NavigationTitle
                defaultTintColor="white"
                title={route.params ? route.params?.siteName : t('common:unknownSite')}
              />
            ),
            headerLeft: () => (
              <TouchableOpacity
                delayPressIn={0}
                onPress={() =>
                  navigation.reset({
                    index: 0,
                    routes: [{ name: 'HomeTab' }]
                  })
                }
                style={[styles.backButton, styles.backButtonLeftPosition]}
              >
                {headerLeftIcon()}
              </TouchableOpacity>
            )
          };
        }}
      />
      <HomeStack.Screen
        name="ReportFilter"
        component={SiteReportFilter}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerRight: () => (
              <TouchableOpacity
                style={styles.backButton}
                onPress={() => onPressReset(route.params?.selectedSite)}
                delayPressIn={0}
              >
                <Icon name="refresh" size={28} color="#ffffff" style={{ transform: [{ rotateY: '180deg' }] }} />
              </TouchableOpacity>
            ),
            headerTitle: () => <NavigationTitle defaultTintColor="white" title={t('site:siteReports.filter')} />,
            headerLeft: () => (
              <TouchableOpacity style={styles.backButton} onPress={onPressBack} delayPressIn={0}>
                <Icon name="close" size={28} color="#ffffff" />
              </TouchableOpacity>
            )
          };
        }}
      />
      <HomeStack.Screen
        name="Calibration"
        component={CalibrationPage}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            header: () => (
              <HeaderBar
                title={
                  route.params
                    ? { en: route.params.siteName, id: route.params.siteName }
                    : { en: 'Unknown Site', id: 'Unknown Site' }
                }
                themeColor={route.params ? route.params.profileColor : '#3CD070'}
              />
            )
          };
        }}
      />
      <HomeStack.Screen
        name="Lobby"
        component={LobbyPage}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerTitle: () => (
              <NavigationTitle
                defaultTintColor="white"
                title={route.params ? route.params?.siteName : t('common:unknownSite')}
              />
            ),
            headerLeft: () => (
              <TouchableOpacity onPress={route.params.backHandler} style={styles.backButton} delayPressIn={0}>
                {headerLeftIcon()}
              </TouchableOpacity>
            )
          };
        }}
      />
      <HomeStack.Screen
        name="Questionnaire"
        component={QuestionnairePage}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3cd070'
            },
            headerTitle: () => (
              <NavigationTitle
                style={styles.navTitle}
                defaultTintColor="white"
                title={route.params ? route.params?.siteName : t('common:unknownSite')}
              />
            ),
            headerRight: () => <QuestionnaireDrawerButton />,
            headerLeft: props => (
              <TouchableOpacity style={styles.backButton} onPress={props.onPress}>
                {headerLeftIcon()}
              </TouchableOpacity>
            )
          };
        }}
      />
      <HomeStack.Screen
        name="QuestionReference"
        component={QuestionReferencePage}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerTitle: () => (
              <NavigationTitle
                defaultTintColor="white"
                title={route.params ? route.params?.siteName : t('common:unknownSite')}
              />
            )
          };
        }}
      />
      <HomeStack.Screen
        name="SubmitReport"
        component={SubmitReportPage}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerTitle: () => (
              <NavigationTitle
                defaultTintColor="white"
                title={route.params ? route.params?.siteName : t('common:unknownSite')}
              />
            ),
            headerLeft: () => (
              <TouchableOpacity style={styles.backButton} onPress={onPrevious}>
                {headerLeftIcon()}
              </TouchableOpacity>
            )
          };
        }}
      />
      <HomeStack.Screen
        name="UploadReport"
        component={UploadReportPage}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerTitle: () => (
              <NavigationTitle
                defaultTintColor="white"
                title={route.params ? route.params?.siteName : t('common:unknownSite')}
              />
            ),
            headerLeft: () => (
              <TouchableOpacity style={styles.backButton} onPress={onPrevious}>
                {headerLeftIcon()}
              </TouchableOpacity>
            )
          };
        }}
      />
      <HomeStack.Screen
        name="Payoff"
        component={PayoffPage}
        options={{
          gestureEnabled: false,
          headerShown: false
        }}
      />
      <HomeStack.Screen
        name="CreateCompany"
        component={CreateCompanyPage}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerTitle: () => <NavigationTitle defaultTintColor="white" title={t('company:createCompany')} />,
            headerLeft: () => (
              <TouchableOpacity style={styles.backButton} onPress={onPrevious}>
                {headerLeftIcon()}
              </TouchableOpacity>
            )
          };
        }}
      />
    </HomeStack.Navigator>
  );
};

export type SettingsStackParamList = {
  Settings: { profileColor: string };
  Licenses: { profileColor: string };
  Tutorials: { profileColor: string };
};

const SettingsStack = createStackNavigator<SettingsStackParamList & WebViewStackParams>();

const SettingsStackNavigator: React.ComponentType<any> = props => {
  const { t } = useTranslation(['settings']);
  return (
    <SettingsStack.Navigator initialRouteName="Settings" screenOptions={defaultStackOptions}>
      <SettingsStack.Screen
        name="Settings"
        component={SettingsTab}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerTitle: () => <NavigationTitle defaultTintColor="white" title={t('settings:settings')} />
          };
        }}
      />
      <SettingsStack.Screen
        name="Licenses"
        component={LicensesPage}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerTitle: () => <NavigationTitle defaultTintColor="white" title={t('settings:softwareLicense')} />
          };
        }}
      />
      <SettingsStack.Screen
        name="Tutorials"
        component={TutorialsPage}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerTitle: () => <NavigationTitle defaultTintColor="white" title={t('settings:tutorial')} />
          };
        }}
      />
      <SettingsStack.Screen
        name="WebView"
        component={WebViewPage}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerTitle: () => <NavigationTitle defaultTintColor="white" title={route.params?.title} />
          };
        }}
      />
    </SettingsStack.Navigator>
  );
};

const defaultTabOptions: BottomTabBarOptions = {
  activeTintColor: '#555',
  activeBackgroundColor: '#fff',
  inactiveTintColor: '#ccc',
  inactiveBackgroundColor: '#fff',
  height: 75,
  style: {
    height: 75,
    paddingTop: SCREEN_WIDTH >= 768 ? 13 : 0,
    paddingLeft: SCREEN_WIDTH >= 750 ? 50 : 0,
    borderTopColor: 'transparent',
    borderTopWidth: 0,
    bottom: 0,
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH,
    marginHorizontal: 'auto',
    position: 'fixed'
  },
  tabStyle: {
    display: SCREEN_WIDTH > 1024 ? 'grid' : 'flex',
    height: 75
  },
  labelStyle: {
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 14,
    marginHorizontal: 0,
    marginTop: SCREEN_WIDTH >= 750 ? 4 : -4,
    marginBottom: SCREEN_WIDTH > 500 ? 20 : 10,
    textAlign: 'center'
  }
};

export type ScheduleStackParamList = {
  Schedule: { profileColor: string };
  Journey: { profileColor: string; originTab: string; isDueToday: boolean };
  Site: { profileColor: string; originTab: string; siteName: string; selectedScheduleKey?: string };
  SiteReportHistory: { profileColor: string; originTab: string; siteName: string };
  Promotion: { profileColor: string; originTab: string; promotion: Promotion };
  Calibration: { profileColor: string; originTab: string; siteName: string };
  Lobby: { profileColor: string; originTab: string; siteName: string; backHandler?: () => any };
  Questionnaire: { profileColor: string; originTab: string; siteName: string };
  QuestionReference: { profileColor: string; originTab: string; siteName: string; url: string };
  QuestionMediaRecord: {
    profileColor: string;
    originTab: string;
    headerTitle: string;
    cameraOrientation: string;
    onCapture?: (uri: string) => any;
  };
  QuestionMediaPlayback: {
    originTab: string;
    uri: string;
    videoIndex?: number;
    currentConditionIndex?: number | string;
  };
  SubmitReport: { profileColor: string; originTab: string; siteName: string; members?: { [uid: string]: User } };
  UploadReport: { profileColor: string; originTab: string; siteName: string };
  Payoff: { profileColor: string; originTab: string; siteName: string };
  CreateCompany: { profileColor: string; originTab: string; siteName: string };
  ReportFilter: { profileColor: string; selectedSite: SelectedSite };
};
const ScheduleStack = createStackNavigator<ScheduleStackParamList>();
const ScheduleStackNavigator: React.ComponentType<any> = props => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { t } = useTranslation(['home', 'schedule', 'common']);
  const onPressBack = () => {
    navigation.goBack();
    dispatch(setActiveTab('reports'));
    dispatch(siteReportActions.setResetTemporaryReport());
  };
  const onPressReset = (params: SelectedSite) => {
    firebase.analytics().logEvent('click_reset', {
      category: 'site_detail_page',
      siteName: params?.name
    });

    dispatch(siteReportActions.setResetTemporaryReport());
  };
  return (
    <ScheduleStack.Navigator initialRouteName="Schedule" screenOptions={defaultStackOptions}>
      <ScheduleStack.Screen
        name="Schedule"
        component={ScheduleTab}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerTitle: () => (
              <NavigationTitle
                defaultTintColor="white"
                title={{
                  en: t('schedule:thisWeek'),
                  id: t('schedule:thisWeek'),
                  es: t('schedule:thisWeek'),
                  km: t('schedule:thisWeek'),
                  pt: t('schedule:thisWeek'),
                  th: t('schedule:thisWeek')
                }}
              />
            )
          };
        }}
      />
      <ScheduleStack.Screen
        name="Journey"
        component={JourneyPage}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerTitle: () => <NavigationTitle defaultTintColor="white" title="" />,
            headerLeft: () => (
              <TouchableOpacity
                onPress={() =>
                  navigation.reset({
                    index: 0,
                    routes: [{ name: 'HomeTab' }]
                  })
                }
                style={[styles.backButton, styles.backButtonLeftPosition]}
              >
                {headerLeftIcon()}
              </TouchableOpacity>
            )
          };
        }}
      />
      <ScheduleStack.Screen
        name="Site"
        component={NewSiteScreen}
        options={({ route }) => {
          return {
            tabBarVisible: false,
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerTitle: () => (
              <NavigationTitle
                defaultTintColor="white"
                title={
                  route.params
                    ? { en: route.params.siteName, id: route.params.siteName }
                    : { en: 'Unknown Site', id: 'Unknown Site' }
                }
              />
            ),
            headerLeft: () => (
              <TouchableOpacity
                onPress={() => navigation.navigate('Schedule')}
                style={[styles.backButton, styles.backButtonLeftPosition]}
              >
                {headerLeftIcon()}
              </TouchableOpacity>
            )
          };
        }}
      />
      <ScheduleStack.Screen
        name="ReportFilter"
        component={SiteReportFilter}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerRight: () => (
              <TouchableOpacity style={styles.backButton} onPress={() => onPressReset(route.params?.selectedSite)}>
                <Icon name="refresh" size={28} color="#ffffff" style={{ transform: [{ rotateY: '180deg' }] }} />
              </TouchableOpacity>
            ),
            headerTitle: () => (
              <NavigationTitle defaultTintColor="white" title={{ en: 'Filter And Sort', id: 'Filter And Sortir' }} />
            ),
            headerLeft: () => (
              <TouchableOpacity style={styles.backButton} onPress={onPressBack}>
                <Icon name="close" size={28} color="#ffffff" />
              </TouchableOpacity>
            )
          };
        }}
      />

      <ScheduleStack.Screen
        name="Calibration"
        component={CalibrationPage}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: 'transparent'
            },
            headerTitle: () => (
              <NavigationTitle
                defaultTintColor="white"
                title={
                  route.params
                    ? { en: route.params.siteName, id: route.params.siteName }
                    : { en: 'Unknown Site', id: 'Unknown Site' }
                }
              />
            )
          };
        }}
      />
      <ScheduleStack.Screen
        name="Lobby"
        component={LobbyPage}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerTitle: () => (
              <NavigationTitle
                defaultTintColor="white"
                title={
                  route.params
                    ? { en: route.params.siteName, id: route.params.siteName }
                    : { en: 'Unknown Site', id: 'Unknown Site' }
                }
              />
            ),
            headerLeft: () => (
              <TouchableOpacity onPress={route.params.backHandler} style={styles.backButton}>
                {headerLeftIcon()}
              </TouchableOpacity>
            )
          };
        }}
      />
      <ScheduleStack.Screen
        name="Questionnaire"
        component={QuestionnairePage}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3cd070'
            },
            headerTitle: () => (
              <NavigationTitle
                style={Platform.select({
                  ios: {
                    fontSize: 17,
                    marginHorizontal: 16
                  },
                  android: {
                    fontSize: 20,
                    marginHorizontal: 16
                  }
                })}
                defaultTintColor="white"
                title={route.params ? { en: route.params.siteName, id: route.params.siteName } : 'Unknown Site'}
              />
            ),
            headerLeft: props => (
              <TouchableOpacity style={styles.backButton} onPress={props.onPress}>
                {headerLeftIcon()}
              </TouchableOpacity>
            ),
            headerRight: () => <QuestionnaireDrawerButton />
          };
        }}
      />
      <ScheduleStack.Screen
        name="QuestionReference"
        component={QuestionReferencePage}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerTitle: () => (
              <NavigationTitle
                defaultTintColor="white"
                title={
                  route.params
                    ? { en: route.params.siteName, id: route.params.siteName }
                    : { en: 'Unknown Site', id: 'Unknown Site' }
                }
              />
            )
          };
        }}
      />

      <ScheduleStack.Screen
        name="SubmitReport"
        component={SubmitReportPage}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerTitle: () => (
              <NavigationTitle
                defaultTintColor="white"
                title={
                  route.params
                    ? { en: route.params.siteName, id: route.params.siteName }
                    : { en: 'Unknown Site', id: 'Unknown Site' }
                }
              />
            )
          };
        }}
      />
      <ScheduleStack.Screen
        name="UploadReport"
        component={UploadReportPage}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerTitle: () => (
              <NavigationTitle
                defaultTintColor="white"
                title={
                  route.params
                    ? { en: route.params.siteName, id: route.params.siteName }
                    : { en: 'Unknown Site', id: 'Unknown Site' }
                }
              />
            )
          };
        }}
      />
      <ScheduleStack.Screen
        name="Payoff"
        component={PayoffPage}
        options={{
          gestureEnabled: false,
          headerShown: false
        }}
      />
      <ScheduleStack.Screen
        name="CreateCompany"
        component={CreateCompanyPage}
        options={({ route }) => {
          return {
            gestureEnabled: false,
            headerStyle: {
              backgroundColor: route.params ? route.params.profileColor : '#3CD070'
            },
            headerTitle: () => <NavigationTitle defaultTintColor="white" title="Create Company" />
          };
        }}
      />
    </ScheduleStack.Navigator>
  );
};

export type AppTabsParamList = {
  HomeTab: {};
  ScheduleTab: {};
  IssuesTab: {};
  ProfileTab: {};
  SettingsTab: {};
};

const AppTabs = createBottomTabNavigator<AppTabsParamList>();
export const AppTabsNavigator: React.ComponentType<any> = () => {
  const { t } = useTranslation(['home', 'settings', 'common']);
  return (
    <AppTabs.Navigator initialRouteName="HomeTab" tabBarOptions={defaultTabOptions}>
      <AppTabs.Screen
        name="HomeTab"
        component={HomeStackNavigator}
        options={({ route }) => ({
          tabBarVisible: getTabBarVisibility(route),
          tabBarLabel: ({ focused }: { focused: boolean; tintColor?: string; horizontal?: boolean }) => (
            <NavigationTitle
              style={defaultTabOptions.labelStyle}
              activeTintColor={defaultTabOptions.activeTintColor}
              defaultTintColor="#ccc"
              active={focused}
              title={t('home:home')}
            />
          ),
          tabBarIcon: ({ color, focused }) =>
            focused ? (
              <Icon name="home" size={26} color={color || 'white'} style={{ paddingTop: 5 }} />
            ) : (
              <Icon name="home-outline" size={26} color={color || 'white'} style={{ paddingTop: 5 }} />
            )
        })}
      />
      <AppTabs.Screen
        name="ScheduleTab"
        component={ScheduleStackNavigator}
        options={({ route }) => ({
          tabBarVisible: getTabBarVisibility(route),
          tabBarLabel: ({ focused }: { focused: boolean; tintColor?: string; horizontal?: boolean }) => (
            <NavigationTitle
              style={defaultTabOptions.labelStyle}
              activeTintColor={defaultTabOptions.activeTintColor}
              defaultTintColor="#ccc"
              active={focused}
              title={t('common:schedule')}
            />
          ),
          tabBarIcon: ({ color, focused }) =>
            focused ? (
              <Icon name="clock" size={26} color={color || 'white'} style={{ paddingTop: 5 }} />
            ) : (
              <Icon name="clock-outline" size={26} color={color || 'white'} style={{ paddingTop: 5 }} />
            )
        })}
      />
      <AppTabs.Screen
        name="SettingsTab"
        component={SettingsStackNavigator}
        options={({ route }) => ({
          tabBarVisible: getTabBarVisibility(route),
          tabBarLabel: ({ focused }: { focused: boolean; tintColor?: string; horizontal?: boolean }) => (
            <NavigationTitle
              style={defaultTabOptions.labelStyle}
              activeTintColor={defaultTabOptions.activeTintColor}
              defaultTintColor="#ccc"
              active={focused}
              title={t('settings:settingsTitle')}
            />
          ),
          tabBarIcon: ({ color, focused }) =>
            focused ? (
              <Icon name="cog" size={26} color={color || 'white'} style={{ paddingTop: 5 }} />
            ) : (
              <Icon name="cog-outline" size={26} color={color || 'white'} style={{ paddingTop: 5 }} />
            )
        })}
      />
    </AppTabs.Navigator>
  );
};

const styles = StyleSheet.create({
  iconContainer: {
    paddingLeft: 14
  },
  backButton: {
    width: 40,
    height: 40,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center'
  },
  backButtonLeftPosition: {
    marginLeft: 8
  },
  titleContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    alignSelf: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 8,
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH
  },
  navTitle: {
    fontSize: 17,
    marginHorizontal: 16
  },
  refreshContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
});
