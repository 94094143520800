// Takes a string hex code for a background color
// and derives whether the text within should be white or black.

function hexToRGB(hex: string) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const intermediate =
    hex && typeof hex === 'string' ? hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b) : '';

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(intermediate);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

function contrastTextColor(hex: string) {
  const rgb = hexToRGB(hex);
  if (rgb) {
    if (rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 186) {
      return '#000000';
    }
    return '#ffffff';
  }
  return undefined;
}

export { hexToRGB, contrastTextColor };
