import React from 'react';
import { StyleSheet, View } from 'react-native';
import fontMaker from '../../utils/font';
import theme from '../../constants/theme';

import Text from '../global/Text';

export type QuestionnaireQuestionScoreNumbersProps = {
  score: number;
  index: number;
};

export const QuestionnaireQuestionScoreNumbers = (props: QuestionnaireQuestionScoreNumbersProps) => {
  return (
    <View
      style={[
        styles.scoreTips,
        {
          width: props.score <= 4 ? '80%' : `${92 - props.score}%`,
          justifyContent: props.score <= 4 ? 'space-between' : 'space-around'
        }
      ]}
      testID={'question-number'}
    >
      {new Array(props.score + 2).fill('').map((s, i) => (
        <Text
          key={`slider-score-${props.index}-${i}`}
          style={[styles.scoreTip, { color: i === 0 ? 'transparent' : theme.colors.gray }]}
        >
          {i - 1}
        </Text>
      ))}
    </View>
  );
};

export default QuestionnaireQuestionScoreNumbers;

const styles = StyleSheet.create({
  scoreTips: {
    top: 8,
    height: 20,
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center'
  },
  scoreTip: {
    width: 16,
    overflow: 'visible',
    textAlign: 'center',
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 13
  }
});
