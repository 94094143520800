import { action, ActionType } from 'typesafe-actions';
import update from 'immutability-helper';
import { Report, ReportSummary, Site } from '../../utils/classes';
import { InjectedSite } from '../../typing/types';

export const ADD_SITE = '@reportsubmit/ADD_PENDING_UPLOAD_SITE';
export const ADD_PENDING_MULTI = '@reportsubmit/ADD_PENDING_MULTI';
export const REMOVE_PENDING_MULTI = '@reportsubmit/REMOVE_PENDING_MULTI';
export const REMOVE_SITE = '@reportsubmit/REMOVE_PENDING_UPLOAD_SITE';

export const ADD_PENDING_SINGLE = '@reportsubmit/ADD_PENDING_SINGLE';
export const REMOVE_PENDING_SINGLE = '@reportsubmit/REMOVE_PENDING_SINGLE';

export const SET_REPORT = '@reportsubmit/SET_REPORT_TO_UPLOAD';
export const RESET_REPORT = '@reportsubmit/RESET_REPORT_TO_UPLOAD';

/**
 * (Unused for now)
 * @param siteKey
 * @param reportKeys
 */
export const addPendingUploadSite = (siteKey: string, reportKeys: { [reportKey: string]: boolean }) =>
  action(ADD_SITE, { siteKey, reportKeys });

/**
 * Add report to pending queue.
 * @param siteKey report site id
 * @param reportKey report id
 */
export const addPendingMulti = (siteKey: string, reportKey: string) =>
  action(ADD_PENDING_MULTI, { siteKey, reportKey });

/**
 * Remove report from pending queue
 * @param siteKey report site id
 * @param reportKey report id
 */
export const removePendingMulti = (siteKey: string, reportKey: string) =>
  action(REMOVE_PENDING_MULTI, { siteKey, reportKey });

/**
 * (Unused for now)
 * @param siteKey
 */
export const removePendingUploadSite = (siteKey: string) => action(REMOVE_SITE, { siteKey });

/**
 * Add certain questionnaire from a report to pending queue
 * @param siteKey
 * @param questionnaireKey
 * @param reportKey
 */

export const addPendingSingle = (siteKey: string, scheduleKey: string, reportKey: string) =>
  action(ADD_PENDING_SINGLE, { siteKey, scheduleKey, reportKey });

/**
 * Remove certain questionnaire from a report to pending queue
 * @param siteKey
 * @param questionnaireKey
 * @param reportKey
 */
export const removePendingSingle = (siteKey: string, scheduleKey: string, reportKey: string) =>
  action(REMOVE_PENDING_SINGLE, { siteKey, scheduleKey, reportKey });

/**
 * Add report to upload queue
 * @param site
 * @param siteKey
 * @param questionnaireName
 * @param reportKey
 * @param report
 * @param reportSummary
 */
export const setReportToUpload = (
  site: Site | InjectedSite,
  siteKey: string,
  questionnaireName: string,
  reportKey: string,
  report: Report,
  reportSummary: ReportSummary
) =>
  action(SET_REPORT, {
    site,
    siteKey,
    questionnaireName,
    reportKey,
    report,
    reportSummary
  });

/**
 * Reset upload queue contents
 */
export const resetReportToUpload = () => action(RESET_REPORT);

export const reportsubmitActions = {
  addPendingUploadSite,
  addPendingMulti,
  removePendingMulti,
  removePendingUploadSite,
  addPendingSingle,
  removePendingSingle,
  setReportToUpload,
  resetReportToUpload
};

export type ReportSubmitAction = ActionType<typeof reportsubmitActions>;

export type PendingSiteInfo = {
  [reportKey: string]: boolean | object;
  pendingSingle: {
    /** contain information of pending report key from this questionnaire */
    [scheduleKey: string]: string;
  };
  pendingMulti: {
    /** access pending directly from report key if multisite */
    [reportKey: string]: boolean;
  };
};

export type PendingSites = {
  [siteKey: string]: PendingSiteInfo;
};

export type ReportSubmitState = {
  readonly pendingSites: PendingSites;
  readonly uploadingSite: Site | InjectedSite | null;
  readonly uploadingQuestionnaireName: string | null;
  readonly uploadingSiteKey: string | null;
  readonly uploadingReportKey: string | null;
  readonly uploadingReport: Report | null;
  readonly uploadingReportSummary: ReportSummary | null;
};

const initialState: ReportSubmitState = {
  pendingSites: {},
  uploadingSite: null,
  uploadingQuestionnaireName: null,
  uploadingSiteKey: null,
  uploadingReportKey: null,
  uploadingReport: null,
  uploadingReportSummary: null
};

export const reportsubmitReducer = (state = initialState, incomingAction: ReportSubmitAction) => {
  switch (incomingAction.type) {
    case ADD_SITE:
      return update(state, {
        pendingSites: {
          [incomingAction.payload.siteKey]: { $set: incomingAction.payload.reportKeys }
        }
      });
    case ADD_PENDING_MULTI:
      return update(state, {
        pendingSites: {
          [incomingAction.payload.siteKey]: (pendingSite: PendingSiteInfo) =>
            update(pendingSite || {}, {
              [incomingAction.payload.reportKey]: { $set: true },
              pendingMulti: (byReports: { [reportKey: string]: boolean }) =>
                update(byReports || {}, { [incomingAction.payload.reportKey]: { $set: true } }),
              pendingSingle: (byQuestionnaire: { [questKey: string]: string }) =>
                update(byQuestionnaire || {}, { $set: byQuestionnaire })
            })
        }
      });

    case REMOVE_PENDING_MULTI:
      return update(state, {
        pendingSites: {
          [incomingAction.payload.siteKey]: (pendingSite: PendingSiteInfo) =>
            update(pendingSite || {}, {
              $unset: [incomingAction.payload.reportKey],
              pendingMulti: (byReports: { [reportKey: string]: boolean }) =>
                update(byReports || {}, { $unset: [incomingAction.payload.reportKey] }),
              pendingSingle: (byQuestionnaire: { [questKey: string]: string }) =>
                update(byQuestionnaire || {}, { $set: byQuestionnaire })
            })
        }
      });
    case REMOVE_SITE:
      return update(state, {
        pendingSites: {
          $unset: [incomingAction.payload.siteKey]
        }
      });
    case ADD_PENDING_SINGLE:
      return update(state, {
        pendingSites: {
          [incomingAction.payload.siteKey]: (pendingSite: PendingSiteInfo) =>
            update(pendingSite || {}, {
              [incomingAction.payload.reportKey]: (bool: boolean) => update(bool, { $set: true }),
              pendingSingle: (byQuestionnaire: { [questKey: string]: string }) =>
                update(byQuestionnaire || {}, {
                  [incomingAction.payload.scheduleKey]: { $set: incomingAction.payload.reportKey }
                }),
              pendingMulti: (byReport: { [rKey: string]: boolean }) => update(byReport || {}, { $set: byReport })
            })
        }
      });
    case REMOVE_PENDING_SINGLE:
      return update(state, {
        pendingSites: {
          [incomingAction.payload.siteKey]: (pendingSite: PendingSiteInfo) =>
            update(pendingSite || {}, {
              $unset: [incomingAction.payload.reportKey],
              pendingSingle: (byQuestionnaire: { [questKey: string]: string }) =>
                update(byQuestionnaire || {}, {
                  $unset: [incomingAction.payload.scheduleKey]
                }),
              pendingMulti: (byReport: { [rKey: string]: boolean }) => update(byReport || {}, { $set: byReport })
            })
        }
      });

    case SET_REPORT:
      return {
        ...state,
        uploadingSite: incomingAction.payload.site,
        uploadingSiteKey: incomingAction.payload.siteKey,
        uploadingQuestionnaireName: incomingAction.payload.questionnaireName,
        uploadingReportKey: incomingAction.payload.reportKey,
        uploadingReport: incomingAction.payload.report,
        uploadingReportSummary: incomingAction.payload.reportSummary
      };
    case RESET_REPORT:
      return {
        ...state,
        uploadingSite: null,
        uploadingSiteKey: null,
        uploadingQuestionnaireName: null,
        uploadingReportKey: null,
        uploadingReport: null,
        uploadingReportSummary: null
      };
    default:
      return state;
  }
};
