import React from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import { StyleSheet, View, Image, Dimensions, ActivityIndicator, ImageSourcePropType } from 'react-native';
import Text from '../global/Text';
import fontMaker from '../../utils/font';
import AvatarNew from './AvatarNew';

type LobbyMemberProps = {
  name: string;
  isLeader: boolean;
  imageSrc: ImageSourcePropType | null;
  isConnected: boolean;
  profileColor: string;
  t: any;
};

class LobbyMember extends React.Component<LobbyMemberProps> {
  state = {
    _isLoading: true
  };

  getNameInitials = () => {
    const { t } = this.props;
    const { name, isLeader } = this.props;
    const selectedName = !name ? (isLeader ? t('lobby:leader') : t('lobby:member')) : name;

    return selectedName
      .split(' ')
      .map((n: any) => n[0])
      .join('')
      .substring(0, 2);
  };

  render() {
    const { name, isLeader, imageSrc, isConnected, t } = this.props;
    const { _isLoading } = this.state;
    return (
      <View style={styles.root}>
        <View style={[styles.avatar, isLeader ? styles.leadBorder : styles.memberBorder]}>
          {imageSrc && isConnected ? (
            <AvatarNew source={imageSrc} name={this.getNameInitials()} type="member" />
          ) : (
            <View style={styles.avatarBackground}>
              <Text style={styles.avatarName}>{this.getNameInitials()}</Text>
            </View>
          )}
          {imageSrc && isConnected
            ? _isLoading && (
                <View style={styles.loadingContainer}>
                  <ActivityIndicator size="large" color={this.props.profileColor} animating={_isLoading} />
                </View>
              )
            : null}
        </View>
        <Text numberOfLines={1} ellipsizeMode="tail" style={styles.name}>
          {!name ? (isLeader ? t('lobby:leader') : t('lobby:member')) : name}
        </Text>
      </View>
    );
  }
}

const { width } = Dimensions.get('window');

const styles = StyleSheet.create({
  root: {
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: width * 0.201,
    marginHorizontal: 4,
    marginVertical: 4
  },
  avatar: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 6,
    width: 75,
    height: 75,
    borderRadius: 75 / 2,
    overflow: 'visible',
    backgroundColor: 'transparent',
    padding: 1
  },
  avatarImage: {
    zIndex: 1,
    width: 64,
    height: 64,
    borderRadius: 64 / 2,
    resizeMode: 'cover'
  },
  avatarName: {
    ...fontMaker({ weight: 'Bold' }),
    textAlign: 'center',
    fontSize: 26,
    lineHeight: 30,
    color: '#fff'
  },
  avatarBackground: {
    backgroundColor: '#439889',
    height: 64,
    width: 64,
    borderRadius: 64 / 2,
    padding: 2,
    justifyContent: 'center',
    alignContent: 'center'
  },
  leadBorder: {
    borderWidth: 2,
    borderColor: '#3cd070',
    zIndex: 2
  },
  memberBorder: {
    borderWidth: 2,
    borderColor: '#a8a8aa',
    zIndex: 2
  },
  name: {
    color: '#1b2133',
    fontSize: 13
  },
  loadingContainer: {
    position: 'absolute',
    left: 3,
    right: 0,
    top: 3,
    bottom: 0,
    opacity: 0.7,
    justifyContent: 'center',
    alignItems: 'center'
  }
});

export default compose(withTranslation('lobby'))(LobbyMember as any);
