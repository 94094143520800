import React from 'react';
import { FlatList, Modal, StyleSheet, TouchableOpacity, View } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Text from '../global/Text';
import { LanguagesOptions, LanguageType } from '../../constants/Languages';
import LandingLanguageItem from './LandingLanguageItem';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { landingActions } from '../../store/reducers/landing';
import { useTranslation } from 'react-i18next';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../utils/screensize';

export type LandingLanguageModalProps = {
  onLanguageChange: (language: LanguageType) => void;
};

export const LandingLanguageModal = (props: LandingLanguageModalProps) => {
  const { t } = useTranslation(['common']);

  const dispatch = useDispatch();
  const modalVisible = useSelector<RootState, boolean>(state => state.landing.modalLanguageVisible);

  const handleCloseLanguageSelectorModal = () => {
    dispatch(landingActions.setModalLanguageVisible(false));
    return;
  };

  return (
    <Modal
      animationType="slide"
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => handleCloseLanguageSelectorModal()}
    >
      <View style={styles.overlay}>
        <View style={styles.root}>
          <View>
            <TouchableOpacity onPress={() => handleCloseLanguageSelectorModal()} style={styles.headerBackContainer}>
              <Icon name="chevron-left" size={25} color="#574FCF" />
              <Text style={styles.headerBackText}>{t('common:back')}</Text>
            </TouchableOpacity>
            <Text style={styles.title}>{t('common:selectLanguage')}</Text>
          </View>
          <View>
            <FlatList
              data={LanguagesOptions}
              renderItem={({ item }) => (
                <LandingLanguageItem onLanguageChange={props.onLanguageChange} options={item} />
              )}
              keyExtractor={({ id }) => id}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  root: {
    backgroundColor: '#ffffff',
    height: SCREEN_HEIGHT,
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH,
    paddingHorizontal: 20,
    paddingTop: 40
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 20
  },
  headerBackContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 50,
    marginLeft: -5
  },
  headerBackText: {
    color: '#574FCF',
    fontWeight: 'bold'
  }
});

export default LandingLanguageModal;
