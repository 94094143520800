import firebase from 'firebase';
import { FirebaseFunctionsTypes } from 'firebase/functions';
import moment from 'moment';

import { Site } from '../../../../utils/classes';
import { UserJourneyScheduleInfo } from '../journeySchedule.reducer';

export type getUserJourneyScheduleProps = {
  auth: any;
  site: Site;
  siteKey: string;
  journey: any;
  organizationKey: string;
  startDate: string;
  endDate: string;
};

type DataParam = Omit<getUserJourneyScheduleProps, 'site' | 'auth'> & {
  userKey: string;
  today: string;
  /** in minutes */
  timezone: string;
  journey: any;
};

const getUserJourneySchedule = (
  props: getUserJourneyScheduleProps
): Promise<FirebaseFunctionsTypes.HttpsCallableResult> | [] => {
  const { organizationKey, siteKey, site, journey, startDate, endDate, auth } = props;

  if (!journey) {
    return [];
  }

  const m = moment();
  const todayString = m.format('YYYY-MM-DD');
  const getUserJourneyScheduleData = firebase
    .functions()
    .httpsCallable<DataParam, UserJourneyScheduleInfo[]>('getUserJourneySchedule');

  const params: DataParam = {
    organizationKey,
    userKey: auth.uid,
    today: todayString,
    timezone: site.timezone || '',
    startDate,
    endDate,
    siteKey,
    journey
  };

  return getUserJourneyScheduleData(params);
};

export default getUserJourneySchedule;
