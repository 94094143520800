const Constants = {
  ENVIRONMENT: {
    DEV: 'dev',
    STAGING: 'staging',
    PROD: 'prod'
  },
  IMAGE_SIZE: {
    VGA: '640x480',
    HD: '1280x780',
    FHD: '1920x1080',
    LOW: '320x240',
    ORI: '3840x2160' //4K
  },
  COMPRESS_QUALITY: {
    LOW: 95,
    VGA: 90,
    HD: 90,
    FHD: 93,
    ORI: 90
  }
};

const SHARD_ID = {
  REPORT: 'SHARD_REPORT',
  ACTIVITY: 'SHARD_ACTIVITY'
};

enum AUTH_MODE {
  SIGN_UP = 'signup',
  LOGIN = 'login',
  RESET_PASSWORD = 'resetpassword',
  CONFIRM_OTP = 'confirm-otp',
  EMAIL_SENT = 'email-sent',
  VERIFY_OTP = 'verify-otp',
  VERIFIED = 'verified'
}
export { Constants, SHARD_ID, AUTH_MODE };
