import { createAsyncAction } from 'typesafe-actions';
import moment from 'moment';

import * as Types from './submitReport.actionType';
import { Report } from '../../../utils/classes';

export interface SubmitReportAPI {
  report: {
    uploadingReport: Report;
    datetimeSubmitted: moment.MonthWeekdayFn;
  };
  summary: string;
  reportId: string;
  callback: { resolve: Function; reject: Function };
}

export const submitApiReportAsync = createAsyncAction(
  Types.SUBMIT_REPORT_REQUEST,
  Types.SUBMIT_REPORT_SUCCESS,
  Types.SUBMIT_REPORT_FAILURE
)<SubmitReportAPI, boolean | null, string>();
