import React from 'react';
import { StyleSheet, View } from 'react-native';

// component
import SiteReportsFilter from '../components/site/Site/Reports/SiteReportsFilter/SiteReportsFilter';

const SiteReportFilter = () => {
  return (
    <View style={styles.root}>
      <SiteReportsFilter />
    </View>
  );
};

export default SiteReportFilter;
const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: 'white'
  }
});
