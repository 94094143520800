import React from 'react';
import { composeInitialProps } from 'react-i18next';

const styles = {
  container: {
    display: 'flex',
    cursor: 'pointer'
  },
  input: {
    border: 0,
    width: 0,
    height: 0,
    visibility: 'hidden',
    display: 'none'
  }
};

function handleUpload(onComplete, onFail) {
  return event => {
    let file = event.target.files[0];

    // if (!/^image\//.test(file.type)) {
    //   const data = { cancelled: false, error: "Not an image." };
    //   onFail(data);
    //   return;
    // }

    // const reader = new FileReader();

    // reader.onload = event => {
    //   const image: any = document.createElement('video');
    //   image.src = event.target.result;
    //   image.onloadedmetadata = event => {
    //     const { width, height } = event.target;
    //     const uri = event.target.src;
    //     const data = { cancelled: false, uri, width, height };
    //     onComplete(data);
    //   };
    // };

    // reader.onerror = err => {
    //   const data = { cancelled: false, error: err };
    //   onFail(data);
    // };

    // reader.onabort = err => {
    //   const data = { cancelled: false, error: err };
    //   onFail(data);
    // };
    // reader.readAsDataURL(file);

    onComplete(file);
    // file = null;
    // onFail(file);
  };
}

export default function ({ style, onComplete, onFail, children }) {
  const containerStyle = { ...styles.container, ...style };

  const acceptedFile =
    '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  return (
    <label onChange={handleUpload(onComplete, onFail)} style={containerStyle}>
      <input style={styles.input} type="file" accept={'.xlsx, .xls, .csv, .pdf, .doc, .docx'} />
      {children}
    </label>
  );
}
