import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import commonEN from './assets/locales/en/common.json';
import commonID from './assets/locales/id/common.json';
import commonES from './assets/locales/es/common.json';
import commonKM from './assets/locales/km/common.json';
import commonPT from './assets/locales/pt/common.json';
import commonTH from './assets/locales/th/common.json';

import siteEN from './assets/locales/en/site.json';
import siteID from './assets/locales/id/site.json';
import siteES from './assets/locales/es/site.json';
import siteKM from './assets/locales/km/site.json';
import sitePT from './assets/locales/pt/site.json';
import siteTH from './assets/locales/th/site.json';

import calibrationEN from './assets/locales/en/calibration.json';
import calibrationID from './assets/locales/id/calibration.json';
import calibrationES from './assets/locales/es/calibration.json';
import calibrationKM from './assets/locales/km/calibration.json';
import calibrationPT from './assets/locales/pt/calibration.json';
import calibrationTH from './assets/locales/th/calibration.json';

import lobbyEN from './assets/locales/en/lobby.json';
import lobbyID from './assets/locales/id/lobby.json';
import lobbyES from './assets/locales/es/lobby.json';
import lobbyKM from './assets/locales/km/lobby.json';
import lobbyPT from './assets/locales/pt/lobby.json';
import lobbyTH from './assets/locales/th/lobby.json';

import profileEN from './assets/locales/en/profile.json';
import profileID from './assets/locales/id/profile.json';
import profileES from './assets/locales/es/profile.json';
import profileKM from './assets/locales/km/profile.json';
import profilePT from './assets/locales/pt/profile.json';
import profileTH from './assets/locales/th/profile.json';

import journeyEN from './assets/locales/en/journey.json';
import journeyID from './assets/locales/id/journey.json';
import journeyES from './assets/locales/es/journey.json';
import journeyKM from './assets/locales/km/journey.json';
import journeyPT from './assets/locales/pt/journey.json';
import journeyTH from './assets/locales/th/journey.json';

import issueTrackerEN from './assets/locales/en/issueTracker.json';
import issueTrackerID from './assets/locales/id/issueTracker.json';
import issueTrackerES from './assets/locales/es/issueTracker.json';
import issueTrackerKM from './assets/locales/km/issueTracker.json';
import issueTrackerPT from './assets/locales/pt/issueTracker.json';
import issueTrackerTH from './assets/locales/th/issueTracker.json';

import issueMessageEN from './assets/locales/en/issueMessage.json';
import issueMessageID from './assets/locales/id/issueMessage.json';
import issueMessageES from './assets/locales/es/issueMessage.json';
import issueMessageKM from './assets/locales/km/issueMessage.json';
import issueMessagePT from './assets/locales/pt/issueMessage.json';
import issueMessageTH from './assets/locales/th/issueMessage.json';

import featureAccessEN from './assets/locales/en/featureAccess.json';
import featureAccessID from './assets/locales/id/featureAccess.json';
import featureAccessES from './assets/locales/es/featureAccess.json';
import featureAccessKM from './assets/locales/km/featureAccess.json';
import featureAccessPT from './assets/locales/pt/featureAccess.json';
import featureAccessTH from './assets/locales/th/featureAccess.json';

import payoffEN from './assets/locales/en/payoff.json';
import payoffID from './assets/locales/id/payoff.json';
import payoffES from './assets/locales/es/payoff.json';
import payoffKM from './assets/locales/km/payoff.json';
import payoffPT from './assets/locales/pt/payoff.json';
import payoffTH from './assets/locales/th/payoff.json';

import settingsEN from './assets/locales/en/settings.json';
import settingsID from './assets/locales/id/settings.json';
import settingsES from './assets/locales/es/settings.json';
import settingsKM from './assets/locales/km/settings.json';
import settingsPT from './assets/locales/pt/settings.json';
import settingsTH from './assets/locales/th/settings.json';

import questionnaireEN from './assets/locales/en/questionnaire.json';
import questionnaireID from './assets/locales/id/questionnaire.json';
import questionnaireES from './assets/locales/es/questionnaire.json';
import questionnaireKM from './assets/locales/km/questionnaire.json';
import questionnairePT from './assets/locales/pt/questionnaire.json';
import questionnaireTH from './assets/locales/th/questionnaire.json';

import companyEN from './assets/locales/en/company.json';
import companyID from './assets/locales/id/company.json';
import companyES from './assets/locales/es/company.json';
import companyKM from './assets/locales/km/company.json';
import companyPT from './assets/locales/pt/company.json';
import companyTH from './assets/locales/th/company.json';

import scheduleEN from './assets/locales/en/schedule.json';
import scheduleID from './assets/locales/id/schedule.json';
import scheduleES from './assets/locales/es/schedule.json';
import scheduleKM from './assets/locales/km/schedule.json';
import schedulePT from './assets/locales/pt/schedule.json';
import scheduleTH from './assets/locales/th/schedule.json';

import submitReportEN from './assets/locales/en/submitReport.json';
import submitReportID from './assets/locales/id/submitReport.json';
import submitReportES from './assets/locales/es/submitReport.json';
import submitReportKM from './assets/locales/km/submitReport.json';
import submitReportPT from './assets/locales/pt/submitReport.json';
import submitReportTH from './assets/locales/th/submitReport.json';

import uploadReportEN from './assets/locales/en/uploadReport.json';
import uploadReportID from './assets/locales/id/uploadReport.json';
import uploadReportES from './assets/locales/es/uploadReport.json';
import uploadReportKM from './assets/locales/km/uploadReport.json';
import uploadReportPT from './assets/locales/pt/uploadReport.json';
import uploadReportTH from './assets/locales/th/uploadReport.json';

import sagaEN from './assets/locales/en/saga.json';
import sagaID from './assets/locales/id/saga.json';
import sagaES from './assets/locales/es/saga.json';
import sagaKM from './assets/locales/km/saga.json';
import sagaPT from './assets/locales/pt/saga.json';
import sagaTH from './assets/locales/th/saga.json';

import homeEN from './assets/locales/en/home.json';
import homeID from './assets/locales/id/home.json';
import homeES from './assets/locales/es/home.json';
import homeKM from './assets/locales/km/home.json';
import homePT from './assets/locales/pt/home.json';
import homeTH from './assets/locales/th/home.json';

import authEN from './assets/locales/en/auth.json';
import authID from './assets/locales/id/auth.json';
import authES from './assets/locales/es/auth.json';
import authKM from './assets/locales/km/auth.json';
import authPT from './assets/locales/pt/auth.json';
import authTH from './assets/locales/th/auth.json';

import landingEN from './assets/locales/en/landing.json';
import landingID from './assets/locales/id/landing.json';
import landingES from './assets/locales/es/landing.json';
import landingKM from './assets/locales/km/landing.json';
import landingPT from './assets/locales/pt/landing.json';
import landingTH from './assets/locales/th/landing.json';

function getAllkeys(ob) {
  let keys = Object.keys(ob);
}

getAllkeys(settingsPT);
i18n.use(initReactI18next).init(
  {
    lng: 'en',
    fallbackLng: 'en',
    // have a common namespace used around the full app
    ns: ['common'],
    defaultNS: 'common',
    debug: true,
    // cache: {
    //   enabled: true
    // },
    resources: {
      en: {
        common: commonEN,
        site: siteEN,
        calibration: calibrationEN,
        lobby: lobbyEN,
        profile: profileEN,
        journey: journeyEN,
        issue: issueTrackerEN,
        issueMessage: issueMessageEN,
        featureAccess: featureAccessEN,
        payoff: payoffEN,
        settings: settingsEN,
        questionnaire: questionnaireEN,
        company: companyEN,
        schedule: scheduleEN,
        submitReport: submitReportEN,
        uploadReport: uploadReportEN,
        saga: sagaEN,
        home: homeEN,
        auth: authEN,
        landing: landingEN
      },
      id: {
        common: commonID,
        site: siteID,
        calibration: calibrationID,
        lobby: lobbyID,
        profile: profileID,
        journey: journeyID,
        issue: issueTrackerID,
        issueMessage: issueMessageID,
        featureAccess: featureAccessID,
        payoff: payoffID,
        settings: settingsID,
        questionnaire: questionnaireID,
        company: companyID,
        schedule: scheduleID,
        submitReport: submitReportID,
        uploadReport: uploadReportID,
        saga: sagaID,
        home: homeID,
        auth: authID,
        landing: landingID
      },
      es: {
        common: commonES,
        site: siteES,
        calibration: calibrationES,
        lobby: lobbyES,
        profile: profileES,
        journey: journeyES,
        issue: issueTrackerES,
        issueMessage: issueMessageES,
        featureAccess: featureAccessES,
        payoff: payoffES,
        settings: settingsES,
        questionnaire: questionnaireES,
        company: companyES,
        schedule: scheduleES,
        submitReport: submitReportES,
        uploadReport: uploadReportES,
        saga: sagaES,
        home: homeES,
        auth: authES,
        landing: landingES
      },
      km: {
        common: commonKM,
        site: siteKM,
        calibration: calibrationKM,
        lobby: lobbyKM,
        profile: profileKM,
        journey: journeyKM,
        issue: issueTrackerKM,
        issueMessage: issueMessageKM,
        featureAccess: featureAccessKM,
        payoff: payoffKM,
        settings: settingsKM,
        questionnaire: questionnaireKM,
        company: companyKM,
        schedule: scheduleKM,
        submitReport: submitReportKM,
        uploadReport: uploadReportKM,
        saga: sagaKM,
        home: homeKM,
        auth: authKM,
        landing: landingKM
      },
      pt: {
        common: commonPT,
        site: sitePT,
        calibration: calibrationPT,
        lobby: lobbyPT,
        profile: profilePT,
        journey: journeyPT,
        issue: issueTrackerPT,
        issueMessage: issueMessagePT,
        featureAccess: featureAccessPT,
        payoff: payoffPT,
        settings: settingsPT,
        questionnaire: questionnairePT,
        company: companyPT,
        schedule: schedulePT,
        submitReport: submitReportPT,
        uploadReport: uploadReportPT,
        saga: sagaPT,
        home: homePT,
        auth: authPT,
        landing: landingPT
      },
      th: {
        common: commonTH,
        site: siteTH,
        calibration: calibrationTH,
        lobby: lobbyTH,
        profile: profileTH,
        journey: journeyTH,
        issue: issueTrackerTH,
        issueMessage: issueMessageTH,
        featureAccess: featureAccessTH,
        payoff: payoffTH,
        settings: settingsTH,
        questionnaire: questionnaireTH,
        company: companyTH,
        schedule: scheduleTH,
        submitReport: submitReportTH,
        uploadReport: uploadReportTH,
        saga: sagaTH,
        home: homeTH,
        auth: authTH,
        landing: landingTH
      }
    },
    react: {
      wait: true,
      useSuspense: false
    },
    interpolation: {
      escapeValue: false // not needed for react as it does escape per default to prevent xss!
    }
  },
  err => {
    if (err) {
      console.log('something went wrong loading', err);
    }
  }
);

export default i18n;
