import firebase from 'firebase';
import 'firebase/database';
import 'firebase/functions';
import moment from 'moment';
import { Reducer } from 'redux';
import { action, ActionType } from 'typesafe-actions';
import { CompositeSite, InjectedSiteStatus, ThunkResult } from '../../typing/types';
import { OrganizationSchedule, Site } from '../../utils/classes';
import { currentPeriodEndDate, currentPeriodStartDate } from '../../utils/schedule';
import { getUserScheduleSites, UserSiteSchedulePayload } from '../../utils/siteSchedule';
export const FILTER_SITES = '@site/FILTER_SITES';
export const SET_SCHEDULED_SITES = '@site/SET_SCHEDULED_SITES';
export const SET_IS_FETCHING_MY_SITES = '@site/SET_IS_FETCHING_MY_SITES';
import { getServerTime } from '../../utils/getServerTime';
/**
 * True when the async function for sites fetching is running. False for otherwise.
 * @param bool
 */
const setIsFetchingMySites = (bool: boolean) => action(SET_IS_FETCHING_MY_SITES, { bool });

type MySites = { [siteKey: string]: CompositeSite };

/**
 * (Unused functions)
 * @param mySites
 * @param organizationSchedule
 */
const filterSites = (mySites: MySites, organizationSchedule: OrganizationSchedule) => {
  if (!mySites) {
    return action(FILTER_SITES, { list: [], data: {} });
  }

  const filteredSitesList: { key: string; value: CompositeSite }[] = [];
  const filteredSitesData: { [siteKey: string]: CompositeSite } = {};
  for (const siteKey of Object.keys(mySites)) {
    const currentSite: CompositeSite = mySites[siteKey];
    if ((!currentSite.siteSchedules && !currentSite.schedules) || currentSite.disabled) {
      continue;
    }
    if (currentSite.siteSchedules) {
      for (const date of Object.keys(currentSite.siteSchedules)) {
        if (date === 'assignedAuditor') {
          continue;
        }
        const isExists = currentSite.siteSchedules[date] > 0;
        if (isExists) {
          filteredSitesList.push({ key: siteKey, value: currentSite });
          filteredSitesData[siteKey] = currentSite;
          break;
        }
      }
    } else if (currentSite.schedules) {
      for (const date of Object.keys(currentSite.schedules)) {
        if (date === 'assignedAuditor') {
          continue;
        }
        const isExists = currentSite.schedules[date] > 0;
        if (isExists) {
          filteredSitesList.push({ key: siteKey, value: currentSite });
          filteredSitesData[siteKey] = currentSite;
          break;
        }
      }
    }
  }

  return action(FILTER_SITES, { list: filteredSitesList, data: filteredSitesData });
};

/**
 * Setter for scheduledSites
 * @param scheduledSites array of ScheduledSiteInfo
 */
const setMyScheduledSites = (scheduledSites: ScheduledSiteInfo[], now: number = Date.now()) =>
  action(SET_SCHEDULED_SITES, { scheduledSites, now });

/**
 * Retrieve scheduled sites from firebase
 * @param organizationSchedule
 */

const getMyScheduledSites =
  (organizationSchedule: OrganizationSchedule): ThunkResult =>
  async (dispatch, getState) => {
    const state = getState();
    const { auth, profile } = state.firebase;
    const { isConnected } = state.network;

    if (!isConnected) {
      return;
    }
    const getUserScheduledSitesFn = firebase.functions().httpsCallable('getUserScheduledSites');

    // const serverTime = moment(getServerTime());
    const serverTime = moment();
    const now = serverTime.valueOf();
    const today = serverTime.locale('en').format('YYYY-MM-DD');

    const startDateMoment = currentPeriodStartDate(organizationSchedule) || moment();
    const endDateMoment = currentPeriodEndDate(organizationSchedule, startDateMoment)!;

    let startDate = startDateMoment.format('YYYY-MM-DD');
    let endDate = endDateMoment.format('YYYY-MM-DD');

    // Limit due to performance issue
    if (endDateMoment.diff(startDateMoment, 'months') > 1) {
      startDate = moment(now).subtract(90, 'days').format('YYYY-MM-DD');
      endDate = moment(now).add(30, 'days').format('YYYY-MM-DD');
    }

    interface Data {
      userKey: string;
      organizationKey: string;
      startDate: string;
      endDate: string;
      today: string;
    }

    const data: Data = {
      userKey: auth.uid,
      organizationKey: profile.organization,
      startDate,
      endDate,
      today
    };
    // TODO - force
    dispatch(setIsFetchingMySites(true));
    let isFetching = true;
    const handleTimeout = () => {
      if (!isFetching) {
        return;
      }
      const timeoutError = new Error('fetching schedule timeout');
      console.error(timeoutError);
      dispatch(setIsFetchingMySites(false));
    };
    const timeout = setTimeout(handleTimeout, 15 * 1000);

    getUserScheduledSitesFn(data)
      .then((res: any) => {
        isFetching = false;
        clearTimeout(timeout);
        if (res.data) {
          const filteredData: any = res.data.filter((site: any) => {
            return !site.siteProfile.disabled;
          });
          dispatch(setMyScheduledSites(filteredData, now));
        }
        dispatch(setIsFetchingMySites(false));
      })
      .catch(err => {
        dispatch(setIsFetchingMySites(false));
      });
  };

export const scheduleSitesActions = {
  filterSites,
  setMyScheduledSites,
  setIsFetchingMySites
};

export const scheduleSitesThunk = {
  getMyScheduledSites
};

export interface ScheduledSiteInfo {
  isScheduledThisPeriod: boolean;
  scheduledCount: number;
  completedCount: number;
  siteProfile: Site;
  siteKey: string;
  isDraft?: boolean;
  downloadStatus: string;
  isCompletedThisPeriod?: boolean;
  isCompletedToday?: boolean;
  isMakeUp?: boolean;
  isDueToday?: boolean;
  isPendingUpload?: boolean;
  status?: InjectedSiteStatus;
  skuScheduledCount: number;
  skuCompletedCount: number;
  isSKUDraft?: boolean;
  isSKUDueToday?: boolean;
  isSKUScheduledThisPeriod?: boolean;
  isSKUCompletedThisPeriod?: boolean;
  isSKUCompletedToday?: boolean;
  yesterdayActiveScheduleCount?: number;
  yesterdayActiveCompleteCount?: number;
}

export interface ScheduledSites {
  etag: string | null;
  sites: ScheduledSiteInfo[];
}

export type ScheduleSitesAction = ActionType<typeof scheduleSitesActions>;

export type ScheduleSitesState = {
  readonly filteredSitesList: { key: string; value: CompositeSite }[];
  readonly filteredSitesData: { [siteKey: string]: CompositeSite };
  readonly sitesFiltered: boolean;
  readonly myScheduledSites: ScheduledSiteInfo[];
  readonly isFetchingMySites: boolean;
  readonly timestamp: null | number;
  readonly etag: string | null;
};

const initialState: ScheduleSitesState = {
  filteredSitesList: [],
  filteredSitesData: {},
  sitesFiltered: false,
  myScheduledSites: [],
  isFetchingMySites: false,
  timestamp: null,
  etag: null
};

export const scheduleSitesReducer: Reducer<ScheduleSitesState, ScheduleSitesAction> = (
  state = initialState,
  incomingAction
) => {
  switch (incomingAction.type) {
    case FILTER_SITES:
      return {
        ...state,
        sitesFiltered: true,
        filteredSitesList: incomingAction.payload.list,
        filteredSitesData: incomingAction.payload.data
      };
    case SET_SCHEDULED_SITES:
      return {
        ...state,
        myScheduledSites: incomingAction.payload.scheduledSites,
        timestamp: incomingAction.payload.now,
        etag: incomingAction.payload.etag
      };
    case SET_IS_FETCHING_MY_SITES:
      return {
        ...state,
        isFetchingMySites: incomingAction.payload.bool
      };
    default:
      return state;
  }
};
