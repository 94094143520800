import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View } from 'react-native';
import fontMaker from '../../utils/font';
import Text from '../global/Text';
import { SubmitReportStyles } from './SubmitReport';
import { SubmitReportContainerProps } from './SubmitReportContainer';
import SubmitReportSummary from './SubmitReportSummary';
import getReportComments from './utils/getReportComments';
import getReportScore from './utils/getReportScore';
type SubmitReportOverviewProps = Pick<SubmitReportContainerProps, 'selectedReport' | 'language'>;

/** Submit Report Overview is consist of Report Summary and Top Issues */
const SubmitReportOverview = (props: SubmitReportOverviewProps) => {
  const { language } = props;
  const { t } = useTranslation(['submitReport']);
  const { flagCount, scoreWeighted } = getReportScore(props.selectedReport);
  const comments = getReportComments(props.selectedReport);

  return (
    <View style={styles.report}>
      <Text style={styles.heading}>{t('submitReport:overview.summary')}</Text>
      <SubmitReportSummary flagCount={flagCount} scoreWeighted={scoreWeighted} language={language} />
      {comments?.length > 0 ? (
        <View style={styles.reportPoints}>
          <Text>{t('submitReport:overview.topIssues')}</Text>
          {comments.map((comment, index) => (
            <Text key={`${t('submitReport:overview.comment')}-${index + 1}`} style={styles.reportPoint}>
              - {comment}
            </Text>
          ))}
        </View>
      ) : null}
    </View>
  );
};

type SubmitReportOverviewStyles = Pick<SubmitReportStyles, 'heading' | 'report' | 'reportPoints' | 'reportPoint'>;

const styles = StyleSheet.create<SubmitReportOverviewStyles>({
  heading: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: '#cacaca',
    ...fontMaker({ weight: 'Regular' }),
    fontSize: 14
  },
  report: {
    marginBottom: 10,
    paddingHorizontal: 20
  },
  reportPoints: {
    marginTop: 5
  },
  reportPoint: fontMaker({ weight: 'Light' })
});

export default SubmitReportOverview;
