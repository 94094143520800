import React from 'react';
import { useDispatch } from 'react-redux';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack/src/types';
// import CodePush from 'react-native-code-push';

import { StyleSheet, View } from 'react-native';
import Landing from '../components/landing/Landing';
import { AuthStackParamList } from '../routes';

export type LandingPageProps = {
  navigation: StackNavigationProp<AuthStackParamList, 'Landing'>;
  route: RouteProp<AuthStackParamList, 'Landing'>;
};

// The landing page will either display an autologin loading box or the login/signup forms
const LandingPage = (props: LandingPageProps) => {
  const handleLogout = async () => {
    location.reload();
  };

  return (
    <View>
      <Landing onLogout={handleLogout} navigation={props.navigation} route={props.route} />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    backgroundColor: '#efeeed'
  }
});

export default LandingPage;
