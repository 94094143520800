import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

// types
import { RootState } from '../../../../../store/reducers';
import { ScheduledQuestionnaireProps } from './type';
import { ScheduleOption } from '../../../../../typing/types';

//components
import ScheduledQuestionnaireContainer from './ScheduledQuestionnaireContainer';

const ScheduledQuestionnaire = (props: ScheduledQuestionnaireProps) => {
  const {
    scheduleOptions,
    searchFilter,
    scheduleIsLoading,
    handleSelectSchedule,
    countReportToday,
    countReportYesterDay
  } = props;

  // redux state
  const selectedSchedule = useSelector<RootState, Partial<ScheduleOption> | null>(state => state.site.selectedSchedule);

  // state
  const [filteredScheduledData, setFilteredScheduleData] = useState<ScheduleOption[]>([]);

  /** FUNCTION */
  const filterSchedule = (filterText: string) => {
    const newFilteredSchedules = (scheduleOptions || []).filter(s => {
      const re = new RegExp(filterText, 'gi');
      return re.test(s.title);
    });
    setFilteredScheduleData(newFilteredSchedules);
    return;
  };

  const handleFilterChange = (value: string) => {
    if (value) {
      debounce(filterSchedule, 800)(value);
    } else {
      setFilteredScheduleData(scheduleOptions);
    }
  };

  useEffect(() => {
    handleFilterChange(searchFilter);
  }, [searchFilter, scheduleOptions]);

  return (
    <ScheduledQuestionnaireContainer
      selectedSchedule={selectedSchedule}
      scheduleOptions={filteredScheduledData}
      scheduleIsLoading={scheduleIsLoading}
      handleSelectSchedule={handleSelectSchedule}
      countReportToday={countReportToday}
      countReportYesterDay={countReportYesterDay}
    />
  );
};

export default ScheduledQuestionnaire;
