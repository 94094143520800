import React, { useCallback } from 'react';
import {
  StyleSheet,
  View,
  Platform,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableWithoutFeedbackProps,
  TextInput
} from 'react-native';

import Text from '../global/Text';
import { QuestionnaireQuestionProps } from './QuestionnaireQuestion';
import { questionTextInvalid } from '../../utils/report';
import theme from '../../constants/theme';
import { useTranslation } from 'react-i18next';
import QuestionnaireQuestionTutorialModal from './QuestionnaireQuestionTutorialModal';
import { FlagColor } from '../../utils/classes';

export type QuestionnaireQuestionBinaryPropsExtra = {
  styles: any;
};

export type QuestionnaireQuestionBinaryProps = QuestionnaireQuestionBinaryPropsExtra &
  Pick<
    QuestionnaireQuestionProps,
    | 'question'
    | 'index'
    | 'currentConditionIndex'
    | 'language'
    | 'questions'
    | 'commentInputRef'
    | 'currentQuestionIndex'
    | 'tutorialQuestionType'
    | 'isShowQuestionTutorialModal'
    | 'isTutorialMode'
    | 'handleInputComment'
    | 'handleTextInputFocused'
    | 'handleTextInputBlurred'
    | 'handleSelectChoice'
    | 'handleShowTutorialQuestionModal'
    | 'handleChangeTutorialQuestionType'
    | 'handleDismissTutorialQuestionModal'
    | 'handleDismissTutorialMode'
  >;

const QuestionnaireQuestionBinary = (props: QuestionnaireQuestionBinaryProps) => {
  const { t } = useTranslation(['questionnaire', 'common']);
  React.useEffect(() => {
    if (props.isTutorialMode && props.index === props.currentQuestionIndex) {
      props.handleChangeTutorialQuestionType(props.question.type);
      props.handleShowTutorialQuestionModal(true);
    }
  }, [props.index, props.currentQuestionIndex]);

  React.useEffect(() => {
    setComment(props.question.comment);
  }, [props.question.answer]);

  // temporary fix to handle input flicker and moving cursor in RN 0.63.3
  // TODO: revert this once the bug is handled, or there is a permanent fix
  const [comment, setComment] = React.useState<string>(props.question.comment);
  const handleInputComment = (text: string) => {
    setComment(text);
    props.handleInputComment(props.index, text, props.currentConditionIndex!);
  };

  /**
   * Get Flag Label based on the availability of flag label. if there's no flag label or flag label is empty string, then the functions
   * will return the default value
   * @param flagColor - targeted flag color
   */
  const getFlagLabel = useCallback(
    (flagColor: FlagColor) => {
      if (props.question.type === 'binary-with-red-flag' || props.question.type === 'binary') {
        if (props.question.flagLabel && props.question.flagLabel[flagColor]) {
          return props.question.flagLabel[flagColor];
        }
      }
      switch (flagColor) {
        case 'green':
          return props.question.type === 'binary-with-red-flag'
            ? // for binary with red flag type
              t('questionnaire:question.binary.firstYes')
            : // for binary type
              t('common:yes');
        case 'yellow':
          return t('questionnaire:question.binary.no');
        case 'red':
          return props.question.type === 'binary-with-red-flag'
            ? // for binary with red flag type
              t('questionnaire:question.binary.redFlag')
            : // for binary type
              t('common:no');
      }
    },
    [props.question]
  );

  return (
    <View>
      <QuestionnaireQuestionTutorialModal
        handleDismissTutorialQuestionModal={props.handleDismissTutorialQuestionModal}
        handleDismissTutorialMode={props.handleDismissTutorialMode}
        isShowQuestionTutorialModal={props.isShowQuestionTutorialModal}
        tutorialQuestionType={props.tutorialQuestionType}
        language={props.language}
      />
      <View style={props.styles.choicesList}>
        <Touchable
          onPress={() => props.handleSelectChoice(props.index, 'green-flag', props.currentConditionIndex!)}
          testID="button-green"
        >
          <View
            style={[
              props.question.answer === 'green-flag'
                ? [props.styles.choice, props.styles.choiceSelected]
                : props.styles.choice
            ]}
          >
            <View style={props.styles.choiceFlag}>
              <View style={props.styles.greenFlag} />
            </View>
            <Text
              style={
                props.question.answer === 'green-flag'
                  ? [props.styles.choiceText, props.styles.choiceSelectedText]
                  : props.styles.choiceText
              }
              testID="green-flag-label"
            >
              {`${getFlagLabel('green')}`}
            </Text>
          </View>
        </Touchable>
        {props.question.type === 'binary-with-red-flag' ? (
          <Touchable
            onPress={() => props.handleSelectChoice(props.index, 'yellow-flag', props.currentConditionIndex!)}
            testID="button-yellow"
          >
            <View
              style={
                props.question.answer === 'yellow-flag'
                  ? [props.styles.choice, props.styles.choiceSelected]
                  : props.styles.choice
              }
            >
              <View style={props.styles.choiceFlag}>
                <View style={props.styles.yellowFlag} />
              </View>
              <Text
                style={
                  props.question.answer === 'yellow-flag'
                    ? [props.styles.choiceText, props.styles.choiceSelectedText]
                    : props.styles.choiceText
                }
                testID="yellow-flag-label"
              >
                {`${getFlagLabel('yellow')}`}
              </Text>
            </View>
          </Touchable>
        ) : null}
        <Touchable
          onPress={() => props.handleSelectChoice(props.index, 'red-flag', props.currentConditionIndex!)}
          testID="button-red"
        >
          <View
            style={
              props.question.answer === 'red-flag'
                ? [props.styles.choice, props.styles.choiceSelected]
                : props.styles.choice
            }
          >
            <View style={props.styles.choiceFlag}>
              <View style={props.styles.redFlag} />
            </View>
            <Text
              style={
                props.question.answer === 'red-flag'
                  ? [props.styles.choiceText, props.styles.choiceSelectedText]
                  : props.styles.choiceText
              }
              testID="red-flag-label"
            >
              {`${getFlagLabel('red')}`}
            </Text>
          </View>
        </Touchable>
        {!props.question.answerRequired ? (
          <Touchable
            onPress={() => props.handleSelectChoice(props.index, 'not-applicable', props.currentConditionIndex!)}
            testID="button-na"
          >
            <View
              style={
                props.question.answer === 'not-applicable'
                  ? [props.styles.choice, props.styles.choiceSelected]
                  : props.styles.choice
              }
            >
              <View style={props.styles.choiceFlag}>
                <View style={props.styles.noFlag} />
              </View>
              <Text
                style={
                  props.question.answer === 'not-applicable'
                    ? [props.styles.choiceText, props.styles.choiceSelectedText]
                    : props.styles.choiceText
                }
              >
                N/A
              </Text>
            </View>
          </Touchable>
        ) : null}
      </View>
      <View>
        <View
          style={[
            props.styles.openAnswerInputContainer,
            props.question.answer === 'not-applicable' && props.styles.commentDisabled,
            {
              borderColor: questionTextInvalid(props.question, t)
                ? theme.colors.secondary
                : props.question.answer &&
                  (props.question.answer === 'red-flag' || props.question.answer === 'yellow-flag') &&
                  !props.question.comment
                ? theme.colors.secondary
                : 'rgba(0, 0, 0, 0.05)'
            }
          ]}
        >
          <TextInput
            style={props.styles.openCommentInput}
            maxLength={2500}
            multiline={true}
            numberOfLines={4}
            editable={props.question.answer !== 'not-applicable'}
            onChangeText={handleInputComment}
            value={comment}
            placeholder={
              props.question.answer === 'not-applicable'
                ? `${t('questionnaire:question.binary.notApplicable')}`
                : `${t('questionnaire:question.binary.comments')}${
                    props.question.answer === 'green-flag' ? t('questionnaire:question.binary.optional') : ''
                  }`
            }
            ref={props.commentInputRef}
            onFocus={() =>
              props.handleTextInputFocused(
                typeof props.currentConditionIndex! === 'number' ? props.currentConditionIndex! : props.index
              )
            }
            onBlur={() =>
              props.handleTextInputBlurred(
                typeof props.currentConditionIndex! === 'number' ? props.currentConditionIndex! : props.index
              )
            }
            testID="binary-comment"
          />
        </View>
        {!questionTextInvalid(props.question, t) ? (
          <View style={props.styles.validationContainer}>
            <Text style={props.styles.textValidation}>&zwnj;</Text>
          </View>
        ) : (
          <View style={props.styles.validationContainer}>
            <Text style={props.styles.textValidation}> {questionTextInvalid(props.question, t)} </Text>
          </View>
        )}
      </View>
    </View>
  );
};

export default QuestionnaireQuestionBinary;

const styles = StyleSheet.create({});

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
  Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;
