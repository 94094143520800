import { FederatedAuthentication } from '@nimbly-technologies/nimbly-common';
import { apiProdURL } from '../../../constants/api';
// @ts-ignore
import { OKTA_API_BASE_URL, OKTA_API_TOKEN } from '@env';
import firebase from 'firebase';
import { toastActions } from '../../../store/reducers/toast';
import { store } from '../../../store/store';

const generateResponse = async (body: any, url: string) => {
  let tempResult;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  };
  const fetchData = await fetch(url, options);
  if (fetchData?.status === 200) {
    const result = await fetchData.json();
    tempResult = result.data;
  }

  if (fetchData?.status === 409 || fetchData?.status === 400 || fetchData?.status === 401) {
    const result = await fetchData.json();
    tempResult = result.message;
  }

  if (fetchData?.ok && fetchData?.status === 500) {
    const result = await fetchData.json();
    store.dispatch(toastActions.createToast(result?.message, true));
    return;
  }
  return tempResult;
};

export const verifyEnrollment = async (token: string, tenantID: string) => {
  const url = `${apiProdURL}/auth/federated/verify-enrollment`;
  const bodySend = {
    token,
    tenantID
  };
  const getData = await generateResponse(bodySend, url);
  return getData;
};

export const requestEnrollment = async (token: string, tenant: FederatedAuthentication) => {
  const url = `${apiProdURL}/auth/user-enrollment`;
  const bodySend = {
    token,
    tenant
  };
  const getData = await generateResponse(bodySend, url);
  return getData;
};

export const generateCustomToken = async (token: string) => {
  const url = `${apiProdURL}/auth/federated/convert-token`;
  const bodySend = {
    token
  };
  const getData = await generateResponse(bodySend, url);
  return getData;
};

export const getLogoutOkta = async () => {
  try {
    const claims = await (await firebase.auth().currentUser!.getIdTokenResult()).claims;

    if (!claims?.firebase || !claims?.firebase?.sign_in_provider || !claims?.firebase?.identities) return;

    const { sign_in_provider, identities } = claims?.firebase;
    let oktaUserId = identities?.[sign_in_provider]?.[0];

    const url = `${OKTA_API_BASE_URL}/users/${oktaUserId}/sessions`;
    const options = {
      method: 'DELETE',
      headers: {
        Authorization: `SSWS ${OKTA_API_TOKEN}`
      }
    };
    await fetch(url, options);
  } catch (e) {
    // weird error cors even already added in trusted origin
    console.log(e);
  }
};
