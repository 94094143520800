import { Platform, NativeModules, Linking } from 'react-native';
import DeviceInfo from 'react-native-device-info';

/**
 * Open App Settings when permission is denied
 */
export default function openSettings() {
  if (Platform.OS === 'android') {
    NativeModules.OpenSettings.openNetworkSettings(DeviceInfo.getBundleId(), (err: any) => {
      console.log(err);
    });
  } else {
    Linking.canOpenURL('app-settings:')
      .then(supported => {
        if (supported) {
          return Linking.openURL('app-settings:');
        }
      })
      .catch(err => console.log(err));
  }
}
