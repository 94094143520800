import React from 'react';
import { StyleSheet, View } from 'react-native';
import Calibration from '../components/calibration/Calibration';

const CalibrationPage = React.memo(() => {
  return (
    <View style={styles.root}>
      <Calibration />
    </View>
  );
});

export default CalibrationPage;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#efeeed'
  }
});
