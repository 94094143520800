import React from 'react';
import {
  findNodeHandle,
  Dimensions,
  Keyboard,
  LayoutAnimation,
  Modal,
  Platform,
  ScrollView,
  StyleSheet,
  TextInput,
  TextStyle,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableWithoutFeedback,
  TouchableWithoutFeedbackProps,
  View,
  ViewStyle
} from 'react-native';

import Text from '../global/Text';

import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Button from '../global/Button';

import fontMaker from '../../utils/font';
import theme from '../../constants/theme';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../utils/screensize';
import { withTranslation, WithTranslation } from 'react-i18next';

type RecommendationModalProps = {
  showModal: boolean;
  language: string;
  options: any[];
  questionIndex: number;
  onSetRemedies: (index: number, text: string, currentConditionIndex?: number | string) => void;
  onCloseModal: () => any;
  currentConditionIndex?: string | number;
} & WithTranslation;

export class RecommendationModal extends React.Component<RecommendationModalProps, RecommendationModalState> {
  state: RecommendationModalState;

  scrollModal: React.RefObject<ScrollView> = React.createRef();
  recommendInput: React.RefObject<TextInput> = React.createRef();

  keyboardDidShowListener: any;
  keyboardDidHideListener: any;

  constructor(props: RecommendationModalProps) {
    super(props);
    this.keyboardDidShowListener = Keyboard.addListener(
      Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow',
      this.keyboardDidShow.bind(this)
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      Platform.OS === 'ios' ? 'keyboardWillHide' : 'keyboardDidHide',
      this.keyboardDidHide.bind(this)
    );
    this.state = {
      visibleHeight: Dimensions.get('window').height,
      selectedRemedy: {},
      _tempCustomRemedy: ''
    };
  }

  keyboardDidShow(e: any) {
    this.setState({ visibleHeight: height - e.endCoordinates.height }, () => {
      setTimeout(() => {
        if (this.scrollModal.current && this.scrollModal.current.scrollToEnd) {
          this.scrollModal.current.scrollToEnd();
        }
      }, 300);
    });
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }

  keyboardDidHide() {
    this.setState({ visibleHeight: height });
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
  }

  componentWillUnmount() {
    if (this.keyboardDidShowListener) {
      this.keyboardDidShowListener.remove();
    }
    if (this.keyboardDidHideListener) {
      this.keyboardDidHideListener.remove();
    }
  }

  // Scroll down to relevant textinput on focus
  handleTextInputFocused = () => {
    const { selectedRemedy, _tempCustomRemedy } = this.state;
    const { options } = this.props;
    if (this.state._tempCustomRemedy) {
      const newRemedy = { ...selectedRemedy };
      // on selecting back custom field, and previously entered text
      this.setState({
        _tempCustomRemedy: '',
        selectedRemedy: {
          ...newRemedy,
          [options.length]: _tempCustomRemedy
        }
      });
    }

    const textInput = this.recommendInput.current;
    const scroll = this.scrollModal.current;
    if (textInput && scroll) {
      textInput.measureLayout(
        findNodeHandle(scroll)!,
        (x, y) => {
          scroll.scrollTo({ x: 0, y: y - 30, animated: true });
        },
        () => ({})
      );
    }
  };

  handleSelectRecommendation = (index: number) => {
    const { selectedRemedy } = this.state;
    const { options } = this.props;
    const newRemedy: SelectedRemedy = { ...selectedRemedy };

    if (!newRemedy[index]) {
      newRemedy[index] = options[index];
    } else {
      delete newRemedy[index];
    }
    return this.setState({ selectedRemedy: newRemedy });
  };

  handleInputRecommendation = (text: string) => {
    const { selectedRemedy } = this.state;
    const { options } = this.props;

    const newRemedy: SelectedRemedy = { ...selectedRemedy };
    this.setState({
      selectedRemedy: {
        ...newRemedy,
        [options.length]: text
      }
    });
  };

  handleAddCustomRecommendation = () => {
    const { selectedRemedy, _tempCustomRemedy } = this.state;
    const customIndex = this.props.options.length;
    const newRemedy: SelectedRemedy = { ...selectedRemedy };
    let customRemedy = '';

    if (selectedRemedy[customIndex]) {
      customRemedy = newRemedy[customIndex];
      // delete the custom key
      delete newRemedy[customIndex];
      // stores the previously typed custom input to temp - to later retrieve back
      this.setState({ selectedRemedy: newRemedy, _tempCustomRemedy: customRemedy });
    } else {
      // if has previuosly typed custom input - retrieve and set as the current custom remedy
      newRemedy[customIndex] = _tempCustomRemedy ? _tempCustomRemedy : '';
      this.setState({ selectedRemedy: newRemedy }, () => {
        if (this.recommendInput.current) {
          this.recommendInput.current.focus();
        }
      });
    }
  };

  handleCloseModal = () => this.props.onCloseModal();

  handleSetRemedies = () => {
    const { questionIndex } = this.props;
    const selectedRemedies = Object.values(this.state.selectedRemedy)
      .filter(remedy => remedy)
      .join('\n\n');

    this.props.onSetRemedies(questionIndex, selectedRemedies);
    this.props.onCloseModal();
  };

  render() {
    const { selectedRemedy }: RecommendationModalState = this.state;
    const { options, t }: RecommendationModalProps = this.props;
    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={this.props.showModal}
        onRequestClose={this.handleCloseModal}
      >
        <TouchableWithoutFeedback>
          <View style={styles.overlay}>
            <View style={[styles.overlayAdjust, { height: this.state.visibleHeight - this.state.visibleHeight * 0.3 }]}>
              <View style={styles.container}>
                <View style={styles.header}>
                  <Text style={styles.headerTitle}>{t('questionnaire:recommendation.title')}</Text>
                  <Text style={styles.headerSubtitle}>{t('questionnaire:recommendation.choseRecommendation')}</Text>
                </View>
                <View
                  style={{
                    height: SCREEN_WIDTH > 500 ? 550 : 350
                  }}
                >
                  <ScrollView
                    keyboardShouldPersistTaps="always"
                    showsVerticalScrollIndicator={true}
                    showsHorizontalScrollIndicator={false}
                    contentContainerStyle={[
                      styles.scrollViewContainer,
                      { paddingBottom: styles.buttonContainer.height + 16 }
                    ]}
                    ref={this.scrollModal}
                  >
                    <View style={styles.actionContainer}>
                      {options.map((option, index) => {
                        return (
                          <View style={styles.tab} key={index}>
                            <Touchable
                              testID={`recommendation-${index}`}
                              style={Platform.OS === 'ios' ? styles.row : {}}
                              onPress={() => this.handleSelectRecommendation(index)}
                            >
                              <View style={[styles.rowDirection, Platform.OS === 'android' ? styles.row : {}]}>
                                <View style={styles.checkBoxContainer}>
                                  <View style={styles.checkBox}>
                                    {selectedRemedy[index] && (
                                      <Icon
                                        testID={`checked-icon-${index}`}
                                        name={'check'}
                                        size={18}
                                        color={theme.colors.primary}
                                      />
                                    )}
                                  </View>
                                </View>
                                <View style={styles.optionContainer}>
                                  <Text style={styles.option}>{option}</Text>
                                </View>
                              </View>
                            </Touchable>
                          </View>
                        );
                      })}
                      <View style={styles.tab}>
                        <Touchable
                          testID={'add-custom-recommendation'}
                          style={Platform.OS === 'ios' ? styles.row : {}}
                          onPress={this.handleAddCustomRecommendation}
                        >
                          <View style={[styles.rowDirection, Platform.OS === 'android' ? styles.row : {}]}>
                            <View style={styles.checkBoxContainer}>
                              <View style={styles.checkBox}>
                                {selectedRemedy[this.props.options.length] ? (
                                  <Icon
                                    testID={'checked-icon-custom'}
                                    name={'check'}
                                    size={18}
                                    color={theme.colors.primary}
                                  />
                                ) : null}
                              </View>
                            </View>
                            <View style={[styles.optionContainer, styles.customOptionContainer]}>
                              <Text style={styles.option}>Custom</Text>
                            </View>
                          </View>
                        </Touchable>
                      </View>
                      <View style={[styles.openAnswerInputContainer, , { borderColor: 'rgba(0, 0, 0, 0.05)' }]}>
                        <TextInput
                          testID={'custom-recommendation'}
                          style={styles.openAnswerInput}
                          maxLength={2500}
                          multiline={true}
                          numberOfLines={3}
                          onChangeText={text => this.handleInputRecommendation(text)}
                          value={
                            this.state.selectedRemedy[options.length]
                              ? this.state.selectedRemedy[options.length]
                              : this.state._tempCustomRemedy
                          }
                          placeholder={t('questionnaire:recommendation.placeholder')}
                          ref={this.recommendInput}
                          onFocus={this.handleTextInputFocused}
                        />
                      </View>
                    </View>
                  </ScrollView>
                </View>
                <View style={styles.buttonContainer}>
                  <Button
                    testID={'set-custom-recommendation'}
                    style={styles.button}
                    onPress={() => {
                      Keyboard.dismiss();
                      this.handleSetRemedies();
                    }}
                    title={t('questionnaire:recommendation.set')}
                  />
                </View>
              </View>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    );
  }
}

type SelectedRemedy = {
  [index: number]: string;
};

type RecommendationModalState = {
  visibleHeight: number;
  selectedRemedy: SelectedRemedy;
  _tempCustomRemedy: string;
};

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> = TouchableOpacity;

const { width, height } = Dimensions.get('screen');
export default withTranslation(['questionnaire'])(RecommendationModal) as any;
const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  overlayAdjust: {
    width: '100%',
    top: height * 0.03,
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    width: SCREEN_WIDTH > 500 ? width * 0.4 : width * 0.9,
    borderRadius: 5,
    maxHeight: height * 0.9,
    overflow: 'hidden'
  },
  header: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 12,
    paddingBottom: 8,
    paddingHorizontal: 16
  },
  headerTitle: { ...fontMaker({ weight: 'SemiBold' }) },
  headerSubtitle: {
    ...fontMaker({ weight: 'Light' }),
    textAlign: 'center',
    marginVertical: 6
  },
  actionContainer: {
    width: '100%'
  },
  scrollViewContainer: {
    paddingHorizontal: 12
  },
  row: {
    width: '100%',
    paddingHorizontal: 10
  },
  rowDirection: {
    flexDirection: 'row',
    alignItems: 'flex-start'
  },
  checkBoxContainer: {
    width: '10%',
    position: 'relative',
    top: 18
  },
  checkBox: {
    height: 20,
    width: 20,
    borderColor: theme.colors.dark,
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 2
  },
  tab: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  optionContainer: {
    width: '87.5%',
    paddingVertical: 18,
    paddingLeft: 15
  },
  customOptionContainer: {
    paddingTop: 18,
    paddingBottom: 20
  },
  option: {
    textAlign: 'left',
    marginLeft: 5,
    ...fontMaker({ weight: 'normal' }),
    color: theme.colors.tertiaryLight
  },
  boldOption: {
    ...fontMaker({ weight: 'SemiBold' })
  },
  openAnswerInput: {
    maxHeight: 150,
    ...fontMaker({ weight: 'Regular' }),
    color: theme.colors.dark,
    textAlignVertical: 'top',
    padding: 10,
    width: 300
  },
  openAnswerInputContainer: {
    justifyContent: 'flex-start',
    paddingVertical: 10,
    borderWidth: 1,
    marginBottom: 50,
    borderColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 6
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: 72,
    justifyContent: 'center',
    alignItems: 'center',
    // shadow
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.25,
    shadowRadius: 6
  },
  button: {
    width: SCREEN_WIDTH > 500 ? width * 0.2 : width * 0.6
  }
});
