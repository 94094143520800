import React from 'react';
import { StyleSheet, View } from 'react-native';

import Text from '../global/Text';
import fontMaker from '../../utils/font';

import SubmitReportSummary from './SubmitReportSummary';
import { SubmitReportContainerProps } from './SubmitReportContainer';
import { SubmitReportStyles } from './SubmitReport';
import getReportScore from './utils/getReportScore';
import { useTranslation } from 'react-i18next';
import getSATReportComments from './utils/getSATReportComments';
type SubmitSATReportOverviewProps = Pick<
  SubmitReportContainerProps,
  'selectedReport' | 'language' | 'selectedSiteValue' | 'latestUploadedSection'
>;

/** Submit Report Overview is consist of Report Summary and Top Issues */
const SubmitSATReportOverview = (props: SubmitSATReportOverviewProps) => {
  const { language, selectedSiteValue } = props;
  const { t } = useTranslation(['submitReport']);
  const { flagCount, scoreWeighted } = getReportScore(props.selectedReport);
  const sectionIdx = selectedSiteValue?.sectionIdx;
  const latestSections: any = Object.values(props.latestUploadedSection);
  const sat_questionnaires =
    sectionIdx !== undefined ? latestSections[sectionIdx].questions : props.selectedReport.questions;
  const comments = getSATReportComments(sat_questionnaires);

  const getQuestionOrAnswer = (text: string, type: string) => {
    if (!text) {
      return;
    }

    switch (type) {
      case 'question':
        return `${text.split(':')[0]}`;
      case 'answer':
        return `${text.split(':')[1]}`;
      default:
        return '';
    }
  };

  return (
    <View style={styles.report}>
      <Text style={styles.heading}>{t('submitReport:overview.summary')}</Text>
      <SubmitReportSummary flagCount={flagCount} scoreWeighted={scoreWeighted} language={language} />
      <View style={styles.reportPoints}>
        <Text style={styles.heading}>{t('submitReport:overview.questionsAnswers')}</Text>
        {comments.map((comment, index) => (
          <View style={styles.reportPoint} key={`${t('submitReport:overview.comment')}-${index + 1}`}>
            <View style={styles.textQuestion}>
              <Text>{getQuestionOrAnswer(comment, 'question')}</Text>
            </View>
            <View style={styles.textAnswer}>
              <Text>{getQuestionOrAnswer(comment, 'answer')}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

type SubmitSATReportOverviewStyles = Pick<
  SubmitReportStyles,
  'heading' | 'report' | 'reportPoints' | 'reportPoint' | 'textAnswer' | 'textQuestion' | 'text'
>;

const styles = StyleSheet.create<SubmitSATReportOverviewStyles>({
  heading: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: '#cacaca',
    fontWeight: '600',
    fontSize: 14,
    marginBottom: 8
  },
  report: {
    marginBottom: 10,
    paddingHorizontal: 20
  },
  reportPoints: {
    marginTop: 15,
    marginBottom: 15
  },
  textQuestion: {
    flex: 1,
    textAlignVertical: 'center',
    textAlign: 'left',
    backgroundColor: '#F9F8F8',
    marginRight: 8,
    marginBottom: 8,
    padding: 8,
    whiteSpace: 'nowrap'
  },
  textAnswer: {
    flex: 1,
    lineHeight: 18.4,
    height: 'calc(100% - 8px)',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundColor: '#F9F8F8',
    marginBottom: 8,
    padding: 8
  },
  text: {
    whiteSpace: 'nowrap'
  },
  reportPoint: {
    ...fontMaker({ weight: 'Light' }),
    flexDirection: 'row',
    alignItems: 'center'
  }
});

export default SubmitSATReportOverview;
