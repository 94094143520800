import React, { useState } from 'react';
import {
  StyleSheet,
  Platform,
  TouchableWithoutFeedbackProps,
  TouchableNativeFeedback,
  TouchableOpacity,
  Text,
  View
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
// import MapView, { Marker, Region, EdgeInsets } from 'react-native-maps';

// utils
import { SCREEN_HEIGHT } from '../../utils/screensize';

// types
import { JourneyMapProps } from './type';

const JourneyMap = (props: JourneyMapProps) => {
  const { journeySites, visitedSites } = props;

  const [initialRegion] = useState<Region>({
    latitude: journeySites[0].coordinates ? journeySites[0].coordinates.latitude : 0,
    longitude: journeySites[0].coordinates ? journeySites[0].coordinates.longitude : 0,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421
  });

  const [legalLabelInsets] = useState<EdgeInsets>({
    top: 0,
    left: 0,
    bottom: 0,
    right: 40
  });

  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);

  const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
    Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

  return (
    <>
      {/* <MapView
        style={[styles.map, isFullScreen && styles.fullscreen]}
        initialRegion={initialRegion}
        legalLabelInsets={legalLabelInsets}
      >
        {journeySites.map((site: any, index: number) => (
          <Marker
            key={site.siteID}
            coordinate={{
              latitude: site.coordinates.latitude,
              longitude: site.coordinates.longitude
            }}
          >
            <View style={[styles.circle, visitedSites.includes(site.siteID) && styles.visitedColor]}>
              <Text style={styles.pinText}>{index + 1}</Text>
            </View>
          </Marker>
        ))}
      </MapView> */}
      <Touchable
        style={styles.icon}
        onPress={() => {
          setIsFullScreen(!isFullScreen);
        }}
      >
        <Icon style={styles.icon} name={isFullScreen ? 'fullscreen-exit' : 'fullscreen'} size={26} color={'black'} />
      </Touchable>
    </>
  );
};

export default JourneyMap as any;

const styles = StyleSheet.create({
  map: {
    width: '100%',
    height: 200
  },
  fullscreen: {
    height: SCREEN_HEIGHT - 100
  },
  icon: {
    position: 'absolute',
    right: 0
  },
  circle: {
    width: 30,
    height: 30,
    borderRadius: 30 / 2,
    backgroundColor: '#fc464f',
    justifyContent: 'center'
  },
  pinText: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 18
  },
  visitedColor: {
    backgroundColor: '#56da6a'
  }
});
