import React from 'react';
import { useSelector } from 'react-redux';
import { useIsFocused } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack/src/types';

import { RootState } from '../store/reducers';

import { StyleSheet, View } from 'react-native';
import SiteList from '../components/schedule/SiteList';

import { ScheduleStackParamList } from '../routes';

type ScheduleTabProps = { navigation: StackNavigationProp<ScheduleStackParamList, 'Schedule'> };

const ScheduleTab = (props: ScheduleTabProps) => {
  const profileColor = useSelector<RootState, string>(state => state.profiletheme.color);
  const isFocused = useIsFocused();

  React.useEffect(() => {
    props.navigation.setParams({ profileColor: profileColor });
  }, []);

  return (
    <View style={styles.root}>
      <SiteList isFocused={isFocused} />
    </View>
  );
};

export default ScheduleTab;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#efeeed'
  }
});
