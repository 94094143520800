import moment from 'moment';
import { Organization, statistic, OrganizationSchedule } from 'nimbly-common';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import firebase from 'firebase';

import { currentPeriodStartDate } from '../../../../utils/schedule';
import API from '../../../helpers/api';
import { RootState } from '../../../reducers';
import * as actions from './action';
import { apiProdURL } from '../../../../constants/api';

// SELECTOR
const selectAuth = (state: RootState) => state.firebase.auth;
const selectOrganization = (state: RootState) => state.organization.myOrganization;

// CONSTANT
const UNAUTHORIZED = 'unauthorized';
const INVALID_ORGANIZATION = 'invalid-organization';
const UNKNOWN_ERROR = 'unknown-error';

const getWeeklyDates = (
  organizationSchedule: OrganizationSchedule
): {
  startDate: string;
  endDate: string;
} => {
  const startMoment = currentPeriodStartDate(organizationSchedule) || moment().startOf('isoWeek');
  const startDate = startMoment.format('YYYY-MM-DD');
  const endDate = startMoment.clone().add(6, 'day').format('YYYY-MM-DD');

  return {
    startDate,
    endDate
  };
};

function* fetchWeeklyCompletion() {
  const auth = yield select(selectAuth);
  if (!auth) {
    yield put(actions.fetchWeeklyCompletion.failure({ error: UNAUTHORIZED }));
    return;
  }
  const uid: string = auth.uid;

  const organization: Organization = yield select(selectOrganization);
  if (!organization) {
    yield put(actions.fetchWeeklyCompletion.failure({ error: INVALID_ORGANIZATION }));
    return;
  }

  const { startDate, endDate } = getWeeklyDates(organization.schedule);

  let token: string;
  try {
    token = yield call(API.getToken);
  } catch (error) {
    yield put(actions.fetchWeeklyCompletion.failure({ error: error.message || UNKNOWN_ERROR }));
    return;
  }

  // TODO: input real statistic url
  const statisticURL = `${apiProdURL}/statistics/users/${uid}/daily`;
  const query = {
    startDate,
    endDate,
    periodType: 'custom'
  };

  const qs = API.makeQueryString(query);
  const api = new API({ authorization: token });

  try {
    const res = yield call(api.getJSON, `${statisticURL}?${qs}`);
    if (res === null) {
      yield put(actions.fetchWeeklyCompletion.failure({ error: UNKNOWN_ERROR }));
      return;
    }

    const data: { daily: statistic.UserOneCompletionDaily[]; overview: statistic.UserCompletionOverview } = res.data;
    yield put(actions.fetchWeeklyCompletion.success(res.data));
  } catch (error) {
    // TODO: Logger
    yield put(actions.fetchWeeklyCompletion.failure({ error: UNKNOWN_ERROR }));
  }
}

function* fetchWeeklyFlagOverview() {
  const auth = yield select(selectAuth);
  if (!auth) {
    yield put(actions.fetchWeeklyFlagOverview.failure({ error: UNAUTHORIZED }));
    return;
  }
  const uid: string = auth.uid;

  const organization: Organization = yield select(selectOrganization);
  if (!organization) {
    yield put(actions.fetchWeeklyFlagOverview.failure({ error: INVALID_ORGANIZATION }));
    return;
  }

  const { startDate, endDate } = getWeeklyDates(organization.schedule);

  let token: string;
  try {
    token = yield call(API.getToken);
  } catch (error) {
    yield put(actions.fetchWeeklyFlagOverview.failure({ error: error.message || UNKNOWN_ERROR }));
    return;
  }

  // TODO: input real statistic url
  const statisticURL = `${apiProdURL}/statistics/flags/users/${uid}`;
  const query = {
    startDate,
    endDate,
    periodType: 'custom'
  };

  const qs = API.makeQueryString(query);
  const api = new API({ authorization: token });

  try {
    const res = yield call(api.getJSON, `${statisticURL}?${qs}`);
    if (res === null) {
      yield put(actions.fetchWeeklyFlagOverview.failure({ error: UNKNOWN_ERROR }));
      return;
    }

    const data: { scheduled: statistic.QuestionFlagOverview; adhoc: statistic.QuestionFlagOverview } = res.data;
    yield put(actions.fetchWeeklyFlagOverview.success(data));
  } catch (error) {
    // TODO: Logger
    yield put(actions.fetchWeeklyFlagOverview.failure({ error: UNKNOWN_ERROR }));
  }
}

function* fetchMonthlyCompletion() {
  const auth = yield select(selectAuth);
  const uid: string = auth.uid;

  let token: string;
  try {
    token = yield call(API.getToken);
  } catch (error) {
    yield put(actions.fetchWeeklyCompletion.failure({ error: error.message || UNKNOWN_ERROR }));
    return;
  }

  // const serverTime = firebase.database().getServerTime() || new Date();

  const startDate = moment().startOf('month').format('YYYY-MM-DD');
  const endDate = moment().endOf('month').format('YYYY-MM-DD');

  const statisticURL = `${apiProdURL}/statistics/users/${uid}/daily`;
  const query = {
    startDate,
    endDate,
    periodType: 'custom'
  };

  const qs = API.makeQueryString(query);
  const api = new API({ authorization: token });

  try {
    const res = yield call(api.getJSON, `${statisticURL}?${qs}`);
    if (res === null) {
      yield put(actions.fetchMonthlyCompletion.failure({ error: UNKNOWN_ERROR }));
      return;
    }

    const data: { daily: statistic.UserOneCompletionDaily[]; overview: statistic.UserCompletionOverview } = res.data;
    yield put(actions.fetchMonthlyCompletion.success(data));
  } catch (error) {
    // TODO: Logger
    yield put(actions.fetchMonthlyCompletion.failure({ error: UNKNOWN_ERROR }));
  }
}

function* fetchMonthlyFlagOverview() {
  const auth = yield select(selectAuth);
  if (!auth) {
    yield put(actions.fetchMonthlyFlagOverview.failure({ error: UNAUTHORIZED }));
    return;
  }
  const uid: string = auth.uid;

  const organization: Organization = yield select(selectOrganization);
  if (!organization) {
    yield put(actions.fetchMonthlyFlagOverview.failure({ error: INVALID_ORGANIZATION }));
    return;
  }

  // const serverTime = firebase.database().getServerTime() || new Date();

  const startDate = moment().startOf('month').format('YYYY-MM-DD');
  const endDate = moment().endOf('month').format('YYYY-MM-DD');

  let token: string;
  try {
    token = yield call(API.getToken);
  } catch (error) {
    yield put(actions.fetchMonthlyFlagOverview.failure({ error: error.message || UNKNOWN_ERROR }));
    return;
  }

  // TODO: input real statistic url
  const statisticURL = `${apiProdURL}/statistics/flags/users/${uid}`;
  const query = {
    startDate,
    endDate,
    periodType: 'custom'
  };

  const qs = API.makeQueryString(query);
  const api = new API({ authorization: token });

  try {
    const res = yield call(api.getJSON, `${statisticURL}?${qs}`);
    if (res === null) {
      yield put(actions.fetchMonthlyFlagOverview.failure({ error: UNKNOWN_ERROR }));
      return;
    }

    const data: { scheduled: statistic.QuestionFlagOverview; adhoc: statistic.QuestionFlagOverview } = res.data;
    yield put(actions.fetchMonthlyFlagOverview.success(data));
  } catch (error) {
    // TODO: Logger
    yield put(actions.fetchMonthlyFlagOverview.failure({ error: UNKNOWN_ERROR }));
  }
}

export function* profileStatisticWatcher() {
  yield takeLatest(actions.fetchWeeklyCompletion.request, fetchWeeklyCompletion);
  yield takeLatest(actions.fetchWeeklyFlagOverview.request, fetchWeeklyFlagOverview);

  yield takeLatest(actions.fetchMonthlyCompletion.request, fetchMonthlyCompletion);
  yield takeLatest(actions.fetchMonthlyFlagOverview.request, fetchMonthlyFlagOverview);
}
