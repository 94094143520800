import React from 'react';
import { useTranslation } from 'react-i18next';

import { Platform, Dimensions, StyleSheet, View, ImageBackground, StatusBar } from 'react-native';
import Text from '../global/Text';
import Button from '../global/Button';
import theme from '../../constants/theme';
import fontMaker from '../../utils/font';
import AppStatusBar from '../global/StatusBar';
import { Site, FlagCount } from '../../utils/classes';
import PayoffScores from './PayoffScores';
import { PayoffStyles } from './Payoff';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../utils/screensize';

export type PayOffContainerProps = {
  /** Language to be used throughout the app */
  language?: string;
  /** Chosen Site based on the report */
  selectedSite: Site;
  /** Chosen Journey Plan if exist */
  journey: any;
  /** Number of flag for each color (red, yellow, green) */
  flagCount: null | FlagCount;
  /** Score result for (in integer) */
  scoreRaw: null | number;
  /** Score result for (in integer) */
  scoreTotal: null | number;
  /** Score result for (in percentage) */
  scoreWeighted: null | number;
  /** Date of auditor visit */
  dateVisit: null | string;
  /** Time of auditor visit */
  timeVisit: null | string;
  /** How long is the audit going on */
  timeDuration: null | string;
  /** Handle - Navigate to home tab */
  handleNavHome: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-var-requires
const backgroundUrl = require('../../assets/img/payoff-rocket.png');

const PayOffContainer = (props: PayOffContainerProps) => {
  const {
    selectedSite,
    journey,
    dateVisit,
    timeVisit,
    timeDuration,
    flagCount,
    scoreRaw,
    scoreTotal,
    scoreWeighted
  } = props;

  const { t } = useTranslation(['common', 'payoff']);

  return (
    <View style={styles.root}>
      <ImageBackground source={backgroundUrl.default} style={styles.image} resizeMode="contain" />
      {Platform.OS === 'ios' ? <AppStatusBar backgroundColor="#3cd070" barStyle="dark-content" /> : null}
      <View style={styles.body}>
        <View style={[styles.section, styles.upperHalf]}>
          <Text style={styles.subtitle}>{selectedSite && selectedSite.name ? selectedSite.name : ''}</Text>
          <Text style={styles.title}>{t('payoff:reportComplete')}</Text>
        </View>
        <View style={[styles.section, styles.bottomHalf]}>
          <View style={styles.scoreContainer}>
            <PayoffScores flagCount={flagCount} scoreWeighted={scoreWeighted} />
          </View>
          <Text style={styles.bodyText}>{t('payoff:generateReport')}</Text>
          <View style={styles.dateContainer}>
            <View style={styles.contentRow}>
              <Text style={styles.contentTitle}>{t('payoff:dateOfVisit')}</Text>
              <Text style={styles.contentText}>{dateVisit}</Text>
            </View>
            <View style={styles.contentRow}>
              <Text style={styles.contentTitle}>{t('payoff:timeOfVisit')}</Text>
              <Text style={styles.contentText}>{timeVisit}</Text>
            </View>
            <View style={styles.contentRow}>
              <Text style={styles.contentTitle}>{t('payoff:duration')}</Text>
              <Text style={styles.contentText}>{timeDuration}</Text>
            </View>
            {scoreTotal !== null && scoreTotal > 0 ? (
              <View style={styles.contentRow}>
                <Text style={styles.contentTitle}>{t('payoff:totalScore')}</Text>
                <Text style={styles.contentText}>
                  {+scoreRaw!.toFixed(2)}/{+scoreTotal.toFixed(2)}
                </Text>
              </View>
            ) : null}
          </View>
        </View>
      </View>
      <View style={styles.footer}>
        <Button
          onPress={props.handleNavHome}
          title={journey ? t('payoff:backToJourneyPlan') : t('payoff:gotIt').toUpperCase()}
          backgroundColor={theme.colors.primary}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1
  },
  image: {
    position: 'absolute',
    top: '-11.5%',
    right: '0.5%',
    height: '100%',
    width: SCREEN_WIDTH > 500 ? '80%' : '100%'
  },
  body: {
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingTop: SCREEN_HEIGHT * 0.075,
    marginBottom: 5 + SCREEN_HEIGHT * 0.1,
    paddingHorizontal: 5
  },
  section: {
    alignItems: 'center'
  },
  upperHalf: {
    flex: 2
  },
  bottomHalf: {
    flex: 3,
    justifyContent: 'flex-end'
  },
  title: {
    marginBottom: 20,
    color: '#00695c',
    ...fontMaker({ weight: 'Bold' }),
    fontSize: 34,
    lineHeight: 38,
    textAlign: 'center'
  },
  subtitle: {
    marginTop: 12,
    marginBottom: 2,
    color: '#00695c',
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 24,
    lineHeight: 28,
    textAlign: 'center'
  },
  bodyText: {
    paddingTop: 8,
    color: '#a8a8aa',
    ...fontMaker({ weight: 'Normal' }),
    textAlign: 'center'
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: '#fff',
    justifyContent: 'center',
    minHeight: 64,
    height: SCREEN_HEIGHT * 0.1,
    paddingVertical: 10,
    paddingHorizontal: SCREEN_WIDTH > 500 ? 250 : SCREEN_WIDTH * 0.2,
    // shadow properties
    shadowColor: '#919191',
    shadowRadius: 6,
    shadowOpacity: 0.7,
    shadowOffset: { width: 1, height: 1 },
    elevation: 3
  },
  scoreContainer: {
    flexDirection: 'row'
  },
  dateContainer: {
    flexDirection: 'column',
    marginVertical: 24,
    marginHorizontal: 'auto',
    width: SCREEN_WIDTH > 500 ? 500 : SCREEN_WIDTH,
    marginLeft: SCREEN_WIDTH > 500 ? 0 : 80
  },
  contentRow: {
    flexDirection: 'row',
    width: SCREEN_WIDTH,
    height: 24
  },
  contentTitle: {
    flex: SCREEN_WIDTH > 500 ? 2 : 9,
    fontSize: 12,
    color: theme.colors.gray,
    ...fontMaker({ weight: 'Normal' })
  },
  contentText: {
    flex: 9,
    fontSize: 14,
    color: theme.colors.darkgray,
    ...fontMaker({ weight: 'SemiBold' })
  }
});

export default PayOffContainer;
