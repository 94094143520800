import moment from 'moment';
export const getTodayMinutes = (givenDate: Date) => {
  const momentDate = moment(givenDate);
  const startOfDay = momentDate.clone().startOf('day');
  const minutesSinceStartOfDay = momentDate.diff(startOfDay, 'minutes');
  return minutesSinceStartOfDay;
};

export const getEndTimeMinutes = (startDate: Date, endDate: Date) => {
  if (moment(startDate).isSame(endDate, 'date')) {
    return getTodayMinutes(endDate);
  } else {
    return getTodayMinutes(endDate) + 1440;
  }
};
