import React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import DeviceInfo from 'react-native-device-info';

// redux
import { RootState } from '../../store/reducers';

// utils
import Text from '../global/Text';

// type
type HeaderBarProps = {
  title: { en: string; id: string };
  themeColor?: string;
};

const HeaderBar = (props: HeaderBarProps) => {
  const { title, themeColor } = props;

  const navigation = useNavigation();

  // redux state
  const language = useSelector<RootState, 'en' | 'id'>(state => state.firebase.profile.language);

  const onPressBack = () => {
    navigation.goBack();
  };

  return (
    <View style={styles.container}>
      <View style={[styles.shapeContainer]}>
        <TouchableOpacity style={styles.backButton} onPress={() => onPressBack()}>
          <Icon name="arrow-left" size={28} color="#ffffff" />
        </TouchableOpacity>

        <Text style={styles.title} numberOfLines={1}>
          {title[language || 'en']}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: { backgroundColor: '#ffffff' },
  shapeContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    height: DeviceInfo.hasNotch() ? 90 : 65,
    backgroundColor: '#00AB4E',
    borderBottomLeftRadius: 40,
    padding: 15,
    paddingLeft: 18
  },
  backButton: {
    marginTop: 32
  },
  title: { fontSize: 24, lineHeight: 32, color: '#ffffff', fontWeight: 'normal' }
});

export default HeaderBar;
