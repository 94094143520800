import React from 'react';
import { StyleSheet, View } from 'react-native';
import moment from 'moment';

import Text from '../../../../global/Text';

export type SiteReportFilterCalendarDaysProps = {
  /** The current Month chosen */
  currentMonth: number;
};

/**
 * Issue Modal Calendar Days generates initial name of the day (i.e. Mon, Tue, ..., Sun)
 */
const SiteReportFilterCalendarDays = (props: SiteReportFilterCalendarDaysProps) => {
  const { currentMonth } = props;
  const dateFormat = 'ddd';
  const days = [];
  const startDate = moment(currentMonth)
    .utc()
    .startOf('month')
    // 1 is for Monday - later move into redux listen for organization props
    .isoWeekday(1);
  for (let i = 0; i < 7; i += 1) {
    const localStartDate = moment(startDate).local();
    days.push(
      <View style={styles.dayContainer} key={i}>
        <Text style={styles.dayText} key={i}>
          {localStartDate.add(i, 'days').format(dateFormat)}
        </Text>
      </View>
    );
  }
  return (
    <View style={styles.daysContainer} testID="SiteReportFilterCalendarDays-container">
      {days}
    </View>
  );
};

const styles = StyleSheet.create({
  daysContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginVertical: 5
  },
  dayContainer: {
    flex: 1,
    alignItems: 'center'
  },
  dayText: {
    color: '#BDBDBD',
    fontWeight: '600',
    fontSize: 11
  }
});

export default SiteReportFilterCalendarDays;
