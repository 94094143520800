import React, { useState, useRef, useEffect } from 'react';
import { StyleSheet, View, Image, Dimensions, ActivityIndicator, ImageSourcePropType } from 'react-native';
import fontMaker from '../../utils/font';
import Text from '../global/Text';

const useImageLoaded = () => {
  const [loaded, setLoaded] = useState(false);
  const [imageError, setImageError] = useState(false);
  const ref = useRef();

  const onLoad = () => {
    setLoaded(true);
  };

  const onError = () => {
    setImageError(true);
  };
  useEffect(() => {
    if (ref?.current && ref?.current?.complete) {
      onLoad();
    }
  });

  return [ref, loaded, onLoad, imageError, onError];
};

const AvatarNew = (props: any) => {
  const { source, name, type } = props;
  const [ref, loaded, onLoad, imageError, onError] = useImageLoaded();
  if (loaded && imageError) {
    if (type === 'member') {
      return (
        <View style={styles.avatarBackgroundLobby}>
          <Text style={styles.avatarNameLobby}>{name}</Text>
        </View>
      );
    } else {
      return (
        <View style={[styles.avatar, styles.leadBorder]}>
          <View style={styles.avatarBackground}>
            <Text style={[styles.avatarName]}>{name}</Text>
          </View>
        </View>
      );
    }
  }
  return (
    <img
      src={source.uri}
      alt={source.uri}
      style={{ zIndex: 1, width: 64, height: 64, borderRadius: 75 / 2, objectFit: 'cover' }}
      onError={onError}
      onLoad={onLoad}
      ref={ref}
    />
  );
};
const styles = StyleSheet.create({
  avatarBorder: {
    borderWidth: 2,
    borderColor: '#3cd070',
    zIndex: 2
  },
  avatarImage: {
    zIndex: 1,
    width: 46,
    height: 46,
    borderRadius: 46 / 2,
    resizeMode: 'cover'
  },
  completed: {
    color: '#fff'
  },
  avatar: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 6,
    width: 56,
    height: 56,
    borderRadius: 56 / 2,
    overflow: 'visible',
    backgroundColor: 'transparent',
    padding: 1
  },
  avatarBackground: {
    backgroundColor: '#439889',
    height: 46,
    width: 46,
    borderRadius: 46 / 2,
    padding: 2,
    justifyContent: 'center',
    alignContent: 'center'
  },
  avatarBackgroundLobby: {
    zIndex: 1,
    backgroundColor: '#439889',
    height: 64,
    width: 64,
    borderRadius: 64 / 2,
    padding: 2,
    justifyContent: 'center',
    alignContent: 'center'
  },
  avatarNameLobby: {
    textAlign: 'center',
    fontSize: 26,
    ...fontMaker({ weight: 'Bold' }),
    color: '#fff'
  },
  avatarName: {
    textAlign: 'center',
    fontSize: 22,
    ...fontMaker({ weight: 'Bold' }),
    color: '#1b2133'
  },
  leadBorder: {
    borderWidth: 2,
    borderColor: '#3cd070',
    zIndex: 2
  }
});
export default AvatarNew;
