import { action, createAsyncAction } from 'typesafe-actions';
import * as types from './siteReport.actionType';
import * as Common from 'nimbly-common';
import { getDatesByPeriod } from './utils/date';
import { Moment } from 'moment';

export const fetchSiteReportsAsync = createAsyncAction(
  types.FETCH_SITE_REPORTS_REQUEST,
  types.FETCH_SITE_REPORTS_SUCCESS,
  types.FETCH_SITE_REPORTS_FAILURE
)<
  { clearList: boolean; siteID: string },
  { reports: (Common.SiteReportSummary & { questionnaire: Common.Questionnaire })[]; total: number },
  { error: string }
>();

export const setCustomDates = (startDate: Moment | null, endDate: Moment | null) =>
  action(types.SET_CUSTOM_DATES, { startDate, endDate });

export const setPeriodType = (periodType: Common.enums.PeriodTypes | null) =>
  action(types.SET_PERIOD_TYPE, { periodType });

export const setSortDirection = (sortDirection: 'asc' | 'desc') =>
  action(types.SET_FILTERED_SORT_DIRECTION_TEMP, { sortDirection });

export const setSitesFilterQuery = (text: string) => action(types.SET_FILTER_QUERY, { text });

export const setPageIndexReport = (value: number) => action(types.SET_PAGE_INDEX_REPORT, { value });

export const setResetTemporaryReport = () => action(types.RESET_SITE_REPORT_FILTER);

export const setBackTemporaryReport = () => action(types.SET_BACK_TEMPORARY_REPORT);

export const setApplyReportFilter = () => action(types.APPLY_SITE_REPORT_FILTER);

export const setSiteReportFilteredStartDateTemp = (startDate: string | null) =>
  action(types.SET_FILTERED_START_DATE_TEMP, { startDate });

export const setSiteReportsFilteredEndDateTemp = (endDate: string | null) =>
  action(types.SET_FILTERED_END_DATE_TEMP, { endDate });

export const setFilteredTempDates = (
  periodType: (Common.enums.PeriodTypes & 'today') | null,
  orgSchedule: Common.OrganizationSchedule,
  date: { customStart: Moment; customEnd: Moment } | null = null
) => {
  const { startDate, endDate } = getDatesByPeriod(periodType, orgSchedule, date);
  return action(types.SET_FILTERED_DATES_TEMP, {
    startDate: startDate,
    endDate: endDate,
    periodType
  });
};

export const setDates = (
  periodType: (Common.enums.PeriodTypes & 'today') | null,
  orgSchedule: Common.OrganizationSchedule,
  date: { customStart: Moment; customEnd: Moment } | null = null
) => {
  const { startDate, endDate } = getDatesByPeriod(periodType, orgSchedule, date);
  return action(types.SET_DATES, {
    startDate: startDate,
    endDate: endDate,
    periodType
  });
};
