/* eslint-disable max-params */
import { enums } from '@nimbly-technologies/nimbly-common';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { SelectedSite } from '../Site/type';
import getScheduleDateTime, { GetScheduleDateTimeArgs } from './getScheduleDateTime';

export interface ScheduleCheckInOutPermissionArgs {
  selectedSchedule: GetScheduleDateTimeArgs['schedule'] | null;
  isAdhoc: boolean;
  selectedSite: SelectedSite;
  roleAccessAdhoc: boolean;
  featureAccessAdhoc: boolean;
  selectedCalendarDate: string;
  lastSiteDateTimeUpdated?: number;
}

function useScheduleCheckInOutPermission({
  isAdhoc,
  selectedSchedule,
  selectedCalendarDate
}: Pick<ScheduleCheckInOutPermissionArgs, 'isAdhoc' | 'selectedSchedule' | 'selectedCalendarDate'>) {
  const selectedSite = useSelector((state: RootState) => state.site.selectedSite!);
  const roleAccessAdhoc = useSelector(
    (state: RootState) => state.account.role?.resourceMap['application:report:adhoc'].create || false
  );
  const featureAccessAdhoc = useSelector((state: RootState) =>
    Boolean(state.featureAccess.features[enums.Features.ADHOC_REPORTING])
  );

  const lastUpdated = useSelector((state: RootState) => state.siteschedule[`${selectedSite.siteID}`]?.lastUpdated);

  return useMemo(
    () =>
      getScheduleCheckInOutPermission({
        featureAccessAdhoc,
        isAdhoc,
        roleAccessAdhoc,
        selectedSchedule,
        selectedSite,
        selectedCalendarDate,
        lastSiteDateTimeUpdated: lastUpdated
      }),
    [selectedSite, isAdhoc, roleAccessAdhoc, featureAccessAdhoc, selectedSchedule, selectedCalendarDate, lastUpdated]
  );
}

export default useScheduleCheckInOutPermission;

export const getScheduleCheckInOutPermission = ({
  featureAccessAdhoc,
  isAdhoc,
  roleAccessAdhoc,
  selectedSchedule,
  selectedSite,
  selectedCalendarDate,
  lastSiteDateTimeUpdated
}: ScheduleCheckInOutPermissionArgs) => {
  const siteTimezone = selectedSite?.timezone;
  if (!selectedSchedule || !siteTimezone) {
    return {
      isCheckinAllowed: false,
      isCheckoutAllowed: false,
      isPassedCheckOutTime: false
    };
  }

  if (isAdhoc) {
    return {
      isCheckinAllowed: roleAccessAdhoc || featureAccessAdhoc,
      isCheckoutAllowed: roleAccessAdhoc || featureAccessAdhoc,
      isPassedCheckOutTime: false
    };
  }

  const prevSiteDateTime = moment.tz(selectedSchedule.siteDateTime, siteTimezone);
  const timeElapsed = moment().diff(lastSiteDateTimeUpdated);
  const siteDateTime = lastSiteDateTimeUpdated ? prevSiteDateTime.clone().add(timeElapsed) : moment();

  const yesterday = moment().subtract(1, 'day').startOf('day').toISOString();

  const scheduleInDate = selectedSchedule.isYesterdayActiveSchedule ? yesterday : selectedCalendarDate;

  const { startDateTime, endDateTime } = getScheduleDateTime({
    schedule: selectedSchedule,
    siteTimeZone: siteTimezone,
    selectedCalendarDate: scheduleInDate,
    serverDateTime: siteDateTime.format(),
    siteDateTime: siteDateTime
  });
  const { enforceCheckIn, enforceCheckOut } = selectedSchedule;
  const isPassedCheckInTime = siteDateTime.isAfter(endDateTime);
  const isBetweenCheckInAndCheckOutTime = siteDateTime.isBetween(startDateTime, endDateTime);
  const isCheckinAllowed = !enforceCheckIn || (enforceCheckIn && isPassedCheckInTime);

  const isPassedCheckOutTime = siteDateTime.isAfter(endDateTime);
  const isOverCheckOutTime = enforceCheckOut
    ? siteDateTime.isSameOrBefore(endDateTime)
    : siteDateTime.isSameOrBefore(endDateTime?.endOf('day'));

  const isCheckoutAllowed = isOverCheckOutTime;
  const isCompleted = selectedSchedule.scheduledDates?.[selectedCalendarDate]?.isComplete;
  const isOverdue = !isCompleted && (enforceCheckIn || enforceCheckOut) && isPassedCheckOutTime;

  return {
    isCheckinAllowed,
    isCheckoutAllowed,
    isPassedCheckOutTime,
    isOverCheckOutTime,
    startDateTime,
    endDateTime,
    isPassedCheckInTime,
    enforceCheckIn,
    enforceCheckOut,
    isOverdue,
    isBetweenCheckInAndCheckOutTime
  };
};
