import firebase from 'firebase';

export const getServerTime = () => {
  let serverTime;
  firebase
    .database()
    .ref('/.info/serverTimeOffset')
    .once('value')
    .then(
      function stv(data) {
        serverTime = data.val();
      },
      function (err) {
        return err;
      }
    );
  return serverTime;
};
