import React from 'react';
import {
  Platform,
  TouchableOpacityProps,
  TouchableWithoutFeedbackProps,
  TouchableNativeFeedback,
  TouchableOpacity,
  StyleSheet
} from 'react-native';
import { connect, MapStateToProps } from 'react-redux';
import { compose } from 'redux';
import { RootState } from '../../store/reducers';
import { ConnectedDispatch } from '../../typing/types';
import fontMaker from '../../utils/font';
import { WithTranslation, withTranslation } from 'react-i18next';
import {
  ContainerSurveyCard,
  FooterCard,
  HeaderCard,
  SurveyCard,
  TextFooterCard,
  TextHeaderCard,
  TextFooterPlaceHolder
} from './styled-components/SurveyItem.styled';

import { LanguageType } from '../../constants/Languages';

export class SurveyNPSCard extends React.Component<SurveyNPSCardProps> {
  render() {
    const { onPressSurvey, t } = this.props;

    const Touchable: any = TouchableOpacity;

    const siteStyle: any[] = [styles.site];

    return (
      <Touchable onPress={() => onPressSurvey!()} activeOpacity={0.75} activeOpacity={0.75}>
        <View style={[siteStyle]}>
          <View style={styles.siteProfile}>
            <View style={styles.siteText}>
              <Text style={[styles.siteName]}>{t('common:survey.title')}</Text>
              <View style={styles.siteStatus}>
                <Text style={styles.siteReportsDoneText}>{t('common:survey.subtitle')}</Text>
                <Text style={styles.sitePlaceholderText}>{t('common:survey.tap')}</Text>
              </View>
            </View>
          </View>
        </View>
      </Touchable>
    );
  }
}

type StateProps = {
  language: LanguageType;
};

type OwnProps = {
  onPressSurvey?: () => void;
};

type SurveyNPSCardProps = OwnProps & StateProps & ConnectedDispatch & WithTranslation;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = state => ({
  language: state.firebase.profile.language || 'en'
});

const enhance = compose(connect(mapStateToProps), withTranslation(['common']));

const styles = StyleSheet.create({
  site: {
    // flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    // alignItems: 'center',
    marginHorizontal: 12.5,
    marginBottom: 12.5,
    borderRadius: 10,
    border: 'none',
    backgroundColor: '#fff',
    // Android settings
    elevation: 5,
    // iOS settings
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: 5 },
    borderRightWidth: 9
  },
  siteWithAddress: {
    marginTop: 12.5
  },
  siteProfile: {
    // flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 20,
    paddingVertical: 17.5
  },
  siteText: {
    width: '100%'
  },
  siteTextTopLine: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 3
  },
  siteTextLeft: {
    flex: 1
  },
  siteTextCenter: {
    flex: 1.15,
    alignItems: 'center'
  },
  siteTextRight: {
    flex: 1,
    alignItems: 'flex-end'
  },
  siteSubtitle: {
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 14,
    lineHeight: 20
  },
  siteSubtitleRight: {
    alignItems: 'flex-end'
  },
  siteSubtitleDone: {
    color: '#a8a8a8'
  },
  siteStatusText: {
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 14,
    lineHeight: 20
  },
  pendingStatusColor: {
    color: '#ffbf00'
  },
  draftStatusColor: {
    color: '#ffa040'
  },
  priorityStatusColor: {
    color: '#c43e00'
  },
  ongoingStatusColor: {
    color: '#3cd170'
  },
  siteStatusDoneText: {
    marginBottom: -22,
    lineHeight: 42
  },
  siteName: {
    ...fontMaker({ weight: 'SemiBold' }),
    maxWidth: 400,
    fontSize: 18,
    lineHeight: 21
  },
  siteNameDone: {
    color: '#a8a8a8'
  },
  siteStatus: {
    width: '100%',
    marginTop: 6,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  siteHoursText: {
    flex: 1,
    fontSize: 13,
    color: '#a8a8a8'
  },
  siteFlagsText: {
    flex: 1,
    fontSize: 13,
    color: '#a8a8a8'
  },
  siteReportsDoneText: {
    flex: 1,
    fontSize: 13,
    ...fontMaker({ weight: 'Light' }),
    color: '#a8a8a8'
  },
  sitePlaceholderText: {
    fontSize: 14,
    color: '#a8a8a8',
    ...fontMaker({ weight: 'Light' })
  },
  siteStatusTextBold: {
    ...fontMaker({ weight: 'SemiBold' }),
    color: '#a8a8a8'
  },
  address: {
    flex: 4,
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingVertical: 5
  },
  addessIcon: {
    paddingTop: 4,
    paddingRight: 6
  },
  addressText: {
    flex: 1,
    ...fontMaker({ weight: 'Light' }),
    fontSize: 12
  },
  addressSection: {
    width: '100%',
    flex: 1,
    paddingBottom: 10,
    paddingHorizontal: 20
  },
  addressContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderTopColor: '#d7dadf',
    borderTopWidth: StyleSheet.hairlineWidth,
    paddingTop: 6
  },
  mapContainer: {
    flex: 2,
    marginLeft: 10,
    marginVertical: 8
  },
  coverContent: {
    flex: 1
  }
});

export default enhance(SurveyNPSCard) as any;
