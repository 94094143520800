import * as Classes from './classes';

export const destructPerQuestion = (question: Classes.Question, index: number): Classes.ExtendedQuestion[] => {
  const newQuestions: Classes.ExtendedQuestion[] = [];
  if (question.conditionalQuestion && question.isConditional) {
    if (question.conditionalQuestion.conditions[question.answer]) {
      const conditionalQuestions = question.conditionalQuestion.conditions[question.answer];
      conditionalQuestions.forEach((q: any, i: number) => {
        const newQuestion = { ...q };
        newQuestion.parentIndex = index;
        newQuestion.no = i;
        newQuestion.parentAnswer = question.answer;
        newQuestions.push(newQuestion);
      });
    }
  }
  newQuestions.unshift({ parentNumber: index, ...question });

  return newQuestions;
};

export const destructConditionalQuestions = (questions: Classes.Question[]) => {
  let newQuestions: Classes.ExtendedQuestion[] = [];
  for (const [index, question] of questions.entries()) {
    const destructQuestions = destructPerQuestion(question, index);
    newQuestions = [...newQuestions, ...destructQuestions];
  }

  return newQuestions;
};
