import React, { useMemo, useState } from 'react';
import moment from 'moment';
import firebase from 'firebase';
import { useTranslation } from 'react-i18next';
import { StyleSheet, View, Text, TouchableOpacity, Animated, Easing } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

// utils
import { heightPercentageToDP, SCREEN_WIDTH } from '../../../../utils/screensize';

// type
import { SiteCalendarHeaderProps } from './type';
import SiteCalendarMonthPickerModal from './SiteCalendarMonthPickerModal';

const SiteCalendarHeader = (props: SiteCalendarHeaderProps) => {
  const { scheduleSummaries, setSelectedDate, selectedDate, startDate, endDate, periodUnit } = props;
  const { t } = useTranslation(['site']);

  const [currentMonth, setCurrentMonth] = useState<number>(moment().month());
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [slider, setSlider] = useState(new Animated.Value(0));
  const [calendarHeight, setCalendarHeight] = useState<null | number>(null);

  const mCurrent = moment().month(currentMonth);
  const mStart = moment(startDate);
  const mEnd = moment(endDate);

  const handleSelectDate = (date: string) => {
    setSelectedDate(date);
    firebase.analytics().logEvent('click_date', { category: 'site_detail_page', date: date });
  };

  const handleChangeMonth = (month: number) => {
    setCurrentMonth(month);
    setCalendarHeight(null);
    firebase.analytics().logEvent('change_month', { category: 'site_detail_page', month: month });
  };

  // dot components
  const ScheduleMark = () => {
    return <View style={styles.scheduleMark}></View>;
  };

  const _renderHeader = useMemo(() => {
    if (periodUnit === 'year') {
      const monthString = mCurrent.format('MMM YYYY').toLocaleUpperCase();
      const prevMonthString = moment(mCurrent).subtract(1, 'month').format('MMM YYYY').toLocaleUpperCase();
      const nextMonthString = moment(mCurrent).add(1, 'month').format('MMM YYYY').toLocaleUpperCase();

      const isSameYear = (i: number) => {
        return mCurrent.isSame(moment(mCurrent).add(i, 'month'), 'year');
      };

      const changeMonth = (i: number) => {
        if (periodUnit === 'year') {
          // calculcate next selected month rows and adjust height accordingly
          const m = moment(mCurrent).startOf('month');
          m.add(i, 'month');
          const firstWeekDays = 7 - m.isoWeekday() + 1;
          let rows = 1;
          let rest = m.daysInMonth() - firstWeekDays;
          // Middle rows
          let middleRows = Math.floor(rest / 7);
          rows = rows + middleRows;
          rest = rest - middleRows * 7;
          // Last row?
          if (rest > 0) {
            rows += 1;
          }
          const baseHeight = 13.5;
          const nextHeight = baseHeight * rows;
          setCalendarHeight(heightPercentageToDP(nextHeight));
        }

        Animated.timing(slider, {
          toValue: i,
          duration: 250,
          useNativeDriver: true,
          easing: Easing.ease
        }).start(() => {
          setCurrentMonth(currentMonth + i);
          setSlider(new Animated.Value(0));
        });

        firebase.analytics().logEvent('change_month', { category: 'site_detail_page', month: currentMonth + i });
      };

      return (
        <View style={styles.calendarHeaderContainer}>
          {/* hide button if prev month is in different year */}
          {isSameYear(-1) ? (
            <TouchableOpacity style={styles.calendarHeaderButton} onPress={() => changeMonth(-1)}>
              <Icon name="chevron-left" size={28} color="#535353" />
            </TouchableOpacity>
          ) : (
            <View style={[styles.calendarHeaderButton, { width: 28 }]} />
          )}
          <Animated.View
            style={[
              styles.animatedMonthContainer,
              {
                transform: [
                  {
                    translateX: slider.interpolate({
                      inputRange: [-1, 1],
                      outputRange: [Number(SCREEN_WIDTH), SCREEN_WIDTH * -1]
                    })
                  }
                ]
              }
            ]}
          >
            <View style={[styles.calendarHeaderInnerContainer, styles.calendarHeaderTextLeft]}>
              <Text style={[styles.calendarHeaderText]}>{prevMonthString}</Text>
              <Icon name="chevron-down" size={18} color="#535353" />
            </View>
            <TouchableOpacity style={styles.calendarHeaderInnerContainer} onPress={() => setIsModalVisible(true)}>
              <Text style={styles.calendarHeaderText}>{monthString}</Text>
              <Icon name="chevron-down" size={18} color="#535353" />
            </TouchableOpacity>
            <View style={[styles.calendarHeaderInnerContainer, styles.calendarHeaderTextRight]}>
              <Text style={[styles.calendarHeaderText]}>{nextMonthString}</Text>
              <Icon name="chevron-down" size={18} color="#535353" />
            </View>
          </Animated.View>
          {/* hide button if next month is in different year */}
          {isSameYear(1) ? (
            <TouchableOpacity style={styles.calendarHeaderButton} onPress={() => changeMonth(1)}>
              <Icon name="chevron-right" size={28} color="#535353" />
            </TouchableOpacity>
          ) : (
            <View style={[styles.calendarHeaderButton, { width: 28 }]} />
          )}
        </View>
      );
    }

    const monthDiff = mEnd.diff(mStart, 'month', true);
    const monthString =
      // monthly period and current period is between 2 month
      periodUnit === 'month' && monthDiff !== 0 && mEnd.month() !== mStart.month()
        ? `${mStart.format('MMM').toLocaleUpperCase()} - ${moment(mStart)
            .add(monthDiff, 'month')
            .format('MMM YYYY')
            .toLocaleUpperCase()}`
        : mCurrent.format('MMM YYYY').toLocaleUpperCase();

    return <Text style={styles.calendarHeaderText}>{monthString}</Text>;
  }, [mCurrent, periodUnit, mStart, mEnd, calendarHeight]);

  const _renderDays = useMemo(() => {
    const dateFormat = 'ddd';
    const days = [];
    const day = moment(mStart);

    // default starting day for yearly schedule
    if (periodUnit === 'year') {
      day.startOf('isoWeek');
    }

    for (let i = 0; i < 7; i += 1) {
      days.push(
        <View key={`site-calendar-day-${i}`} style={styles.dayCell}>
          <Text style={styles.dayText}>{day.format(dateFormat)}</Text>
        </View>
      );
      day.add(1, 'days');
    }
    return <View style={styles.daysContainer}>{days}</View>;
  }, [mStart]);

  const _renderDates = useMemo(() => {
    const createDateRows = (start: moment.Moment, end: moment.Moment) => {
      const day = moment(start);
      const endPeriod = moment(end);

      const dateFormat = 'D';
      const rows = [];

      while (day.isSameOrBefore(endPeriod)) {
        const days = [];
        for (let i = 0; i < 7; i += 1) {
          const momentDate = moment(day);
          const dateString = momentDate.format('YYYY-MM-DD');
          // used by yearly schedule to match `momentDate` and day header
          const isSameDay = momentDate.isoWeekday() === i + 1;

          if (momentDate.isAfter(endPeriod) || (periodUnit === 'year' && !isSameDay)) {
            days.push(<View key={`site-calendar-date-${i}`} style={styles.dayCell} />);
          } else {
            days.push(
              <TouchableOpacity
                key={`site-calendar-date-${i}`}
                onPress={() => handleSelectDate(dateString)}
                delayPressIn={0}
                style={styles.centered}
              >
                <View
                  style={[styles.dayCell, styles.dateCell, dateString === selectedDate ? styles.selectedCell : null]}
                >
                  <Text style={[styles.dateCellText, dateString === selectedDate ? { color: '#fff' } : null]}>
                    {momentDate.format(dateFormat)}
                  </Text>
                  {(scheduleSummaries[dateString] || []).length !== 0 ? <ScheduleMark /> : null}
                </View>
                {momentDate.isSame(moment().format('YYYY-MM-DD'), 'date') ? (
                  <View style={periodUnit === 'week' ? styles.todayMark : styles.monthlyMark} />
                ) : null}
              </TouchableOpacity>
            );
            day.add(1, 'days');
          }
        }

        rows.push(
          <View style={styles.dateRow} key={day.toISOString()}>
            {days}
          </View>
        );
      }
      return rows;
    };

    let dates: any[];

    if (periodUnit === 'year') {
      // render 3 calendar for slider animation
      const start = moment(mCurrent).startOf('month');
      const end = moment(mCurrent).endOf('month');
      const currentRows = createDateRows(start, end);

      const prevStart = moment(start).subtract(1, 'month').startOf('month');
      const nextStart = moment(start).add(1, 'month').startOf('month');
      const prevEnd = moment(end).subtract(1, 'month').endOf('month');
      const nextEnd = moment(end).add(1, 'month').endOf('month');

      const prevRows = createDateRows(prevStart, prevEnd);
      const nextRows = createDateRows(nextStart, nextEnd);

      dates = [
        <View key="prev" style={[styles.dateContainer, styles.dateContainerLeft]}>
          {prevRows}
        </View>,
        <View key="currentRows" style={[styles.dateContainer]}>
          {currentRows}
        </View>,
        <View key="nextRows" style={[styles.dateContainer, styles.dateContainerRight]}>
          {nextRows}
        </View>
      ];
    } else {
      const rows = createDateRows(mStart, mEnd);
      dates = [
        <View key="row" style={[styles.dateContainer]}>
          {rows}
        </View>
      ];
    }

    return dates;
  }, [selectedDate, periodUnit, mCurrent, mStart, mEnd, calendarHeight]);

  return (
    <View style={styles.container} testID="calendar-header">
      <Text style={[styles.dayText, styles.periodText]}>
        {t('site:calendar.viewPeriod', { day: periodUnit === 'week' ? 7 : periodUnit === 'month' ? 30 : 365 })}
      </Text>
      {_renderHeader}
      {_renderDays}
      <Animated.View
        style={[
          styles.animatedDateContainer,
          {
            height: calendarHeight,
            transform: [
              {
                translateX: slider.interpolate({
                  inputRange: [-1, 1],
                  outputRange: [Number(SCREEN_WIDTH), SCREEN_WIDTH * -1]
                })
              }
            ]
          }
        ]}
      >
        {_renderDates}
      </Animated.View>
      <SiteCalendarMonthPickerModal
        selectedMonth={currentMonth}
        isVisibile={isModalVisible}
        selectMonth={handleChangeMonth}
        closeModal={() => setIsModalVisible(false)}
      />
    </View>
  );
};
// };

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    paddingHorizontal: 20,
    paddingVertical: 15,
    justifyContent: 'center',
    alignItems: 'center',

    //box shadow
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,

    elevation: 3,
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH
  },
  centered: {
    alignItems: 'center'
  },
  periodText: {
    width: '100%',
    textAlign: 'left',
    fontSize: 12,
    marginBottom: 19
  },

  daysContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 10,
    width: '100%',
    paddingLeft: 25,
    paddingRight: 25
  },
  dayCell: {
    justifyContent: 'center',
    alignItems: 'center',
    // minus container padding / total days - margin per cell
    width: SCREEN_WIDTH > 500 ? 90 : 45
  },
  dateCellText: {
    color: '#574FCF',
    fontWeight: '500'
  },
  animatedDateContainer: {
    minWidth: 70,
    flexDirection: 'row',
    paddingRight: 35
  },
  animatedMonthContainer: {
    minWidth: 70,
    flexDirection: 'row'
  },
  dateRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 2
  },
  dateRowLeft: {
    justifyContent: 'flex-start'
  },
  dateContainer: {
    width: '100%',
    marginTop: 5,
    marginHorizontal: 20
  },
  dateContainerLeft: {
    position: 'absolute',
    left: -SCREEN_WIDTH
  },
  dateContainerRight: {
    position: 'absolute',
    left: SCREEN_WIDTH
  },
  dayText: {
    fontWeight: '400',
    color: '#8F8F8F'
  },
  dateCell: {
    borderColor: '#EAE9F9',
    borderWidth: 1,
    borderRadius: 5,
    // minus container padding / total days - margin per cell
    height: SCREEN_WIDTH > 500 ? 90 : 45
  },
  calendarHeaderContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  calendarHeaderInnerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 150
  },
  calendarHeaderText: {
    fontSize: 14,
    lineHeight: 19,
    fontWeight: '600'
  },
  calendarHeaderTextLeft: {
    position: 'absolute',
    left: -SCREEN_WIDTH
  },
  calendarHeaderTextRight: {
    position: 'absolute',
    right: -SCREEN_WIDTH
  },

  calenderHeader: {
    fontSize: 14,
    lineHeight: 19,
    fontWeight: '600'
  },
  calendarHeaderButton: {
    marginHorizontal: 30
  },
  todayMark: {
    borderBottomColor: '#F6BB42',
    borderBottomWidth: 2,
    borderStyle: 'solid',
    width: '100%',
    marginTop: 7
  },
  monthlyMark: {
    borderBottomColor: '#F6BB42',
    borderBottomWidth: 2,
    borderStyle: 'solid',
    width: '80%',
    marginTop: -7
  },

  selectedCell: {
    backgroundColor: '#574FCF'
  },
  scheduleMark: {
    height: 6,
    width: 6,
    borderRadius: 3,
    backgroundColor: '#F6BB42',
    position: 'absolute',
    top: 3,
    right: 3
  }
});

export default SiteCalendarHeader;
