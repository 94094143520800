import { Signature, Report, Site } from '../../../utils/classes';
import { InjectedSite } from '../../../typing/types';

/**
 * Get an initial Signatures including the initial placeholder images and object
 * @param selectedReport - The Selected Report
 * @param selectedSite - The Selected Site
 */
export default function getInitialSignatures(selectedReport: Report, selectedSite: Site & InjectedSite) {
  const signedSignature = selectedReport.signatures ? selectedReport.signatures.slice() : [];
  const signatures: Signature[] = Array.from(Array(selectedSite.signatures || 0), (v, i) => {
    const signature: Signature = signedSignature[i] ? signedSignature[i] : ({} as Signature);
    return {
      position: signature.position || '',
      name: signature.name || '',
      path: '',
      isSigned: !!signature.isSigned
    };
  });
  return signatures;
}
