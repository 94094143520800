import React, { useMemo } from 'react';
import firebase from 'firebase';
import { useTranslation } from 'react-i18next';
import { View, StyleSheet, ActivityIndicator, Text } from 'react-native';
import moment from 'moment';
//constant
import theme from '../../../../../constants/theme';
import fontMaker from '../../../../../utils/font';

// utils
import { SCREEN_HEIGHT } from '../../../../../utils/screensize';

// components
import { useSelector } from 'react-redux';
// types
import { ScheduleOption } from '../../../../../typing/types';
import getScheduleDateTime from '../../../utils/getScheduleDateTime';

import ScheduleQuestionnaireCard from '../../../components/ScheduleQuestionnaireCard';
// types
import { RootState } from '../../../../../store/reducers';
import { ScheduledQuestionnaireContainerProps } from './type';

const ScheduledQuestionnaireContainer = (props: ScheduledQuestionnaireContainerProps) => {
  const { t } = useTranslation('site');

  const {
    scheduleIsLoading,
    handleSelectSchedule,
    countReportToday,
    countReportYesterDay,
    scheduleOptions = []
  } = props;

  const selectedSite = useSelector((state: RootState) => state.site.selectedSite!);

  const onSelectQuestionnaireSchedule = (schedule: Partial<ScheduleOption>) => {
    firebase.analytics().logEvent('click_questionnaire_card', { category: 'site_details_page' });

    handleSelectSchedule(schedule);
  };
  const siteTime = moment.tz(scheduleOptions[0]?.siteDateTime ?? '', selectedSite.timezone ?? '');
  const now = useMemo(() => siteTime.clone().startOf('day').toISOString(), [siteTime]);
  const yesterday = useMemo(() => siteTime.clone().subtract(1, 'day').startOf('day').toISOString(), [siteTime]);

  const scheduleSummary = useMemo(() => {
    const { timezone } = selectedSite;
    const siteDateTime = moment.tz(scheduleOptions[0]?.siteDateTime ?? '', timezone ?? '');

    let earliestSchedule = siteDateTime.clone().endOf('day');
    let latestSchedule = siteDateTime.clone().startOf('day');

    scheduleOptions.forEach(schedule => {
      const { endDateTime, startDateTime } = getScheduleDateTime({
        schedule,
        selectedCalendarDate: schedule.isYesterdaySchedule ? yesterday : now,
        siteDateTime,
        siteTimeZone: timezone ?? '',
        serverDateTime: schedule.siteDateTime ?? ''
      });

      if (startDateTime?.isBefore(earliestSchedule)) {
        earliestSchedule = startDateTime;
      }

      if (endDateTime?.isAfter(latestSchedule)) {
        latestSchedule = endDateTime;
      }
    });

    return {
      earliestSchedule,
      latestSchedule
    };
  }, [scheduleOptions]);

  const dateRangeLabel = useMemo(() => {
    const { earliestSchedule, latestSchedule } = scheduleSummary;

    const siteDateTime = moment.tz(scheduleOptions[0]?.siteDateTime ?? '', selectedSite.timezone ?? '');

    const hasStartedYesterday = earliestSchedule.isBefore(siteDateTime.clone().startOf('day'));
    const hasEndedTomorrow = latestSchedule.isAfter(siteDateTime.clone().endOf('day'));

    let yesterdayLabel = siteDateTime.format('DD MMM YYYY');

    if (hasStartedYesterday) {
      yesterdayLabel = `${earliestSchedule.format('DD MMM YYYY')} - ${yesterdayLabel}`;
    }

    let todayLabel = siteDateTime.format('DD MMM YYYY');
    if (hasEndedTomorrow) {
      todayLabel = `${todayLabel} - ${latestSchedule.format('DD MMM YYYY')}`;
    }

    return {
      yesterday: yesterdayLabel,
      today: todayLabel
    };
  }, [scheduleSummary]);

  const renderListSchedule = useMemo(() => {
    const isEmpty = scheduleOptions.length === 0;

    switch (true) {
      case scheduleIsLoading:
        return (
          <View style={styles.statusBox}>
            <ActivityIndicator color={theme.colors.primary} />
          </View>
        );
      case isEmpty:
        return (
          <View style={styles.statusBox}>
            <Text style={styles.emptyMessageTitle}>{t('outlet.placeholder.noSchedule')}</Text>
            <Text style={styles.emptyMessageSubtitle}>{t('outlet.placeholder.noScheduleTitle')}</Text>
          </View>
        );
      default:
        return (
          <View style={styles.box}>
            {countReportYesterDay.scheduled ? (
              <View style={styles.textContainer}>
                <Text style={[styles.text, styles.textBold]}>{dateRangeLabel.yesterday}</Text>
                <Text style={[styles.text, styles.subtitle]}>
                  {`${t('site:outlet.reportDoneToday')} ${countReportYesterDay.done}/${countReportYesterDay.scheduled}`}
                </Text>
              </View>
            ) : (
              ''
            )}

            {countReportYesterDay.scheduled > 0 &&
              scheduleOptions.map((item, index) =>
                item.isYesterdaySchedule ? (
                  <ScheduleQuestionnaireCard
                    schedule={item}
                    handleSelectSchedule={onSelectQuestionnaireSchedule}
                    key={`q-schedule-item-${index}`}
                    selectedCalendarDate={yesterday}
                  />
                ) : (
                  ''
                )
              )}
            <View style={styles.textContainer}>
              <Text style={[styles.text, styles.textBold]}>{dateRangeLabel.today}</Text>
              <View style={styles.subheaderContainer}>
                <Text style={[styles.text, styles.subtitle]}>
                  {t('site:outlet.reportDoneToday')} {` ${countReportToday.done}/`}
                  <Text style={styles.textBold}>{countReportToday.scheduled}</Text>
                </Text>
              </View>
            </View>

            {scheduleOptions.map((item, index) =>
              !item.isYesterdaySchedule && !item.isYesterdayActiveSchedule ? (
                <ScheduleQuestionnaireCard
                  schedule={item}
                  handleSelectSchedule={onSelectQuestionnaireSchedule}
                  key={`q-schedule-item-${index}`}
                  selectedCalendarDate={now}
                />
              ) : (
                ''
              )
            )}
          </View>
        );
    }
  }, [scheduleIsLoading, scheduleOptions]);

  return <View style={styles.container}>{renderListSchedule}</View>;
};

const styles = StyleSheet.create({
  container: {},
  statusBox: {
    height: SCREEN_HEIGHT * 0.2,
    justifyContent: 'center',
    alignItems: 'center'
  },
  box: {
    borderRadius: 5,
    paddingLeft: 15
  },
  textContainer: {
    paddingBottom: 20
  },
  endList: {
    justifyContent: 'center',
    alignItems: 'center',
    borderTopWidth: 1,
    height: 30,
    borderColor: theme.colors.light
  },
  endListText: {
    fontSize: 12,
    color: theme.styles.fontColor.other
  },
  emptyMessageTitle: {
    fontSize: 20,
    lineHeight: 26,
    color: theme.styles.fontColor.headline
  },
  emptyMessageSubtitle: {
    fontSize: 12,
    lineHeight: 18,
    color: theme.styles.fontColor.other
  },
  text: {
    color: theme.colors.darkgray,
    fontSize: 14,
    lineHeight: 19,
    marginTop: 10
  },
  closeContainer: { flex: 1, alignItems: 'flex-end' },
  subtitle: { fontSize: 12, lineHeight: 18 },
  textBold: { ...fontMaker({ weight: 'SemiBold' }) }
});

export default ScheduledQuestionnaireContainer;
