import React from 'react';
import { User } from 'nimbly-common';
import { SCREEN_WIDTH, SCREEN_HEIGHT } from '../../utils/screensize';
import { useSelector, useDispatch } from 'react-redux';
import { FirebaseReducer } from 'react-redux-firebase';
import {
  Modal,
  Platform,
  StyleSheet,
  TouchableNativeFeedback,
  TouchableOpacity,
  View,
  Image,
  Text
} from 'react-native';
import { dismissFeatureModal } from '../../store/reducers/featureAccess/featureAccess.action';

import fontMaker from '../../utils/font';
import { RootState } from '../../store/reducers';
const closeButton = require('../../assets/icons/close-button.png');

const title = {
  en: 'No Access',
  id: 'Akses Tidak Tersedia'
};

const subtitle = {
  en: 'Sorry, but you don’t have access to this feature.',
  id: 'Maaf, Anda tidak memiliki akses ke fitur ini.'
};

const body = {
  en: 'Please contact your admin to enable this feature.',
  id: 'Silakan hubungi Admin Anda untuk mengakses fitur ini.'
};

const buttonTranslation = {
  en: 'Okay',
  id: 'OK'
};

const error = {
  title: {
    en: 'Oops!',
    id: 'Oops!'
  },
  subtitle: {
    en: 'Problem retrieving feature access.',
    id: 'Terjadi kendala saat mengambil akses fitur.'
  },
  body: {
    en: 'Please restart the app.',
    id: 'Silahkan restart aplikasi.'
  }
};

const FeatureAccessModal = () => {
  const featureBlockModal = useSelector<RootState, boolean>(state => state.featureAccess.modalVisible);
  const featureAccessError = useSelector<RootState, string | null>(state => state.featureAccess.error);

  const profile = useSelector<RootState, FirebaseReducer.Profile<User>>(state => state.firebase.profile);
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(dismissFeatureModal());
  };

  return (
    <Modal animationType="fade" transparent={true} visible={featureBlockModal} onRequestClose={handleCloseModal}>
      <View style={styles.overlay}>
        <View style={styles.container}>
          <View style={styles.header}>
            <Touchable onPress={handleCloseModal}>
              <Image source={closeButton} />
            </Touchable>
          </View>
          <View style={styles.actionContainer}>
            <Text style={styles.headerTitle}>
              {!featureAccessError ? title[profile.language] : error.title[profile.language]}
            </Text>
            <View>
              <Text style={styles.firstSub}>
                {!featureAccessError ? subtitle[profile.language] : error.subtitle[profile.language]}
              </Text>
              <Text style={styles.secondSub}>
                {!featureAccessError ? body[profile.language] : error.body[profile.language]}
              </Text>
            </View>
          </View>
          <Touchable style={styles.confirmTouch} onPress={handleCloseModal}>
            <View style={styles.confirmButton}>
              <Text style={styles.confirmText}>{buttonTranslation[profile.language]}</Text>
            </View>
          </Touchable>
        </View>
      </View>
    </Modal>
  );
};

const Touchable: any = Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

const styles = StyleSheet.create({
  // Modal
  overlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  container: {
    alignItems: 'center',
    backgroundColor: '#fff',
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH * 0.7,
    borderRadius: 15,
    maxHeight: SCREEN_HEIGHT * 0.7,
    overflow: 'hidden'
  },
  header: {
    width: '100%',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingTop: 8,
    paddingHorizontal: 12
  },
  headerTitle: {
    ...fontMaker({ weight: 'normal' }),
    fontSize: 24,
    marginBottom: 20,
    color: '#535353',
    textAlign: 'center'
  },
  headerSubtitle: {
    ...fontMaker({ weight: 'Light' }),
    textAlign: 'center',
    marginVertical: 12
  },
  actionContainer: {
    width: '80%',
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10
  },
  row: {
    width: '100%',
    paddingVertical: 15,
    paddingHorizontal: 10
  },
  option: {
    textAlign: 'center'
  },
  boldOption: {
    ...fontMaker({ weight: 'SemiBold' })
  },
  confirmButton: {
    backgroundColor: '#0ABA26',
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    ...Platform.select({
      android: {
        width: '70%',
        marginBottom: 20
      }
    })
  },
  confirmText: {
    color: 'white',
    padding: 7,
    fontSize: 18,
    ...fontMaker({ weight: '600' })
  },
  confirmTouch: {
    width: '70%',
    marginBottom: 20
  },
  firstSub: {
    textAlign: 'center',
    color: '#535353'
  },
  secondSub: {
    textAlign: 'center',
    color: '#535353'
  }
});

export default FeatureAccessModal;
