import { QuestionTypes } from 'nimbly-common/lib/enumerators';
import { Question } from '../../../utils/classes';
import getRangeFlagColor from './getRangeFlagColor';

/**
 * Get each category answers summary
 * on question type binary, binary-with-red-flag, score and range flag
 * @param selectedReport - The selected report
 */

type QuestionAnswer = Pick<Question, 'answer' | 'type' | 'score' | 'rangeOptions' | 'scoreWeight' | 'flag'>;
type QuestionSection = { categoryName: string; data: QuestionAnswer[] };
type GroupQuestionByCategoryFn = (questions: Question[]) => QuestionSection[];

type Answers = {
  green: number;
  yellow: number;
  red: number;
  weightedScore: number;
};
type CategorySummary = { category: string; answers: Answers };
type CategoryAnswersSummary = { isFound: boolean; summary?: CategorySummary };

type ReportCategorySummaries = CategorySummary[];
type GetCategoryAnswersSummary = (questions: Question[], category: string) => CategoryAnswersSummary;

const groupQuestionByCategory: GroupQuestionByCategoryFn = questions => {
  const sections: Map<string, Question[]> = new Map();

  questions.forEach(question => {
    const questionCategory = question.category;
    const currentSection = sections.get(questionCategory);
    if (currentSection) {
      sections.set(questionCategory, [...currentSection, question]);
    } else {
      sections.set(questionCategory, [question]);
    }
  });

  return [...sections].map(([questionCategory, currentSectionQuestions]) => {
    const questionAnswer: QuestionAnswer[] = currentSectionQuestions.map(question => {
      return {
        answer: question.answer,
        type: question.type,
        score: question.score,
        flag: question.flag,
        rangeOptions: question.rangeOptions,
        scoreWeight: question.scoreWeight
      };
    });

    return {
      categoryName: questionCategory,
      data: questionAnswer
    };
  });
};

const calculatingFlag = (answers, stringAnswer, flagQuestion?) => {
  let flag = '';
  if (flagQuestion) {
    flag = flagQuestion;
  } else {
    flag = stringAnswer.slice(0, -5);
  }

  if (flag === 'green') {
    answers.green += 1;
  } else if (flag === 'yellow') {
    answers.yellow += 1;
  } else if (flag === 'red') {
    answers.red += 1;
  }
  return answers;
};

const getCategoryAnswersSummary: GetCategoryAnswersSummary = (questions, category) => {
  const categoryQuestions = groupQuestionByCategory(questions);
  let answers: Answers = {
    red: 0,
    yellow: 0,
    green: 0,
    weightedScore: -1
  };
  const reportCategorySummaries: ReportCategorySummaries = categoryQuestions.map(categoryQuestion => {
    let totalScore = 0;
    let totalWeight = 0;

    categoryQuestion.data.forEach(question => {
      const stringAns = String(question.answer);

      if (question.type === 'binary') {
        answers = calculatingFlag(answers, stringAns);
      } else if (question.type === 'binary-with-red-flag') {
        answers = calculatingFlag(answers, stringAns);
      } else if (question.type === 'score') {
        if (stringAns !== 'not-applicable') {
          const weight = question.scoreWeight ? question.scoreWeight : question.score;
          totalScore += (+stringAns / question.score) * weight;
          totalWeight += weight;
        }
      } else if (question.type === 'range-flag') {
        const answer = getRangeFlagColor(question);
        if (answer !== 'not-applicable' && answer !== 'Error') {
          answers = calculatingFlag(answers, stringAns);
        }
      } else if (question.type === 'multiple-choice-score') {
        const answer = question.answer;
        const flag = question.flag;
        if (answer !== 'not-applicable' && answer !== 'Error') {
          answers = calculatingFlag(answers, stringAns, flag);
        }
      }
    });

    if (totalScore > 0 || totalWeight > 0) {
      answers.weightedScore = (totalScore / totalWeight) * 100;
    }

    return {
      category: categoryQuestion.categoryName,
      answers
    };
  });

  const categorySummary = reportCategorySummaries.find(summary => summary && summary.category === category);
  if (categorySummary) {
    return {
      isFound: true,
      summary: categorySummary
    };
  }

  return {
    isFound: false
  };
};

export default getCategoryAnswersSummary;
