import AsyncStorage from '@react-native-community/async-storage';
import firebase from 'firebase';
// import RNFS from 'react-native-fs';
import moment, { unitOfTime, MomentInput } from 'moment';

import { previousPeriodEndDate } from './schedule';
import { CacheReport } from '../store/reducers/reportcache/reportcache.actionThunk';
import { ReportSections } from '../store/reducers/reportcache/reportcache.types';
import { Report, OrganizationSchedule, ReportSection, User } from './classes';

async function getUserProfile(): Promise<User & { uid: string }> {
  const user = firebase.auth().currentUser;
  const userProfileSnap = await firebase.database().ref(`/user/${user!.uid}`).once('value');
  return { ...userProfileSnap.val(), uid: user!.uid };
}

function getCacheKeys(siteKey: string, reportKey: string) {
  return {
    reportCacheKey: `report/${siteKey}/${reportKey}`,
    summaryCacheKey: `reportIndex/${siteKey}/${reportKey}`
  };
}

function getDiffWithNow(time: MomentInput, unit: unitOfTime.Diff): number {
  // const serverTime = firebase.database().getServerTime();
  const diff = moment().diff(time, unit);
  return Math.abs(diff);
}

function isExpiredReport(report: any, organizationSchedule: OrganizationSchedule) {
  const prevEnd = previousPeriodEndDate(organizationSchedule);

  if (report && report.datetimeIn) {
    const checkedIn = moment(report.datetimeIn);
    if (prevEnd && checkedIn.isSameOrBefore(prevEnd, 'day') && report.status === 'draft') {
      return true;
    }
  }
  return false;
}

function getSavedPhotos(reports: (Report | ReportSection)[]) {
  let savedPhotos: string[] = [];
  reports.forEach(report => {
    if (report.questions) {
      report.questions.forEach(({ photos }) => {
        if (photos) {
          savedPhotos = savedPhotos.concat(photos);
        }
      });
    }
  });
  return savedPhotos;
}

async function cleanUpExpiredReports(expiredReports: CacheReport[]) {
  const { organization, uid } = await getUserProfile();

  const reports = expiredReports
    .filter(({ type }) => type === 'report' || type === 'reportSection')
    .map(({ value }) => value as Report | ReportSection);
  const savedPhotos: string[] = getSavedPhotos(reports);

  // Clean up saved photos of expired report
  // await Promise.all(
  //   savedPhotos.map(async photoURI => {
  //     const isExists = await RNFS.exists(photoURI);
  //     if (isExists) {
  //       return RNFS.unlink(photoURI);
  //     }
  //     return Promise.resolve();
  //   })
  // );
  const pushReports = expiredReports
    .filter(({ type }) => type !== 'unavailable')
    .map(({ type, siteKey, reportKey, value, sectionIdx }) => {
      const ref =
        type !== 'reportSection'
          ? `/${type}/${organization}/${siteKey}/${reportKey}`
          : `/${type}/${organization}/${siteKey}/${reportKey}/${sectionIdx}/${uid}`;
      firebase
        .database()
        .ref(ref)
        .transaction((report: any) => {
          if (report && report.status === 'complete') {
            return report;
          }
          return { ...value, status: 'expired' };
        });

      const itemToRemove =
        type !== 'reportSection'
          ? `${type}/${siteKey}/${reportKey}`
          : `reportSection/${siteKey}/${reportKey}/${sectionIdx}`;

      return AsyncStorage.removeItem(itemToRemove);
    });

  try {
    await Promise.all(pushReports);
    return Promise.resolve();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    throw error;
  }
}

function mergeSiteReportIndex(siteReportIndex?: any, siteCacheReportIndex?: any) {
  let mergedSiteReportIndex = siteReportIndex ? { ...siteReportIndex } : null;
  if (siteCacheReportIndex) {
    mergedSiteReportIndex = { ...mergedSiteReportIndex, ...siteCacheReportIndex };
  }
  return mergedSiteReportIndex;
}

const cleanReportSectionPhotos = (reportSections: ReportSections, sectionIdx?: number) => {
  const allPhotos: Promise<any>[] = [];
  for (const index of Object.keys(reportSections)) {
    if (sectionIdx !== undefined && !isNaN(sectionIdx) && Number(index) !== sectionIdx) {
      continue;
    }
    if (reportSections[Number(index)]) {
      const { questions } = reportSections[Number(index)];
      questions.forEach(({ photos }) => {
        if (photos && photos.length) {
          allPhotos.push(...makePhotoPromise(photos));
        }
      });
    }
  }
  if (allPhotos.length) {
    return Promise.all(allPhotos);
  }
  return Promise.resolve();
};

const makePhotoPromise = (uris: string[]) => {
  const promises = uris.map(async uri => {
    return Promise.resolve();
  });
  return promises;
};

export {
  getUserProfile,
  getCacheKeys,
  getDiffWithNow,
  isExpiredReport,
  cleanUpExpiredReports,
  mergeSiteReportIndex,
  getSavedPhotos,
  cleanReportSectionPhotos,
  makePhotoPromise
};
