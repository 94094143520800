import React from 'react';
import {
  Platform,
  Dimensions,
  StyleSheet,
  View,
  TextInput,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableWithoutFeedbackProps,
  StatusBar
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Tooltip from 'react-native-walkthrough-tooltip';
import TooltipContent from '../global/tutorial/TooltipContent';

import { SCREEN_WIDTH } from '../../utils/screensize';

import Text from '../global/Text';

import fontMaker from '../../utils/font';
import { useTranslation } from 'react-i18next';
import { Organization, EmailTarget, User } from '../../utils/classes';
import { SubmitReportStyles } from './SubmitReport';

export type SubmitReportTargetsProps = {
  profile: User;
  language: string;
  organization: Organization | null | undefined;
  emailTargets: EmailTarget[];
  email: string;
  _emailIsValid: boolean;
  _domainIsValid: boolean;
  isTutorialMode: boolean;
  handleToggleEmailTarget: (email: string) => void;
  handleEmailChange: (text: string) => void;
  handleTextInputFocused: () => void;
  handleTextInputBlur: () => void;
  handleChangeTooltipTarget: (value: boolean) => void;
  showTooltipTarget: boolean;
};

/** SubmitReportTargets shows List of email targets that will received the report details in PDF  */
const SubmitReportTargets = (props: SubmitReportTargetsProps) => {
  const { profile, language, organization, emailTargets, email, _emailIsValid, _domainIsValid } = props;
  const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
    Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;
  const { t } = useTranslation(['submitReport', 'common']);
  return (
    <View style={styles.targets}>
      <Text style={styles.heading}>{t('submitReport:reportTargets.emailReport')}</Text>
      <Touchable disabled={true}>
        <View style={styles.target}>
          <Text style={styles.targetText}>Supervisor</Text>
          <Icon name="check" size={24} color="#3cd070" style={styles.targetIcon} />
        </View>
      </Touchable>
      {emailTargets.map(target =>
        target ? (
          <Touchable
            key={target.email}
            disabled={target.setByAdmin || target.email === profile.email}
            onPress={() => props.handleToggleEmailTarget(target.email)}
          >
            <View style={styles.target}>
              <Text style={styles.targetText}>
                {target.email === profile.email ? t('submitReport:reportTargets.myself') : target.email}
                {target.setByAdmin ? t('submitReport:reportTargets.setByAdmin') : ''}
              </Text>
              {target.enabled ? <Icon name="check" size={24} color="#3cd070" style={styles.targetIcon} /> : null}
            </View>
          </Touchable>
        ) : null
      )}

      <Tooltip
        isVisible={props.isTutorialMode && props.showTooltipTarget}
        topAdjustment={Platform.OS === 'android' ? -StatusBar.currentHeight! : 0}
        placement="top"
        content={<TooltipContent text={t('submitReport:tutorial.reportTargets.addEmail')}></TooltipContent>}
        onClose={() => props.handleChangeTooltipTarget(false)}
      >
        <View
          style={[
            styles.target,
            { width: SCREEN_WIDTH - 40, backgroundColor: props.isTutorialMode ? '#ffffff' : 'transparent' }
          ]}
        >
          <TextInput
            style={styles.emailInput}
            onChangeText={props.handleEmailChange}
            onFocus={props.handleTextInputFocused}
            onBlur={props.handleTextInputBlur}
            value={email}
            keyboardType="email-address"
            autoCapitalize="none"
            placeholder={t('submitReport:reportTargets.addEmail')}
            blurOnSubmit={true}
            onSubmitEditing={() => props.handleToggleEmailTarget(email)}
          />
          <Touchable onPress={() => props.handleToggleEmailTarget(email)}>
            {email && _emailIsValid && !_domainIsValid ? (
              <Icon name="alert" size={28} color="#ea3c53" style={styles.targetIcon} />
            ) : _domainIsValid ? (
              <Icon name="plus-circle-outline" size={28} color="#3cd070" style={styles.targetIcon} />
            ) : (
              <Icon name="plus" size={28} color="#a8a8aa" style={styles.targetIcon} />
            )}
          </Touchable>
        </View>
      </Tooltip>

      {email !== '' && !_emailIsValid ? (
        <View style={styles.validationContainer}>
          <Text style={styles.validationText}>{t('submitReport:reportTargets.validEmail')}</Text>
        </View>
      ) : _emailIsValid && !_domainIsValid ? (
        <View style={styles.validationContainer}>
          <Text style={styles.validationText}>
            {t('submitReport:reportTargets.emailDomain', { emailDomains: organization?.emailDomain })}
          </Text>
        </View>
      ) : null}
    </View>
  );
};

type SubmitReportTargetsStyles = Pick<
  SubmitReportStyles,
  | 'targets'
  | 'heading'
  | 'target'
  | 'targetText'
  | 'targetIcon'
  | 'emailInput'
  | 'validationContainer'
  | 'validationText'
>;

const screen = Dimensions.get('screen');
const { width } = screen;
const styles = StyleSheet.create<SubmitReportTargetsStyles>({
  targets: {
    paddingTop: SCREEN_WIDTH > 500 ? 30 : 0,
    paddingBottom: 20,
    paddingHorizontal: 20
  },
  target: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
    paddingVertical: 7.5,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: '#a8a8aa'
  },
  heading: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: '#cacaca',
    ...fontMaker({ weight: 'Regular' }),
    fontSize: 14
  },
  targetText: {
    maxWidth: width * 0.75,
    ...fontMaker({ weight: 'Light' })
  },
  targetIcon: {
    textAlign: 'center',
    width: 30,
    marginLeft: 10
  },
  emailInput: {
    width: width * 0.65,
    ...fontMaker({ weight: 'Light' })
  },
  validationContainer: {
    alignItems: 'flex-start',
    paddingTop: 5,
    marginLeft: -4
  },
  validationText: {
    ...fontMaker({ weight: 'Light' }),
    color: '#ea3c53'
  }
});

export default SubmitReportTargets;
