import React, { PureComponent } from 'react';
import { Dimensions, Modal, Platform, StyleSheet, TouchableNativeFeedback, TouchableOpacity, View } from 'react-native';

import Text from '../global/Text';

import fontMaker from '../../utils/font';
import theme from '../../constants/theme';

export type ModalOption = {
  text: { [language: string]: string } | string;
  action: () => any;
  style: 'cancel' | 'destructive' | 'confirm';
};

export type ModalConfig = {
  title: string;
  subtitle: string;
  options: ModalOption[];
};

type ConfirmModalProps = {
  isVisible: boolean;
  language?: string;
  title: string;
  subtitle: string;
  options: ModalOption[];
  onCloseModal?: () => any;
  onConfirmDelete?: () => any;
};

class ConfirmModal extends PureComponent<ConfirmModalProps> {
  handleCloseModal = () => {
    this.props.onCloseModal();
  };

  renderText = (option: any | { [language: string]: string }) => {
    if (typeof option === 'string') {
      return option;
    }
    return option.text;
  };

  render() {
    const { language, title, subtitle, options } = this.props;

    return (
      <Modal
        animationType="fade"
        transparent={true}
        visible={this.props.isVisible}
        onRequestClose={this.handleCloseModal!}
      >
        <View style={styles.overlay}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.headerTitle}>{this.renderText(title)}</Text>
              <Text style={styles.headerSubtitle}>{this.renderText(subtitle)}</Text>
            </View>
            <View style={styles.actionContainer}>
              {options.map((option, index) => (
                <View style={styles.tab} key={index}>
                  <Touchable style={styles.row} onPress={option.action} delayPressIn={0}>
                    <View style={styles.row}>
                      <Text
                        style={[
                          styles.option,
                          option.style === 'confirm' ? styles.boldOption : {},
                          { color: option.style === 'destructive' ? theme.colors.secondaryDark : '#439889' }
                        ]}
                      >
                        {this.renderText(option)}
                      </Text>
                    </View>
                  </Touchable>
                </View>
              ))}
            </View>
          </View>
        </View>
      </Modal>
    );
  }
}

const Touchable: any = Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

const { width, height } = Dimensions.get('window');

const styles = StyleSheet.create({
  // Modal
  overlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  container: {
    alignItems: 'center',
    backgroundColor: '#fff',
    width: width * 0.7,
    maxWidth: 450,
    borderRadius: 5,
    maxHeight: height * 0.7,
    overflow: 'hidden'
  },
  header: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 24,
    paddingBottom: 16,
    paddingHorizontal: 25
  },
  headerTitle: { ...fontMaker({ weight: 'SemiBold' }), fontSize: 18, marginBottom: 20, fontWeight: 'bold' },
  headerSubtitle: {
    ...fontMaker({ weight: 'Light' }),
    textAlign: 'center',
    marginVertical: 12
  },
  actionContainer: {
    width: '100%'
  },
  row: {
    width: '100%'
  },
  tab: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 20,

    borderTopWidth: StyleSheet.hairlineWidth,
    borderTopColor: 'rgba(27, 33, 51, 0.3)'
  },
  option: {
    width: '100%',
    textAlign: 'center'
  },
  boldOption: {
    ...fontMaker({ weight: 'SemiBold' })
  }
});

export default ConfirmModal;
