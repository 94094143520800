import moment from 'moment';
import { InjectedSite } from '../../../typing/types';
import {
  Site,
  Report,
  ReportSection,
  SiteSKU,
  OrganizationSKU,
  SKUInventory,
  InventoryReport
} from '../../../utils/classes';

/**
 * Defines a new object containing inventory data that has been updated.
 * @param serverTime retrieveable using firebase.database().getServerTime()
 * @param selectedSiteKey currently active site key from props
 * @param selectedSite currently active site from props
 * @param selectedReportKey currently active report key from props
 * @param selectedReport currently active report from props
 * @param auth firebase auth data
 * @param siteSKUs list of site skus from props
 */
function updateInventoryData(
  serverTime: any,
  selectedSiteKey: string,
  selectedSite: Site & InjectedSite,
  selectedReportKey: string,
  selectedReport: Report | ReportSection,
  auth: any,
  siteSKUs: { [sku: string]: SiteSKU & { skuDetail: OrganizationSKU } } | null | undefined
): any {
  // const now = moment(serverTime).toISOString(true);
  const inventoryDate = moment(selectedReport.datetimeIn).subtract(4, 'hours').toISOString(true);

  if (!selectedSite.hasInventory) {
    return { status: 0 };
  }

  const finalInventory: SKUInventory = {};
  selectedReport.questions.forEach(question => {
    if (question.type === 'inventory' && question.inventory && question.sku) {
      const { sku, inventory } = question;
      const updatedInventory: InventoryReport = {
        in: inventory.in,
        out: inventory.out,
        final: inventory.final,
        recordedBy: auth.uid,
        updatedAt: moment().toISOString(true),
        sku,
        siteKey: selectedSiteKey,
        reportKey: selectedReportKey,
        inventoryDate,
        poKey: '',
        type: 'report'
      };
      if (inventory.in >= 0 && inventory.out >= 0 && inventory.final >= 0) {
        finalInventory[sku] = updatedInventory;
      }
    } else if (question.type === 'inventory-v2' && question.skuInventory) {
      const { skuInventory } = question;
      Object.keys(skuInventory).forEach(skuKey => {
        const skuProfile = siteSKUs && siteSKUs[skuKey] && siteSKUs[skuKey].skuDetail;
        if (!skuProfile) {
          // skip
          return { status: -1 };
        }

        const inventory = skuInventory[skuKey];
        const updatedInventory: InventoryReport = {
          in: inventory.in,
          out: inventory.out,
          final: inventory.final,
          recordedBy: auth.uid,
          updatedAt: moment().toISOString(true),
          sku: skuKey,
          siteKey: selectedSiteKey,
          reportKey: selectedReportKey,
          inventoryDate,
          poKey: '',
          type: 'report'
        };

        /**
         * sku type final only can have `in === -1`
         */
        if ((skuProfile.inOrigin !== 'none' ? inventory.in >= 0 : true) && inventory.out >= 0 && inventory.final >= 0) {
          finalInventory[skuKey] = updatedInventory;
        }
      });
    }
  });

  return { status: 1, data: finalInventory };
}

export default updateInventoryData;
