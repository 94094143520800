import React from 'react';

import { StyleSheet, View } from 'react-native';
import Text from '../global/Text';
import fontMaker from '../../utils/font';
import { SubmitReportStyles } from './SubmitReport';
import { FlagCount } from '../../utils/classes';
import { useTranslation } from 'react-i18next';

export type SubmitReportOverviewProps = {
  flagCount: FlagCount;
  scoreWeighted: number;
  language: string;
};

/** Submit Report Summary consist of number of flags and the report score */
const SubmitReportSummary = (props: SubmitReportOverviewProps) => {
  const { flagCount, scoreWeighted, language } = props;
  const { t } = useTranslation(['submitReport']);
  return (
    <View>
      <View style={styles.flagContainer}>
        <View style={styles.redFlag} />
        <Text style={styles.siteText}>
          {t('submitReport:overview.redFlag')} {flagCount.red}
        </Text>
      </View>
      <View style={styles.flagContainer}>
        <View style={styles.yellowFlag} />
        <Text style={styles.siteText}>
          {t('submitReport:overview.yellowFlag')} {flagCount.yellow}
        </Text>
      </View>
      <View style={styles.flagContainer}>
        <View style={styles.greenFlag} />
        <Text style={styles.siteText}>
          {t('submitReport:overview.greenFlag')} {flagCount.green}
        </Text>
      </View>
      <View style={styles.flagContainer}>
        <View style={styles.noFlag} />
        <Text style={styles.siteText}>
          {t('submitReport:overview.score')} {scoreWeighted > -1 ? `${scoreWeighted.toFixed(0)}%` : 'N/A'}
        </Text>
      </View>
    </View>
  );
};
type SubmitReportSummaryStyles = Pick<
  SubmitReportStyles,
  'flagContainer' | 'siteText' | 'redFlag' | 'yellowFlag' | 'greenFlag' | 'noFlag'
>;

const styles = StyleSheet.create<SubmitReportSummaryStyles>({
  siteText: fontMaker({ weight: 'Light' }),
  flagContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  redFlag: {
    width: 7.5,
    height: 15,
    marginRight: 5,
    backgroundColor: '#ff2323'
  },
  yellowFlag: {
    width: 7.5,
    height: 15,
    marginRight: 5,
    backgroundColor: '#ffbf00'
  },
  greenFlag: {
    width: 7.5,
    height: 15,
    marginRight: 5,
    backgroundColor: '#3cd070'
  },
  noFlag: {
    width: 7.5,
    height: 15,
    marginRight: 5,
    backgroundColor: 'transparent'
  }
});

export default SubmitReportSummary;
