import React, { useEffect, useRef, useState } from 'react';
import { isLoaded } from 'react-redux-firebase';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import Camera from 'react-html5-camera-photo';

import ConfirmModal, { ModalConfig } from '../global/ConfirmModal';
import 'react-html5-camera-photo/build/css/index.css';

import {
  Platform,
  Dimensions,
  StyleSheet,
  View,
  ScrollView,
  KeyboardAvoidingView,
  Image,
  ActivityIndicator,
  ImageStyle,
  NativeScrollEvent,
  Modal
} from 'react-native';
import Text from '../global/Text';
import Button from '../global/Button';
import RoundedButton from '../global/RoundedButton';
import fontMaker from '../../utils/font';
import SignaturePad from 'react-signature-canvas';

import {
  Report,
  Organization,
  Site,
  EmailTarget,
  User,
  Signature,
  Questionnaire,
  SelfieSignature
} from '../../utils/classes';
import { InjectedSite } from '../../typing/types';
import SubmitReportOverview from './SubmitReportOverview';
import SubmitReportSignature from './SubmitReportSignature';
import SubmitReportTargets from './SubmitReportTargets';
import { SubmitReportStyles } from './SubmitReport';
import { useTranslation } from 'react-i18next';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../utils/screensize';
export type StateProps = {
  editingSignIndex: number;
  /** True If component is still loading */
  _isBusy: boolean;
  /** True if the text input is a valid email */
  _emailIsValid: boolean;
  /** True if the email domain is valid */
  _domainIsValid: boolean;
  /** The redux Firebase auth object */
  auth?: any;
  /** The redux FIrebase user profile object */
  profile: User;
  /** The Email Targets object */
  emailTargets: EmailTarget[];
  /** email address from the text input */
  email: string;
  /** The selected site */
  selectedSite: Site & InjectedSite;
  /** The selected site key */
  selectedSiteKey: string;
  /** The chosen report */
  selectedReport: Report;
  /** The chosen report key */
  selectedReportKey: string;
  /** Consist of Signatures Object */
  signatures: Signature[];
  /** Consist of selfie signature object */
  selfieSignatures: SelfieSignature[] | null;
  /** True if the signature is validated */
  signaturesValidation: boolean[];
  /** True if the signature is validated */
  selfieSignatureValidation: boolean | null;
  /** Language to be used throughout the app */
  language: string;
  /** The organization details */
  organization: Organization;
  /** True to display a toast and vice versa */
  toastDisplay: boolean;
  /** List of all questionnaires that corresponding to the site */
  allQuestionnaires: { [key: string]: Questionnaire };
  /** True if account is a dummy */
  isDummy: boolean;
  /** True if the device is connected to the internet */
  isConnected: boolean;
  /** Get - state tutorial mode or not */
  isTutorialMode: boolean;
  /** Get - profile color of the organization */
  profileColor: string;
  /** Get - state show or not on tooltip target email*/
  showTooltipTarget: boolean;
  /** Get - state show or not on tooltip signature*/
  showTooltipSignature: boolean;
  /** ref to scroll view */
  scrollViewRef: any;
  showSignatureModal: boolean;
  /** Handle - click email target at tutorial */
  handleScrollToReportTargets: () => void;
  /** Handle - When Checkout Button Clicked  */
  handleConfirmCheckOut: () => void;
  /** Handle - When "Tap To Sign" is clicked on signature card */
  handleDrawSignIndex: (signIdx: number) => void;
  /** Handle - When Tap the "Delete" button from signature menu */
  handleRemoveSign: (signIdx: number) => void;
  /** Handle - When Input a Signature details (name and position) */
  handleSignTextInput: (i: number, key: string, v: string) => void;
  /** Handle - Toggle for choosing email target */
  handleToggleEmailTarget: (email: string) => void;
  /** Handle - handle change email in text input */
  handleEmailChange: (text: string) => void;
  /** Handle - When text input is focused */
  handleTextInputFocused: () => void;
  /** Handle - When text input is blurred */
  handleTextInputBlur: () => void;
  /** Handle - Show Draw Menu (Change, Remove) */
  handleDrawOptions: (signIdx: number) => void;
  /** Handle - change state target email tooltip*/
  handleChangeTooltipTarget: (bool: boolean) => void;
  /** Handle - change state target signature tooltip*/
  handleShowTooltipSignature: (bool: boolean) => void;
  /** Handle - tp track scroll on submit report screen */
  handleScrollProperty: (event: NativeScrollEvent) => void;
  /** Handle - change depend on position signature card */
  handleSignatureCardYPosition: (value: number) => void;
  /** Handle - take photo for selfie signature */
  handleActivateImagePicker: () => void;
  showModalConfirmChangeSelfie: boolean;
  modalConfig: ModalConfig;
  setShowSignatureModal: (value: boolean) => void;
  setSignatures: (value: Signature[]) => void;
  setEditingSignIndex: (val: number | null) => void;
  updateReport: (report: Report) => void;
  handleTakePhoto: (uri: string) => void;
  showCamera: boolean;
  handleModalConfirmChangeSelfie: () => void;
};
export type SubmitReportContainerProps = StateProps;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const siteIcon = require('../../assets/img/icon-site.png');

const SubmitReportContainer = (props: SubmitReportContainerProps) => {
  const { t } = useTranslation(['submitReport', 'common']);
  let signatureRef: any = useRef();
  const { showSignatureModal } = props;

  const clearSignature = () => {
    if (signatureRef) {
      signatureRef?.clear();
    }
  };

  const setSignature = () => {
    try {
      const signatureImagePath = signatureRef.getTrimmedCanvas().toDataURL('image/png');
      const selectedSignature = cloneDeep(signatures[props.editingSignIndex]);
      selectedSignature.path = signatureImagePath;
      selectedSignature.isSigned = true;

      const updatedSignatures = [...signatures];
      updatedSignatures[props.editingSignIndex] = selectedSignature;

      props.updateReport({ ...selectedReport, signatures: updatedSignatures });

      props.setSignatures(updatedSignatures);
      setShowSignatureModal(false);
    } catch (error) {}
  };
  const {
    language,
    organization,
    selectedSite,
    selectedReport,
    signaturesValidation,
    signatures,
    emailTargets,
    email,
    _emailIsValid,
    _domainIsValid,
    setShowSignatureModal,
    handleTakePhoto,
    showCamera
  } = props;

  return (
    <View style={styles.root}>
      <ConfirmModal
        isVisible={props.showModalConfirmChangeSelfie}
        language={props.language}
        title={props.modalConfig.title}
        subtitle={props.modalConfig.subtitle}
        options={props.modalConfig.options}
        onCloseModal={props.handleModalConfirmChangeSelfie}
      />
      <Modal visible={showSignatureModal}>
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
          <Text style={{ fontSize: 24 }}> {t('submitReport:signatureCard.signHere')} </Text>
          <View
            style={{
              borderColor: 'grey',
              width: '100%',
              borderWidth: 1,
              borderRadius: 5,
              maxWidth: 768,
              height: 300,
              marginTop: 20
            }}
          >
            <SignaturePad ref={ref => (signatureRef = ref)} canvasProps={{ height: 300, width: 768 }} />
          </View>
          <RoundedButton
            title={t('submitReport:signatureCard.setSignature')}
            onPress={setSignature}
            style={styles.signButton}
          />
          <RoundedButton
            title={t('submitReport:signatureCard.clearSignature')}
            onPress={clearSignature}
            style={styles.signButton}
            inverse
            backgroundColor={'#3cd070'}
          />
          <RoundedButton
            inverse
            backgroundColor={'#3cd070'}
            style={[styles.signButton]}
            title={t('common:cancel')}
            onPress={() => {
              setShowSignatureModal(false);
              props.setEditingSignIndex(null);
            }}
          />
        </View>
      </Modal>
      <Modal visible={showCamera}>
        <Camera
          onTakePhoto={dataUri => {
            handleTakePhoto(dataUri);
          }}
        />
        <View style={[styles.buttonContainer]}>
          <Button onPress={props.handleCloseCamera} style={styles.button} title={t('common:cancel')} />
        </View>
      </Modal>
      {/* <UploadReportStatus isFixed={true} fromHome={false} /> */}
      <Text style={styles.title}>{t('submitReport:editReport')}</Text>
      <KeyboardAvoidingView
        style={styles.root}
        keyboardVerticalOffset={80}
        behavior="padding"
        enabled={Platform.OS === 'ios'}
      >
        {!isLoaded(organization) ? (
          <View style={styles.loading}>
            <ActivityIndicator color={props.profileColor} size="large" />
            <Text>{t('submitReport:loading')}</Text>
          </View>
        ) : (
          <ScrollView
            ref={props.scrollViewRef}
            contentContainerStyle={styles.container}
            keyboardDismissMode="interactive"
            scrollEventThrottle={10}
            onScroll={(event: any) => props.handleScrollProperty(event.nativeEvent)}
          >
            <View style={styles.site}>
              <View style={styles.sitePhotoColumn}>
                <View style={styles.sitePhoto}>
                  <Image source={siteIcon.default} style={styles.sitePhotoImage as ImageStyle} />
                </View>
              </View>
              <View style={styles.siteDetailsColumn}>
                <Text allowFontScaling={false} numberOfLines={1} ellipsizeMode="tail" style={styles.siteName}>
                  {selectedSite.name}
                </Text>
                <Text allowFontScaling={false} style={styles.siteSubtitle}>
                  {selectedSite.subtitle}
                </Text>
                <Text allowFontScaling={false} style={styles.siteText}>
                  {selectedSite.city}, {selectedSite.province}
                </Text>
                <Text allowFontScaling={false} style={styles.siteText}>
                  {t('submitReport:reportStarted')} {moment(selectedReport?.datetimeIn).format('DD MMM, hh:mmA')}
                </Text>
              </View>
            </View>
            <SubmitReportOverview selectedReport={selectedReport} language={language} />
            {props.signatures.length > 0 || (props.selfieSignatures && props.selfieSignatures.length > 0) ? (
              <React.Fragment>
                <View style={{ paddingHorizontal: 20 }}>
                  <Text style={styles.heading}>{t('submitReport:signatures')}</Text>
                </View>
                <ScrollView
                  style={{ minHeight: 200 }}
                  contentContainerStyle={{ justifyContent: 'center', paddingHorizontal: 10 }}
                  horizontal={true}
                  alwaysBounceHorizontal={true}
                  showsHorizontalScrollIndicator={false}
                  keyboardShouldPersistTaps="always"
                  onLayout={event => props.handleSignatureCardYPosition(event.nativeEvent.layout.y)}
                >
                  <SubmitReportSignature
                    language={language}
                    signatures={signatures}
                    selfieSignatures={props.selfieSignatures}
                    selfieSignatureValidation={props.selfieSignatureValidation}
                    signaturesValidation={signaturesValidation}
                    isTutorialMode={props.isTutorialMode}
                    isBusy={props._isBusy}
                    showTooltipSignature={props.showTooltipSignature}
                    handleDrawSignIndex={props.handleDrawSignIndex}
                    handleSignTextInput={props.handleSignTextInput}
                    handleDrawOptions={props.handleDrawOptions}
                    handleNextTutorial={props.handleScrollToReportTargets}
                    handleActivateImagePicker={props.handleActivateImagePicker}
                  />
                </ScrollView>
              </React.Fragment>
            ) : null}
            <SubmitReportTargets
              profile={props.profile}
              language={props.language}
              emailTargets={emailTargets}
              email={email}
              _emailIsValid={_emailIsValid}
              _domainIsValid={_domainIsValid}
              organization={props.organization}
              isTutorialMode={props.isTutorialMode}
              showTooltipTarget={props.showTooltipTarget}
              handleToggleEmailTarget={props.handleToggleEmailTarget}
              handleEmailChange={props.handleEmailChange}
              handleTextInputFocused={props.handleTextInputFocused}
              handleTextInputBlur={props.handleTextInputBlur}
              handleChangeTooltipTarget={props.handleChangeTooltipTarget}
            />
          </ScrollView>
        )}
      </KeyboardAvoidingView>
      <View style={styles.footer}>
        {props._isBusy ? (
          <ActivityIndicator size="small" color={props.profileColor} />
        ) : (
          <Button
            testID="checkout-button"
            disabled={props._isBusy}
            onPress={props.handleConfirmCheckOut}
            backgroundColor={props.profileColor}
            title={props._isBusy ? t('submitReport:loadingCapital') : t('submitReport:checkout')}
          />
        )}
      </View>
    </View>
  );
};

type SubmitReportContainerStyles = Pick<
  SubmitReportStyles,
  | 'root'
  | 'container'
  | 'title'
  | 'loading'
  | 'site'
  | 'sitePhotoColumn'
  | 'sitePhoto'
  | 'sitePhotoImage'
  | 'siteDetailsColumn'
  | 'siteName'
  | 'siteSubtitle'
  | 'siteText'
  | 'heading'
  | 'footer'
  | 'signButton'
>;

const styles = StyleSheet.create<SubmitReportContainerStyles>({
  root: {
    flex: 1,
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH
  },
  container: {
    flexGrow: 1
  },
  title: {
    paddingTop: 10,
    paddingBottom: 5,
    ...fontMaker({ weight: 'Light' }),
    textAlign: 'center'
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  site: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 20,
    paddingHorizontal: 20
  },
  sitePhotoColumn: {
    flex: 1
  },
  sitePhoto: {
    width: 70,
    height: 70,
    marginRight: 15,
    padding: 10,
    borderRadius: 35,
    backgroundColor: '#a8a8aa'
  },
  sitePhotoImage: {
    width: 50,
    height: 50,
    resizeMode: 'contain'
  },
  siteDetailsColumn: {
    flex: 3
  },
  siteName: {
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 18,
    lineHeight: 21,
    width: '70%'
  },
  siteSubtitle: {
    ...fontMaker({ weight: 'Light' }),
    opacity: 0.7
  },
  siteText: fontMaker({ weight: 'Light' }),
  heading: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: '#cacaca',
    ...fontMaker({ weight: 'Regular' }),
    fontSize: 14
  },
  footer: {
    justifyContent: 'center',
    minHeight: 64,
    height: Dimensions.get('window').height * 0.1,
    paddingVertical: 10,
    paddingHorizontal: SCREEN_WIDTH > 500 ? 250 : Dimensions.get('window').width * 0.2,
    backgroundColor: '#535353'
  },
  signButton: {
    marginTop: 10,
    paddingHorizontal: 20
  },
  button: {
    width: 400,
    marginHorizontal: 'auto'
  },
  buttonContainer: {
    minHeight: 64,
    height: SCREEN_HEIGHT * 0.1,
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH,
    marginHorizontal: 'auto',
    paddingVertical: 12,
    backgroundColor: '#535353',
    zIndex: 2,
    bottom: 0,
    position: 'sticky',
    marginTop: -10
  }
});

export default SubmitReportContainer;
