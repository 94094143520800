import React from 'react';
import 'react-html5-camera-photo/build/css/index.css';
import { useTranslation } from 'react-i18next';
import { Image, ImageBackground, StyleSheet, TouchableOpacity, View } from 'react-native';
import PhotoIcon from '../../assets/icons/add-photo.png';
import { SelfieSignature } from '../../utils/classes';
import { SCREEN_WIDTH, widthPercentageToDP as wp } from '../../utils/screensize';
import Text from '../global/Text';

type SelfieSignatureCardProps = {
  /** Get user language setting */
  language: string;
  /** selfie signature data */
  selfieSignatures: SelfieSignature[];
  /** True if selfie signature validation*/
  selfieSignatureValidation: boolean | null;
  /** Handle - activate camera to take selfie photo */
  handleActivateImagePicker: () => void;
};

const videoConstraints = {
  width: 300,
  height: 300,
  facingMode: 'user'
};
const SelfieSignatureCard = (props: SelfieSignatureCardProps) => {
  const { selfieSignatures, selfieSignatureValidation, language } = props;
  const { t } = useTranslation(['submitReport', 'common']);
  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
  }, [webcamRef]);
  const validateBorderColor =
    selfieSignatureValidation === null ? '#A0A4A8' : selfieSignatureValidation ? '#D4EFDE' : '#FFD6DA';

  const handleTakePhoto = uri => {};

  return (
    <>
      {selfieSignatures && selfieSignatures.length && selfieSignatures[0].path ? (
        <TouchableOpacity
          style={[styles.rootImage, { borderColor: validateBorderColor }]}
          onPress={props.handleActivateImagePicker}
          testID="edit-photo"
        >
          <ImageBackground
            source={{ uri: selfieSignatures[0].path }}
            resizeMode="cover"
            style={{ height: '100%', width: '100%' }}
          >
            {selfieSignatures[0].title ? (
              <View style={styles.titleContainer}>
                <Text style={{ color: '#ffffff' }}> {selfieSignatures[0].title} </Text>
              </View>
            ) : null}
          </ImageBackground>
        </TouchableOpacity>
      ) : (
        <TouchableOpacity
          style={[styles.root, { borderColor: validateBorderColor }]}
          onPress={props.handleActivateImagePicker}
          testID="button-open-camera"
        >
          <View style={styles.addPhotoContainer}>
            <Image source={PhotoIcon} style={styles.icon} />
            <Text style={styles.text}>{t('submitReport:selfieSignature.takeSelfie')}</Text>
          </View>
          {/* Show title who person will be take a selfie */}
          {selfieSignatures && selfieSignatures.length && selfieSignatures[0].title ? (
            <View style={styles.titleContainer}>
              <Text style={{ color: '#ffffff' }}> {selfieSignatures[0].title} </Text>
            </View>
          ) : null}
        </TouchableOpacity>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  root: {
    borderWidth: 1,

    borderRadius: 4,
    width: SCREEN_WIDTH > 500 ? 200 : SCREEN_WIDTH * 0.38,
    marginVertical: 14,
    marginHorizontal: 8,
    backgroundColor: '#fff',
    height: 190,
    padding: 20,

    justifyContent: 'center',
    alignItems: 'center'
  },
  rootImage: {
    borderWidth: 1,
    overflow: 'hidden',

    borderRadius: 4,
    width: SCREEN_WIDTH > 500 ? 200 : SCREEN_WIDTH * 0.38,
    marginVertical: 14,
    marginHorizontal: 8,
    backgroundColor: '#fff',
    height: 190
  },
  addPhotoContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    marginBottom: SCREEN_WIDTH > 500 ? wp(0.1) : wp(2),
    height: SCREEN_WIDTH > 500 ? wp(1.8) : wp(8),
    width: SCREEN_WIDTH > 500 ? wp(1.8) : wp(8)
  },
  text: {
    fontSize: 12,
    lineHeight: 16,

    textAlign: 'center',

    color: '#535353'
  },
  titleContainer: {
    position: 'absolute',
    width: SCREEN_WIDTH * 0.38 - 10,
    minHeight: 30,
    left: 5,
    bottom: 5,
    backgroundColor: 'rgba(0,0,0,0.5)',

    borderRadius: 4,
    overflow: 'hidden',

    justifyContent: 'center',
    alignItems: 'center'
  }
});

export default SelfieSignatureCard;
