import { createReducer, ActionType } from 'typesafe-actions';

import * as actions from './submitReport.action';

type SubmitApiReportAction = ActionType<typeof actions>;

const initialState: any = {
  isSuccess: false
};

export const submitApiReportReducer = createReducer<any, SubmitApiReportAction>(initialState)
  .handleAction(actions.submitApiReportAsync.request, state => ({
    ...state,
    isSuccess: false
  }))
  .handleAction(actions.submitApiReportAsync.success, state => ({
    ...state,
    isSuccess: true
  }))
  .handleAction(actions.submitApiReportAsync.failure, state => ({
    ...state,
    isSuccess: false
  }));
