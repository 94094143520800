import React from 'react';
import { useSelector } from 'react-redux';
import { RouteProp, useIsFocused } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack/src/types';

import { RootState } from '../store/reducers';

import { StyleSheet, View } from 'react-native';
import Questionnaire from '../components/questionnaire/Questionnaire';
import QuestionnaireDrawer from '../components/questionnaire/QuestionnaireDrawer';
import { HomeStackParamList, ScheduleStackParamList } from '../routes';

export type QuestionnairePageProps = {
  navigation: StackNavigationProp<HomeStackParamList | ScheduleStackParamList, 'Questionnaire'>;
  route: RouteProp<HomeStackParamList | ScheduleStackParamList, 'Questionnaire'>;
};

// This page is nested into QuestionnaireDrawer
const QuestionnairePage = (props: QuestionnairePageProps) => {
  const _isVisible = useSelector<RootState, boolean>(state => state.report.drawerVisible);
  const isFocused = useIsFocused();

  return (
    <View style={styles.root}>
      <QuestionnaireDrawer isVisible={_isVisible} />
      <Questionnaire {...props} isFocused={isFocused} />
    </View>
  );
};

export default QuestionnairePage;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#efeeed'
  }
});
