import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { LanguageType } from '../../constants/Languages';
import { SCREEN_WIDTH, widthPercentageToDP as wp } from '../../utils/screensize';
import Text from '../global/Text';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const PhotoIcon = require('../../assets/icons/take-photo.png');

type AddPhotoProps = {
  // isEditing exists purely to track the photo editing, to re-render
  newPhoto: boolean;
  language: LanguageType;
  isBusy?: boolean;
  isEditing?: boolean;
  isValid?: boolean;
  isTutorialMode?: boolean;
  key?: string;
  imageLink?: string;
  photoIndex?: number;
  onActivateEdit?: () => void;
  onActivateImagePicker?: () => void;
};

// Simple placeholder to add and preview photo
// Also functions as a button to activate the camera
export const AddPhoto = (props: AddPhotoProps) => {
  const { isBusy, isEditing, isValid, imageLink, newPhoto, language } = props;
  const { t } = useTranslation(['questionnaire']);
  const [width, setWidth] = React.useState<number>(0);
  const [height, setHeight] = React.useState<number>(0);
  const [newImage, setNewImage] = React.useState<string>('');

  React.useEffect(() => {
    if (props.imageLink && props.imageLink !== 'failed' && typeof props.imageLink === 'string') {
      const filePhoto = imageLink.split('/fileName/');
      const newPhoto = filePhoto[0];
      setNewImage(newPhoto);
    }
  }, [imageLink, newImage]);

  const handleImageLoaded = () => {
    if (newImage !== '') {
      Image.getSize(
        props.imageLink,
        (imageWidth, imageHeight) => {
          setWidth(imageWidth);
          setHeight(imageHeight);
        },
        err => {
          // eslint-disable-next-line no-console
          console.log(err);
        }
      );
    }
  };

  const handlePressEdit = () => props.onActivateEdit!();

  if (isBusy || isEditing) {
    return (
      <View style={styles.portraitBox} testID="activity-indicator">
        <ActivityIndicator size="small" color="#3cd070" />
      </View>
    );
  }

  let PhotoPicker;

  // if (SCREEN_WIDTH > 1024) {
  PhotoPicker = (
    <Touchable onPress={props.onActivateImagePicker}>
      <View style={[styles.portraitBox, !isValid ? styles.invalid : null]}>
        <Image source={PhotoIcon.default} style={styles.icon} />
        <Text allowFontScaling={false} style={styles.subtitle}>
          {t('questionnaire:addPhoto')}
        </Text>
      </View>
    </Touchable>
  );
  // } else {
  //   PhotoPicker = (
  //     <ImagePicker onComplete={props.onActivateImagePicker}>
  //       <View style={[styles.portraitBox, !isValid ? styles.invalid : null]}>
  //         <Image source={PhotoIcon.default} style={styles.icon} />
  //         <Text allowFontScaling={false} style={styles.subtitle}>
  //           {t('questionnaire:addPhoto')}
  //         </Text>
  //       </View>
  //     </ImagePicker>
  //   );
  // }

  return newPhoto ? (
    PhotoPicker
  ) : (
    <Touchable onPress={handlePressEdit} testID="button-edit">
      <View style={width <= height ? styles.portraitBox : styles.landscapeBox}>
        {imageLink && imageLink !== 'failed' && newImage !== '' ? (
          <Image source={{ uri: `${newImage}` }} style={styles.photo} onLoad={handleImageLoaded} />
        ) : null}
      </View>
    </Touchable>
  );
};

const Touchable: any = TouchableOpacity;

const styles = StyleSheet.create({
  portraitBox: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 80,
    height: (62 / 3) * 4 + 4,
    marginVertical: 10,
    marginHorizontal: 2,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: '#efeeed',
    backgroundColor: '#efeeed'
  },
  landscapeBox: {
    justifyContent: 'center',
    alignItems: 'center',
    width: (62 / 3) * 4 + 4,
    height: 80,
    marginVertical: 10,
    marginHorizontal: 2,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: '#efeeed',
    backgroundColor: '#efeeed'
  },
  icon: {
    marginBottom: SCREEN_WIDTH > 500 ? wp(0.1) : wp(2),
    height: SCREEN_WIDTH > 500 ? wp(1.8) : wp(8),
    width: SCREEN_WIDTH > 500 ? wp(1.8) : wp(8)
  },
  subtitle: {
    textAlign: 'center',
    fontSize: SCREEN_WIDTH > 500 ? wp(0.8) : wp(3)
  },
  invalid: {
    borderColor: '#ff6f00'
  },
  photo: {
    flex: 1,
    width: '100%',
    borderRadius: 2
  },
  innerBox: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  errorText: {
    marginTop: 5,
    fontSize: 12,
    lineHeight: 12.5,
    textAlign: 'center',
    color: '#FA6161'
  },
  progressText: {
    marginTop: 5,
    fontSize: 12,
    lineHeight: 12.5,
    textAlign: 'center',
    color: '#3cd070'
  }
});

export default AddPhoto;
