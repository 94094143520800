import React from 'react';
import { NavigationContainerRef, NavigationState, PartialState } from '@react-navigation/native';

export const navigationRef: React.RefObject<NavigationContainerRef> = React.createRef();

function navigate(name: string, params: { [key: string]: any }) {
  navigationRef.current!.navigate(name, params);
}

function reset(state: NavigationState | PartialState<NavigationState>) {
  navigationRef.current!.resetRoot(state);
}

const RootNavigation = { navigate, reset };

export default RootNavigation;
