import React from 'react';
import {
  Platform,
  StyleSheet,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  ButtonProps,
  TextStyle,
  View
} from 'react-native';

import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Text from './Text';
import fontMaker from '../../utils/font';
import { setApplyReportFilter } from '../../store/reducers/siteReport/siteReport.action';

export type HollowButtonProps = {
  title: string;
  style?: StyleProp<ViewStyle>;
  icon?: string;
  fontSize?: number;
  primaryColor?: string;
  onPress?: (event?: any) => any;
  isPressed: boolean;
  disabled?: boolean;
  accessibilityLabel?: string;
  testID?: string;
  underlayColor?: string;
  inverse?: boolean;
  isFilled?: boolean;
} & ButtonProps;

export const HollowButton: React.FunctionComponent<HollowButtonProps> = props => {
  const {
    accessibilityLabel,
    fontSize,
    primaryColor,
    onPress,
    isPressed = false,
    title,
    disabled,
    testID,
    style,
    icon,
    inverse,
    isFilled
  } = props;
  const buttonStyles: any = [styles.button];
  const textStyles: any = [styles.text];
  const viewStyles: any = [styles.view];

  if (style) {
    buttonStyles.push(style);
  }

  if (primaryColor && !inverse) {
    buttonStyles.push({ borderColor: primaryColor });
  }

  if (fontSize) {
    textStyles.push({ fontSize });
    if (fontSize < 14) {
      textStyles.push({ paddingVertical: 4 });
    }
  }

  if (primaryColor) {
    textStyles.push({ color: primaryColor });
  }

  const accessibilityStates: any = [];
  if (disabled) {
    buttonStyles.push(styles.buttonDisabled);
    textStyles.push(styles.textDisabled);
    accessibilityStates.push('disabled');
  }

  if (inverse && !disabled) {
    buttonStyles.push({
      backgroundColor: '#ffffff',
      borderWidth: 2,
      borderColor: primaryColor
    });

    textStyles.push({ color: primaryColor });
  }

  if (inverse && disabled) {
    buttonStyles.push(styles.buttonInverseDisabled);
    textStyles.push(styles.textInverseDisabled);
  }

  if (isPressed) {
    viewStyles.push({
      backgroundColor: primaryColor
    });
    textStyles.push({ color: '#fff' });
  }

  if (isFilled) {
    buttonStyles.push({ backgroundColor: primaryColor });
    viewStyles.push({
      backgroundColor: primaryColor
    });
    textStyles.push({ color: '#fff' });
  }

  return (
    <TouchableOpacity
      delayPressIn={0}
      accessibilityRole="button"
      accessibilityLabel={accessibilityLabel}
      accessibilityStates={accessibilityStates}
      testID={testID}
      disabled={disabled}
      onPress={onPress}
      style={buttonStyles}
    >
      <View style={viewStyles}>
        {icon ? <Icon name={icon} size={16} color={isPressed ? '#fff' : primaryColor} /> : null}
        <Text allowFontScaling={false} style={textStyles}>
          {title}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default HollowButton;

const styles = StyleSheet.create({
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 2,
    paddingHorizontal: 2,
    borderRadius: 40,
    borderWidth: 2,
    borderStyle: 'solid',

    alignSelf: 'flex-start'
  },
  text: {
    ...Platform.select({
      ios: {
        ...fontMaker({ weight: 'SemiBold' }),
        textAlign: 'center',
        padding: 4,
        paddingHorizontal: 8
      },
      android: {
        ...fontMaker({ weight: 'SemiBold' }),
        textAlign: 'center',
        padding: 4,
        paddingHorizontal: 8
      }
    })
  },
  buttonDisabled: {
    ...(Platform.select({
      ios: {
        backgroundColor: 'rgba(86, 199, 104, 0.3)',
        borderColor: 'rgba(86, 199, 104, 0.3)'
      },
      android: {
        elevation: 0,
        backgroundColor: 'rgba(86, 199, 104, 0.3)',
        borderColor: 'rgba(86, 199, 104, 0.3)'
      }
    }) as ViewStyle)
  },
  buttonInverseDisabled: {
    ...(Platform.select({
      ios: {
        borderColor: 'rgba(86, 199, 104, 0.3)'
      },
      android: {
        elevation: 0,
        borderColor: 'rgba(86, 199, 104, 0.3)'
      }
    }) as ViewStyle)
  },
  textDisabled: {
    ...(Platform.select({
      ios: {
        color: '#fff'
      },
      android: {
        color: '#fff'
      }
    }) as TextStyle)
  },
  textInverseDisabled: {
    ...(Platform.select({
      ios: {
        color: 'rgba(86, 199, 104, 0.3)'
      },
      android: {
        color: 'rgba(86, 199, 104, 0.3)'
      }
    }) as TextStyle)
  },
  view: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 40,
    paddingHorizontal: 8
  }
});
