const isEmail = (text: string) => {
  const pattern = [
    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]',
    '{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$'
  ].join('');
  const regex = new RegExp(pattern);
  return regex.test(text);
};

const isPhone = (text: string) => {
  const regex = /^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{8,16})$/;
  return regex.test(text);
};

export { isEmail, isPhone };
