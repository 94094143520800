import React, { useMemo, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import firebase from 'firebase';
import {
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  View,
  Image,
  Platform,
  TextInput,
  TouchableNativeFeedback
} from 'react-native';
import { useNavigation } from '@react-navigation/native';

// types
import * as Common from 'nimbly-common';
import { SelectedSite } from '../Outlet/type';

// style
import fontMaker from '../../../../utils/font';

// components
import Text from '../../../global/Text';

// utils
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

// redux
import { RootState } from '../../../../store/reducers';
import {
  setDates,
  setSitesFilterQuery,
  setResetTemporaryReport
} from '../../../../store/reducers/siteReport/siteReport.action';

// icons
// import searchIcon from '../../../../assets/icons/Search.png';
const searchIcon = require('../../../../assets/icons/Search.png');
const filterIcon = require('../../../../assets/icons/filter-icon.png');
const closeIcon = require('../../../../assets/icons/close.png');

type TabTypes = 'today' | 'last-7' | 'last-30' | 'filter';
type ReportsFilterProps = {
  selectedSite: SelectedSite;
};

const ReportsFilter = (props: ReportsFilterProps) => {
  const { selectedSite } = props;

  const dateType = useSelector<RootState, string>(state => state.siteReport.dateType);
  const organization = useSelector<RootState, Common.Organization | null | undefined>(
    state => state.organization.myOrganization
  );
  const profileColor = useSelector<RootState, '#0ABA26' | '#a6192e'>(state => state.profiletheme.color);

  const [query, setQuery] = useState<string>('');
  const { t } = useTranslation(['site', 'common']);
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const debounceFilterQuery = useCallback(
    debounce((text: string) => {
      dispatch(setSitesFilterQuery(text));
    }, 1000),
    []
  );
  const startSearch = () => {
    firebase.analytics().logEvent('search_report', {
      category: 'site_detail_page',
      siteName: selectedSite?.name
    });
  };
  const handleSearch = (e: string) => {
    setQuery(e);
    debounceFilterQuery(e);
  };

  const handleClearSearch = () => {
    setQuery('');
    debounceFilterQuery('');

    firebase.analytics().logEvent('click_close_search', {
      category: 'site_detail_page',
      siteName: selectedSite?.name,
      type: 'search report'
    });
  };

  const handleFilterPage = () => {
    firebase.analytics().logEvent('click_filter', {
      category: 'site_detail_page',
      siteName: selectedSite?.name
    });
    navigation.navigate('ReportFilter', {
      profileColor,
      selectedSite
    });
  };

  const renderTabs = useMemo(() => {
    const tabs: { value: TabTypes; label: string }[] = [
      { value: 'today', label: t('site:siteReports.time.today') },
      { value: Common.enums.PeriodTypes.LAST_7, label: t('site:siteReports.time.last-7') },
      { value: Common.enums.PeriodTypes.LAST_30, label: t('site:siteReports.time.last-30') },
      { value: 'filter', label: t('site:siteReports.time.filter') }
    ];

    const setActiveDateType = (value: (Common.enums.PeriodTypes & 'today') | null) => {
      if (value === dateType) {
        return;
      }
      firebase.analytics().logEvent('click_quick_filter', {
        category: 'site_detail_page',
        siteName: selectedSite?.name,
        filterName: value
      });
      dispatch(setDates(value, organization!.schedule));
      dispatch(setResetTemporaryReport());
    };

    const getActive = (tabVal: TabTypes) => {
      if (dateType === tabVal) {
        return true;
      }
      return false;
    };

    return tabs.map(t => (
      <Touchable
        onPress={() =>
          t.value === 'filter' ? handleFilterPage() : setActiveDateType(t.value as Common.enums.PeriodTypes & 'today')
        }
        key={t.value}
      >
        <View
          style={[
            t.value === 'filter' ? styles.buttonFilter : styles.button,
            getActive(t.value) ? styles.activeButton : {}
          ]}
        >
          {t.value === 'filter' ? <Image style={styles.image} source={filterIcon} /> : null}
          <Text style={[styles.label, getActive(t.value) ? styles.activeLabel : {}]}>{t.label}</Text>
        </View>
      </Touchable>
    ));
  }, [dateType, setDates]);

  return (
    <View style={styles.root}>
      <View style={styles.searchHeader}>
        <View style={styles.inputBox}>
          <TextInput
            style={styles.inputText}
            placeholder={t('common:search')}
            value={query}
            onChangeText={e_ => handleSearch(e_)}
            onTouchStart={() => startSearch()}
          />
          <Image source={searchIcon.default} style={styles.iconStyle} />
          <Touchable onPress={handleClearSearch}>
            <Image source={closeIcon.default} style={styles.closeStyle} />
          </Touchable>
        </View>
      </View>
      <View style={{ overflow: 'hidden' }}>
        <ScrollView
          horizontal={true}
          style={styles.container}
          contentContainerStyle={{ paddingLeft: 5, paddingRight: 20 }}
          showsHorizontalScrollIndicator={false}
        >
          {renderTabs}
        </ScrollView>
      </View>
    </View>
  );
};
const Touchable: any = Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;
export default ReportsFilter;
const styles = StyleSheet.create({
  root: {
    shadowColor: '#000',
    shadowOffset: { width: 1, height: 3 },
    shadowOpacity: 0.2,
    backgroundColor: 'white'
  },
  container: {
    paddingHorizontal: 10,
    paddingVertical: 15,
    height: 63,
    maxHeight: 63,
    backgroundColor: '#ffffff',

    // BOX SHADOW
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,

    elevation: 5
  },
  activeButton: {
    backgroundColor: '#574FCF',
    borderWidth: 0
  },
  button: {
    paddingHorizontal: 12,
    paddingVertical: 7,
    backgroundColor: '#ffffff',
    borderRadius: 5,
    borderColor: '#D8D8D8',
    borderStyle: 'solid',
    borderWidth: 1,
    marginLeft: 8
  },
  buttonFilter: {
    flexDirection: 'row',
    paddingHorizontal: 12,
    paddingVertical: 7,
    backgroundColor: '#ffffff',
    borderRadius: 5,
    borderColor: '#D8D8D8',
    borderStyle: 'solid',
    borderWidth: 1,
    marginLeft: 8,
    alignItems: 'center'
  },
  activeLabel: {
    color: '#fff'
  },
  label: {
    fontSize: 12,
    lineHeight: 18,
    color: '#535353'
  },
  image: {
    marginRight: 7,
    width: 12,
    height: 12
  },
  searchHeader: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10
  },
  inputBox: {
    width: 300
  },
  inputText: {
    backgroundColor: '#FAFAFA',
    borderRadius: 5,
    height: 35,
    textAlign: 'left',
    paddingLeft: 35,
    color: '#929292',
    ...fontMaker({ weight: 'Regular' }),
    lineHeight: 18.4
  },
  iconStyle: {
    position: 'absolute',
    left: 4,
    bottom: 4
  },
  closeStyle: {
    position: 'absolute',
    right: 4,
    bottom: 4
  }
});
