/* eslint-disable  */
import moment from 'moment-timezone';
import { Organization } from 'nimbly-common';
import React, { useEffect, useMemo, useState } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
// types
import { RootState } from '../../../../store/reducers';

import { SiteScheduleDetails } from '../../../../store/reducers/siteSchedule/siteSchedule.reducer';
import { ScheduleOption } from '../../../../typing/types';
import { OrganizationSchedule, ScheduleStatus } from '../../../../utils/classes';

// hooks
import usePeriodDate from '../../hooks/usePeriodDate';
import { SelectedSite } from '../../Site/type';
// components
import { enums } from '@nimbly-technologies/nimbly-common';
import { getEndTimeMinutes, getTodayMinutes } from '../../../../utils/getTodayMinutes';
import { getScheduleCalendarCheckInOutPermission } from '../getScheduleCalendarCheckInOutPermission';
import SiteCalendarContainer from './SiteCalendarContainer';
import { SiteCalendarProps } from './type';
const SiteCalendar = (props: SiteCalendarProps) => {
  const { selectedDate, siteReports, setSelectedDate, todaySchedules, handleSelectSchedule } = props;

  // redux state
  const selectedSiteKey = useSelector<RootState, string | null>(state => state.site.selectedSiteKey!);
  const organization = useSelector<RootState, Organization | null | undefined>(
    state => state.organization.myOrganization
  );
  const organizationSchedule = organization?.schedule || new OrganizationSchedule(undefined, undefined, 'week');
  const { startDate, endDate, dateReady } = usePeriodDate(organizationSchedule);
  const siteSchedule = useSelector<RootState, SiteScheduleDetails>(state => {
    return state.siteschedule[selectedSiteKey!];
  });
  const selectedSite = useSelector<RootState, SelectedSite>(state => state.site.selectedSite!);
  const [isInitializing, setIsInitializing] = useState<boolean>(true);

  const isConnected = useSelector<RootState, boolean>((rootState: RootState) => rootState.network.isConnected);
  const featureAccessOfflineAudit = useSelector<RootState, boolean>(state =>
    Boolean(state.featureAccess.features[enums.Features.OFFLINE_AUDIT])
  );
  const roleAccessAdhoc = useSelector(
    (state: RootState) => state.account.role?.resourceMap['application:report:adhoc'].create || false
  );
  const featureAccessAdhoc = useSelector((state: RootState) =>
    Boolean(state.featureAccess.features[enums.Features.ADHOC_REPORTING])
  );

  if (selectedSite?.timezone) {
    moment.tz.setDefault(selectedSite?.timezone);
  }

  const draftScheduleMap = useMemo(() => {
    const mappedReport: {
      [scheduleKey: string]: {
        [reportKey: string]: {
          key: string;
          date: string;
        };
      };
    } = {};
    Object.keys(siteReports || {}).forEach(reportKey => {
      const report = siteReports[reportKey];

      if (report.status === 'draft' && report.scheduleKey) {
        if (!mappedReport[report.scheduleKey]) {
          mappedReport[report.scheduleKey] = {};
        }

        mappedReport[report.scheduleKey][reportKey] = {
          key: reportKey,
          date: moment.parseZone(report.datetimeScheduled).format('YYYY-MM-DD')
        };
      }
    });

    return mappedReport;
  }, [siteReports]);

  /**
   * Check completed report
   * to make sure report status isCompleted
   */
  const checkIsAlreadyComplete = (scheduleDate: string, scheduleKey: string) => {
    let isComplete = false;

    for (const [key, sum] of Object.entries(siteReports)) {
      const date = selectedSite!.timezone
        ? moment.tz(sum.datetimeScheduled, selectedSite!.timezone).format('YYYY-MM-DD')
        : moment(sum.datetimeScheduled).format('YYYY-MM-DD');

      isComplete =
        // change this key slicing when report is rewired to new db
        date === scheduleDate && sum.scheduleKey === scheduleKey && sum.status === 'complete' && !sum.isAdhoc;

      if (isComplete) {
        break;
      }
    }

    return isComplete;
  };

  // generate one week data schedule
  // to show the detail for every date
  const summarySingleSiteSchedules = useMemo(() => {
    const getDraftSchedule = (key: string, date: string) => {
      const draftSummary = draftScheduleMap[key] || {};
      let draftVal: { date: string; key: string } | null = null;

      Object.keys(draftSummary || {}).forEach(reportKey => {
        if (draftSummary[reportKey].date === date && Object.keys(draftSummary[reportKey]).length > 0) {
          draftVal = draftSummary[reportKey];
        }
      });

      if (draftVal) {
        return draftVal;
      }

      return null;
    };

    const scheduleSummaries: { [date: string]: Partial<ScheduleOption>[] } = {};

    if (isInitializing) {
      // return immediately on 1st visit, process below is heavy and blocks the UI
      setTimeout(() => setIsInitializing(false), 0);
      return scheduleSummaries;
    }

    (siteSchedule?.singleSchedules || []).forEach(s => {
      const { startDate, startTime, endDate, endTime, datesCustomInfo } = s;

      // Convert to moment object for startDate and startTime
      let startDateTime = moment(startDate).add(startTime, 'minutes');
      let endDateTime = moment(endDate?.length > 1 ? endDate : startDate).add(endTime, 'minutes');

      if (s.type === 'custom') {
        const dateCustom = datesCustomInfo[0] ?? {};

        startDateTime = moment(dateCustom.startDate);
        endDateTime = moment(dateCustom.endDate);
      } else if (s.type === 'never') {
        startDateTime = moment(startDate).add(startTime, 'minutes');
        endDateTime = moment(startDate).add(endTime, 'minutes');
      }

      // handle custom schedule with empty schedule date
      if (Object.keys(s.scheduledDates).length <= 1 && s.type === 'never') {
        // if()
        // Set the default as weekly period unit

        let daySpan = endDateTime.clone().endOf('day').diff(startDateTime.clone().startOf('day'), 'days') + 1;

        if (s.isAdhocOnly) {
          daySpan = 7;
        }

        for (let i = 0; i < daySpan; i++) {
          const date = moment(startDateTime).clone().add(i, 'day').format('YYYY-MM-DD');

          let status: ScheduleStatus = s.isAdhocOnly ? 'adhoc' : 'ready';

          if (!scheduleSummaries[date]) {
            scheduleSummaries[date] = [];
          }

          if (s.totalScheduled === s.totalCompleted) {
            status = 'finished-period';
          }

          if (s.scheduledDates[date]?.isComplete || checkIsAlreadyComplete(startDateTime.format('YYYY-MM-DD'), s.key)) {
            status = 'finished-today';
          }

          const draftSchedule = getDraftSchedule(s.key, startDateTime.format('YYYY-MM-DD'));
          if (draftSchedule) {
            status = 'draft';
          }

          scheduleSummaries[date].push({
            ...s,
            key: s.key,
            title: s.questionnaireTitle,
            hasDeadline: s.hasStrictTime,
            startTime: startDateTime.hours() * 60 + startDateTime.clone().minutes(),
            startDate: startDateTime.format('YYYY-MM-DD'),
            endDate: endDateTime.format('YYYY-MM-DD'),
            endTime: endDateTime.hours() * 60 + endDateTime.clone().minutes(),
            status: status,
            type: s.type,
            questionnaire: s.questionnaireDetails.value,
            draftReport: draftSchedule,
            hasAdhocCustom: s?.hasAdhocCustom,
            isAdhocOnly: s?.isAdhocOnly,
            allowAdhoc: s?.allowAdhoc
          });
        }
      } else if (s.type === 'custom') {
        const { datesCustomInfo } = s;
        if (datesCustomInfo.length > 0) {
          datesCustomInfo.forEach(dateCustom => {
            const { endDate, startDate } = dateCustom;
            const startDateTime = moment.tz(startDate, selectedSite?.timezone ?? '');
            const endDateTime = moment.tz(endDate, selectedSite?.timezone ?? '');
            const startDateKey = startDateTime.clone().format('YYYY-MM-DD');

            let status: ScheduleStatus = s.isAdhocOnly ? 'adhoc' : 'ready';

            const draftSchedule = getDraftSchedule(s.key, startDateKey);
            if (draftSchedule) {
              status = 'draft';
            }

            const totalDay = endDateTime.clone().endOf('day').diff(startDateTime.clone().startOf('day'), 'days');
            for (let i = 0; i <= totalDay; i++) {
              const date = startDateTime.clone().add(i, 'day').format('YYYY-MM-DD');
              const isOldSchedule = moment.tz(selectedSite?.timezone || '').isAfter(moment(endDateTime), 'day');

              if (s.totalScheduled === s.totalCompleted) {
                status = 'finished-period';
              }

              if (s.scheduledDates[startDateKey]?.isComplete || checkIsAlreadyComplete(startDateKey, s.key)) {
                status = 'finished-today';
              }

              if (isOldSchedule) {
                if (s?.scheduledDates?.[startDateKey]?.isComplete) {
                  s.isDoneToday = true;
                } else {
                  status = 'overdue';
                }
              }

              if (!scheduleSummaries[date]) {
                scheduleSummaries[date] = [];
              }

              if (startDateTime.format('YYYY-MM-DD') !== endDateTime.format('YYYY-MM-DD')) {
                if (s?.scheduledDates?.[startDateKey]?.isComplete) {
                  if (
                    startDateTime.format('YYYY-MM-DD') === startDateKey ||
                    endDateTime.format('YYYY-MM-DD') === startDateKey
                  ) {
                    status = 'finished-today';
                  } else {
                    status = 'finished-period';
                  }
                }
              }

              scheduleSummaries[date].push({
                ...s,
                key: s.key,
                title: s.questionnaireTitle,
                hasDeadline: s.hasStrictTime,
                startTime: startDateTime.clone().hours() * 60 + startDateTime.clone().minutes(),
                startDate: startDateTime.format('YYYY-MM-DD'),
                endDate: endDateTime.format('YYYY-MM-DD'),
                endTime: endDateTime.clone().hours() * 60 + endDateTime.clone().minutes(),
                status: status,
                type: s.type,
                questionnaire: s.questionnaireDetails.value,
                draftReport: draftSchedule,
                hasAdhocCustom: s?.hasAdhocCustom,
                isAdhocOnly: s?.isAdhocOnly,
                allowAdhoc: s?.allowAdhoc
              });
            }
          });
        } else {
          let daySpan = endDateTime.clone().endOf('day').diff(startDateTime.clone().startOf('day'), 'days') + 1;

          if (s.isAdhocOnly) {
            daySpan = 30;
          }
          for (let i = 0; i < daySpan; i++) {
            const date = moment(startDateTime).clone().add(i, 'day').format('YYYY-MM-DD');

            let status: ScheduleStatus = s.isAdhocOnly ? 'adhoc' : 'ready';

            if (!scheduleSummaries[date]) {
              scheduleSummaries[date] = [];
            }

            const draftSchedule = getDraftSchedule(s.key, date);
            if (draftSchedule) {
              status = 'draft';
            }

            scheduleSummaries[date].push({
              ...s,
              key: s.key,
              title: s.questionnaireTitle,
              hasDeadline: s.hasStrictTime,
              startTime: startDateTime.hours() * 60 + startDateTime.clone().minutes(),
              startDate: startDateTime.format('YYYY-MM-DD'),
              endDate: endDateTime.format('YYYY-MM-DD'),
              endTime: endDateTime.hours() * 60 + endDateTime.clone().minutes(),
              status: status,
              type: s.type,
              questionnaire: s.questionnaireDetails.value,
              draftReport: draftSchedule,
              hasAdhocCustom: s?.hasAdhocCustom,
              isAdhocOnly: s?.isAdhocOnly,
              allowAdhoc: s?.allowAdhoc
            });
          }
        }
      } else {
        Object.keys(s.scheduledDates).forEach(date => {
          let startDateTime = moment(date).add(s.startTime, 'minutes');
          let endDateTime = moment(date).add(s.endTime, 'minutes');
          let daySpan = endDateTime.clone().endOf('day').diff(startDateTime.clone().startOf('day'), 'days') + 1;

          for (let i = 0; i < daySpan; i++) {
            const scheduleDate = moment(startDateTime).clone().add(i, 'day').format('YYYY-MM-DD');

            let status = 'ready' as ScheduleStatus;

            if (!scheduleSummaries[scheduleDate]) {
              scheduleSummaries[scheduleDate] = [];
            }

            if (s.totalScheduled === s.totalCompleted) {
              status = 'finished-period';
            }

            if (s.scheduledDates[date]?.isComplete || checkIsAlreadyComplete(date, s.key)) {
              status = 'finished-today';
            }

            const draftSchedule = getDraftSchedule(s.key, date);

            if (draftSchedule) {
              status = 'draft';
            }

            const scheduleEndDate = moment(scheduleDate).add(endDate, 'minutes');
            const isOldSchedule = moment.tz(selectedSite?.timezone || '').isAfter(moment(scheduleEndDate), 'day');

            if (isOldSchedule) {
              if (s?.scheduledDates?.[date]?.isComplete) {
                s.isDoneToday = true;
              } else {
                status = 'overdue';
              }
            }

            if (!scheduleSummaries[scheduleDate]) {
              scheduleSummaries[scheduleDate] = [];
            }
            let scheduleStartTime = s.startTime;
            let scheduleEndTime = s.endTime;

            if ((!scheduleStartTime || !scheduleEndTime) && s?.datesCustomInfo?.length) {
              let dateCustomValue = s?.datesCustomInfo?.filter(
                dateCustom => moment(dateCustom.startDate).format('YYYY-MM-DD') === selectedDate
              );

              scheduleStartTime = getTodayMinutes(dateCustomValue?.[0]?.startDate) || 0;
              scheduleEndTime = getEndTimeMinutes(dateCustomValue?.[0]?.startDate, dateCustomValue?.[0]?.endDate) || 0;
            }

            scheduleSummaries[scheduleDate].push({
              ...s,
              key: s.key,
              title: s.questionnaireTitle,
              hasDeadline: s.hasStrictTime,
              startTime: scheduleStartTime,
              endTime: scheduleEndTime,
              startDate: startDateTime.format('YYYY-MM-DD'),
              status: status,
              type: s.type,
              questionnaire: s.questionnaireDetails.value,
              draftReport: draftSchedule,
              hasAdhocCustom: s?.hasAdhocCustom,
              isAdhocOnly: s?.isAdhocOnly,
              allowAdhoc: s?.allowAdhoc
            });
          }
        });
      }
    });
    return scheduleSummaries;
  }, [siteSchedule, todaySchedules, siteReports, draftScheduleMap, dateReady, startDate, isInitializing, selectedDate]);

  useEffect(() => {
    // reset to current date if leave calendar page
    setSelectedDate(
      moment.tz(siteSchedule.singleSchedules?.[0]?.siteDateTime, selectedSite?.timezone ?? '').format('YYYY-MM-DD')
    );
    return () => {
      setSelectedDate(moment().format('YYYY-MM-DD'));
    };
  }, []);

  if (!dateReady || isInitializing) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="small" color="#3cd070" />
      </View>
    );
  }

  return (
    <SiteCalendarContainer
      organization={organization!}
      scheduleSummaries={summarySingleSiteSchedules}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      startDate={startDate}
      endDate={endDate}
      handleSelectSchedule={handleSelectSchedule}
      isConnected={isConnected}
      siteReports={siteReports}
      featureAccessOfflineAudit={featureAccessOfflineAudit}
    />
  );
};

const styles = StyleSheet.create({
  loadingContainer: {
    justifyContent: 'center',
    flex: 1
  }
});

export default SiteCalendar;
