import { InjectedSiteStatus } from '../../../typing/types';
import { ScheduledJourneyInfo } from '../../../store/reducers/schedulejourneys';
import getJourneyReportStatus from './getJourneyReportStatus';

/**
 * Generate journey status string based on schedulded info
 * @param scheduledJourneyInfo
 */
function getJourneyStatus(
  scheduledJourneyInfo: ScheduledJourneyInfo,
  onGoingJourneyReportKey?: string | null
): InjectedSiteStatus {
  if (onGoingJourneyReportKey) {
    if (getJourneyReportStatus(scheduledJourneyInfo.report, onGoingJourneyReportKey) === 'ongoing') {
      return 'ongoing';
    }
    if (getJourneyReportStatus(scheduledJourneyInfo.report, onGoingJourneyReportKey) === 'finished') {
      return 'finished-today';
    }
  }

  switch (true) {
    case scheduledJourneyInfo.isPendingUpload:
      return 'pending-upload';
    case scheduledJourneyInfo.isDraft:
      return 'draft';
    case scheduledJourneyInfo.isMakeUp:
      return 'makeup';
    case scheduledJourneyInfo.isCompletedThisPeriod:
      return 'finished-period';
    case scheduledJourneyInfo.isCompletedToday:
      return 'finished-today';
    case !scheduledJourneyInfo.isScheduledThisPeriod:
      return 'none-due';
    default:
      return 'ready';
  }
}

export default getJourneyStatus;
