import React from 'react';
import { View } from 'react-native';
import { AUTH_MODE } from '../../constants/Constants';
import { LanguageType } from '../../constants/Languages';
import { AuthFormInput, AuthFormValidation } from './Auth';
import AuthForm from './AuthForm';
import AuthVideoTutorialModal from './AuthVideoTutorialModal';

export interface AuthContainerProps {
  /** To check if the account is frozen or not */
  freezeBanner: boolean;
  /** Mode of the auth component - Sign Up, Login, or Reset Password */
  isFederatedAuth: boolean;

  authMode: AUTH_MODE;
  /** Language to be used throughout the app */
  language: LanguageType;
  /** Boolean true if the phone has a notch */
  hasNotch: boolean;
  /** The auth form field state */
  form: AuthFormInput;
  /** The auth form validation state */
  formValidation: AuthFormValidation;
  /** Show or hide password */
  isPasswordVisible: boolean;
  /** True if component is waiting (for auth server to return) */
  _isBusy: boolean;
  /** True if login email (dynamic link) has been sent */
  _emailSentSuccess: boolean;
  /** True if caps lock is detected on the user phone, warning on password input */
  _capsWarning: boolean;
  /** True if user is on the password field, allowing then next Enter key to submit the form */
  _submitOnEnter: boolean;
  /** The Phone 2FA */
  _twoFactorConfirmation: any;
  /** Get - state of modal tutorial */
  visibleTutorialModal: boolean;
  /** Event - focus is moved to a lower text field */
  onFocusMovedDown: () => void;
  /** Event - 2FA code text input */
  onCodeChange: (text: string) => void;
  /** Event - 2FA process terminated */
  onCancelValidation: () => void;
  /** Event - 2FA code input submitted */
  onSubmitValidation: () => void;
  /** Event - Phone number text input */
  onPhoneNumberChange: (text: string) => void;
  /** Event - Email text input */
  onEmailChange: (text: string) => void;
  /** Event - Email or Phone text input */
  onEmailOrPhoneChange: (text: string) => void;
  /** Event - Password text input */
  onPasswordChange: (text: string) => void;
  /** Event - Signup form submitted */
  onSubmitSignup: () => void;
  /** Event - Login form submitted */
  onSubmitLogin: () => void;
  /** Event - Forgot password form submitted */
  onSubmitPasswordReset: () => void;
  /** Event - Change authentication mode to Sign Up */
  onChangeSectionSignup: () => void;
  /** Event - Change authentication mode to Login */
  onChangeSectionLogin: () => void;
  /** Event - Change authentication mode to Forgot Password */
  onChangeSectionForgotPassword: () => void;
  /** Event - Contact WhatsApp Support button clicked */
  onContactWhatsAppSupport: () => void;
  /** Event - Email text input focused */
  onEmailTextInputFocused: () => void;
  /** Event - Password text input focused */
  onPasswordTextInputFocused: () => void;
  /** Handle - show of hide video tutorial modal */
  handleVisibleTutorialModal: any;
  /** Handle - show or hide login password */
  onTogglePasswordVisibility: () => void;
  onPressResendOTP: () => void;
  onSubmitRequestFederated: () => void;
  showModalRequestAccess: boolean;
  onCloseModalRequest: () => void;
  hasPendingCreds: boolean;
}

const AuthContainer = (props: AuthContainerProps) => {
  return (
    <View>
      <AuthVideoTutorialModal
        hasNotch={props.hasNotch}
        handleVisibleTutorialModal={props.handleVisibleTutorialModal}
        visibleTutorialModal={props.visibleTutorialModal}
      />
      <AuthForm
        onSubmitRequestFederated={props.onSubmitRequestFederated}
        showModalRequestAccess={props.showModalRequestAccess}
        onCloseModalRequest={props.onCloseModalRequest}
        freezeBanner={props.freezeBanner}
        isFederatedAuth={props.isFederatedAuth}
        hasPendingCreds={props.hasPendingCreds}
        hasNotch={props.hasNotch}
        language={props.language}
        authMode={props.authMode}
        form={props.form}
        formValidation={props.formValidation}
        isPasswordVisible={props.isPasswordVisible}
        _isBusy={props._isBusy}
        _capsWarning={props._capsWarning}
        _emailSentSuccess={props._emailSentSuccess}
        _submitOnEnter={props._submitOnEnter}
        onFocusMovedDown={props.onFocusMovedDown}
        onChangeSectionSignup={props.onChangeSectionSignup}
        onChangeSectionLogin={props.onChangeSectionLogin}
        onChangeSectionForgotPassword={props.onChangeSectionForgotPassword}
        onPhoneNumberChange={props.onPhoneNumberChange}
        onEmailChange={props.onEmailChange}
        onEmailOrPhoneChange={props.onEmailOrPhoneChange}
        onPasswordChange={props.onPasswordChange}
        onEmailTextInputFocused={props.onEmailTextInputFocused}
        onPasswordTextInputFocused={props.onPasswordTextInputFocused}
        onSubmitLogin={props.onSubmitLogin}
        onSubmitPasswordReset={props.onSubmitPasswordReset}
        onSubmitSignup={props.onSubmitSignup}
        onContactWhatsAppSupport={props.onContactWhatsAppSupport}
        handleVisibleTutorialModal={props.handleVisibleTutorialModal}
        onTogglePasswordVisibility={props.onTogglePasswordVisibility}
        onCodeChange={props.onCodeChange}
        onCancelValidation={props.onCancelValidation}
        onSubmitValidation={props.onSubmitValidation}
        onPressResendOTP={props.onPressResendOTP}
      />
    </View>
  );
};

export default AuthContainer;
