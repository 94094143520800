import firebase from 'firebase';
import { createAsyncAction, createReducer, ActionType } from 'typesafe-actions';
import { put, call } from 'redux-saga/effects';

import { User, AllUsers } from '../../../utils/classes';
import { apiProdURL } from '../../../constants/api';

export const FETCH_USERS_REQUEST = '@user/FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = '@user/FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = '@user/FETCH_USERS_FAILURE';

export const fetchUsersAsync = createAsyncAction(FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS, FETCH_USERS_FAILURE)<
  number | undefined,
  User[],
  Error
>();

export const userActions = { fetchUsersAsync };

export function* fetchUsersSaga(action: ReturnType<typeof fetchUsersAsync.request>): Generator {
  const tryCount = action.payload || 1;
  try {
    const idToken = (yield firebase.auth().currentUser!.getIdToken()) as string;

    const requestRoles = () =>
      fetch(`${apiProdURL}/user-roles/all`, {
        method: 'GET',
        headers: {
          authorization: idToken
        }
      });
    const request = () =>
      fetch(`${apiProdURL}/users`, {
        method: 'GET',
        headers: {
          authorization: idToken
        }
      });
    const responseRoles = (yield call(requestRoles)) as Body;
    const response = (yield call(request)) as Body;
    const bodyRoles = (yield responseRoles.json()) as any;
    const body = (yield response.json()) as any;
    const roleMap: { [role: string]: number } = {};
    bodyRoles.data.forEach((item: any) => {
      roleMap[item.role] = item.level;
    });
    body.data.forEach((user: User) => {
      user.level = roleMap[user.role];
    });
    yield put(fetchUsersAsync.success(body.data));
  } catch (err) {
    // Retry up to 3 times before throwing a failure
    if (tryCount >= 3) {
      yield put(fetchUsersAsync.request(tryCount + 1));
    } else {
      yield put(fetchUsersAsync.failure(err));
    }
  }
}

export type UserAction = ActionType<typeof userActions>;
export type UserState = {
  readonly isLoading: boolean;
  readonly lastUpdated: number | null;
  readonly errorMessage: string | null;
  readonly users: AllUsers;
};

const initialState: UserState = {
  isLoading: false,
  lastUpdated: null,
  errorMessage: null,
  users: {}
};

export const userReducer = createReducer<UserState, UserAction>(initialState)
  .handleAction(fetchUsersAsync.request, state => ({
    ...state,
    isLoading: true,
    errorMessage: null
  }))
  .handleAction(fetchUsersAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    errorMessage: action.payload.message
  }))
  .handleAction(fetchUsersAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    lastUpdated: Date.now(),
    users: action.payload.reduce((map: AllUsers, obj: User) => {
      map[obj.userID!] = obj;
      return map;
    }, {})
  }));
