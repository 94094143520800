import React from 'react';
import { StyleSheet, View } from 'react-native';

interface AppDividerProps {
  width?: number;
}
const AppDivider: React.SFC<AppDividerProps> = props => {
  const { width } = props;
  const dividerStyles: any = [styles.divider];
  if (width) {
    dividerStyles.push({ width });
  }

  return <View style={dividerStyles} />;
};

const styles = StyleSheet.create({
  divider: {
    height: 0,
    width: 240,
    marginVertical: 12,
    borderStyle: 'solid',
    borderTopWidth: 1.2,
    borderTopColor: '#cacaca'
  }
});

export default AppDivider;
