import React from 'react';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack/src/types';

import { StyleSheet, View, ActivityIndicator } from 'react-native';
import { WebView } from 'react-native-webview';
import { HomeStackParamList } from '../routes';

type QuestionReferencePageProps = {
  route: RouteProp<HomeStackParamList, 'QuestionReference'>;
  navigation: StackNavigationProp<HomeStackParamList, 'QuestionReference'>;
};

const QuestionReferencePage = (props?: QuestionReferencePageProps) => {
  /** Simple tracker for page load progress */
  const [_pageLoaded, setPageLoaded] = React.useState(false);

  // When page finishes loading, hide the ActivityIndicator
  const handleLoadEnd = () => {
    if (!_pageLoaded) {
      setPageLoaded(true);
    }
  };

  const getURL = () => {
    const { url } = props!.route.params;
    const lastPart = url.substr(url.lastIndexOf('.') + 1);
    if (lastPart === 'pdf') {
      return `http://docs.google.com/gview?embedded=true&url=${url}`;
    }
    return url;
  };

  return (
    <View style={styles.root}>
      {!_pageLoaded ? (
        <View style={styles.loadingContainer}>
          <ActivityIndicator color="#3cd070" size="large" />
        </View>
      ) : null}
      <WebView androidHardwareAccelerationDisabled={true} source={{ uri: getURL() }} onLoadEnd={handleLoadEnd} />
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#efeeed'
  },
  loadingContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 100
  }
});

export default QuestionReferencePage;
