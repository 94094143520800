/**
 * React Mandatory
 */
import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

/**
 * Page Components
 */
import Text from './Text';
import {
  StyleSheet,
  Animated,
  SafeAreaView,
  TouchableOpacity,
  TouchableNativeFeedback,
  TouchableWithoutFeedbackProps,
  Platform,
  ViewStyle,
  View,
  TextStyle
} from 'react-native';

/**
 * Reducers
 */
import { RootState } from '../../store/reducers';
import { ConnectedDispatch } from '../../typing/types';
import { networkActions } from '../../store/reducers/network';
import { handleConnectionTimeout } from '../../utils/handleConnectionTimeout';
import fontMaker from '../../utils/font';
import { LanguageType } from '../../constants/Languages';
import { useTranslation } from 'react-i18next';

/**
 * Display status of internet connection. Statue on this page is determined
 * with isThrottled variable from the network redux store.
 * @param isHome (props, boolean): When true, will apply <SafeAreaView>,
 * thus giving space to the component from the top of the screen.
 */
export const ConnectionStatus = (props: ConnectionStatusProps & ConnectedDispatch) => {
  const { t } = useTranslation(['common']);
  const [_animatedValue, setAnimatedValue] = useState<Animated.Value>(new Animated.Value(0));
  const [_animation, setAnimation] = useState<Animated.AnimatedInterpolation>(
    _animatedValue.interpolate({
      inputRange: [0, 0.3, 1],
      outputRange: [-70, 10, 0]
    })
  );

  const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
    Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

  /**
   * Attempt to send request again to server. Request can only be send once
   * every 15 seconds.
   *
   */
  // const handleCheckConnection = () => {
  //   if (props.retryTimeout! <= 0) {
  //     props.dispatch(networkActions.setRetryTimeout(15));
  //     fetch('https://admin.hellonimbly.com/api/ping', {
  //       method: 'POST',
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json'
  //       },
  //       body: null
  //     })
  //       .then((val: any) => {
  //         props.dispatch(networkActions.setSpeedThrottled(false));
  //       })
  //       .catch((val: any) => {
  //         if (props.retryTimeout! > 3) {
  //           props.dispatch(networkActions.setRetryTimeout(3));
  //         }
  //       });
  //   }
  // };

  /**
   * Countdown system
   *
   */
  useEffect(() => {
    if (props.retryTimeout! <= 0) {
      return;
    }

    const intervalId = setInterval(() => {
      const retryTimeout = props.retryTimeout ? props.retryTimeout : 1;
      props.dispatch(networkActions.setRetryTimeout(retryTimeout - 1));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [props.retryTimeout]);

  if (props.fromHome) {
    return (
      <Animated.View style={[styles.root, !props.throttled ? styles.hide : null]}>
        <SafeAreaView>
          <View style={[styles.safeRoot, styles.fromHome]}>
            <Text style={styles.text} testID="connStatusString">
              {t('common:poorConnection')}
            </Text>
            {/* <Touchable onPress={handleCheckConnection} style={styles.button}>
              <Text style={[styles.buttonText]}>
                RETRY{props.retryTimeout! > 0 ? ` (${props.retryTimeout})` : null}
              </Text>
            </Touchable> */}
          </View>
        </SafeAreaView>
      </Animated.View>
    );
  }
  return (
    <Animated.View style={[styles.root, styles.safeRoot, !props.throttled ? styles.hide : null]}>
      <Text style={styles.text} testID="connStatusString">
        {t('common:poorConnection')}
      </Text>
      {/* <Touchable onPress={handleCheckConnection} style={styles.button}>
        <Text style={[styles.buttonText]}>RETRY{props.retryTimeout! > 0 ? ` (${props.retryTimeout})` : null}</Text>
      </Touchable> */}
    </Animated.View>
  );
};

const mapStateToProps = (state: RootState): ConnectionStatusProps => ({
  throttled: state.network.isThrottled,
  connected: state.network.isConnected,
  retryTimeout: state.network.retryTimeout,
  language: state.firebase.profile.language || 'en'
});

/**
 * Component props
 */
export type ConnectionStatusProps = {
  throttled?: boolean;
  connected?: boolean;
  retryTimeout?: number;
  fromHome?: boolean;
  language?: LanguageType;
};

export default compose(connect(mapStateToProps))(ConnectionStatus) as any;

/**
 * Component styling
 */
const styles = StyleSheet.create({
  root: {
    position: 'relative',
    padding: 15,
    zIndex: 1000,
    width: '100%',
    backgroundColor: '#FFC81F'
  },
  safeRoot: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  fromHome: {
    paddingTop: 22
  },
  text: {
    flex: 8,
    marginLeft: 20,
    color: 'white',
    fontSize: 14
  },
  hide: {
    display: 'none'
  },
  button: {
    flex: 3,
    paddingVertical: 8,
    color: 'white'
  },
  buttonText: {
    color: 'white',
    textAlign: 'center',
    ...fontMaker({ weight: 'SemiBold' })
  }
});
