import React from 'react';
import Text from '../global/Text';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { LanguageType } from '../../constants/Languages';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { landingActions } from '../../store/reducers/landing';

export type ItemLanguageOptionProps = {
  options: { id: string; title: string };
  onLanguageChange: (language: LanguageType) => void;
};

export const ItemLanguageOption = (props: ItemLanguageOptionProps) => {
  const dispatch = useDispatch();

  const language = useSelector<RootState, LanguageType>(state =>
    state.firebase.profile && state.firebase.profile.isLoaded && !state.firebase.profile.isEmpty
      ? state.firebase.profile.language
      : state.landing.language
  );

  const handleSelectLanguage = () => {
    props.onLanguageChange(props.options.id as LanguageType);
    dispatch(landingActions.setModalLanguageVisible(false));
    return;
  };

  return (
    <TouchableOpacity onPress={handleSelectLanguage}>
      <View
        style={[
          styles.languageSelectorContainer,
          language === props.options.id ? styles.languageSelectorContainerSelected : null
        ]}
      >
        <Text
          style={[
            styles.languageSelectorText,
            language === props.options.id ? styles.languageSelectorTextSelected : null
          ]}
        >
          {props.options.title}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  languageSelectorContainer: {
    paddingVertical: 15,
    paddingHorizontal: 25
  },
  languageSelectorText: {},
  languageSelectorContainerSelected: {
    backgroundColor: '#D4EFDE'
  },
  languageSelectorTextSelected: {
    fontWeight: 'bold'
  }
});

export default ItemLanguageOption;
