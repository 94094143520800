import firebase from 'firebase';
import moment from 'moment';

import {
  ReportIndex,
  SiteSchedule,
  QuestionnaireSchedule,
  QuestionnaireIndex,
  Schedule,
  ScheduleStatusEnum
} from '../../../utils/classes';
import theme from '../../../constants/theme';
type DateCountIndex = {
  [date: string]: {
    exists: boolean;
    index: number;
  };
};

type DateCountArray = { date: string; count: number }[];

const makeDailyDateCountIndex = (startDate: string, endDate: string): [DateCountIndex, DateCountArray] => {
  const dateCountIndex: DateCountIndex = {};
  const dateCountArray: DateCountArray = [];

  let index = 0;
  for (const m = moment(startDate); m.isSameOrBefore(endDate, 'day'); m.add(1, 'day')) {
    const date = m.format('YYYY-MM-DD');
    dateCountIndex[date] = {
      exists: true,
      index
    };

    dateCountArray.push({ count: 1, date });
    index++;
  }

  return [dateCountIndex, dateCountArray];
};

const makeWeeklyDateCountIndex = (
  daysOfWeek: number[],
  periodStartDate: string,
  periodEndDate: string
): [DateCountIndex, DateCountArray] => {
  const dateCountIndex: DateCountIndex = {};
  const dateCountArray: DateCountArray = [];

  const m = moment();
  let index = 0;
  for (const isoWeekday of daysOfWeek.sort()) {
    const date = m.isoWeekday(isoWeekday).format('YYYY-MM-DD');
    if (date < periodStartDate || date > periodEndDate) {
      continue;
    }

    dateCountIndex[date] = {
      index,
      exists: true
    };
    dateCountArray.push({ count: 1, date });
    index++;
  }

  return [dateCountIndex, dateCountArray];
};

const makeMonthlyDateCountIndex = (
  datesOfMonth: number[],
  periodStartDate: string,
  periodEndDate: string
): [DateCountIndex, DateCountArray] => {
  const dateCountIndex: DateCountIndex = {};
  const dateCountArray: DateCountArray = [];

  const m = moment();

  let index = 0;
  for (const currentDate of datesOfMonth.sort()) {
    const date = m.date(currentDate).format('YYYY-MM-DD');
    if (date < periodStartDate || date > periodEndDate) {
      continue;
    }
    dateCountIndex[date] = {
      index,
      exists: true
    };
    dateCountArray.push({ count: 1, date });
    index++;
  }

  return [dateCountIndex, dateCountArray];
};

const makeCustomDateCountIndex = (
  datesCustom: Schedule,
  periodStartDate: string,
  periodEndDate: string
): [DateCountIndex, DateCountArray] => {
  const dateCountIndex: DateCountIndex = {};
  const dateCountArray: DateCountArray = [];

  let index = 0;
  for (const date of Object.keys(datesCustom).sort()) {
    if (date < periodStartDate || date > periodEndDate) {
      continue;
    }
    dateCountIndex[date] = {
      index,
      exists: true
    };
    dateCountArray.push({ count: 1, date });
    index++;
  }

  return [dateCountIndex, dateCountArray];
};

export const getSiteScheduleInfo = (
  startDate: string,
  endDate: string,
  siteSchedule: SiteSchedule | Schedule | null,
  siteSummaries: ReportIndex | null,
  ignoreQuestionnaire = false,
  isMultiSite: false
): ScheduleInfo => {
  const defaultReturnValue: ScheduleInfo = {
    nextDue: null,
    unfinishedDates: [],
    isDoneToday: false,
    draftKey: null,
    scheduledCount: 0,
    completedCount: 0
  };

  const today = getTodayString();

  const dateCountIndex: DateCountIndex = {};

  let scheduledCount = 0;
  let isScheduledToday = false;
  const scheduledDates: DateCountArray = Object.keys(siteSchedule || {})
    .filter(key => key !== 'assignedAuditor' && key >= startDate && key <= endDate)
    .sort((a, b) => (a < b ? -1 : 1))
    .map((date, i) => {
      dateCountIndex[date] = {
        exists: true,
        index: i
      };

      if (date === today) {
        isScheduledToday = true;
      }

      scheduledCount = siteSchedule[date] as number;

      defaultReturnValue.scheduledCount += scheduledCount;

      return { date, count: scheduledCount };
    });

  if (!siteSummaries) {
    defaultReturnValue.nextDue = isScheduledToday ? today : scheduledDates[0].date;
    defaultReturnValue.unfinishedDates = scheduledDates.map(({ date }) => date);

    return defaultReturnValue;
  }

  for (const reportKey in siteSummaries) {
    if (siteSummaries[reportKey].status === 'complete') {
      delete siteSummaries[reportKey];
    }
  }

  const isAdhocTeamAudit =
    isMultiSite && siteSchedule === null ? Object.keys(siteSummaries).slice(0, 1) : Object.keys(siteSummaries).sort();

  const now = moment.now();
  for (const reportKey of isAdhocTeamAudit) {
    const currentSummary = siteSummaries[reportKey];

    if (currentSummary?.status === 'draft' && !defaultReturnValue?.draftKey) {
      defaultReturnValue.draftKey = reportKey;
    }

    if (currentSummary?.status !== 'complete' || currentSummary?.isAdhoc) {
      continue;
    }

    defaultReturnValue.completedCount++;

    const [reportDate] = reportKey.split('_');

    if (
      ignoreQuestionnaire &&
      !defaultReturnValue.isDoneToday &&
      currentSummary.datetimeIn &&
      moment(currentSummary.datetimeIn).isSame(now, 'day')
    ) {
      defaultReturnValue.isDoneToday = true;
    }

    const currentDateIndex = dateCountIndex[reportDate];
    if (currentDateIndex && currentDateIndex.exists) {
      const currentIndex = currentDateIndex.index;
      scheduledDates[currentIndex].count--;
    }
  }

  for (const { date, count } of scheduledDates) {
    if (count <= 0) {
      continue;
    }

    if (date === today || !defaultReturnValue.nextDue) {
      defaultReturnValue.nextDue = date;
    }

    defaultReturnValue.unfinishedDates.push(date);
  }

  return defaultReturnValue;
};

export type DateCountMap = {
  slice: DateCountArray;
  index: DateCountIndex;
};

export type ScheduleInfo = {
  nextDue: string | null;
  unfinishedDates: string[];
  isDoneToday: boolean;
  draftKey: string | null;
  scheduledCount: number;
  completedCount: number;
  isMultiSite?: boolean;
};

const makeQuestionnaireVersionMap = (questionnaireIndex: QuestionnaireIndex): { [questKey: string]: boolean } => {
  const map: { [questKey: string]: boolean } = {};
  questionnaireIndex.versions.forEach(questKey => {
    map[questKey] = true;
  });

  return map;
};

export const getTodayString = (): string => {
  // const serverTime = firebase.database().getServerTime();
  const today = moment().format('YYYY-MM-DD');

  return today;
};

const countCompletedQuestionnaireReport = (
  mappedQuestionnaire: { [questKey: string]: boolean },
  siteSummaries: ReportIndex,
  siteScheduleData: DateCountMap & ScheduleInfo
) => {
  const now = moment().toISOString();
  for (const reportKey of Object.keys(siteSummaries || {}).sort()) {
    const summary = siteSummaries![reportKey];
    if (!summary.questionnaire || !mappedQuestionnaire[summary.questionnaire]) {
      continue;
    }

    if (summary.status === 'draft' && !siteScheduleData.draftKey) {
      siteScheduleData.draftKey = reportKey;
    }

    if (summary.status !== 'complete' || summary.isAdhoc) {
      continue;
    }

    const [reportDate] = reportKey.split('_');
    if (!moment(reportDate, 'YYYY-MM-DD').isValid()) {
      continue;
    }

    siteScheduleData.completedCount++;

    const dateCountArray = siteScheduleData.slice;
    const dateCountIndex = siteScheduleData.index;

    if (dateCountIndex[reportDate] && dateCountIndex[reportDate].exists) {
      dateCountArray[dateCountIndex[reportDate].index].count--;
    }

    if (moment(summary.datetimeIn).isSame(now, 'day')) {
      siteScheduleData.isDoneToday = true;
    }
  }
};

function dailyRecurringQuestionnaireSchedule(
  selectedQuestionnaireIndex: QuestionnaireIndex,
  siteSummaries: ReportIndex | null,
  siteScheduleData: DateCountMap & ScheduleInfo
) {
  const today = getTodayString();

  const questionnaireVersionMap: { [questionnaireKey: string]: boolean } =
    makeQuestionnaireVersionMap(selectedQuestionnaireIndex);

  if (siteSummaries) {
    countCompletedQuestionnaireReport(questionnaireVersionMap, siteSummaries, siteScheduleData);
  }

  for (const { date, count } of siteScheduleData.slice) {
    if (count <= 0) {
      continue;
    }

    if (date >= today && !siteScheduleData.nextDue) {
      siteScheduleData.nextDue = date;
    }
    siteScheduleData.unfinishedDates.push(date);
  }
}

const nonDailyQuestionnaireSchedule = (
  selectedQuestionnaireIndex: QuestionnaireIndex,
  siteSummaries: ReportIndex | null,
  siteScheduleData: DateCountMap & ScheduleInfo,
  now: number
) => {
  const questionnaireVersionMap: { [questionnaireKey: string]: boolean } =
    makeQuestionnaireVersionMap(selectedQuestionnaireIndex);

  const dateCountArray = siteScheduleData.slice;
  const dateCountIndex = siteScheduleData.index;
  if (siteSummaries) {
    countCompletedQuestionnaireReport(questionnaireVersionMap, siteSummaries, siteScheduleData);
  }

  for (const { date, count } of dateCountArray) {
    if (count <= 0 || !dateCountIndex[date]) {
      continue;
    }

    if (moment(date).isSame(now, 'day')) {
      siteScheduleData.nextDue = date;
    } else if (!siteScheduleData.nextDue) {
      siteScheduleData.nextDue = date;
    }

    siteScheduleData.unfinishedDates.push(date);
  }
};

export const getQuestionnaireScheduleInfo = (
  periodStartDate: string,
  periodEndDate: string,
  selectedQuestionnaireSchedule: QuestionnaireSchedule | null,
  selectedQuestionnaireIndex: QuestionnaireIndex | null,
  siteSummaries: ReportIndex | null
): ScheduleInfo => {
  const defaultReturnValue: ScheduleInfo = {
    nextDue: null,
    unfinishedDates: [],
    isDoneToday: false,
    draftKey: null,
    completedCount: 0,
    scheduledCount: 0
  };
  if (!selectedQuestionnaireSchedule || !selectedQuestionnaireIndex) {
    return defaultReturnValue;
  }

  let dateCountArray: DateCountArray = [];
  let dateCountIndex: DateCountIndex = {};

  const siteScheduleData: DateCountMap & ScheduleInfo = {
    nextDue: null,
    unfinishedDates: [],
    isDoneToday: false,
    draftKey: null,

    scheduledCount: 0,
    completedCount: 0,

    slice: [],
    index: {}
  };

  const now = Date.now();

  switch (selectedQuestionnaireSchedule.type) {
    case 'daily':
      [dateCountIndex, dateCountArray] = makeDailyDateCountIndex(periodStartDate, periodEndDate);
      siteScheduleData.slice = dateCountArray;
      siteScheduleData.index = dateCountIndex;

      siteScheduleData.scheduledCount = dateCountArray.reduce((sum, curr) => sum + curr.count, 0);

      // mutate schedule data
      dailyRecurringQuestionnaireSchedule(selectedQuestionnaireIndex, siteSummaries, siteScheduleData);
      break;
    case 'weekly':
      [dateCountIndex, dateCountArray] = makeWeeklyDateCountIndex(
        selectedQuestionnaireSchedule.daysOfWeek || [],
        periodStartDate,
        periodEndDate
      );
      siteScheduleData.slice = dateCountArray;
      siteScheduleData.index = dateCountIndex;

      siteScheduleData.scheduledCount = dateCountArray.reduce((sum, curr) => sum + curr.count, 0);

      // mutate schedule data
      nonDailyQuestionnaireSchedule(selectedQuestionnaireIndex, siteSummaries, siteScheduleData, now);
      break;

    case 'monthly':
      [dateCountIndex, dateCountArray] = makeMonthlyDateCountIndex(
        selectedQuestionnaireSchedule.datesOfMonth || [],
        periodStartDate,
        periodEndDate
      );
      siteScheduleData.slice = dateCountArray;
      siteScheduleData.index = dateCountIndex;

      siteScheduleData.scheduledCount = dateCountArray.reduce((sum, curr) => sum + curr.count, 0);

      // mutate schedule data
      nonDailyQuestionnaireSchedule(selectedQuestionnaireIndex, siteSummaries, siteScheduleData, now);
      break;
    case 'custom':
      [dateCountIndex, dateCountArray] = makeCustomDateCountIndex(
        selectedQuestionnaireSchedule.datesCustom || {},
        periodStartDate,
        periodEndDate
      );
      siteScheduleData.slice = dateCountArray;
      siteScheduleData.index = dateCountIndex;

      siteScheduleData.scheduledCount = dateCountArray.reduce((sum, curr) => sum + curr.count, 0);

      // mutate schedule data
      nonDailyQuestionnaireSchedule(selectedQuestionnaireIndex, siteSummaries, siteScheduleData, now);
      break;
    default:
      return defaultReturnValue;
  }

  const result: ScheduleInfo = {
    unfinishedDates: siteScheduleData.unfinishedDates,
    nextDue: siteScheduleData.nextDue,
    isDoneToday: siteScheduleData.isDoneToday,
    draftKey: siteScheduleData.draftKey,
    scheduledCount: siteScheduleData.scheduledCount,
    completedCount: siteScheduleData.completedCount
  };

  return result;
};

export const getScheduleStatusColor = (status: Partial<ScheduleStatus>, isOverdue: boolean) => {
  switch (status) {
    case ScheduleStatusEnum.DRAFT:
      return theme.colors.tertiaryYellow;
    case ScheduleStatusEnum.PENDING_UPLOAD:
      return theme.colors.secondarylightpurple;
    case ScheduleStatusEnum.FINISHED_TODAY:
    case ScheduleStatusEnum.FINISHED_PERIOD:
      return theme.colors.tertiaryGreen;
    case ScheduleStatusEnum.ADHOC:
      return theme.colors.lightBlue;
    case ScheduleStatusEnum.READY:
    default: {
      if (isOverdue) {
        return theme.colors.secondaryRed;
      }
      return theme.colors.lightgray;
    }
  }
};
