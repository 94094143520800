import React from 'react';
import { connect } from 'react-redux';
import { enums, OrganizationSchedule } from 'nimbly-common';
import { Animated, Platform, StyleSheet, View } from 'react-native';

import fontMaker from '../../utils/font';
import WelcomeTrackerProgress from './WelcomeTrackerProgress';
import WelcomeTrackerSales from './WelcomeTrackerSales';

import { RootState } from '../../store/reducers';
import { ConnectedDispatch } from '../../typing/types';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../utils/screensize';
import { ScheduledSiteInfo } from '../../store/reducers/schedulesites';

export class WelcomeSummary extends React.Component<WelcomeSummaryProps> {
  // Returns number of reports done in period
  _renderReportsCompletedThisPeriod = () => {
    const { myScheduledSites } = this.props;

    let count = 0;
    myScheduledSites.forEach(site => {
      count += site.completedCount;
    });

    return count;
  };

  // Returns number of reports due in period
  _renderReportsDueThisPeriod = () => {
    const { myScheduledSites } = this.props;

    let count = 0;

    myScheduledSites.forEach(site => {
      count += site.scheduledCount;
    });

    return count;
  };

  render() {
    if (!this.props.isFocused) {
      return <View />;
    }

    const { defaultProgressView, language, isReady, isDummy, hasNotch, featureAccessSales } = this.props;
    let completed = isReady ? this._renderReportsCompletedThisPeriod() : 0;
    let total = isReady ? this._renderReportsDueThisPeriod() || completed || 1 : 1;

    if (isDummy) {
      completed = 9;
      total = 10;
    }

    return (
      <View style={[styles.root, hasNotch ? styles.rootNotch : null]}>
        <View style={styles.container}>
          <View style={styles.progressContainer}>
            {defaultProgressView ? (
              <WelcomeTrackerProgress
                dimension={170}
                radius={175}
                width={20}
                penX={175}
                penY={175}
                completed={completed}
                total={total}
                language={language}
                isReady={isReady}
                opacity={this.props.offset}
                isFocused={this.props.isFocused}
                organizationSchedule={this.props.organizationSchedule}
              />
            ) : featureAccessSales ? (
              <WelcomeTrackerSales opacity={this.props.offset} />
            ) : null}
          </View>
        </View>
      </View>
    );
  }
}

type StateProps = {
  profile: any;
  language: string;
  isDummy: boolean;
  hasNotch: boolean;
  myScheduledSites: ScheduledSiteInfo[];
  featureAccessSales: boolean;
  organizationSchedule: OrganizationSchedule | null;
};

type OwnProps = {
  isReady: boolean;
  isFocused: boolean;
  offset: Animated.Value;
  defaultProgressView: boolean;
};

type EnhancedProps = StateProps & ConnectedDispatch;

type WelcomeSummaryProps = OwnProps & EnhancedProps;

const mapStateToProps = (state: RootState): StateProps => ({
  profile: state.firebase.profile,
  language: state.firebase.profile.language || 'en',
  isDummy: state.account.isDummy,
  hasNotch: state.account.hasNotch,
  myScheduledSites: state.scheduleSites.myScheduledSites,
  featureAccessSales: state.featureAccess.features[enums.Features.SALES_TARGET_TRACKER],
  organizationSchedule: state.organization.myOrganization?.schedule || null
});

export default connect(mapStateToProps)(WelcomeSummary);

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    alignItems: 'center',
    height: SCREEN_WIDTH >= 360 ? SCREEN_HEIGHT * 0.3 : SCREEN_HEIGHT * 0.4,
    width: '50%',
    top: -70,
    marginHorizontal: '25%',
    backgroundColor: 'transparent',
    zIndex: 100
  },
  // rootNotch: {
  //   top: 56
  // },
  container: {
    justifyContent: 'space-between',
    height: SCREEN_WIDTH >= 360 ? SCREEN_HEIGHT * 0.37 : SCREEN_HEIGHT * 0.45,
    alignItems: 'center'
  },
  progressContainer: {
    justifyContent: 'flex-end',
    height: 250
  },
  greeting: {
    ...fontMaker({ weight: 'Normal' }),
    fontSize: 18
  },
  date: {
    marginBottom: SCREEN_WIDTH >= 360 ? 0 : 50,
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 20,
    lineHeight: 24
  }
});
