import { PopulatedSiteSKU } from '../../../typing/types';

export const SKUsTutorialData: { [key: string]: PopulatedSiteSKU } = {
  '-skuTutorial': {
    skuKey: '-skuTutorial',
    createdAt: '2019-11-18T15:13:48.018Z',
    price: 0,
    stock: 0,
    thresholdType: 'number',
    updatedBy: '',
    updatedAt: '',
    siteKey: '-siteTutorial',
    createdBy: 'm2c9tcrtVBXVWQ0YpBK92tymx2r2',
    disabled: false,
    threshold: 50,
    batches: {
      '-batchTutorial': {
        // eslint-disable-next-line id-blacklist
        number: 20,
        stock: 20,
        createdAt: new Date().toISOString()
      }
    },
    skuDetail: {
      description: '',
      unit: 'pcs',
      thresholdType: 'number',
      updatedAt: '2019-11-18T15:13:48.018Z',
      sites: { '-siteTutorial': true },
      maxLimit: 0,
      disabled: false,
      tags: { good: true, milk: true },
      organizationKey: '',
      price: 0,
      inOrigin: 'onsite',
      createdBy: '',
      // eslint-disable-next-line id-blacklist
      number: 'D12345',
      updatedBy: '',
      createdAt: '2019-11-18T15:13:48.018Z',
      name: 'Baju merah',
      threshold: 50,
      batches: {
        ['-batchTutorial']: {
          batchNumber: '-batchTutorial',
          expiredAt: new Date().toISOString(),
          createdAt: new Date().toISOString()
        }
      },
      photoURLs: []
    }
  }
};
