/* eslint-disable complexity */
import firebase from 'firebase';
import update from 'immutability-helper';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  ActivityIndicator,
  BackHandler,
  Dimensions,
  NativeEventSubscription,
  ScrollView,
  StyleSheet,
  View
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { connect } from 'react-redux';
import { firebaseConnect, getVal, isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import { LobbyPageProps } from '../../routes/lobby';
import { RootState } from '../../store/reducers';
import * as questionnaireIndexActions from '../../store/reducers/questionnaireIndex/questionnaireIndex.action';
import { getPopulatedQuestionnairesAsync } from '../../store/reducers/questionnaires/questionnaire.action';
import { reportActions } from '../../store/reducers/report';
import * as reportcacheThunks from '../../store/reducers/reportcache/reportcache.actionThunk';
import { ReportSections } from '../../store/reducers/reportcache/reportcache.types';
import { toastActions } from '../../store/reducers/toast';
import { ConnectedDispatch, FirebaseConnect, InjectedSite } from '../../typing/types';
import { ReportSection, User } from '../../utils/classes';
import fontMaker from '../../utils/font';
import { compileReport, getAuditors, getCompleteSections, getIncompleteSectionIndexes } from '../../utils/multisite';
import { getTotalCompleteQuestions } from '../../utils/report';
import { SCREEN_WIDTH } from '../../utils/screensize';
import Button from '../global/Button';
import ConfirmModal, { ModalConfig } from '../global/ConfirmModal';
import Text from '../global/Text';
import LobbyMember from './LobbyMember';
import LobbyQuestionnaireSection from './LobbyQuestionnaireSection';
import { enums } from '@nimbly-technologies/nimbly-common';

const initialState: LobbyState = {
  uploadingSections: [],
  _sectionToDelete: [],
  _sectionToUpload: [],
  _isBusy: false,
  _isUploading: false,
  _isTimeout: false,
  _isCancelled: false,
  _isUploadAllReady: false,
  _isReadyToCompile: false,
  _totalAllPhotos: 0,
  _isFinalizing: false,
  _isMainReportComplete: false,
  _isNavigating: false,
  members: {},
  isMemberReady: false,
  // Modal Config
  _showModal: false,
  _modalConfig: {
    title: '',
    subtitle: '',
    // eslint-disable-next-line no-empty
    options: [{ text: { en: 'Cancel', id: 'Batal' }, action: () => null, style: 'cancel' }]
  },
  featureAccess: {}
};

class Lobby extends React.Component<LobbyProps, LobbyState> {
  state: LobbyState = { ...initialState };

  private backHandler?: NativeEventSubscription;
  private compileReadyInterval?: any;
  private uploadAllReadyInterval?: any;
  private photoPromiseArr: any[] = [];

  componentDidMount() {
    this.getMemberProfile();
    this.setUploadingState();
    this.props.dispatch(getPopulatedQuestionnairesAsync.request());
    this.props.dispatch(questionnaireIndexActions.fetchQuestionnaireIndex.request());

    this.props.navigation.setParams({ backHandler: this.handleBackPress });
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.handleBackPress);

    this.props.navigation.addListener('focus', () => this.navigationHandler('focus'));
    this.props.navigation.addListener('blur', () => this.navigationHandler('blur'));
  }

  componentDidUpdate(prevProps: LobbyProps, prevState: LobbyState) {
    const {
      isConnected,
      siteReportSections,
      selectedReportKey,
      mainReport,
      isDummy,
      uploadedReportSections,
      questionnaireIndex,
      isLoading,
      selectedSite,
      dispatch
    } = this.props;
    const { _isUploading, _isTimeout, _isReadyToCompile, _isUploadAllReady, _isMainReportComplete } = this.state;

    if (!prevState._isTimeout && _isTimeout) {
      this.handleUploadTimeout();
    }

    if (prevProps.isConnected && !isConnected && _isUploading) {
      this.handleConnectionLost();
    }

    if (!isLoaded(prevProps.uploadedReportSections) && isLoaded(uploadedReportSections)) {
      this.syncUploadedSectionWithLocal();
    }

    if (
      (!prevState._isUploadAllReady && _isUploadAllReady) ||
      ((!siteReportSections ||
        !siteReportSections[selectedReportKey] ||
        !Object.keys(siteReportSections[selectedReportKey]).length) &&
        this.uploadAllReadyInterval)
    ) {
      clearInterval(this.uploadAllReadyInterval);
      this.uploadAllReadyInterval = null;
    }
    //    if (prevState._isUploadAllReady && !_isUploadAllReady && !_isReadyToCompile) {
    //      this.uploadAllReadyInterval = setInterval(this.checkUploadAllReady, 500);
    //    }

    if (!questionnaireIndex && !isLoading) {
      dispatch(questionnaireIndexActions.fetchQuestionnaireIndex.request());
    }

    // refetch questionnaire index if site section value changed
    if (prevProps.selectedSite.children?.length !== selectedSite.children?.length) {
      dispatch(questionnaireIndexActions.fetchQuestionnaireIndex.request());
    } else {
      const prev: string[] = prevProps.selectedSite.children?.map(s => s.assignedQuestionnaire) || [];
      const current: string[] = selectedSite.children?.map(s => s.assignedQuestionnaire) || [];

      if (!isEqual(prev, current)) {
        dispatch(questionnaireIndexActions.fetchQuestionnaireIndex.request());
      }
    }

    if (
      !isDummy &&
      mainReport &&
      mainReport[selectedReportKey] &&
      mainReport[selectedReportKey].status === 'complete' &&
      !_isMainReportComplete
    ) {
      this.updateStateLobby();
      this.handleMainReportComplete();
    }
  }

  private updateStateLobby = () => {
    this.setState({ _isMainReportComplete: true });
  };

  componentWillUnmount() {
    clearTimeout(this.uploadingTimer);
    clearInterval(this.compileReadyInterval);
    clearInterval(this.uploadAllReadyInterval);
    this.props.navigation.removeListener('focus', () => null);
    this.props.navigation.removeListener('blur', () => null);
  }

  // If user's cache was empty but uploaded section exist. update cache with value from db
  private syncUploadedSectionWithLocal = async () => {
    const { uploadedReportSections, siteReportSections, auth, dispatch, selectedSiteKey, selectedReportKey } =
      this.props;
    if (uploadedReportSections) {
      const restoreCache = Object.keys(uploadedReportSections).map(async section => {
        const sectionNum = Number(section);
        const sections = uploadedReportSections[sectionNum];
        if (!sections) {
          return Promise.resolve();
        }
        const sectionDispatches = Object.keys(sections).map(uid => {
          if (
            !!siteReportSections &&
            siteReportSections[selectedReportKey] &&
            siteReportSections[selectedReportKey][sectionNum]
          ) {
            return Promise.resolve();
          }
          const userSection = sections[uid];
          if (userSection.status !== 'draft' || uid !== auth.uid || isNaN(Number(userSection.siteChild))) {
            return Promise.resolve();
          }
          return dispatch(
            reportcacheThunks.setReportSection(selectedSiteKey, selectedReportKey, userSection.siteChild, userSection)
          );
        });

        await Promise.all(sectionDispatches);
        return Promise.resolve();
      });

      await Promise.all(restoreCache);
    }
  };

  private navigationHandler = (event: string) => {
    switch (event) {
      case 'blur':
        clearInterval(this.uploadAllReadyInterval);
        clearInterval(this.compileReadyInterval);
        break;
      case 'focus':
        this.compileReadyInterval = setInterval(this.checkCompileReady, 500);
        this.uploadAllReadyInterval = setInterval(this.checkUploadAllReady, 500);
        break;
      default:
        break;
    }
  };

  /**
   * Check all required sections are ready or not.
   * Optional section will always ready
   */
  private checkCompileReady = () => {
    const { uploadedReportSections, selectedSite } = this.props;
    const { _isReadyToCompile: prevCompileState } = this.state;

    try {
      const sectionReadyStatus: boolean[] = [];
      selectedSite.children!.forEach((child, sectionIdx) => {
        const { isRequired } = selectedSite.children![sectionIdx];
        const section = uploadedReportSections ? uploadedReportSections[sectionIdx] : null;
        let isReady = !isRequired;
        if (section) {
          for (const memberId of Object.keys(section)) {
            const sectionStat = section[memberId]?.status;
            const isCompleted = sectionStat === 'complete' || sectionStat === 'approved';
            isReady = isReady || isCompleted;
            if (isReady) break;
          }
        }
        sectionReadyStatus.push(isReady);
      });
      // allow compile report when at least 1 section completed and all required sections completed
      const isCompileReady = !sectionReadyStatus.includes(false);

      if (prevCompileState !== isCompileReady) {
        this.setState({ _isReadyToCompile: isCompileReady });
      }
    } catch (error) {}
  };

  private checkUploadAllReady = () => {
    const { siteReportSections, selectedReportKey } = this.props;
    const { _isUploadAllReady: prevUploadState } = this.state;

    try {
      let isReady = false;
      if (!!siteReportSections && siteReportSections[selectedReportKey]) {
        const reportSections = siteReportSections[selectedReportKey];
        for (const sectionIdx of Object.keys(reportSections)) {
          const reportSection = reportSections[Number(sectionIdx)];
          const questions = reportSection ? reportSection.questions : [];
          if (
            reportSection?.datetimeOut &&
            getTotalCompleteQuestions(questions) >= questions.length &&
            reportSection.status !== 'rejected'
          ) {
            isReady = true;
            break;
          }
        }
      }
      if (prevUploadState !== isReady) {
        this.setState({ _isUploadAllReady: isReady });
      }
    } catch (error) {}
  };

  private getMemberProfile = async () => {
    const { selectedSite, isConnected, auth, profile } = this.props;
    const memberProfiles: { [uid: string]: User } = {
      [auth.uid]: profile
    };

    if (isConnected) {
      try {
        const memberIds = [selectedSite?.assignedAuditor, ...selectedSite.team!];
        const memberProfilesPromises = memberIds
          .filter(id => id !== auth.uid)
          .map(id => firebase.database().ref('user').child(id).once('value'));
        const memberProfilesSnaps = await Promise.all(memberProfilesPromises);
        memberProfilesSnaps.forEach(snap => {
          if (snap.key && snap.val()) {
            memberProfiles[snap.key] = snap.val();
          }
        });
      } catch (error) {}
    }
    this.setState({ members: memberProfiles, isMemberReady: true });
  };

  private handleBackPress = () => {
    const { navigation, isFocused, t } = this.props;

    if (!this.state._isUploading || !isFocused) {
      return navigation.pop();
    }

    const modalConfig: ModalConfig = {
      title: `${t('lobby:oops')}...`,
      subtitle: t('lobby:prompt.uploadingInProgress'),
      options: [
        {
          text: t('common:yes'),
          style: 'destructive',
          action: () => {
            this.confirmCancelUpload();
            this.setState({ _showModal: !this.state._showModal });
            navigation.pop();
          }
        },
        {
          text: t('common:cancel'),
          style: 'cancel',
          action: () => {
            this.setState({ _showModal: !this.state._showModal });
            navigation.navigate('Lobby');
          }
        }
      ]
    };

    this.setState({ _showModal: !this.state._showModal, _modalConfig: modalConfig });

    return true;
  };

  private handleMainReportComplete = () => {
    const { navigation, route, dispatch, selectedSiteKey, selectedReportKey, selectedSite, auth, t } = this.props;
    const isLeader = selectedSite?.assignedAuditor === auth?.uid;
    if (isLeader) {
      return;
    }
    navigation.reset({
      index: 0,
      routes: [{ name: route.params.originTab || 'HomeTab' }]
    });
    const modalConfig: ModalConfig = {
      title: t('lobby:reportCompleted'),
      subtitle: t('lobby:reportCompletedSubtitle'),
      options: [
        {
          text: 'OK',
          action: () => {
            this.clearField();
            dispatch(reportcacheThunks.removeSiteReportSections(selectedSiteKey, selectedReportKey));
            navigation.reset({
              index: 0,
              routes: [{ name: route.params.originTab || 'HomeTab' }]
            });
          },
          style: 'confirm'
        }
      ]
    };

    this.setState({ _showModal: !this.state._showModal, _modalConfig: modalConfig, _isBusy: true });
  };

  private handleConnectionLost = () => {
    const { dispatch, t } = this.props;
    const msg = t('lobby:error.connectionLost');
    dispatch(toastActions.createToast(msg, true, 5000));

    this.confirmCancelUpload();
  };

  private handleUploadTimeout = () => {
    const { dispatch, t } = this.props;
    const toastMessage = t('lobby:error.uploadTimeout');

    this.confirmCancelUpload();
    dispatch(toastActions.createToast(toastMessage, true, 0));
  };

  private confirmCancelUpload = () => {
    if (this.photoPromiseArr.length) {
      this.photoPromiseArr.forEach(prom => {
        prom.cancel();
      });
      this.photoPromiseArr = [];
      clearTimeout(this.uploadingTimer);
    }
    this.clearField();
  };

  private clearField = () => {
    this.setUploadingState();
  };

  /**
   * Prepare uploading state for each section when the component is mounted.
   * Set the uploadingSection into Array of null based on how many children the site has
   */
  private setUploadingState = () => {
    const {
      selectedSite: { children }
    } = this.props;
    this.setState({
      uploadingSections: Array(Object.keys(children!).length).fill(null)
    });
  };

  /**
   * Get report section from cache.
   */
  private getLocalReportSection = (sectionIdx: number) => {
    const { siteReportSections, selectedReportKey } = this.props;

    const reportSections = siteReportSections ? siteReportSections[selectedReportKey] : null;
    const existingReportSection = reportSections ? reportSections[sectionIdx] : null;

    return existingReportSection;
  };

  /**
   * Get the uid of report section's auditor and section status.
   * If there are multiple user sort by the latest datetimeUpdated
   */
  private getUploadedSection = (sectionIdx: number) => {
    const { uploadedReportSections } = this.props;
    const uploadedReportSection = uploadedReportSections ? uploadedReportSections[sectionIdx] : null;
    const uploadedSectionUser = uploadedReportSection
      ? Object.keys(uploadedReportSection).sort((a, b) => {
          if (uploadedReportSection[b].datetimeUpdated! < uploadedReportSection[a].datetimeUpdated!) {
            return -1;
          }
          return 0;
        })[0]
      : null;

    const uploadedSectionStatus = uploadedReportSection ? uploadedReportSection[uploadedSectionUser!].status : null;

    return { uploadedSectionUser, uploadedSectionStatus };
  };

  private alertSectionInProgress = (
    userId: string,
    sectionIdx: number,
    alias: string,
    questionnaireIndexKey: string,
    existingReportSection: ReportSection | null
  ) => {
    const { t } = this.props;

    const { members } = this.state;
    const { displayName } = members[userId];

    const modalConfig: ModalConfig = {
      title: t('common:conflict'),
      subtitle: t('lobby:prompt.sectionInProgress', { displayName }),
      options: [
        {
          text: t('common:proceed'),
          style: 'destructive',
          action: () => {
            this.setState({ _showModal: !this.state._showModal });
            return this.confirmNavQuestionnaire(sectionIdx, alias, questionnaireIndexKey, existingReportSection);
          }
        },
        {
          text: t('common:cancel'),
          style: 'cancel',
          action: () => this.setState({ _showModal: !this.state._showModal, _isBusy: false, _isNavigating: false })
        }
      ]
    };

    this.setState({ _showModal: !this.state._showModal, _modalConfig: modalConfig });
  };

  private handleNavQuestionnaire = async (sectionIdx: number, alias: string, questionnaireIndexKey: string) => {
    const { auth, profile, selectedSiteKey, selectedReportKey } = this.props;

    try {
      await this.setState({ _isBusy: true, _isNavigating: true });
      const { uploadedSectionUser, uploadedSectionStatus } = this.getUploadedSection(sectionIdx);
      const localReportSection = this.getLocalReportSection(sectionIdx);

      if (uploadedSectionStatus === 'draft' && uploadedSectionUser && uploadedSectionUser !== auth.uid) {
        return this.alertSectionInProgress(
          uploadedSectionUser,
          sectionIdx,
          alias,
          questionnaireIndexKey,
          localReportSection
        );
      }

      return this.confirmNavQuestionnaire(sectionIdx, alias, questionnaireIndexKey, localReportSection);
    } catch (error) {
      console.log(error);
      console.log(error.message);
    }
  };

  private confirmNavQuestionnaire = async (
    sectionIdx: number,
    alias: string,
    questionnaireIndexKey: string,
    localReportSection: ReportSection | null
  ) => {
    const {
      auth,
      selectedSite,
      dispatch,
      selectedSiteKey,
      selectedReportKey,
      profile,
      questionnaireIndex,
      uploadedReportSections,
      report,
      reportSummary
    } = this.props;

    try {
      const now = moment().toISOString(true);
      const selectedQuestionnaireIndex = questionnaireIndex?.[questionnaireIndexKey];
      const selectedQuestionnaire = selectedQuestionnaireIndex?.populated?.latest;

      const newReportSection = new ReportSection(
        selectedQuestionnaire?.questions || [],
        selectedQuestionnaireIndex?.latest || '',
        sectionIdx,
        auth.uid,
        now,
        '',
        'draft',
        selectedSite.children![sectionIdx].isRequired,
        now,
        ''
      );

      const uploadedSection =
        !!uploadedReportSections && uploadedReportSections[sectionIdx] && uploadedReportSections[sectionIdx]![auth.uid];

      let latestUpdated: ReportSection | null = uploadedSection || localReportSection;

      // if both exists look for last updated
      if (uploadedSection && localReportSection) {
        if (uploadedSection.datetimeUpdated > localReportSection.datetimeUpdated) {
          latestUpdated = uploadedSection;
        } else {
          latestUpdated = localReportSection;
        }
      }

      report.questionnaire = selectedQuestionnaireIndex?.latest;
      reportSummary.questionnaire = selectedQuestionnaireIndex?.latest;

      const selectedReportSection = latestUpdated || newReportSection;

      await dispatch(reportcacheThunks.createOrUpdateReport(selectedSiteKey, selectedReportKey, report, reportSummary));

      dispatch(reportActions.selectReport(selectedReportKey, selectedReportSection));
      dispatch(
        reportcacheThunks.setReportSection(selectedSiteKey, selectedReportKey, sectionIdx, selectedReportSection)
      );

      const ref = `/reportSection/${profile.organization}/${selectedSiteKey}/${selectedReportKey}/${sectionIdx}/${auth.uid}`;

      firebase.database().ref(ref).update(selectedReportSection);

      await this.setState({ _isBusy: false, _isNavigating: false });
      this.props.navigation.navigate('Questionnaire', {
        profileColor: this.props.profileColor,
        originTab: this.props.route.params.originTab || 'HomeTab',
        siteName: alias
      });
    } catch (error) {
      console.log(error);
      console.log(error.message);

      throw error;
    }
  };

  private _renderTeamMembers = () => {
    const { selectedSite, isConnected, profileColor } = this.props;
    const { members } = this.state;

    const auditors = getAuditors(selectedSite);

    return auditors.map((uid, i) => {
      const name = members[uid] ? members[uid].displayName : '';
      const teamLead = uid === selectedSite?.assignedAuditor;
      const image = !!members && members[uid] && members[uid]?.photoURL ? { uri: members[uid]?.photoURL } : null;

      return (
        <LobbyMember
          key={i}
          name={name}
          isLeader={teamLead}
          imageSrc={image}
          isConnected={isConnected}
          profileColor={profileColor}
        />
      );
    });
  };

  private uploadReportSections = async (reportSections: ReportSections) => {
    const {
      auth: { uid },
      selectedSiteKey,
      organizationKey: organization,
      selectedReportKey,
      dispatch
    } = this.props;

    try {
      const now = moment().toISOString(true);
      this.setState({ _isTimeout: false, _isCancelled: false });

      const reportSectionIndexes = Object.keys(reportSections);
      for (const sectionIdx of reportSectionIndexes) {
        const sectionNumber = Number(sectionIdx);
        await this.setState({
          uploadingSections: update(this.state.uploadingSections, {
            [sectionNumber]: {
              $set: {
                total: 0,
                uploadedCount: 0,
                isFinished: false,
                uploadedPhotos: [],
                uploadedConditionalPhotos: {}
              }
            }
          })
        });
      }

      if (!selectedReportKey) {
        throw new Error('Report key not found');
      }

      const draftReportsUpdates = reportSectionIndexes.map(async sectionIdx => {
        const sectionNumber = Number(sectionIdx);

        // Don't proceed completing the section if there is already an uploaded/approved version
        if (this.hasAlreadyUploadedVersion(sectionNumber)) return;
        // eslint-disable-next-line max-len
        const reportSectionRef = `/reportSection/${organization}/${selectedSiteKey}/${selectedReportKey}/${sectionIdx}/${uid}`;

        const reportSection = reportSections[sectionNumber];

        const updatedReportSection = update(reportSection, {
          datetimeSubmitted: { $set: now },
          datetimeUpdated: { $set: now },
          status: { $set: 'complete' }
        });

        let callback: { resolve: Function; reject: Function } = { resolve: Promise, reject: Promise };
        const uploadReportPromise = new Promise((resolve, reject) => {
          callback.resolve = resolve;
          callback.reject = reject;
        });

        await firebase.database().ref(reportSectionRef).update(updatedReportSection);

        // Delete local when uploaded
        return dispatch(reportcacheThunks.removeReportSection(selectedSiteKey, selectedReportKey, sectionNumber));
      });

      return Promise.all(draftReportsUpdates);
    } catch (error) {
      throw error;
    }
  };

  private alertOffline = () => {
    const { t } = this.props;
    const modalConfig: ModalConfig = {
      title: t('common:warning'),
      subtitle: t('lobby:error.offlineAlert'),
      options: [
        {
          text: 'OK',
          action: () => this.clearField(),
          style: 'cancel'
        }
      ]
    };

    this.setState({ _showModal: !this.state._showModal, _modalConfig: modalConfig });
  };

  private handleUploadAll = () => {
    const {
      siteReportSections,
      uploadedReportSections,
      selectedReportKey,
      isConnected,
      auth,
      profile,
      selectedSiteKey,
      t
    } = this.props;
    const { _isFinalizing } = this.state;

    try {
      const cacheEmpty =
        !siteReportSections ||
        !siteReportSections[selectedReportKey] ||
        Object.keys(siteReportSections[selectedReportKey]).length === 0;

      if (cacheEmpty && _isFinalizing) {
        const finalizeModalConfig: ModalConfig = {
          title: t('lobby:finalizeReport'),
          subtitle: t('lobby:prompt.unfinishedReport'),
          options: [
            {
              text: t('common:proceed'),
              action: () => {
                this.setState({ _showModal: !this.state._showModal }, () => {
                  this.finalizeReport();
                });
              },
              style: 'confirm'
            },
            {
              text: t('common:cancel'),
              action: () => this.setState({ _showModal: !this.state._showModal }),
              style: 'cancel'
            }
          ]
        };
        return this.setState({
          _showModal: !this.state._showModal,
          _modalConfig: finalizeModalConfig
        });
      } else if (cacheEmpty) {
        throw new Error('Local report section empty');
      } else if (!isConnected) {
        return this.alertOffline();
      }

      const reportSections = siteReportSections![selectedReportKey];
      const completeSections = getCompleteSections(reportSections);
      const incompleteReport = getIncompleteSectionIndexes(reportSections);

      const uploadModalConfig: ModalConfig = {
        title: t('lobby:uploadReport'),
        subtitle: t('lobby:prompt.uploadWarning'),
        options: [
          {
            text: t('common:proceed'),
            action: () => {
              firebase.analytics().logEvent('report_attempt_upload');
              this.setState({ _sectionToDelete: incompleteReport, _showModal: !this.state._showModal }, () => {
                this.confirmUploadAll(completeSections);
              });
            },
            style: 'confirm'
          },
          {
            text: t('common:cancel'),
            action: () => this.setState({ _showModal: !this.state._showModal }),
            style: 'cancel'
          }
        ]
      };
      this.setState({
        _showModal: !this.state._showModal,
        _modalConfig: uploadModalConfig
      });
    } catch (error) {}
  };

  private hasAlreadyUploadedVersion = (sectionIdx: number) => {
    const { uploadedReportSections } = this.props;
    const sectionVersions = uploadedReportSections[sectionIdx];

    for (const key of Object.keys(sectionVersions)) {
      const version = sectionVersions[key];
      if (['complete', 'approved'].includes(version.status)) return true;
    }

    return false;
  };

  private confirmUploadAll = async (reportSections: ReportSections) => {
    const { dispatch, t } = this.props;
    const { _isFinalizing } = this.state;
    try {
      await this.uploadReportSections(reportSections);
    } catch (error) {
      const toastMessage = t('lobby:uploadFailed');
      dispatch(toastActions.createToast(toastMessage, true, 0));

      this.confirmCancelUpload();
    }
    if (!_isFinalizing) {
      // To prevent clearing field before async storage process finished
      setTimeout(this.clearField, 1000);
      return;
    }
    this.finalizeReport();
  };

  private confirmUploadSingleSection = async (sectionIdx: number) => {
    const { siteReportSections, selectedReportKey } = this.props;
    try {
      if (!siteReportSections) {
        throw new Error('Report section empty');
      }
      const sectionToUpload: ReportSections = {
        [sectionIdx]: siteReportSections[selectedReportKey][sectionIdx]
      };
      await this.uploadReportSections(sectionToUpload);
    } catch (error) {
      // eslint-disable-next-line no-console
    }
    this.clearField();
  };

  private handleToggleModal = (action: 'delete' | 'upload' | 'cancel', sectionIdx?: number) => {
    const { _sectionToDelete, _sectionToUpload } = this.state;
    const { siteReportSections, selectedReportKey, isConnected, auth, profile, selectedSiteKey, t } = this.props;
    const sectionOption = {
      _sectionToDelete:
        action === 'delete' && sectionIdx !== undefined ? [..._sectionToDelete, sectionIdx] : [..._sectionToDelete],
      _sectionToUpload:
        action === 'upload' && sectionIdx !== undefined ? [..._sectionToUpload, sectionIdx] : [..._sectionToUpload]
    };

    const uploadModalConfig: ModalConfig = {
      title: t('lobby:deleteSection'),
      subtitle: t('lobby:prompt.deleteWarning'),
      options: [
        {
          text: t('common:proceed'),
          action: () => {
            this.confirmDeleteSection();
          },
          style: 'destructive'
        },
        {
          text: t('common:cancel'),
          action: () => this.handleToggleModal('cancel'),
          style: 'cancel'
        }
      ]
    };

    this.setState({
      _showModal: !this.state._showModal,
      _modalConfig: uploadModalConfig,
      ...sectionOption
    });
  };

  private confirmDeleteSection = async () => {
    const { dispatch, selectedSiteKey, selectedReportKey, auth, profile } = this.props;
    const { _sectionToDelete: sectionIndexes } = this.state;
    this.setState({ _isBusy: true });
    try {
      if (sectionIndexes.length) {
        sectionIndexes.map(sectionIdx => {
          const ref = `/reportSection/${profile.organization}/${selectedSiteKey}/${selectedReportKey}/${sectionIdx}/${auth.uid}`;
          firebase.database().ref(ref).remove();

          dispatch(reportcacheThunks.removeReportSection(selectedSiteKey, selectedReportKey, sectionIdx));
          return null;
        });
      }
    } catch (error) {}
    this.setState({ _sectionToDelete: [], _showModal: false, _isBusy: false });
  };

  private handleFinalizeReport = async () => {
    await this.setState({ _isFinalizing: true });
    this.handleUploadAll();
  };

  private finalizeReport = async () => {
    const {
      uploadedReportSections,
      report,
      reportSummary,
      dispatch,
      selectedReportKey,
      selectedSiteKey,
      selectedSite
    } = this.props;

    try {
      const final = compileReport(report!, reportSummary!, uploadedReportSections);
      await Promise.all([
        dispatch(reportActions.selectReport(selectedReportKey, final.report)),
        dispatch(reportcacheThunks.updateCacheReport(selectedSiteKey, selectedReportKey, final.report)),
        dispatch(reportcacheThunks.updateCacheReportSummary(selectedSiteKey, selectedReportKey, final.reportSummary)),
        dispatch(reportcacheThunks.removeSiteReportSections(selectedSiteKey, selectedReportKey))
      ]);

      const reportRef = `/report/${this.props.profile.organization}/${selectedSiteKey}/${selectedReportKey}`;
      const reportSummaryRef = `/reportIndex/${this.props.profile.organization}/${selectedSiteKey}/${selectedReportKey}`;

      this.props.firebase.update(reportRef, final.report);
      this.props.firebase.update(reportSummaryRef, final.reportSummary);

      this.props.navigation.navigate('SubmitReport', {
        profileColor: this.props.profileColor,
        originTab: this.props.route.params.originTab || 'HomeTab',
        siteName: selectedSite.name,
        members: this.state.members
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.clearField();
    }
  };

  render() {
    const { language, selectedSite, uploadedReportSections, auth, isConnected, profileColor, t, isLoading } =
      this.props;
    const { isMemberReady, _isReadyToCompile, _isUploadAllReady, _isBusy, _isUploading, _isNavigating } = this.state;

    const isLeader = selectedSite?.assignedAuditor === auth.uid;

    if (!isMemberReady && isLoading) {
      return (
        <View style={styles.loading}>
          <ActivityIndicator color={profileColor} size="large" />
          <Text>{t('common:loading')}...</Text>
        </View>
      );
    }
    return (
      <View style={styles.root}>
        <ConfirmModal
          isVisible={this.state._showModal}
          language={language}
          title={this.state._modalConfig.title}
          subtitle={this.state._modalConfig.subtitle}
          options={this.state._modalConfig.options}
          onCloseModal={() => this.handleToggleModal('cancel')}
          onConfirmDelete={this.confirmDeleteSection}
        />
        <ScrollView style={styles.mainScroll} contentContainerStyle={styles.body}>
          <View style={styles.memberContainer}>
            <View style={[styles.headerJustify, styles.headerSpacing, styles.headerBottomBorder]}>
              <Text style={styles.headerText}>{t('common:teamMembers')}</Text>
              <Icon name="account-group" color="#1b2133" size={22} />
            </View>
            <ScrollView
              horizontal={true}
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={styles.horizontalScrollContent}
              style={styles.horizontalScroll}
            >
              {this._renderTeamMembers()}
            </ScrollView>
          </View>
          <View style={styles.sectionContainer}>
            {(!isLoaded(uploadedReportSections) && isConnected) || isLoading ? (
              <View style={styles.loadingQuestionnaire}>
                <ActivityIndicator color={profileColor} size="large" />
                <Text>{t('common:loading')}...</Text>
              </View>
            ) : _isNavigating ? (
              <ActivityIndicator color={profileColor} size="large" />
            ) : (
              <LobbyQuestionnaireSection
                team={this.state.members}
                onNavQuestionnaire={this.handleNavQuestionnaire}
                onDeleteSection={this.handleToggleModal}
                isUploading={this.state._isUploading}
                isBusy={this.state._isBusy}
                uploadingSections={this.state.uploadingSections}
              />
            )}
          </View>
        </ScrollView>
        <View style={styles.footer}>
          <Button
            disabled={(isLeader ? !_isReadyToCompile && !_isUploadAllReady : !_isUploadAllReady) || _isBusy}
            style={styles.button}
            title={
              _isUploading
                ? `${t('common:uploading')}...`
                : _isReadyToCompile && isLeader
                ? t('lobby:finalizeReport')
                : t('common:upload')
            }
            onPress={_isReadyToCompile && isLeader ? this.handleFinalizeReport : this.handleUploadAll}
          />
        </View>
      </View>
    );
  }
}

interface UploadSection {
  total: number;
  uploadedCount: number;
  isFinished: boolean;
  uploadedPhotos: string[][];
  uploadedConditionalPhotos: { [key: string]: any[] };
}

export type UploadingSections = (UploadSection | null)[];

interface LobbyState {
  uploadingSections: UploadingSections;
  _showModal: boolean;
  _modalConfig: ModalConfig;
  _sectionToDelete: number[];
  _sectionToUpload: number[];
  _isUploading: boolean;
  _isBusy: boolean;
  _isNavigating: boolean;
  _isTimeout: boolean;
  _isCancelled: boolean;
  _totalAllPhotos: number;
  _isUploadAllReady: boolean;
  _isReadyToCompile: boolean;
  _isFinalizing: boolean;
  _isMainReportComplete: boolean;
  members: { [uid: string]: User };
  isMemberReady: boolean;
  featureAccess: any;
}

interface OwnProps {
  isFocused: boolean;
  t: any;
}

type EnhancedProps = FirebaseProps & StateProps & ConnectedDispatch & FirebaseConnect;

type LobbyProps = OwnProps & LobbyPageProps & EnhancedProps;

export interface UploadedSiteReportSections {
  [section: number]: { [uid: string]: ReportSection } | null;
}

type FirebaseProps = ReturnType<typeof mapStateToFirebase>;
type StateProps = ReturnType<typeof mapStateToProps>;

const mapStateToFirebase = (state: RootState) => ({
  profile: state.firebase.profile,
  selectedSiteKey: state.site.selectedSiteKey as string,
  selectedReportKey: state.report.selectedReportKey as string
});

const mapFirebaseToState = (props: FirebaseProps) => {
  const draftReportsQuery = [
    {
      path: `/reportSection/${props.profile.organization}/${props.selectedSiteKey}/${props.selectedReportKey}`,
      storeAs: `lobbyReportSection/${props.selectedSiteKey}/${props.selectedReportKey}`
    }
  ];
  const allQuery = [...draftReportsQuery];
  return allQuery;
};

const mapStateToProps = (state: RootState) => ({
  auth: state.firebase.auth,
  selectedSite: state.site.selectedSite as InjectedSite,
  selectedReport: state.report.selectedReport as ReportSection,
  siteReportSections: state.reportcache.multiReports[state.site.selectedSiteKey!],
  isConnected: state.network.isConnected,
  language: state.firebase.profile.language || 'en',
  uploadedReportSections: getVal(
    state.firebase.data,
    `lobbyReportSection/${state.site.selectedSiteKey}/${state.report.selectedReportKey}`
  ),
  mainReport: state.sitedata[state.site.selectedSiteKey!] && state.sitedata[state.site.selectedSiteKey!].report,
  organizationKey: state.firebase.profile.organization,
  report: state.reportcache.reports[state.site.selectedSiteKey!]
    ? state.reportcache.reports[state.site.selectedSiteKey!][state.report.selectedReportKey!]
    : null,
  reportSummary:
    state.reportcache.summaries[state.site.selectedSiteKey!] && state.report.selectedReportKey
      ? state.reportcache.summaries[state.site.selectedSiteKey!][state.report.selectedReportKey!]
      : null,
  questionnaireIndex: state.questionnaireIndex.questionnaireIndex,
  isLoading: state.questionnaireIndex.isLoading,
  questionnaireIndexState: state.questionnaireIndex,
  isDummy: state.account.isDummy,
  profileColor: state.profiletheme.color,
  featureAccessIRFSAT: state.featureAccess.features[enums.Features.IRF_SAT]
});

function mapDispatchToProps(dispatch: any) {
  return {
    submitApiReport: (payload: any) => dispatch(submitApiReportAsync.request(payload))
  };
}

const enhance = compose(
  connect(mapStateToFirebase),
  firebaseConnect(mapFirebaseToState),
  connect(mapStateToProps),
  withTranslation(['lobby', 'common'])
);

export default enhance(Lobby) as any;

const { width, height } = Dimensions.get('window');

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadingQuestionnaire: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 25
  },
  root: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH
  },
  mainScroll: {
    flex: 1,
    overflow: 'visible'
  },
  body: {
    flexGrow: 1,
    paddingBottom: 16,
    alignItems: 'center',
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH
  },
  // Horizontal Style Card Member
  memberContainer: {
    // marginTop: 16,
    width: '100%',
    minWidth: '100%',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.25,
    shadowRadius: 6
  },
  headerJustify: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#1b2133',
    backgroundColor: '#fff',
    elevation: 6
  },
  headerSpacing: {
    paddingHorizontal: 20,
    paddingVertical: 7
  },
  headerBottomBorder: {
    borderBottomColor: '#d1d1d1',
    borderBottomWidth: 1
  },
  headerText: {
    ...fontMaker({ weight: 'Normal' })
  },
  horizontalScroll: {
    flexDirection: 'row',
    backgroundColor: '#f2f2f2',
    paddingHorizontal: 8,
    paddingBottom: 4,
    elevation: 6
  },
  horizontalScrollContent: {
    paddingRight: 16,
    minHeight: height * 0.17
  },
  // Questionnaire Section
  sectionContainer: {
    marginTop: 16,
    alignItems: 'center'
  },
  sectionHeader: {
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 15,
    color: '#a8a8a8',
    marginVertical: 16
  },
  footer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 64,
    height: Dimensions.get('window').height * 0.1,
    paddingVertical: 10,
    backgroundColor: '#fff',

    shadowColor: '#919191',
    shadowRadius: 6,
    shadowOpacity: 0.7,
    shadowOffset: { width: 1, height: 1 },
    elevation: 3
  },
  button: {
    width: SCREEN_WIDTH > 500 ? 568 : SCREEN_WIDTH
  }
});
