import React from 'react';
import { Text, StyleProp, TextStyle, TextProps, StyleSheet } from 'react-native';

import fontMaker from '../../utils/font';
import theme from '../../constants/theme';

export interface AppTextProps {
  style?: StyleProp<TextStyle>;
  children?: any;
}

const AppText: React.FunctionComponent<AppTextProps & TextProps> = props => {
  const textStyles: any = [styles.default];
  if (props.style) {
    textStyles.push(props.style);
  }

  return (
    <Text {...props} style={textStyles}>
      {props.children}
    </Text>
  );
};

export default AppText;

const styles = StyleSheet.create({
  default: {
    ...fontMaker({ weight: 'Regular' }),
    color: theme.styles.textDark.color,
    lineHeight: 18.4
  }
});
