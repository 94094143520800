import React, { useState, useMemo, useEffect } from 'react';
import firebase from 'firebase';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Platform, Linking } from 'react-native';
import { useTranslation } from 'react-i18next';

// types
import { OutletProps } from './type';
import { RootState } from '../../../../store/reducers';
import { useScheduleDateReportCount } from './useScheduleDateReportCount';
// utils
import { getTodayString } from '../../utils/schedule';

// components
import OutletContainer from './OutletContainer';

const Outlet = (props: OutletProps) => {
  const { t } = useTranslation('common');
  const {
    isLoadingSchedule,
    scheduleOptions,
    selectedSite,
    siteSchedule,
    featureAccessGeo,
    reportCompleted,
    reportScheduled,
    selectedSchedule,
    getSingleSiteSchedule,
    handleSelectSchedule,
    onCalibrateLocation,
    setShowFooter
  } = props;

  // redux state
  const isDummy = useSelector<RootState, boolean>(state => state.account.isDummy);

  //state
  const [searchFilter, setSearchFilter] = useState('');

  const refreshPage = () => {
    getSingleSiteSchedule();
  };

  const { countReportToday, countReportYesterday, scheduleDateString } = useScheduleDateReportCount({
    isDummy,
    isMultiSite: Boolean(selectedSite?.isMultiSite),
    schedules: scheduleOptions,
    isLoadingSchedule
  });

  const handleShowMap = () => {
    if (selectedSite?.coordinates) {
      firebase.analytics().logEvent('click_map', { category: 'site_detail_page', siteName: selectedSite?.name });

      const { latitude, longitude } = selectedSite?.coordinates;

      const scheme = Platform.OS === 'ios' ? 'maps:' : 'geo:';
      Linking.openURL(`${scheme + latitude},${longitude}?q=${latitude},${longitude}(${selectedSite?.name})`);
    }
  };

  useEffect(() => {
    firebase.analytics().logEvent('site_outlet');
  }, []);

  return (
    <OutletContainer
      {...props}
      countReportToday={countReportToday}
      featureAccessGeo={featureAccessGeo || false}
      scheduleIsLoading={siteSchedule?.isLoading}
      selectedSite={selectedSite}
      selectedSchedule={selectedSchedule}
      reportCompleted={reportCompleted}
      reportScheduled={reportScheduled}
      scheduleOptions={scheduleOptions}
      searchFilter={searchFilter}
      scheduleDateString={scheduleDateString}
      handleShowMap={handleShowMap}
      handleSelectSchedule={handleSelectSchedule}
      refreshPage={refreshPage}
      onCalibrateLocation={onCalibrateLocation}
      setShowFooter={setShowFooter}
      setSearchFilter={setSearchFilter}
      countReportYesterDay={countReportYesterday}
    />
  );
};

export default Outlet;
