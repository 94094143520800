import { RouteProp } from '@react-navigation/native';

import { ReportSectionIndex, User, EmailTarget } from '../../../utils/classes';
import { HomeStackParamList, ScheduleStackParamList } from '../../../routes';

/**
 * Retrieve emails from the team audit members
 * @param auth
 * @param sections
 * @param navigation
 */
export default function retrieveMemberEmails(
  auth: any,
  sections: ReportSectionIndex[],
  route: RouteProp<HomeStackParamList | ScheduleStackParamList, 'SubmitReport'>
) {
  const members: { [uid: string]: User } = route.params ? route.params.members! : {};
  if (!members || !sections) {
    return [];
  }
  const memberEmailTargets: EmailTarget[] = [];
  const memberEmailTargetsMap: { [uid: string]: boolean } = {};
  sections.forEach(({ doneBy }) => {
    if (!memberEmailTargetsMap[doneBy] && doneBy !== auth.uid) {
      memberEmailTargets.push({
        email: members[doneBy].email,
        enabled: true,
        status: 'unsent',
        setByAdmin: false
      });
      memberEmailTargetsMap[doneBy] = true;
    }
  });

  return memberEmailTargets;
}
