import firebase from 'firebase';
import { appversion } from '../constants/appversion';
import getDeviceProfile from '../helpers/getDeviceProfile';
import moment from 'moment';

export const errorLogger = async (
  uid: string,
  organization: string,
  site: string,
  reportKey: string,
  error: Error,
  component: string
) => {
  // try {
  //   const deviceProfile = await getDeviceProfile();
  //   delete deviceProfile.hasNotch;
  //   const err = {
  //     appversion,
  //     component,
  //     site,
  //     reportKey,
  //     errorDetails: error,
  //     errorStack: error.stack || 'N/A',
  //     errorMessage: error.message || 'N/A',
  //     createdBy: uid,
  //     createdByOrganization: organization,
  //     createdAt: moment(),
  //     deviceInfo: deviceProfile
  //   };
  //   const env = process.env.NODE_ENV;
  //   if (env !== 'development') {
  //     const ref = '/errorlog';
  //     // firebase.database().ref(ref).push(err);
  //   } else {
  //     throw error;
  //   }
  // } catch (err) {
  //   throw err;
  // }
};
