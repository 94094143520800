import React from 'react';

import { Modal, Platform, StyleSheet, TouchableNativeFeedback, TouchableOpacity, View, ScrollView } from 'react-native';
import theme from '../../constants/theme';
import Text from './Text';
import { SCREEN_WIDTH } from '../../utils/screensize';

export type Option = {
  label: string;
  value: string;
};

type ModalInputProps = {
  title: string;
  visible: boolean;
  submitText: string;
  onSubmit: () => void;
  onClose: () => void;
  children: React.ReactNode;
};

const ModalInput = (props: ModalInputProps) => {
  const { title, visible, submitText, children, onSubmit, onClose } = props;

  return (
    <>
      <Modal animationType="fade" transparent={true} visible={visible} onRequestClose={onClose}>
        <TouchableOpacity style={styles.container} activeOpacity={1} onPress={onClose}>
          <View style={styles.modalContainer}>
            <View style={styles.modalView}>
              <View style={styles.modalHeading}>
                <Text>{title}</Text>
              </View>
              <View>{children}</View>
              <Touchable onPress={onSubmit} style={styles.row}>
                <View style={[styles.tabRow, styles.tabRowCenter]}>
                  <Text style={theme.styles.textPrimary}>{submitText}</Text>
                </View>
              </Touchable>
            </View>
          </View>
        </TouchableOpacity>
      </Modal>
    </>
  );
};

export default ModalInput;

const Touchable: any = Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

const width = SCREEN_WIDTH;

const styles = StyleSheet.create({
  row: {},
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  modalView: {
    alignItems: 'center',
    backgroundColor: '#fff',
    width: '85%',
    borderRadius: 5
  },
  modalHeading: {
    width: '100%',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 10,
    backgroundColor: 'rgba(27, 33, 51, 0.1)'
  },
  tabRow: {
    width: width - 100,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 15,
    paddingHorizontal: 10,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: 'rgba(27, 33, 51, 0.3)'
  },
  tabRowCenter: {
    justifyContent: 'center'
  },
  rowContainer: {
    maxHeight: 300
  },
  selectBox: {
    borderRadius: 4,
    backgroundColor: theme.colors.light,
    width: '100%',
    height: 40,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 20,
    marginBottom: 10
  },
  container: {
    flex: 1,
    zIndex: 1
  }
});
