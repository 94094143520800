import { Task } from 'redux-saga';
import { cancel, put, takeLatest, takeLeading } from 'redux-saga/effects';

import {
  getUserSingleSiteSchedule,
  SiteScheduleInfo,
  UserSingleSiteSchedulePayload
} from '../../../utils/siteSchedule';
import * as actions from './siteSchedule.action';
import * as types from './siteSchedule.actionTypes';

function* fetchUserSingleSiteSchedule(action: ReturnType<typeof actions.fetchSingleSiteScheduleRequest>): Generator {
  const { siteKey, startDate, endDate } = action.payload;
  yield put(actions.setIsLoading(siteKey, true));
  try {
    const payload: UserSingleSiteSchedulePayload = {
      siteID: siteKey,
      startDate,
      endDate
    };
    // @ts-ignore
    const siteScheduleInfo: SiteScheduleInfo[] = yield getUserSingleSiteSchedule(payload);
    yield put(actions.setSingleSiteScheduleData(siteKey, siteScheduleInfo));
  } catch (error) {}
  yield put(actions.setIsLoading(siteKey, false));
}

function* cancelWorkerSaga(task: Task) {
  yield cancel(task);
}

export default function* siteScheduleSaga() {
  const fetchSingleSiteSaga: Task = yield takeLatest(
    types.FETCH_SINGLE_SITE_SCHEDULE_REQUEST,
    fetchUserSingleSiteSchedule
  );
  yield takeLeading(types.CANCEL, cancelWorkerSaga, fetchSingleSiteSaga);
}
