import React from 'react';
import { compose } from 'redux';
import { connect, InferableComponentEnhancerWithProps } from 'react-redux';
import firebase from 'firebase';
import { firebaseConnect, isLoaded } from 'react-redux-firebase';

import {
  Animated,
  Platform,
  StyleSheet,
  TouchableOpacity,
  TouchableNativeFeedback,
  TouchableWithoutFeedback,
  TouchableWithoutFeedbackProps,
  View,
  ActivityIndicator
} from 'react-native';
import Text from '../global/Text';

import moment from 'moment';

import { SCREEN_WIDTH, SCREEN_HEIGHT } from '../../utils/screensize';
import { round, numberWithCommas } from '../../utils/number';
import fontMaker from '../../utils/font';
import theme from '../../constants/theme';

import { RootState } from '../../store/reducers';
import { ConnectedDispatch } from '../../typing/types';

// TODO: list possible color sequences on reach full progress
const barColorSequence = [theme.colors.primary, theme.colors.primaryDark];

class WelcomeTrackerSales extends React.Component<WelcomeTrackerSalesProps, WelcomeTrackerSalesState> {
  constructor(props: WelcomeTrackerSalesProps) {
    super(props);
    this.state = {
      _isLoaded: false,
      _order: 0,
      completed: 0,
      salesTargetToDate: 0,
      salesTargetTotal: 0,
      viewType: 'today',
      daysLeft: moment().endOf('month').diff(moment(), 'days'),
      // Bug: Android scaleX 0 value glitches out. Set to 0.001 temporarily.
      animation: new Animated.Value(0.0001),
      progress: 0,
      repetition: 0
    };
  }

  static getDerivedStateFromProps(props: WelcomeTrackerSalesProps, state: WelcomeTrackerSalesState) {
    const month = moment().format('YYYY-MM');
    if (
      !state._isLoaded &&
      isLoaded(props.mySites) &&
      isLoaded(props.salesTargets) &&
      isLoaded(props.salesFiguresCumulative)
    ) {
      let completed = 0;
      let cumulativeSales = 0;

      if (props.salesFiguresCumulative) {
        for (const { siteKey } of props.mySites) {
          if (!props.salesFiguresCumulative[siteKey]) {
            continue;
          }

          completed += props.salesFiguresCumulative[siteKey][month] || 0;
        }
      }
      if (props.salesTargets) {
        for (const { siteKey } of props.mySites) {
          if (!props.salesTargets[siteKey]) {
            continue;
          }

          cumulativeSales += props.salesTargets[siteKey][month] || 0;
        }
      }

      const salesTargetTotal = cumulativeSales;
      // Recalculate total based on daysToDate with total daysInMonth
      const daysInMonth = moment().daysInMonth();
      const daysToDate = daysInMonth - state.daysLeft;
      const salesTargetToDate = Math.round(salesTargetTotal * (daysToDate / daysInMonth));
      const denominator = state.viewType === 'today' ? salesTargetToDate : salesTargetTotal;
      return {
        _isLoaded: true,
        completed,
        salesTargetToDate,
        salesTargetTotal,
        progress: denominator === 0 ? 'N/A' : Math.max((completed * 1.0) / denominator, 0),
        repetition: denominator === 0 ? 0 : Math.ceil(completed / Math.max(denominator, 1))
      };
    }
    return null;
  }

  componentDidMount() {
    if (this.state._isLoaded) {
      const fullBar = 1;
      const overlapProgress = this.state.progress !== 'N/A' ? this.state.progress % fullBar : 1;
      const count = this.state.repetition - 1;

      this.animateProgress(fullBar, overlapProgress, count);
    }
  }

  componentDidUpdate(prevProps: WelcomeTrackerSalesProps, prevState: WelcomeTrackerSalesState) {
    if (this.state._isLoaded && !prevState._isLoaded) {
      const fullBar = 1;
      const overlapProgress = this.state.progress !== 'N/A' ? this.state.progress % fullBar : 1;
      const count = this.state.repetition - 1;

      this.animateProgress(fullBar, overlapProgress, count);
    }

    if (this.state.viewType !== prevState.viewType) {
      const { completed, salesTargetToDate, salesTargetTotal } = this.state;

      const denominator = this.state.viewType === 'today' ? salesTargetToDate : salesTargetTotal;
      const progress = denominator === 0 ? 'N/A' : Math.max((completed * 1.0) / denominator, 0);
      const repetition = denominator === 0 ? 0 : Math.ceil(completed / Math.max(denominator, 1));

      const fullBar = 1;
      const overlapProgress = progress !== 'N/A' ? progress % fullBar : 1;
      const count = repetition - 1;

      this.animateProgress(fullBar, overlapProgress, count);

      this.setState({ progress, repetition });
    }
  }

  animateProgress = (fullBar: number, overlapProgress: number, count: number) => {
    for (let i = 0; i <= count; i++) {
      setTimeout(() => {
        Animated.timing(this.state.animation, {
          toValue: i === count ? overlapProgress : fullBar,
          duration: 500,
          useNativeDriver: true
        }).start(() => {
          if (i !== count) {
            this.state.animation.setValue(0.0001);
            this.setState({ _order: i + 1 });
          }
        });
      }, i * 500);
    }
  };

  handleSalesView = (type: 'mtd' | 'today') => {
    firebase.analytics().logEvent(`toggle_sales_target_${type}`);
    this.setState({ viewType: type });
  };

  handleToggleSalesView = () => {
    const { viewType } = this.state;
    const newViewType = viewType === 'mtd' ? 'today' : 'mtd';
    this.setState({ viewType: newViewType });
  };

  render() {
    const {
      _isLoaded,
      _order,
      daysLeft,
      progress,
      completed,
      salesTargetToDate,
      salesTargetTotal,
      viewType
    } = this.state;

    const innerWidth = SCREEN_WIDTH * 0.65;
    const progressBarCompleteStyle = [
      styles.progressBarComplete,
      theme.styles.backgroundPrimary,
      { width: innerWidth }
    ];
    const textStyle = {
      color: progress === 'N/A' || progress < 0.45 ? theme.colors.primaryDark : '#fff'
    };

    if (!_isLoaded) {
      return (
        <Animated.View
          style={[
            styles.root,
            {
              opacity: this.props.opacity
                ? this.props.opacity.interpolate({
                    inputRange: [20, SCREEN_HEIGHT * 0.07],
                    outputRange: [1, 0],
                    extrapolate: 'clamp'
                  })
                : 1
            }
          ]}
        >
          <View style={styles.content}>
            <View style={styles.header}>
              <Text allowFontScaling={false} style={styles.title}>
                SALES
              </Text>
            </View>
            <View style={styles.loading}>
              <ActivityIndicator color={theme.colors.primary} size="large" />
            </View>
          </View>
        </Animated.View>
      );
    }

    return (
      <Animated.View
        style={[
          styles.root,
          {
            opacity: this.props.opacity
              ? this.props.opacity.interpolate({
                  inputRange: [20, SCREEN_HEIGHT * 0.15],
                  outputRange: [1, 0],
                  extrapolate: 'clamp'
                })
              : 1
          }
        ]}
      >
        <TouchableWithoutFeedback onPress={this.handleToggleSalesView}>
          <View style={styles.content}>
            <View style={styles.header}>
              <Text allowFontScaling={false} style={styles.title}>
                SALES
              </Text>
              <View style={styles.salesTabs}>
                <Touchable onPress={() => this.handleSalesView('today')}>
                  <View style={[styles.salesTab, viewType === 'today' ? styles.selectedSalesTab : {}]}>
                    <Text
                      allowFontScaling={false}
                      style={[styles.viewTypeText, viewType === 'today' ? styles.selectedViewtTypeText : {}]}
                    >
                      To-Date
                    </Text>
                  </View>
                </Touchable>
                <Touchable onPress={() => this.handleSalesView('mtd')}>
                  <View style={[styles.salesTab, viewType === 'mtd' ? styles.selectedSalesTab : {}]}>
                    <Text
                      allowFontScaling={false}
                      style={[styles.viewTypeText, viewType === 'mtd' ? styles.selectedViewtTypeText : {}]}
                    >
                      Month
                    </Text>
                  </View>
                </Touchable>
              </View>
            </View>
            <View style={styles.daysContainer}>
              <View style={styles.days}>
                <Text allowFontScaling={false} style={styles.daysText}>{`${daysLeft} day${
                  daysLeft > 1 ? 's' : ''
                } left`}</Text>
              </View>
            </View>
            <View style={styles.salesContainer}>
              <Text allowFontScaling={false} style={styles.salesText}>
                {numberWithCommas(completed)} /{' '}
                {numberWithCommas(viewType === 'today' ? salesTargetToDate : salesTargetTotal)} IDR
              </Text>
            </View>
            <View style={styles.progressContainer}>
              <Animated.View
                style={[
                  styles.progressBar,
                  {
                    backgroundColor:
                      _order === 0 ? 'rgba(0, 0, 0, 0.08)' : barColorSequence[(_order - 1) % barColorSequence.length]
                  }
                ]}
              >
                <Animated.View
                  style={[
                    ...progressBarCompleteStyle,
                    {
                      transform: [
                        {
                          translateX: this.state.animation.interpolate({
                            inputRange: [0, 1],
                            outputRange: [innerWidth / -2, 0]
                          })
                        },
                        { scaleX: this.state.animation }
                      ],
                      backgroundColor: barColorSequence[_order % barColorSequence.length]
                    }
                  ]}
                />
                <Text allowFontScaling={false} style={[styles.progressText, textStyle]}>
                  {progress !== 'N/A' ? `${round(progress * 100)}%` : progress}
                </Text>
              </Animated.View>
            </View>
          </View>
        </TouchableWithoutFeedback>
      </Animated.View>
    );
  }
}

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
  Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

type OwnProps = {
  opacity: Animated.Value;
};

type StateProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapStateToFirebaseProps>;

type WelcomeTrackerSalesState = {
  _isLoaded: boolean;
  _order: number;
  completed: number;
  salesTargetToDate: number;
  salesTargetTotal: number;
  viewType: 'today' | 'mtd';
  daysLeft: number;
  animation: Animated.Value;
  progress: number | 'N/A';
  repetition: number;
};

type EnhancedProps = StateProps & ConnectedDispatch;

type WelcomeTrackerSalesProps = OwnProps & EnhancedProps;

const mapStateToFirebaseProps = (state: RootState) => ({
  profile: state.firebase.profile
});

const firebaseConnectWelcomeTrackerSales = (props: WelcomeTrackerSalesProps) => [
  {
    path: `/siteSalesTarget/${props.profile.organization}/target`,
    storeAs: 'allSalesTargets'
  },
  {
    path: `/siteSalesTarget/${props.profile.organization}/figureCumulative`,
    storeAs: 'allSalesFiguresCumulative'
  }
];

const mapStateToProps = (state: RootState) => ({
  mySites: state.scheduleSites.myScheduledSites,
  salesTargets: state.firebase.data.allSalesTargets as { [siteKey: string]: { [month: string]: number } },
  salesFiguresCumulative: state.firebase.data.allSalesFiguresCumulative as {
    [siteKey: string]: { [month: string]: number };
  }
});

const enhance: InferableComponentEnhancerWithProps<EnhancedProps, OwnProps> = compose(
  connect(mapStateToFirebaseProps),
  firebaseConnect(firebaseConnectWelcomeTrackerSales),
  connect(mapStateToProps)
);

export default enhance(WelcomeTrackerSales);

const styles = StyleSheet.create({
  root: {
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 12.5,
    marginHorizontal: 12.5,
    borderRadius: 10,
    backgroundColor: '#fff',
    // Android settings
    elevation: 10,
    // iOS settings
    shadowOpacity: 0.2,
    shadowOffset: { width: 4, height: 10 }
  },
  content: {
    flexDirection: 'column',
    paddingVertical: 12,
    paddingHorizontal: 16
  },
  loading: {
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH * 0.65,
    paddingVertical: 12,
    paddingHorizontal: 16
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  title: {
    ...fontMaker({ weight: 'SemiBold' }),
    color: theme.colors.gray,
    paddingBottom: 2
  },
  daysContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 8
  },
  days: {
    flexDirection: 'row'
  },
  daysText: {
    color: theme.colors.gray,
    fontSize: 12
  },
  salesContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'baseline',
    paddingVertical: 4
  },
  salesText: {
    color: theme.colors.dark,
    fontSize: 11
  },
  progressContainer: {
    alignItems: 'center'
  },
  progressBar: {
    width: '100%',
    height: 15,
    marginBottom: 5,
    borderRadius: 20,
    overflow: 'hidden'
  },
  progressBarComplete: {
    height: 15,
    backgroundColor: theme.colors.primary,
    borderRadius: 20
  },
  progressText: {
    position: 'absolute',
    width: '100%',
    fontSize: 12,
    lineHeight: 15,
    textAlign: 'center'
  },
  salesTabs: {
    flexDirection: 'row',
    borderRadius: 14,
    backgroundColor: theme.colors.light,
    // Shadow
    shadowColor: '#000',
    elevation: 2,
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.15,
    shadowRadius: 3
  },
  salesTab: {
    paddingVertical: 4,
    paddingHorizontal: 6,
    minWidth: 70,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 14,
    backgroundColor: theme.colors.light
  },
  selectedSalesTab: {
    backgroundColor: '#ffffff'
  },
  viewTypeText: {
    fontSize: 11,
    fontWeight: '400',
    color: theme.colors.gray
  },
  selectedViewtTypeText: {
    color: theme.colors.primary
  }
});
