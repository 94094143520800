/**
 * Attempts to guess whether the user has caps lock on if the supplied string's first 3 letters are all uppercase.
 *
 * There is no true way to detect if the user's caps lock button is on,
 * so the app has to make a guess based on the string supplied.
 *
 * @param text - the string to detect consecutive capital letters
 * @returns true if the first 3 letters of the string are all uppercase
 */
export default function detectCapsLock(text: string) {
  let isUpperCase = false;
  const firstLetters = text.slice(0, 3);
  if (text.length >= 3 && firstLetters === firstLetters.toUpperCase() && isNaN(firstLetters)) {
    isUpperCase = true;
  }
  return isUpperCase;
}
