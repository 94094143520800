import localforage from 'localforage';
import { firebaseReducer, FirebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';
import { StateType } from 'typesafe-actions';
import { User } from '../utils/classes';
import { AccountAction, accountReducer } from './reducers/account/account';
import { ActivityAction } from './reducers/activity/activity';
import { BroadcastAction, broadcastReducer } from './reducers/broadcast';
import { CalibrationAction, calibrationReducer } from './reducers/calibration';
import { CompanyAction, companyReducer } from './reducers/company/company';
import { deparmentReducer, DepartmentAction } from './reducers/department/department';
import { featureAccessReducer } from './reducers/featureAccess/featureAccess.reducer';
import { GeolocationAction, geolocationReducer } from './reducers/geolocation';
import { JourneyAction, journeyReducer } from './reducers/journey/journey';
import journeyScheduleReducer, { JourneyScheduleAction } from './reducers/journeySchedule/journeySchedule.reducer';
import { LandingAction, landingReducer } from './reducers/landing';
import { MessageBoardAction, messageBoardReducer } from './reducers/messageBoard/messageBoard.reducer';
import { NetworkAction, networkReducer } from './reducers/network';
import { NotificationAction, notificationReducer } from './reducers/notification';
import { npsReducer } from './reducers/nps/nps.reducer';
import { OrganizationAction, organizationReducer } from './reducers/organization/organization';
import { PayoffAction, payoffReducer } from './reducers/payoff';
import { ProfileStatisticAction, profileStatisticReducer } from './reducers/profile/statistic/reducer';
import { ProfileThemeAction, profilethemeReducer } from './reducers/profiletheme';
import {
  questionnaireIndexReducer,
  QuestionnairesAction
} from './reducers/questionnaireIndex/questionnaireIndex.reducer';
import { questionnaireReducer } from './reducers/questionnaires/questionnaire.reducer';
import { ReportAction, reportReducer } from './reducers/report';
import reportcacheReducer, { ReportCacheAction } from './reducers/reportcache/reportcache.reducer';
import { ReportScheduleAction } from './reducers/reportSchedule/reportSchedule.reducer';
import { ReportSubmitAction, reportsubmitReducer } from './reducers/reportsubmit';
import { salesReducer, SalesTargetAction } from './reducers/sales/sales.reducer';
import { ScheduleJourneysAction, scheduleJourneysReducer } from './reducers/schedulejourneys';
import { ScheduleSitesAction, scheduleSitesReducer } from './reducers/schedulesites';
import { SiteAction, siteReducer } from './reducers/site/site';
import siteDatasetReducer, { SiteDatasetAction } from './reducers/siteDataset/siteDataset.reducer';
import { SiteReportAction, siteReportReducer } from './reducers/siteReport/siteReport.reducer';
import siteScheduleReducer, { SiteScheduleAction } from './reducers/siteSchedule/siteSchedule.reducer';
import { submitApiReportReducer } from './reducers/submitReport/submitReport.reducer';
import { ToastAction, toastReducer } from './reducers/toast';
import { TutorialAction, tutorialReducer } from './reducers/tutorial';
import { UploadQueueAction, uploadqueueReducer } from './reducers/uploadqueue';
import { UploadReportAction, uploadreportReducer } from './reducers/uploadreport';
import { UserAction, userReducer } from './reducers/user/user';

const reportcachePersistConfig: PersistConfig<any> = {
  key: 'root-reportcache',
  storage: localforage,
  blacklist: ['isReady'],
  timeout: 0
};

const siteSchedulePersistConfig: PersistConfig<any> = {
  key: 'root-siteschedule',
  storage: localforage,
  timeout: 0
};

const organizationPersistConfig: PersistConfig<any> = {
  key: 'root-organization',
  storage: localforage,
  timeout: 0
};

const departmentPersistConfig: PersistConfig<any> = {
  key: 'root-department',
  storage: localforage,
  timeout: 0
};

const scheduleSitesPersistConfig: PersistConfig<any> = {
  key: 'root-scheduleSites',
  storage: localforage,
  timeout: 0
};

const sitedataPersistConfig: PersistConfig<any> = {
  key: 'root-sitedata',
  storage: localforage,
  timeout: 0
};

const questionnairesPersistConfig: PersistConfig<any> = {
  key: 'root-questionnaires',
  storage: localforage,
  timeout: 0
};

const accountPersistConfig: PersistConfig<any> = {
  key: 'root-account',
  storage: localforage,
  timeout: 0
};

const journeyPersistConfig: PersistConfig<any> = {
  key: 'root-journey',
  storage: localforage,
  timeout: 0
};

const appReducer = combineReducers({
  firebase: firebaseReducer as <
    // Extending the User type so that it affects all connects
    UserType extends User,
    Schema extends Record<string, any> = {}
  >(
    state: any,
    action: any
  ) => FirebaseReducer.Reducer<UserType, Schema>,
  network: networkReducer,
  toast: toastReducer,
  notification: notificationReducer,
  landing: landingReducer,
  journey: persistReducer(journeyPersistConfig, journeyReducer),
  organization: persistReducer(organizationPersistConfig, organizationReducer),
  department: persistReducer(departmentPersistConfig, deparmentReducer),
  user: userReducer,
  site: siteReducer,
  calibration: calibrationReducer,
  geolocation: geolocationReducer,
  report: reportReducer,
  reportsubmit: reportsubmitReducer,
  reportcache: persistReducer(reportcachePersistConfig, reportcacheReducer),
  uploadreport: uploadreportReducer,
  uploadqueue: uploadqueueReducer,
  account: persistReducer(accountPersistConfig, accountReducer),
  broadcast: broadcastReducer,
  scheduleJourneys: scheduleJourneysReducer,
  scheduleSites: persistReducer(scheduleSitesPersistConfig, scheduleSitesReducer),
  sitedata: persistReducer(sitedataPersistConfig, siteDatasetReducer),
  siteschedule: persistReducer(siteSchedulePersistConfig, siteScheduleReducer),
  journeyschedule: journeyScheduleReducer,
  profiletheme: profilethemeReducer,
  tutorial: tutorialReducer,
  payoff: payoffReducer,
  profileStatistic: profileStatisticReducer,
  company: companyReducer,
  featureAccess: featureAccessReducer,
  questionnaires: persistReducer(questionnairesPersistConfig, questionnaireReducer),
  questionnaireIndex: questionnaireIndexReducer,
  npsUser: npsReducer,
  messageBoard: messageBoardReducer,
  salesTarget: salesReducer,
  siteReport: siteReportReducer,
  submitApiReport: submitApiReportReducer
});

export const rootReducer = (appState: RootState | undefined, appAction: RootAction) => {
  return appReducer(appState, appAction);
};

export type RootState = StateType<typeof appReducer>;
export type RootAction =
  | NetworkAction
  | ToastAction
  | NotificationAction
  | LandingAction
  | OrganizationAction
  | DepartmentAction
  | JourneyAction
  | UserAction
  | SiteAction
  | CalibrationAction
  | GeolocationAction
  | ReportAction
  | ReportSubmitAction
  | UploadReportAction
  | UploadQueueAction
  | ReportCacheAction
  | AccountAction
  | BroadcastAction
  | ScheduleSitesAction
  | ScheduleJourneysAction
  | SiteDatasetAction
  | SiteScheduleAction
  | JourneyScheduleAction
  | ReportScheduleAction
  | ProfileThemeAction
  | TutorialAction
  | PayoffAction
  | ProfileStatisticAction
  | CompanyAction
  | QuestionnairesAction
  | PromotionAction
  | MessageBoardAction
  | SalesTargetAction
  | ActivityAction
  | SiteReportAction;
