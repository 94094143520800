import { Platform, Linking, BackHandler, Alert } from 'react-native';
import { appversion } from '../../../constants/appversion';

/**
 * Check for updates with the version currently on the server.
 * Only major and minor versions will be used for comparison,
 * as patch updates will be delivered automatically via CodePush.
 *
 * @param latestVersion - the latest version as retrieved from Firebase
 */
export default function checkForUpdates(latestVersion: string) {
  // Semantic versioning will give us 3 strings when splitting - major, minor, and patch
  const [currentMajor, currentMinor] = appversion.split('.');
  const [latestMajor, latestMinor] = latestVersion.split('.');
  if (
    parseInt(latestMajor, 10) > parseInt(currentMajor, 10) ||
    (latestMajor === currentMajor && parseInt(latestMinor, 10) > parseInt(currentMinor, 10))
  ) {
    Alert.alert(
      'App Update',
      'A new app version is available. Please update to the latest version.',
      [
        {
          text: 'OK',
          onPress: () => {
            if (Platform.OS === 'android') {
              Linking.openURL('https://play.google.com/store/apps/details?id=com.sustainnovationgroup.sharpnsights');
            } else if (Platform.OS === 'ios') {
              Linking.openURL('https://itunes.apple.com/us/app/sharpnsights/id1381296300');
            }
            BackHandler.exitApp();
          }
        }
      ],
      { cancelable: false }
    );
  }
}
