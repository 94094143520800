import imageCompression from 'browser-image-compression';
import firebase from 'firebase';
import debounce from 'lodash/debounce';
// import { PERMISSIONS, request } from 'react-native-permissions';
// import RNFS from 'react-native-fs';
import moment from 'moment';
import { enums } from 'nimbly-common';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ActivityIndicator, AppState, InteractionManager, Keyboard, StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import { firebaseConnect, getVal, isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import { reqCamPermission, reqMicPermission } from '../../constants/Devices';
import theme from '../../constants/theme';
import { QuestionnairePageProps } from '../../routes/questionnaire';
import { RootState } from '../../store/reducers';
import { companyActions } from '../../store/reducers/company/company';
import { reportActions } from '../../store/reducers/report';
import * as reportcacheThunks from '../../store/reducers/reportcache/reportcache.actionThunk';
import { siteActions } from '../../store/reducers/site/site';
import { toastActions } from '../../store/reducers/toast';
import { dismissTutorialMode, tutorialActions } from '../../store/reducers/tutorial';
import { ConnectedDispatch, FirebaseConnect, InjectedSite } from '../../typing/types';
import { Question, Report, ReportSection, SiteInventory } from '../../utils/classes';
import { destructConditionalQuestions } from '../../utils/destructConditionalQuestions';
import { errorLogger } from '../../utils/errorLogger';
import { padZeroes } from '../../utils/file';
import { countIsInvalid, isValidNumber } from '../../utils/number';
import uploadReportDocuments from '../../utils/uploadReportDocuments';
import { ModalConfig } from '../global/ConfirmModal';
import Text from '../global/Text';
import { SKUsTutorialData } from './assets/SKUsTutorialData';
import QuestionnaireContainer from './QuestionnaireContainer';
import calibrateSKU from './utils/calibrateSKU';
import { getConditionalQuestionChild, validateConditionalQuestion } from './utils/conditionalQuestion';
import filterQuestions from './utils/filterInventoryQuestions';
import retrieveInvalidPhotos from './utils/retrieveInvalidPhotos';
import updateInventoryData from './utils/updateInventoryData';
import updateQuestionnaireState from './utils/updateQuestionnaireState';
import { ModalWarningConfig } from './WarningModal';

export class Questionnaire extends React.Component<QuestionnaireProps, QuestionnaireState> {
  state: QuestionnaireState = {
    _isTransitioned: false,
    _isBusy: false,
    isShowTooltipReview: false,
    isShowTooltipNA: false,
    _isUploadingCV: false,
    _captureTargetSKUKey: null,
    _editingPhotoIndex: null,
    _editingVideoIndex: null,
    _showModal: false,
    _footerModal: false,
    _showWarningModal: false,
    _showCamera: false,
    _modalWarningConfig: {
      title: '',
      subtitle: '',
      // eslint-disable-next-line no-empty
      options: [{ text: this.props.t('common:cancel'), action: () => null, style: 'cancel' }]
    },
    _modalConfig: {
      title: '',
      subtitle: '',
      // eslint-disable-next-line no-empty
      options: [{ text: this.props.t('common:cancel'), action: () => null, style: 'cancel' }]
    },
    _invalidPhotos: [],
    _invalidConditionalPhotos: [],
    _completedCount: 0,
    currentInventory: undefined,
    skuQuery: ''
  };

  saveQuestionnaireDebounced: any;

  constructor(props: QuestionnaireProps) {
    super(props);
    this.saveQuestionnaireDebounced = debounce(this.saveQuestionnaire, 1000);
  }

  private handleSKUQueryChanged = debounce((text: string) => this.setState({ skuQuery: text }), 250);

  static getDerivedStateFromProps(props: QuestionnaireProps, state: QuestionnaireState) {
    const { siteSKUs, latestInventory, calibratingData, isTutorialMode } = props;
    const { questions } = props.selectedReport;
    const currentInventory = state.currentInventory;
    let calibratingSKU: { [skuKey: string]: boolean } = {};
    const latestInventoryReportDate =
      calibratingData && Object.keys(calibratingData).length > 0 ? Object.keys(calibratingData)[0] : null;
    const calibratingDataKeys = latestInventoryReportDate
      ? Object.keys(calibratingData[latestInventoryReportDate] || {})
      : [];

    // If calibratingData is undefined, all SKUs have >0 starting stock
    // Therefore, there are no SKUs that need calibration
    if (calibratingData === undefined) {
      // do nothing
    } else if (
      siteSKUs &&
      (props.calibratingSKU === null ||
        Object.keys(props.calibratingSKU).length === 0 ||
        (latestInventoryReportDate && props.calibratingSKUTimestamp !== latestInventoryReportDate))
    ) {
      Object.keys(siteSKUs).forEach(skuKey => {
        calibratingSKU[skuKey] = calibratingDataKeys.indexOf(skuKey) === -1;
      });
      props.dispatch(reportActions.setReportCalibratingSKU(calibratingSKU, latestInventoryReportDate));
    } else {
      calibratingSKU = props.calibratingSKU || {};
    }

    const hasVideoAttachment = props.featureAccessVideo && props.organization?.features?.video;

    const completedQuestions = filterQuestions(
      questions,
      currentInventory,
      siteSKUs,
      calibratingSKU,
      isTutorialMode,
      hasVideoAttachment
    );
    const updatedState = updateQuestionnaireState(
      state,
      currentInventory,
      completedQuestions.length,
      props.selectedSite,
      props.selectedReportKey,
      latestInventory
    );

    return updatedState;
  }

  async componentDidMount() {
    const { questions } = this.props.selectedReport;
    const { auth, profile } = this.props;
    // Wait for animation to complete before setting component as ready
    InteractionManager.runAfterInteractions(() => {
      this.setState({ _isTransitioned: true });
    });

    const photo = await retrieveInvalidPhotos(questions, this.handleInputAnswer);

    if (photo.invalidPhotoCount > 0) {
      this.setState(
        { _invalidPhotos: photo.invalidPhotos, _invalidConditionalPhotos: photo.invalidConditionalPhotos },
        () => {
          this.showDeleteInvalidPhotosConfirmation(photo.invalidPhotoCount);
        }
      );
    }

    try {
      // Set calibratingSKU to null upon entering the report to reset
      // Refer to getDerivedStateFromProps for more info
      this.props.dispatch(reportActions.setReportCalibratingSKU(null, null));

      // Get newest companies for competitor analysis
      this.props.dispatch(companyActions.fetchCompaniesAsync.request());

      // Get newest prouct categories for competitor analysis
      this.props.dispatch(companyActions.fetchProductCategoriesAsync.request());

      // Get newest product for competitior analysis
      this.props.dispatch(companyActions.fetchProductAsync.request());

      // Get newes store for competitor analysis
      this.props.dispatch(siteActions.fetchSitesAsync.request(undefined));

      // Get newest product target market for competitor analysis
      this.props.dispatch(companyActions.fetchProductTargetMarketAsync.request());
    } catch (err) {
      errorLogger(auth.uid, profile.organization, '', '', err, 'Questionnaire.tsx/componentDidMount');
    }
  }

  componentDidUpdate(prevProps: QuestionnaireProps, prevState: QuestionnaireState) {
    // this condition to watch every change on completed count in tutorial mode ,
    // so when all question is already answered, will show up tooltip in review button
    if (
      this.props.selectedReport &&
      this.props.selectedReport.questions &&
      this.state._completedCount !== prevState._completedCount
    ) {
      if (
        this.state._completedCount === this.props.selectedReport.questions.length &&
        this.props.isShowQuestionTutorialModal === false
      ) {
        this.setState({ isShowTooltipReview: true });
      }
    } else if (this.state._isBusy !== prevState._isBusy || this.state._completedCount !== prevState._completedCount) {
      if (this.state._completedCount < this.props.selectedReport.questions.length) {
        this.setState({ isShowTooltipReview: false });
      }
    }

    if (
      prevProps.isShowQuestionTutorialModal === true &&
      this.props.isShowQuestionTutorialModal === false &&
      this.props.tutorialQuestionType === 'open'
    ) {
      this.handleShowTooltipNA(true);
    }
  }

  handleShowTooltipNA = (value: boolean) => {
    this.setState({ isShowTooltipNA: value });
  };

  handleDismissTooltipReview = () => {
    this.setState({ isShowTooltipReview: false });
  };

  handleChangeTutorialQuestionType = (type: string) => {
    this.props.dispatch(tutorialActions.setTutorialQuestionType(type));
  };

  handleShowTutorialQuestionModal = (isShow: boolean) => {
    this.props.dispatch(tutorialActions.showTutorialQuestionModal(isShow));
  };

  componentWillUnmount() {
    this.setState({ isShowTooltipReview: false });
    // Save questionnaire & inventory when back button is pressed
    // Prevent saving questionnaire & inventory when app is being closed
    // Prevent saving questionnaire & inventory when there are invalid photos
    if (AppState.currentState === 'active') {
      this.saveQuestionnaire();
      this.saveInventoryStatus();
    }
    this.props.dispatch(reportActions.setCameraActive(false));
    Keyboard.dismiss();
  }

  showDeleteInvalidPhotosConfirmation(invalidPhotoCount: number) {
    const { t } = this.props;
    const uploadModalConfig: ModalConfig = {
      title: t('questionnaire:modal.photoMissing'),
      subtitle: t('questionnaire:modal.warningMissing', { invalidPhotoCount }),
      options: [
        {
          text: t('questionnaire:modal.proceed'),
          action: () => {
            this.setState(
              prevState => ({ _showModal: !prevState._showModal }),
              () => {
                this.deleteInvalidPhotos();
              }
            );
          },
          style: 'confirm'
        },
        {
          text: t('common:cancel'),
          action: () => {
            this.setState(
              prevState => ({ _showModal: !prevState._showModal }),
              () => {
                this.props.navigation.pop();
              }
            );
          },
          style: 'cancel'
        }
      ]
    };
    return this.setState({
      _showModal: !this.state._showModal,
      _modalConfig: uploadModalConfig
    });
  }

  async deleteInvalidPhotos() {
    const { questions } = this.props.selectedReport;
    for (let questionIndex = 0; questionIndex < this.state._invalidPhotos.length; questionIndex++) {
      const questionInvalidPhotos = this.state._invalidPhotos[questionIndex];
      const questionConditionalInvalidPhotos = this.state._invalidConditionalPhotos[questionIndex];

      if (questionInvalidPhotos.length > 0) {
        const questionPhotos = questions[questionIndex].photos.slice();
        if (questionPhotos.length > 1) {
          for (const photoIndex of questionInvalidPhotos) {
            questionPhotos.splice(photoIndex, 1);
            await this.props.dispatch(reportActions.setAnswerPhotos(questionIndex, questionPhotos, ''));
          }
        }

        if (questionPhotos.length < 2) {
          await this.props.dispatch(reportActions.setAnswerPhotos(questionIndex, [], ''));
        }
      }

      if (questionConditionalInvalidPhotos.length > 0) {
        const currentQuestion = questions[questionIndex];

        if (currentQuestion.isConditional) {
          const conditionalQuestions = currentQuestion.conditionalQuestion!.conditions;
          const conditionalAnswerKeys = Object.keys(conditionalQuestions || {});

          for (const [conditionalIndex, conditionalAnswerKey] of conditionalAnswerKeys.entries()) {
            if (conditionalQuestions[conditionalAnswerKey]) {
              const conditionalQuestion = conditionalQuestions[conditionalAnswerKey];
              for (let indexConditional = 0; indexConditional < conditionalQuestion.length; indexConditional++) {
                const conditionalValue = conditionalQuestion[indexConditional];
                if (conditionalValue.photos) {
                  const conditionalPhotos = conditionalQuestion[indexConditional].photos.slice();
                  if (conditionalPhotos.length > 1) {
                    for (const [conditionalPhotoIndex] of questionConditionalInvalidPhotos.entries()) {
                      conditionalPhotos.splice(conditionalPhotoIndex, 1);

                      await this.props.dispatch(
                        reportActions.setAnswerPhotos(
                          questionIndex,
                          conditionalPhotos,
                          indexConditional,
                          conditionalAnswerKey
                        )
                      );
                    }
                  }
                  if (conditionalPhotos.length < 2) {
                    await this.props.dispatch(
                      reportActions.setAnswerPhotos(questionIndex, [], indexConditional, conditionalAnswerKey)
                    );
                  }
                }
              }
            }
          }
        }
      }
    }
    await this.saveQuestionnaire();
    this.setState({ _invalidPhotos: [], _invalidConditionalPhotos: [] });
  }

  handleMoveToNextQuestion = () => {
    const { selectedReport, currentQuestionIndex } = this.props;

    if (currentQuestionIndex < selectedReport.questions.length - 1) {
      this.props.dispatch(reportActions.setQuestionIndexWithDrawer(currentQuestionIndex + 1));
    }
  };

  handleMoveToPrevQuestion = () => {
    const { selectedReport, currentQuestionIndex } = this.props;

    if (currentQuestionIndex > 0) {
      this.props.dispatch(reportActions.setQuestionIndexWithDrawer(currentQuestionIndex - 1));
    }
  };

  handleNavReference = (url: string) => {
    const lastPart = url.substr(url.lastIndexOf('.') + 1);
    if (lastPart === 'pdf') {
      window.open(`http://docs.google.com/gview?embedded=true&url=${url}`, '_blank');
      return;
    }

    window.open(url, '_blank');
  };

  handleNavCreateCompany = () => {
    const { profileColor, route, selectedSite } = this.props;
    this.props.navigation.navigate('CreateCompany', {
      profileColor,
      originTab: route.params.originTab || 'HomeTab',
      siteName: selectedSite.name
    });
  };

  handleSelectChoice = (index: number, choice: string, condition?: number | string) => {
    const { questions } = this.props.selectedReport;
    if (typeof condition === 'number') {
      if (
        choice === 'not-applicable' &&
        questions[index].conditionalQuestion!.conditions[questions[index].answer][condition].answer === 'not-applicable'
      ) {
        // toggle default to green when previously set to not-applicable in binary
        if (
          questions[index].conditionalQuestion!.conditions[questions[index].answer][condition].type.indexOf('binary') >
          -1
        ) {
          this.props.dispatch(reportActions.selectAnswerChoice(index, 'green-flag', condition!));
          // toggle back to its default in open-ended question
        } else if (
          questions[index].conditionalQuestion!.conditions[questions[index].answer][condition].type ===
          'multiple-choice'
        ) {
          this.props.dispatch(reportActions.selectAnswerChoice(index, choice, condition!));
        } else {
          this.props.dispatch(
            reportActions.selectAnswerChoice(
              index,
              questions[index].conditionalQuestion!.conditions[questions[index].answer][condition].type === 'number'
                ? 0
                : questions[index].conditionalQuestion!.conditions[questions[index].answer][condition].type ===
                    'score' ||
                  questions[index].conditionalQuestion!.conditions[questions[index].answer][condition].type ===
                    'range-flag'
                ? ''
                : 'open-ended',
              condition!
            )
          );
        }
      } else {
        this.props.dispatch(reportActions.selectAnswerChoice(index, choice, condition!));
      }
    } else {
      if (choice === 'not-applicable' && questions[index].answer === 'not-applicable') {
        // toggle default to green when previously set to not-applicable in binary
        if (questions[index].type.indexOf('binary') > -1) {
          this.props.dispatch(reportActions.selectAnswerChoice(index, 'green-flag'));
          // toggle back to its default in open-ended question
        } else if (questions[index].type === 'multiple-choice') {
          this.props.dispatch(reportActions.selectAnswerChoice(index, choice));
        } else {
          this.props.dispatch(
            reportActions.selectAnswerChoice(
              index,
              questions[index].type === 'number'
                ? 0
                : questions[index].type === 'score' || questions[index].type === 'range-flag'
                ? ''
                : 'open-ended'
            )
          );
        }
      } else {
        this.props.dispatch(reportActions.selectAnswerChoice(index, choice));
      }
    }
  };

  handleSelectWeightedChoice = (index: number, choice: string | number, condition?: number | string) => {
    const answerCondition = typeof condition === 'number' ? condition : null;
    this.props.dispatch(reportActions.selectAnswerChoiceScore(index, choice.label, answerCondition, choice.flag));
  };

  private handleSelectChecklist = (index: number, choice: string, condition?: number | string) => {
    const { questions } = this.props.selectedReport;

    if (choice === 'not-applicable') {
      // reset answer if not-applicable is selected
      this.props.dispatch(reportActions.selectChecklistChoice(index, [choice], condition));
      return;
    }

    const q = questions[index];
    const question =
      validateConditionalQuestion(q, q.answer, condition) && typeof condition === 'number'
        ? getConditionalQuestionChild(q, q.answer, condition)
        : q;
    const answers = question.answers;
    const clonedAnswers: string[] = typeof answers === 'undefined' ? ([] as string[]) : (answers.slice() as string[]);
    const selectedAnswer = clonedAnswers.findIndex(answer => answer === choice);
    const naAnswer = clonedAnswers.findIndex(answer => answer === 'not-applicable');

    if (selectedAnswer > -1) {
      clonedAnswers.splice(selectedAnswer, 1);
    } else {
      clonedAnswers.push(choice);
    }

    if (naAnswer > -1) {
      clonedAnswers.splice(naAnswer, 1);
    }
    this.props.dispatch(reportActions.selectChecklistChoice(index, clonedAnswers, condition));
  };

  handleInputAnswer = (index: number, text: string, condition?: string | number) => {
    this.props.dispatch(reportActions.inputAnswerText(index, text, condition!));
  };

  handleInputComment = (index: number, text: string, condition?: string | number) => {
    this.props.dispatch(reportActions.inputAnswerCommentText(index, text, condition));
  };

  handleInputDecimal = (index: number, text: string, condition?: string | number) => {
    // handler when deleting answer
    if (text === '') {
      this.props.dispatch(reportActions.inputAnswerText(index, text, condition!));
    }
    if (isValidNumber(text, { negative: true })) {
      // save as string if text ends with dot, or initial negative
      if (text.endsWith('.') || text === '-') {
        this.props.dispatch(reportActions.inputAnswerText(index, text, condition!));
      } else {
        const parsedText = parseFloat(text);
        this.props.dispatch(reportActions.inputAnswerText(index, parsedText, condition!));
      }
    }
  };

  handleInputScore = (index: number, value: number, condition?: number | string) => {
    this.props.dispatch(reportActions.inputAnswerText(index, value, condition!));
  };

  handleSetRemedies = (index: number, text: string, currentConditionIndex?: number | string) => {
    this.props.dispatch(reportActions.setAnswerRemedy(index, text, currentConditionIndex));
  };

  handleInputInventory = (index: number, text: string, type: 'in' | 'out' | 'final', question: Question) => {
    const currentInventory = this.state.currentInventory;
    const startInventory =
      currentInventory && currentInventory[question.sku] ? currentInventory[question.sku].final : 0;
    const isValid = !countIsInvalid(text);
    const parsedText = text === '' ? -1 : parseFloat(text);
    let inInventory = 0;
    let finalInventory = 0;

    switch (type) {
      case 'in':
        inInventory = isValid ? parsedText : 0;
        finalInventory = question.inventory!.final === -1 ? 0 : question.inventory!.final;
        break;
      case 'final':
        inInventory = question.inventory!.in === -1 ? 0 : question.inventory!.in;
        finalInventory = isValid ? parsedText : 0;
        break;
      default:
        break;
    }

    const intermediate = (startInventory + inInventory - finalInventory).toFixed(2);
    const inventoryOutNumber = Math.abs(parseFloat(intermediate));

    if (isValid) {
      this.props.dispatch(reportActions.inputAnswerInventory(index, parsedText, type));
      this.props.dispatch(reportActions.inputAnswerInventory(index, inventoryOutNumber, 'out'));
    } else {
      this.props.dispatch(reportActions.inputAnswerInventory(index, -1, type));
      this.props.dispatch(reportActions.inputAnswerInventory(index, inventoryOutNumber, 'out'));
    }
  };

  handleInputCompetitorAnalysis = (index: number, text: string, type: string) => {
    // reset photos if form changed
    if (type === 'form') {
      this.props.dispatch(reportActions.setAnswerPhotos(index, []));
    }
    this.props.dispatch(reportActions.inputAnswerCompetitorAnalysis(index, text, type));
  };

  handleInputSKUInventory = (
    index: number,
    skuKey: string,
    input: any,
    type: 'in' | 'out' | 'final' | 'photos' | 'comment',
    question: Question,
    downloadedImage
  ) => {
    let selectedSiteSKUs = this.props.siteSKUs!;
    let selectedCalibratingSKUs = this.props.calibratingSKU;

    if (this.props.isTutorialMode) {
      selectedSiteSKUs = SKUsTutorialData;
      selectedCalibratingSKUs = { '-skuTutorial': true };
    }

    const calibratedSKU = calibrateSKU(skuKey, input, question, type, selectedSiteSKUs, selectedCalibratingSKUs);

    if (calibratedSKU.status === -1) {
      return;
    } else if (calibratedSKU.status === 1) {
      if (calibratedSKU.isValid) {
        this.props.dispatch(
          reportActions.inputAnswerSKUInventory(index, skuKey, calibratedSKU.parsedText, type, downloadedImage)
        );
        this.props.dispatch(
          reportActions.inputAnswerSKUInventory(index, skuKey, calibratedSKU.inventoryOutNumber, 'out', downloadedImage)
        );
      } else {
        this.props.dispatch(reportActions.inputAnswerSKUInventory(index, skuKey, -1, type, downloadedImage));
        this.props.dispatch(
          reportActions.inputAnswerSKUInventory(index, skuKey, calibratedSKU.inventoryOutNumber, 'out', downloadedImage)
        );
      }
    } else {
      this.props.dispatch(reportActions.inputAnswerSKUInventory(index, skuKey, input, type, downloadedImage));
    }
    this.props.dispatch(reportActions.inputAnswerSKUInventory(index, skuKey, true, 'isFilledByUser', downloadedImage));
  };

  _handleFail = props => {};

  handleConfirmEditPhoto = (photoIndex: number, skuKey?: string, currentConditionIndex?: number | string) => {
    const { t } = this.props;
    const editModalConfig: ModalConfig = {
      title: t('questionnaire:prompt.editPhoto'),
      subtitle: t('questionnaire:prompt.photo'),
      options: [
        {
          text: t('common:delete'),
          action: () => {
            this.setState(
              prevState => ({ _showModal: !prevState._showModal }),
              () => {
                this.handleDeletePhoto(photoIndex, skuKey, currentConditionIndex);
              }
            );
          },
          style: 'destructive'
        },
        {
          text: t('common:cancel'),
          action: () => {
            this.setState(prevState => ({ _showModal: !prevState._showModal }));
          },
          style: 'cancel'
        }
      ]
    };
    return this.setState({
      _showModal: !this.state._showModal,
      _modalConfig: editModalConfig
    });
  };

  cancelEditVideo = () => {
    this.setState({ _editingVideoIndex: null });
  };

  handleNavRecordVideo = (currentConditionIndex?: number | string, props?: any) => {
    const {
      auth,
      profile,
      currentQuestionIndex,
      selectedReport,
      selectedReportKey,
      selectedSiteKey,
      temporaryVideoURI,
      organization,
      t
    } = this.props;
    try {
      if (props.uri) {
        let videos: string[] = [];
        if (typeof currentConditionIndex! === 'number') {
          if (
            selectedReport.questions[currentQuestionIndex].isConditional &&
            selectedReport.questions[currentQuestionIndex].conditionalQuestion!.conditions[
              selectedReport.questions[currentQuestionIndex].answer
            ][currentConditionIndex!].videos
          ) {
            const reportQuestion = selectedReport.questions[currentQuestionIndex];
            const answerKey = reportQuestion.answer;
            videos = reportQuestion.conditionalQuestion!.conditions[answerKey][currentConditionIndex].videos!.slice();
          }
        } else if (selectedReport.questions[currentQuestionIndex].videos) {
          videos = selectedReport.questions[currentQuestionIndex].videos.slice();
        }

        const questionNumberSerial = padZeroes(currentQuestionIndex + 1, 3);
        let serverTime;
        firebase
          .database()
          .ref('/.info/serverTimeOffset')
          .once('value')
          .then(
            function stv(data) {
              serverTime = data.val();
            },
            function (err) {
              return err;
            }
          );
        const timestamp = moment(serverTime).format('YYYYMMDD-HHmmss');
        const videoURI = `${
          props!.uri
        }/fileName/${selectedSiteKey}/${selectedReportKey}/Q${questionNumberSerial}-${timestamp}.mp4`;
        const videoFile = this.dataURLtoFile(props.uri, 'testingvideo.mp4');
        this.setState(
          {
            isUploading: true
          },
          () => {
            uploadReportDocuments(
              videoFile,
              organization.organizationID,
              selectedSiteKey,
              selectedReportKey,
              false,
              false,
              false,
              undefined,
              null,
              currentQuestionIndex,
              undefined,
              'video'
            )
              .then(res => {
                videos.push(res.path);

                // unset temporary URI on save
                this.props.dispatch(reportActions.setTemporaryVideoURI(null));
                this.props.dispatch(
                  reportActions.setAnswerVideos(currentQuestionIndex, videos, currentConditionIndex!)
                );
                // Pop back to the questionnaire

                this.saveQuestionnaireDebounced();
                this.setState({
                  isUploading: false
                });
              })
              .catch(() => {
                this.props.dispatch(toastActions.createToast(t('common:alert.uploadFail'), false, 5000));
                this.setState({
                  isUploading: false
                });
              });
          }
        );
      }
    } catch (err) {
      if (err) {
        console.log(err);
      }
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  handleCloseModalWarning = () => {
    this.setState({
      _showWarningModal: false
    });
  };

  handleUploadDocuments = (currentConditionIndex?: number | string, file?: any) => {
    const {
      auth,
      t,
      profile,
      currentQuestionIndex,
      selectedReport,
      selectedReportKey,
      selectedSiteKey,
      temporaryVideoURI,
      organization
    } = this.props;

    this.setState(
      {
        _currentConditionIndex: currentConditionIndex
      },
      () => {
        if (this.state._showWarningModal) {
          this.setState({
            _showWarningModal: false
          });
        }

        if (file?.size > 7000000) {
          const uploadModalConfig: ModalWarningConfig = {
            title: t('questionnaire:prompt.cannotAttach'),
            subtitle: t('questionnaire:prompt.limitFile')
          };
          return this.setState({
            _showWarningModal: !this.state._showWarningModal,
            _modalWarningConfig: uploadModalConfig
          });
        }

        let documents: File[] = [];
        let fileExt = '';
        const questionNumberSerial = padZeroes(currentQuestionIndex + 1, 3);

        if (typeof currentConditionIndex! === 'number') {
          if (
            selectedReport.questions[currentQuestionIndex].isConditional &&
            selectedReport.questions[currentQuestionIndex].conditionalQuestion!.conditions[
              selectedReport.questions[currentQuestionIndex].answer
            ][currentConditionIndex!].documents
          ) {
            const reportQuestion = selectedReport.questions[currentQuestionIndex];
            const answerKey = reportQuestion.answer;
            documents =
              reportQuestion.conditionalQuestion!.conditions[answerKey][currentConditionIndex].documents!.slice();
          }
        } else if (selectedReport.questions[currentQuestionIndex].documents) {
          documents = selectedReport.questions[currentQuestionIndex].documents.slice();
        }

        let serverTime;
        firebase
          .database()
          .ref('/.info/serverTimeOffset')
          .once('value')
          .then(
            function stv(data) {
              serverTime = data.val();
            },
            function (err) {
              return err;
            }
          );
        const timestamp = moment(serverTime).format('YYYYMMDD-HHmmss');

        if (file) {
          fileExt = file.name.split('.').pop();
        }

        if (documents.findIndex(x => x?.name === file?.name) >= 0) {
          return;
        }

        if (file !== undefined) {
          this.setState(
            {
              isUploading: true
            },
            () => {
              uploadReportDocuments(
                file,
                organization.organizationID,
                selectedSiteKey,
                selectedReportKey,
                false,
                false,
                false,
                undefined,
                null,
                currentQuestionIndex,
                undefined,
                'document'
              )
                .then(res => {
                  documents.push(res.path);

                  // unset temporary URI on save
                  this.props.dispatch(reportActions.setTemporaryVideoURI(null));
                  this.props.dispatch(
                    reportActions.setAnswerFile(currentQuestionIndex, documents, currentConditionIndex!)
                  );
                  // Pop back to the questionnaire

                  this.saveQuestionnaireDebounced();
                  this.setState({
                    isUploading: false
                  });
                })
                .catch(() => {
                  this.props.dispatch(toastActions.createToast(t('common:alert.uploadFail'), false, 5000));
                  this.setState({
                    isUploading: false
                  });
                });
            }
          );
        }
      }
    );
  };

  RegAccessCamAndMic = async () => {
    this.setState({ _captureTargetSKUKey: null });
    this.props.dispatch(reportActions.setCameraActive(true));
    this.props.dispatch(reportActions.setCameraFormat('video'));

    await reqCamPermission();
    await reqMicPermission();
  };

  handleDeleteVideo = (videoIndex, currentConditionIndex) => {
    const { currentQuestionIndex, selectedReport } = this.props;

    try {
      let videos: string[] = [];

      if (typeof currentConditionIndex === 'number') {
        if (
          selectedReport.questions[currentQuestionIndex].conditionalQuestion!.conditions[
            selectedReport.questions[currentQuestionIndex].answer
          ][currentConditionIndex].videos
        ) {
          const reportQuestion = selectedReport.questions[currentQuestionIndex];
          const answerKey = reportQuestion.answer;
          videos = reportQuestion.conditionalQuestion!.conditions[answerKey][currentConditionIndex].videos!.slice();
        }
      } else if (selectedReport.questions[currentQuestionIndex].videos) {
        videos = selectedReport.questions[currentQuestionIndex].videos.slice();
      }

      if (videos && videoIndex !== undefined && videoIndex >= 0 && videos[videoIndex]) {
        videos.splice(videoIndex, 1);
      }

      this.props.dispatch(reportActions.setAnswerVideos(currentQuestionIndex, videos, currentConditionIndex));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  handleDeleteFile = (videoIndex, currentConditionIndex) => {
    const { currentQuestionIndex, selectedReport } = this.props;

    try {
      let documents: File[] = [];

      if (typeof currentConditionIndex === 'number') {
        if (
          selectedReport.questions[currentQuestionIndex].conditionalQuestion!.conditions[
            selectedReport.questions[currentQuestionIndex].answer
          ][currentConditionIndex].documents
        ) {
          const reportQuestion = selectedReport.questions[currentQuestionIndex];
          const answerKey = reportQuestion.answer;
          documents =
            reportQuestion.conditionalQuestion!.conditions[answerKey][currentConditionIndex].documents!.slice();
        }
      } else if (selectedReport.questions[currentQuestionIndex].documents) {
        documents = selectedReport.questions[currentQuestionIndex].documents.slice();
      }

      if (documents && videoIndex !== undefined && videoIndex >= 0 && documents[videoIndex]) {
        documents.splice(videoIndex, 1);
      }

      this.props.dispatch(reportActions.setAnswerFile(currentQuestionIndex, documents, currentConditionIndex));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
    }
  };

  handleNavPlaybackVideo = (uri: string, videoIndex: number, currentConditionIndex?: number | string) => {
    const { t } = this.props;

    const editModalConfig: ModalConfig = {
      title: t('questionnaire:prompt.editVideo'),
      subtitle: t('questionnaire:prompt.video'),
      options: [
        {
          text: t('common:delete'),
          action: () => {
            this.setState(
              prevState => ({ _showModal: !prevState._showModal }),
              () => {
                this.handleDeleteVideo(videoIndex, currentConditionIndex);
              }
            );
          },
          style: 'destructive'
        },
        {
          text: t('common:cancel'),
          action: () => {
            this.setState(prevState => ({ _showModal: !prevState._showModal }));
          },
          style: 'cancel'
        }
      ]
    };
    return this.setState({
      _showModal: !this.state._showModal,
      _modalConfig: editModalConfig
    });
  };

  handleEditUploadFile = (fileIndex: number, currentConditionIndex?: number | string) => {
    const { t } = this.props;
    const editModalConfig: ModalConfig = {
      title: t('questionnaire:prompt.editDocument'),
      subtitle: t('questionnaire:prompt.document'),
      options: [
        {
          text: t('common:delete'),
          action: () => {
            this.setState(
              prevState => ({ _showModal: !prevState._showModal }),
              () => {
                this.handleDeleteFile(fileIndex, currentConditionIndex);
              }
            );
          },
          style: 'destructive'
        },
        {
          text: t('common:cancel'),
          action: () => {
            this.setState(prevState => ({ _showModal: !prevState._showModal }));
          },
          style: 'cancel'
        }
      ]
    };
    return this.setState({
      _showModal: !this.state._showModal,
      _modalConfig: editModalConfig
    });
  };

  handleCloseCamera = () => {
    this.setState({
      _showModal: false,
      _showCamera: false,
      _footerModal: false
    });
  };

  handleTakePhoto = (currentConditionIndex?: number | string, uri?: any) => {
    this.handlePhotoCaptured(uri, false, this.state._currentConditionIndex);
    this.handleCloseCamera();
  };

  handleAcivateCamera = () => {
    this.setState({
      _showCamera: true
    });
  };

  handleActivateImagePicker = (skuKey?: string, currentConditionIndex?: number | string, props?: any) => {
    const { t } = this.props;
    this.setState({
      _captureTargetSKUKey: skuKey,
      _currentConditionIndex: currentConditionIndex,
      _footerModal: true
    });
  };

  // TODO: refactor and collect permission into Devices.ts file
  checkPermission = async () => {};

  setTemporaryPhoto = (uri: string, currentConditionIndex: number | string) => {
    const { selectedSiteKey, selectedReportKey } = this.props;
    if (selectedSiteKey && selectedReportKey) {
      // Firing this action will cause Questionnaire.componentDidUpdate() to fire
      this.handlePhotoCaptured(uri, false, currentConditionIndex);
    }
  };

  cancelEditPhoto = () => {
    this.setState({ _editingPhotoIndex: null });
  };

  // When camera is done, it will fire an event with the photo url.
  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  handlePhotoCaptured = async (
    temporaryPhotoURI: string,
    isImagePicker: boolean,
    currentConditionIndex?: number | string,
    photoIndex?: number
  ) => {
    let photos: File[] = [];
    let downloadedImage = [];
    const { selectedSiteKey, selectedReportKey, selectedReport, currentQuestionIndex, auth, profile, organization, t } =
      this.props;

    const { _captureTargetSKUKey } = this.state;
    try {
      if (
        _captureTargetSKUKey &&
        selectedReport.questions[currentQuestionIndex].skuInventory &&
        selectedReport.questions[currentQuestionIndex].skuInventory![_captureTargetSKUKey]!.photos
      ) {
        photos = selectedReport.questions[currentQuestionIndex].skuInventory![_captureTargetSKUKey]!.photos!.slice();
        downloadedImage =
          selectedReport.questions[currentQuestionIndex].skuInventory![_captureTargetSKUKey]!.downloadedImage!.slice();
      } else if (typeof currentConditionIndex === 'number') {
        if (
          selectedReport.questions[currentQuestionIndex].isConditional &&
          selectedReport.questions[currentQuestionIndex].conditionalQuestion!.conditions[
            selectedReport.questions[currentQuestionIndex].answer
          ][currentConditionIndex].photos
        ) {
          const reportQuestion = selectedReport.questions[currentQuestionIndex];
          const answerKey = reportQuestion.answer;
          photos = reportQuestion.conditionalQuestion!.conditions[answerKey][currentConditionIndex!].photos!.slice();
          downloadedImage =
            reportQuestion.conditionalQuestion!.conditions[answerKey][currentConditionIndex!].downloadedImage!.slice();
        }
      } else if (selectedReport.questions[currentQuestionIndex].photos) {
        photos = selectedReport.questions[currentQuestionIndex].photos.slice();
        downloadedImage = selectedReport.questions[currentQuestionIndex].downloadedImage.slice();
      }

      let questionNumberSerial = padZeroes(currentQuestionIndex + 1, 3);
      if (selectedReport.questions[currentQuestionIndex].isConditional && typeof currentConditionIndex === 'number') {
        // Q001-CQ001-001-timestamp
        questionNumberSerial += `-CQ${padZeroes(currentConditionIndex! + 1, 3)}-${padZeroes(
          this.state._editingPhotoIndex + 1,
          3
        )}`;
      }
      let serverTime;
      firebase
        .database()
        .ref('/.info/serverTimeOffset')
        .once('value')
        .then(
          function stv(data) {
            serverTime = data.val();
          },
          function (err) {
            return err;
          }
        );
      const timestamp = moment(serverTime).format('YYYYMMDD-HHmmss');

      const options = {
        maxSizeMB: 0.05,
        maxWidthOrHeight: 1920
      };
      const compressedFile = await imageCompression(
        this.dataURLtoFile(
          temporaryPhotoURI,
          `${selectedSiteKey}/${selectedReportKey}/Q${questionNumberSerial}-${timestamp}.jpg`
        ),
        options
      );

      this.setState(
        {
          isUploading: true
        },
        () => {
          uploadReportDocuments(
            compressedFile,
            organization.organizationID,
            selectedSiteKey,
            selectedReportKey,
            false,
            false,
            false,
            undefined,
            null,
            currentQuestionIndex,
            undefined,
            'photo'
          )
            .then(res => {
              if (photoIndex === null || photoIndex === undefined) {
                photos.push(res.path);
                downloadedImage.push(res.downloadURL);
              } else {
                photos[photoIndex!] = res.path;
                downloadedImage[photoIndex] = res.downloadURL;
              }
              if (_captureTargetSKUKey) {
                this.handleInputSKUInventory(
                  currentQuestionIndex,
                  _captureTargetSKUKey,
                  photos,
                  'photos',
                  selectedReport.questions[currentQuestionIndex],
                  downloadedImage
                );
              }
              this.props.dispatch(
                reportActions.setAnswerPhotos(currentQuestionIndex, photos, downloadedImage, currentConditionIndex!)
              );
              this.saveQuestionnaireDebounced();
              this.setState({
                isUploading: false
              });
            })
            .catch(() => {
              this.props.dispatch(toastActions.createToast(t('common:alert.uploadFail'), false, 5000));
              this.setState({
                isUploading: false
              });
            });
        }
      );
    } catch (error) {
      if (error) {
        errorLogger(auth.uid, profile.organization, selectedSiteKey, selectedReportKey, error, 'Questionnaire.tsx');
      }
    }
    this.props.dispatch(reportActions.setCameraActive(false));
    this.props.dispatch(reportActions.setTemporaryPhotoURI(null));
    this.setState({
      _isUploadingCV: false,
      _captureTargetSKUKey: null,
      _editingPhotoIndex: null,
      _showModal: false,
      _footerModal: false
    });
  };

  handleImageEdited = async (uri: string, skuKey?: string | undefined, currentConditionIndex?: number | string) => {
    const { selectedSiteKey, selectedReportKey, selectedReport, currentQuestionIndex, auth, profile } = this.props;
    try {
      let photos: string[] = [];
      if (
        skuKey &&
        selectedReport.questions[currentQuestionIndex].skuInventory &&
        selectedReport.questions[currentQuestionIndex].skuInventory![skuKey]!.photos
      ) {
        photos = selectedReport.questions[currentQuestionIndex].skuInventory![skuKey]!.photos!.slice();
      } else if (typeof currentConditionIndex === 'number') {
        if (
          selectedReport.questions[currentQuestionIndex].conditionalQuestion!.conditions[
            selectedReport.questions[currentQuestionIndex].answer
          ][currentConditionIndex].photos
        ) {
          const reportQuestion = selectedReport.questions[currentQuestionIndex];
          const answerKey = reportQuestion.answer;
          photos = reportQuestion.conditionalQuestion!.conditions[answerKey][currentConditionIndex].photos!.slice();
        }
      } else if (selectedReport.questions[currentQuestionIndex].photos) {
        photos = selectedReport.questions[currentQuestionIndex].photos.slice();
      }
      // let questionNumberSerial = padZeroes(currentQuestionIndex + 1, 3);
      // if (selectedReport.questions[currentQuestionIndex].isConditional && typeof currentConditionIndex === 'number') {
      //   // Q001-CQ001-001-timestamp
      //   questionNumberSerial += `-CQ${padZeroes(currentConditionIndex! + 1, 3)}-${padZeroes(
      //     this.state._editingPhotoIndex + 1,
      //     3
      //   )}`;
      // }
      // const serverTime = firebase.database().getServerTime();
      // const timestamp = moment(serverTime).format('YYYYMMDD-HHmmss');
      // const photoURI =
      //   Platform.OS === 'android'
      //     ? // eslint-disable-next-line max-len
      //       `file://${RNFS.ExternalDirectoryPath}/${selectedSiteKey}/${selectedReportKey}/Q${questionNumberSerial}-${timestamp}.jpg`
      //     : // eslint-disable-next-line max-len
      //       `${RNFS.DocumentDirectoryPath}/${selectedSiteKey}/${selectedReportKey}/Q${questionNumberSerial}-${timestamp}.jpg`;
      // // Create directory if it does not exist
      // await RNFS.mkdir(
      //   Platform.OS === 'android'
      //     ? `${RNFS.ExternalDirectoryPath}/${selectedSiteKey}/${selectedReportKey}/`
      //     : `${RNFS.DocumentDirectoryPath}/${selectedSiteKey}/${selectedReportKey}/`
      // );
      // await RNFS.copyFile(uri, photoURI);
      // await RNFS.unlink(uri);
      photos[this.state._editingPhotoIndex!] = uri;

      if (skuKey) {
        this.handleInputSKUInventory(
          currentQuestionIndex,
          skuKey,
          photos,
          'photos',
          selectedReport.questions[currentQuestionIndex]
        );
      } else {
        this.props.dispatch(reportActions.setAnswerPhotos(currentQuestionIndex, photos, currentConditionIndex));
      }
      this.saveQuestionnaireDebounced();
    } catch (error) {
      if (error) {
        errorLogger(auth.uid, profile.organization, selectedSiteKey, selectedReportKey, error, 'Questionnaire.tsx');
      }
    }
    this.setState({ _editingPhotoIndex: null, _showModal: false });
  };

  handleDeletePhoto = async (photoIndex: number, skuKey?: string, currentConditionIndex?: number | string) => {
    const { selectedReport, currentQuestionIndex } = this.props;
    let photos: string[] = [];
    let downloadedImage: string[] = [];
    if (
      skuKey &&
      selectedReport.questions[currentQuestionIndex].skuInventory &&
      selectedReport.questions[currentQuestionIndex].skuInventory![skuKey]!.photos
    ) {
      photos = selectedReport.questions[currentQuestionIndex].skuInventory![skuKey]!.photos!.slice();
      downloadedImage = selectedReport.questions[currentQuestionIndex].skuInventory![skuKey]!.downloadedImage!.slice();
    } else if (typeof currentConditionIndex === 'number') {
      if (
        selectedReport.questions[currentQuestionIndex].conditionalQuestion!.conditions[
          selectedReport.questions[currentQuestionIndex].answer
        ][currentConditionIndex].photos
      ) {
        const reportQuestion = selectedReport.questions[currentQuestionIndex];
        const answerKey = reportQuestion.answer;
        photos = reportQuestion.conditionalQuestion!.conditions[answerKey][currentConditionIndex].photos!.slice();
        downloadedImage =
          reportQuestion.conditionalQuestion!.conditions[answerKey][currentConditionIndex].downloadedImage!.slice();
      }
    } else if (selectedReport.questions[currentQuestionIndex].photos) {
      photos = selectedReport.questions[currentQuestionIndex].photos.slice();
      downloadedImage = selectedReport.questions[currentQuestionIndex].downloadedImage.slice();
    }
    if (photos && photos[photoIndex]) {
      photos.splice(photoIndex, 1);
      downloadedImage.splice(photoIndex, 1);
    }
    if (skuKey) {
      this.handleInputSKUInventory(
        currentQuestionIndex,
        skuKey,
        photos,
        'photos',
        selectedReport.questions[currentQuestionIndex]
      );
    } else {
      this.props.dispatch(
        reportActions.setAnswerPhotos(currentQuestionIndex, photos, downloadedImage, currentConditionIndex)
      );
    }
  };

  saveInventoryStatus = async () => {
    // const serverTime = firebase.database().getServerTime();

    const finalInventory = updateInventoryData(
      moment(),
      this.props.selectedSiteKey,
      this.props.selectedSite,
      this.props.selectedReportKey,
      this.props.selectedReport,
      this.props.auth,
      this.props.siteSKUs
    );

    if (finalInventory.status === -1) {
      return;
    } else if (finalInventory.status === 0) {
      return Promise.resolve();
    }

    // eslint-disable-next-line max-len
    const inventoryRef = `/inventory/${this.props.profile.organization}/${this.props.selectedSiteKey}/${this.props.selectedReportKey}`;

    if (Object.keys(finalInventory).length > 0) {
      return this.props.firebase.update(inventoryRef, finalInventory.data);
    }
    return Promise.resolve();
  };

  // Save the questionnaire results to AsyncStorage
  saveQuestionnaire = async () => {
    const {
      selectedSiteKey,
      selectedReportKey,
      selectedReport,
      dispatch,
      selectedSite,
      isConnected,
      profile,
      reportSections,
      auth,
      journey
    } = this.props;

    try {
      // const serverTime = firebase.database().getServerTime();
      const now = moment().toISOString(true);
      if (!selectedSite.isMultiSite) {
        //update cache report
        dispatch(
          reportcacheThunks.updateCacheReport(selectedSiteKey, selectedReportKey, {
            ...selectedReport,
            datetimeUpdated: now
          } as Report)
        );

        if (isConnected) {
          let reportRef = `/report/${profile.organization}/${selectedSiteKey}/${selectedReportKey}`;

          //handle if site is in a journey
          if (journey) {
            // eslint-disable-next-line max-len
            reportRef = `/reportJourney/${profile.organization}/${journey.code}/${selectedSiteKey}/${selectedReportKey}`;
          }

          const reportUpdates: Partial<Report> = {
            datetimeUpdated: now,
            questions: selectedReport.questions
          };
          return this.props.firebase.update(reportRef, reportUpdates);
        }
      } else {
        const reportSection = reportSections![(selectedReport as ReportSection).siteChild];
        if (this.state._completedCount === selectedReport.questions.length) {
          this.handleFinishSection(true);
        } else {
          dispatch(
            reportcacheThunks.setReportSection(
              selectedSiteKey,
              selectedReportKey,
              (selectedReport as ReportSection).siteChild,
              {
                ...reportSection,
                datetimeOut: '',
                datetimeUpdated: now,
                questions: selectedReport.questions
              } as ReportSection
            )
          );

          if (isConnected) {
            let ref = `/reportSection/${profile.organization}/${selectedSiteKey}/${selectedReportKey}/${
              (selectedReport as ReportSection).siteChild
            }/${auth.uid}`;

            //handle if site is in a journey, TODO: make sure if this is necessary
            if (journey) {
              // eslint-disable-next-line max-len
              ref = `/reportJourneySection/${profile.organization}/${
                journey.code
              }/${selectedSiteKey}/${selectedReportKey}/${(selectedReport as ReportSection).siteChild}/${auth.uid}`;
            }
            return this.props.firebase.update(ref, {
              questions: selectedReport.questions,
              datetimeOut: '',
              datetimeUpdated: now
            });
          }
        }
      }
      return Promise.resolve();
    } catch (error) {
      if (error) {
        errorLogger(
          auth.uid,
          profile.organization,
          selectedSiteKey,
          selectedReportKey,
          error,
          'Questionnaire.tsx/saveQuestionnaire'
        );
      }
    }
  };

  // Submit the questionnaire results to Firebase
  handleSubmitQuestionnaire = async () => {
    this.setState({ _isBusy: true });
    const { selectedSite, selectedReport, selectedSiteKey, selectedReportKey, auth, profile } = this.props;

    if (this.props.isTutorialMode) {
      this.setState({ isShowTooltipReview: false });
    }

    try {
      // Go through each question to ensure the photos are saved in the proper array order
      const destructQuestions = destructConditionalQuestions(selectedReport.questions);
      destructQuestions.forEach((question: any, questionIndex: number) => {
        const index = question.parentAnswer ? question.parentIndex : question.parentNumber;
        const currentConditionIndex = question.parentAnswer ? question.no : '';
        if (question.photos) {
          const photos = question.photos.slice();
          const downloadedImage = question.downloadedImage.slice();

          question.photos.forEach((photo: any, photoIndex: number) => {
            if (!photo) {
              question.photos.splice(photoIndex, 1);
            }
          });

          question.downloadedImage.forEach((photo: any, photoIndex: number) => {
            if (!photo) {
              question.downloadedImage.splice(photoIndex, 1);
            }
          });

          this.props.dispatch(
            reportActions.setAnswerPhotos(
              index,
              photos,
              downloadedImage,
              currentConditionIndex,
              question.parentAnswer ? question.parentAnswer! : undefined
            )
          );
        }
        if (question.videos) {
          const videos = question.videos.slice();
          question.videos.forEach((video: any, videoIndex: number) => {
            if (!video) {
              question.videos.splice(videoIndex, 1);
            }
          });
          this.props.dispatch(
            reportActions.setAnswerVideos(
              index,
              videos,
              currentConditionIndex,
              question.parentAnswer ? question.parentAnswer! : undefined
            )
          );
        }
        // Convert string to number if there's invalid decimal value for range flag
        if (question.type === 'range-flag') {
          if (typeof question.answer !== 'number' && question.answer !== 'not-applicable') {
            this.props.dispatch(reportActions.inputAnswerText(index, Number(question.answer), currentConditionIndex));
          }
        }
      });
      this.saveQuestionnaire();

      this.saveInventoryStatus();
      this.setState({ _isBusy: false });

      return this.props.navigation.navigate('SubmitReport', {
        siteName: selectedSite.name,
        originTab: this.props.route.params.originTab || 'HomeTab',
        profileColor: this.props.profileColor
      });
    } catch (error) {
      console.log(error);
      if (error) {
        errorLogger(
          auth.uid,
          profile.organization,
          selectedSiteKey,
          selectedReportKey,
          error,
          'Questionnaire.tsx/handleSubmitQuestionnaire'
        );
      }
    }
  };

  handleFinishSection = async (isTriggered?: boolean) => {
    const { selectedReport, selectedSiteKey, selectedReportKey, dispatch, navigation, auth, profile } = this.props;
    this.setState({ _isBusy: true });
    // const serverTime = firebase.database().getServerTime();
    const now = moment().toISOString(true);
    const destructQuestions = destructConditionalQuestions(selectedReport.questions);
    destructQuestions.forEach((question: any, questionIndex: number) => {
      const index = question.parentAnswer ? question.parentIndex : question.parentNumber;
      const currentConditionIndex = question.parentAnswer ? question.no : '';
      if (question.photos) {
        const photos = question.photos.slice();
        const downloadedImage = question.downloadedImage.slice();

        question.photos.forEach((photo: any, photoIndex: number) => {
          if (!photo) {
            question.photos.splice(photoIndex, 1);
          }
        });

        question.downloadedImage.forEach((photo: any, photoIndex: number) => {
          if (!photo) {
            question.downloadedImage.splice(photoIndex, 1);
          }
        });

        this.props.dispatch(
          reportActions.setAnswerPhotos(
            index,
            photos,
            downloadedImage,
            currentConditionIndex,
            question.parentAnswer ? question.parentAnswer! : undefined
          )
        );
      }
      if (question.videos) {
        const videos = question.videos.slice();
        question.videos.forEach((video: any, videoIndex: number) => {
          if (!video) {
            question.videos.splice(videoIndex, 1);
          }
        });
        this.props.dispatch(
          reportActions.setAnswerVideos(
            index,
            videos,
            currentConditionIndex,
            question.parentAnswer ? question.parentAnswer! : undefined
          )
        );
      }
      // Convert string to number if there's invalid decimal value for range flag
      if (question.type === 'range-flag') {
        if (typeof question.answer !== 'number' && question.answer !== 'not-applicable') {
          this.props.dispatch(reportActions.inputAnswerText(index, Number(question.answer), currentConditionIndex));
        }
      }
    });
    dispatch(
      reportcacheThunks.setReportSection(
        selectedSiteKey,
        selectedReportKey,
        (selectedReport as ReportSection).siteChild,
        {
          ...selectedReport,
          status: 'waiting_for_upload',
          datetimeOut: now,
          datetimeUpdated: now
        } as ReportSection
      )
    );
    const ref = `/reportSection/${profile.organization}/${selectedSiteKey}/${selectedReportKey}/${
      (selectedReport as ReportSection).siteChild
    }/${selectedReport?.doneBy}`;

    firebase
      .database()
      .ref(ref)
      .update({ ...selectedReport, status: 'waiting_for_upload', datetimeUpdated: now, datetimeOut: now });

    this.setState({ _isBusy: false });
    if (!isTriggered) {
      navigation.pop();
    }
  };

  handleInput = (
    name: string,
    index: number,
    input: any,
    condition?: number | string,
    type?: any,
    question?: Question,
    skuKey?: string
  ) => {
    switch (name) {
      case 'choice':
        this.handleSelectChoice(index, input, condition!);
        break;
      case 'checklist':
        this.handleSelectChecklist(index, input, condition!);
        break;
      case 'answer':
        this.handleInputAnswer(index, input, condition!);
        break;
      case 'comment':
        this.handleInputComment(index, input, condition!);
        break;
      case 'number':
        this.handleInputDecimal(index, input, condition!);
        break;
      case 'score':
        this.handleInputScore(index, input, condition!);
        break;
      case 'range-flag':
        this.handleInputDecimal(index, input, condition!);
        break;
      case 'inventory':
        this.handleInputInventory(index, input, type, question!);
        break;
      case 'inventory-v2':
        this.handleInputSKUInventory(index, skuKey!, input, type, question!);
        break;
      case 'competitor':
        this.handleInputCompetitorAnalysis(index, input, type);
        break;
      case 'weighted-choice':
        this.handleSelectWeightedChoice(index, input, condition!);
        break;
      default:
        break;
    }
  };

  handleCloseQuestionnaireModal = () => {
    this.setState({ _showModal: false, _footerModal: false });
  };

  handleDismissTutorialQuestionModal = () => {
    this.props.dispatch(tutorialActions.showTutorialQuestionModal(false));
  };

  handleDismissTutorialMode = async () => {
    await this.props.dispatch(dismissTutorialMode());
    this.handleDismissTutorialQuestionModal();
    this.props.navigation.reset({
      index: 0,
      routes: [{ name: 'HomeTab' }]
    });
  };

  render() {
    const { _isTransitioned } = this.state;
    const {
      organization,
      selectedReport,
      currentQuestionIndex,
      siteSKUs,
      calibratingSKU,
      language,
      selectedSite: { isMultiSite },
      hasNotch,
      t
    } = this.props;

    if (!_isTransitioned || !isLoaded(calibratingSKU)) {
      return (
        <View style={styles.loading}>
          <ActivityIndicator color={theme.colors.primary} size="large" />
          <Text>{t('questionnaire:container.loadingQuestionnaire')}</Text>
        </View>
      );
    }

    let selectedCalibratingSKU = calibratingSKU;
    let selectedSiteSKUs = siteSKUs;

    if (this.props.isTutorialMode) {
      selectedCalibratingSKU = { '-skuTutorial': true };
      selectedSiteSKUs = SKUsTutorialData;
    }

    return (
      <QuestionnaireContainer
        showFooterModal={this.state._footerModal}
        isUploading={this.state.isUploading}
        showWarningModal={this.state._showWarningModal}
        modalWarningConfig={this.state._modalWarningConfig}
        handleMoveToPrevQuestion={this.handleMoveToPrevQuestion}
        handleTakeFromGallery={this.handlePhotoCaptured}
        handleCloseCamera={this.handleCloseCamera}
        handleAcivateCamera={this.handleAcivateCamera}
        showCamera={this.state._showCamera}
        showModal={this.state._showModal}
        hasNotch={hasNotch}
        isUploadingCV={this.state._isUploadingCV}
        isBusy={this.state._isBusy}
        isMultiSite={isMultiSite}
        currentQuestionIndex={currentQuestionIndex}
        translation={this.props.t}
        selectNewFile={this.handleUploadDocuments}
        handleTakePhoto={this.handleTakePhoto}
        isTransitioned={this.state._isTransitioned}
        isShowTooltipReview={this.state.isShowTooltipReview}
        isShowTooltipNA={this.state.isShowTooltipNA}
        isTutorialMode={this.props.isTutorialMode}
        isShowQuestionTutorialModal={this.props.isShowQuestionTutorialModal}
        calibratingSKU={selectedCalibratingSKU}
        completedCount={this.state._completedCount}
        editingPhotoIndex={this.state._editingPhotoIndex}
        language={language}
        modalConfig={this.state._modalConfig}
        selectedReport={selectedReport}
        organization={organization}
        currentInventory={this.state.currentInventory}
        skuQuery={this.state.skuQuery}
        currentQuestionIndex={currentQuestionIndex}
        currentConditionIndex={this.state._currentConditionIndex}
        _handleFail={this._handleFail}
        siteSKUs={selectedSiteSKUs}
        onCloseModalWarning={this.handleCloseModalWarning}
        profileColor={this.props.profileColor}
        featureAccessVideo={this.props.featureAccessVideo}
        handleDismissTooltipReview={this.handleDismissTooltipReview}
        handleSKUQueryChanged={this.handleSKUQueryChanged}
        handleUploadDocuments={this.handleUploadDocuments}
        handleEditUploadFile={this.handleEditUploadFile}
        handleNavPlaybackVideo={this.handleNavPlaybackVideo}
        handleConfirmEditPhoto={this.handleConfirmEditPhoto}
        handleCloseQuestionnaireModal={this.handleCloseQuestionnaireModal}
        handleSubmitQuestionnaire={this.handleSubmitQuestionnaire}
        handleMoveToNextQuestion={this.handleMoveToNextQuestion}
        handleNavReference={this.handleNavReference}
        handleNavRecordVideo={this.handleNavRecordVideo}
        handleNavCreateCompany={this.handleNavCreateCompany}
        saveQuestionnaireDebounced={this.saveQuestionnaireDebounced}
        handleFinishSection={this.handleFinishSection}
        handleActivateImagePicker={this.handleActivateImagePicker}
        handleSetRemedies={this.handleSetRemedies}
        handleInput={this.handleInput}
        handleChangeTutorialQuestionType={this.handleChangeTutorialQuestionType}
        handleShowTutorialQuestionModal={this.handleShowTutorialQuestionModal}
        handleShowTooltipNA={this.handleShowTooltipNA}
        handleDismissTutorialQuestionModal={this.handleDismissTutorialQuestionModal}
        handleDismissTutorialMode={this.handleDismissTutorialMode}
        tutorialQuestionType={this.props.tutorialQuestionType}
        userRole={this.props.userRole}
      />
    );
  }
}

type QuestionnaireState = {
  _isTransitioned: boolean;
  _isBusy: boolean;
  _isUploadingCV: boolean;
  isShowTooltipReview: boolean;
  isShowTooltipNA: boolean;
  _captureTargetSKUKey: string | null;
  _editingPhotoIndex: any;
  _editingVideoIndex: number | null;
  _showModal: boolean;
  _modalConfig: ModalConfig;
  _invalidPhotos: number[][];
  _invalidConditionalPhotos: number[][][][];
  _completedCount: number;
  currentInventory: any | null;
  skuQuery: string;
  _showCamera: boolean;
};

type OwnProps = { isFocused: boolean };
type FirebaseProps = ReturnType<typeof mapStateToFirebase>;
type StateProps = ReturnType<typeof mapStateToProps>;
type EnhancedProps = FirebaseProps & StateProps & ConnectedDispatch & FirebaseConnect & WithTranslation;
type QuestionnaireProps = OwnProps & QuestionnairePageProps & EnhancedProps;

type ProfileColor = {
  [key: string]: string;
};

type RootStateExtend = RootState & { profileColor: ProfileColor };

const mapStateToFirebase = (state: RootState) => ({
  profile: state.firebase.profile,
  isTutorialMode: state.tutorial.isTutorialMode,
  selectedSiteKey: state.site.selectedSiteKey as string,
  selectedReport: state.report.selectedReport as Report | ReportSection,
  siteSKUs: state.sitedata[state.site.selectedSiteKey!] && state.sitedata[state.site.selectedSiteKey!].siteSKU,
  journey: state.journey.journey
});

const mapFirebaseToState = (props: FirebaseProps) => {
  if (!props.selectedReport || !props.selectedSiteKey || props.isTutorialMode) {
    return [];
  }

  const inventoryQuestion = props.selectedReport.questions.find(q => q.type === 'inventory-v2');
  if (!inventoryQuestion || !inventoryQuestion.skuInventory) {
    return [];
  }

  const skuKeys = Object.keys(inventoryQuestion.skuInventory);
  const skuCalibratingQueries = [];
  for (const skuKey of skuKeys) {
    const isZero = props.siteSKUs && props.siteSKUs[skuKey] && props.siteSKUs[skuKey].stock === 0;
    // As long as we have an SKU at 0 stock, pull the latest inventory history
    if (isZero) {
      const inventoryReportQuery = {
        path: `/inventory/${props.profile.organization}/${props.selectedSiteKey}`,
        storeAs: 'inventoryReportCalibrating',
        // start at not null
        queryParams: ['limitToLast=1']
      };
      skuCalibratingQueries.push(inventoryReportQuery);
      return skuCalibratingQueries;
    }
  }
  return [];
};

const mapStateToProps = (state: RootStateExtend) => ({
  auth: state.firebase.auth,
  deviceProfile: state.account.deviceProfile,
  organization: state.organization.myOrganization,
  selectedSite: state.site.selectedSite as InjectedSite,
  selectedReportKey: state.report.selectedReportKey as string,
  isShowQuestionTutorialModal: state.tutorial.showModalTutorial,
  currentQuestionIndex: state.report.questionIndex,
  language: state.firebase.profile.language || 'en',
  reportSections: state.reportcache.multiReports[state.site.selectedSiteKey!]
    ? state.reportcache.multiReports[state.site.selectedSiteKey!][state.report.selectedReportKey!]
    : null,
  latestInventory: getVal(state.firebase.data, 'latestInventory') as SiteInventory | null,
  isConnected: state.network.isConnected,
  hasNotch: state.account.hasNotch,
  calibratingData: state.firebase.data.inventoryReportCalibrating,
  calibratingSKU: state.report.selectedReportCalibratingSKU,
  calibratingSKUTimestamp: state.report.selectedReportCalibratingSKUTimestamp,
  cameraActive: state.report.cameraActive,
  temporaryPhotoURI: state.report.temporaryPhotoURI,
  profileColor: state.profiletheme.color,
  tutorialQuestionType: state.tutorial.tutorialQuestionType,
  featureAccessVideo: state.featureAccess.features[enums.Features.PHOTO_ATTACHMENT_ANNOTATION],
  userRole: state.account.role
});

const enhance = compose(
  connect(mapStateToFirebase),
  firebaseConnect(mapFirebaseToState),
  connect(mapStateToProps),
  withTranslation(['questionnaire', 'common'])
);

export default enhance(Questionnaire) as any;

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
});
