import { Animated, Easing } from 'react-native';

/**
 * Begins an infinite animation loop that takes 3s per cycle
 * @param anim - The animated value to cycle
 */
export default function cycleAnimation(anim: Animated.Value) {
  Animated.sequence([
    Animated.timing(anim, {
      toValue: 100,
      duration: 1500,
      easing: Easing.linear,
      useNativeDriver: true
    }),
    Animated.timing(anim, {
      toValue: 0,
      duration: 1500,
      easing: Easing.linear,
      useNativeDriver: true
    })
  ]).start(event => {
    if (event.finished) {
      cycleAnimation(anim);
    }
  });
}
