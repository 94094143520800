import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {
  StyleSheet,
  View,
  Platform,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableWithoutFeedbackProps,
  Alert
} from 'react-native';

// components
import Text from '../global/Text';

// utils
import fontMaker from '../../utils/font';

import { SCREEN_WIDTH } from '../../utils/screensize';

// types
import { Site } from '../../utils/classes';
import { JourneySiteListProps } from './type';

const JourneySiteList = (props: JourneySiteListProps) => {
  // props
  const { journeySites, visitedSites, handleNavSite } = props;

  // state
  const [addressContainerHeights, setAddressContainerHeights] = useState<Record<string, number>>({});

  //get every address container heights to set arrow heights
  const handleAddressContainerOnLayout = (event: any, siteId: string) => {
    const { height } = event.nativeEvent.layout;
    const newAddressContainerHeights = { ...addressContainerHeights };
    newAddressContainerHeights[siteId] = height;
    setAddressContainerHeights(newAddressContainerHeights);
  };

  return (
    <View style={styles.sitesContainer} testID="journey-site-list">
      {journeySites.map((site: Site, index: number) =>
        site.siteID ? (
          <Touchable
            onPress={() => handleNavSite(site.siteID || '', site, 0, null)}
            key={site.siteID}
            style={styles.siteContainer}
            testID={`journey-site-item-${index}`}
          >
            <View style={{ flexDirection: 'row' }}>
              <View style={styles.markerContainer}>
                <Icon name="map-marker" size={26} color={'#fc4b5e'}></Icon>
                {index !== journeySites.length - 1 && (
                  <>
                    <View style={[styles.arrow, { height: addressContainerHeights[site.siteID] }]} />
                    <View style={styles.arrowHead} />
                  </>
                )}
              </View>
              <View style={styles.siteTextContainer}>
                <Text style={styles.siteText}>{site.name}</Text>
                <View
                  style={styles.addressContainer}
                  onLayout={e => handleAddressContainerOnLayout(e, site.siteID || '')}
                >
                  <Text style={styles.addressText}>{site.address}</Text>
                </View>
              </View>
              {visitedSites.includes(site.siteID) && (
                <View>
                  <Icon name="check-circle" size={26} color={'#56da6a'}></Icon>
                </View>
              )}
            </View>
          </Touchable>
        ) : null
      )}
    </View>
  );
};

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
  Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

export default JourneySiteList as any;

const styles = StyleSheet.create({
  sitesContainer: {
    padding: 10,
    marginBottom: 150
  },
  siteContainer: {
    flexDirection: 'row',
    width: '100%'
  },
  markerContainer: {
    alignItems: 'center'
  },
  arrow: {
    borderWidth: 0.5,
    width: 1,
    marginVertical: 5
  },
  arrowHead: {
    borderRightWidth: 1,
    borderBottomWidth: 1,
    height: 5,
    width: 5,
    position: 'absolute',
    bottom: 5,
    transform: [{ rotate: '45deg' }]
  },
  siteTextContainer: {
    flexGrow: 1,
    marginHorizontal: 5,
    marginTop: 4
  },
  siteText: {
    fontSize: 16
  },
  addressContainer: {
    // screen width minus arrow icon width
    width: SCREEN_WIDTH - 30,
    marginVertical: 5
  },
  addressText: {
    ...fontMaker({ weight: 'Light' }),
    fontSize: 12
  }
});
