import { all, fork, takeEvery, takeLatest } from 'redux-saga/effects';
import { fetchRoleAsync, fetchRoleSaga } from './reducers/account/account';
import { logActivityAsync } from './reducers/activity/activity.action';
//activity
import { logActivityActionSaga } from './reducers/activity/activity.actionSaga';
import {
  createCompanyAsync,
  createCompanySaga,
  createProductAsync,
  createProductCategoryAsync,
  createProductCategorySaga,
  createProductSaga,
  createProductTargetMarketAsync,
  createProductTargetMarketSaga,
  createPromotionAsync,
  createPromotionSaga,
  fetchCompaniesAsync,
  fetchCompaniesSaga,
  fetchProductAsync,
  fetchProductCategoriesAsync,
  fetchProductCategoriesSaga,
  fetchProductSaga,
  fetchProductTargetMarketAsync,
  fetchProductTargetMarketSaga
} from './reducers/company/company';
import {
  fetchAllDepartmentIndexAsync,
  fetchAllDepartmentIndexSaga,
  fetchDepartmentMapAsync,
  fetchDepartmentMapSaga,
  fetchDepartmentsAsync,
  fetchDepartmentsSaga
} from './reducers/department/department';
import { fetchFeatureAccessAsync } from './reducers/featureAccess/featureAccess.action';
import { fetchFeatureAccess } from './reducers/featureAccess/featureAccess.actionSaga';
import {
  createJourneyReportAsync,
  createJourneyReportSaga,
  getJourneyReportSummaryAsync,
  getJourneyReportSummarySaga,
  updateJourneyReportAsync,
  updateJourneyReportSaga
} from './reducers/journey/journey';
// journey schdule
import journeyScheduleSaga from './reducers/journeySchedule/journeySchedule.actionSaga';
import { fetchSiteMessageBoardAsync } from './reducers/messageBoard/messageBoard.action';
import { fetchSiteMessageBoard } from './reducers/messageBoard/messageBoard.saga';
import { createNPSIsSubmittedAsync, fetchNPSUserAsync } from './reducers/nps/nps.action';
import { createNPSIsSubmittedSaga, fetchNPSSaga } from './reducers/nps/nps.saga';
import { fetchMyOrganizationAsync, fetchMyOrganizationSaga } from './reducers/organization/organization';
import { profileStatisticWatcher } from './reducers/profile/statistic/saga';
import { fetchQuestionnaireIndex } from './reducers/questionnaireIndex/questionnaireIndex.action';
import { fetchQuestionnaireIndexSaga } from './reducers/questionnaireIndex/questionnaireIndex.actionSaga';
import {
  fetchQuestionnairesAsync,
  getPopulatedQuestionnairesAsync
} from './reducers/questionnaires/questionnaire.action';
import { fetchQuestionnaires, getPopulatedQuestionnaires } from './reducers/questionnaires/questionnaire.actionSaga';
import { fetchSiteSalesTargetAsync } from './reducers/sales/sales.action';
import { fetchSiteSalesTarget } from './reducers/sales/sales.actionSaga';
import { fetchSitesAsync, fetchSitesSaga } from './reducers/site/site';
import { fetchSiteReportsAsync } from './reducers/siteReport/siteReport.action';
import { fetchSiteReport } from './reducers/siteReport/siteReport.actionSaga';
//site schedule
import siteScheduleSaga from './reducers/siteSchedule/siteSchedule.actionSaga';
import { submitApiReportAsync } from './reducers/submitReport/submitReport.action';
import { submitReportSaga } from './reducers/submitReport/submitReport.actionSaga';
import { fetchUsersAsync, fetchUsersSaga } from './reducers/user/user';

export function* rootSaga() {
  yield all([takeEvery(fetchRoleAsync.request, fetchRoleSaga)]);
  yield all([takeEvery(fetchMyOrganizationAsync.request, fetchMyOrganizationSaga)]);
  yield all([takeEvery(fetchDepartmentsAsync.request, fetchDepartmentsSaga)]);
  yield all([takeEvery(fetchDepartmentMapAsync.request, fetchDepartmentMapSaga)]);
  yield all([takeEvery(fetchAllDepartmentIndexAsync.request, fetchAllDepartmentIndexSaga)]);
  yield all([takeEvery(createJourneyReportAsync.request, createJourneyReportSaga)]);
  yield all([takeEvery(updateJourneyReportAsync.request, updateJourneyReportSaga)]);
  yield all([takeEvery(getJourneyReportSummaryAsync.request, getJourneyReportSummarySaga)]);
  yield all([takeEvery(fetchCompaniesAsync.request, fetchCompaniesSaga)]);
  yield all([takeEvery(createCompanyAsync.request, createCompanySaga)]);
  yield all([takeEvery(fetchProductCategoriesAsync.request, fetchProductCategoriesSaga)]);
  yield all([takeEvery(createProductCategoryAsync.request, createProductCategorySaga)]);
  yield all([takeEvery(createPromotionAsync.request, createPromotionSaga)]);
  yield all([takeEvery(createProductAsync.request, createProductSaga)]);
  yield all([takeEvery(fetchUsersAsync.request, fetchUsersSaga)]);
  yield all([takeEvery(fetchSitesAsync.request, fetchSitesSaga)]);
  yield all([takeEvery(getPopulatedQuestionnairesAsync.request, getPopulatedQuestionnaires)]);
  yield all([takeEvery(fetchQuestionnairesAsync.request, fetchQuestionnaires)]);
  yield fork(profileStatisticWatcher);
  yield all([takeEvery(fetchProductAsync.request, fetchProductSaga)]);
  yield all([takeEvery(fetchFeatureAccessAsync.request, fetchFeatureAccess)]);
  yield all([takeEvery(fetchQuestionnaireIndex.request, fetchQuestionnaireIndexSaga)]);
  yield all([takeEvery(fetchProductTargetMarketAsync.request, fetchProductTargetMarketSaga)]);
  yield all([takeEvery(createProductTargetMarketAsync.request, createProductTargetMarketSaga)]);
  yield all([takeEvery(logActivityAsync.request, logActivityActionSaga)]);
  yield all([takeEvery(fetchNPSUserAsync.request, fetchNPSSaga)]);
  yield all([takeEvery(createNPSIsSubmittedAsync.request, createNPSIsSubmittedSaga)]);
  yield all([takeLatest(fetchSiteMessageBoardAsync.request, fetchSiteMessageBoard)]);
  yield takeLatest(submitApiReportAsync.request, submitReportSaga);
  yield all([takeLatest(fetchSiteSalesTargetAsync.request, fetchSiteSalesTarget)]);
  yield all([takeLatest(fetchSiteReportsAsync.request, fetchSiteReport)]);
  yield all([fork(siteScheduleSaga)]);
  yield all([fork(journeyScheduleSaga)]);
}
