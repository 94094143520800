import React, { useMemo } from 'react';
import { ScrollView, Platform, StatusBar } from 'react-native';
import SignatureCard from './SignatureCard';
import SelfieSignatureCard from './SelfieSignatureCard';
import { Signature, SelfieSignature } from '../../utils/classes';

import Tooltip from 'react-native-walkthrough-tooltip';
import TooltipContent, { TooltipBtnContent } from '../global/tutorial/TooltipContent';
import { setTutorialMode } from '../../store/reducers/tutorial';
import { useTranslation } from 'react-i18next';

export type SubmitReportSignatureProps = {
  language: string;
  signatures: Signature[];
  selfieSignatures: SelfieSignature[] | null;
  signaturesValidation: boolean[];
  selfieSignatureValidation: boolean | null;
  showTooltipSignature: boolean;
  isTutorialMode: boolean;
  isBusy: boolean;
  handleDrawSignIndex: (signIdx: number) => void;
  handleSignTextInput: (i: number, key: string, v: string) => void;
  handleDrawOptions: (signIdx: number) => void;
  handleNextTutorial: () => void;
  handleActivateImagePicker: () => void;
};

/** Signature Section Part on the Submit Report Screen  */
const SubmitReportSignature = (props: SubmitReportSignatureProps) => {
  const { t } = useTranslation(['submitReport', 'common']);
  const {
    signatures,
    signaturesValidation,
    language,
    handleNextTutorial,
    isBusy,
    selfieSignatures,
    selfieSignatureValidation
  } = props;

  const tooltipBtnContent: TooltipBtnContent = useMemo(() => {
    return {
      mainBtn: {
        title: t('submitReport:tutorial.reportSignature.next'),
        onPress: handleNextTutorial
      },
      skipBtn: {
        title: t('submitReport:tutorial.reportSignature.end'),
        onPress: () => setTutorialMode(false)
      }
    };
  }, [handleNextTutorial]);

  return (
    <ScrollView horizontal={true} style={{ maxWidth: 768 }}>
      {selfieSignatures && selfieSignatures.length ? (
        <SelfieSignatureCard
          handleActivateImagePicker={props.handleActivateImagePicker}
          language={language}
          selfieSignatures={selfieSignatures}
          selfieSignatureValidation={selfieSignatureValidation}
        />
      ) : null}
      {signatures.map((signature, i) => {
        return (
          <Tooltip
            key={`tooltip-${i + 1}`}
            isVisible={props.isTutorialMode && props.showTooltipSignature}
            topAdjustment={Platform.OS === 'android' ? -StatusBar.currentHeight! : 0}
            placement="top"
            content={
              <TooltipContent
                text={t('submitReport:tutorial.reportSignature.signHere')}
                mainBtn={tooltipBtnContent.mainBtn}
                skipBtn={tooltipBtnContent.skipBtn}
              />
            }
            onClose={handleNextTutorial}
          >
            <SignatureCard
              key={`sign-${i + 1}`}
              isSigned={signature.isSigned}
              hasError={props.isTutorialMode ? false : !signaturesValidation[i]}
              imageLink={signature.path}
              isBusy={isBusy}
              language={language}
              name={signature.name}
              position={signature.position}
              signatureValidation={signaturesValidation[i]}
              handleDrawSignature={() => props.handleDrawSignIndex(i)}
              handleDrawOptions={() => props.handleDrawOptions(i)}
              handleTextInput={(key: string) => (v: string) => props.handleSignTextInput(i, key, v)}
            />
          </Tooltip>
        );
      })}
    </ScrollView>
  );
};
export default SubmitReportSignature;
