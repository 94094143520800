import { isEmpty } from 'react-redux-firebase';

import { ScheduledSiteInfo } from '../../../store/reducers/schedulesites';
import { PendingSites } from '../../../store/reducers/reportsubmit';

import { ReportSummary, OrganizationReportIndex, AllSites, UserRole } from '../../../utils/classes';
import { getDistance } from '../../../utils/geolocation';

/**
 * Generate list of sites and seperate the sites into different categories
 * @param mySitesScheduled raw sites retrieved from database
 * @param pendingSites sites that have unuploaded report
 * @param cacheReportIndex
 * @param currentCoordinates
 * @param allSites list of all sites to be filtered for ad-hoc
 * @param role the user's role including permissions map
 * @param t translation function
 * @param isDummy if this logic is used for jest test
 */
function generateSites(
  mySitesScheduled: ScheduledSiteInfo[],
  pendingSites: PendingSites,
  cacheReportIndex: OrganizationReportIndex,
  currentCoordinates: any,
  allSites: AllSites | null | undefined,
  role: UserRole | null | undefined,
  t: any,
  isDummy: boolean
) {
  const _tempAssigned: ScheduledSiteInfo[] = [];
  const _tempCompleted: ScheduledSiteInfo[] = [];
  const _tempSections = [];
  const _tempUploadPending: ScheduledSiteInfo[] = [];

  let currentSummary: ReportSummary;

  const sectionTitle: { [key: number]: string } = {
    0: t('schedule:sitesReadyToUpload'),
    1: t('schedule:sitesAssigned'),
    2: t('schedule:sitesCompleted')
  };

  mySitesScheduled.forEach(scheduledSite => {
    if (
      pendingSites &&
      pendingSites[scheduledSite?.siteKey] &&
      (!isEmpty(pendingSites[scheduledSite?.siteKey]?.pendingMulti) ||
        !isEmpty(pendingSites[scheduledSite?.siteKey]?.pendingSingle))
    ) {
      scheduledSite.isPendingUpload = true;
      _tempUploadPending.push(scheduledSite);
    } else if (
      scheduledSite.isScheduledThisPeriod &&
      (!scheduledSite.isCompletedToday || isDummy) &&
      (!scheduledSite.isCompletedThisPeriod || isDummy)
    ) {
      if (cacheReportIndex && cacheReportIndex[scheduledSite?.siteKey]) {
        // intercept local draft immediately break from inner for loop if found
        for (const reportKey of Object.keys(cacheReportIndex[scheduledSite?.siteKey])) {
          currentSummary = cacheReportIndex[scheduledSite?.siteKey][reportKey];
          if (currentSummary.status === 'draft') {
            scheduledSite.isDraft = true;
            break;
          }
        }
      }
      _tempAssigned.push(scheduledSite);
    } else {
      _tempCompleted.push(scheduledSite);
    }
  });

  [_tempUploadPending, _tempAssigned, _tempCompleted].forEach((arr, i) => {
    if (arr && arr.length > 0) {
      _tempSections.push({ title: sectionTitle[i], data: arr });
    }
  });
  if (
    role?.resourceMap &&
    role?.resourceMap['application:report:adhoc'] &&
    role?.resourceMap['application:report:adhoc']?.create &&
    allSites &&
    Object.keys(allSites).length > 0
  ) {
    const siteDistances: {
      [key: string]: number;
    } = {};
    let mutatedAdhocSite: ScheduledSiteInfo;
    const adhocSiteInfo: ScheduledSiteInfo[] = Object.keys(allSites)
      .filter(siteKey => {
        const site = allSites[siteKey];
        if (!currentCoordinates) {
          return false;
        }
        if (!site.coordinates) {
          return true;
        }

        const distance = getDistance(currentCoordinates, site.coordinates);
        siteDistances[siteKey] = distance || 99999;
        return !!distance && distance < 5;
      })
      .map(siteKey => {
        const site = allSites[siteKey];
        mutatedAdhocSite = {
          siteProfile: site,
          isScheduledThisPeriod: true,
          siteKey: site.siteID!,
          scheduledCount: 0,
          completedCount: 0
        };
        return mutatedAdhocSite;
      })
      .sort((a, b) => {
        const aDistance = siteDistances[a.siteKey];
        const bDistance = siteDistances[b.siteKey];

        return aDistance - bDistance;
      });
    _tempSections.push({ title: t('schedule:nearby') as string, data: adhocSiteInfo });
  }

  return _tempSections;
}

export default generateSites;
