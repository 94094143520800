import { contrastTextColor } from '../utils/color';

const fontSize = {
  h1: 20,
  h2: 18,
  h3: 14,
  h4: 12,
  body: 12,
  bodyStrong: 12,
  bodySmall: 10,
  buttonSmall: 38
};

const fontType = {
  base: 'Poppins',
  light: 'Poppins-Light',
  lightItalic: 'Poppins-LightItalic',
  bold: 'Poppins-Bold',
  semiBold: 'Poppins-SemiBold',
  semiBoldItalic: 'Poppins-SemiBoldItalic',
  boldItalic: 'Poppins-BoldItalic',
  italic: 'Poppins-Italic',
  medium: 'Poppins-Medium',
  mediumItalic: 'Poppins-MediumItalic',
  regular: 'Poppins-Regular'
};

export const colors = {
  primary: '#3cd070',
  primaryLight: '#78ffa0',
  primaryDark: '#009e43',
  secondary: '#ff6f00',
  secondaryLight: '#ffa040',
  secondaryDark: '#c43e00',
  tertiary: '#00695C',
  tertiaryLight: '#439889',
  tertiaryDark: '#003D33',
  lightgray: '#c4c4c4',
  secondarylightgray: '#e2e2e2',
  gray: '#a8a8aa',
  darkgray: '#535353',
  light: '#efeeed',
  dark: '#535353',
  navigation: '#3cd070',
  semanticError: '#ed5565',
  nimblyPurple: '#574fcf',
  nimblyGreen: '#3BD070',
  danger: '#FF5E6F',
  lightgreen: '#56C768',
  secondarylightgreen: '#D4EFDE',
  green: '#0ABA26',
  lightyellow: '#F6BB42',
  secondarylightpurple: '#867FF8',
  black: '#000000',
  lightBlue: '#6EA2EA',
  darkgreen: '#00AB4E',
  white: '#FFFFFF',
  primaryRed: '#FF354A'
};

const theme = {
  colors: colors,
  styles: {
    backgroundPrimary: {
      backgroundColor: '#3cd070'
    },
    borderPrimary: {
      borderColor: '#3cd070'
    },
    textContrastPrimary: {
      color: contrastTextColor('#3cd070')
    },
    textPrimary: {
      color: '#3cd070'
    },
    backgroundSecondary: {
      backgroundColor: '#ff6f00'
    },
    borderSecondary: {
      borderColor: '#ff6f00'
    },
    textContrastSecondary: {
      color: contrastTextColor('#ff6f00')
    },
    textSecondary: {
      color: '#ff6f00'
    },
    backgroundDark: {
      backgroundColor: '#535353'
    },
    borderDark: {
      borderColor: '#535353'
    },
    textDark: {
      color: '#535353'
    },
    textContrastDark: {
      color: contrastTextColor('#535353')
    },
    backgroundNavigation: {
      backgroundColor: '#3cd070'
    },
    borderNavigation: {
      borderColor: '#3cd070'
    },
    textContrastNavigation: {
      color: contrastTextColor('#3cd070')
    },
    backgroundTabs: {
      backgroundColor: '#535353'
    },
    textContrastTabs: {
      color: contrastTextColor('#535353')
    },
    fontColor: {
      basic: '#535353',
      headline: '#25282B',
      other: '#A0A4A8'
    },
    h1: {
      fontFamily: fontType.base,
      fontSize: fontSize.h1,
      lineHeight: 26,
      color: colors.black
    },
    h2: {
      fontFamily: fontType.base,
      fontSize: fontSize.h2,
      lineHeight: 25,
      color: colors.black
    },
    h3: {
      fontFamily: fontType.base,
      fontSize: fontSize.h3,
      lineHeight: 19,
      color: colors.black
    },
    h4: {
      fontFamily: fontType.semiBold,
      fontSize: fontSize.h4,
      lineHeight: 16,
      color: colors.black
    },
    body: {
      fontFamily: fontType.base,
      fontSize: fontSize.body,
      lineHeight: 18,
      color: colors.darkgray
    },
    bodyStrong: {
      fontFamily: fontType.medium,
      fontSize: fontSize.bodyStrong,
      lineHeight: 22,
      color: colors.darkgray
    },
    bodySmall: {
      fontFamily: fontType.base,
      fontSize: fontSize.bodySmall,
      lineHeight: 18,
      color: colors.darkgray
    }
  },
  buttonStyles: {
    small: {
      fontSize: fontSize.bodySmall
    }
  }
};

export const TOOLTIP_MAIN_BTN_COLOR = theme.colors.nimblyPurple;
export const TOOLTIP_SKIP_BTN_COLOR = theme.colors.semanticError;
export const DIVIDER_COLOR = theme.colors.lightgray;

export default theme;
