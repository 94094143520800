import { SiteInventory, SKUInventory, Site } from '../../../utils/classes';
import { InjectedSiteStatus } from '../../../typing/types';

/**
 * The inventory node fetched for the last 2.
 * - One from ongoing report.
 * - One from one latest report before current.
 * - If only one data available it supposed to be from this report
 *
 * @param state application state
 * @param currentInventory
 * @param questionsLength length of questionnaire
 * @param selectedSite
 * @param selectedReportKey
 * @param latestInventory
 */
function updateQuestionnaireState(
  state: any,
  currentInventory: any,
  completedLength: number,
  selectedSite: Site & {
    status: InjectedSiteStatus;
    title: string;
    nextDueDate: string | null;
    previousEndDate: string | null;
    reportsRemaining: number;
    hasInventory: boolean;
    isQuestionnaireSchedule: boolean;
  },
  selectedReportKey: string,
  latestInventory: SiteInventory | null
) {
  state._completedCount = completedLength;
  if (currentInventory === undefined) {
    if (!selectedSite.hasInventory) {
      return { ...state, currentInventory: null };
    }
    if (!latestInventory) {
      return { ...state, currentInventory: null };
    }
    const keys = Object.keys(latestInventory).sort();
    if (keys.length === 1 && latestInventory[selectedReportKey]) {
      return { ...state, currentInventory: null };
    }
    if (keys.length === 2) {
      let actual: SKUInventory;
      if (keys[1] === selectedReportKey) {
        actual = latestInventory[keys[0]];
      } else {
        actual = latestInventory[keys[1]];
      }
      return { ...state, currentInventory: actual };
    }
    return { ...state, currentInventory: null };
  }
  return { ...state };
}

export default updateQuestionnaireState;
