import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect, isLoaded, getVal } from 'react-redux-firebase';
import moment from 'moment';

import {
  Animated,
  Platform,
  StyleSheet,
  TouchableOpacity,
  TouchableNativeFeedback,
  TouchableWithoutFeedbackProps,
  View,
  ActivityIndicator
} from 'react-native';

import Text from '../global/Text';

import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import { SCREEN_WIDTH } from '../../utils/screensize';
import { round, numberWithCommas } from '../../utils/number';
import fontMaker from '../../utils/font';
import theme from '../../constants/theme';

import { ConnectedDispatch, FirebaseConnect } from '../../typing/types';
import { RootState } from '../../store/reducers';

// TODO: list possible color sequences on reach full progress
const barColorSequence = [theme.colors.primary, theme.colors.primaryDark];

class SiteSalesSummary extends React.Component<SiteSalesSummaryProps, SiteSalesSummaryState> {
  constructor(props: SiteSalesSummaryProps) {
    super(props);
    this.state = {
      _isLoaded: false,
      _order: 0,
      completed: 0,
      salesTargetToDate: 0,
      salesTargetTotal: 0,
      viewType: 'today',
      daysLeft: moment()
        .endOf('month')
        .diff(moment(), 'days'),
      // Bug: Android scaleX 0 value glitches out. Set to 0.001 temporarily.
      animation: new Animated.Value(0.0001),
      progress: 0,
      repetition: 0
    };
  }

  static getDerivedStateFromProps(props: SiteSalesSummaryProps, state: SiteSalesSummaryState) {
    if (!state._isLoaded && isLoaded(props.salesTarget) && isLoaded(props.salesFigureCumulative)) {
      const completed = props.salesFigureCumulative != null ? props.salesFigureCumulative : 0;
      const sales = props.salesTarget != null ? props.salesTarget : 0;
      if (sales > 0) {
        const salesTargetTotal = sales;
        // Recalculate total based on daysToDate with total daysInMonth
        const daysInMonth = moment().daysInMonth();
        const daysToDate = daysInMonth - state.daysLeft;
        const salesTargetToDate = Math.round(salesTargetTotal * (daysToDate / daysInMonth));
        const denominator = state.viewType === 'today' ? salesTargetToDate : salesTargetTotal;
        return {
          _isLoaded: true,
          completed,
          salesTargetToDate,
          salesTargetTotal,
          progress: denominator === 0 ? 1 : Math.max((completed * 1.0) / denominator, 0),
          repetition: denominator === 0 ? 0 : Math.ceil(completed / Math.max(denominator, 1))
        };
      } else {
        return {
          _isLoaded: true
        };
      }
    }
    return null;
  }

  componentDidMount() {
    if (this.state._isLoaded) {
      const fullBar = 1;
      const overlapProgress = this.state.progress % fullBar;
      const count = this.state.repetition - 1;

      for (let i = 0; i <= count; i++) {
        setTimeout(() => {
          Animated.timing(this.state.animation, {
            toValue: i === count ? overlapProgress : fullBar,
            duration: 500,
            useNativeDriver: true
          }).start(() => {
            if (i !== count) {
              this.state.animation.setValue(0.0001);
              this.setState({ _order: i + 1 });
            }
          });
        }, i * 500);
      }
    }
  }

  componentDidUpdate(prevProps: SiteSalesSummaryProps, prevState: SiteSalesSummaryState) {
    if (this.state._isLoaded && !prevState._isLoaded) {
      const fullBar = 1;
      const overlapProgress = this.state.progress % fullBar;
      const count = this.state.repetition - 1;

      for (let i = 0; i <= count; i++) {
        setTimeout(() => {
          Animated.timing(this.state.animation, {
            toValue: i === count ? overlapProgress : fullBar,
            duration: 500,
            useNativeDriver: true
          }).start(() => {
            if (i !== count) {
              this.state.animation.setValue(0.0001);
              this.setState({ _order: i + 1 });
            }
          });
        }, i * 500);
      }

      if (this.state.viewType !== prevState.viewType) {
        const { completed, salesTargetToDate, salesTargetTotal } = this.state;

        const denominator = this.state.viewType === 'today' ? salesTargetToDate : salesTargetTotal;
        const progress = denominator === 0 ? 1 : Math.max((completed * 1.0) / denominator, 0);
        const repetition = denominator === 0 ? 0 : Math.ceil(completed / Math.max(denominator, 1));

        const fullBar2 = 1;
        const overlapProgress2 = progress % fullBar2;
        const count2 = repetition - 1;

        for (let i = 0; i <= count2; i++) {
          setTimeout(() => {
            Animated.timing(this.state.animation, {
              toValue: i === count2 ? overlapProgress2 : fullBar2,
              duration: 500,
              useNativeDriver: true
            }).start(() => {
              if (i !== count2) {
                this.state.animation.setValue(0.0001);
                this.setState({ _order: i + 1 });
              }
            });
          }, i * 500);
        }

        this.setState({ progress, repetition });
      }
    }
  }

  handleSalesView = (type: 'mtd' | 'today') => {
    this.setState({ viewType: type });
  };

  render() {
    const {
      _isLoaded,
      _order,
      daysLeft,
      completed,
      salesTargetToDate,
      salesTargetTotal,
      progress,
      viewType
    } = this.state;

    const innerWidth = SCREEN_WIDTH * 0.65;
    const progressBarCompleteStyle = [
      styles.progressBarComplete,
      theme.styles.backgroundPrimary,
      { width: innerWidth }
    ];
    const textStyle = {
      color: progress < 0.45 ? theme.colors.primaryDark : '#fff'
    };

    if (!_isLoaded) {
      return (
        <View style={styles.loading}>
          <ActivityIndicator size="small" color={theme.colors.primary} />
        </View>
      );
    }

    if (_isLoaded && completed === 0 && (salesTargetToDate === 0 || salesTargetTotal === 0)) {
      return null;
    }

    return (
      <View style={styles.content}>
        <View style={styles.header}>
          <Text allowFontScaling={false} style={styles.title}>
            SALES
          </Text>
          <View style={styles.salesTabs}>
            <View style={[styles.salesTab, viewType === 'today' ? styles.selectedSalesTab : {}]}>
              <Touchable onPress={() => this.handleSalesView('today')}>
                <Text
                  allowFontScaling={false}
                  style={[styles.viewTypeText, viewType === 'today' ? styles.selectedViewtTypeText : {}]}
                >
                  To-Date
                </Text>
              </Touchable>
            </View>
            <View style={[styles.salesTab, viewType === 'mtd' ? styles.selectedSalesTab : {}]}>
              <Touchable onPress={() => this.handleSalesView('mtd')}>
                <Text
                  allowFontScaling={false}
                  style={[styles.viewTypeText, viewType === 'mtd' ? styles.selectedViewtTypeText : {}]}
                >
                  Month
                </Text>
              </Touchable>
            </View>
          </View>
        </View>
        <View style={styles.salesContainer}>
          <View style={styles.days}>
            <View style={styles.clock}>
              <Icon name={'clock-outline'} size={13} color={theme.colors.gray} />
            </View>
            <Text allowFontScaling={false} style={styles.daysText}>{`${daysLeft} day${
              daysLeft > 1 ? 's' : ''
            } left`}</Text>
          </View>
          <Text allowFontScaling={false} style={styles.salesText}>
            {numberWithCommas(completed)} /{' '}
            {numberWithCommas(viewType === 'today' ? salesTargetToDate : salesTargetTotal)} IDR
          </Text>
        </View>
        <View style={styles.progressContainer}>
          <Animated.View
            style={[
              styles.progressBar,
              {
                backgroundColor:
                  _order === 0 ? 'rgba(0, 0, 0, 0.08)' : barColorSequence[(_order - 1) % barColorSequence.length]
              }
            ]}
          >
            <Animated.View
              style={[
                ...progressBarCompleteStyle,
                {
                  transform: [
                    {
                      translateX: this.state.animation.interpolate({
                        inputRange: [0, 1],
                        outputRange: [innerWidth / -2, 0]
                      })
                    },
                    { scaleX: this.state.animation }
                  ],
                  backgroundColor: barColorSequence[_order % barColorSequence.length]
                }
              ]}
            />
            <Text allowFontScaling={false} style={[styles.progressText, textStyle]}>
              {round(progress * 100)}%
            </Text>
          </Animated.View>
        </View>
      </View>
    );
  }
}

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
  Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

type OwnProps = {
  siteKey: string;
};

type FirebaseProps = ReturnType<typeof mapStateToFirebase>;

type StateProps = {
  salesFigureCumulative: number;
  salesTarget: number;
};

type SiteSalesSummaryState = {
  _isLoaded: boolean;
  _order: number;
  completed: number;
  salesTargetToDate: number;
  salesTargetTotal: number;
  viewType: 'today' | 'mtd';
  daysLeft: number;
  animation: Animated.Value;
  progress: number;
  repetition: number;
};

type EnhancedProps = StateProps & ConnectedDispatch & FirebaseConnect;

type SiteSalesSummaryProps = OwnProps & EnhancedProps;

const mapStateToFirebase = (state: RootState) => ({
  profile: state.firebase.profile
});

const mapFirebaseToState = (props: OwnProps & FirebaseProps) => {
  const month = moment().format('YYYY-MM');
  return [
    {
      path: `/siteSalesTarget/${props.profile.organization}/target/${props.siteKey}/${month}`,
      storeAs: 'siteSalesTarget'
    },
    {
      path: `/siteSalesTarget/${props.profile.organization}/figureCumulative/${props.siteKey}/${month}`,
      storeAs: 'siteSalesFigureCumulative'
    }
  ];
};

const mapStateToProps = (state: RootState): StateProps => ({
  salesTarget: getVal(state.firebase.data, 'siteSalesTarget') as number,
  salesFigureCumulative: getVal(state.firebase.data, 'siteSalesFigureCumulative') as number
});

const enhance = compose(connect(mapStateToFirebase), firebaseConnect(mapFirebaseToState), connect(mapStateToProps));

export default enhance(SiteSalesSummary) as any;

const styles = StyleSheet.create({
  loading: {
    justifyContent: 'flex-end',
    height: 42.5,
    paddingBottom: 8
  },
  content: {
    flexDirection: 'column',
    marginTop: 10,
    paddingTop: 14,
    borderTopWidth: 1,
    borderColor: theme.colors.light
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 14
  },
  title: {
    ...fontMaker({ weight: 'SemiBold' }),
    color: theme.colors.gray,
    paddingBottom: 2
  },
  days: {
    flexDirection: 'row'
  },
  daysText: {
    color: theme.colors.gray,
    fontSize: 12
  },
  clock: {
    top: 1,
    paddingRight: 3
  },
  salesContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 4
  },
  salesText: {
    color: theme.colors.dark,
    fontSize: 11
  },
  progressContainer: {
    alignItems: 'center'
  },
  progressBar: {
    width: '100%',
    height: 15,
    marginBottom: 5,
    borderRadius: 20,
    overflow: 'hidden'
  },
  progressBarComplete: {
    height: 15,
    backgroundColor: theme.colors.primary,
    borderRadius: 20
  },
  progressText: {
    position: 'absolute',
    width: '100%',
    fontSize: 12,
    lineHeight: 15,
    textAlign: 'center'
  },
  salesTabs: {
    flexDirection: 'row',
    borderRadius: 14,
    backgroundColor: theme.colors.light,
    // Shadow
    shadowColor: '#efeeed',
    elevation: 2,
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.8,
    shadowRadius: 3
  },
  salesTab: {
    paddingVertical: 4,
    paddingHorizontal: 6,
    minWidth: 70,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 14,
    backgroundColor: theme.colors.light
  },
  selectedSalesTab: {
    backgroundColor: '#ffffff'
  },
  viewTypeText: {
    fontSize: 11,
    fontWeight: '400',
    color: theme.colors.gray
  },
  selectedViewtTypeText: {
    color: theme.colors.primary
  }
});
