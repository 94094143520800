import React, { FC } from 'react';
import { Modal, StyleSheet, View, ActivityIndicator } from 'react-native';

import Text from '../global/Text';

import fontMaker from '../../utils/font';
import { useTranslation } from 'react-i18next';

type QuestionnaireUploadCVModalProps = {
  isVisible: boolean;
  language: string;
};

const QuestionnaireUploadCVModal: FC<QuestionnaireUploadCVModalProps> = props => {
  const { isVisible } = props;
  const { t } = useTranslation(['questionnare']);
  return (
    <Modal animationType="fade" transparent={true} visible={isVisible}>
      <View style={styles.overlay}>
        <View style={styles.container}>
          <ActivityIndicator size="large" />
          <Text style={styles.loadingText}>{t('questionnaire:uploadCVModal.loadingAI')}</Text>
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  overlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  container: {
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingHorizontal: 32,
    paddingVertical: 16,
    borderRadius: 5,
    overflow: 'hidden'
  },
  loadingText: {
    ...fontMaker({ weight: 'Light' }),
    textAlign: 'center',
    marginVertical: 12
  }
});

export default QuestionnaireUploadCVModal;
