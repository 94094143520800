import { useFocusEffect } from '@react-navigation/native';
import React, { useEffect } from 'react';
import { ActivityIndicator, BackHandler, StyleSheet, ToastAndroid, View, Image } from 'react-native';
import RNExitApp from 'react-native-exit-app';
import { useDispatch, useSelector } from 'react-redux';
import { FirebaseReducer, isLoaded } from 'react-redux-firebase';
import ConnectionStatus from '../components/global/ConnectionStatus';
import Welcome from '../components/home/Welcome';
import theme from '../constants/theme';
import NimblyLogo from '../assets/img/nimbly-logo.png';
import { RootState } from '../store/reducers';
import { accountActions } from '../store/reducers/account/account';
import { departmentActions } from '../store/reducers/department/department';
import * as reportcacheThunks from '../store/reducers/reportcache/reportcache.actionThunk';
import { scheduleSitesActions } from '../store/reducers/schedulesites';
import { siteActions } from '../store/reducers/site/site';
import { Organization, User } from '../utils/classes';
import { getLogos } from '../utils/getCustomLogo';
import { toastActions } from '../store/reducers/toast';
import { useTranslation } from 'react-i18next';

const HomeTab = () => {
  const isFetchingMySites = useSelector<RootState, boolean>(state => state.scheduleSites.isFetchingMySites);
  const profile = useSelector<RootState, FirebaseReducer.Profile<User>>(state => state.firebase.profile);
  const language = useSelector<RootState, 'en' | 'id'>(state => state.firebase.profile.language);
  const featureAccessLoading = useSelector<RootState, boolean>(state => state.featureAccess.isLoading);
  const organization = useSelector<RootState, Organization | null | undefined>(
    state => state.organization.myOrganization
  );

  const dispatch = useDispatch();
  const { t } = useTranslation(['home, common']);
  const [_isCheckingCache, setIsCheckingCache] = React.useState(false);
  const [customLogos, setCustomLogos] = React.useState<any>({ useNimblyLogo: true });

  // Pressing the back button twice will kill the app
  const [_isExiting, setIsExiting] = React.useState(false);

  // Load data from API sagas on component mount
  useEffect(() => {
    dispatch(accountActions.fetchRoleAsync.request(undefined));
    dispatch(siteActions.fetchSitesAsync.request(undefined));
    fetchLogos();
  }, []);

  const fetchLogos = async () => {
    if (!organization?.organizationID) return;

    try {
      const res = await getLogos(organization.organizationID);
      if (!res || !Object.keys(res).length) return;
      setCustomLogos(res.webAuditLogo);
    } catch (err) {
      dispatch(toastActions.createToast(t('home:homePageError'), true));
    }
  };

  // Load data from API sagas on component focus
  useFocusEffect(
    React.useCallback(() => {
      dispatch(departmentActions.fetchDepartmentMapAsync.request(undefined));
      if (!isFetchingMySites) {
        return;
      }
      dispatch(scheduleSitesActions.setIsFetchingMySites(false));
    }, [])
  );

  useFocusEffect(
    React.useCallback(() => {
      const message =
        language === 'en' ? 'Press the Back button again to exit' : 'Tekan tombol Kembali lagi untuk keluar';
      const onBackPress = () => {
        if (!_isExiting) {
          ToastAndroid.showWithGravity(message, ToastAndroid.LONG, ToastAndroid.BOTTOM);
          setIsExiting(true);
          // Reset state after the toast disappears and the back button is not pressed again
          setTimeout(() => setIsExiting(false), 6000);
        } else {
          RNExitApp.exitApp();
        }
        return true;
      };

      BackHandler.addEventListener('hardwareBackPress', onBackPress);

      return () => BackHandler.removeEventListener('hardwareBackPress', onBackPress);
    }, [_isExiting])
  );

  React.useEffect(() => {
    if (isLoaded(organization)) {
      dispatch(reportcacheThunks.cleanLocalExpiredReports(organization?.schedule));
      setIsCheckingCache(true);
    }
  }, []);

  React.useEffect(() => {
    if (isLoaded(organization) && !_isCheckingCache) {
      dispatch(reportcacheThunks.cleanLocalExpiredReports(organization?.schedule));
      setIsCheckingCache(true);
    }
  }, [organization]);

  if (!isLoaded(organization) || !profile.isLoaded || featureAccessLoading) {
    return (
      <View
        style={{
          display: 'flex',
          flex: 1,
          alignContent: 'center',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <ActivityIndicator color={theme.colors.primaryDark} size="large" />
      </View>
    );
  }

  return (
    <View style={styles.root}>
      <View style={styles.containerLogo}>
        <Image
          resizeMode="stretch"
          style={styles.logo}
          source={{ uri: customLogos?.useNimblyLogo ? NimblyLogo : customLogos?.downloadURL }}
        />
      </View>
      <ConnectionStatus fromHome={true} />
      <View style={styles.container}>
        <Welcome />
      </View>
    </View>
  );
};

export default HomeTab;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#ffff'
  },
  container: {
    flex: 1,
    backgroundColor: '#efeeed'
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerLogo: {
    marginVertical: 15,
    marginLeft: 24,
    height: 50,
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  logo: {
    flex: 1,
    width: '100%',
    height: '100%',
    resizeMode: 'contain'
  }
});
