import { createReducer, ActionType } from 'typesafe-actions';
import * as actions from './sales.action';
import { SalesTargetState } from './type';

export type SalesTargetAction = ActionType<typeof actions>;

const initialState: SalesTargetState = {
  completed: 0,
  salesTargetTotal: 0,
  salesTargetToDate: 0,
  isLoading: false,
  error: ''
};

export const salesReducer = createReducer<SalesTargetState, SalesTargetAction>(initialState)
  .handleAction(actions.fetchSiteSalesTargetAsync.request, state => ({
    ...state,
    error: null,
    isLoading: true
  }))
  .handleAction(actions.fetchSiteSalesTargetAsync.success, (state, action) => ({
    ...state,
    completed: action.payload.completed,
    salesTargetTotal: action.payload.salesTargetTotal,
    salesTargetToDate: action.payload.salesTargetToDate,
    isLoading: false
  }))
  .handleAction(actions.fetchSiteSalesTargetAsync.failure, (state, action) => ({
    ...state,
    error: action.payload.error,
    isLoading: false
  }));
