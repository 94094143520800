import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Animated, Modal, StyleSheet, View, Easing, TouchableOpacity } from 'react-native';
import moment from 'moment';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import Text from '../../../global/Text';
import fontMaker from '../../../../utils/font';

interface Props {
  isVisibile: boolean;
  selectedMonth: number;
  selectMonth: (month: number) => void;
  closeModal: () => void;
}

const SiteCalendarMonthPickerModal = (props: Props) => {
  const { selectedMonth, isVisibile, selectMonth, closeModal } = props;

  const [containerHeight, setContainerHeight] = useState<number>(0);
  const slideUpValue = useRef(new Animated.Value(0)).current;
  const slideDownValue = useRef(new Animated.Value(1)).current;

  const getTransformCondition = useMemo(() => {
    if (isVisibile) {
      return {
        translateY: slideUpValue.interpolate({
          inputRange: [0, 1],
          outputRange: [200, 0]
        })
      };
    } else {
      return {
        translateY: slideDownValue.interpolate({
          inputRange: [0, 1],
          outputRange: [containerHeight, 0]
        })
      };
    }
  }, [isVisibile]);

  const renderMonth = useMemo(() => {
    const month = moment().startOf('year');
    const months = [];
    const capitalizeFirstLetter = (word: string) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    };
    for (let i = 0; i < 12; i++) {
      months.push(
        <TouchableOpacity
          style={[styles.monthContainer, i === selectedMonth ? styles.selected : null]}
          onPress={() => {
            selectMonth(i);
            closeModal();
          }}
        >
          <Text style={styles.monthText}>{capitalizeFirstLetter(month.format('MMMM'))}</Text>
        </TouchableOpacity>
      );
      month.add(1, 'month');
    }

    return months;
  }, [selectedMonth]);

  // handle animation slide up
  useEffect(() => {
    if (isVisibile) {
      Animated.timing(slideUpValue, {
        toValue: 1,
        duration: 300,
        useNativeDriver: true,
        easing: Easing.ease
      }).start();
    }
  }, [isVisibile]);

  return (
    <Modal visible={isVisibile} transparent={true} animationType="fade">
      {/* <TouchableWithoutFeedback onPress={Keyboard.dismiss}> */}
      <View style={styles.root}>
        <Animated.View
          onLayout={(event: any) => {
            const { height } = event.nativeEvent.layout;
            setContainerHeight(height);
          }}
          style={[
            styles.container,
            {
              transform: [getTransformCondition]
            }
          ]}
        >
          <View>
            <View style={styles.close}>
              <Text style={styles.title}>{moment().format('YYYY')}</Text>
              <TouchableOpacity onPress={() => closeModal()} testID="outlet-footer-close">
                <Icon name="close" size={25} />
              </TouchableOpacity>
            </View>
          </View>

          <View style={styles.body}>{renderMonth}</View>
        </Animated.View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  root: { backgroundColor: 'rgba(0,0,0,0.4)', flex: 1 },
  container: {
    position: 'absolute',
    bottom: 0,
    width: '100%',

    // based on height of container to wrapped all component
    backgroundColor: '#fff',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,

    // box shadow
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5
    },
    shadowOpacity: 0.36,
    shadowRadius: 6.68,

    elevation: 11
  },
  body: { backgroundColor: '#ffffff', paddingBottom: 38, paddingTop: 20 },
  close: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 18,
    paddingLeft: 24,
    paddingRight: 17,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20
  },
  title: {
    fontSize: 18,
    lineHeight: 27,
    ...fontMaker({ weight: 'SemiBold' })
  },
  monthContainer: {
    height: 40,
    justifyContent: 'center'
  },
  monthText: {
    paddingHorizontal: 20,
    fontSize: 14,
    lineHeight: 19
  },
  selected: {
    backgroundColor: '#E6FAED'
  }
});

export default SiteCalendarMonthPickerModal;
