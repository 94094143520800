import React from 'react';
import {
  StyleSheet,
  View,
  ScrollView,
  Dimensions,
  Platform,
  TouchableWithoutFeedbackProps,
  TouchableOpacity,
  TouchableNativeFeedback,
  TextInput
} from 'react-native';
import Text from '../global/Text';
import { QuestionnaireQuestionProps } from './QuestionnaireQuestion';
import QuestionnaireQuestionInventoryNewCard from './QuestionnaireQuestionInventoryNewCard';
import fontMaker from '../../utils/font';
import theme from '../../constants/theme';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { useTranslation } from 'react-i18next';
import QuestionnaireQuestionTutorialModal from './QuestionnaireQuestionTutorialModal';

const screen = Dimensions.get('screen');
const { height, width } = screen;

export type QuestionnaireQuestionInventoryNewProps = Pick<
  QuestionnaireQuestionProps,
  | 'question'
  | 'index'
  | 'siteSKUs'
  | 'skuQuery'
  | 'calibratingSKU'
  | 'allowAuditorStockIn'
  | 'keyboardShown'
  | 'language'
  | 'query'
  | 'scrollRef'
  | 'inventoryCardRefs'
  | 'skuFinal'
  | 'skuIn'
  | 'skuCommentVisible'
  | 'currentQuestionIndex'
  | 'isTutorialMode'
  | 'isShowQuestionTutorialModal'
  | 'tutorialQuestionType'
  | 'handleConfirmEditPhoto'
  | 'handleToggleSKUComment'
  | 'handleActivateImagePicker'
  | 'handleClearSKUQuery'
  | 'handleInputSKUQuery'
  | 'handleSelectChoice'
  | 'handleInputSKUInventory'
  | 'handleInventoryInputFocused'
  | 'handleInventoryInputBlurred'
  | 'handleShowTutorialQuestionModal'
  | 'handleChangeTutorialQuestionType'
  | 'handleDismissTutorialQuestionModal'
  | 'handleDismissTutorialMode'
>;

const QuestionnaireQuestionInventoryNew = (props: QuestionnaireQuestionInventoryNewProps) => {
  const { t } = useTranslation(['questionnaire', 'common']);
  React.useEffect(() => {
    if (props.isTutorialMode && props.index === props.currentQuestionIndex) {
      props.handleChangeTutorialQuestionType(props.question.type);
      props.handleShowTutorialQuestionModal(true);
    }
  }, [props.index, props.currentQuestionIndex]);

  if (props.question.skuInventory && props.siteSKUs && Object.keys(props.siteSKUs).length > 0) {
    return (
      <View>
        <QuestionnaireQuestionTutorialModal
          handleDismissTutorialQuestionModal={props.handleDismissTutorialQuestionModal}
          handleDismissTutorialMode={props.handleDismissTutorialMode}
          isShowQuestionTutorialModal={props.isShowQuestionTutorialModal}
          tutorialQuestionType={props.tutorialQuestionType}
          language={props.language}
        />
        <View style={styles.inventorySearchBarContainer}>
          <View style={styles.inventorySearchBar}>
            <View style={styles.searchBox}>
              <View style={styles.searchIcon}>
                <Icon name="magnify" size={22} color={theme.colors.gray} />
              </View>
              <View style={styles.searchTextContainer}>
                <TextInput
                  style={styles.searchTextInput}
                  placeholder={t('questionnaire:question.newInventory.searchSKU')}
                  multiline={false}
                  numberOfLines={1}
                  value={props.query}
                  onChangeText={text => props.handleInputSKUQuery(text)}
                  testID="inventory-new-search"
                />
              </View>
              {props.query ? (
                <Touchable onPress={props.handleClearSKUQuery} testID="clear-search" delayPressIn={0}>
                  <View style={styles.clearContainer}>
                    <View style={styles.clearCircle}>
                      <View style={styles.clearIcon}>
                        <Icon name="close" size={10} color="#fff" />
                      </View>
                    </View>
                  </View>
                </Touchable>
              ) : null}
            </View>
          </View>
        </View>
        <View style={{ marginTop: 5 }}>
          <QuestionnaireQuestionInventoryNewCard
            styles={styles}
            question={props.question}
            index={props.index}
            allowAuditorStockIn={props.allowAuditorStockIn}
            keyboardShown={props.keyboardShown}
            language={props.language}
            query={props.query}
            siteSKUs={props.siteSKUs}
            skuQuery={props.skuQuery}
            calibratingSKU={props.calibratingSKU}
            skuIn={props.skuIn}
            skuFinal={props.skuFinal}
            skuCommentVisible={props.skuCommentVisible}
            inventoryCardRefs={props.inventoryCardRefs}
            handleConfirmEditPhoto={props.handleConfirmEditPhoto}
            handleToggleSKUComment={props.handleToggleSKUComment}
            handleActivateImagePicker={props.handleActivateImagePicker}
            handleClearSKUQuery={props.handleClearSKUQuery}
            handleInputSKUQuery={props.handleInputSKUQuery}
            handleSelectChoice={props.handleSelectChoice}
            handleInputSKUInventory={props.handleInputSKUInventory}
            handleInventoryInputFocused={props.handleInventoryInputFocused}
            handleInventoryInputBlurred={props.handleInventoryInputBlurred}
            scrollRef={props.scrollRef}
          />
        </View>

        {props.keyboardShown ? <View style={styles.questionKeyboardPadding} /> : null}
      </View>
    );
  } else {
    return (
      <View style={styles.emptySKUContainer} testID="container-not-found">
        <Text style={styles.emptySKUHeaderTitle}>{t('questionnaire:question.newInventory.SKUNotFound')}</Text>
        <Text style={styles.emptySKUHeaderText}>{t('questionnaire:question.newInventory.contactAdmin')}</Text>
      </View>
    );
  }
};

export default QuestionnaireQuestionInventoryNew;

const styles = StyleSheet.create({
  inventorySearchBarContainer: {
    flex: 1,
    width: '100%',
    zIndex: 1
  },
  inventorySearchBar: {
    width: '100%',
    height: 45,
    paddingHorizontal: 15,
    paddingBottom: 10,
    backgroundColor: '#fff',
    shadowOpacity: 0.15,
    shadowOffset: { height: 5, width: 0 }
  },
  inventoryCardScroll: {
    height: height * 0.6
  },
  inventoryCardContainer: {
    flexGrow: 1,
    paddingBottom: width <= 360 ? 60 : 35
  },
  emptySKUContainer: {
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: height * 0.1
  },
  emptySKUHeaderTitle: {
    ...fontMaker({ weight: 'SemiBold' }),
    textAlign: 'center',
    lineHeight: 26,
    fontSize: 24
  },
  emptySKUHeaderText: {
    textAlign: 'center',
    lineHeight: 20,
    fontSize: 18
  },
  searchBox: {
    borderRadius: 4,
    backgroundColor: theme.colors.light,
    width: '100%',
    height: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },
  searchIcon: {
    width: '15%',
    alignItems: 'center'
  },
  searchTextContainer: {
    width: '75%'
  },
  searchTextInput: {
    ...fontMaker({ weight: 'Light' }),
    color: theme.colors.dark,
    height: '100%'
  },
  clearContainer: {
    width: '10%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  clearCircle: {
    width: 16,
    height: 16,
    borderRadius: 16 / 2,
    backgroundColor: theme.colors.gray,
    justifyContent: 'center',
    alignItems: 'center'
  },
  clearIcon: {
    position: 'relative'
  },
  questionKeyboardPadding: {
    height: 5,
    paddingVertical: 100
  }
});

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
  Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;
