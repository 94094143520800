export function capsFirstLetter(text: string, all = false): string {
  if (text === null || text === undefined) {
    return '';
  }

  text = text.toString();

  if (!all) {
    return text[0].toUpperCase() + text.slice(1);
  }
  const words = text.split(' ');

  const newWords: string[] = [];
  words.forEach((word, i) => {
    const prev = words[i - 1] ? words[i - 1] : null;
    if (prev !== null && prev === '' && word === '') {
      return false;
    }
    if (word) {
      newWords.push(word[0].toUpperCase() + word.slice(1));
    } else {
      newWords.push(word);
    }
  });
  return newWords.join(' ');
}

export function queryStringify(obj: any, prefix?: any): string {
  const pairs: any[] = [];
  for (const key in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue;
    }

    const value = obj[key];
    const enkey = encodeURIComponent(key);
    let pair;
    if (typeof value === 'object') {
      pair = queryStringify(value, prefix ? `${prefix}[${enkey}]` : enkey);
    } else {
      pair = `${prefix ? `${prefix}[${enkey}]` : enkey}=${encodeURIComponent(value)}`;
    }
    pairs.push(pair);
  }
  return pairs.join('&');
}

export function randomString(len = 10): string {
  const str = `QWERTYUIOPASDFGHJKLZXCVBNM1234567890`;
  let pass = '';

  for (let i = 0; i < len; i++) {
    const randomIndex = Math.floor(Math.random() * str.length);
    pass += str[randomIndex];
  }
  return pass;
}

export function isReservedWord(text: string) {
  return text === 'new' || text === 'edit';
}

/**
 * Appends a string digit with thousands separators
 * @param {text}
 *
 * @returns {string}
 */
export function numberWithCommas(text: string): string {
  const parts = text.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}
