import { createAsyncAction } from 'typesafe-actions';

import * as actionTypes from './messageBoard.actionTypes';
import { MessageBoard } from 'nimbly-common';

export const fetchSiteMessageBoardAsync = createAsyncAction(
  actionTypes.FETCH_SITE_MESSAGE_BOARD_REQUEST,
  actionTypes.FETCH_SITE_MESSAGE_BOARD_SUCCESS,
  actionTypes.FETCH_SITE_MESSAGE_BOARD_FAILURE
)<{ siteID: string }, { siteID: string; data: MessageBoard[] | null }, { message: string }>();
