import React from 'react';
import { Dimensions, Modal, Platform, StyleSheet, TouchableNativeFeedback, TouchableOpacity, View } from 'react-native';
import Text from '../global/Text';

import fontMaker from '../../utils/font';
import theme from '../../constants/theme';
import HollowButton from './HollowButton';
import { ModalOption } from './ConfirmModal';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

interface ApproveRejectModalProps {
  isVisible: boolean;
  language?: string;
  title: string;
  subtitle: string;
  options: ModalOption[];
  onCloseModal?: () => any;
  onConfirmDelete?: () => any;
}

const ApproveRejectModal = (props: ApproveRejectModalProps) => {
  const handleCloseModal = () => {
    props.onCloseModal();
  };

  const renderText = (option: any | { [language: string]: string }) => {
    if (!option) return '';
    if (typeof option === 'string') {
      return option;
    }
    return option.text;
  };

  const { title, subtitle, options } = props;

  return (
    <Modal animationType="fade" transparent={true} visible={props.isVisible} onRequestClose={handleCloseModal!}>
      <View style={styles.overlay}>
        <View style={styles.container}>
          <View style={styles.header}>
            <View style={styles.headerBox}>
              <Icon name="alert" size={18} color={theme.colors.lightyellow} />
              <Text style={styles.headerTitle}>{renderText(title)}</Text>
            </View>
            <Text style={styles.headerSubtitle}>{renderText(subtitle)}</Text>
          </View>
          <View style={styles.actionContainer}>
            {options.map((option, index) => (
              <View style={styles.tab} key={index}>
                <HollowButton
                  testID="reject-button"
                  primaryColor={theme.colors.nimblyPurple}
                  onPress={option.action}
                  isFilled={option.style === 'confirm'}
                  title={option.text}
                  isPressed={false}
                />
              </View>
            ))}
          </View>
        </View>
      </View>
    </Modal>
  );
};

const { width, height } = Dimensions.get('window');

const styles = StyleSheet.create({
  // Modal
  overlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  container: {
    alignItems: 'center',
    backgroundColor: '#fff',
    width: width * 0.7,
    maxWidth: 450,
    borderRadius: 5,
    maxHeight: height * 0.7,
    overflow: 'hidden'
  },
  header: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 24,
    paddingHorizontal: 24
  },
  headerTitle: {
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 18,
    marginBottom: 20,
    fontWeight: 'bold',
    marginLeft: 4
  },
  headerBox: {
    justifyContent: 'space-evenly',
    flexDirection: 'row'
  },
  headerSubtitle: {
    ...fontMaker({ weight: 'Light' }),
    textAlign: 'center',
    marginVertical: 12
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 24
  },
  row: {
    width: '100%'
  },
  tab: {
    justifyContent: 'space-between',
    paddingHorizontal: 16
  },
  option: {
    width: '100%',
    textAlign: 'center'
  },
  boldOption: {
    ...fontMaker({ weight: 'SemiBold' })
  }
});

export default ApproveRejectModal;
