import moment from 'moment';
import { useMemo } from 'react';
import { ScheduleOption } from '../../../../typing/types';
import { getTodayString } from '../../utils/schedule';
import { ScheduledQuestionnaireProps } from './ScheduledQuestionnaire/type';

export const useScheduleDateReportCount = ({
  schedules,
  isMultiSite,
  isDummy,
  isLoadingSchedule
}: {
  schedules: ScheduleOption[];
  isMultiSite: boolean;
  isDummy: boolean;
  isLoadingSchedule: boolean;
}): {
  countReportToday: ScheduledQuestionnaireProps['countReportToday'];
  countReportYesterday: ScheduledQuestionnaireProps['countReportYesterDay'];
  scheduleDateString: string;
} => {
  const { countReportToday, countReportYesterday, scheduleDateString } = useMemo(() => {
    let totalSchedulesToday = 0;
    let totalDoneToday = 0;
    let totalSchedulesYesterday = 0;
    let totalDoneYesterday = 0;
    let hasScheduleToday = false;
    let hasScheduleTomorrow = false;
    let tempDate = '';

    if (isLoadingSchedule) {
      return {
        countReportToday: { done: totalDoneToday, scheduled: totalSchedulesToday },
        countReportYesterday: { done: totalDoneYesterday, scheduled: totalSchedulesYesterday },
        scheduleDateString: ''
      };
    }

    const today = getTodayString();
    const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');

    if (!isMultiSite) {
      schedules.forEach(s => {
        const { status, isYesterdaySchedule, scheduledDates, nextDue } = s;

        // Today's schedule
        if (!isYesterdaySchedule) {
          if (status === 'finished-today' || status === 'finished-period') {
            totalDoneToday += 1;
          }
          totalSchedulesToday += 1;
        }

        // Yesterday's schedule
        if (isYesterdaySchedule) {
          if (status === 'finished-today' || status === 'finished-period') {
            totalDoneYesterday += 1;
          }
          totalSchedulesYesterday += 1;
        }

        if (scheduledDates[today]?.isComplete) {
          hasScheduleToday = true;
        } else if (scheduledDates[tomorrow]?.isComplete) {
          hasScheduleTomorrow = true;
        }

        // fallback if schedule is not for today or tomorrow
        if (nextDue && moment(nextDue).isAfter(today, 'date') && !tempDate) {
          tempDate = nextDue;
        }
      });
    }

    // Reset today's totals if dummy
    if (isDummy) {
      totalDoneToday = 0;
      totalDoneYesterday = 0;
    }

    let computedScheduleDateString = today;

    if (hasScheduleToday) {
      computedScheduleDateString = moment(today).format('MMM DD');
    } else if (hasScheduleTomorrow) {
      computedScheduleDateString = moment(tomorrow).format('MMM DD');
    } else if (tempDate) {
      computedScheduleDateString = tempDate;
    }

    return {
      countReportToday: { done: totalDoneToday, scheduled: totalSchedulesToday },
      countReportYesterday: { done: totalDoneYesterday, scheduled: totalSchedulesYesterday },
      scheduleDateString: computedScheduleDateString
    };
  }, [schedules, isMultiSite, isDummy, isLoadingSchedule]);

  return {
    countReportToday,
    scheduleDateString,
    countReportYesterday
  };
};
