import React from 'react';

import {
  StyleSheet,
  View,
  TouchableWithoutFeedbackProps,
  Platform,
  TextInput,
  TouchableOpacity,
  TouchableNativeFeedback
} from 'react-native';
import Slider from '@react-native-community/slider';
import { QuestionnaireQuestionProps } from './QuestionnaireQuestion';
import { questionTextInvalid } from '../../utils/report';
import theme from '../../constants/theme';
import fontMaker from '../../utils/font';

import Text from '../global/Text';
import { useTranslation } from 'react-i18next';
import QuestionnaireQuestionScoreAnswer from './QuestionnaireQuestionScoreAnswer';
import QuestionnaireQuestionScoreNumbers from './QuestionnaireQuestionScoreNumbers';
import QuestionnaireQuestionScoreTips from './QuestionnaireQuestionScoreTips';

export type QuestionnaireQuestionBinaryProps = Pick<
  QuestionnaireQuestionProps,
  | 'question'
  | 'index'
  | 'currentConditionIndex'
  | 'language'
  | 'isTutorialMode'
  | 'commentInputRef'
  | 'currentQuestionIndex'
  | 'handleInputComment'
  | 'handleTextInputFocused'
  | 'handleTextInputBlurred'
  | 'handleInputScore'
  | 'handleSelectChoice'
  | 'handleShowTutorialQuestionModal'
  | 'handleChangeTutorialQuestionType'
>;

export type QuestionnaireQuestionBinaryPropsExtra = {
  styles: any;
};

const QuestionnaireQuestionScore = (
  props: QuestionnaireQuestionBinaryProps & QuestionnaireQuestionBinaryPropsExtra
) => {
  const { t } = useTranslation(['questionnaire', 'common']);
  React.useEffect(() => {
    if (props.isTutorialMode && props.index === props.currentQuestionIndex) {
      props.handleChangeTutorialQuestionType(props.question.type);
      props.handleShowTutorialQuestionModal(true);
    }
  }, [props.index, props.currentQuestionIndex]);

  React.useEffect(() => {
    setComment(props.question.comment);
  }, [props.question.answer]);
  // temporary fix to handle input flicker and moving cursor in RN 0.63.3
  // TODO: revert this once the bug is handled, or there is a permanent fix
  const [comment, setComment] = React.useState<string>(props.question.comment);
  const handleInputComment = (text: string) => {
    setComment(text);
    props.handleInputComment(props.index, text, props.currentConditionIndex!);
  };

  return (
    <View>
      {!props.question.answerRequired ? (
        <View style={props.styles.choicesList}>
          <Touchable
            onPress={() => props.handleSelectChoice(props.index, 'not-applicable', props.currentConditionIndex!)}
            testID={'score-na'}
          >
            <View
              style={
                props.question.answer === 'not-applicable'
                  ? [props.styles.choice, props.styles.choiceSelected]
                  : props.styles.choice
              }
            >
              <View style={props.styles.choiceFlag}>
                <View style={props.styles.noFlag} />
              </View>
              <Text
                style={
                  props.question.answer === 'not-applicable'
                    ? [props.styles.choiceText, props.styles.choiceSelectedText]
                    : props.styles.choiceText
                }
              >
                N/A
              </Text>
            </View>
          </Touchable>
        </View>
      ) : null}
      <Text style={styles.scoreWeight}>
        {t('questionnaire:question.score.weight')}:{' '}
        {props.question.hasOwnProperty('scoreWeight')
          ? props.question.scoreWeight.toString()
          : props.question.score.toString()}
      </Text>
      <QuestionnaireQuestionScoreAnswer question={props.question} index={props.index} language={props.language} />
      <View style={styles.scoreAnswerInputContainer}>
        <View style={styles.sliderLine}>
          <QuestionnaireQuestionScoreTips score={props.question.score} index={props.index} />
          <QuestionnaireQuestionScoreNumbers score={props.question.score} index={props.index} />
        </View>
        <View style={styles.scoreRoot}>
          <Slider
            style={{ flex: 1 }}
            minimumValue={-1}
            maximumValue={props.question.score}
            step={1}
            disabled={props.question.answer === 'not-applicable'}
            value={
              props.question.answer === '' || props.question.answer === 'not-applicable'
                ? -1
                : typeof props.question.answer === 'number'
                ? (props.question.answer as number)
                : 0
            }
            onSlidingComplete={value => props.handleInputScore(props.index, value, props.currentConditionIndex!)}
            minimumTrackTintColor={theme.colors.primary}
            maximumTrackTintColor={theme.colors.gray}
            testID="score-input"
          />
        </View>
      </View>
      <Text style={styles.scoreFlavorText}>
        {typeof props.question.answer === 'number' && props.question.answer / props.question.score < 0.8
          ? t('questionnaire:question.score.note')
          : ''}
      </Text>
      <View
        style={[
          props.styles.openAnswerInputContainer,
          props.question.answer === 'not-applicable' && props.styles.commentDisabled,
          {
            borderColor: questionTextInvalid(props.question, t) ? theme.colors.secondary : 'rgba(0, 0, 0, 0.05)'
          }
        ]}
      >
        <TextInput
          style={props.styles.openCommentInput}
          maxLength={2500}
          multiline={true}
          editable={props.question.answer !== 'not-applicable'}
          numberOfLines={4}
          onChangeText={handleInputComment}
          value={comment}
          placeholder={
            props.question.answer === 'not-applicable'
              ? `${t('questionnaire:question.score.notApplicable')}`
              : `${t('questionnaire:question.score.comments')}${
                  props.question.answer === 'green-flag' ? t('questionnaire:question.score.optional') : ''
                }`
          }
          ref={props.commentInputRef}
          onFocus={() =>
            props.handleTextInputFocused(
              typeof props.currentConditionIndex! === 'number' ? props.currentConditionIndex! : props.index
            )
          }
          onBlur={() =>
            props.handleTextInputBlurred(
              typeof props.currentConditionIndex! === 'number' ? props.currentConditionIndex! : props.index
            )
          }
          testID={'score-comment'}
        />
      </View>
      {!questionTextInvalid(props.question, t) ? (
        <View style={props.styles.validationContainer}>
          <Text style={props.styles.textValidation}>&zwnj;</Text>
        </View>
      ) : (
        <View style={props.styles.validationContainer}>
          <Text style={props.styles.textValidation}> {questionTextInvalid(props.question, t)} </Text>
        </View>
      )}
    </View>
  );
};

export default QuestionnaireQuestionScore;

const styles = StyleSheet.create({
  scoreFlavorText: {
    minHeight: 14.5,
    marginTop: 45,
    marginBottom: 25,
    marginHorizontal: 25,
    ...fontMaker({ weight: 'Regular' }),
    lineHeight: 14,
    fontSize: 12
  },
  scoreRoot: {
    justifyContent: 'flex-start',
    width: '77%'
  },
  scoreWeight: {
    textAlign: 'center',
    color: '#8f8f8f',
    marginBottom: 15,
    paddingHorizontal: 10
  },
  sliderLine: {
    position: 'absolute',
    top: '54%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  scoreAnswerInputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 20,
    paddingBottom: 14
  }
});

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
  Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;
