import { action, createAsyncAction } from 'typesafe-actions';

import * as actionTypes from './siteSchedule.actionTypes';

// types
import { Site } from '../../../utils/classes';
import { UserSingleSiteScheduleInfo } from './siteSchedule.reducer';

export const setIsLoading = (siteKey: string, bool: boolean) => action(actionTypes.SET_IS_LOADING, { siteKey, bool });

export const setIsReady = (siteKey: string, bool: boolean) => action(actionTypes.SET_IS_READY, { siteKey, bool });
export const setSingleSiteScheduleData = (siteKey: string, scheduleInfo: UserSingleSiteScheduleInfo[]) =>
  action(actionTypes.SET_SINGLE_SITE_SCHEDULE_DATA, { siteKey, scheduleInfo });

/**
 * Remove schedule info while retaining the existence of site state
 */
export const removeSiteScheduleData = (siteKey: string) => action(actionTypes.REMOVE_SITE_SCHEDULE_DATA, { siteKey });

/**
 * Wipe clean site schedule object
 */
export const clearSiteScheduleData = (siteKeys: string[]) => action(actionTypes.CLEAR_SITE_SCHEDULE_DATA, { siteKeys });

// ts-ignore
export const fetchSingleSiteScheduleRequest = (data: {
  siteKey: string;
  site: Site;
  startDate: string;
  endDate: string;
  organizationID: string;
  userID: string;
}) =>
  action(actionTypes.FETCH_SINGLE_SITE_SCHEDULE_REQUEST, {
    siteKey: data.siteKey,
    site: data.site,
    startDate: data.startDate,
    endDate: data.endDate,
    organizationID: data.organizationID,
    userID: data.userID
  });

export const cancelFetchSiteSchedule = () => action(actionTypes.CANCEL);
