import React, { useState } from 'react';

import { Modal, Platform, StyleSheet, TouchableNativeFeedback, TouchableOpacity, View, ScrollView } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import theme from '../../constants/theme';
import Text from './Text';
import { SCREEN_WIDTH } from '../../utils/screensize';

export type Option = {
  label: string;
  value: string;
};

type SelectorPopupProps = {
  onChangeSelected: (option: Option) => void;
  selected: Option | null;
  title: string;
  options: Option[];
  addNewText?: string;
  onClickAddNew?: () => void;
  onShow?: () => void;
};

const SelectorPopup = (props: SelectorPopupProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const { selected, title, options, addNewText, onChangeSelected, onClickAddNew, onShow } = props;

  const handleOpenModal = () => {
    setVisible(true);
    if (onShow) {
      onShow();
    }
  };
  const handleCloseModal = () => {
    setVisible(false);
  };

  return (
    <>
      <Touchable onPress={handleOpenModal}>
        <View style={styles.selectBox}>
          {selected ? <Text>{selected.label}</Text> : <Text>{title}</Text>}
          <Icon name="chevron-down" size={22} color={theme.colors.gray} />
        </View>
      </Touchable>

      <Modal animationType="fade" transparent={true} visible={visible} onRequestClose={handleCloseModal}>
        <TouchableOpacity style={styles.container} activeOpacity={1} onPressOut={handleCloseModal}>
          <View style={styles.modalContainer}>
            <View style={styles.modalView}>
              <View style={styles.modalHeading}>
                <Text>{title}</Text>
              </View>
              <ScrollView
                showsHorizontalScrollIndicator={false}
                showsVerticalScrollIndicator={false}
                style={styles.rowContainer}
              >
                {selected &&
                  options.map(option => (
                    <Touchable
                      key={option.value}
                      onPress={() => {
                        onChangeSelected(option);
                        handleCloseModal();
                      }}
                      style={styles.row}
                    >
                      <View style={styles.tabRow}>
                        <Text>{option.label}</Text>
                        {option.value === selected.value ? <Icon name="check" size={16} color="#3cd070" /> : null}
                      </View>
                    </Touchable>
                  ))}
              </ScrollView>
              {addNewText && onClickAddNew && (
                <Touchable
                  onPress={() => {
                    onClickAddNew();
                    handleCloseModal();
                  }}
                  style={styles.row}
                >
                  <View style={[styles.tabRow, styles.tabRowCenter]}>
                    <Text style={theme.styles.textPrimary}>{addNewText}</Text>
                  </View>
                </Touchable>
              )}
            </View>
          </View>
        </TouchableOpacity>
      </Modal>
    </>
  );
};

export default SelectorPopup;

const Touchable: any = Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

const width = SCREEN_WIDTH;

const styles = StyleSheet.create({
  row: {},
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  modalView: {
    alignItems: 'center',
    backgroundColor: '#fff',
    width: SCREEN_WIDTH - 50,
    borderRadius: 5
  },
  modalHeading: {
    width: '100%',
    alignItems: 'center',
    paddingVertical: 15,
    paddingHorizontal: 10,
    backgroundColor: 'rgba(27, 33, 51, 0.1)'
  },
  tabRow: {
    width: SCREEN_WIDTH - 70,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 15,
    paddingHorizontal: 20,
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderBottomColor: 'rgba(27, 33, 51, 0.1)'
  },
  tabRowCenter: {
    justifyContent: 'center'
  },
  rowContainer: {
    maxHeight: 300
  },
  selectBox: {
    borderRadius: 4,
    backgroundColor: theme.colors.light,
    width: '100%',
    height: 40,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 20,
    marginBottom: 10
  },
  container: {
    flex: 1
  }
});
