import { useEffect } from 'react';
import { Dispatch } from 'redux';
import firebase from 'firebase';
import { FirebaseDatabaseTypes } from 'firebase/database';

import { Site, SiteQuestionnaireSchedule, Schedule } from '../../../utils/classes';
import { PopulatedSiteSKU } from '../../../typing/types';
import { setSiteData } from '../../../store/reducers/siteDataset/siteDataset.action';
import { SiteData } from '../../../store/reducers/siteDataset/siteDataset.reducer';

type Reference = FirebaseDatabaseTypes.Reference;
type DataSnapshot = FirebaseDatabaseTypes.DataSnapshot;

/**
 * Build own listener which value then injected to `react-redux-firebase`'s reducer.
 * as it's listener sometimes fails after the app idle for some time
 */

const db = firebase.database();
const setState = (snapshot: DataSnapshot, dispatch: Dispatch, siteKey: string, dataType: keyof SiteData) => {
  dispatch(setSiteData(siteKey, dataType, snapshot.val()));
};
export default (
  organizationKey: string,
  siteKey: string,
  site: Site,
  startDate: string,
  endDate: string,
  dispatch: Dispatch,
  journey?: any
) => {
  useEffect(() => {
    if (!siteKey || !organizationKey || !site || !startDate || !endDate) {
      return;
    }
    let reloaded = false;
    /**
     * [https://firebase.google.com/docs/database/android/offline-capabilities?authuser=1#section-offline-queries]
     * considering adding `.keepSynced(true)`
     * however according to docs
     * `Data that is kept in sync is not purged from the cache.`
     * possibly creating bloated file size
     */

    const siteScheduleRef: Reference = db.ref(`/schedule/${organizationKey}/${siteKey}`);
    siteScheduleRef
      .orderByKey()
      .startAt(startDate)
      .endAt(endDate)
      .on('value', (snapshot: any) => setState(snapshot, dispatch, siteKey, 'schedule'));

    let reportRef: Reference = db.ref(`/report/${organizationKey}/${siteKey}`);
    let summaryRef: Reference = db.ref(`/reportIndex/${organizationKey}/${siteKey}`);

    reportRef.limitToLast(18).on('value', (snapshot: any) => setState(snapshot, dispatch, siteKey, 'report'));
    summaryRef.limitToLast(18).on('value', (snapshot: any) => setState(snapshot, dispatch, siteKey, 'summary'));

    //change report ref if site is in a journey
    if (journey) {
      reportRef = db.ref(`/reportJourney/${organizationKey}/${journey.code}/${siteKey}`);
      summaryRef = db.ref(`/reportJourneyIndex/${organizationKey}/${journey.code}/${siteKey}`);
    }

    const inventoryRef: Reference = db.ref(`/inventory/${organizationKey}/${siteKey}`);
    inventoryRef
      .limitToLast(2)
      .on('value', (snapshot: any) => setState(snapshot, dispatch, siteKey, 'latestInventory'));

    const siteSKURef: Reference = db.ref(`/siteSKU/${organizationKey}/${siteKey}`);
    siteSKURef
      .orderByChild('disabled')
      .equalTo(false)
      .on('value', async (snapshot: any) => {
        const data = snapshot.val() as { [skuKey: string]: PopulatedSiteSKU };
        if (!data) {
          // do nothing
          dispatch(setSiteData(siteKey, 'siteSKU', data));
          return;
        }
        const skuKeys = Object.keys(data);
        const skuPromises = skuKeys.map(skuKey => {
          return db.ref(`/organizationSKU/${organizationKey}/${skuKey}`).once('value');
        });
        const skuSnaps = await Promise.all(skuPromises);
        skuSnaps.forEach(skuSnap => {
          data[skuSnap.key!].skuDetail = skuSnap.val();
        });
        if (!reloaded) {
          dispatch(setSiteData(siteKey, 'siteSKU', data));
        }
      });

    const siteQuestionnaireScheduleRef: Reference = db.ref(`/siteQuestionnaireSchedule/${organizationKey}/${siteKey}`);
    siteQuestionnaireScheduleRef
      .orderByChild('disabled')
      .equalTo(false)
      .on('value', async (snapshot: any) => {
        const data = snapshot.val() as SiteQuestionnaireSchedule;
        if (!data) {
          // do nothing
          if (!reloaded) {
            dispatch(setSiteData(siteKey, 'siteQuestionnaireSchedule', data));
          }
          return;
        }

        const questionnaireKeys = Object.keys(data);

        const customQuestionnaireSchedulePromises = questionnaireKeys.map(questKey => {
          return db
            .ref(`/customSiteQuestionnaireSchedule/${organizationKey}/${siteKey}/${questKey}`)
            .orderByKey()
            .startAt(startDate)
            .endAt(endDate)
            .once('value');
        });
        const customQuestionnaireScheduleSnaps = await Promise.all(customQuestionnaireSchedulePromises);
        customQuestionnaireScheduleSnaps.forEach(customQuestionnaireScheduleSnap => {
          if (data[customQuestionnaireScheduleSnap!.key!].type === 'custom') {
            const schedule = customQuestionnaireScheduleSnap!.val() as Schedule;
            data[customQuestionnaireScheduleSnap!.key!].datesCustom = schedule;
          }
        });
        if (!reloaded) {
          dispatch(setSiteData(siteKey, 'siteQuestionnaireSchedule', data));
        }
      });

    const unsetListener = () => {
      if (siteScheduleRef) {
        siteScheduleRef.off();
      }
      if (reportRef) {
        reportRef.off();
      }
      if (summaryRef) {
        summaryRef.off();
      }
      if (inventoryRef) {
        inventoryRef.off();
      }
      if (siteSKURef) {
        siteSKURef.off();
      }
      if (siteQuestionnaireScheduleRef) {
        siteQuestionnaireScheduleRef.off();
      }
    };

    return () => {
      reloaded = true;
      unsetListener();
    };
  }, [siteKey, site, organizationKey, dispatch, startDate, endDate]);
};
