export const promotionTypeOptions = [
  { label: 'Discount', value: 'discount' },
  { label: 'Free Item', value: 'freeItem' },
  { label: 'Coupon', value: 'coupon' },
  { label: 'Refund', value: 'refund' },
  { label: 'Reward Program', value: 'rewardProgram' },
  { label: 'Sponsorship', value: 'sponsorship' },
  { label: 'Referral', value: 'referral' },
  { label: 'Collaboration with other brand', value: 'collab' },
  { label: 'Other', value: 'other' }
];
