import React from 'react';
import {
  StyleSheet,
  View,
  TouchableWithoutFeedbackProps,
  Platform,
  TextStyle,
  TextInput,
  TouchableNativeFeedback,
  TouchableOpacity
} from 'react-native';
import Text from '../global/Text';
import { QuestionnaireQuestionProps } from './QuestionnaireQuestion';
import { questionTextInvalid, questionNumberInvalid } from '../../utils/report';
import theme from '../../constants/theme';
import fontMaker from '../../utils/font';
import getRangeFlagColor from './utils/getRangeFlagColor';
import QuestionnaireQuestionRangeFlagResult from './QuestionnaireQuestionRangeFlagResult';
import { useTranslation } from 'react-i18next';

export type QuestionnaireQuestionNumberProps = Pick<
  QuestionnaireQuestionProps,
  | 'question'
  | 'index'
  | 'currentConditionIndex'
  | 'language'
  | 'numberInputRef'
  | 'commentInputRef'
  | 'currentQuestionIndex'
  | 'isTutorialMode'
  | 'handleInputComment'
  | 'handleSelectChoice'
  | 'handleTextInputFocused'
  | 'handleTextInputBlurred'
  | 'handleInputDecimal'
  | 'handleNumberInputFocused'
  | 'handleNumberInputBlurred'
  | 'handleShowTutorialQuestionModal'
  | 'handleChangeTutorialQuestionType'
>;
export type QuestionnaireQuestionNumberPropsExtra = {
  styles: any;
};
const QuestionnaireQuestionRangeFlag = (
  props: QuestionnaireQuestionNumberProps & QuestionnaireQuestionNumberPropsExtra
) => {
  const { t } = useTranslation(['questionnaire', 'common']);
  React.useEffect(() => {
    if (props.isTutorialMode && props.index === props.currentQuestionIndex) {
      props.handleChangeTutorialQuestionType(props.question.type);
      props.handleShowTutorialQuestionModal(true);
    }
  }, [props.index, props.currentQuestionIndex]);

  React.useEffect(() => {
    setComment(props.question.comment);
  }, [props.question.answer]);

  // temporary fix to handle input flicker and moving cursor in RN 0.63.3
  // TODO: revert this once the bug is handled, or there is a permanent fix
  const [comment, setComment] = React.useState<string>(props.question.comment);
  const handleInputComment = (text: string) => {
    setComment(text);
    props.handleInputComment(props.index, text, props.currentConditionIndex!);
  };

  return (
    <View>
      {!props.question.answerRequired ? (
        <View style={props.styles.choicesList}>
          <Touchable
            delayPressIn={0}
            onPress={() => {
              props.handleSelectChoice(props.index, 'not-applicable', props.currentConditionIndex!);
            }}
            testID="number-na"
          >
            <View
              style={
                props.question.answer === 'not-applicable'
                  ? [props.styles.choice, props.styles.choiceSelected]
                  : props.styles.choice
              }
            >
              <View style={props.styles.choiceFlag}>
                <View style={props.styles.noFlag} />
              </View>
              <Text
                style={
                  props.question.answer === 'not-applicable'
                    ? [props.styles.choiceText, props.styles.choiceSelectedText]
                    : props.styles.choiceText
                }
              >
                N/A
              </Text>
            </View>
          </Touchable>
        </View>
      ) : null}
      <QuestionnaireQuestionRangeFlagResult
        question={props.question}
        index={props.index}
        language={props.language}
        t={t}
      />
      <View
        style={[
          styles.rangeFlagAnswerInputContainer,
          props.question.answer === 'not-applicable' && props.styles.commentDisabled,
          {
            borderColor: questionNumberInvalid(props.question, t) ? theme.colors.secondary : 'rgba(0, 0, 0, 0.05)'
          }
        ]}
      >
        <TextInput
          testID={'range-flag-input'}
          style={styles.rangeFlagAnswerInput}
          maxLength={20}
          editable={props.question.answer !== 'not-applicable'}
          onChangeText={text => props.handleInputDecimal(props.index, text, props.currentConditionIndex!)}
          value={props.question.answer === 'not-applicable' ? '' : props.question.answer.toString()}
          keyboardType={Platform.OS === 'android' ? 'default' : 'numbers-and-punctuation'}
          placeholder={
            props.question.answer === 'not-applicable'
              ? `${t('questionnaire:question.rangeFlag.notApplicable')}`
              : t('questionnaire:question.rangeFlag.typeAnswer')
          }
          ref={props.numberInputRef}
          onFocus={() =>
            props.handleNumberInputFocused(
              typeof props.currentConditionIndex! === 'number' ? props.currentConditionIndex! : props.index
            )
          }
          onBlur={() =>
            props.handleNumberInputBlurred(
              typeof props.currentConditionIndex! === 'number' ? props.currentConditionIndex! : props.index
            )
          }
        />
      </View>
      <View
        style={[props.styles.validationRangeContainer, props.styles.rangeFlagValidationContainer]}
        testID={'errorMsg'}
      >
        {questionNumberInvalid(props.question, t) ? (
          <Text style={props.styles.textValidation}>{questionNumberInvalid(props.question, t) || ''}</Text>
        ) : null}
      </View>
      <View
        style={[
          props.styles.openAnswerInputContainer,
          props.question.answer === 'not-applicable' && props.styles.commentDisabled,
          {
            borderColor: questionTextInvalid(props.question, t) ? theme.colors.secondary : 'rgba(0, 0, 0, 0.05)'
          }
        ]}
      >
        <TextInput
          testID={'range-flag-comment'}
          style={props.styles.openCommentInput}
          maxLength={2500}
          multiline={true}
          editable={props.question.answer !== 'not-applicable'}
          numberOfLines={4}
          onChangeText={handleInputComment}
          value={comment}
          placeholder={
            props.question.answer === 'not-applicable'
              ? 'Not Applicable'
              : `${t('questionnaire:question.rangeFlag.comments')}${
                  getRangeFlagColor(props.question) === 'green-flag'
                    ? t('questionnaire:question.rangeFlag.optional')
                    : ''
                }`
          }
          ref={props.commentInputRef}
          onFocus={() =>
            props.handleTextInputFocused(
              typeof props.currentConditionIndex! === 'number' ? props.currentConditionIndex! : props.index
            )
          }
          onBlur={() =>
            props.handleTextInputBlurred(
              typeof props.currentConditionIndex! === 'number' ? props.currentConditionIndex! : props.index
            )
          }
        />
      </View>
      <View style={props.styles.validationContainer} testID={'commentMsg'}>
        {!questionTextInvalid(props.question, t) ? (
          <Text style={props.styles.textValidation}>&zwnj;</Text>
        ) : (
          <Text style={props.styles.textValidation}> {questionTextInvalid(props.question, t)} </Text>
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  rangeFlagAnswerInputContainer: {
    justifyContent: 'flex-start',
    marginHorizontal: 20,
    paddingVertical: 0,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 6
  },
  scoreRoot: {
    alignSelf: 'center',
    paddingVertical: 20,
    width: '85%'
  },
  sliderLine: {
    position: 'absolute',
    top: '54%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rangeFlagSliderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 20,
    paddingBottom: 14
  },
  rangeFlagAnswerInput: {
    height: 39,
    ...fontMaker({ weight: 'Regular' }),
    color: '#535353',
    textAlignVertical: 'top',
    paddingVertical: 10,
    paddingHorizontal: 10
  }
});

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
  Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

export default QuestionnaireQuestionRangeFlag;
