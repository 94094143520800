import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigation } from '@react-navigation/native';
import { StyleSheet, ScrollView, View, Platform, Text, ActivityIndicator, Alert } from 'react-native';

// redux
import RootNavigation from '../../RootNavigation';
import { RootState } from '../../store/reducers';
import { toastActions } from '../../store/reducers/toast';
import { siteActions } from '../../store/reducers/site/site';
import { scheduleJourneysActions } from '../../store/reducers/schedulejourneys';
import { journeyActions } from '../../store/reducers/journey/journey';

// components
import Button from '../global/Button';
import JourneyMap from './JourneyMap';
import JourneyInfo from './JourneyInfo';
import JourneySiteList from './JourneySiteList';
import ConfirmModal, { ModalConfig } from '../global/ConfirmModal';

// utils
import getJourneyReportStatus from '../schedule/utils/getJourneyReportStatus';
import theme from '../../constants/theme';

// types
import { JourneyPageProps } from '../../routes/journey';
import { Site, ReportSummary } from '../../utils/classes';

const Journey = (props: JourneyPageProps) => {
  const { t } = useTranslation(['common, journey']);

  // props
  const isDueToday = props.route.params.isDueToday;

  // state redux
  const auth = useSelector((state: RootState) => state.firebase.auth);
  const journey = useSelector((state: RootState) => state.journey.journey);
  const journeyReport = useSelector((state: RootState) => state.journey.journeyReport);
  const reportSummary = useSelector((state: RootState) => state.journey.reportSummary);
  const ongoingJourneyReportKey = useSelector((state: RootState) => state.journey.ongoingJourneyReportKey);
  const isLoading = useSelector((state: RootState) => state.journey.isLoading);
  const errorJourney = useSelector((state: RootState) => state.journey.error);
  const sites = useSelector((state: RootState) => state.site.sites);
  const profileColor = useSelector((state: RootState) => state.profiletheme.color);

  // state
  const [journeySites, setJourneySites] = useState<Site[]>([]);
  const [visitedSites, setVisitedSites] = useState<string[]>([]);
  const [isLoadingVisited, setIsLoadingVisited] = useState<boolean>(false);
  const [modalConfirmVisible, setModalConfirmVisible] = useState<boolean>(false);
  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    title: '',
    subtitle: '',
    options: []
  });

  const dispatch = useDispatch();
  const navigation = useNavigation();

  /**
   * get summary report for this journey
   */
  useEffect(() => {
    if (journey && auth.uid) {
      dispatch(journeyActions.getJourneyReportSummaryAsync.request());
    }
  }, [journey, auth]);

  useEffect(() => {
    const visited: string[] = [];

    if (journey) {
      Object.keys(reportSummary).forEach((siteID: string) => {
        if (reportSummary[siteID].length >= journey?.questionnaires.length) {
          visited.push(siteID);
        }
      });

      setVisitedSites(visited);
    }
  }, [reportSummary, journey]);

  /**
   * Effect to fetch sites and filter by journey sites
   */
  useEffect(() => {
    if (journey) {
      if (!sites) {
        dispatch(siteActions.fetchSitesAsync.request(undefined));
      } else {
        //filter sites and get array of journey sites
        const newJourneySites: Site[] = [];
        journey.sites.forEach(journeySiteKey => {
          if (sites[journeySiteKey]) {
            newJourneySites.push(sites[journeySiteKey]);
          }
        });
        setJourneySites(newJourneySites);
      }
    }
  }, [journey, sites]);

  //handle error
  useEffect(() => {
    if (errorJourney) {
      handleError(errorJourney);
    }
  }, [errorJourney]);

  const handleError = (message: string) => {
    dispatch(toastActions.createToast(t('journey:toast.errorOcurred'), true, 5000));
  };

  const handleStartJourney = async () => {
    if (journey) {
      //start a new journey by user in current date
      dispatch(
        journeyActions.createJourneyReportAsync.request({
          journeyID: journey.code,
          reportDate: moment().format('YYYY-MM-DD')
        })
      );
      //reset schedule journeys
      dispatch(scheduleJourneysActions.setMyScheduledJourneys([]));
    }
  };

  const handleEndJourney = async () => {
    if (journey && journeyReport) {
      //end an ongoing journey report
      dispatch(
        journeyActions.updateJourneyReportAsync.request({
          ...journeyReport,
          end: new Date().toISOString(),
          hasEnded: true
        })
      );

      //reset schedule journeys
      dispatch(scheduleJourneysActions.setMyScheduledJourneys([]));

      // back to home after finish the journey
      navigation.reset({
        index: 0,
        routes: [{ name: 'HomeTab' }]
      });
    }
  };

  //handle button start or end journey
  const handleButtonPress = async () => {
    if (journey) {
      // handle if journey is not due today
      if (!isDueToday) {
        const modalConf: ModalConfig = {
          title: t('common:reminder'),
          subtitle: t('journey:noSchedule'),
          options: [
            {
              text: t('common:ok'),
              style: 'confirm',
              action: () => {
                setModalConfirmVisible(false);
              }
            }
          ]
        };
        setModalConfig(modalConf);
        setModalConfirmVisible(true);
        return;
      }
      if (getJourneyReportStatus(journeyReport, ongoingJourneyReportKey) === 'idle') {
        //handle case if another journey is ongoing
        if (ongoingJourneyReportKey) {
          const modalConf: ModalConfig = {
            title: t('common:reminder'),
            subtitle: t('journey:alert.onGoingJourney'),
            options: [
              {
                text: t('common:ok'),
                style: 'confirm',
                action: () => {
                  setModalConfirmVisible(false);
                }
              }
            ]
          };
          setModalConfig(modalConf);
          setModalConfirmVisible(true);
          return;
        }

        // start journey
        const modalConf: ModalConfig = {
          title: t('common:confirmation'),
          subtitle: t('journey:alert.confirmStartJourney'),
          options: [
            {
              text: t('common:yes'),
              style: 'confirm',
              action: () => {
                handleStartJourney();
                setModalConfirmVisible(false);
              }
            },
            {
              text: t('common:no'),
              style: 'cancel',
              action: () => {
                setModalConfirmVisible(false);
              }
            }
          ]
        };
        setModalConfig(modalConf);
        setModalConfirmVisible(true);

        return;
      } else if (getJourneyReportStatus(journeyReport, ongoingJourneyReportKey) === 'ongoing') {
        const isAllVisited = visitedSites.length >= journeySites.length;
        const prompt = `${t('journey:alert.confirmFinishJourney')}${
          !isAllVisited ? t('journey:alert.missedSites') : ''
        }`;
        const modalConf: ModalConfig = {
          title: t('common:confirmation'),
          subtitle: prompt,
          options: [
            {
              text: t('common:yes'),
              style: 'confirm',
              action: () => {
                handleEndJourney();
                setModalConfirmVisible(false);
              }
            },
            {
              text: t('common:no'),
              style: 'cancel',
              action: () => {
                setModalConfirmVisible(false);
              }
            }
          ]
        };
        setModalConfig(modalConf);
        setModalConfirmVisible(true);
      }
    }
  };

  /**
   * Handle site navigation
   */
  const handleNavSite = (
    siteKey: string,
    site: Site,
    siteCompletedReports: number,
    siteLastReport: ReportSummary | null
  ) => {
    if ((reportSummary[siteKey] || []).length >= (journey?.questionnaires || []).length) {
      Alert.alert('Info', t('journey:alert.alreadyFinishSite'), [
        {
          text: 'OK'
        }
      ]);
      return;
    }

    if (getJourneyReportStatus(journeyReport, ongoingJourneyReportKey) === 'finished') {
      Alert.alert(t('common:confirmation'), t('journey:alert.alreadyFinishJourney'), [
        {
          text: 'OK'
        }
      ]);
      return;
    }

    if (!isDueToday) {
      Alert.alert(t('common:reminder'), t('journey:alert.noSchedule'), [
        {
          text: 'OK'
        }
      ]);
      return;
    }

    if (getJourneyReportStatus(journeyReport, ongoingJourneyReportKey) === 'idle') {
      Alert.alert(t('common:reminder'), t('journey:alert.startJourneyFirst'), [
        {
          text: 'OK'
        }
      ]);
      return;
    }

    dispatch(
      siteActions.selectSite(
        siteKey,
        {
          ...site,
          assignedAuditor: !site.isMultiSite ? auth.uid : site.assignedAuditor
        },
        null,
        siteCompletedReports,
        siteLastReport
      )
    );

    RootNavigation.navigate('Site', {
      profileColor: profileColor,
      originTab: 'HomeTab',
      siteName: site.name
    });
  };

  if (!journey || journeySites.length === 0 || isLoadingVisited || isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator color={theme.colors.primaryDark} size="large" />
        <Text style={styles.loadingText}>{t('journey:loadingJourneyInfo')}</Text>
      </View>
    );
  }

  return (
    <>
      <ScrollView style={styles.root} showsVerticalScrollIndicator={false} stickyHeaderIndices={[1]}>
        <ConfirmModal
          isVisible={modalConfirmVisible}
          title={modalConfig.title}
          subtitle={modalConfig.subtitle}
          options={modalConfig.options}
          onCloseModal={() => setModalConfirmVisible(!modalConfirmVisible)}
        />
        <JourneyMap journeySites={journeySites} visitedSites={visitedSites} />
        <JourneyInfo
          journey={journey}
          journeySites={journeySites}
          journeyReport={journeyReport}
          visitedSites={visitedSites}
        />
        <JourneySiteList
          handleNavSite={handleNavSite}
          journeySites={journeySites}
          visitedSites={visitedSites}
          isDueToday={isDueToday}
        />
      </ScrollView>
      <View style={styles.bottomCard}>
        <Button
          title={
            getJourneyReportStatus(journeyReport, ongoingJourneyReportKey) === 'idle'
              ? t('journey:startJourney')
              : getJourneyReportStatus(journeyReport, ongoingJourneyReportKey) === 'ongoing'
              ? t('journey:finishJourney')
              : t('journey:finished')
          }
          icon={
            getJourneyReportStatus(journeyReport, ongoingJourneyReportKey) === 'idle'
              ? Platform.OS === 'ios'
                ? 'chevron-right'
                : 'arrow-right'
              : undefined
          }
          style={styles.button}
          backgroundColor={
            getJourneyReportStatus(journeyReport, ongoingJourneyReportKey) === 'ongoing' ? '#fc4b5e' : undefined
          }
          onPress={handleButtonPress}
          disabled={
            !!(
              errorJourney ||
              getJourneyReportStatus(journeyReport, ongoingJourneyReportKey) === 'finished' ||
              isLoading
            )
          }
        />
      </View>
    </>
  );
};

export default Journey;

const styles = StyleSheet.create({
  root: {
    flex: 1
  },
  loadingContainer: {
    marginTop: 20,
    alignContent: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  loadingText: {
    marginTop: 5,
    textAlign: 'center'
  },
  bottomCard: {
    position: 'absolute',
    bottom: 0,
    height: 100,
    justifyContent: 'center',
    paddingHorizontal: 15,
    width: '100%',
    // Android settings
    elevation: 5,
    // iOS settings
    shadowOpacity: 0.15,
    backgroundColor: '#ffffff'
  },
  button: {
    marginBottom: 20
  }
});
