import firebase from 'firebase';
import update from 'immutability-helper';
import { createAction, createAsyncAction, createReducer, ActionType } from 'typesafe-actions';
import { put, call } from 'redux-saga/effects';

import { ReportSummary, Schedule, Site, AllSites, SiteTabs } from '../../../utils/classes';
import { InjectedSite, InjectedSiteStatus, ScheduleOption } from '../../../typing/types';
import { apiProdURL } from '../../../constants/api';

export const FETCH_SITES_REQUEST = '@site/FETCH_SITES_REQUEST';
export const FETCH_SITES_SUCCESS = '@site/FETCH_SITES_SUCCESS';
export const FETCH_SITES_FAILURE = '@site/FETCH_SITES_FAILURE';
export const SELECT = '@site/SELECT_SITE';
export const MODIFY = '@site/MODIFY_SITE';
export const RESET = '@site/RESET_SITE';
export const SET_SITE_STATUS = '@site/SET_SITE_STATUS';
export const SHOW_MODAL = '@site/SHOW_MODAL';
export const DISMISS_MODAL = '@site/DISMISS_MODAL';
export const SET_SITE_SCHEDULE = '@site/SET_SITE_SCHEDULE';
export const SET_SITE_CONFIG = '@site/SET_SITE_CONFIG';
export const SET_ACTIVE_TAB = '@site/SET_ACTIVE_TAB';
export const SET_SELECTED_SCHEDULE = '@siteDataset/SET_SELECTED_SCHEDULE';

export const fetchSitesAsync = createAsyncAction(FETCH_SITES_REQUEST, FETCH_SITES_SUCCESS, FETCH_SITES_FAILURE)<
  undefined | number,
  Site[],
  Error
>();

/**
 * @param activeTab
 */
export const setActiveTab = createAction(SET_ACTIVE_TAB, (activeTab: SiteTabs) => activeTab)();

/**
 * @param scheduleOption
 *
 */
export const setSelectedSchedule = createAction(
  SET_SELECTED_SCHEDULE,
  (schedule: Partial<ScheduleOption> | null) => schedule
)();

export function* fetchSitesSaga(action: ReturnType<typeof fetchSitesAsync.request>): Generator {
  const tryCount = action.payload || 1;
  try {
    const idToken = (yield firebase.auth().currentUser!.getIdToken()) as string;
    const request = () =>
      fetch(`${apiProdURL}/sites`, {
        method: 'GET',
        headers: {
          authorization: idToken
        }
      });
    const response = (yield call(request)) as Body;
    const body = (yield response.json()) as any;
    yield put(fetchSitesAsync.success(body.data));
  } catch (err) {
    // Retry up to 3 times before throwing a failure
    if (tryCount >= 3) {
      yield put(fetchSitesAsync.request(tryCount + 1));
    } else {
      yield put(fetchSitesAsync.failure(err));
    }
  }
}

/**
 * Select a site, which saves the key and value of that site
 * @param key
 * @param value
 * @param schedule
 * @param completedCount
 * @param lastReport
 **/
const selectSite = createAction(
  SELECT,
  (
    key: string,
    value: InjectedSite | Site,
    schedule: Schedule | null,
    completedCount: number | null,
    lastReport: ReportSummary | null
  ) => ({
    key,
    value,
    schedule,
    completedCount,
    lastReport
  })
)();

/**
 * Modifies the selected site
 * If the key is not equal to the currently selected site key, nothing will happen
 * @param key
 * @param value
 */
const modifySite = createAction(MODIFY, (key: string, value: InjectedSite | Site) => ({
  key,
  value
}))();

/**
 * Clear site data
 */
const clearSite = createAction(RESET)();

/**
 * Update site status
 * @param status - status of the site
 */
const setSiteStatus = createAction(SET_SITE_STATUS, (status: InjectedSiteStatus) => ({
  status
}))();

/**
 * Show site settings modal
 */
const showSiteSettingsModal = createAction(SHOW_MODAL)();

/**
 * Dismiss site settings modal
 */
const dismissSiteSettingsModal = createAction(DISMISS_MODAL)();

/**
 * Set site schedule
 * @param schedule
 */
const setSiteSchedule = createAction(SET_SITE_SCHEDULE, (schedule: Schedule) => ({
  schedule
}))();

/**
 * Set site config for default email recipients targets and required signature
 * @param emailTargets
 * @param signatures
 */
const setSiteConfig = createAction(SET_SITE_CONFIG, (emailTargets: string[], signatures: number) => ({
  emailTargets,
  signatures
}))();

export const siteActions = {
  fetchSitesAsync,
  selectSite,
  modifySite,
  clearSite,
  setSiteStatus,
  showSiteSettingsModal,
  dismissSiteSettingsModal,
  setSiteSchedule,
  setSiteConfig,
  setActiveTab,
  setSelectedSchedule
};

export type SiteAction = ActionType<typeof siteActions>;
export type SiteState = {
  readonly isLoading: boolean;
  readonly lastUpdated: number | null;
  readonly errorMessage: string | null;
  readonly sites: AllSites | null | undefined;
  readonly selectedSiteKey: string | null;
  readonly selectedSite: Site | InjectedSite | null;
  readonly selectedSiteSchedule: Schedule | null;
  readonly selectedSiteCompletedCount: number | null;
  readonly selectedSiteLastReport: ReportSummary | null;
  readonly settingsModalVisible: boolean;
  readonly activeTab: SiteTabs;
  readonly selectedSchedule: Partial<ScheduleOption> | null;
};

const initialState: SiteState = {
  isLoading: false,
  lastUpdated: null,
  errorMessage: null,
  sites: undefined,
  selectedSiteKey: null,
  selectedSite: null,
  selectedSiteSchedule: null,
  selectedSiteCompletedCount: null,
  selectedSiteLastReport: null,
  settingsModalVisible: false,
  activeTab: 'outlet',
  selectedSchedule: null
};

export const siteReducer = createReducer<SiteState, SiteAction>(initialState)
  .handleAction(fetchSitesAsync.request, state => ({
    ...state,
    isLoading: true,
    errorMessage: null
  }))
  .handleAction(fetchSitesAsync.failure, (state, incomingAction) => ({
    ...state,
    isLoading: false,
    errorMessage: incomingAction.payload.message
  }))
  .handleAction(fetchSitesAsync.success, (state, incomingAction) => ({
    ...state,
    isLoading: false,
    lastUpdated: Date.now(),
    sites: incomingAction.payload.reduce((map: { [deptKey: string]: Site }, obj: Site) => {
      map[obj.siteID!] = obj;
      return map;
    }, {})
  }))
  .handleAction(selectSite, (state, incomingAction) => ({
    ...state,
    selectedSiteKey: incomingAction.payload.key,
    selectedSite: incomingAction.payload.value,
    selectedSiteSchedule: incomingAction.payload.schedule,
    selectedSiteCompletedCount: incomingAction.payload.completedCount,
    selectedSiteLastReport: incomingAction.payload.lastReport
  }))
  .handleAction(modifySite, (state, incomingAction) => {
    if (state.selectedSiteKey !== incomingAction.payload.key) {
      return state;
    }
    return {
      ...state,
      selectedSiteKey: incomingAction.payload.key,
      selectedSite: incomingAction.payload.value
    };
  })
  .handleAction(setSiteStatus, (state, incomingAction) => ({
    ...state,
    selectedSite: update(state.selectedSite, {
      status: { $set: 'pending-upload' }
    })
  }))
  .handleAction(showSiteSettingsModal, state => ({
    ...state,
    settingsModalVisible: true
  }))
  .handleAction(dismissSiteSettingsModal, state => ({
    ...state,
    settingsModalVisible: false
  }))
  .handleAction(setSiteSchedule, (state, incomingAction) => ({
    ...state,
    selectedSiteSchedule: incomingAction.payload.schedule
  }))
  .handleAction(setSiteConfig, (state, incomingAction) => ({
    ...state,
    selectedSite: update(state.selectedSite, {
      emailTargets: { $set: incomingAction.payload.emailTargets },
      signatures: { $set: incomingAction.payload.signatures }
    })
  }))
  .handleAction(clearSite, state => ({
    ...state,
    selectedSiteKey: null,
    selectedSite: null,
    selectedSiteSchedule: null,
    selectedSiteCompletedCount: null,
    selectedSiteLastReport: null
  }))
  .handleAction(setActiveTab, (state, action) => ({
    ...state,
    activeTab: action.payload
  }))
  .handleAction(setSelectedSchedule, (state, action) => ({
    ...state,
    selectedSchedule: action.payload
  }));
