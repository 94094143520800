import React from 'react';
import { StyleSheet, View } from 'react-native';

import Text from '../global/Text';
import { AuthContainerProps } from './AuthContainer';
import { useTranslation } from 'react-i18next';
import { AUTH_MODE } from '../../constants/Constants';

type AuthFormHeaderProps = Pick<AuthContainerProps, 'authMode' | 'language'>;

const AuthFormHeader = (props: AuthFormHeaderProps) => {
  const { t } = useTranslation(['auth', 'common']);
  return (
    <View style={styles.header}>
      {props.authMode === AUTH_MODE.LOGIN ? (
        <Text allowFontScaling={false} style={styles.headerText}>
          {' '}
          {t('common:login')}
        </Text>
      ) : props.authMode === AUTH_MODE.RESET_PASSWORD ? (
        <Text allowFontScaling={false} style={styles.headerText}>
          {' '}
          {t('common:forgotPassword')}
        </Text>
      ) : props.authMode === AUTH_MODE.SIGN_UP ? (
        <Text allowFontScaling={true} style={styles.headerTextMultiline}>
          {' '}
          {t('auth:formHeader.activateAccount')}
        </Text>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  header: {
    paddingBottom: 35,
    paddingTop: 50
  },
  headerText: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 36,
    lineHeight: 54,
    fontWeight: 'bold'
  },
  headerTextMultiline: {
    color: '#fff',
    textAlign: 'center',
    fontSize: 31,
    lineHeight: 54,
    fontWeight: 'bold'
  }
});

export default AuthFormHeader;
