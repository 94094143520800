import React from 'react';
import { StyleSheet, View, Text, Image } from 'react-native';

const freezeIcon = require('../../assets/icons/freeze-icon.png');
const titleTranslation: { [lang: string]: string } = {
  en: 'The Account you’re trying to access is deactivated. Try another account.',
  id: 'Akun Anda telah dinonaktifkan. Hubungi Admin Anda untuk informasi lebih lanjut.'
};

const AuthFreezeBanner = (props: any) => {
  const { language } = props;
  return (
    <View style={styles.freezeContainer}>
      <View style={styles.freezeLogo}>
        <Image source={freezeIcon} />
      </View>
      <View style={styles.freezeTextContainer}>
        <Text style={styles.freezeText}>{titleTranslation[language]}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  freezeContainer: {
    backgroundColor: '#ff5e6f',
    borderRadius: 5,
    marginTop: 10,
    flexDirection: 'row',
    marginBottom: 5
  },
  freezeLogo: {
    padding: 5,
    marginLeft: 10,
    justifyContent: 'center'
  },
  freezeTextContainer: {
    padding: 10
  },
  freezeText: {
    color: 'white',
    fontSize: 10,
    fontStyle: 'italic'
  }
});

export default AuthFreezeBanner;
