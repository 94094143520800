import { action, ActionType } from 'typesafe-actions';
import update from 'immutability-helper';
import { Reducer } from 'redux';

import { Report, EmailTarget, ReportSection, ReportStatus } from '../../utils/classes';
import { Constants } from '../../constants/Constants';

export const SELECT_REPORT = '@report/SELECT_REPORT';
export const SET_CAMERA_ACTIVE = '@report/SET_CAMERA_ACTIVE';
export const SET_CAMERA_FORMAT = '@report/SET_CAMERA_FORMAT';
export const SET_REPORT_EMAIL_TARGETS = '@report/SET_REPORT_EMAIL_TARGETS';
export const SET_REPORT_CALIBRATING_SKU = '@report/SET_REPORT_CALIBRATING_SKU';
export const SET_REPORT_STATUS = '@report/SET_REPORT_STATUS';
export const SAVE_REPORT_CHECKOUT_TIME = '@report/SAVE_REPORT_CHECKOUT_TIME';
export const SAVE_REPORT_SUBMISSION_TIME = '@report/SAVE_REPORT_SUBMISSION_TIME';
export const TOGGLE_DRAWER_VISIBILITY = '@report/TOGGLE_DRAWER_VISIBILITY';
export const SET_DRAWER_QUESTION_INDEX = '@report/SET_DRAWER_QUESTION_INDEX';
export const SET_QUESTION_INDEX = '@report/SET_QUESTION_INDEX';
export const SELECT_ANSWER_CHOICE = '@report/SELECT_ANSWER_CHOICE';
export const SELECT_ANSWER_CHOICE_SCORE = '@report/SELECT_ANSWER_CHOICE_SCORE';
export const SELECT_CHECKLIST_CHOICE = '@report/SELECT_CHECKLIST_CHOICE';
export const INPUT_ANSWER_TEXT = '@report/INPUT_ANSWER_TEXT';
export const INPUT_ANSWER_COMMENT = '@report/INPUT_ANSWER_COMMENT';
export const INPUT_ANSWER_COMPETITOR_ANALYSIS = '@report/INPUT_ANSWER_COMPETITOR_ANALYSIS';
export const INPUT_ANSWER_PPE_RESULT = '@report/INPUT_ANSWER_PPE_RESULT';
export const INPUT_ANSWER_INVENTORY = '@report/INPUT_ANSWER_INVENTORY';
export const INPUT_ANSWER_SKU_INVENTORY = '@report/INPUT_ANSWER_SKU_INVENTORY';
export const SET_ANSWER_PHOTOS = '@report/SET_ANSWER_PHOTOS';
export const SET_ANSWER_FILE = '@report/SET_ANSWER_FILE';
export const SET_ANSWER_VIDEOS = '@report/SET_ANSWER_VIDEOS';
export const SET_TEMPORARY_PHOTO_URI = '@report/SET_TEMPORARY_PHOTO_URI';
export const SET_TEMPORARY_VIDEO_URI = '@report/SET_TEMPORARY_VIDEO_URI';
export const SET_ANSWER_REMEDY = '@report/SET_ANSWER_REMEDY';
export const RESET = '@report/RESET';
export const SET_CAMERA_IMAGE_SIZE = '@report/SET_CAMERA_IMAGE_SIZE';

const setCameraImageSize = (imageSize: string) => action(SET_CAMERA_IMAGE_SIZE, { imageSize });

/**
 * Select a report, which saves the key of that report
 * @param key
 * @param value
 */
const selectReport = (key: string | null, value: Report | ReportSection | null) =>
  action(SELECT_REPORT, { key, value });

/**
 * Set camera to active to supress notifications showing
 * @param bool
 */
const setCameraActive = (bool: boolean) => action(SET_CAMERA_ACTIVE, { bool });

/**
 * Set camera to active to supress notifications showing
 * @param format
 */
const setCameraFormat = (format: 'photo' | 'video') => action(SET_CAMERA_FORMAT, { format });

/**
 * Set email targets for report
 * @param targets
 */
const setReportEmailTargets = (targets: EmailTarget[]) => action(SET_REPORT_EMAIL_TARGETS, { targets });

/**
 * Set calibrating SKU for report
 * @param calibratingSKU
 * @param datetime - capture timestamp when SKU was last updated (due to Firebase listener being dynamic)
 */
const setReportCalibratingSKU = (calibratingSKU: { [skuKey: string]: boolean } | null, datetime: string | null) =>
  action(SET_REPORT_CALIBRATING_SKU, { calibratingSKU, datetime });

/**
 * Set status for report
 * @param status
 */
const setReportStatus = (status: ReportStatus) => action(SET_REPORT_STATUS, { status });

/**
 * Save timestamp of check-out
 * @param timestamp
 */
const saveCheckoutTime = (timestamp: string) => action(SAVE_REPORT_CHECKOUT_TIME, { timestamp });

/**
 * Save timestamp of submission
 * @param timestamp
 */
const saveSubmissionTime = (timestamp: string) => action(SAVE_REPORT_SUBMISSION_TIME, { timestamp });

/**
 * Set drawer visibility to toggle drawer
 */
const toggleDrawerVisibility = () => action(TOGGLE_DRAWER_VISIBILITY);

/**
 * Set question index via drawer
 * @param index
 */
const setQuestionIndexWithDrawer = (index: number) => action(SET_DRAWER_QUESTION_INDEX, { index });

/**
 * Set question index after swiper is done swiping
 * @param index
 */
const setQuestionIndex = (index: number) => action(SET_QUESTION_INDEX, { index });

/**
 * Set answer choice
 * @param index
 * @param choice
 */
const selectAnswerChoice = (index: number, choice: string | number, condition?: string | number) =>
  action(SELECT_ANSWER_CHOICE, { index, choice, condition });

const selectAnswerChoiceScore = (index: number, choice: string | number, condition?: string | number, flag: string) =>
  action(SELECT_ANSWER_CHOICE_SCORE, { index, choice, condition, flag });

/**
 * Set answer choice for checklist
 * @param index
 * @param choice
 * @param currentConditionIndex
 */
const selectChecklistChoice = (index: number, choice: string[], currentConditionIndex?: number | string) =>
  action(SELECT_CHECKLIST_CHOICE, { index, choice, currentConditionIndex });

/**
 * Input answer text
 * @param index
 * @param text
 * @param currentConditionIndex
 */
const inputAnswerText = (index: number, text: string | number, currentConditionIndex?: string | number) =>
  action(INPUT_ANSWER_TEXT, { index, text, currentConditionIndex });

/**
 * Input inventory number
 * @param index
 * @param inventory
 * @param type
 */
const inputAnswerInventory = (index: number, inventory: number, type: 'in' | 'out' | 'final') =>
  action(INPUT_ANSWER_INVENTORY, { index, inventory, type });

/**
 * Input for inventory-v2, input can be inventory (number), photos (string array), or comment (string)
 * @param index
 * @param skuKey
 * @param input
 * @param type
 */
const inputAnswerSKUInventory = (
  index: number,
  skuKey: string,
  input: any,
  type: 'in' | 'out' | 'final' | 'photos' | 'comment' | 'isFilledByUser',
  downloadedImage: string[]
) => action(INPUT_ANSWER_SKU_INVENTORY, { index, skuKey, input, type, downloadedImage });

/**
 * Set comment text
 * @param index
 * @param text
 * @param currentConditionIndex
 */
const inputAnswerCommentText = (index: number, text: string, currentConditionIndex?: string | number) =>
  action(INPUT_ANSWER_COMMENT, { index, text, currentConditionIndex });

/**
 * Input inventory number
 * @param index
 * @param text
 * @param type
 */
const inputAnswerCompetitorAnalysis = (index: number, text: string, type: string) =>
  action(INPUT_ANSWER_COMPETITOR_ANALYSIS, { index, text, type });

/**
 * Set comment text
 * @param index
 * @param text
 * @param currentConditionIndex
 *
 */
const inputAnswerPPEResult = (index: number, text: string, currentConditionIndex?: string | number) =>
  action(INPUT_ANSWER_PPE_RESULT, { index, text, currentConditionIndex });

/**
 * Set answer photos
 * @param index
 * @param photos
 * @param currentConditionIndex
 * @param currentConditonalKey
 */
const setAnswerPhotos = (
  index: number,
  photos: string[],
  downloadedImage: string[],
  currentConditionIndex?: number | string,
  currentConditonalKey?: string | number
) => action(SET_ANSWER_PHOTOS, { index, photos, downloadedImage, currentConditionIndex, currentConditonalKey });

/**
 * Set temporary photo uri
 * @param uri
 */
const setTemporaryPhotoURI = (uri: string | null) => action(SET_TEMPORARY_PHOTO_URI, { uri });

/**
 * Set temporary video uri
 * @param uri
 */
const setTemporaryVideoURI = (uri: string | null) => action(SET_TEMPORARY_VIDEO_URI, { uri });

/**
 * Set answer videos
 * @param index
 * @param videos
 * @param currentConditionIndex
 * @param currentConditonalKey
 */
const setAnswerVideos = (
  index: number,
  videos: string[],
  currentConditionIndex?: number | string,
  currentConditonalKey?: string | number
) => action(SET_ANSWER_VIDEOS, { index, videos, currentConditionIndex, currentConditonalKey });

const setAnswerFile = (
  index: number,
  documents: File[],
  currentConditionIndex?: number | string,
  currentConditonalKey?: string | number
) => action(SET_ANSWER_FILE, { index, documents, currentConditionIndex, currentConditonalKey });

/**
 * Set answer remedy
 * @param index
 * @param text
 */
const setAnswerRemedy = (index: number, text: string, currentConditionIndex?: number | string) =>
  action(SET_ANSWER_REMEDY, { index, text, currentConditionIndex });

/**
 *
 */
const reset = () => action(RESET);

export const reportActions = {
  selectReport,
  setCameraActive,
  setCameraFormat,
  setReportEmailTargets,
  setReportCalibratingSKU,
  setReportStatus,
  saveCheckoutTime,
  saveSubmissionTime,
  toggleDrawerVisibility,
  setQuestionIndexWithDrawer,
  setQuestionIndex,
  setAnswerRemedy,
  selectAnswerChoice,
  selectAnswerChoiceScore,
  selectChecklistChoice,
  inputAnswerText,
  inputAnswerInventory,
  inputAnswerSKUInventory,
  inputAnswerCommentText,
  inputAnswerCompetitorAnalysis,
  inputAnswerPPEResult,
  setAnswerPhotos,
  setAnswerVideos,
  setAnswerFile,
  setTemporaryPhotoURI,
  setTemporaryVideoURI,
  reset,
  setCameraImageSize
};

export type ReportAction = ActionType<typeof reportActions>;
export type ReportState = {
  readonly cameraActive: boolean;
  readonly cameraFormat: 'photo' | 'video';
  readonly selectedReportKey: string | null;
  readonly selectedReport: Report | ReportSection | null;
  readonly selectedReportCalibratingSKU: { [skuKey: string]: boolean } | null;
  readonly selectedReportCalibratingSKUTimestamp: string | null;
  readonly temporaryPhotoURI: string | null;
  readonly temporaryVideoURI: string | null;
  readonly questionIndex: number;
  readonly drawerVisible: boolean;
  readonly questionIndexTransition: number | null;
  readonly cameraImageSize: string;
};

const initialState: ReportState = {
  cameraImageSize: Constants.IMAGE_SIZE.VGA,
  cameraActive: false,
  cameraFormat: 'photo',
  selectedReportKey: null,
  selectedReport: null,
  selectedReportCalibratingSKU: null,
  selectedReportCalibratingSKUTimestamp: null,
  temporaryPhotoURI: null,
  temporaryVideoURI: null,
  questionIndex: 0,
  drawerVisible: false,
  // Temp value for external components (e.g. drawer) to change the index
  // The variable index can only be changed via swiper
  questionIndexTransition: null
  // Will only exist in multi site report
};

export const reportReducer: Reducer<ReportState, ReportAction> = (state = initialState, incomingAction: any) => {
  switch (incomingAction.type) {
    case SET_CAMERA_IMAGE_SIZE: {
      return {
        ...state,
        cameraImageSize: incomingAction.payload.imageSize
      };
    }
    case SELECT_REPORT: {
      return {
        ...state,
        selectedReportKey: incomingAction.payload.key,
        selectedReport: incomingAction.payload.value,
        questionIndex: 0,
        questionIndexTransition: null
      };
    }
    case SET_CAMERA_ACTIVE:
      return {
        ...state,
        cameraActive: incomingAction.payload.bool
      };
    case SET_CAMERA_FORMAT:
      return {
        ...state,
        cameraFormat: incomingAction.payload.format
      };
    case SET_REPORT_EMAIL_TARGETS:
      return update(state, {
        selectedReport: {
          emailTargets: { $set: incomingAction.payload.targets }
        }
      });
    case SET_REPORT_CALIBRATING_SKU:
      return {
        ...state,
        selectedReportCalibratingSKU: incomingAction.payload.calibratingSKU,
        selectedReportCalibratingSKUTimestamp: incomingAction.payload.datetime
      };
    case SET_REPORT_STATUS:
      return update(state, {
        selectedReport: {
          status: { $set: incomingAction.payload.status }
        }
      });
    case SAVE_REPORT_CHECKOUT_TIME:
      return update(state, {
        selectedReport: {
          datetimeOut: { $set: incomingAction.payload.timestamp }
        }
      });
    case SAVE_REPORT_SUBMISSION_TIME:
      return update(state, {
        selectedReport: {
          datetimeSubmitted: { $set: incomingAction.payload.timestamp }
        }
      });
    case TOGGLE_DRAWER_VISIBILITY:
      return {
        ...state,
        drawerVisible: !state.drawerVisible
      };
    case SET_DRAWER_QUESTION_INDEX:
      return {
        ...state,
        drawerVisible: false,
        questionIndexTransition: incomingAction.payload.index
      };
    case SET_QUESTION_INDEX:
      return {
        ...state,
        questionIndex: incomingAction.payload.index,
        questionIndexTransition: null
      };
    case SELECT_ANSWER_CHOICE:
      if (typeof incomingAction.payload.condition === 'number') {
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                conditionalQuestion: {
                  conditions: {
                    [state.selectedReport!.questions[incomingAction.payload.index].answer]: {
                      [incomingAction.payload.condition]: {
                        answer: { $set: incomingAction.payload.choice }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                answer: { $set: incomingAction.payload.choice }
              }
            }
          }
        });
      }

    case SELECT_ANSWER_CHOICE_SCORE:
      if (typeof incomingAction.payload.condition === 'number') {
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                conditionalQuestion: {
                  conditions: {
                    [state.selectedReport!.questions[incomingAction.payload.index].answer]: {
                      [incomingAction.payload.condition]: {
                        answer: { $set: incomingAction.payload.choice },

                        flag: { $set: incomingAction.payload.flag }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                answer: { $set: incomingAction.payload.choice },
                flag: { $set: incomingAction.payload.flag }
              }
            }
          }
        });
      }

    case SELECT_CHECKLIST_CHOICE:
      if (typeof incomingAction.payload.currentConditionIndex === 'number') {
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                conditionalQuestion: {
                  conditions: {
                    [state.selectedReport!.questions[incomingAction.payload.index].answer]: {
                      [incomingAction.payload.currentConditionIndex]: {
                        answer: {
                          $set: (() => {
                            if (typeof incomingAction.payload.choice !== 'undefined') {
                              if (incomingAction.payload.choice.length > 0) {
                                return incomingAction.payload.choice.join(',');
                              }
                              return '';
                            }
                            return '';
                          })()
                        },
                        answers: { $set: incomingAction.payload.choice }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                answer: {
                  $set: (() => {
                    if (typeof incomingAction.payload.choice !== 'undefined') {
                      if (incomingAction.payload.choice.length > 0) {
                        return incomingAction.payload.choice.join(',');
                      }
                      return '';
                    }
                    return '';
                  })()
                },
                answers: { $set: incomingAction.payload.choice }
              }
            }
          }
        });
      }
    case INPUT_ANSWER_TEXT:
      if (typeof incomingAction.payload.currentConditionIndex === 'number') {
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                conditionalQuestion: {
                  conditions: {
                    [state.selectedReport!.questions[incomingAction.payload.index].answer]: {
                      [incomingAction.payload.currentConditionIndex]: {
                        answer: { $set: incomingAction.payload.text }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                answer: { $set: incomingAction.payload.text }
              }
            }
          }
        });
      }

    case INPUT_ANSWER_INVENTORY:
      return update(state, {
        selectedReport: {
          questions: {
            [incomingAction.payload.index]: {
              inventory: { [incomingAction.payload.type]: { $set: incomingAction.payload.inventory } }
            }
          }
        }
      });
    case INPUT_ANSWER_SKU_INVENTORY:
      return update(state, {
        selectedReport: {
          questions: {
            [incomingAction.payload.index]: {
              skuInventory: {
                [incomingAction.payload.skuKey]: {
                  [incomingAction.payload.type]: { $set: incomingAction.payload.input },
                  downloadedImage: { $set: incomingAction.payload.downloadedImage }
                }
              }
            }
          }
        }
      });
    case INPUT_ANSWER_COMMENT:
      if (typeof incomingAction.payload.currentConditionIndex === 'number') {
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                conditionalQuestion: {
                  conditions: {
                    [state.selectedReport!.questions[incomingAction.payload.index].answer]: {
                      [incomingAction.payload.currentConditionIndex]: {
                        comment: { $set: incomingAction.payload.text }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                comment: { $set: incomingAction.payload.text }
              }
            }
          }
        });
      }
    case INPUT_ANSWER_COMPETITOR_ANALYSIS:
      return update(state, {
        selectedReport: {
          questions: {
            [incomingAction.payload.index]: {
              competitorAnalysis: { [incomingAction.payload.type]: { $set: incomingAction.payload.text } }
            }
          }
        }
      });
    case INPUT_ANSWER_PPE_RESULT:
      if (typeof incomingAction.payload.currentConditionIndex === 'number') {
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                conditionalQuestion: {
                  conditions: {
                    [state.selectedReport!.questions[incomingAction.payload.index].answer]: {
                      [incomingAction.payload.currentConditionIndex]: {
                        detectPPEResult: { $set: incomingAction.payload.text }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                detectPPEResult: { $set: incomingAction.payload.text }
              }
            }
          }
        });
      }

    case SET_ANSWER_PHOTOS:
      if (typeof incomingAction.payload.currentConditionIndex === 'number') {
        let key =
          typeof incomingAction.payload.currentConditonalKey === 'string'
            ? incomingAction.payload.currentConditonalKey
            : state.selectedReport!.questions[incomingAction.payload.index].answer;
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                conditionalQuestion: {
                  conditions: {
                    [key]: {
                      [incomingAction.payload.currentConditionIndex]: {
                        photos: { $set: incomingAction.payload.photos },
                        downloadedImage: { $set: incomingAction.payload.downloadedImage }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                photos: { $set: incomingAction.payload.photos },
                downloadedImage: { $set: incomingAction.payload.downloadedImage }
              }
            }
          }
        });
      }

    case SET_ANSWER_VIDEOS:
      if (typeof incomingAction.payload.currentConditionIndex === 'number') {
        let key =
          typeof incomingAction.payload.currentConditonalKey === 'string'
            ? incomingAction.payload.currentConditonalKey
            : state.selectedReport!.questions[incomingAction.payload.index].answer;
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                conditionalQuestion: {
                  conditions: {
                    [key]: {
                      [incomingAction.payload.currentConditionIndex]: {
                        videos: { $set: incomingAction.payload.videos }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                videos: { $set: incomingAction.payload.videos }
              }
            }
          }
        });
      }
    case SET_ANSWER_FILE:
      if (typeof incomingAction.payload.currentConditionIndex === 'number') {
        let key =
          typeof incomingAction.payload.currentConditonalKey === 'string'
            ? incomingAction.payload.currentConditonalKey
            : state.selectedReport!.questions[incomingAction.payload.index].answer;
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                conditionalQuestion: {
                  conditions: {
                    [key]: {
                      [incomingAction.payload.currentConditionIndex]: {
                        documents: { $set: incomingAction.payload.documents }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                documents: { $set: incomingAction.payload.documents }
              }
            }
          }
        });
      }
    case SET_TEMPORARY_PHOTO_URI:
      return {
        ...state,
        temporaryPhotoURI: incomingAction.payload.uri
      };
    case SET_TEMPORARY_VIDEO_URI:
      return {
        ...state,
        temporaryVideoURI: incomingAction.payload.uri
      };
    case SET_ANSWER_REMEDY:
      if (typeof incomingAction.payload.currentConditionIndex === 'number') {
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                conditionalQuestion: {
                  conditions: {
                    [state.selectedReport!.questions[incomingAction.payload.index].answer]: {
                      [incomingAction.payload.currentConditionIndex]: {
                        remedy: { $set: incomingAction.payload.text }
                      }
                    }
                  }
                }
              }
            }
          }
        });
      } else {
        return update(state, {
          selectedReport: {
            questions: {
              [incomingAction.payload.index]: {
                remedy: { $set: incomingAction.payload.text }
              }
            }
          }
        });
      }
    case RESET:
      return initialState;
    default:
      return state;
  }
};
