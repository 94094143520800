import { createAsyncAction, createReducer, ActionType } from 'typesafe-actions';
import { put, call, select } from 'redux-saga/effects';
import { MONGO_API } from '../../../constants/api';
import { Company_BASE, Product, ProductCategory, Company, CompanyTargetMarket } from 'nimbly-common';
import firebase from 'firebase';
import i18n from 'i18next';
import { RootState } from '../../reducers';

export const FETCH_COMPANIES_REQUEST = '@company/FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = '@company/FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILURE = '@company/FETCH_COMPANIES_FAILURE';

export const CREATE_COMPANY_REQUEST = '@company/CREATE_COMPANY_REQUEST';
export const CREATE_COMPANY_SUCCESS = '@company/CREATE_COMPANY_SUCCESS';
export const CREATE_COMPANY_FAILURE = '@company/CREATE_COMPANY_FAILURE';

export const FETCH_PRODUCT_CATEGORIES_REQUEST = '@company/FETCH_PRODUCT_CATEGORIES_REQUEST';
export const FETCH_PRODUCT_CATEGORIES_SUCCESS = '@company/FETCH_PRODUCT_CATEGORIES_SUCCESS';
export const FETCH_PRODUCT_CATEGORIES_FAILURE = '@company/FETCH_PRODUCT_CATEGORIES_FAILURE';

export const CREATE_PRODUCT_CATEGORY_REQUEST = '@company/CREATE_PRODUCT_CATEGORY_REQUEST';
export const CREATE_PRODUCT_CATEGORY_SUCCESS = '@company/CREATE_PRODUCT_CATEGORY_SUCCESS';
export const CREATE_PRODUCT_CATEGORY_FAILURE = '@company/CREATE_PRODUCT_CATEGORY_FAILURE';

export const CREATE_PROMOTION_REQUEST = '@company/CREATE_PROMOTION_REQUEST';
export const CREATE_PROMOTION_SUCCESS = '@company/CREATE_PROMOTION_SUCCESS';
export const CREATE_PROMOTION_FAILURE = '@company/CREATE_PROMOTION_FAILURE';

export const CREATE_PRODUCT_REQUEST = '@company/CREATE_PRODUCT_REQUEST';
export const CREATE_PRODUCT_SUCCESS = '@company/CREATE_PRODUCT_SUCCESS';
export const CREATE_PRODUCT_FAILURE = '@company/CREATE_PRODUCT_FAILURE';

export const FETCH_PRODUCTS_REQUEST = '@company/FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = '@company/FETCH_PRODUCTS_LOADING';
export const FETCH_PRODUCTS_FAILURE = '@company/FETCH_PRODUCTS_FAILURE';

export const FETCH_PRODUCT_TARGETMARKET_REQUEST = '@company/FETCH_PRODUCT_TARGETMARKET_REQUEST';
export const FETCH_PRODUCT_TARGETMARKET_SUCCESS = '@company/FETCH_PRODUCT_TARGETMARKET_LOADING';
export const FETCH_PRODUCT_TARGETMARKET_FAILURE = '@company/FETCH_PRODUCT_TARGETMARKET_FAILURE';

export const CREATE_PRODUCT_TARGETMARKET_REQUEST = '@company/CREATE_PRODUCT_TARGETMARKET_REQUEST';
export const CREATE_PRODUCT_TARGETMARKET_SUCCESS = '@company/CREATE_PRODUCT_TARGETMARKET_LOADING';
export const CREATE_PRODUCT_TARGETMARKET_FAILURE = '@company/CREATE_PRODUCT_TARGETMARKET_FAILURE';

export const fetchCompaniesAsync = createAsyncAction(
  FETCH_COMPANIES_REQUEST,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE
)<undefined, Company_BASE[], string>();

export const createCompanyAsync = createAsyncAction(
  CREATE_COMPANY_REQUEST,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAILURE
)<CreateCompanyPayload, Company[], string>();

export const fetchProductCategoriesAsync = createAsyncAction(
  FETCH_PRODUCT_CATEGORIES_REQUEST,
  FETCH_PRODUCT_CATEGORIES_SUCCESS,
  FETCH_PRODUCT_CATEGORIES_FAILURE
)<undefined, ProductCategory[], string>();

export const fetchProductTargetMarketAsync = createAsyncAction(
  FETCH_PRODUCT_TARGETMARKET_REQUEST,
  FETCH_PRODUCT_TARGETMARKET_SUCCESS,
  FETCH_PRODUCT_TARGETMARKET_FAILURE
)<undefined, CompanyTargetMarket[], string>();

export const createProductCategoryAsync = createAsyncAction(
  CREATE_PRODUCT_CATEGORY_REQUEST,
  CREATE_PRODUCT_CATEGORY_SUCCESS,
  CREATE_PRODUCT_CATEGORY_FAILURE
)<CreateProductCategoryPayload, ProductCategory[], string>();

export const createProductTargetMarketAsync = createAsyncAction(
  CREATE_PRODUCT_TARGETMARKET_REQUEST,
  CREATE_PRODUCT_TARGETMARKET_SUCCESS,
  CREATE_PRODUCT_TARGETMARKET_FAILURE
)<CreateProductTargetMarketPayload, CompanyTargetMarket[], string>();

export const createPromotionAsync = createAsyncAction(
  CREATE_PROMOTION_REQUEST,
  CREATE_PROMOTION_SUCCESS,
  CREATE_PROMOTION_FAILURE
)<CreatePromotionPayload, undefined, string>();

export const createProductAsync = createAsyncAction(
  CREATE_PRODUCT_REQUEST,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_FAILURE
)<CreateProductPayload, undefined, string>();

export const fetchProductAsync = createAsyncAction(
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE
)<undefined, { data: Product[] }, string>();

type CreateCompanyPayload = {
  name: string;
  logo: string;
  description: string;
  website: string;
  established: number;
  noOfEmployees: number;
  strength: string[];
  weakness: string[];
};

type CreateProductCategoryPayload = {
  categoryName: string;
};

type CreateProductTargetMarketPayload = {
  targetMarketName: string;
};

export type CreatePromotionPayload = {
  companyCode: string;
  createdAt: string;
  createdBy: string;
  detail: string;
  displayLocation: string;
  endDate: string;
  imageURL: string;
  isEndDateIndefinite: boolean;
  organizationID: string;
  productCode: string;
  startDate: string;
  title: string;
  type: string;
  updatedAt: string;
  updatedBy: string;
};

export type CreateProductPayload = {
  categories: { label: string; value: string }[];
  companyCode: string;
  createdAt: string;
  createdBy: string;
  description: string;
  disabled: boolean;
  image: string;
  name: string;
  organizationID: string;
  price: {
    rangeFrom: number;
    rangeTo: number;
  };
  stores: { label: string; value: string }[];
  targetMarket: { label: string; value: string }[];
  updatedAt: string;
  updatedBy: string;
  variant: string;
};

export const companyActions = {
  fetchCompaniesAsync,
  createCompanyAsync,
  fetchProductCategoriesAsync,
  createProductCategoryAsync,
  createPromotionAsync,
  createProductAsync,
  fetchProductAsync,
  fetchProductTargetMarketAsync,
  createProductTargetMarketAsync
};

const api = MONGO_API;

export function* fetchCompaniesSaga(): Generator {
  try {
    const authToken = (yield firebase.auth().currentUser!.getIdToken()) as string;
    const request = () =>
      fetch(`${api}/competitor/companies`, {
        headers: {
          authorization: authToken
        },
        method: 'GET'
      });

    const response = (yield call(request)) as Body;

    const body = (yield response.json()) as any;
    if (body.message !== 'APP:SUCCESS') {
      throw new Error();
    }

    yield put(fetchCompaniesAsync.success(body.data));
  } catch (err) {
    yield put(fetchCompaniesAsync.failure(i18n.t('saga:company.fetch.error')));
  }
}

export function* createCompanySaga(action: any): Generator {
  try {
    const reqBody = JSON.stringify(action.payload);
    const authToken = (yield firebase.auth().currentUser!.getIdToken()) as string;
    const request = () =>
      fetch(`${api}/competitor/companies`, {
        headers: {
          authorization: authToken
        },
        method: 'POST',
        body: reqBody
      });

    const response = (yield call(request)) as Body;
    const body = (yield response.json()) as any;

    if (body.message !== 'APP:SUCCESS') {
      throw new Error();
    }

    const state: any = yield select();
    const companies: Company[] = state.company.companies;

    companies.unshift(body.data);
    yield put(createCompanyAsync.success(companies));
  } catch (err) {
    yield put(createCompanyAsync.failure(i18n.t('saga:company.create.error')));
  }
}

export function* fetchProductSaga(): Generator {
  try {
    const authToken = (yield firebase.auth().currentUser!.getIdToken()) as string;
    const request = () =>
      fetch(`${api}/competitor/products`, {
        headers: {
          authorization: authToken
        },
        method: 'GET'
      });

    const response = (yield call(request)) as Body;

    const body = (yield response.json()) as any;
    if (body.message !== 'APP:SUCCESS') {
      throw new Error();
    }
    yield put(fetchProductAsync.success(body.data));
  } catch (err) {
    yield put(fetchProductAsync.failure(i18n.t('saga:company.fetchProduct.error')));
  }
}

export function* fetchProductCategoriesSaga(): Generator {
  try {
    const authToken = (yield firebase.auth().currentUser!.getIdToken()) as string;
    const request = () =>
      fetch(`${api}/competitor/categories`, {
        headers: {
          authorization: authToken
        },
        method: 'GET'
      });

    const response = (yield call(request)) as Body;

    const body = (yield response.json()) as any;

    if (body.message !== 'APP:SUCCESS') {
      throw new Error();
    }

    yield put(fetchProductCategoriesAsync.success(body.data));
  } catch (err) {
    yield put(fetchProductCategoriesAsync.failure(i18n.t('saga:company.fetchProductCategories.error')));
  }
}

export function* fetchProductTargetMarketSaga(): Generator {
  try {
    const authToken = (yield firebase.auth().currentUser!.getIdToken()) as string;
    const request = () =>
      fetch(`${api}/competitor/target-market`, {
        headers: {
          authorization: authToken
        },
        method: 'GET'
      });

    const response = (yield call(request)) as Body;

    const body = (yield response.json()) as any;

    if (body.message !== 'APP:SUCCESS') {
      throw new Error();
    }

    yield put(fetchProductTargetMarketAsync.success(body.data));
  } catch (err) {
    yield put(fetchProductTargetMarketAsync.failure(i18n.t('saga:company.fetchTargetMarket.error')));
  }
}

export function* createProductCategorySaga(action: any): Generator {
  try {
    const stateSelector = (reduxState: RootState) => reduxState;
    const currentState: RootState = (yield select(stateSelector)) as RootState;
    const organizationID = currentState.firebase.profile.organization;

    const reqBody = JSON.stringify(action.payload);
    const authToken = (yield firebase.auth().currentUser!.getIdToken()) as string;
    const request = () =>
      fetch(`${api}/competitor/categories`, {
        headers: {
          authorization: authToken
        },
        method: 'POST',
        body: reqBody
      });

    const response = (yield call(request)) as Body;

    const body = (yield response.json()) as any;
    if (body.message !== 'APP:SUCCESS') {
      throw new Error();
    }

    const state: any = yield select();
    const productCategories: ProductCategory[] = state.company.productCategories;

    productCategories.unshift({ code: body.data, categoryName: action.payload.categoryName, organizationID });

    yield put(createProductCategoryAsync.success(productCategories));
  } catch (err) {
    yield put(createProductCategoryAsync.failure(i18n.t('saga:company.createCategory.error')));
  }
}

export function* createProductTargetMarketSaga(action: any): Generator {
  try {
    const reqBody = JSON.stringify(action.payload);
    const authToken = (yield firebase.auth().currentUser!.getIdToken()) as string;
    const request = () =>
      fetch(`${api}/competitor/target-market`, {
        headers: {
          authorization: authToken
        },
        method: 'POST',
        body: reqBody
      });

    const response = (yield call(request)) as Body;

    const body = (yield response.json()) as any;
    if (body.message !== 'APP:SUCCESS') {
      throw new Error();
    }

    const state: any = yield select();
    const productTargetMarkets: CompanyTargetMarket[] = state.company.productTargetMarket;

    productTargetMarkets.unshift({ targetMarketName: action.payload.targetMarketName, code: body.data });

    yield put(createProductTargetMarketAsync.success(productTargetMarkets));
  } catch (err) {
    yield put(createProductTargetMarketAsync.failure(i18n.t('saga:company.createTargetMarket.error')));
  }
}

export function* createPromotionSaga(action: any): Generator {
  try {
    const reqBody = JSON.stringify(action.payload);
    const authToken = (yield firebase.auth().currentUser!.getIdToken()) as string;
    const request = () =>
      fetch(`${api}/competitor/promotions`, {
        headers: {
          authorization: authToken
        },
        method: 'POST',
        body: reqBody
      });

    const response = (yield call(request)) as Body;
    const body = (yield response.json()) as any;

    if (body.message !== 'APP:SUCCESS') {
      throw new Error();
    }
    yield put(createPromotionAsync.success());
  } catch (err) {
    yield put(createPromotionAsync.failure(i18n.t('saga:company.createPromotion.error')));
  }
}

export function* createProductSaga(action: any): Generator {
  try {
    const reqBody = JSON.stringify(action.payload);
    const authToken = (yield firebase.auth().currentUser!.getIdToken()) as string;
    const request = () =>
      fetch(`${api}/competitor/products`, {
        headers: {
          authorization: authToken,
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: reqBody
      });

    const response = (yield call(request)) as Body;
    const body = (yield response.json()) as any;

    if (body.message !== 'APP:SUCCESS') {
      throw new Error('failed to create product');
    }

    yield put(createProductAsync.success());
  } catch (err) {
    yield put(createProductAsync.failure(i18n.t('saga:company.createProduct.error')));
  }
}

export type CompanyAction = ActionType<typeof companyActions>;
export type CompanyState = {
  readonly companies: Company_BASE[];
  readonly productCategories: { code: string; categoryName: string }[];
  readonly error: string | null;
  readonly isLoading: boolean;
  readonly products: any;
  readonly productTargetMarket: { code: string; targetMarketName: string }[];
};

const initialState: CompanyState = {
  companies: [],
  productCategories: [],
  error: null,
  isLoading: false,
  products: [],
  productTargetMarket: []
};

export const companyReducer = createReducer<CompanyState, CompanyAction>(initialState)
  .handleAction(fetchProductAsync.request, state => ({
    ...state,
    isLoading: true,
    error: null
  }))
  .handleAction(fetchProductAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload
  }))
  .handleAction(fetchProductAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    products: action.payload
  }))
  .handleAction(fetchCompaniesAsync.request, state => ({
    ...state,
    isLoading: true,
    error: null
  }))
  .handleAction(fetchCompaniesAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload
  }))
  .handleAction(fetchCompaniesAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    companies: action.payload
  }))
  .handleAction(createCompanyAsync.request, state => ({
    ...state,
    isLoading: true,
    error: null
  }))
  .handleAction(createCompanyAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload
  }))
  .handleAction(createCompanyAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    companies: action.payload
  }))
  .handleAction(fetchProductCategoriesAsync.request, (state: any) => ({
    ...state,
    isLoading: true,
    error: null
  }))
  .handleAction(fetchProductCategoriesAsync.failure, (state: any, action: any) => ({
    ...state,
    isLoading: false,
    error: action.payload
  }))
  .handleAction(fetchProductCategoriesAsync.success, (state: any, action: any) => ({
    ...state,
    isLoading: false,
    productCategories: action.payload
  }))
  .handleAction(createProductCategoryAsync.request, (state: any) => ({
    ...state,
    isLoading: true,
    error: null
  }))
  .handleAction(createProductCategoryAsync.failure, (state: any, action: any) => ({
    ...state,
    isLoading: false,
    error: action.payload
  }))
  .handleAction(createProductCategoryAsync.success, (state: any, action: any) => ({
    ...state,
    isLoading: false,
    productCategories: action.payload
  }))
  .handleAction(createPromotionAsync.request, (state: any) => ({
    ...state,
    isLoading: true,
    error: null
  }))
  .handleAction(createPromotionAsync.failure, (state: any, action: any) => ({
    ...state,
    isLoading: false,
    error: action.payload
  }))
  .handleAction(createPromotionAsync.success, (state: any) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(createProductAsync.request, (state: any) => ({
    ...state,
    isLoading: true,
    error: null
  }))
  .handleAction(createProductAsync.failure, (state: any, action: any) => ({
    ...state,
    isLoading: false,
    error: action.payload
  }))
  .handleAction(createProductAsync.success, (state: any) => ({
    ...state,
    isLoading: false
  }))
  .handleAction(fetchProductTargetMarketAsync.request as any, (state: any) => ({
    ...state,
    isLoading: true,
    error: null
  }))
  .handleAction(fetchProductTargetMarketAsync.failure as any, (state: any, action: any) => ({
    ...state,
    isLoading: false,
    error: action.payload
  }))
  .handleAction(fetchProductTargetMarketAsync.success as any, (state: any, action: any) => ({
    ...state,
    isLoading: false,
    productTargetMarket: action.payload
  }))
  .handleAction(createProductTargetMarketAsync.request as any, (state: any) => ({
    ...state,
    isLoading: true,
    error: null
  }))
  .handleAction(createProductTargetMarketAsync.failure as any, (state: any, action: any) => ({
    ...state,
    isLoading: false,
    error: action.payload
  }))
  .handleAction(createProductTargetMarketAsync.success as any, (state: any, action: any) => ({
    ...state,
    isLoading: false,
    productTargetMarket: action.payload
  }));
