import firebase from 'firebase';
import { createAsyncAction, createReducer, ActionType } from 'typesafe-actions';
import { put, call, select } from 'redux-saga/effects';

import { RootState } from '../../reducers';
import { Organization } from 'nimbly-common';
import { apiProdURL } from '../../../constants/api';

export const FETCH_MY_ORGANIZATION_REQUEST = '@organization/FETCH_MY_ORGANIZATION_REQUEST';
export const FETCH_MY_ORGANIZATION_SUCCESS = '@organization/FETCH_MY_ORGANIZATION_SUCCESS';
export const FETCH_MY_ORGANIZATION_FAILURE = '@organization/FETCH_MY_ORGANIZATION_FAILURE';

export const fetchMyOrganizationAsync = createAsyncAction(
  FETCH_MY_ORGANIZATION_REQUEST,
  FETCH_MY_ORGANIZATION_SUCCESS,
  FETCH_MY_ORGANIZATION_FAILURE
)<undefined | number, Organization, Error>();

export const organizationActions = { fetchMyOrganizationAsync };

export function* fetchMyOrganizationSaga(action: ReturnType<typeof fetchMyOrganizationAsync.request>): Generator {
  const tryCount = action.payload || 1;
  try {
    const organizationID = (yield select((state: RootState) => state.firebase.profile.organization)) as string;
    const idToken = (yield firebase.auth().currentUser!.getIdToken()) as string;
    const request = () =>
      fetch(`${apiProdURL}/organizations/id/${organizationID}`, {
        method: 'GET',
        headers: {
          authorization: idToken
        }
      });
    const response = (yield call(request)) as Body;
    const body = (yield response.json()) as any;
    yield put(fetchMyOrganizationAsync.success(body.data));
  } catch (err) {
    // Retry up to 3 times before throwing a failure
    if (tryCount >= 3) {
      yield put(fetchMyOrganizationAsync.request(tryCount + 1));
    } else {
      yield put(fetchMyOrganizationAsync.failure(err));
    }
  }
}

export type OrganizationAction = ActionType<typeof organizationActions>;
export type OrganizationState = {
  readonly isLoading: boolean;
  readonly lastUpdated: number | null;
  readonly errorMessage: string | null;
  readonly myOrganization: Organization | null | undefined;
};

const initialState: OrganizationState = {
  isLoading: false,
  lastUpdated: null,
  errorMessage: null,
  myOrganization: undefined
};

export const organizationReducer = createReducer<OrganizationState, OrganizationAction>(initialState)
  .handleAction(fetchMyOrganizationAsync.request, state => ({
    ...state,
    isLoading: true,
    errorMessage: null
  }))
  .handleAction(fetchMyOrganizationAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    errorMessage: action.payload.message
  }))
  .handleAction(fetchMyOrganizationAsync.success, (state, action) => ({
    ...state,
    isLoading: false,
    lastUpdated: Date.now(),
    myOrganization: action.payload || null
  }));
