import { takeLatest, put, call, select } from 'redux-saga/effects';
import { QuestionnaireIndex, Questionnaire } from 'nimbly-common';
import * as nimblyApi from '../../../constants/api';
import * as actions from './questionnaire.action';
import * as types from './questionnaire.actionType';
import { RootState } from '../../../store/reducers';
import { getFirebase } from 'react-redux-firebase';
import { fetchUsersSaga } from '../user/user';
import { QuestionnaireData, QuestionnaireOrdered, QuestionnaireIndexKey } from './type';

export function* fetchQuestionnaires() {
  try {
    const firebase = getFirebase();
    if (firebase.auth) {
      yield put(actions.setLoading(true));
      const authToken = (yield firebase.auth().currentUser!.getIdToken()) as string;

      const options = {
        method: 'GET',
        headers: {
          authorization: authToken
        }
      };

      const url = `${nimblyApi.NIMBLY_API_QUESTIONNAIRES}`;
      const request = () => fetch(url, options);
      const response = yield call(request);

      if (response && response.status === 200) {
        const result = yield response.json();
        const mappedQuestionnaire: { [id: string]: Questionnaire } = {};

        result.data.forEach((questionnaire: Questionnaire, i: number) => {
          const { questionnaireID } = questionnaire;
          if (!mappedQuestionnaire.hasOwnProperty(questionnaireID)) {
            mappedQuestionnaire[questionnaireID] = questionnaire;
          }
        });
        yield put(actions.fetchQuestionnairesAsync.success({ data: mappedQuestionnaire }));
        return null;
      }

      const result = yield response.json();
      yield put(actions.fetchQuestionnairesAsync.failure({ error: result.message }));
      return null;
    }
  } catch (err) {
    yield put(actions.fetchQuestionnairesAsync.success({ data: {} }));
    yield put(actions.fetchQuestionnairesAsync.failure({ error: '' }));
    return null;
  }
}

function* getQuestionnairesIndex() {
  try {
    const firebase = getFirebase();
    if (firebase.auth) {
      const authToken = (yield firebase.auth().currentUser!.getIdToken()) as string;
      const options = {
        method: 'GET',
        headers: {
          Authorization: authToken
        }
      };

      const getQuestionairesIndexURL = `${nimblyApi.NIMBLY_API_QUESTIONNAIRES_INDEX}`;
      const request = () => fetch(getQuestionairesIndexURL, options);
      const response = yield call(request);
      if (response && response.status === 200) {
        const respData = yield response.json();

        const mappingData: { [key: string]: QuestionnaireIndex } = {};

        respData.data.forEach((q: QuestionnaireIndex) => {
          const qIndexKey: string = q.questionnaireIndexID;
          if (!mappingData.hasOwnProperty(qIndexKey)) {
            mappingData[qIndexKey] = q;
          }
        });
        return mappingData;
      }
    }
  } catch (e) {
    return null;
  }

  return null;
}

function* getQuestionnaires() {
  try {
    const firebase = getFirebase();
    if (firebase.auth) {
      const authToken = (yield firebase.auth().currentUser!.getIdToken()) as string;

      const options = {
        method: 'GET',
        headers: {
          Authorization: authToken
        }
      };
      const getQuestionairesIndexURL = `${nimblyApi.NIMBLY_API_QUESTIONNAIRES}`;
      const request = () => fetch(getQuestionairesIndexURL, options);
      const response = yield call(request);
      if (response && response.status === 200) {
        const respData = yield response.json();
        const mappingData: { [key: string]: Questionnaire } = {};
        respData.data.forEach((questionnaire: Questionnaire) => {
          const questionnaireKey: string = questionnaire.questionnaireID;
          mappingData[questionnaireKey] = questionnaire;
        });

        return mappingData;
      }
    }
  } catch (e) {
    return null;
  }
  return null;
}

export function* getPopulatedQuestionnaires() {
  const getState = (state: RootState) => state;
  const state = yield select(getState);

  try {
    const questionnaireIndex = yield getQuestionnairesIndex();
    const questionnaires = yield getQuestionnaires();
    let users = state.user.users;

    if (!users) {
      users = yield fetchUsersSaga();
    }

    const questionnaireKeys: QuestionnaireIndexKey[] = [];

    for (const qIndex of Object.keys(questionnaireIndex)) {
      const { versions } = questionnaireIndex[qIndex];
      if (!versions || !Array.isArray(versions) || versions.length === 0) {
        continue;
      }
      const latestVersion = questionnaireIndex[qIndex].latest;
      questionnaireKeys.push({ parent: qIndex, version: latestVersion });
    }
    const data: QuestionnaireData = {};
    const ordered: QuestionnaireOrdered = [];

    if (questionnaireIndex && questionnaires) {
      questionnaireKeys.forEach((k: { parent: string; version: string }) => {
        if (questionnaires[k.version]) {
          const questionnaire = questionnaires[k.version];
          questionnaire.nameModifiedBy = questionnaire.modifiedBy ? users[questionnaire.modifiedBy].displayName : '';

          data[k.parent] = questionnaire;
          ordered.push({ key: k.parent, value: questionnaire });
        }
      });
      yield put(actions.getPopulatedQuestionnairesAsync.success({ data, ordered, index: questionnaireIndex }));
    } else {
      return yield put(actions.getPopulatedQuestionnairesAsync.success({ data: {}, ordered: [], index: {} }));
    }
  } catch (e) {
    actions.getPopulatedQuestionnairesAsync.failure({ error: e.message });
  }
}
