// Definition of classes to be used in the app
import { BaseMeasure } from '../constants/unitOfMeasure';
import { LanguageType } from '../constants/Languages';
// import { any // this is firestore Timestamp } from '@firebase/firestore-types';
// import { unitOfMeasure } from 'types/constants';
import { CheckListTypeV2 } from '@nimbly-technologies/nimbly-common';

export type Language = LanguageType;
export type FlagColor = 'red' | 'yellow' | 'green';
export type UserRoleString = 'auditor' | 'supervisor' | 'admin' | 'superadmin';
export type UserStatus = 'fresh' | 'active' | 'disabled';

export type DeviceInfo = {
  binaryVersion: string;
  carrier: string;
  locale: string;
  phoneBrand: string;
  phoneModel: string;
  phoneOS: string;
  phoneOSVersion: string;
};

// User with profile and permissions
export class User {
  constructor(
    public role: UserRoleString,
    public email: string,
    public phoneNumber: string,
    public displayName: string,
    public organization: string,
    public photoURL: string,
    public status: UserStatus,
    public createdBy: string,
    public language: Language = 'en',
    public uuid: string = '',
    public deviceInfo: null | DeviceInfo = null,
    public organizationID?: string,
    public userID?: string,
    public level?: number
  ) {}
}

// User Role (permissions will be deprecated in UserRole)
export interface UserRole {
  _id: string;
  organizationID: string;
  role: string;
  label: string;
  resources: UserRoleResource[];
  resourceMap: UserRoleResourceMap;
}

export interface UserRoleResponse {
  _id: string;
  organizationID: string;
  role: string;
  label: string;
  resources: UserRoleResource[];
}

export interface UserRoleResource {
  _id: string;
  permission: ('view' | 'create' | 'edit' | 'delete')[];
  resource: string;
}

export interface UserRoleResourceMap {
  [permission: string]: {
    view?: boolean;
    create?: boolean;
    edit?: boolean;
    delete?: boolean;
  };
}

export type PeriodUnit = 'year' | 'month' | 'quarter' | 'week';

export class OrganizationSchedule {
  constructor(
    /** Complex manual schedule is the only option */
    public type: 'manual' = 'manual',
    /** moment-timezone string */
    public timezone: string = '',
    /** size of each period */
    public periodUnit: PeriodUnit = 'week',
    /** how many of that unit makes up one repeat (e.g. 1 report per periodLength weeks) */
    public periodLength: number = 1,
    /**
     * numbered day of period to dictate the start of that period
     * (e.g. 1 for Monday of week, 28 for 28 of month)
     */
    public periodStart: number = 1
  ) {}
}

export type Feature = 'video' | 'scoring' | 'questionnaireDueTime';

export type OrganizationFeature = {
  [K in Feature]: boolean;
};

// Organization
export class Organization {
  name: string;
  emailDomain: string;
  organizationID: string;
  schedule: OrganizationSchedule;
  // geoPrecision set to 'none' means that geo check-in is disabled
  geoPrecision: 'low' | 'medium' | 'high' | 'none';
  // reportFormat either in Word or PDF
  reportFormat: 'xlsx' | 'pdf';
  reportSortByFlag: boolean;
  // allowCalibration will allow empty addresses/coordinates on sites, which will be populated on first check-in
  allowCalibration: boolean;
  // allowGallery will allow pictures to be taken from the gallery
  allowGallery: boolean;
  // allowRecommendations will allow the organization to use smart recommendations
  allowRecommendations: boolean;
  // allowAuditorStockIn will allow auditors to input stock in for inventory-v2 questions
  allowAuditorStockIn: boolean;
  // allowAuditorEditIssueMeta will allow auditors to change meta fields (assigned dept, status, and due date)
  allowAuditorEditIssueMeta: boolean;
  // allowSupervisorEditQuestionnaire will allow 'supervisor' role to edit questionnaire (along with 'admin' role)
  allowSupervisorEditQuestionnaire: boolean;
  // allowAutoEscalateIssue will automatically set issue to high priority once past due date
  allowAutoEscalateIssue: boolean;
  // allow adhoc report
  allowAdhocReport?: {
    [role: string]: boolean;
  };
  // allow user to take high resolution photo
  highResPhoto: boolean;
  features?: Partial<OrganizationFeature>;

  constructor(
    name = '',
    emailDomain = '',
    schedule: OrganizationSchedule = new OrganizationSchedule(),
    geoPrecision: 'low' | 'medium' | 'high' = 'high',
    reportFormat: 'xlsx' | 'pdf' = 'pdf',
    reportSortByFlag = false,
    allowCalibration = false,
    allowGallery = false,
    allowRecommendations = false,
    allowAuditorStockIn = false,
    allowAuditorEditIssueMeta = false,
    allowSupervisorEditQuestionnaire = false,
    allowAutoEscalateIssue = false,
    highResPhoto = false
  ) {
    this.name = name;
    this.emailDomain = emailDomain;
    this.schedule = schedule;
    this.geoPrecision = geoPrecision;
    this.reportFormat = reportFormat;
    this.reportSortByFlag = reportSortByFlag;
    this.allowCalibration = allowCalibration;
    this.allowGallery = allowGallery;
    this.allowRecommendations = allowRecommendations;
    this.allowAuditorStockIn = allowAuditorStockIn;
    this.allowAuditorEditIssueMeta = allowAuditorEditIssueMeta;
    this.allowSupervisorEditQuestionnaire = allowSupervisorEditQuestionnaire;
    this.allowAutoEscalateIssue = allowAutoEscalateIssue;
    this.highResPhoto = highResPhoto;
    this.features = {};
  }
}

// Department - category falling under Organization
// Used to group Sites
export class Department {
  constructor(
    public name: string = '',
    public description: string = '',
    public email: string = '',
    public departmentID?: string,
    public organizationID?: string
  ) {}
}

// Index of department keys, each assigned to boolean true if Site is under it
interface DepartmentIndex {
  [departmentKey: string]: boolean;
}

// Map of departments, with a number for the user's hierarchy level in each
// Higher number means higher in the hierarchy
export interface DepartmentMap {
  [departmentKey: string]: number;
}

// Coordinates for GPS positioning
export class Coordinates {
  constructor(public latitude: number, public longitude: number) {}
}

export type CurrentCoordinates = {
  mocked: boolean;
  timestamp: number;
  coords: {
    accuracy: number;
    altitude: number;
    heading: number;
    latitude: number;
    longitude: number;
  };
};

// Site with details on location and slots to assign auditor and questionnaire
export class Site {
  name: string;
  // Records other optional data like mall name
  subtitle: string;
  photoURL: string;
  country: string;
  province: string;
  city: string;
  locationName: string;
  address: string;
  coordinates: null | Coordinates;
  // periodLength: [DEPRECATED] number of reports to be done per organizational period
  reportsPerPeriod: number;
  // Value should be empty if children exist (see below)
  assignedQuestionnaire: string;
  // The auditor in charge of the site, who will also function as the team leader (see below)
  assignedAuditor: string;
  // emailTargets are a string Array of emails
  emailTargets: string[];
  // owner: The user (usually with role 'supervisor') who will "own" the site
  owner: string;
  // departments: The categories which this site falls under, usually only 1
  // This will allow for sites to be listed under a particular brand, group, supplier, etc.
  departments: DepartmentIndex;
  // Optionally assign more auditors to the site who can contribute
  // Only team leaders can submit reports
  team: string[];
  // A list of children that can make up the large site (optional)
  children: SiteChild[];
  signatures: number;
  isMultiSite: boolean;
  timezone: string | null;
  /** in minutes */
  utcOffset: number | null;
  siteID?: string;
  organizationID?: string;
  radius?: string | null;

  constructor(
    name: string,
    subtitle: string,
    photoURL: string,
    country: string,
    province: string,
    city: string,
    locationName: string,
    address: string,
    coordinates: null | Coordinates,
    reportsPerPeriod: number,
    assignedQuestionnaire: string,
    assignedAuditor: string,
    emailTargets: string[],
    owner: string,
    departments: DepartmentIndex,
    team: string[] = [],
    children: SiteChild[] = [],
    signatures = 0,
    isMultiSite = false,
    timezone: null | string = null,
    utcOffset: null | number = null,
    radius: null | string = null
  ) {
    this.name = name;
    this.subtitle = subtitle;
    this.photoURL = photoURL || '';
    this.country = country;
    this.province = province;
    this.city = city;
    this.locationName = locationName;
    this.address = address;
    this.coordinates = coordinates;
    this.reportsPerPeriod = reportsPerPeriod || -1;
    this.assignedQuestionnaire = assignedQuestionnaire || '';
    this.assignedAuditor = assignedAuditor || '';
    this.emailTargets = emailTargets || [];
    this.owner = owner || '';
    this.departments = departments || {};
    this.team = team;
    this.children = children;
    this.signatures = signatures;
    this.isMultiSite = isMultiSite;
    this.timezone = timezone;
    this.utcOffset = utcOffset;
    this.radius = radius;
  }
}

// Small child 'site' that makes up the overall site
// Each child can have its own questionnaire
export class SiteChild {
  name: string;
  assignedQuestionnaire: string;
  isRequired: boolean;
  dayLimit?: number;
  assignedAuditor?: string;

  constructor(name: string, assignedQuestionnaire: string, isRequired = true, dayLimit = 1, assignedAuditor = '') {
    this.name = name || '';
    this.assignedQuestionnaire = assignedQuestionnaire || '';
    this.isRequired = isRequired;
    this.dayLimit = dayLimit;
    this.assignedAuditor = assignedAuditor;
  }
}

export class RangeOptions {
  rangeFrom: number;
  rangeTo: number;
  flag: FlagColor | '';
  constructor(rangeFrom: number, rangeTo: number, flag: FlagColor | '') {
    this.rangeFrom = rangeFrom || 0;
    this.rangeTo = rangeTo || 0;
    this.flag = flag || '';
  }
}
export class InventoryQuestion {
  in: number;
  out: number;
  final: number;
  // comment is an optional string
  comment?: string;
  // photos are a string array of download urls
  photos?: string[];
  documents?: any[];
  isFilledByUser?: boolean;

  constructor() {
    this.in = 0;
    this.out = 0;
    this.final = -1;
  }
}

export class ConditionalQuestion<T = any> {
  /**
   * conditions is a key-value property for conditional tree
   *
   * @example
   * cq.conditions = {
   *    yes: T,
   *    no: T
   * }
   */
  conditions: {
    [key: string]: Question[];
  };
  /** matchingQuestion is for syncing up different branches of answer, and will be counted as the same question in statistic
   *
   * @example
   * cq.matchingQuestion = {
   *    'yes.1': 'no.1',
   * }
   */
  matchingQuestion: {
    [key: string]: string;
  };

  constructor(cq: Partial<ConditionalQuestion>) {
    this.conditions = cq.conditions || {};
    this.matchingQuestion = cq.matchingQuestion || {};
  }

  /** Add branch based on answer key */
  // addCondition(key: string, branch: T) {
  //   this.conditions[key] = branch;
  // }

  /** Remove branch based on answer key */
  // removeCondition(key: string) {
  //   if (this.conditions[key]) delete this.conditions[key];
  // }
}
export type Option = {
  label: string;
  value: string;
};

export class CompetitorAnalysisQuestion {
  company: Option | null = null;
  form: 'promotion' | 'product';
  promotionTitle: string;
  promotionProduct: Option | null = null;
  promotionType: Option | null = null;
  promotionDisplayLocation: string;
  promotionDetail: string;
  promotionStartDate: string;
  promotionEndDate: string;
  promotionIndefiniteEndDate: number;
  productName: string;
  productPriceFrom: string;
  productPriceTo: string;
  productCategory: Option | null = null;
  productDescription: string;
  productTargetMarket: Option | null = null;
  productVariant: Option | null = null;
  productStores: Option | null = null;
  constructor() {
    this.form = 'promotion';
    this.promotionTitle = '';
    this.promotionDisplayLocation = '';
    this.promotionDetail = '';
    this.promotionIndefiniteEndDate = 0;
    this.productName = '';
    this.productPriceFrom = '';
    this.productPriceTo = '';
    this.productDescription = '';
    this.promotionStartDate = '';
    this.promotionEndDate = '';
  }
}

export type QuestionType =
  | 'open'
  | 'number'
  | 'inventory'
  | 'inventory-v2'
  | 'binary'
  | 'binary-with-red-flag'
  | 'score'
  | 'checklist'
  | 'multiple-choice'
  | 'multiple-choice-score'
  | 'range-flag'
  | 'competitor';

export class Question {
  // 'binary-with-red-flag' will allow the user to put a red flag to immediately fail the report
  type: QuestionType;
  content: string;
  flag: string;
  // reference is an optional URL
  reference: string;
  // photoLimit takes any number
  photoLimit: number;
  // videoLimit takes any number
  videoLimit: number;
  // document takes any number
  documentLimit: number;
  // photoMinimum takes any number
  photoMinimum: number;
  // videoMinimum takes any number (disabled from now)
  videoMinimum: number;

  // potentialRemedies is an array of strings (see remedies below)
  potentialRemedies: string[];
  // `multipleChoices` is an array of strings
  multipleChoices: string[];
  // `checklists` is an array of strings
  checklists: string[];
  // answer is a string or number
  answer: string | number;
  // answers is an array of string or numbers, if the answer is array
  answers: string[] | number[];
  // comment is an optional string
  comment: string;
  // photos are a string array of download urls
  photos: string[];
  // videos are a string array of videos urls
  videos: string[];
  // videos are a string array of videos urls
  // category is a string for reference
  category: string;
  // score is a number
  score: number;
  // scoreWeight is a number representing relative score of the question compared to others. Default is equal to value of score
  scoreWeight: number;
  // answerRequired is boolean true if answer is required
  answerRequired: boolean;
  // remedy is an processed string that is an effective subset of potentialRemedies
  remedy: string;
  sku: string;
  inventory?: InventoryQuestion | null;
  skuInventory?: { [key: string]: InventoryQuestion } | null;
  competitorAnalysis?: CompetitorAnalysisQuestion | null;
  rangeOptions: RangeOptions[];
  maxRange: number;
  minRange: number;
  flagLabel?: FlagLabel | null;
  categoryID?: string;
  detectPPE?: string;
  detectPPEResult?: string;
  // Competitor Analysis
  // competitorAnalysis?: CompetitorAnalysisQuestion | null;
  // Conditional Questions
  /** Flag if the question is conditional */
  isConditional?: boolean;
  // Flag if question allows gallery as photo source
  allowGallery?: boolean;
  /** Object for conditional data */
  conditionalQuestion?: ConditionalQuestion | null;
  documentMinimum?: number;
  documents?: string[];
  checklistsV2?: CheckListTypeV2[];
  constructor(
    type: QuestionType = 'inventory',
    content: string = '',
    reference: string = '',
    photoLimit: number = 0,
    videoLimit: number = 0,

    photoMinimum: number = 0,
    videoMinimum: number = 0,
    potentialRemedies: string[] = [],
    multipleChoices: string[] = [],
    checklists: string[] = [],
    answer: string | number = '',
    answers: string[] | number[] = [],
    comment: string = '',
    photos: any = [],
    videos: any = [],

    category: string = '',
    score: number = 0,
    scoreWeight: number = 0,
    answerRequired: boolean = true,
    remedy: string = '',
    /** Stock identifier for inventory type questionnaire */
    sku: string = '',
    rangeOptions: RangeOptions[] = [],
    maxRange: number = 0,
    minRange: number = 0,
    flagLabel = null,
    categoryID = '',
    detectPPE = 'none',
    detectPPEResult = '',
    // competitorAnalysis = null,
    isConditional = false,
    allowGallery = false,
    conditionalQuestion: ConditionalQuestion,
    documents: any = [],
    documentLimit: number = 0,
    flag: string,
    checklistsV2 = [] as CheckListTypeV2[]
  ) {
    this.type = type || 'binary';
    this.content = content || '';
    this.reference = reference || '';
    this.photoLimit = photoLimit || 0;
    this.videoLimit = videoLimit || 0;
    this.documentLimit = documentLimit || 0;
    this.photoMinimum = photoMinimum || 0;
    this.videoMinimum = videoMinimum || 0;
    this.potentialRemedies = potentialRemedies || [];
    this.multipleChoices = multipleChoices || [];
    this.checklists = checklists || [];
    this.answer = answer || '';
    this.answers = answers || [];
    this.comment = comment || '';
    this.photos = photos || [];
    this.videos = videos || [];
    this.documents = documents || [];
    this.category = category || '';
    this.score = score || 0;
    this.scoreWeight = scoreWeight || score || 0;
    this.answerRequired = answerRequired;
    this.remedy = remedy || '';
    this.sku = sku || '';
    this.inventory = sku && this.type === 'inventory' ? { in: -1, out: -1, final: -1 } : null;
    this.skuInventory = this.type === 'inventory-v2' ? {} : null;
    this.rangeOptions = rangeOptions || [];
    this.maxRange = maxRange || 0;
    this.minRange = minRange || 0;
    this.flagLabel = flagLabel || null;
    this.categoryID = categoryID;
    this.detectPPE = detectPPE;
    this.detectPPEResult = detectPPEResult;
    // this.competitorAnalysis = competitorAnalysis;
    this.isConditional = isConditional;
    this.allowGallery = allowGallery;
    this.conditionalQuestion = conditionalQuestion;
    this.flag = flag;
    this.checklistsV2 = checklistsV2;
  }
}
export interface FlagLabel {
  green: string;
  yellow: string;
  red: string;
}
export type ExtendedQuestion = {
  parentIndex?: number;
  parentAnswer?: string;
  no?: number;
  parentNumber?: number;
} & Question;

export type QuestionnaireStatus = 'draft' | 'published';

// Questionnaire is a collection of questions
export class Questionnaire {
  title: string;
  questions: Question[];
  status: QuestionnaireStatus;
  dateCreated: string;
  dateUpdated: string;
  // A disabled questionnaire will not be able to be seen or used
  disabled: boolean;
  type: 'default' | 'inventory' | 'inventory-v2';
  questionnaireIndex: string;
  modifiedBy: string;

  constructor(
    title: string,
    questions: Question[],
    status: QuestionnaireStatus,
    dateCreated: string,
    dateUpdated: string,
    type: 'default' | 'inventory' | 'inventory-v2' = 'default',
    questionnaireIndex = '',
    modifiedBy: string
  ) {
    this.title = title || '';
    this.questions = questions || [];
    this.status = status || 'draft';
    this.dateCreated = dateCreated || '';
    this.dateUpdated = dateUpdated || '';
    this.disabled = false;
    this.type = type;
    this.questionnaireIndex = questionnaireIndex || '';
    this.modifiedBy = modifiedBy || '';
  }
}

export class SiteQuestionnaireDeadline {
  isEnabled: boolean; // whether or not questionnaire has deadline
  startTime: string; // HH:mm format
  endTime: string; // HH:mm format
  remindTime: number; // 0 | 15 | 30 mins
  assignedAuditor: string[]; // list of auditors assigned to the questionnaire
  questionnaireIndexKey: string; // FK to be queried upon
  siteKey: string; // FK to be queried upon
  siteQuestionnaireScheduleKey: string; // FK to be queried upon

  constructor(
    isEnabled: boolean,
    startTime: string,
    endTime: string,
    remindTime: number,
    assignedAuditor: string[],
    questionnaireIndexKey: string,
    siteKey: string,
    siteQuestionnaireScheduleKey: string
  ) {
    this.isEnabled = isEnabled || false;
    this.startTime = startTime || '';
    this.endTime = endTime || '';
    this.remindTime = remindTime || 30;
    this.assignedAuditor = assignedAuditor || [''];
    this.questionnaireIndexKey = questionnaireIndexKey;
    this.siteKey = siteKey;
    this.siteQuestionnaireScheduleKey = siteQuestionnaireScheduleKey;
  }
}

export interface EmailTarget {
  email: string;
  enabled: boolean;
  setByAdmin: boolean;
  status: 'unsent' | 'sent' | 'failed';
}

/**
 * ReportSection represents a child's portion of the report
 * It will contain the questions that represents the child's assignedQuestionnaire
 * It will also point to the SiteChild and auditor who did the section
 */
export class ReportSection {
  questions: Question[];
  // siteChild is a number representing the index of the site child
  siteChild: number;
  doneBy: string;
  datetimeIn: string;
  datetimeOut: string;
  status: ReportStatus;
  isRequired: boolean;
  questionnaire: string | undefined;
  datetimeUpdated: string;
  datetimeSubmitted?: string;

  constructor(
    questions: Question[],
    questionnaire: string,
    siteChild: number,
    doneBy: string,
    datetimeIn: string,
    datetimeOut: string,
    status: ReportStatus,
    isRequired: boolean,
    datetimeUpdated: string,
    datetimeSubmitted?: string
  ) {
    this.questions = questions;
    this.questionnaire = questionnaire;
    this.siteChild = siteChild;
    this.doneBy = doneBy;
    this.datetimeIn = datetimeIn || '';
    this.datetimeOut = datetimeOut || '';
    this.status = status;
    this.isRequired = isRequired;
    this.datetimeUpdated = datetimeUpdated;
    this.datetimeSubmitted = datetimeSubmitted;
  }
}

// ReportSectionIndex acts as a map of the questions after the report is compiled
export class ReportSectionIndex {
  constructor(
    /**
     * @see `Site.children[]`
     */
    public startIndex: number,
    public siteChild: number,
    /**
     * @see `User`
     */
    public doneBy: string,
    public datetimeIn: string = '',
    public datetimeOut: string = '',
    /**
     * @see `QuestionnaireIndex`
     */
    public questionnaire: string,
    /**
     * @see `Questionnaire`
     *
     * new
     */
    public questionnaireVersion?: string
  ) {}
}

export interface IReportSectionIndex {
  /**
   * @see `Site.children[]`
   */
  startIndex: number;
  siteChild: number;
  /**
   * @see `User`
   */
  doneBy: string;
  /**
   * @see `QuestionnaireIndex`
   */
  questionnaire: string;
  /**
   * @see `Questionnaire`
   *
   * new
   */
  questionnaireVersion: string;
  /**
   * new for firestore
   */
  checkInAt: any; // this is firestore Timestamp;
  /**
   * new for firestore
   */
  checkOutAt: null | any; // this is firestore Timestamp;
}

export interface Signature {
  name: string;
  position: string;
  isSigned: boolean;
  path: string;
}

export interface SelfieSignature {
  title: string;
  path: string;
}

/**
 * Report is a set of questions with additional metadata
 * Report key `YYYY-MM-DD_Moment.now()`
 */

export type ReportStatus = 'draft' | 'complete' | 'expired' | 'rejected' | 'approved' | 'waiting_for_upload';

export type ScheduleStatus =
  | 'pending-upload'
  | 'none-due'
  | 'ready'
  | 'makeup'
  | 'not-allowed-makeup'
  | 'draft'
  | 'finished-today'
  | 'finished-period'
  | 'adhoc'
  | 'error-questionnaire-not-found'
  | 'overdue';

export enum ScheduleStatusEnum {
  PENDING_UPLOAD = 'pending-upload',
  NONE_DUE = 'none-due',
  READY = 'ready',
  MAKEUP = 'makeup',
  DRAFT = 'draft',
  FINISHED_TODAY = 'finished-today',
  FINISHED_PERIOD = 'finished-period',
  ADHOC = 'adhoc',
  ERROR_QUESTIONNAIRE_NOT_FOUND = 'error-questionnaire-not-found',
  ERROR_ORGANIZATION_SCHEDULE_INVALID = 'error-organization-schedule-invalid',
  NOT_ALLOWED_MAKEUP = 'not-allowed-makeup',
  ONGOING = 'ongoing',
  OVERDUE = 'overdue'
}

export class Report {
  questions: Question[];
  sections: ReportSectionIndex[];
  status: ReportStatus;
  site: string;
  auditor: string;
  // dueDate is the targetted due date for the report submission
  dueDate: string;
  // datetimeIn and datetimeOut represent the check-in sequence for this report
  datetimeIn: string;
  datetimeOut: string;
  // datetimeSubmitted is the actual datetime for the report submission
  datetimeSubmitted: string;
  datetimeScheduled: string;
  datetimeUpdated: string;
  // isMakeUp is boolean true if report is a make-up report
  isMakeUp: boolean;
  // makeUpReason is a string for reason that report was missed
  // TODO: Reason for reports missed and have NO MAKE-UP
  makeUpReason: string;
  // emailTargets are an Array of EmailTarget objects
  emailTargets: EmailTarget[];
  signatures?: Signature[];
  selfieSignatures?: SelfieSignature[];
  // questionnaire id at the time report is made
  questionnaire?: string;
  isAdhoc: boolean;
  isGenerated: boolean;
  isIssueCreated: boolean;
  isInventoryAdjusted: boolean;
  isScheduleAdjusted: boolean;
  scheduleKey: string;
  // `hasDeadline` is boolean true if questionnaire is set to have a schedule/deadline
  hasDeadline: boolean;
  // startTime `number` in minutes relative to 12.00am of the current day
  startTime: number | null;
  // endTime `number` in minutes relative to 12.00am of the current day
  endTime: number | null;
  journeyKey?: string;
  journeyReportCode?: string;

  constructor(
    questions: Question[],
    sections: ReportSectionIndex[],
    status: ReportStatus,
    site: string,
    auditor: string,
    emailTargets: EmailTarget[],
    dueDate: string,
    datetimeIn: string,
    datetimeOut: string,
    datetimeSubmitted: string,
    datetimeScheduled: string,
    isMakeUp: boolean,
    makeUpReason: string,
    questionnaire?: string,
    signatures?: Signature[],
    selfieSignatures?: SelfieSignature[],
    isAdhoc = false,
    isGenerated = false,
    scheduleKey = '',
    hasDeadline = false,
    startTime: number | null = null,
    endTime: number | null = null,
    datetimeUpdated: string = new Date().toISOString(),
    journeyKey?: string,
    journeyReportCode?: string
  ) {
    this.questions = questions;
    this.sections = sections;
    this.status = status || 'draft';
    this.site = site || '';
    this.auditor = auditor || '';
    this.emailTargets = emailTargets || [];
    this.dueDate = dueDate || '';
    this.datetimeIn = datetimeIn || '';
    this.datetimeOut = datetimeOut || '';
    this.datetimeSubmitted = datetimeSubmitted || '';
    this.datetimeScheduled = datetimeScheduled;
    this.datetimeUpdated = datetimeUpdated;
    this.isMakeUp = isMakeUp || false;
    this.makeUpReason = makeUpReason || '';
    this.questionnaire = questionnaire;
    this.signatures = signatures;
    this.selfieSignatures = selfieSignatures;
    this.isAdhoc = isAdhoc;
    this.isGenerated = isGenerated;
    this.isIssueCreated = false;
    this.isInventoryAdjusted = false;
    this.isScheduleAdjusted = false;
    this.scheduleKey = scheduleKey;
    this.hasDeadline = hasDeadline;
    this.startTime = startTime;
    this.endTime = endTime;
    this.journeyKey = journeyKey;
    this.journeyReportCode = journeyReportCode;
  }
}

/**
 * The type serves as a lean data structure for `Payoff` screen props
 * which will be used as a payload through Broadcast message to navigate to `Payoff` screen
 */
export type PreGeneratedQuestion = Pick<Question, 'type' | 'answer' | 'score' | 'scoreWeight' | 'rangeOptions'>;
export type PreGeneratedReport = Pick<Report, 'datetimeIn' | 'datetimeOut'> & { questions: PreGeneratedQuestion[] };

type OmitOldReport =
  | 'datetimeIn'
  | 'datetimeOut'
  | 'datetimeSubmitted'
  | 'datetimeScheduled'
  | 'datetimeUpdated'
  | 'signatures'
  | 'sections'
  | 'isScheduleAdjusted'
  | 'dueDate';
/**
 * To be used in firestore
 */
export interface IReport extends Omit<Report, OmitOldReport> {
  /**
   * @ref `User`
   */
  siteOwnerKey: string;
  /**
   * @ref `User` - all auditors involved in this report. More than 1 if Team audit 1 if Single Audit
   */
  team: string[];
  /**
   * @ref `SiteScheduleEvent`
   */
  scheduleKey: string;
  sections: null | IReportSectionIndex[];
  signatures: Signature[];

  checkInAt: any; // this is firestore Timestamp;
  checkOutAt: any; // this is firestore Timestamp;
  submittedAt: any; // this is firestore Timestamp;
  scheduledAt: any; // this is firestore Timestamp;
  updatedAt: any; // this is firestore Timestamp;
  dueAt: any; // this is firestore Timestamp;
}
// FlagCount is an object with counters for 3 colors of flags
export interface FlagCount {
  green: number;
  yellow: number;
  red: number;
  [flag: string]: number;
}

// SiteSchedule is a list of dates with an assignedAuditor key
export interface SiteSchedule {
  assignedAuditor: string;
  [key: string]: string | number;
}

export interface AllSiteSchedules {
  [siteKey: string]: SiteSchedule;
}

export interface ReportSummary {
  auditor: string;
  datetimeIn: string;
  datetimeOut: string;
  datetimeUpdated: string;
  datetimeScheduled?: string;
  /**
   * @ref SiteScheduleEvent
   * empty string if adhoc
   */
  scheduleKey?: string;
  journeyKey?: string;
  siteKey?: string;
  status: 'draft' | 'complete' | 'expired';
  isAdhoc?: boolean;
  questionnaire?: string;
  isMakeUp?: boolean;
  flagCount?: FlagCount;
  scoreFlag?: FlagCount;
  scoreWeighted?: number;
  scoreRaw?: number;
  scoreTotal?: number;
  sections?: SectionSummary[];
  department?: string;
}

export interface ISectionSummary
  extends Omit<IReportSummary, 'team' | 'site' | 'sections' | 'scheduleKey' | 'isMakeUp' | 'isAdhoc' | 'scheduledAt'> {
  siteChild: number;
}

/**
 * For firestore
 */
export interface IReportSummary {
  /** Ref - User; If Team Audit then is leader */
  auditor: string;
  /** Ref - User */
  team: string[];
  /** Ref - Site */
  site: string;
  questionnaire: string;
  /**
   * @ref SiteScheduleEvent
   * empty string if adhoc
   */
  scheduleKey: string;
  isMakeUp: boolean;
  status: ReportStatus;
  isAdhoc: boolean;
  flagCount: null | FlagCount;
  scoreFlag: null | FlagCount;
  scoreWeighted: number;
  scoreRaw: number;
  scoreTotal: number;
  checkInAt: any; // this is firestore Timestamp;
  checkOutAt: any; // this is firestore Timestamp;
  scheduledAt: any; // this is firestore Timestamp;
  updatedAt: any; // this is firestore Timestamp;
  sections: null | ISectionSummary[];
}

// ReportIndex is a list of ReportSummary objects
export interface ReportIndex {
  [reportKey: string]: ReportSummary;
}

export interface OrganizationReportIndex {
  [siteKey: string]: ReportIndex;
}

export interface GlobalReportIndex {
  [organizationKey: string]: OrganizationReportIndex;
}
export interface SiteReport {
  [reportKey: string]: Report;
}

export interface OrganizationReport {
  [siteKey: string]: SiteReport;
}

export interface GlobalReport {
  [organizationKey: string]: OrganizationReport;
}

// DailyStatistic objects contain duration, flags, and report count properties per day
export interface DailyStatistic {
  totalDuration: number;
  totalFlags: FlagCount;
  totalReports: number;
  totalScoreWeighted: number;
  adhocReports: number;
  adhocDuration: number;
  adhocFlags: FlagCount;
}

export interface AllDailyStatistic {
  [date: string]: {
    [uid: string]: DailyStatistic;
  };
}

export type StatType = 'site' | 'auditor' | 'questionnaire' | 'overall' | 'owner' | 'department';

export interface AllUsers {
  [uid: string]: User;
}

export interface AllSites {
  [key: string]: Site;
}

export interface Schedule {
  [date: string]: number;
}
export interface SiteIndex {
  siteSchedules?: Schedule;
  adhocReports?: Schedule;
  site: string;
  datetimeUpdated: string;
  disabled: boolean;
}

export interface AuditorSiteIndex {
  [siteKey: string]: SiteIndex;
}

export interface OrganizationAuditorSiteIndex {
  [uid: string]: AuditorSiteIndex;
}

type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

type Section = {
  siteChild: number;
  questionnaire: string;
  isAdhoc?: boolean;
  datetimeScheduled?: string;
};

export interface SectionSummary extends Overwrite<ReportSummary, Section> {}

export interface QuestionnaireIndex {
  disabled: boolean;
  versions: string[];
  latest: string;
}

export interface AllQuestionnaireIndex {
  [key: string]: QuestionnaireIndex;
}

export interface InventoryReport {
  in: number;
  out: number;
  final: number;

  recordedBy: string;
  /**
   * skuKey
   */
  sku: string;
  siteKey: string;
  reportKey: string;
  poKey: string;
  inventoryDate: string;
  type: 'po' | 'report' | 'correction';

  /**
   * any // this is firestore Timestamp
   */
  updatedAt: string;
}

export interface SKUInventory {
  [sku: string]: InventoryReport;
}

export interface SiteInventory {
  [date: string]: SKUInventory;
}

export interface OrganizationSiteInventory {
  [siteKey: string]: SiteInventory;
}

export type IssueStatus = 'open' | 'resolved' | 'expired';

export type Priority = 'normal' | 'high';

export type Severity = 'red' | 'yellow';

export type MessageType =
  | 'reopened'
  | 'update'
  | 'comment'
  | 'assignedTo'
  | 'assignedDepartment'
  | 'dueDate'
  | 'status'
  | 'priority'
  | 'severity'
  | null;

export type MessageActionType = 'remove' | 'add' | 'update' | 'default';

export type MessageEntity = {
  actionType: MessageActionType;
  payload: string | string[];
};

export class IssueMessage {
  createdBy: string;
  createdByName: string;
  entity?: MessageEntity;
  message: string;
  createdAt: string;
  updatedAt: string;
  issueId: string;
  photos: string[];
  status: IssueStatus;
  messageType: MessageType;
  severity: Severity;

  constructor(
    createdBy: string,
    name: string,
    message: string,
    issueId: string,
    photos: string[] = [],
    status: IssueStatus = 'open',
    messageType: MessageType,
    severity: Severity,
    updatedAt: string = new Date().toISOString(),
    createdAt: string = new Date().toISOString()
  ) {
    this.createdBy = createdBy;
    this.createdByName = name;
    this.message = message;
    this.issueId = issueId;
    this.photos = photos;
    this.status = status;
    this.messageType = messageType;
    this.severity = severity;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
  }
}

export interface IssueMembers {
  [key: string]: boolean;
}

export class Issue {
  createdBy: string;
  assignedTo: string;
  questionText: string;
  photoLimit: number;
  resolvedBy: string;
  priority: Priority;
  severity: Severity;
  status: IssueStatus;
  category: string;
  site: string;
  section: number;
  members?: IssueMembers;
  assignedDepartments: string[];
  questionnaire: string;
  questionIndex: number;
  dueDate: string;
  createdAt: string;
  updatedAt: string;
  resolvedAt: string;

  constructor(
    assignedTo: string,
    createdBy: string,
    questionText: string,
    severity: Severity,
    site: string,
    questionnaire: string,
    questionIndex: number,
    section: number,
    photoLimit: number,
    duedate: string,
    category: string,
    departments: string[] = []
  ) {
    this.assignedTo = assignedTo;
    this.createdBy = createdBy;
    this.questionText = questionText;
    this.priority = 'normal';
    this.severity = severity;
    this.site = site;
    this.questionnaire = questionnaire;
    this.section = section;
    this.questionIndex = questionIndex;
    this.photoLimit = photoLimit;
    this.status = 'open';
    this.dueDate = duedate;
    this.category = category;
    this.assignedDepartments = departments;
    this.resolvedAt = '';
    this.resolvedBy = '';
    this.createdAt = new Date().toISOString();
    this.updatedAt = new Date().toISOString();
  }
}

export interface AllIssues {
  [key: string]: Issue;
}

export interface UserQuestionnaireScheduleNotification {
  atStart: boolean;
  atEnd: boolean;
  startWith: 'app' | 'email';
  endWith: 'app' | 'email';
}

export interface SiteQuestionnaireNotification {
  /** Site Reference */
  siteKey: string;
  /** QuestionnaireIndex Reference */
  questionnaireIndexKey: string;
  /** HH:mm */
  startTime: string;
  /** HH:mm */
  endTime: string;
  offsetTime: number;
  notifyAuditor: UserQuestionnaireScheduleNotification;
  notifySiteOwner: UserQuestionnaireScheduleNotification;
  isEnabled: boolean;
}

export type ScheduleType = 'daily' | 'weekly' | 'monthly' | 'custom';

export interface QuestionnaireDueTime {
  /** HH:mm */
  startTime: string;
  /** HH:mm */
  endTime: string;
  /** Notify supervisor and/or auditor if pass due time */
  isEnabled: boolean;
}

export interface SiteScheduleEvent {
  // FOREIGN KEYS START
  assignedAuditor: string;
  /**
   * for future implementation
   * may be empty string or null if used for Site is Site
   */
  questionnaireIndexKey: string;
  /** list of user keys */
  auditors: string[];
  /** list of questionnaireIndex keys */
  questionnaires: string[];
  siteKey: string;
  departmentKey: string;
  /** site owner at the time schedule is created */
  siteOwnerKey: string;
  createdBy: string;
  updatedBy: string;
  // FOREIGN KEYS END

  disabled: boolean;

  // CONFIG START

  signatures: number;
  emailTargets: string[];

  // CONFIG END

  type: ScheduleType;
  /**
   * If `type` is `'custom'` then `false` else `true`
   */
  isRecurring: boolean;
  /**
   * [YYYY-MM-DD] Date of when the earliest date this schedule event start
   */
  startDate: string;
  /**
   * [YYYY-MM-DD] Date of when the latest date this schedule event end
   */
  endDate: string;
  hasDeadline: boolean;
  /** in minutes */
  offsetTime: null | number;
  /**
   * [HH:mm] start working schedule
   */
  startTime: null | number;
  /**
   * [HH:mm] end working schedule
   */
  endTime: null | number;
  /**
   * @type `number` related to time in minutes
   * `null` if it is not in use
   */
  reminderTime: null | number;
  /**
   * for custom
   */
  datesCustom: null | { [date: string]: boolean };
  /**
   * for future implementation
   * for custom
   * [YYYY-MM-DD] date
   */
  date?: null | string;
  /**
   * for weekly schedule
   * iso week day 1 ~ 7; 1 === monday
   */
  daysOfWeek: null | number[];
  /**
   * for monthly schedule
   * date of month 1 ~ 31
   */
  datesOfMonth: null | number[];
  createdAt: any; // this is firestore Timestamp;
  updatedAt: any; // this is firestore Timestamp;
}
export interface QuestionnaireSchedule extends Omit<SiteScheduleEvent, 'createdAt' | 'updatedAt' | 'datesCustom'> {
  /** @deprecated was never used */
  dayLimit?: number;
  createdAt: number;
  updatedAt: number;
  datesCustom?: null | Schedule;
}

export interface AllQuestionnaireSchedule {
  [key: string]: QuestionnaireSchedule;
}

/**
 * Individual site's questionnaire schedule.
 * Unique questionnaire's schedule for each site
 */
export interface SiteQuestionnaireSchedule {
  [questionnaireKey: string]: QuestionnaireSchedule;
}

export interface AllSiteQuestionnaireSchedule {
  [siteKey: string]: SiteQuestionnaireSchedule;
}

export interface CustomQuestionnaireSchedule {
  [questionnaireKey: string]: Schedule;
}

/**
 * @enum `message` is set as default value
 * @enum `report_complete` allow broadcast to have `payload`
 * */
type BroadcastType = 'message' | 'report_complete';

/**
 * @enum `allUsers` to send to all users directly
 * @enum `users` to send to 1 or more users
 * */
type BroadcastTargetType = 'allUsers' | 'users' | 'departments' | 'sites';
export class Broadcast {
  message: string;
  createdBy: string;
  broadcasted: boolean;
  broadcastSchedule: string;
  broadcastType: BroadcastType;
  status: 'draft' | 'complete' | 'pending';
  /** organization's timezone */
  timezone: string;
  updatedAt: string;
  createdAt: string;
  /** broadcast targets */
  targetType: Extract<BroadcastTargetType, 'allUsers' | 'users'>;
  targets: string[];
  /** payload is stringified JSON */
  payload: string;

  constructor(
    message: string,
    createdBy: string,
    broadcasted: boolean,
    broadcastSchedule: string,
    broadcastType: BroadcastType = 'message',
    status: 'draft' | 'complete' | 'pending',
    timezone: string,
    updatedAt: string,
    createdAt: string = new Date().toISOString(),
    targetType: Extract<BroadcastTargetType, 'allUsers' | 'users'>,
    targets: string[] = [],
    payload = ''
  ) {
    this.message = message;
    this.createdBy = createdBy;
    this.broadcasted = broadcasted;
    this.broadcastSchedule = broadcastSchedule;
    this.broadcastType = broadcastType;
    this.status = status;
    this.timezone = timezone;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.targetType = targetType;
    this.targets = targets;
    this.payload = payload;
  }
}

/**
 * Just like Notification, but attached to the Site(s),
 * so when the member of site changes, new member can still see the message board list.
 * This is to cover broadcast that is targeted to site(s)/department(s).
 */
export class MessageBoard {
  mbJobID: string;
  message: string;
  createdBy: string;
  broadcasted: boolean;
  broadcastSchedule: string;
  broadcastType: BroadcastType;
  status: 'draft' | 'complete' | 'pending';
  /** organization's timezone */
  timezone: string;
  updatedAt: string;
  createdAt: string;
  /** broadcast targets */
  targetType: Extract<BroadcastTargetType, 'departments' | 'sites'>;
  targets?: { key: string; siteName: string }[];
  supervisorKey?: string;
  /** payload is stringified JSON */
  payload?: string;

  constructor(
    mbJobId: string,
    message: string,
    createdBy: string,
    broadcasted: boolean,
    broadcastSchedule: string,
    broadcastType: BroadcastType = 'message',
    status: 'draft' | 'complete' | 'pending',
    timezone: string,
    updatedAt: string,
    createdAt: string = new Date().toISOString(),
    targetType: Extract<BroadcastTargetType, 'departments' | 'sites'>,
    targets: { key: string; siteName: string }[] = [],
    payload = ''
  ) {
    this.mbJobID = mbJobId;
    this.message = message;
    this.createdBy = createdBy;
    this.broadcasted = broadcasted;
    this.broadcastSchedule = broadcastSchedule;
    this.broadcastType = broadcastType;
    this.status = status;
    /** organization's timezone */
    this.timezone = timezone;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.targetType = targetType;
    this.targets = targets;
    this.payload = payload;
  }
}

export class Promotion {
  title: string;
  message: string;
  startAt: string;
  endAt: string;
  status: 'active' | 'draft' | 'disabled';
  createdBy: string;
  recipientType: 'all' | 'departments' | 'sites';
  logo: string;
  logoURL: string;
  cover: string;
  coverURL: string;
  updatedAt: string;
  createdAt: string;

  constructor(
    title: string,
    message: string,
    startAt: string,
    endAt: string,
    status: 'active' | 'draft' | 'disabled',
    createdBy: string,
    recipientType: 'all' | 'departments' | 'sites',
    logo: string,
    cover: string,
    updatedAt: string,
    createdAt: string
  ) {
    this.title = title;
    this.message = message;
    this.startAt = startAt;
    this.endAt = endAt;
    this.status = status;
    this.createdBy = createdBy;
    this.recipientType = recipientType;
    this.logo = logo;
    this.cover = cover;
    this.coverURL = '';
    this.logoURL = '';
    this.updatedAt = updatedAt;
    this.createdAt = createdAt || new Date().toISOString();
  }
}

export interface SitePromotion {
  siteKey: string;
  promotionKey: string;
  endAt: string;
  updatedAt: string;
  createdAt: string;
}

export interface SKUTag {
  [tagName: string]: boolean;
}

export class Company {
  constructor(
    public id: number,
    public code: string,
    public companyName: string,
    public logo: string,
    public description: string,
    public website: string,
    public established: string,
    public noOfEmployees: number,
    public strength: string[],
    public weakness: string[],
    public products: { id: number }[],
    public promotions: { id: number }[],
    public createdAt: any //timestamp
  ) {}
}

// no need now
export interface OrganizationSKUInventory {
  organizationKey: string;
  sku: string;
  stock: number;
  updatedAt: string;
  updatedBy: string;
  createdAt: string;
  createdBy: string;
}

export interface SKUBatch {
  batchNumber: number | string;
  expiredAt: string;
  createdAt: string;
}

export type SKUThresholdType = 'percentage' | 'number';
export type SKUInOrigin = 'po' | 'onsite' | 'none';
/**
 * Profile of each SKU in an organization
 */
export class OrganizationSKU {
  constructor(
    // eslint-disable-next-line id-blacklist
    public number: string | number,
    public organizationKey: string,
    public name: string,
    public description: string,
    /**
     * Base retail price for this item. May be overiden by site's price
     */
    public price: number,
    public photoURLs: string[],
    public tags: SKUTag,
    public threshold: number,
    public thresholdType: SKUThresholdType,
    /**
     * `0` if not set
     */
    public maxLimit: number,
    public updatedBy: string,
    public createdBy: string,
    public unit: BaseMeasure,
    public inOrigin?: SKUInOrigin,
    public updatedAt: string = new Date().toISOString(),
    public createdAt: string = new Date().toISOString(),
    public disabled: boolean = false,
    public sites: { [siteKey: string]: boolean } = {},
    public batches: { [bactch: string]: SKUBatch } = {}
  ) {}
}

type ExportedSKUProfile = {
  sites: { key: string; name: string }[];
  tags: string[];
};
export interface OrganizationSKUData extends Overwrite<OrganizationSKU, ExportedSKUProfile> {}

export interface SiteSKUBatch {
  /**
   * Client without batch sku default to `0`
   */
  // eslint-disable-next-line id-blacklist
  number: string | number;
  stock: number;
  createdAt: string;
}

export interface SiteSKU {
  skuKey: string;
  siteKey: string;
  price: number;
  stock: number;
  threshold: number;
  thresholdType: 'percentage' | 'number';
  batches: { [key: string]: SiteSKUBatch };
  updatedAt: string;
  updatedBy: string;
  createdAt: string;
  createdBy: string;
  disabled: boolean;
}

export type VendorItemInfo = {
  disabled: boolean;
  price: number;
};

export type VendorItemList = {
  [skuKey: string]: VendorItemInfo;
};
export class Vendor {
  name: string;
  nameLowercase: string;
  description: string;
  city: string;
  address: string;
  email: string;
  items: VendorItemList;
  currency: string;
  updatedAt: string;
  createdAt: string;
  disabled: boolean;

  constructor(
    name: string,
    description: string,
    city: string,
    address: string,
    email: string,
    items: VendorItemList,
    currency = 'IDR',
    updatedAt: string = new Date().toISOString(),
    createdAt: string = new Date().toISOString()
  ) {
    this.name = name;
    this.nameLowercase = name.toLowerCase();
    this.description = description;
    this.city = city;
    this.address = address;
    this.email = email;
    this.items = items;
    this.currency = currency;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
    this.disabled = false;
  }
}

export type POStatus = 'pending' | 'onprogress' | 'delivered' | 'void';
export type POItemDetail = {
  skuKey: string;
  skuNumber: string | number;
  quantity: number;
  price: number;
  batchNumber: string | number;
  expiredAt: string;
};

export class PurchaseOrder {
  constructor(
    // eslint-disable-next-line id-blacklist
    public number: string | number,
    public items: { [sku: string]: boolean },
    public itemDetails: POItemDetail[],
    public vendorKey: string,
    public siteKey: string,
    public status: POStatus = 'pending',
    public confirmedBy: string,
    public emailTargets: string[],
    public note: string,
    public estimatedDelivery: string,
    public updatedBy: string,
    public createdBy: string,
    public confirmedAt: string,
    public updatedAt: string = new Date().toISOString(),
    public createdAt: string = new Date().toISOString(),

    public totalPrice: number = 0,
    public deliveredAt: string = '',
    public currency: string = 'IDR'
  ) {}
}

// Still not sure if junction table is needed
// export interface SKUTagSite {
//   skuKey: string;
//   siteKeys: { [siteKey: string]: boolean };
// }

export interface OrganizationSKUTag {
  organizationKey: string;
  tags: string[];
}

export interface ReportQueueData {
  organizationKey: string;
  siteKey: string;
  reportKey: string;
  report: Report;
}

export interface Activity {
  updatedBy: string;
  datetime: string;
}
export type SiteTabs = 'outlet' | 'reports' | 'calendar' | 'sales' | 'messages' | 'promotions';
