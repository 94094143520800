import update from 'immutability-helper';

import * as actions from './reportcache.action';
import * as actionTypes from './reportcache.actionTypes';

// Types
import { Reducer } from 'redux';
import { ActionType } from 'typesafe-actions';
import { OrganizationReportSections, MultiSummaries, SiteReportSections, ReportSections } from './reportcache.types';
import * as Classes from '../../../utils/classes';

export type ReportCacheAction = ActionType<typeof actions>;
export type ReportCacheState = {
  readonly reports: Classes.OrganizationReport;
  readonly summaries: Classes.OrganizationReportIndex;

  readonly multiReports: OrganizationReportSections;
  readonly multiSummaries: MultiSummaries;

  readonly backupReports: { [reportKey: string]: Classes.Report };
  readonly backupSummaries: { [reportKey: string]: Classes.ReportSummary };

  readonly isReady: boolean;
};

const initialState: ReportCacheState = {
  reports: {},
  summaries: {},

  multiReports: {},
  multiSummaries: {},

  backupReports: {},
  backupSummaries: {},

  isReady: false
};

const reportcacheReducer: Reducer<ReportCacheState, ReportCacheAction> = (
  state = initialState,
  incomingAction: ReportCacheAction
) => {
  switch (incomingAction.type) {
    case actionTypes.SET_CACHE_REPORT:
      return update(state, {
        reports: {
          [incomingAction.payload.siteKey]: siteReports =>
            update(siteReports || {}, {
              [incomingAction.payload.reportKey]: { $set: incomingAction.payload.report }
            })
        }
      });
    case actionTypes.SET_CACHE_REPORT_SUMMARY:
      return {
        ...state,
        summaries: {
          ...state.summaries,
          [incomingAction.payload.siteKey]: {
            [incomingAction.payload.reportKey]: incomingAction.payload.reportSummary
          }
        }
      };
    case actionTypes.SET_CACHE_REPORT_AND_SUMMARY:
      return {
        ...state,
        reports: {
          ...state.reports,
          [incomingAction.payload.siteKey]: {
            ...state.reports[incomingAction.payload.siteKey],
            [incomingAction.payload.reportKey]: incomingAction.payload.report
          }
        },
        summaries: {
          ...state.summaries,
          [incomingAction.payload.siteKey]: {
            ...state.summaries[incomingAction.payload.siteKey],
            [incomingAction.payload.reportKey]: incomingAction.payload.reportSummary
          }
        }
      };
    case actionTypes.REMOVE_CACHE_REPORT_AND_SUMMARY:
      return update(state, {
        reports: {
          [incomingAction.payload.siteKey]: (siteReports: { [reportKey: string]: Classes.Report }) =>
            update(siteReports || {}, {
              $unset: [incomingAction.payload.reportKey]
            })
        },
        summaries: {
          [incomingAction.payload.siteKey]: (siteSummaries: Classes.ReportIndex) =>
            update(siteSummaries || {}, {
              $unset: [incomingAction.payload.reportKey]
            })
        }
      });
    case actionTypes.SET_REPORT_SECTION_STATE:
      return update(state, {
        multiReports: {
          [incomingAction.payload.siteKey]: (siteReportSection: SiteReportSections) =>
            update(siteReportSection || {}, {
              [incomingAction.payload.reportKey]: (reportSections: ReportSections) =>
                update(reportSections || {}, {
                  [incomingAction.payload.sectionIdx]: {
                    $set: incomingAction.payload.report
                  }
                })
            })
        }
      });
    case actionTypes.REMOVE_SITE_REPORT_SECTION_STATE:
      return update(state, {
        multiReports: {
          $unset: [incomingAction.payload.siteKey]
        }
      });
    case actionTypes.REMOVE_REPORT_SECTION_STATE:
      return update(state, {
        multiReports: {
          [incomingAction.payload.siteKey]: {
            [incomingAction.payload.reportKey]: {
              $unset: [incomingAction.payload.sectionIdx]
            }
          }
        }
      });
    case actionTypes.INIT_REPORT_CACHE:
      return update(state, {
        reports: { $set: incomingAction.payload.reports },
        summaries: { $set: incomingAction.payload.summaries },
        multiReports: { $set: incomingAction.payload.multiReports },
        multiSummaries: { $set: incomingAction.payload.multiSummaries }
      });
    case actionTypes.SET_BACKUP_REPORT:
      return update(state, {
        backupReports: (allReports: { [reportKey: string]: Classes.Report }) =>
          update(allReports || {}, {
            [incomingAction.payload.reportKey]: { $set: incomingAction.payload.report }
          }),

        backupSummaries: (allSummaries: { [reportKey: string]: Classes.ReportSummary }) =>
          update(allSummaries || {}, {
            [incomingAction.payload.reportKey]: { $set: incomingAction.payload.summary }
          })
      });
    case actionTypes.REMOVE_BACKUP_REPORTS:
      return update(state, {
        backupReports: (allReports: { [reportKey: string]: Classes.Report }) =>
          update(allReports || {}, {
            $unset: incomingAction.payload.reportKeys
          }),
        backupSummaries: (allSummaries: { [reportKey: string]: Classes.ReportSummary }) =>
          update(allSummaries || {}, {
            $unset: incomingAction.payload.reportKeys
          })
      });
    case actionTypes.CLEAR_BACKUP_CACHE:
      return update(state, {
        backupReports: { $set: {} },
        backupSummaries: { $set: {} }
      });
    case actionTypes.SET_CACHE_READY:
      return {
        ...state,
        isReady: true
      };
    case actionTypes.CLEAR_CACHE:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

export default reportcacheReducer;
