import React from 'react';
import { useFirebase } from 'react-redux-firebase';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack/src/types';
// import MapView, { Region, Marker, Circle, EventUserLocation } from 'react-native-maps';
import { Dimensions, StyleSheet, AppState, AppStateStatus, View, ActivityIndicator, Alert, Image } from 'react-native';
import { useTranslation } from 'react-i18next';

// assets
import MapMarkerIcon from '../../assets/icons/map-marker.png';

// components
import Button from '../global/RoundedButton';
import Text from '../global/Text';
import fontMaker from '../../utils/font';

// types
import { CalibrationProps } from './Calibration';
import { HomeStackParamList } from '../../routes';

// utils
import { getDistance } from '../../utils/geolocation';
import { mapsConfig } from '../../constants/gmaps';
import { SCREEN_WIDTH } from '../../utils/screensize';
import theme from '../../constants/theme';

const CalibrationContainer = (props: CalibrationProps) => {
  const firebaseRedux = useFirebase();
  const navigation = useNavigation<StackNavigationProp<HomeStackParamList>>();
  const { t } = useTranslation(['calibration', 'common']);

  const [mapProperties, setMapProperties] = React.useState({
    radius: 0.25,
    latitudeDelta: 0.005,
    longitudeDelta: 0.0022
  });
  const [_isWithinCircle, setIsWithinCircle] = React.useState(false);
  const [_isValid, setIsValid] = React.useState(false);
  const [_isLoading, setIsLoading] = React.useState(false);
  const [_isBusy, setIsBusy] = React.useState(false);
  const [_isSubmitting, setIsSubmitting] = React.useState(false);

  const { auth, profile, selectedSite, selectedSiteKey, gpsLocation, pinLocation, hasNotch } = props;

  React.useEffect(() => {
    AppState.addEventListener('change', handleAppStateChange);
    switch (props.organization?.geoPrecision) {
      case 'high':
        setMapProperties({
          radius: 0.25,
          latitudeDelta: 0.005,
          longitudeDelta: 0.0022
        });
        break;
      case 'medium':
        setMapProperties({
          radius: 1,
          latitudeDelta: 0.025,
          longitudeDelta: 0.011
        });
        break;
      case 'low':
        setMapProperties({
          radius: 2.5,
          latitudeDelta: 0.025,
          longitudeDelta: 0.011
        });
        break;
      default:
        setMapProperties({
          radius: 2.5,
          latitudeDelta: 0.025,
          longitudeDelta: 0.011
        });
        break;
    }
    async function setCalibratingStateToDB() {
      await firebaseRedux.set(`/calibration/${profile.organization}/${selectedSiteKey}`, auth.uid);
    }
    setCalibratingStateToDB();
  }, []);

  const handleAppStateChange = (deviceState: AppStateStatus) => {
    if (deviceState !== 'active') {
      firebaseRedux.remove(`/calibration/${profile.organization}/${selectedSiteKey}`);
    } else {
      firebaseRedux.set(`/calibration/${profile.organization}/${selectedSiteKey}`, auth.uid);
    }
  };

  React.useEffect(() => {
    return () => {
      AppState.removeEventListener('change', handleAppStateChange);
      firebaseRedux.remove(`/calibration/${profile.organization}/${selectedSiteKey}`);
      props.onResetMap();
    };
  }, []);

  /** Sets the map pin location in redux to the centre of the map */
  // const handleRegionChanged = async (region: Region) => {
  //   // The first time this is fired, the map is usually not in the correct spot
  //   // Thus, workaround this by setting _isLoading to false
  //   if (_isLoading) {
  //     setIsLoading(false);
  //   }

  //   const location = {
  //     coordinates: { latitude: region.latitude, longitude: region.longitude },
  //     locationName: '',
  //     address: '',
  //     country: '',
  //     province: '',
  //     city: ''
  //   };
  //   props.onSetPinLocation(location);
  //   setIsBusy(true);

  //   const distance = getDistance(gpsLocation.coordinates, location.coordinates);
  //   if (distance == null || distance > mapProperties.radius) {
  //     setIsWithinCircle(false);
  //     setIsValid(false);
  //     setIsBusy(false);
  //   } else {
  //     // Parse the compound code to get province and country
  //     const res = await fetch(
  //       `https://maps.googleapis.com/maps/api/geocode/json?latlng=${region.latitude},${region.longitude}
  //     &key=${mapsConfig.apiKeys.geo}`
  //     );
  //     if (res.ok) {
  //       const body = await res.json();
  //       if (body.status === 'ZERO_RESULTS') {
  //         setIsWithinCircle(true);
  //         setIsValid(false);
  //         setIsBusy(false);
  //       }
  //       if (body.results.length > 0 && body.plus_code.compound_code) {
  //         const locationCompounds = body.plus_code.compound_code.split(', ');
  //         location.country = locationCompounds[locationCompounds.length - 1];
  //         location.province = locationCompounds[locationCompounds.length - 2];
  //         body.results.forEach((entry: any) => {
  //           if (!location.address) {
  //             if (
  //               entry.types &&
  //               entry.types[0] &&
  //               (entry.types.includes('street_address') || entry.types.includes('route'))
  //             ) {
  //               if (entry.hasOwnProperty('address_components') && entry.address_components.length > 0) {
  //                 location.address = entry.formatted_address;
  //               }
  //             }
  //           }
  //           if (!location.city) {
  //             if (entry.types && entry.types[0] && entry.types.includes('administrative_area_level_1')) {
  //               if (entry.hasOwnProperty('address_components') && entry.address_components.length > 0) {
  //                 location.city = entry.address_components[0].long_name;
  //               }
  //             }
  //           }
  //         });
  //         if (locationCompounds.length === 1) {
  //           location.country = location.country.slice(location.country.indexOf(' ') + 1);
  //         } else if (locationCompounds.length === 2) {
  //           location.province = location.province.slice(location.province.indexOf(' ') + 1);
  //         }
  //         props.onSetPinLocation(location);
  //         setIsWithinCircle(true);
  //         setIsValid(true);
  //         setIsBusy(false);
  //       }
  //     }
  //   }
  // };

  const handleUserLocationChanged = (event: EventUserLocation) => {
    const location = {
      coordinates: {
        latitude: event.nativeEvent.coordinate.latitude,
        longitude: event.nativeEvent.coordinate.longitude
      },
      locationName: '',
      address: '',
      country: '',
      province: '',
      city: ''
    };
    const distance = getDistance(gpsLocation.coordinates, location.coordinates)! * 1000;
    if (distance > 35) {
      props.onSetGPSLocation(location);
    }
  };

  const handleConfirmLocation = () => {
    Alert.alert(t('prompt.confirmLocation'), t('prompt.askCorrectLocation'), [
      {
        text: t('common:yes'),
        onPress: () => submitLocation()
      },
      {
        text: t('common:no')
      }
    ]);
  };

  const submitLocation = async () => {
    setIsSubmitting(true);
    // Update the site in Firebase
    await firebaseRedux.update(`/site/${profile.organization}/${selectedSiteKey}`, pinLocation);
    props.onModifySite(selectedSiteKey, { ...selectedSite, ...pinLocation });
    navigation.pop();
  };

  return (
    <View style={styles.root}>
      <View style={styles.mapContainer}>
        {/* <MapView
          style={styles.mapView}
          initialRegion={{
            latitude: gpsLocation.coordinates.latitude,
            longitude: gpsLocation.coordinates.longitude,
            latitudeDelta: mapProperties.latitudeDelta,
            longitudeDelta: mapProperties.longitudeDelta
          }}
          showsUserLocation={true}
          showsMyLocationButton={true}
          showsIndoors={false}
          zoomEnabled={false}
          scrollEnabled={!_isLoading && !_isSubmitting}
          onRegionChangeComplete={handleRegionChanged}
          onUserLocationChange={handleUserLocationChanged}
        >
          <Circle
            center={{
              latitude: gpsLocation.coordinates.latitude,
              longitude: gpsLocation.coordinates.longitude
            }}
            radius={mapProperties.radius * 1000}
            strokeWidth={2}
            strokeColor="rgba(80, 73, 178, 0.8)"
            fillColor="rgba(80, 73, 178, 0.2)"
          />
          <Marker
            coordinate={{ latitude: pinLocation.coordinates.latitude, longitude: pinLocation.coordinates.longitude }}
          />
        </MapView> */}
      </View>
      <View style={[styles.infoCard, hasNotch ? styles.infoCardNotch : null]}>
        {_isLoading || _isBusy ? (
          <View style={styles.infoLoading}>
            <ActivityIndicator size="large" color="#3cd070" />
          </View>
        ) : (
          <View style={styles.infoBody}>
            {_isWithinCircle ? (
              _isValid ? (
                <View style={styles.locationAddressContainer}>
                  <View>
                    <Image source={MapMarkerIcon} height={24} width={24} />
                  </View>
                  <View style={{ marginLeft: 12 }}>
                    <Text style={styles.locationAddress} numberOfLines={2}>
                      {pinLocation.address ||
                        `${pinLocation.coordinates.latitude}, ${pinLocation.coordinates.longitude}`}
                    </Text>
                  </View>
                </View>
              ) : (
                <Text style={styles.locationInvalid}>{t('error.invalidLocation')}</Text>
              )
            ) : (
              <Text style={styles.locationInvalid}>{t('error.correctLocation')}</Text>
            )}
          </View>
        )}
        {_isSubmitting ? (
          <View style={styles.buttonLoading}>
            <ActivityIndicator size="small" color="#3cd070" />
          </View>
        ) : (
          <Button
            disabled={
              _isBusy ||
              !_isWithinCircle ||
              !_isValid ||
              !pinLocation ||
              (pinLocation.coordinates.latitude === 0 && pinLocation.coordinates.longitude === 0)
            }
            style={styles.button}
            onPress={handleConfirmLocation}
            title={t('prompt.setLocation')}
          />
        )}
      </View>
    </View>
  );
};

const { height } = Dimensions.get('window');

const styles = StyleSheet.create({
  root: {
    flex: 1,
    flexDirection: 'column',
    backgroundColor: '#fff'
  },
  mapContainer: {
    flexGrow: 1,
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  mapView: {
    ...StyleSheet.absoluteFillObject
  },
  infoCard: {
    padding: 20,
    alignItems: 'center'
  },
  infoCardNotch: {
    marginBottom: height * 0.025
  },
  infoLoading: {
    flexDirection: 'column',
    justifyContent: 'center',
    height: 70,
    marginVertical: 8
  },
  infoBody: {
    width: '100%',
    height: 70,
    marginVertical: 8
  },
  infoHeader: {
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 16
  },
  locationAddress: {
    ...fontMaker({ weight: 'Light' }),
    color: theme.styles.fontColor.basic
  },
  locationInvalid: {
    ...fontMaker({ weight: 'Light' }),
    color: '#ff6f00'
  },
  buttonLoading: {
    justifyContent: 'center',
    height: 43
  },
  button: {
    width: SCREEN_WIDTH - SCREEN_WIDTH / 3
  },
  locationAddressContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start'
  }
});

export default CalibrationContainer;
