import React from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase';
import moment from 'moment';
import { compose } from 'redux';
import { Animated, Platform, StyleSheet, View } from 'react-native';
import Text from '../global/Text';
import theme from '../../constants/theme';
import fontMaker from '../../utils/font';

import { RootState } from '../../store/reducers';
import { Organization } from '../../utils/classes';
import { ConnectedDispatch } from '../../typing/types';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../utils/screensize';
import { ScheduledSiteInfo } from '../../store/reducers/schedulesites';
import { withTranslation, WithTranslation } from 'react-i18next';

export class WelcomeSummary extends React.Component<WelcomeSummaryProps> {
  // Returns number of reports done in period
  _renderReportsCompletedThisPeriod = () => {
    const { myScheduledSites } = this.props;

    let count = 0;
    myScheduledSites.forEach(site => {
      count += site.completedCount;
    });

    return count;
  };

  // Returns number of reports due in period
  _renderReportsDueThisPeriod = () => {
    const { myScheduledSites } = this.props;

    let count = 0;

    myScheduledSites.forEach(site => {
      count += site.scheduledCount;
    });

    return count;
  };

  render() {
    const { t, profile, hasNotch, myScheduledSitesLastUpdated } = this.props;
    const now = moment();
    //
    const nowAdjusted = moment();
    const lastUpdated = myScheduledSitesLastUpdated ? moment(myScheduledSitesLastUpdated) : null;

    return (
      <View style={[styles.root, hasNotch ? styles.rootNotch : null, { backgroundColor: this.props.profileColor }]}>
        <View style={styles.container}>
          <Animated.Text
            allowFontScaling={false}
            style={[
              styles.greeting,
              theme.styles.textContrastPrimary,
              {
                marginVertical: this.props.offset
                  ? this.props.offset.interpolate({
                      inputRange: [0, SCREEN_HEIGHT * 0.3],
                      outputRange: [12, 0],
                      extrapolate: 'clamp'
                    })
                  : 1,
                opacity: this.props.offset
                  ? this.props.offset.interpolate({
                      inputRange: [20, SCREEN_HEIGHT * 0.275],
                      outputRange: [1, 0],
                      extrapolate: 'clamp'
                    })
                  : 1
              }
            ]}
          >
            {t('home:summary.hi')}
            {profile.displayName}
          </Animated.Text>
          <Text allowFontScaling={false} style={[styles.date, theme.styles.textContrastPrimary]}>
            {nowAdjusted.format('dddd, D MMMM').toUpperCase()}
          </Text>
          {lastUpdated ? (
            <Text style={[styles.lastUpdated, theme.styles.textContrastPrimary]}>
              {t('common:lastUpdated')} :{' '}
              {lastUpdated.isSame(now, 'day') ? lastUpdated.format('hh:mm a') : lastUpdated.format('D MMM hh:mm a')}
            </Text>
          ) : null}
        </View>
      </View>
    );
  }
}

type StateProps = {
  auth: any;
  profile: any;
  language: string;
  organization: Organization | null | undefined;
  isDummy: boolean;
  hasNotch: boolean;
  myScheduledSites: ScheduledSiteInfo[];
  myScheduledSitesLastUpdated: number | null;
  profileColor: string;
};

type OwnProps = {
  isReady: boolean;
  offset: Animated.Value;
};

type EnhancedProps = StateProps & ConnectedDispatch & WithTranslation;

type WelcomeSummaryProps = OwnProps & EnhancedProps;

const mapStateToProps = (state: RootState): StateProps => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  language: state.firebase.profile.language || 'en',
  organization: state.organization.myOrganization,
  isDummy: state.account.isDummy,
  hasNotch: state.account.hasNotch,
  myScheduledSites: state.scheduleSites.myScheduledSites,
  myScheduledSitesLastUpdated: state.scheduleSites.timestamp,
  profileColor: state.profiletheme.color
});

const state = {
  serverTimeData: {}
};

const enhance = compose(connect(mapStateToProps), withTranslation(['home, common']));
export default enhance(WelcomeSummary);

const styles = StyleSheet.create({
  root: {
    position: 'relative',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: SCREEN_WIDTH >= 360 ? SCREEN_HEIGHT * 0.52 : SCREEN_HEIGHT * 0.58,
    maxHeight: 400,
    paddingTop: SCREEN_WIDTH >= 360 ? 26 : Platform.OS === 'ios' ? 22 : 0,
    paddingBottom: SCREEN_WIDTH >= 360 ? 72 : 36,
    backgroundColor: '#3cd070'
  },
  rootNotch: {
    paddingTop: 56
  },
  container: {
    alignItems: 'center'
  },
  greeting: {
    marginTop: SCREEN_WIDTH >= 360 ? 0 : 10,
    ...fontMaker({ weight: 'Normal' }),
    fontSize: 16
  },
  date: {
    marginTop: SCREEN_WIDTH >= 360 ? 0 : -10,
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 20,
    lineHeight: 24
  },
  lastUpdated: {
    marginTop: SCREEN_WIDTH >= 360 ? 5 : -2.5,
    marginBottom: SCREEN_WIDTH >= 360 ? 0 : 50,
    ...fontMaker({ weight: 'Light' }),
    textTransform: 'capitalize',
    fontSize: 12
  }
});
