import { getFirebase } from 'react-redux-firebase';
import { call, put } from 'redux-saga/effects';
import { MessageBoard } from 'nimbly-common';

import { NIMBLY_API_MESSAGE_BOARD } from '../../../constants/api';
import * as actions from './messageBoard.action';

export function* fetchSiteMessageBoard(action: ReturnType<typeof actions.fetchSiteMessageBoardAsync.request>) {
  const firebase = getFirebase();

  try {
    const token = (yield firebase.auth().currentUser?.getIdToken()) as string;
    const options = {
      method: 'GET',
      headers: {
        Authorization: token
      }
    };
    if (action.payload?.siteID !== null && token !== '') {
      const url = `${NIMBLY_API_MESSAGE_BOARD}/${action.payload.siteID}`;
      const request = () => fetch(url, options);
      const response = yield call(request);

      if (response?.status === 200) {
        const result = yield response.json();
        const messageBoard: MessageBoard[] | null = result.data;
        (messageBoard || []).sort(
          (a: MessageBoard, b: MessageBoard) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        yield put(actions.fetchSiteMessageBoardAsync.success({ siteID: action.payload.siteID, data: messageBoard }));
      }
    }
  } catch (error) {
    yield put(actions.fetchSiteMessageBoardAsync.failure({ message: error.message }));
  }
}
