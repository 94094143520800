import { Question } from '../../../utils/classes';

/**
 * Check Whether Yellow flag is on the left/right side, then decide which number is greater than or
 * less than. Returning the flag based on the range
 * @param question
 */
export default function getRangeFlagColor(question: Pick<Question, 'rangeOptions' | 'answer'>) {
  if (question.answer === '') {
    return 'Error';
  }
  let flag = question.rangeOptions && question.rangeOptions[0].flag === 'green' ? 'red' : 'green';
  const isInvert =
    question.rangeOptions && question.rangeOptions.length < 2
      ? false
      : question.rangeOptions[0].rangeTo === question.rangeOptions[1].rangeFrom
      ? false
      : question.rangeOptions[1].rangeTo === question.rangeOptions[0].rangeFrom
      ? true
      : false;
  if (question.answer === 'not-applicable') {
    return question.answer;
  }
  if (question.rangeOptions && question.rangeOptions.length < 2) {
    if (
      Number(question.answer) >= question.rangeOptions[0].rangeFrom &&
      Number(question.answer) <= question.rangeOptions[0].rangeTo
    ) {
      flag = question.rangeOptions[0].flag;
    }
  } else if (question.rangeOptions.length === 2) {
    if (isInvert) {
      if (
        Number(question.answer) >= question.rangeOptions[1].rangeFrom &&
        Number(question.answer) < question.rangeOptions[1].rangeTo
      ) {
        flag = question.rangeOptions[1].flag;
      } else if (
        Number(question.answer) >= question.rangeOptions[0].rangeFrom &&
        Number(question.answer) <= question.rangeOptions[0].rangeTo
      ) {
        flag = question.rangeOptions[0].flag;
      }
    } else {
      if (
        Number(question.answer) >= question.rangeOptions[0].rangeFrom &&
        Number(question.answer) < question.rangeOptions[0].rangeTo
      ) {
        flag = question.rangeOptions[0].flag;
      } else if (
        Number(question.answer) >= question.rangeOptions[1].rangeFrom &&
        Number(question.answer) <= question.rangeOptions[1].rangeTo
      ) {
        flag = question.rangeOptions[1].flag;
      }
    }
  }
  switch (flag) {
    case 'green':
      return 'green-flag';
    case 'yellow':
      return 'yellow-flag';
    case 'red':
      return 'red-flag';
    default:
      return 'Error';
  }
}
