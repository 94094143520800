import firebase from 'firebase';
import { FirebaseFunctionsTypes } from 'firebase/functions';
import moment from 'moment';

import { Site } from '../../../utils/classes';
import { UserSingleSiteScheduleInfo } from '../../../store/reducers/siteSchedule/siteSchedule.reducer';

export type getUserSingleSiteScheduleProps = {
  auth: any;
  site: Site;
  siteKey: string;
  organizationKey: string;
  startDate: string;
  endDate: string;
};

type DataParam = Omit<getUserSingleSiteScheduleProps, 'site' | 'auth'> & {
  userKey: string;
  today: string;
  /** in minutes */
  timezone: string;
};

const getUserSingleSiteSchedule = (
  props: getUserSingleSiteScheduleProps
): Promise<FirebaseFunctionsTypes.HttpsCallableResult> => {
  const { organizationKey, siteKey, site, startDate, endDate, auth } = props;
  // const serverTime = firebase.database().getServerTime() as Date;
  const m = moment();
  const todayString = m.format('YYYY-MM-DD');

  const getSingleSiteSchedule = firebase
    .functions()
    .httpsCallable<DataParam, UserSingleSiteScheduleInfo[]>('getUserSingleSiteSchedule');

  const params: DataParam = {
    organizationKey,
    userKey: auth.uid,
    today: todayString,
    timezone: site.timezone || '',
    startDate,
    endDate,
    siteKey
  };

  return getSingleSiteSchedule(params);
};

export default getUserSingleSiteSchedule;
