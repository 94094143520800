type ObjectKeys = {
  [key: string]: any;
};

function shallowCompare(newObj: ObjectKeys, prevObj: ObjectKeys): boolean {
  for (const key in newObj) {
    if (newObj[key] !== prevObj[key]) {
      return true;
    }
  }
  return false;
}

export default shallowCompare;
