import React, { useState } from 'react';
import { StyleSheet, View, Platform } from 'react-native';

type Props = {
  content: any;
  children: any;
  isActive: boolean;
};

export const ToolTips = (props: Props) => {
  const [childHeight, setChildHeight] = useState<number>(0);
  // TODO: Change this to props
  const OFFSET = 15;
  const TRIANGLE_WIDTH = 10;
  const SHADOW_STYLE = {
    // iOS generated from https://ethercreative.github.io/react-native-shadow-generator/
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84
      },
      android: {
        elevation: 5
      }
    })
  };

  const onLayoutChange = (e: any) => {
    const { height } = e.nativeEvent.layout;
    setChildHeight(height);
  };

  return (
    <View style={styles.tooltips}>
      {props.content() && props.isActive && (
        <View style={[styles.floatingContainer, { bottom: childHeight + OFFSET }]}>
          <View style={[styles.contentContainer, SHADOW_STYLE]}>{props.content()}</View>
          <View
            style={[
              styles.triangle,
              {
                height: TRIANGLE_WIDTH,
                width: TRIANGLE_WIDTH,
                margin: -(TRIANGLE_WIDTH / 2),
                ...SHADOW_STYLE
              }
            ]}
          />
        </View>
      )}
      <View onLayout={onLayoutChange}>{props.children}</View>
    </View>
  );
};
const styles = StyleSheet.create({
  tooltips: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center'
  },
  floatingContainer: {
    position: 'absolute'
  },
  contentContainer: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    minWidth: '100%'
  },
  triangle: {
    backgroundColor: 'white',
    alignSelf: 'center',
    transform: [{ rotate: '45deg' }],
    overflow: 'hidden'
  }
});

export default ToolTips;
