import React, { useEffect } from 'react';
import { Question, SiteSKU, OrganizationSKU, SKUInventory } from '../../utils/classes';
import {
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  StyleSheet,
  View,
  Text,
  Dimensions,
  TouchableOpacity,
  Image,
  NativeMethodsMixinStatic
} from 'react-native';

import RecommendationModal from './RecommendationModal';
import QuestionnaireSwiperMedia from './QuestionnaireSwiperMedia';
import QuestionnaireSwiperRecommendation from './QuestionnaireSwiperRecommendation';
import QuestionnaireSwiperHeading from './QuestionnaireSwiperHeading';
import QuestionnaireQuestion from './QuestionnaireQuestion';
import fontMaker from '../../utils/font';
import { SCREEN_WIDTH, SCREEN_HEIGHT } from '../../utils/screensize';
const downArrow = require('../../assets/icons/down-arrow.png');
import { useTranslation } from 'react-i18next';
import { LanguageType } from '../../constants/Languages';
import { Option } from '../../utils/classes';
import { getConditionalQuestion, validateConditionalQuestion } from './utils/conditionalQuestion';

export type QuestionnaireSwiperContainerProps = {
  /** Currently selected language (en / id) */
  language: LanguageType;

  /** Stores sku query from SKU Query On Input Change */
  query: string;

  /** List of questions for questionnaire */
  questions: Question[];

  /** Question selected in questionnaire */
  question: Question;

  /** Index of question selected in questionnaire (Generated from maps function in QuestionnaireSwiper) */
  index: number;

  /** Stores sku query from questionnaire */
  skuQuery: string;

  /** Whether modal is shown / not */
  showModal: any;

  /** Whether keyboard is shown / not */
  keyboardShown: boolean;

  /** Index of a photo that is being edited */
  editingPhotoIndex: number | null;

  /** Is auditor allowed to stock in? (Option can be altered in admin) */
  allowAuditorStockIn: boolean | undefined;

  /** Index of question selected in questionnaire (Value supposed to be the same as index, this one is retrieved from redux) */
  currentQuestionIndex?: number | null;

  /** conditional question */
  currentConditionIndex?: number | undefined;

  /** Currently selected inventory for questionnaire */
  currentInventory: SKUInventory | null;

  /** Does the questionnaire allowed to have video attachment (Option can be altered in admin)  */
  hasVideoAttachment: boolean | undefined;

  /** Whether recommendation is exist in questionnaire or not (Option can be altered in admin > questionnaire) */
  allowRecommendation: boolean | undefined;

  /** Stock coming in to the inventory */
  inventoryIn: { [index: number]: string };

  /** Total stock existing in an inventory */
  inventoryFinal: { [index: number]: string };

  /** state for close or show up NA tooltip */
  isShowTooltipNA: boolean;

  /** To check whether SKU in the questionnaire is callibrated with stock in existing inventory (So it won't be double submitted) */
  calibratingSKU: { [skuKey: string]: boolean } | null;

  /**  */
  siteSKUs: { [sku: string]: SiteSKU & { skuDetail: OrganizationSKU } } | null | undefined;

  /** Incoming items (SKU) */
  skuIn: { [key: string]: string };

  /** SKU that has been updated (total stock after deduction of in and out) */
  skuFinal: { [key: string]: string };

  /**  */
  skuCommentVisible: { [key: string]: boolean };

  /** Get - is in tutorial mode or not */
  isTutorialMode: boolean;

  /** Get - show tutorial modal or not */
  isShowQuestionTutorialModal: boolean;

  /** active question type , for showing modal based on question type */
  tutorialQuestionType: string;

  /** The ref of the ScrollView */
  scrollRef: React.RefObject<any>;

  /** The ref of the comment box (if applicable) */
  commentInputRef: React.RefObject<any> | null;

  /** The ref of the number input box (if applicable) */
  numberInputRef: React.RefObject<any> | null;

  /** The refs of all inventory cards (if applicable) */
  inventoryCardRefs: { [key: string]: React.RefObject<any> };

  /** The ref of the number input box (if applicable) */
  competitorAnalysisInputRef: { [key: string]: React.RefObject<any> };

  /** Handle show or close tooltip modal */
  handleShowTooltipNA: (value: boolean) => void;

  /** When activated, enables / disables SKU comment section */
  handleToggleSKUComment: (skuKey: string) => void;

  /** Open record video page */
  handleNavRecordVideo: (currentConditionIndex?: number | string, props?: any) => void;

  /** When activated, shows / hides modal */
  handleToggleModal: (index: number) => void;

  /** Navigate to (string) */
  handleNavReference: (url: string) => void;

  /** Navigate to Create Company page */
  handleNavCreateCompany: () => void;

  /** Allows user to take photo using camera / upload existing photo from the gallery */
  handleActivateImagePicker: (currentConditionIndex?: number | string, props?: any) => void;

  /** Set recommendation selected by the user */
  handleSetRemedies: (index: number, text: string, currentConditionIndex?: number | string) => void;

  /** Open image picker to edit the photo */
  handleConfirmEditPhoto: (
    photoIndex: number,
    skuKey?: string,
    currentConditionIndex?: number | string,
    props?: any
  ) => void;

  /** Play uploaded video on MediaPlayback page */
  handleNavPlaybackVideo: (uri: string, videoIndex: number, currentConditionIndex?: number | string) => void;

  /** Handle form input for type: select and save its data to redux */
  handleSelectChoice: (index: number, choice: string, currentConditionIndex?: number | string) => void;

  /** Handle form input for type: select and save its data to redux */
  handleSelectWeightedChoice: (index: number, choice: string, currentConditionIndex?: number | string) => void;

  /** Handle form input for type: select and save its data to redux */
  handleSelectChecklist: (index: number, choice: string, currentConditionIndex?: number | string) => void;

  /** Handle form input for type: comment and save its data to redux */
  handleInputComment: (index: number, text: string, currentConditionIndex?: number | string) => void;

  /** Handle form input for type: number with decimal and save its data to redux */
  handleInputDecimal: (index: number, text: string, currentConditionIndex?: number | string) => void;

  /** Handle form input for type: score and save its data to redux */
  handleInputScore: (index: number, value: number, currentConditionIndex?: number | string) => void;

  /** Handle form input for type: SKU Query and save its data to redux and update query variable */
  handleInputSKUQuery: (text: string) => void;

  /** Handle form input for type: inventory and save its data to redux */
  handleInputInventory: (index: number, text: string, type: 'in' | 'out' | 'final', question: Question) => void;

  /** Determine value of updated SKU inventory input field */
  handleInputSKUInventory: (
    index: number,
    skuKey: string,
    text: string,
    type: 'in' | 'out' | 'final' | 'photos' | 'comment',
    question: Question
  ) => void;

  /** Handle form input for type: competitor-analysis and save its data to redux */
  handleInputCompetitorAnalysis: (index: number, value: any, type: string) => void;

  /** Scroll down to relevant competitor analysis input field on focus */
  handleCompetitorAnalysisInputFocused: (index: number, type: string) => void;

  /** When relevant competitor nalysis input field is blurred, scroll back up */
  handleCompetitorAnalysisInputBlurred: (index: number) => void;

  /** Scroll down to relevant text input field on focus */
  handleTextInputFocused: (index: number) => void;

  /** When relevant text input field is blurred, scroll back up */
  handleTextInputBlurred: (index: number) => void;

  /** Scroll down to relevant number input field on focus */
  handleNumberInputFocused: (index: number) => void;

  /** When relevant number input field is blurred, scroll back up */
  handleNumberInputBlurred: (index: number) => void;

  /** Clear SKU query */
  handleClearSKUQuery: () => void;

  /** Scroll down to relevant inventory input field on focus */
  handleInventoryInputFocused: (
    questionIndex: number,
    skuKey: string,
    type: 'in' | 'final' | 'comment',
    skuItemIndex: number
  ) => void;

  /** When relevant inventory input field is blurred, scroll back up */
  handleInventoryInputBlurred: (
    questionIndex: number,
    skuKey: string,
    type: 'in' | 'final' | 'comment',
    skuItemIndex: number
  ) => void;

  /** Show or hide tutorial modal on question */
  handleShowTutorialQuestionModal: (isShow: boolean) => void;

  /** Set question type of tutorial */
  handleChangeTutorialQuestionType: (type: string) => void;

  /** close tutorial modal on question */
  handleDismissTutorialQuestionModal: () => void;

  /** end tutorial mode */
  handleDismissTutorialMode: () => void;

  /** Edit File */
  handleEditUploadFile: (currentConditionIndex?: number | string, props?: any) => void;

  /** Choose File */
  handleUploadDocuments: (currentConditionIndex?: number | string, props?: any) => void;
  handleUploadFile: (currentConditionIndex?: number | string, props?: any) => void;

  showCamera: boolean;

  handleTakePhoto: (currentConditionIndex?: number | string, props?: any) => void;
};

const screen = Dimensions.get('screen');
const { width } = screen;
const QuestionnaireSwiperContainer = (props: QuestionnaireSwiperContainerProps) => {
  const { t } = useTranslation(['questionnaire']);
  const [arrQuestionnaire, setArrQuestionnaire] = React.useState<any>([]);
  const [keyboardShown, setKeyboardShown] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<any>({});
  const [isConditional, setIsConditional] = React.useState(
    validateConditionalQuestion(props.question, props.question.answer)
  );

  const [conditionalQuestion, setConditionalQuestion] = React.useState<Question[]>(
    getConditionalQuestion(props.question, props.question.answer)
  );

  useEffect(() => {
    const questions: Question[] = getConditionalQuestion(props.question, props.question.answer);

    if (!validateConditionalQuestion(props.question, props.question.answer)) {
      setIsConditional(false);
      return;
    }

    const modalStates: any = {};
    questions.forEach((question, index) => {
      if (question.type === 'binary' || question.type === 'binary-with-red-flag') {
        modalStates[index] = false;
      }
    });
    setIsConditional(true);
    setConditionalQuestion(questions);
    setShowModal(modalStates);

    return () => {
      setShowModal({});
    };
  }, [props.question, props.question.answer]);

  const handleToggleModal = (index: number) => {
    const modalStates = {
      ...showModal,
      [index]: !showModal![index]
    };
    setShowModal(modalStates);
  };
  const handleTextInputFocused = (index: number) => {
    setKeyboardShown(true);
    // props.scrollRef.current.scrollTo({
    //   x: 0,
    //   y: arrQuestionnaire[index],
    //   animated: true
    // });
  };

  // On textinput blur, scroll back up
  const handleTextInputBlurred = (index: number) => {
    setKeyboardShown(false);
  };
  const handleNumberInputFocused = (index: number) => {
    setKeyboardShown(true);
    // props.scrollRef.current.scrollTo({
    //   x: 0,
    //   y: arrQuestionnaire[index],
    //   animated: true
    // });
  };

  // On textinput blur, scroll back up
  const handleNumberInputBlurred = (index: number) => {
    setKeyboardShown(false);
  };

  return (
    <KeyboardAvoidingView
      key={`swiper-modal-${props.index + 1}`}
      behavior="padding"
      enabled={
        props.questions[props.currentQuestionIndex!] &&
        props.questions[props.currentQuestionIndex!].type !== 'inventory-v2' &&
        props.questions[props.currentQuestionIndex!].type !== 'competitor' &&
        props.showModal[props.index] &&
        Platform.OS === 'ios'
      }
      style={styles.flex}
    >
      {props.questions[props.currentQuestionIndex!] &&
      props.questions[props.currentQuestionIndex!].type !== 'inventory-v2' &&
      props.showModal[props.index] &&
      props.question.potentialRemedies ? (
        <RecommendationModal
          showModal={props.showModal[props.index]}
          language={props.language}
          questionIndex={props.index}
          onSetRemedies={props.handleSetRemedies}
          onCloseModal={() => props.handleToggleModal(props.index)}
          options={props.question.potentialRemedies.length > 0 ? props.question.potentialRemedies : []}
        />
      ) : null}
      <ScrollView
        contentContainerStyle={styles.questionContainer}
        ref={props.question.type !== 'inventory-v2' ? props.scrollRef : null}
        keyboardDismissMode={Platform.OS === 'ios' ? 'interactive' : 'on-drag'}
        keyboardShouldPersistTaps="always"
        scrollEnabled={props.question.type !== 'inventory-v2'}
      >
        <QuestionnaireSwiperHeading
          language={props.language}
          index={props.index}
          questions={props.questions}
          question={props.question}
          handleNavReference={props.handleNavReference}
          siteSKUs={props.siteSKUs}
          calibratingSKU={props.calibratingSKU}
        />

        <QuestionnaireQuestion
          query={props.query}
          questions={props.questions}
          question={props.question}
          index={props.index}
          language={props.language}
          siteSKUs={props.siteSKUs}
          skuQuery={props.skuQuery}
          currentQuestionIndex={props.currentQuestionIndex}
          skuCommentVisible={props.skuCommentVisible}
          calibratingSKU={props.calibratingSKU}
          inventoryIn={props.inventoryIn}
          inventoryFinal={props.inventoryFinal}
          skuFinal={props.skuFinal}
          skuIn={props.skuIn}
          scrollRef={props.scrollRef}
          commentInputRef={props.commentInputRef}
          numberInputRef={props.numberInputRef}
          inventoryCardRefs={props.inventoryCardRefs}
          competitorAnalysisInputRef={props.competitorAnalysisInputRef}
          currentInventory={props.currentInventory}
          allowAuditorStockIn={props.allowAuditorStockIn}
          handleSelectChoice={props.handleSelectChoice}
          handleSelectWeightedChoice={props.handleSelectWeightedChoice}
          handleSelectChecklist={props.handleSelectChecklist}
          handleInputComment={props.handleInputComment}
          keyboardShown={props.keyboardShown}
          isTutorialMode={props.isTutorialMode}
          isShowTooltipNA={props.isShowTooltipNA}
          handleTextInputFocused={props.handleTextInputFocused}
          handleTextInputBlurred={props.handleTextInputBlurred}
          handleNumberInputFocused={props.handleNumberInputFocused}
          handleNumberInputBlurred={props.handleNumberInputBlurred}
          handleInputScore={props.handleInputScore}
          handleInputDecimal={props.handleInputDecimal}
          handleInputInventory={props.handleInputInventory}
          handleClearSKUQuery={props.handleClearSKUQuery}
          handleInputSKUQuery={props.handleInputSKUQuery}
          handleInputSKUInventory={props.handleInputSKUInventory}
          handleInventoryInputFocused={props.handleInventoryInputFocused}
          handleInventoryInputBlurred={props.handleInventoryInputBlurred}
          handleInputCompetitorAnalysis={props.handleInputCompetitorAnalysis}
          handleCompetitorAnalysisInputFocused={props.handleCompetitorAnalysisInputFocused}
          handleCompetitorAnalysisInputBlurred={props.handleCompetitorAnalysisInputBlurred}
          handleConfirmEditPhoto={props.handleConfirmEditPhoto}
          handleToggleSKUComment={props.handleToggleSKUComment}
          handleActivateImagePicker={props.handleActivateImagePicker}
          handleShowTooltipNA={props.handleShowTooltipNA}
          handleShowTutorialQuestionModal={props.handleShowTutorialQuestionModal}
          handleChangeTutorialQuestionType={props.handleChangeTutorialQuestionType}
          handleDismissTutorialQuestionModal={props.handleDismissTutorialQuestionModal}
          handleDismissTutorialMode={props.handleDismissTutorialMode}
          handleNavCreateCompany={props.handleNavCreateCompany}
          isShowQuestionTutorialModal={props.isShowQuestionTutorialModal}
          tutorialQuestionType={props.tutorialQuestionType}
        />
        <QuestionnaireSwiperRecommendation
          question={props.question}
          handleToggleModal={props.handleToggleModal}
          allowRecommendation={props.allowRecommendation}
          index={props.index}
          language={props.language}
        />

        <QuestionnaireSwiperMedia
          language={props.language}
          question={props.question}
          index={props.index}
          editingPhotoIndex={props.editingPhotoIndex}
          handleUploadDocuments={props.handleUploadDocuments}
          handleEditUploadFile={props.handleEditUploadFile}
          handleConfirmEditPhoto={props.handleConfirmEditPhoto}
          handleNavRecordVideo={props.handleNavRecordVideo}
          handleNavPlaybackVideo={props.handleNavPlaybackVideo}
          handleActivateImagePicker={props.handleActivateImagePicker}
          hasVideoAttachment={props.hasVideoAttachment}
          isTutorialMode={props.isTutorialMode}
          currentQuestionIndex={props.currentQuestionIndex}
          isShowQuestionTutorialModal={props.isShowQuestionTutorialModal}
          showCamera={props.showCamera}
          handleTakePhoto={props.handleTakePhoto}
          handleCloseCamera={props.handleCloseCamera}
        />

        {props.question.isConditional &&
          props.question.conditionalQuestion &&
          props.question.conditionalQuestion.conditions[props.question.answer] &&
          props.question.conditionalQuestion.conditions[props.question.answer].map(
            (el: any, conditionalIndex: number) => (
              <View
                style={styles.conditionalContainer}
                onLayout={event => {
                  const layout = event.nativeEvent.layout;
                  let newArrQuestionnaire = arrQuestionnaire.slice();
                  newArrQuestionnaire[conditionalIndex] = layout.y;
                  setArrQuestionnaire(newArrQuestionnaire);
                }}
                key={`conditionalQuestion-${conditionalIndex}`}
              >
                <View style={styles.conditionalTitle}>
                  <Text style={styles.conditionText}>
                    {t('questionnaire:questionnaireSwiper.conditionalNumber', {
                      index: props.currentQuestionIndex! + 1,
                      length: props.questions.length
                    })}
                  </Text>
                </View>
                <View style={styles.conditionalTitle}>
                  <Text style={styles.boldText}>
                    {t('questionnaire:questionnaireSwiper.followUpConditional', {
                      index: conditionalIndex + 1,
                      length: props.question!.conditionalQuestion!.conditions[props.question.answer].length
                    })}
                  </Text>
                  <Text style={styles.conditionText}>{el.content}</Text>
                </View>
                <QuestionnaireQuestion
                  query={props.query}
                  questions={props.question!.conditionalQuestion!.conditions[props.question.answer]}
                  question={props.question.conditionalQuestion!.conditions[props.question.answer][conditionalIndex]}
                  index={props.index}
                  currentConditionIndex={conditionalIndex}
                  language={props.language}
                  siteSKUs={props.siteSKUs}
                  skuQuery={props.skuQuery}
                  skuCommentVisible={props.skuCommentVisible}
                  calibratingSKU={props.calibratingSKU}
                  currentQuestionIndex={props.currentQuestionIndex}
                  inventoryIn={props.inventoryIn}
                  inventoryFinal={props.inventoryFinal}
                  skuFinal={props.skuFinal}
                  skuIn={props.skuIn}
                  scrollRef={props.scrollRef}
                  commentInputRef={props.commentInputRef}
                  numberInputRef={props.numberInputRef}
                  inventoryCardRefs={props.inventoryCardRefs}
                  currentInventory={props.currentInventory}
                  allowAuditorStockIn={props.allowAuditorStockIn}
                  handleSelectChoice={props.handleSelectChoice}
                  handleSelectWeightedChoice={props.handleSelectWeightedChoice}
                  handleSelectChecklist={props.handleSelectChecklist}
                  handleInputComment={props.handleInputComment}
                  competitorAnalysisInputRef={props.competitorAnalysisInputRef}
                  keyboardShown={keyboardShown}
                  isTutorialMode={props.isTutorialMode}
                  isShowTooltipNA={props.isShowTooltipNA}
                  handleTextInputFocused={handleTextInputFocused}
                  handleTextInputBlurred={handleTextInputBlurred}
                  handleNumberInputFocused={handleNumberInputFocused}
                  handleNumberInputBlurred={handleNumberInputBlurred}
                  handleInputCompetitorAnalysis={props.handleInputCompetitorAnalysis}
                  handleCompetitorAnalysisInputFocused={props.handleCompetitorAnalysisInputFocused}
                  handleCompetitorAnalysisInputBlurred={props.handleCompetitorAnalysisInputBlurred}
                  handleInputScore={props.handleInputScore}
                  handleInputDecimal={props.handleInputDecimal}
                  handleInputInventory={props.handleInputInventory}
                  handleClearSKUQuery={props.handleClearSKUQuery}
                  handleInputSKUQuery={props.handleInputSKUQuery}
                  handleInputSKUInventory={props.handleInputSKUInventory}
                  handleInventoryInputFocused={props.handleInventoryInputFocused}
                  handleInventoryInputBlurred={props.handleInventoryInputBlurred}
                  handleConfirmEditPhoto={props.handleConfirmEditPhoto}
                  handleToggleSKUComment={props.handleToggleSKUComment}
                  handleActivateImagePicker={props.handleActivateImagePicker}
                  handleShowTooltipNA={props.handleShowTooltipNA}
                  handleShowTutorialQuestionModal={props.handleShowTutorialQuestionModal}
                  handleChangeTutorialQuestionType={props.handleChangeTutorialQuestionType}
                  handleDismissTutorialQuestionModal={props.handleDismissTutorialQuestionModal}
                  handleDismissTutorialMode={props.handleDismissTutorialMode}
                  isShowQuestionTutorialModal={props.isShowQuestionTutorialModal}
                  tutorialQuestionType={props.tutorialQuestionType}
                  handleNavCreateCompany={props.handleNavCreateCompany}
                />

                {el && el.type !== 'inventory-v2' && showModal[conditionalIndex] && el.potentialRemedies ? (
                  <RecommendationModal
                    showModal={showModal[conditionalIndex]}
                    language={props.language}
                    questionIndex={props.index}
                    onSetRemedies={props.handleSetRemedies}
                    currentConditionIndex={conditionalIndex}
                    onCloseModal={() => handleToggleModal(conditionalIndex)}
                    options={el.potentialRemedies.length > 0 ? el.potentialRemedies : []}
                  />
                ) : null}
                <QuestionnaireSwiperMedia
                  language={props.language}
                  question={props.question.conditionalQuestion!.conditions[props.question.answer][conditionalIndex]}
                  currentConditionIndex={conditionalIndex}
                  index={props.index}
                  editingPhotoIndex={props.editingPhotoIndex}
                  handleConfirmEditPhoto={props.handleConfirmEditPhoto}
                  handleNavRecordVideo={props.handleNavRecordVideo}
                  handleNavPlaybackVideo={props.handleNavPlaybackVideo}
                  handleActivateImagePicker={props.handleActivateImagePicker}
                  hasVideoAttachment={props.hasVideoAttachment}
                  isTutorialMode={props.isTutorialMode}
                  currentQuestionIndex={props.currentQuestionIndex}
                  isShowQuestionTutorialModal={props.isShowQuestionTutorialModal}
                  showCamera={props.showCamera}
                  handleTakePhoto={props.handleTakePhoto}
                  handleUploadDocuments={props.handleUploadDocuments}
                  handleEditUploadFile={props.handleEditUploadFile}
                  handleCloseCamera={props.handleCloseCamera}
                />
              </View>
            )
          )}

        {props.question.type !== 'inventory-v2' && props.question.type !== 'competitor' && props.keyboardShown ? (
          <View style={styles.questionKeyboardPadding} />
        ) : null}
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

export default QuestionnaireSwiperContainer;

const styles = StyleSheet.create({
  flex: { flex: 1 },
  questionContainer: {
    width: '100%',
    flex: 1
  },
  questionKeyboardPadding: {
    height: 5,
    paddingVertical: 100
  },
  conditionalTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 5
  },
  conditionText: {
    textAlign: 'center',
    marginBottom: 5,
    paddingHorizontal: 10,
    ...fontMaker({ weight: 'Regular' }),
    color: '#535353'
  },
  boldText: {
    textAlign: 'center',
    marginBottom: 6,
    paddingHorizontal: 10,
    ...fontMaker({ weight: 'Bold' }),
    color: '#535353'
  },
  conditionalContainer: {
    borderStyle: 'solid',
    borderTopWidth: 0.5,
    borderTopColor: 'rgba(0, 0, 0, 0.05)',
    marginTop: 50
  },
  rootConditionButton: {
    position: 'absolute',
    bottom: SCREEN_HEIGHT * 0.02,
    right: SCREEN_WIDTH * 0.05,
    zIndex: 10
  },
  conditionButton: {
    backgroundColor: '#574FCF',
    borderRadius: 45,
    height: 30,
    padding: 8,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 3
    },
    shadowOpacity: 0.29,
    shadowRadius: 4.65,

    elevation: 7
  },
  imageStyle: {
    marginLeft: 3,
    height: 10,
    width: 10
  }
});
