/* eslint-disable complexity */
import { useState, useEffect, useMemo } from 'react';
import { useIsFocused } from '@react-navigation/native';
import { FirebaseReducer } from 'react-redux-firebase';

// utils
import { getTodayString, getSiteScheduleInfo } from '../utils/schedule';

// types
import { ReportIndex } from '../../../utils/classes';

import { InjectedSiteStatus } from '../../../typing/types';
import { SelectedSite } from '../Site/Outlet/type';
import { SiteData } from '../../../store/reducers/siteDataset/siteDataset.reducer';
import { PendingSites } from '../../../store/reducers/reportsubmit';

export interface UseSiteStatusParam {
  auth: FirebaseReducer.AuthState;
  isDummy: boolean;
  selectedSite: SelectedSite;
  selectedSiteKey: string | null;
  selectedSiteData: SiteData | null;
  pendingSites: PendingSites;
  startDate: string;
  endDate: string;
  mergedSiteSummaries: ReportIndex;
  isJourney?: boolean;
}

const useSiteStatus = (params: UseSiteStatusParam) => {
  const {
    auth,
    isDummy,
    selectedSite,
    selectedSiteKey,
    selectedSiteData,
    pendingSites,
    startDate,
    endDate,
    mergedSiteSummaries,
    isJourney
  } = params;

  const isFocused = useIsFocused();

  const [todayString] = useState(() => getTodayString());
  const [siteStatus, setSiteStatus] = useState<InjectedSiteStatus>('none-due');
  const [draftReportKey, setDraftReportKey] = useState<string | null>(null);
  const [pendingKey, setPendingKey] = useState<string | null>(null);
  const [selectedNextDue, setSelectedNextDue] = useState<string | null>(null);
  const [makeUpDates, setMakeUpDates] = useState<string[]>([]);
  const [reportScheduled, setReportScheduled] = useState(0);
  const [reportCompleted, setReportCompleted] = useState(0);
  const [stateReady, setStateReady] = useState(false);

  useEffect(() => {
    const defineSiteStatus = () => {
      if (!selectedSiteData) {
        return;
      }

      if (!selectedSite) {
        return;
      }

      if (!selectedSiteKey) {
        return;
      }

      if (
        !selectedSite.coordinates ||
        (selectedSite.coordinates.latitude === 0 && selectedSite.coordinates.longitude === 0)
      ) {
        setSiteStatus('error-location-not-found');
        return;
      }

      if (!(selectedSite.team || []).includes(auth.uid)) {
        setSiteStatus('none-due');
        return;
      }
      // journey should follow single site flow
      const isMultiSite = selectedSite.isMultiSite && !isJourney;
      // Set site status immediately as pending-upload if condition fulfiled
      const selectedSitePending = pendingSites[selectedSiteKey];
      if (selectedSitePending) {
        if (isMultiSite && selectedSitePending.pendingMulti) {
          const [reportKey] = Object.keys(selectedSitePending.pendingMulti || {}).sort();
          if (reportKey) {
            setPendingKey(reportKey);
            setSiteStatus('pending-upload');
            return;
          }
        }
      }

      const res = isMultiSite
        ? getSiteScheduleInfo(startDate, endDate, selectedSiteData.schedule, mergedSiteSummaries, true, isMultiSite)
        : null;

      if (!res) {
        setSiteStatus('ready');
        setSelectedNextDue(todayString);
        return;
      }
      const { draftKey, isDoneToday, nextDue, unfinishedDates, completedCount, scheduledCount } = res;

      setReportScheduled(scheduledCount);
      setReportCompleted(completedCount);

      if (draftKey) {
        setSiteStatus('draft');
        setDraftReportKey(draftKey);

        return;
      }

      if (isDoneToday && !isDummy) {
        setSiteStatus('finished-today');
        return;
      }

      if (unfinishedDates.length === 0 && !isDummy) {
        setSiteStatus('finished-period');
        return;
      }

      if (nextDue || isDummy) {
        if (nextDue && nextDue < todayString && !isDummy) {
          if (unfinishedDates.length > 0) {
            setSiteStatus('makeup');
            const filteredDates = unfinishedDates.filter(date => date < todayString);
            setMakeUpDates(filteredDates);

            return;
          }
          const env = process.env.NODE_ENV;
          if (env === 'development') {
            // eslint-disable-next-line no-console
            console.warn('site identified as makeup without makeup dates');
          }
        }
        setSiteStatus('ready');
        setSelectedNextDue(isDummy ? todayString : nextDue);
      } else {
        /**
         * TODO: May never arrive here.
         * need to add indicator that this site this period has schedule
         * and add the condition that count `unfinishedDates.length`
         */
        setSiteStatus('none-due');
      }
    };

    let defineHandler: any;
    if (isFocused) {
      // debounce handler to reduce too many render
      defineHandler = setTimeout(() => {
        defineSiteStatus();
        setTimeout(() => setStateReady(true), 0);
      }, 150);
    } else {
      setSiteStatus('none-due');
      setDraftReportKey(null);
      setPendingKey(null);
      setSelectedNextDue(null);
      setMakeUpDates([]);
      setStateReady(false);
    }

    return () => {
      if (defineHandler) {
        clearTimeout(defineHandler);
        defineHandler = null;
      }
    };
  }, [
    auth,
    startDate,
    endDate,
    mergedSiteSummaries,
    isDummy,
    isFocused,
    pendingSites,
    selectedSiteData,
    selectedSite,
    selectedSiteKey
  ]);

  const stateSelector = useMemo(
    () => ({
      status: siteStatus,
      draftKey: draftReportKey,
      pendingKey,
      makeUpDates,
      nextDue: selectedNextDue,
      reportScheduled,
      reportCompleted,
      stateReady
    }),
    [siteStatus, draftReportKey, pendingKey, makeUpDates, selectedNextDue, reportScheduled, reportCompleted, stateReady]
  );

  return stateSelector;
};

export default useSiteStatus;
