import { action, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';
import firebase from 'firebase';
import moment from 'moment';
import { JourneySchedule, JourneyReport } from 'nimbly-common';

import { OrganizationSchedule } from '../../utils/classes';
import { ThunkResult } from '../../typing/types';
import { errorLogger } from '../../utils/errorLogger';
import { currentPeriodStartDate, currentPeriodEndDate } from '../../utils/schedule';

import { setOngoingJourneyReportKey, setJourneyReport } from '../reducers/journey/journey';

export const SET_SCHEDULED_JOURNEYS = '@journey/SET_SCHEDULED_JOURNEYS';
export const SET_IS_FETCHING_MY_JOURNEYS = '@journey/SET_IS_FETCHING_MY_JOURNEYS';

import { MONGO_API } from '../../constants/api';

/**
 * True when the async function for journeys fetching is running. False for otherwise.
 * @param bool
 */
const setIsFetchingMyJourneys = (bool: boolean) => action(SET_IS_FETCHING_MY_JOURNEYS, { bool });

/**
 * Setter for scheduledJourneys
 * @param scheduledJourneys array of ScheduledJourneyInfo
 */
const setMyScheduledJourneys = (scheduledJourneys: ScheduledJourneyInfo[], now: number = Date.now()) =>
  action(SET_SCHEDULED_JOURNEYS, { scheduledJourneys, now });

/**
 * Retrieve scheduled journeys from firebase
 * @param organizationSchedule
 */

const getMyScheduledJourneys = (organizationSchedule: OrganizationSchedule): ThunkResult => async (
  dispatch,
  getState
) => {
  const state = getState();
  const { auth, profile } = state.firebase;
  const { isConnected } = state.network;
  const { ongoingJourneyReportKey } = state.journey;

  const currentUser = await firebase.auth().currentUser;

  if (!currentUser) {
    return;
  }

  const token = currentUser.getIdToken();

  // const serverTime = firebase.database().getServerTime() as Date;
  const today = moment().format('YYYY-MM-DD');

  const startDateMoment = currentPeriodStartDate(organizationSchedule) || moment();
  const endDateMoment = currentPeriodEndDate(organizationSchedule, startDateMoment)!;

  const startDate = startDateMoment.format('YYYY-MM-DD');
  const endDate = endDateMoment.format('YYYY-MM-DD');

  const now = moment().valueOf();

  type Data = {
    userKey: string;
    organizationKey: string;
    startDate: string;
    endDate: string;
    today: string;
  };

  const data: Data = {
    userKey: auth.uid,
    organizationKey: profile.organization,
    startDate,
    endDate,
    today
  };

  if (!isConnected) {
    dispatch(setIsFetchingMyJourneys(false));
    return;
  }

  try {
    dispatch(setIsFetchingMyJourneys(true));

    let isFetching = true;
    // still do not handle timeout for now
    let isTimeout = false;
    const handleTimeout = () => {
      if (isFetching) {
        const timeoutError = new Error('fetching schedule timeout');
        isTimeout = true;
        dispatch(setIsFetchingMyJourneys(false));
        // if (__DEV__) {
        //   // eslint-disable-next-line  no-console
        //   console.warn(timeoutError);
        // }
      }
    };
    const timeout = setTimeout(handleTimeout, 10 * 1000);

    const request = () =>
      fetch(`${MONGO_API}/journey/plans/my-scheduled-period?start-date=${startDate}&end-date=${endDate}`, {
        headers: {
          authorization: token
        },
        method: 'GET'
      });
    const response = await request();

    if (response.status === 200) {
      const result = (await response.json()) as any;
      const filteredData: ScheduledJourneyInfo[] = result.data.filter((journey: any) => {
        return !journey.journeyProfile.disabled;
      });

      isFetching = false;
      clearTimeout(timeout);

      // check if all data not match with current ongoing journey report key
      let isMatchOngoingReportKey = false;

      filteredData.forEach(s => {
        if (s?.report?.code === ongoingJourneyReportKey) {
          isMatchOngoingReportKey = true;
        }
      });

      // set on going report to null if not match will all journey report
      if (!isMatchOngoingReportKey) {
        dispatch(setOngoingJourneyReportKey(null));
        dispatch(setJourneyReport(null));
      }

      dispatch(setMyScheduledJourneys(filteredData, now));
    } else {
      dispatch(setMyScheduledJourneys([], now));
    }

    dispatch(setIsFetchingMyJourneys(false));
  } catch (error) {
    // handle error
    errorLogger(auth.uid, profile.organization, '', '', error, 'schedulejourneys.tsx/getMyScheduledJourneys');
    dispatch(setMyScheduledJourneys([], now));
    dispatch(setIsFetchingMyJourneys(false));
  }
};

export const scheduleJourneysActions = {
  setMyScheduledJourneys,
  setIsFetchingMyJourneys
};

export const scheduleJourneysThunk = {
  getMyScheduledJourneys
};

export interface ScheduledJourneyInfo {
  isScheduledThisPeriod: boolean;
  scheduledCount: number;
  completedCount: number;
  journeyProfile: JourneySchedule;
  journeyKey: string;
  isDraft?: boolean;
  isCompletedThisPeriod?: boolean;
  isCompletedToday?: boolean;
  isMakeUp?: boolean;
  isDueToday?: boolean;
  isPendingUpload?: boolean;
  report: JourneyReport | null;
}

export type ScheduleJourneysAction = ActionType<typeof scheduleJourneysActions>;
export type ScheduleJourneysState = {
  readonly myScheduledJourneys: ScheduledJourneyInfo[];
  readonly isFetchingMyJourneys: boolean;
  readonly timestamp: null | number;
};

const initialState: ScheduleJourneysState = {
  myScheduledJourneys: [],
  isFetchingMyJourneys: false,
  timestamp: null
};

export const scheduleJourneysReducer: Reducer<ScheduleJourneysState, ScheduleJourneysAction> = (
  state = initialState,
  incomingAction
) => {
  switch (incomingAction.type) {
    case SET_SCHEDULED_JOURNEYS:
      return {
        ...state,
        myScheduledJourneys: incomingAction.payload.scheduledJourneys,
        timestamp: incomingAction.payload.now
      };
    case SET_IS_FETCHING_MY_JOURNEYS:
      return {
        ...state,
        isFetchingMyJourneys: incomingAction.payload.bool
      };
    default:
      return state;
  }
};
