import React, { PureComponent } from 'react';
import { View, StyleSheet } from 'react-native';
import Text from '../global/Text';

import fontMaker from '../../utils/font';

type LobbyQuestionnaireCategoryProps = {
  title: string;
};

class LobbyQuestionnaireCategory extends PureComponent<LobbyQuestionnaireCategoryProps> {
  render() {
    const { title } = this.props;
    return (
      <View style={styles.root}>
        <Text style={styles.title}>{title}</Text>
        {this.props.children}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  root: {
    marginVertical: 2
  },
  title: {
    paddingVertical: 4,
    paddingLeft: 8,
    textAlign: 'left',
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 14,
    color: '#a8a8a8'
  }
});

export default LobbyQuestionnaireCategory;
