import { apiProdURL } from '../../../constants/api';

export const findTenantByEmailDomain = async (emailDomain: string, email: string) => {
  const url = `${apiProdURL}/auth/federated/domain`;

  const bodySend = {
    domain: emailDomain,
    email
  };

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodySend)
  };

  const fetchData = await fetch(url, options);

  if (fetchData.status === 200) {
    const result = await fetchData.json();
    return result.data;
  }
};
