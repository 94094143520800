import React from 'react';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { StyleSheet, View } from 'react-native';
import CreateCompany from '../components/createcompany/CreateCompany';
import { HomeStackParamList, ScheduleStackParamList } from '../routes';

export type CreateCompanyPageProps = {
  navigation: StackNavigationProp<HomeStackParamList | ScheduleStackParamList, 'CreateCompany'>;
  route: RouteProp<HomeStackParamList | ScheduleStackParamList, 'CreateCompany'>;
};

const CreateCompanyPage = (props: CreateCompanyPageProps) => {
  return (
    <View style={styles.root}>
      <CreateCompany navigation={props.navigation} route={props.route} />
    </View>
  );
};

export default CreateCompanyPage;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#fff'
  }
});
