import { action, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';

export const SET_CONNECTION_STATUS = '@network/SET_CONNECTION_STATUS';
export const SET_SPEED_THROTTLED = '@network/SET_SPEED_THROTTLED';
export const SET_RETRY_TIMEOUT = '@network/SET_RETRY_TIMEOUT';
export const RESET_CONNECTION_STATUS = '@network/RESET_CONNECTION_STATUS';

/**
 * Set the connection status via NetInfo
 * @param isConnected
 */
const setConnectionStatus = (isConnected: boolean) => action(SET_CONNECTION_STATUS, { isConnected: isConnected });
const setSpeedThrottled = (isThrottled: boolean) => action(SET_SPEED_THROTTLED, { isThrottled: isThrottled });
const setRetryTimeout = (second: number) => action(SET_RETRY_TIMEOUT, { second: second });

/**
 * Reset connection status
 */
const resetConnectionStatus = () => action(RESET_CONNECTION_STATUS);

export const networkActions = { setConnectionStatus, setSpeedThrottled, setRetryTimeout, resetConnectionStatus };

export type NetworkAction = ActionType<typeof networkActions>;
type NetworkState = {
  readonly isConnected: boolean;
  readonly isThrottled: boolean;
  readonly retryTimeout: number;
};

export const initialState: NetworkState = {
  isConnected: true,
  isThrottled: true,
  retryTimeout: 0
};

export const networkReducer: Reducer<NetworkState, NetworkAction> = (
  state: NetworkState = initialState,
  incomingAction: NetworkAction
) => {
  switch (incomingAction.type) {
    case SET_SPEED_THROTTLED:
      return {
        ...state,
        isThrottled: incomingAction.payload.isThrottled
      };
    case SET_CONNECTION_STATUS:
      return {
        ...state,
        isConnected: incomingAction.payload.isConnected,
        isThrottled: !incomingAction.payload.isConnected
      };
    case SET_RETRY_TIMEOUT:
      return {
        ...state,
        retryTimeout: incomingAction.payload.second
      };
    case RESET_CONNECTION_STATUS:
      return initialState;
    default:
      return state;
  }
};
