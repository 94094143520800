import firebase from 'firebase';
import { put, call } from 'redux-saga/effects';
import i18n from 'i18next';

import { NIMBLY_API_REPORT } from '../../../constants/api';
import { submitApiReportAsync, SubmitReportAPI } from './submitReport.action';
import RootNavigation from '../../../RootNavigation';

export function* submitReportSaga(action: { payload: SubmitReportAPI }): Generator {
  try {
    const reportID = action?.payload?.reportId;
    const reqBody = {
      report: action?.payload?.report,
      summary: action?.payload?.summary
    };

    const token = (yield firebase.auth().currentUser!.getIdToken()) as string;

    if (!token) {
      yield put(submitApiReportAsync.failure('Token is invalid'));
      return;
    }
    const request = () =>
      fetch(`${NIMBLY_API_REPORT}/${reportID}/submit`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: token
        },
        method: 'PUT',
        body: JSON.stringify(reqBody)
      });

    const response = (yield call(request)) as any;
    if (response?.status !== 200) {
      throw new Error(i18n.t('saga:submitApiReport.error'));
    }

    yield put(submitApiReportAsync.success(true));
    action.payload.callback.resolve();
  } catch (err) {
    RootNavigation.navigate('HomeTab', {
      screen: 'Home'
    });
    yield put(submitApiReportAsync.failure(err.message));
    action.payload.callback.reject();
  }
}
