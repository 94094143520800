import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Animated,
  Easing,
  Keyboard,
  Modal,
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
  ActivityIndicator
} from 'react-native';
import theme from '../../constants/theme';
import fontMaker from '../../utils/font';
// utils
import { SCREEN_WIDTH, widthPercentageToDP as wp } from '../../utils/screensize';
import Text from '../global/Text';

// type
// import { SiteScheduleModalProps } from './type';

const LoadingUpload = (props: any) => {
  const { showFooterModal, setShowFooter, handleTakePhoto, handleTakeFromGallery } = props;
  const [slideUpValue] = useState(new Animated.Value(0));
  const [slideDownValue] = useState(new Animated.Value(1));
  const [containerHeight, setContainerHeight] = useState(0);
  const [isShowKeyboard, setIsShowKeyboard] = useState(false);

  const keyboardWillShow: any = null;
  const keyboardWillHide: any = null;

  const { t } = useTranslation('questionnaire');

  const handleCloseOutletFooter = () => {
    setShowFooter(false);
    Animated.timing(slideDownValue, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
      easing: Easing.ease
    }).start();
  };

  const getTransformCondition = useMemo(() => {
    if (showFooterModal) {
      return {
        translateY: slideUpValue.interpolate({
          inputRange: [0, 1],
          outputRange: [200, 0]
        })
      };
    } else {
      return {
        translateY: slideDownValue.interpolate({
          inputRange: [0, 1],
          outputRange: [containerHeight, 0]
        })
      };
    }
  }, [showFooterModal]);

  useEffect(() => {
    if (Platform.OS === 'ios') {
      Keyboard.addListener('keyboardWillShow', handleKeyboardWillShow);
      Keyboard.addListener('keyboardWillHide', handleKeyboardWillHide);
    }

    return () => {
      if (Platform.OS === 'ios') {
        keyboardWillShow?.remove();
        keyboardWillHide?.remove();
      }
    };
  }, []);

  const handleKeyboardWillShow = () => {
    setIsShowKeyboard(true);
  };

  const handleKeyboardWillHide = () => {
    setIsShowKeyboard(false);
  };

  // handle animation slide up
  useEffect(() => {
    if (showFooterModal) {
      Animated.timing(slideUpValue, {
        toValue: 1,
        duration: 300,
        useNativeDriver: true,
        easing: Easing.ease
      }).start();
    }
  }, [showFooterModal]);

  return (
    <Modal visible={showFooterModal} transparent={true} animationType={'fade'}>
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={styles.root}>
          <ActivityIndicator color={theme.colors.primary} size="large" />
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const styles = StyleSheet.create({
  root: {
    backgroundColor: 'rgba(0,0,0,0.6)',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999999,
    flex: 1,
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH,
    marginHorizontal: 'auto'
  },
  icon: {
    marginBottom: SCREEN_WIDTH > 500 ? wp(0.1) : wp(2),
    height: SCREEN_WIDTH > 500 ? wp(1.8) : wp(7),
    width: SCREEN_WIDTH > 500 ? wp(1.8) : wp(7)
  },
  iconContainer: {
    paddingRight: 15,
    paddingTop: 10
  },
  borderLine: {
    borderBottomColor: '#EFEEED',
    borderBottomWidth: 1
  },
  container: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    marginHorizontal: 'auto',

    // based on height of container to wrapped all component
    backgroundColor: '#fff',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,

    // box shadow
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 5
    },
    shadowOpacity: 0.36,
    shadowRadius: 6.68,

    elevation: 11
  },
  close: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: 15,
    paddingRight: 10,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20
  },
  closeButton: {
    height: 25,
    width: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: 25 / 2,
    borderTopRightRadius: 25 / 2,
    borderBottomLeftRadius: 25 / 2,
    borderColor: theme.styles.fontColor.headline,
    borderWidth: 2
  },
  body: { backgroundColor: '#ffffff', padding: 20, paddingBottom: 38 },
  questionnaireTitleContainer: {
    maxHeight: 65,
    height: 65,
    alignItems: 'flex-start',
    marginBottom: 10
  },
  scheduleTypeOption: {
    // SCREEN WIDTH - padding
    width: (SCREEN_WIDTH - 40 - 4) / 2,
    justifyContent: 'center',
    alignItems: 'center'
  },
  activeScheduleOption: {
    backgroundColor: '#00AB4E',
    borderRadius: 30
  },
  activeText: {
    color: '#ffffff'
  },
  text: {
    fontSize: 17.5,
    lineHeight: 25,
    color: theme.styles.textDark.color,
    marginBottom: 10,
    cursor: 'pointer'
  },
  startButton: {
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.green,
    paddingVertical: 10
  },
  startButtonText: {
    fontSize: 18,
    lineHeight: 27,
    ...fontMaker({ weight: 'Bold' }),
    color: '#ffffff'
  },
  scheduleTypeButton: {
    width: (SCREEN_WIDTH - 40) / 2 - 5,
    height: 48,

    justifyContent: 'center',
    alignItems: 'center',

    borderColor: theme.colors.lightgreen,
    borderWidth: 1,
    borderRadius: 5
  },
  activeSelectedTypeButton: {
    backgroundColor: theme.colors.secondarylightgreen
  },
  typeText: {
    fontSize: 18,
    lineHeight: 27,
    ...fontMaker({ weight: 'SemiBold' }),
    color: theme.colors.lightgreen
  },
  containerTitle: { justifyContent: 'flex-start', alignItems: 'flex-start', marginBottom: 12, marginStart: 20 },
  titleText: {
    fontSize: 14,
    lineHeight: 19,
    fontWeight: '600',
    paddingTop: 17,
    color: theme.styles.textDark.color,
    ...fontMaker({ weight: 'SemiBold' })
  },
  containerSelectType: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 50
  },
  disableBorder: {
    borderColor: 'rgba(86, 199, 104, 0.3)'
  },
  disableText: {
    color: 'rgba(86, 199, 104, 0.3)'
  },
  multsiteContainer: {
    marginBottom: 20,
    color: theme.colors.dark
  },
  multisiteTitle: {
    fontSize: 18,
    lineHeight: 25,
    fontWeight: '500'
  },
  multisiteSubtitle: {
    marginTop: 10,
    fontSize: 14,
    lineHeight: 19,
    ...fontMaker({ weight: 'regular' })
  },
  makeupReasonContainer: {
    width: '100%',
    borderWidth: 1,
    borderRadius: 3,
    borderColor: '#CFE3FF',
    marginBottom: 30,
    height: 60,
    padding: 10,
    marginTop: 25
  },
  boldText: {
    ...fontMaker({ weight: 'SemiBold' })
  }
});

export default LoadingUpload;
