import React from 'react';
import {
  StyleSheet,
  View,
  Platform,
  TouchableWithoutFeedbackProps,
  TouchableNativeFeedback,
  TextStyle,
  TouchableOpacity,
  TextInput
} from 'react-native';
import Text from '../global/Text';
import { QuestionnaireQuestionProps } from './QuestionnaireQuestion';
import { questionTextInvalid, questionNumberInvalid } from '../../utils/report';
import theme from '../../constants/theme';
import fontMaker from '../../utils/font';
import { useTranslation } from 'react-i18next';
import QuestionnaireQuestionTutorialModal from './QuestionnaireQuestionTutorialModal';

export type QuestionnaireQuestionNumberProps = Pick<
  QuestionnaireQuestionProps,
  | 'question'
  | 'index'
  | 'currentConditionIndex'
  | 'language'
  | 'handleSelectChoice'
  | 'handleInputComment'
  | 'numberInputRef'
  | 'commentInputRef'
  | 'currentQuestionIndex'
  | 'isTutorialMode'
  | 'isShowQuestionTutorialModal'
  | 'tutorialQuestionType'
  | 'handleTextInputFocused'
  | 'handleTextInputBlurred'
  | 'handleInputDecimal'
  | 'handleNumberInputFocused'
  | 'handleNumberInputBlurred'
  | 'handleShowTutorialQuestionModal'
  | 'handleChangeTutorialQuestionType'
  | 'handleDismissTutorialQuestionModal'
  | 'handleDismissTutorialMode'
>;

export type QuestionnaireQuestionNumberPropsExtra = {
  styles: any;
};

const QuestionnaireQuestionNumber = (
  props: QuestionnaireQuestionNumberProps & QuestionnaireQuestionNumberPropsExtra
) => {
  const { t } = useTranslation(['questionnaire', 'common']);
  React.useEffect(() => {
    if (props.isTutorialMode && props.index === props.currentQuestionIndex) {
      props.handleChangeTutorialQuestionType(props.question.type);
      props.handleShowTutorialQuestionModal(true);
    }
  }, [props.index, props.currentQuestionIndex]);

  React.useEffect(() => {
    setComment(props.question.comment);
  }, [props.question.answer]);

  // temporary fix to handle input flicker and moving cursor in RN 0.63.3
  // TODO: revert this once the bug is handled, or there is a permanent fix
  const [comment, setComment] = React.useState<string>(props.question.comment);
  const handleInputComment = (text: string) => {
    setComment(text);
    props.handleInputComment(props.index, text, props.currentConditionIndex!);
  };

  return (
    <View>
      <QuestionnaireQuestionTutorialModal
        handleDismissTutorialQuestionModal={props.handleDismissTutorialQuestionModal}
        handleDismissTutorialMode={props.handleDismissTutorialMode}
        isShowQuestionTutorialModal={props.isShowQuestionTutorialModal}
        tutorialQuestionType={props.tutorialQuestionType}
        language={props.language}
      />
      {!props.question.answerRequired ? (
        <View style={props.styles.choicesList}>
          <Touchable
            onPress={() => props.handleSelectChoice(props.index, 'not-applicable', props.currentConditionIndex!)}
            testID="number-na"
          >
            <View
              style={
                props.question.answer === 'not-applicable'
                  ? [props.styles.choice, props.styles.choiceSelected]
                  : props.styles.choice
              }
            >
              <View style={props.styles.choiceFlag}>
                <View style={props.styles.noFlag} />
              </View>
              <Text
                style={
                  props.question.answer === 'not-applicable'
                    ? [props.styles.choiceText, props.styles.choiceSelectedText]
                    : props.styles.choiceText
                }
              >
                N/A
              </Text>
            </View>
          </Touchable>
        </View>
      ) : null}
      <View
        style={[
          styles.numberAnswerInputContainer,
          props.question.answer === 'not-applicable' && props.styles.commentDisabled,
          {
            borderColor: questionNumberInvalid(props.question, t) ? theme.colors.secondary : 'rgba(0, 0, 0, 0.05)'
          }
        ]}
      >
        <TextInput
          style={styles.numberAnswerInput}
          maxLength={20}
          editable={props.question.answer !== 'not-applicable'}
          onChangeText={text => props.handleInputDecimal(props.index, text, props.currentConditionIndex!)}
          value={props.question.answer === 'not-applicable' ? '' : props.question.answer.toString()}
          keyboardType={Platform.OS === 'android' ? 'default' : 'numbers-and-punctuation'}
          placeholder={
            props.question.answer === 'not-applicable'
              ? `${t('questionnaire:question.number.notApplicable')}`
              : t('questionnaire:question.number.typeAnswer')
          }
          ref={props.numberInputRef}
          onFocus={() =>
            props.handleNumberInputFocused(
              typeof props.currentConditionIndex! === 'number' ? props.currentConditionIndex! : props.index
            )
          }
          onBlur={() =>
            props.handleNumberInputBlurred(
              typeof props.currentConditionIndex! === 'number' ? props.currentConditionIndex! : props.index
            )
          }
          testID="number-input"
        />
      </View>

      <View
        style={[
          props.styles.openAnswerInputContainer,
          props.question.answer === 'not-applicable' && props.styles.commentDisabled,
          {
            borderColor: questionTextInvalid(props.question, t) ? theme.colors.secondary : 'rgba(0, 0, 0, 0.05)'
          }
        ]}
      >
        <TextInput
          style={props.styles.openCommentInput}
          maxLength={2500}
          multiline={true}
          editable={props.question.answer !== 'not-applicable'}
          numberOfLines={4}
          onChangeText={handleInputComment}
          value={comment}
          placeholder={
            props.question.answer === 'not-applicable'
              ? `${t('questionnaire:question.number.notApplicable')}`
              : `${t('questionnaire:question.number.comments')}${
                  props.question.answer === 'green-flag' ? t('questionnaire:question.number.optional') : ''
                }`
          }
          ref={props.commentInputRef}
          onFocus={() =>
            props.handleTextInputFocused(
              typeof props.currentConditionIndex! === 'number' ? props.currentConditionIndex! : props.index
            )
          }
          onBlur={() =>
            props.handleTextInputBlurred(
              typeof props.currentConditionIndex! === 'number' ? props.currentConditionIndex! : props.index
            )
          }
          testID="number-comment"
        />
      </View>
      {!questionTextInvalid(props.question, t) ? (
        <View style={props.styles.validationContainer}>
          <Text style={props.styles.textValidation}>&zwnj;</Text>
        </View>
      ) : (
        <View style={props.styles.validationContainer}>
          <Text style={props.styles.textValidation}> {questionTextInvalid(props.question, t)} </Text>
        </View>
      )}
    </View>
  );
};

export default QuestionnaireQuestionNumber;

const styles = StyleSheet.create({
  numberAnswerInputContainer: {
    justifyContent: 'flex-start',
    marginBottom: 15,
    marginHorizontal: 20,
    paddingVertical: 0,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.05)',
    borderRadius: 6
  },
  numberAnswerInput: {
    height: 39,
    ...fontMaker({ weight: 'Regular' }),
    color: '#535353',
    textAlignVertical: 'top',
    paddingVertical: 10,
    paddingHorizontal: 10
  }
});

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
  Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;
