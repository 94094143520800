import React from 'react';
import { StyleSheet, View } from 'react-native';

import Text from '../global/Text';
import theme from '../../constants/theme';
import { AuthContainerProps } from './AuthContainer';
import { useTranslation } from 'react-i18next';
import { AUTH_MODE } from '../../constants/Constants';

type AuthFormFooterProps = Pick<
  AuthContainerProps,
  'authMode' | 'language' | 'onChangeSectionLogin' | 'onChangeSectionSignup' | 'onContactWhatsAppSupport'
>;

type AuthFormFooterPropsExtend = AuthFormFooterProps & {
  handleVisibleTutorialModal: any;
};

const AuthFormFooter = (props: AuthFormFooterPropsExtend) => {
  const { t } = useTranslation(['auth', 'common']);
  if ([AUTH_MODE.SIGN_UP, AUTH_MODE.CONFIRM_OTP, AUTH_MODE.EMAIL_SENT].includes(props.authMode)) {
    return (
      <React.Fragment>
        <View style={styles.footerContainer}>
          <Text style={styles.footerText}>{t('auth:footer.haveAccount')} </Text>
          <Text style={[styles.loginsignupLink, theme.styles.textPrimary]} onPress={props.onChangeSectionLogin}>
            {t('auth:footer.login')}
          </Text>
        </View>
        <View style={[styles.footerContainer]}>
          <Text style={styles.footerText}>{t('auth:footer.havingTrouble')} </Text>
          <Text style={[styles.loginsignupLink, theme.styles.textPrimary]} onPress={props.onContactWhatsAppSupport}>
            {t('auth:footer.contactUs')}
          </Text>
        </View>
        <View style={[styles.footerContainer]}>
          <Text style={styles.footerText}>{t('auth:footer.needHelp')} </Text>
          <Text
            style={[styles.loginsignupLink, theme.styles.textPrimary]}
            onPress={() => props.handleVisibleTutorialModal(true)}
          >
            {t('auth:footer.clickHere')}
          </Text>
        </View>
      </React.Fragment>
    );
  } else if (props.authMode === 'login') {
    return (
      <React.Fragment>
        <View style={styles.footerContainer}>
          <Text style={styles.footerText}>{t('auth:footer.firstTime')} </Text>
          <Text style={[styles.loginsignupLink, theme.styles.textPrimary]} onPress={props.onChangeSectionSignup}>
            {t('auth:footer.activateAccount')}
          </Text>
        </View>
        <View style={[styles.footerContainer]}>
          <Text style={styles.footerText}>{t('auth:footer.havingTrouble')} </Text>
          <Text style={[styles.loginsignupLink, theme.styles.textPrimary]} onPress={props.onContactWhatsAppSupport}>
            {t('auth:footer.contactUs')}
          </Text>
        </View>
        <View style={[styles.footerContainer]}>
          <Text style={styles.footerText}>{t('auth:footer.needHelp')} </Text>
          <Text
            style={[styles.loginsignupLink, theme.styles.textPrimary]}
            onPress={() => props.handleVisibleTutorialModal(true)}
          >
            {t('auth:footer.clickHere')}
          </Text>
        </View>
      </React.Fragment>
    );
  }
  return null;
};

const styles = StyleSheet.create({
  footerContainer: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    marginTop: 5
  },
  footerText: {
    opacity: 0.4
  },
  loginsignupLink: {
    fontWeight: '600'
  }
});

export default AuthFormFooter;
