import { Alert } from 'react-native';
import { AlertButtonStyles } from '../typing/types';

export const warnNoConnection = (language: string, onDismiss: any = () => {}) => {
  const title = language === 'en' ? 'Upload Failed' : 'Gagal Mengunggah';
  const message =
    language === 'en'
      ? `You are currently offline.\nTry uploading again once you've connected to the internet.`
      : `Anda sedang offline.\nCoba unggah kembali saat Anda terhubung ke internet.`;

  const dismissAction = {
    text: language === 'en' ? 'Dismiss' : 'Tutup',
    onPress: () => onDismiss(),
    style: 'destructive' as AlertButtonStyles
  };

  return Alert.alert(title, message, [dismissAction]);
};
