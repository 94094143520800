import moment from 'moment';

const generateUnfinisedDate = () => {
  const dates = [];

  const startWeek = moment(Date.now()).startOf('week');
  const endWeek = moment(Date.now()).endOf('week');

  const substract = endWeek.diff(startWeek.format('YYYY-MM-DD'), 'day');

  for (let i = 0; i < substract; i++) {
    const date = startWeek.add(1, 'day').format('YYYY-MM-DD');
    dates.push(date);
  }

  return dates;
};

const generateScheduleDates = () => {
  const schedule = {};

  generateUnfinisedDate().map((date: string) => {
    schedule[date] = { isComplete: false };
  });

  return schedule;
};

export const tutorialQuestionnaireData = [
  {
    unfinishedDates: generateUnfinisedDate(),
    scheduledDates: generateScheduleDates(),
    totalScheduled: 7,
    hasDeadline: false,
    questionnaireTitle: 'Sample Report',
    emailTargets: [''],
    isQuestionnaireExists: true,
    endTime: null,
    questionnaireDetails: {
      key: '-QuestionnaireTutorial',
      value: {
        questionnaire: {
          status: 'published',
          dateCreated: '2019-07-28T22:32:55.397+07:00',
          questionnaireIndex: '-QuestionnaireTutorial',
          title: 'Sample report',
          dateUpdated: '2019-07-31T16:48:07.420+07:00',
          questions: [
            {
              answerRequired: true,
              photoMinimum: 0,
              category: 'Inventaris',
              answer: '',
              sku: '',
              comment: '',
              type: 'binary-with-red-flag',
              photoLimit: 0,
              remedy: '',
              reference:
                'https://storage.googleapis.com/sustainnovation-audit.appspot.com/tutorial%2FScreen%20Shot%202019-09-26%20at%202.23.png?GoogleAccessId=firebase-adminsdk-k4ccl%40sustainnovation-audit.iam.gserviceaccount.com&Expires=16725200400&Signature=k4AuPVmFqoD23iHpmUj0%2BRppcf%2BcuOpdsDCPoDsAerqTox4ELVEukMej8ZeutDkYjUzDcSlNnjGaBH2hMtcAzYoUaD3LUNDgJY0YCZaKqtwIs65%2BPYkTlm4dlvg7aprhyzqulyFwzwRonqYSPFOapdLOHN1nJinP8q0LGT7wityadyLVJFRtxl6ALao4tJOxWWqJdMZm%2BkD75vNEWUqxQtVohjAmNtLAcycY1VvRXZZmartgkOcZIAtvzy4AL5LxN8PhOj9Td6GZzlZD3jpEIDaa0Ax60CLeSTfWk4tjtIgYxUdIrfqcCkKOlIykPpyvi0DBa7KoJI0PTyEpqdBeTQ%3D%3D',
              score: 0,
              content: 'Apakah baju yang ditampilkan sesuai dengan referensi di bawah?'
            },
            {
              answerRequired: true,
              photoMinimum: 0,
              category: 'Store',
              answer: '',
              sku: '',
              comment: '',
              type: 'score',
              photoLimit: 0,
              remedy: '',
              reference: '',
              score: 10,
              content: 'Berapa nilai untuk kebersihan toko?'
            },
            {
              answerRequired: false,
              photoMinimum: 0,
              category: 'Staf',
              answer: '',
              sku: '',
              comment: '',
              type: 'open',
              photoLimit: 0,
              remedy: '',
              reference: '',
              score: 0,
              content: 'Apakah staf outlet menyampaikan promosi dengan benar ?'
            },
            {
              answerRequired: true,
              photoMinimum: 0,
              category: 'Store',
              answer: '',
              maxRange: 40,
              videoMinimum: 0,
              type: 'range-flag',
              comment: '',
              photoLimit: 0,
              minRange: 0,
              sku: '',
              reference: '',
              rangeOptions: [
                { flag: 'red', rangeFrom: 35, rangeTo: 40 },
                { flag: 'yellow', rangeFrom: 0, rangeTo: 15 }
              ],
              score: 0,
              videoLimit: 0,
              content: 'Temperature pada toko ?'
            },
            {
              reference: '',
              score: 0,
              content: 'check ',
              answer: '',
              category: 'Inventaris',
              sku: '',
              photoLimit: 0,
              type: 'inventory-v2',
              comment: '',
              answerRequired: true,
              skuInventory: {
                '-skuTutorial': {
                  final: 0,
                  in: 0,
                  out: 0
                }
              }
            },
            {
              answerRequired: true,
              photoMinimum: 0,
              category: 'Staf',
              answer: '',
              sku: '',
              comment: '',
              type: 'number',
              photoLimit: 0,
              remedy: '',
              reference: '',
              score: 0,
              content: 'Berapa staf yang hadir hari ini ?'
            },
            {
              multipleChoices: ['Merah', 'Kuning', 'Hijau'],
              photoMinimum: 0,
              category: 'Inventaris',
              answerRequired: true,
              answer: '',
              videoMinimum: 0,
              type: 'multiple-choice',
              comment: '',
              photoLimit: 0,
              sku: '',
              reference: '',
              score: 0,
              videoLimit: 0,
              content: 'Baju apa yang paling banyak terjual?'
            },
            {
              checklists: [
                'Merah',
                'Kuning',
                'Hijau',
                'Biru',
                'Ungu',
                'Oranye',
                'Pink',
                'Toska',
                'Hitam',
                'Biru Langit'
              ],
              photoMinimum: 0,
              category: 'Ketersediaan Barang',
              answerRequired: false,
              answer: '',
              videoMinimum: 0,
              type: 'checklist',
              comment: '',
              photoLimit: 0,
              sku: '',
              reference: '',
              score: 0,
              videoLimit: 0,
              content: 'Warna baju apa saja yang tersedia?'
            },
            {
              answerRequired: true,
              photoMinimum: 0,
              category: 'Staf',
              answer: '',
              sku: '',
              comment: '',
              type: 'binary',
              photoLimit: 1,
              remedy: '',
              reference: '',
              score: 0,
              content: 'Apakah staf menggunakan seragam?'
            }
          ],
          type: 'default',
          disabled: false
        },
        latest: '-Tutorial',
        versions: ['-Tutorial'],
        disabled: false
      }
    },
    isDoneToday: false,
    signatures: 1,
    key: '-scheduleTutorial',
    titleLowercase: 'sample report',
    totalCompleted: 0,
    isScheduledToday: true,
    startTime: null
  }
];
