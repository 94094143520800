import React from 'react';
import {
  Dimensions,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
  TouchableNativeFeedback
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import moment from 'moment';
import { useSelector } from 'react-redux';

import Text from '../../../../global/Text';
import fontMaker from '../../../../../utils/font';
import theme from '../../../../../constants/theme';
import SiteReportFilterCalendar from './SiteReportFilterCalendar';
import SiteReportFilterCalendarDays from './SiteReportFilterCalendarDays';
import { RootState } from '../../../../../store/reducers';

const { width, height } = Dimensions.get('window');

export type SiteReportFilterCalendarContainerProps = {
  /** Title above the calendar */
  title?: null | string;
  /** The start date selected in the calendar */
  startDate: null | string;
  /** The end date selected in the calendar */
  endDate: null | string;
  /** Current date that will be used to get month */
  currentMonth: number;
  /** Handle - handle the right arrow button to change to next month */
  handleChangeNextMonth: () => void;
  /** Handle - handle the left arrow button to change to previous month */
  handleChangePreviousMonth: () => void;
  /** Handle - handle the chosen date on calendar */
  handleSelectDate: (date: string) => void;
};

const SiteReportFilterCalendarContainer = (props: SiteReportFilterCalendarContainerProps) => {
  // Data from redux: global redux actions
  const { currentMonth } = props;
  const headerDateFormat = 'MMMM YYYY';

  return (
    <View style={styles.container} testID="SiteReportFilterCalendarContainer-container">
      <View style={styles.header}>
        <View style={styles.calendarContainer}>
          <View style={styles.monthRow}>
            <Touchable onPress={props.handleChangePreviousMonth} testID="left-icon">
              <View style={styles.chevron}>
                <Icon name="chevron-left" size={16} color={theme.colors.darkgray} />
              </View>
            </Touchable>
            <Text style={styles.calendarMonth}>{moment(currentMonth).format(headerDateFormat)}</Text>
            <Touchable onPress={props.handleChangeNextMonth} testID="right-icon">
              <View style={styles.chevron}>
                <Icon name="chevron-right" size={16} color={theme.colors.darkgray} />
              </View>
            </Touchable>
          </View>
          <SiteReportFilterCalendarDays currentMonth={props.currentMonth} />
          <SiteReportFilterCalendar
            currentMonth={props.currentMonth}
            startDate={props.startDate}
            endDate={props.endDate}
            handleSelectDate={props.handleSelectDate}
          />
        </View>
      </View>
    </View>
  );
};

const Touchable: any = TouchableOpacity;

const styles = StyleSheet.create({
  root: {
    flex: 1
  },
  overlay: {
    flex: 1,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: Platform.select({
    ios: {
      alignItems: 'center',
      backgroundColor: '#FFFFFF',
      width: width * 0.9,
      borderRadius: 5,
      maxHeight: height * 0.9,
      overflow: 'hidden',
      marginTop: 10
    },
    android: {
      alignItems: 'center',
      backgroundColor: '#FFFFFF',
      width: width * 0.9,
      borderRadius: 5,
      maxHeight: height * 0.9,
      overflow: 'hidden',
      marginTop: 10
    }
  }) as ViewStyle,
  header: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20
  },
  top: {
    paddingVertical: 8,
    marginVertical: 8,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  },
  cancel: {
    flex: 1,
    fontSize: 14,
    textAlignVertical: 'bottom',
    color: '#ff2323',
    ...fontMaker({ weight: 'Normal' })
  },
  title: {
    flex: 1,
    textAlign: 'center',
    fontSize: 16,
    color: '#535353',
    ...fontMaker({ weight: 'normal' })
  },
  done: {
    flex: 1,
    textAlign: 'right',
    textAlignVertical: 'bottom',
    fontSize: 14,
    color: '#20639B',
    ...fontMaker({ weight: 'Normal' })
  },
  dateBorder: {
    paddingHorizontal: 24,
    marginBottom: 14,
    width: '100%',
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6
  },
  dateClear: {
    position: 'absolute',
    top: -2,
    right: 0,
    padding: 8
  },
  empty: {
    ...fontMaker({ weight: 'Normal' }),
    color: theme.colors.gray
  },
  dateDisplay: {
    ...fontMaker({ weight: 'Normal' }),
    fontSize: 16,
    color: '#A0A4A8'
  },
  calendarContainer: {
    marginBottom: 18,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  monthRow: {
    width: '75%',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center'
  },
  chevron: {
    padding: 6
  },
  calendarMonth: {
    color: '#000000',
    fontSize: 15,
    fontWeight: '400',
    marginVertical: 4
  }
});

export default SiteReportFilterCalendarContainer;
