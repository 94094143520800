import React from 'react';

import { StyleSheet, View } from 'react-native';
import Text from '../global/Text';
import fontMaker from '../../utils/font';

import { appversion } from '../../constants/appversion';

const LandingVersion = () => {
  return (
    <View style={styles.version}>
      <Text style={styles.versionText}>v{appversion}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  version: {
    position: 'absolute',
    bottom: 8,
    right: 20
  },
  versionText: {
    ...fontMaker({ weight: 'Regular' }),
    color: '#fff',
    fontSize: 12
  }
});

export default LandingVersion;
