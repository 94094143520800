import { Site } from '../../../utils/classes';

export const dummySite = new Site(
  'Tutorial Site',
  'Sample Report',
  '',
  'Indonesia',
  'Jakarta',
  'Jakarta Selatan',
  'Jalan Pegangsaan Timur',
  '',
  { longitude: 107.60821499999997, latitude: -6.911542499999999 },
  0,
  'Sample Questionnaire',
  '',
  [],
  '',
  {},
  [],
  [],
  1,
  false,
  null,
  null
);
