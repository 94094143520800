import firebase from 'firebase';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ActivityIndicator, Alert, Animated, FlatList, Platform, StatusBar, StyleSheet, View } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Tooltip from 'react-native-walkthrough-tooltip';
import { connect } from 'react-redux';
import { isLoaded } from 'react-redux-firebase';
import { compose } from 'redux';
import theme from '../../constants/theme';
import RootNavigation from '../../RootNavigation';
import { RootState } from '../../store/reducers';
import { geolocationThunk } from '../../store/reducers/geolocation';
import { journeyActions } from '../../store/reducers/journey/journey';
import { fetchNPSUserAsync } from '../../store/reducers/nps/nps.action';
import { organizationActions } from '../../store/reducers/organization/organization';
import { getPopulatedQuestionnairesAsync } from '../../store/reducers/questionnaires/questionnaire.action';
import { getPopulatedQuestionnaires } from '../../store/reducers/questionnaires/questionnaire.actionSaga';
import { ScheduledJourneyInfo, scheduleJourneysThunk } from '../../store/reducers/schedulejourneys';
import { ScheduledSiteInfo, scheduleSitesThunk } from '../../store/reducers/schedulesites';
import { siteActions } from '../../store/reducers/site/site';
import { tutorialThunks } from '../../store/reducers/tutorial';
import { InjectedSiteStatus } from '../../typing/types';
import { ReportSummary, Site } from '../../utils/classes';
import fontMaker from '../../utils/font';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../utils/screensize';
import shallowCompare from '../../utils/shallowCompare';
import Text from '../global/Text';
import TooltipContent from '../global/tutorial/TooltipContent';
import SiteListItem from '../schedule/SiteListItem';
import SurveyItem from '../schedule/SurveyItem';
import getJourneyReportStatus from '../schedule/utils/getJourneyReportStatus';
import getJourneyStatus from '../schedule/utils/getJourneyStatus';
import { dummySite } from './assets/tutorialSiteDummy';

const AnimatedFlatList = Animated.createAnimatedComponent(FlatList);

export class WelcomeDaySchedule extends React.Component<WelcomeDayScheduleProps> {
  _scrollY: Animated.Value = new Animated.Value(0);
  _offset = 0;
  listRef: any;
  isRenderedSurveyCard = false;
  skipBtn: { title: string; onPress: () => void } = {
    title: this.props.t('home:tutorial.end'),
    onPress: async () => {
      await this.props.dismissTutorialMode();
      // For some reason the component does not want to re-render after props change
      // Thus, we call forceUpdate() to force it to re-render
      this.forceUpdate();
    }
  };

  constructor(props: WelcomeDayScheduleProps) {
    super(props);
    this.listRef = React.createRef();
  }

  // does a shallow comparison for sorted sites and updates render
  shouldComponentUpdate(nextProps: WelcomeDayScheduleProps) {
    return shallowCompare(nextProps.sortedSites, this.props.sortedSites);
  }

  handleScroll = (e: any) => {
    const offsetY = e.nativeEvent.contentOffset.y;

    // pass offset to animate WelcomeSummary component
    if (this.props.onScroll && offsetY >= 0 && offsetY < SCREEN_HEIGHT * 0.25) {
      this.props.onScroll(offsetY * 1.275);
    }

    // pass offset to animate 'top' value of this component
    this._scrollY.setValue(offsetY);

    // return offset value of Flatlist
    return Animated.event([{ nativeEvent: { contentOffset: { y: offsetY } } }], {
      useNativeDriver: true
    });
  };

  handleNavSite = (siteKey: string, site: Site, siteCompletedReports: number, siteLastReport: ReportSummary | null) => {
    const { auth } = this.props;

    this.props.selectSite(
      siteKey,
      {
        ...site,
        assignedAuditor: !site.isMultiSite ? auth.uid : site.assignedAuditor
      },
      null,
      siteCompletedReports,
      siteLastReport
    );

    RootNavigation.navigate('Site', {
      profileColor: this.props.profileColor,
      originTab: 'HomeTab',
      siteName: site.name
    });
  };

  handleNavJourney = (journey: ScheduledJourneyInfo, isDueToday: boolean) => {
    const {
      profileColor,
      setJourney,
      setJourneyReport,
      ongoingJourneyReportKey,
      language,
      journeyReport,
      setOngoingJourneyReportKey,
      t
    } = this.props;

    let ongoingKey = ongoingJourneyReportKey;
    const status = getJourneyStatus(journey, ongoingJourneyReportKey);

    const report = journey.report || journeyReport;

    // handle draft journey
    if (status === 'draft') {
      ongoingKey = report?.code || null;
      setOngoingJourneyReportKey(report?.code || null);
    }

    if (ongoingJourneyReportKey && report?.code !== ongoingKey) {
      Alert.alert(t('home:daySchedule.reminder'), t('home:daySchedule.onGoing'), [
        {
          text: 'OK'
        }
      ]);
      return;
    }

    setJourney(journey.journeyProfile);
    setJourneyReport(report);

    RootNavigation.navigate('Journey', {
      profileColor: profileColor,
      originTab: 'HomeTab',
      isDueToday: isDueToday || getJourneyReportStatus(journey.report, ongoingJourneyReportKey) === 'ongoing' //handle completed ongoing journey
    });
  };

  getSiteStatus = (scheduledSiteInfo: ScheduledSiteInfo): InjectedSiteStatus => {
    switch (true) {
      case scheduledSiteInfo.isPendingUpload:
        return 'pending-upload';
      case scheduledSiteInfo.isDraft:
        return 'draft';
      case scheduledSiteInfo.isMakeUp:
        return 'makeup';
      case scheduledSiteInfo.isDueToday:
        return 'ready';
      case scheduledSiteInfo.isCompletedThisPeriod:
        return 'finished-period';
      case scheduledSiteInfo.isCompletedToday:
        return 'finished-today';
      case !scheduledSiteInfo.isScheduledThisPeriod:
        return 'none-due';
      default:
        return 'ready';
    }
  };

  componentDidMount() {
    const { organization, getMyScheduledSites, getMyScheduledJourneys, getOrganization } = this.props;

    if (!isLoaded(organization)) {
      return;
    }

    getMyScheduledSites(organization!.schedule);
    getPopulatedQuestionnaires();
    getOrganization(undefined);
  }

  onPressSurvey = async () => {
    const urlSurveyMonkey = 'https://www.surveymonkey.com/r';
    const { language, profileColor } = this.props;

    const typeEvent = 'NpsSurvey';

    const getSelectorDoneButton = `document.querySelector('button.done-button').addEventListener("click", function() {  
      window.ReactNativeWebView.postMessage(JSON.stringify({type: "click", message : "NpsSurvey"}));
    });`;

    RootNavigation.navigate('WebView', {
      url: `${urlSurveyMonkey}/${language === 'id' ? 'WLSQPTH' : 'VHMRCFS'}`,
      profileColor: profileColor,
      purpose: typeEvent,
      injectedJsScript: getSelectorDoneButton
    });
  };

  _renderEmptyList = () => (
    <View style={styles.empty}>
      <Icon name="check-circle-outline" size={56} color={theme.colors.dark} />
      <Text style={styles.emptyText}>{this.props.t('home:daySchedule.noDue')}</Text>
      <Text style={styles.emptySubtitle}>{this.props.t('home:daySchedule.pullDown')}</Text>
    </View>
  );

  _handleAnalyticImpressionSurveyCard = async () => {
    await firebase.analytics().logEvent('impression_survey_card', {
      userid: this.props.auth.uid
    });
  };

  _renderNPSCard = (index: number, isEligible: boolean, sortedSites: any[]) => {
    if (!isEligible) {
      return;
    }

    // index begin in 0
    // the rule is, NPSCard Always in 5rd position. If sites card less than 5 then displayed on the latest position
    if (index === 3) {
      this.isRenderedSurveyCard = true;
      this._handleAnalyticImpressionSurveyCard();
      return <SurveyItem onPressSurvey={this.onPressSurvey} />;
    }

    if (index + 1 === sortedSites.length && !this.isRenderedSurveyCard) {
      this._handleAnalyticImpressionSurveyCard();
      return <SurveyItem onPressSurvey={this.onPressSurvey} />;
    }
  };

  render() {
    const {
      scrollable,
      hasNotch,
      organization,
      sortedSites,
      isReady,
      isFetchingMySites,
      isDummy,
      isTutorialMode,
      isFocused,
      npsUserIsEligible
    } = this.props;

    const rootStyles = [
      styles.root,
      scrollable && sortedSites.length !== 0
        ? {
            top: this._scrollY.interpolate({
              inputRange: [0, SCREEN_HEIGHT * 0.45],
              outputRange: [SCREEN_HEIGHT * 0.1875, SCREEN_HEIGHT * 0.1375],
              extrapolate: 'clamp'
            })
          }
        : null
    ];

    return (
      <Animated.View style={rootStyles}>
        {!isLoaded(organization) ? (
          <View style={styles.loading}>
            <ActivityIndicator color={theme.colors.primary} size="large" />
          </View>
        ) : (
          <>
            {isTutorialMode ? (
              <View style={[styles.siteList, { marginTop: sortedSites.length === 0 ? SCREEN_HEIGHT * 0.1875 : -20 }]}>
                <Tooltip
                  topAdjustment={-StatusBar.currentHeight!}
                  placement="top"
                  content={<TooltipContent text={this.props.t('home:tutorial.tap')} skipBtn={this.skipBtn} />}
                  isVisible={isTutorialMode && isFocused}
                  onClose={() => false}
                >
                  <SiteListItem
                    showMap={false}
                    showSales={false}
                    disableNavigation={false}
                    dueToday={false}
                    siteProfile={dummySite}
                    siteStatus={'ready'}
                    siteKey={'-siteTutorial'}
                    reportScheduled={3}
                    reportCompleted={0}
                    onSitePressed={() => this.handleNavSite('siteTutorial', dummySite, 0, null)}
                  />
                </Tooltip>
              </View>
            ) : (
              <AnimatedFlatList
                ref={this.listRef}
                keyExtractor={(item: any) => item.siteKey || item.journeyKey}
                scrollEventThrottle={1}
                overScrollMode="never"
                bounces={true}
                style={sortedSites.length === 0 ? styles.siteListOuterEmpty : undefined}
                contentContainerStyle={[
                  scrollable ? styles.siteList : styles.siteListCompat,
                  hasNotch ? styles.siteListNotch : null,
                  sortedSites.length === 0 ? styles.siteListEmpty : null
                ]}
                data={sortedSites}
                renderItem={({ item, index }: { item: ScheduledSiteInfo & ScheduledJourneyInfo; index: number }) => (
                  <>
                    {item.siteKey ? (
                      <>
                        <SiteListItem
                          dueToday={true}
                          reportScheduled={!isDummy ? item.scheduledCount : 7}
                          reportCompleted={!isDummy ? item.completedCount : 5}
                          todayOnly={true}
                          onSitePressed={() => {
                            this.handleNavSite(item.siteKey, item.siteProfile, item.completedCount, null);
                          }}
                          siteProfile={item.siteProfile}
                          siteStatus={this.getSiteStatus(item)}
                          siteKey={item.siteKey}
                          key={item.siteKey}
                        />
                        {/* {this._renderNPSCard(index, npsUserIsEligible, sortedSites)} */}
                      </>
                    ) : (
                      // if item is a journey
                      <SiteListItem
                        dueToday={true}
                        reportScheduled={item.scheduledCount}
                        reportCompleted={item.completedCount}
                        todayOnly={true}
                        onSitePressed={() => {
                          this.handleNavJourney(item, !!item.isDueToday);
                        }}
                        siteProfile={item.journeyProfile}
                        siteStatus={getJourneyStatus(item, this.props.ongoingJourneyReportKey)}
                        siteKey={item.journeyKey}
                        key={item.journeyKey}
                      />
                    )}
                  </>
                )}
                ListEmptyComponent={this._renderEmptyList}
                onScroll={this.handleScroll}
              />
            )}
          </>
        )}
      </Animated.View>
    );
  }
}

type WelcomeDayScheduleProps = OwnProps & EnhancedProps & WithTranslation;

const mapDispatchToProps = {
  selectSite: siteActions.selectSite,
  getMyScheduledSites: scheduleSitesThunk.getMyScheduledSites,
  getMyScheduledJourneys: scheduleJourneysThunk.getMyScheduledJourneys,
  updateCacheLocation: geolocationThunk.updateCacheLocation,
  dismissTutorialMode: tutorialThunks.dismissTutorialMode,
  setJourney: journeyActions.setJourney,
  setJourneyReport: journeyActions.setJourneyReport,
  setOngoingJourneyReportKey: journeyActions.setOngoingJourneyReportKey,
  getNPSUser: fetchNPSUserAsync.request,
  getOrganization: organizationActions.fetchMyOrganizationAsync.request,
  getPopulatedQuestionnaires: getPopulatedQuestionnairesAsync.request
};

type OwnProps = {
  onPressSurvey: () => void;
  isFocused: boolean;
  scrollable: boolean;
  npsUserIsEligible: boolean;
  sortedSites: ScheduledSiteInfo[];
  onScroll?: (num: number) => void;
  isReady: boolean;
  isDayOff: boolean;
};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type EnhancedProps = StateProps & DispatchProps;

const mapStateToProps = (state: RootState) => ({
  auth: state.firebase.auth,
  hasNotch: state.account.hasNotch,
  cameraActive: state.report.cameraActive,
  language: state.firebase.profile.language || 'en',
  isFetchingMySites: state.scheduleSites.isFetchingMySites,
  isFetchingMyJourneys: state.scheduleJourneys.isFetchingMyJourneys,
  isDummy: state.account.isDummy,
  organization: state.organization.myOrganization,
  cacheLocation: state.geolocation.cacheLocation,
  profileColor: state.profiletheme.color,
  isTutorialMode: state.tutorial.isTutorialMode,
  isOffDay: state.firebase.data.organizationOffDay,
  journeyReport: state.journey.journeyReport,
  ongoingJourneyReportKey: state.journey.ongoingJourneyReportKey,
  npsUserIsEligible: state.npsUser.isEligible
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps), withTranslation(['home, common']));

export default enhance(WelcomeDaySchedule) as any;

const styles = StyleSheet.create({
  root: {
    backgroundColor: 'transparent'
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  empty: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  emptyText: {
    marginTop: 8,
    ...fontMaker({ weight: 'Light' }),
    fontSize: 20,
    lineHeight: 22
  },
  emptySubtitle: {
    ...fontMaker({ weight: 'Light' }),
    fontSize: 14
  },
  siteListOuterEmpty: {
    marginTop: SCREEN_HEIGHT * 0.3,
    marginBottom: -(SCREEN_HEIGHT * 0.15)
  },
  siteListEmpty: {
    flexGrow: 0.5,
    minHeight: SCREEN_HEIGHT * 0.3
  },
  siteList: {
    minHeight: SCREEN_HEIGHT * 1.25,
    paddingTop: SCREEN_HEIGHT * (SCREEN_WIDTH >= 360 ? 0.25 : Platform.OS === 'ios' ? 0.29 : 0.35),
    paddingBottom: SCREEN_HEIGHT * 0.3
  },
  siteListNotch: {
    marginTop: 20
  },
  siteListCompat: {
    marginTop: 10,
    minHeight: SCREEN_HEIGHT * 1.2,
    // paddingTop: SCREEN_HEIGHT * (SCREEN_WIDTH >= 800 ? 0.025 : SCREEN_WIDTH >= 360 ? 0.15 : 0.25),
    flexGrow: 1,
    paddingBottom: 100
  }
});
