import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { connect, MapStateToProps } from 'react-redux';
import {
  Platform,
  StyleSheet,
  View,
  Image,
  TouchableOpacity,
  TouchableNativeFeedback,
  TouchableWithoutFeedbackProps,
  TouchableOpacityProps
} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { compose } from 'redux';
import { enums } from 'nimbly-common';

import SiteSalesSummary from './SiteSalesSummary';
import Text from '../global/Text';
import fontMaker from '../../utils/font';
import { mapsConfig } from '../../constants/gmaps';
import { RootState } from '../../store/reducers';
import { Coordinates, Site } from '../../utils/classes';
import { ConnectedDispatch, InjectedSiteStatus } from '../../typing/types';

export class SiteListItem extends React.Component<SiteListItemProps> {
  handlePressSite = (siteKey: string, site: Site) => {
    this.props.onSitePressed!(siteKey, site);
  };

  _renderAccentColor = (status: string, dueToday: boolean) => {
    const { todayOnly } = this.props;
    switch (status) {
      case 'draft':
        return '#ffa040';
      case 'makeup':
        return '#c43e00';
      case 'ready':
        if (dueToday && !todayOnly) {
          return '#c43e00';
        }
        return '#fff';
      case 'adhoc':
        return '#fff';
      case 'finished-period':
        return '#a8a8a8';
      case 'finished-today':
        return '#a8a8a8';
      case 'ongoing':
        return '#3cd170';
      default:
        return '#fff';
    }
  };

  _renderSiteStatusText = (status: string, dueToday: boolean) => {
    const { todayOnly, t } = this.props;

    switch (status) {
      case 'pending-upload':
        return (
          <Text style={[styles.siteStatusText, styles.pendingStatusColor]}>
            {t('schedule:pendingUpload').toUpperCase()}
          </Text>
        );
      case 'error-questionnaire-not-found':
        return null;
      case 'none-due':
        return null;
      case 'draft':
        return (
          <Text style={[styles.siteStatusText, styles.draftStatusColor]}>{t('schedule:inProgress').toUpperCase()}</Text>
        );
      case 'makeup':
        return (
          <Text style={[styles.siteStatusText, styles.priorityStatusColor]}>{t('schedule:overdue').toUpperCase()}</Text>
        );
      case 'ready':
        if (dueToday && !todayOnly) {
          return (
            <Text style={[styles.siteStatusText, styles.priorityStatusColor]}>
              {t('schedule:priority').toUpperCase()}
            </Text>
          );
        }
        return null;
      case 'finished-today':
        if (todayOnly) {
          return (
            <Text style={styles.siteStatusDoneText}>
              <Icon name="check-circle-outline" size={42} color="#a8a8a8" />
            </Text>
          );
        }
        return null;
      case 'finished-period':
        return (
          <Text style={styles.siteStatusDoneText}>
            <Icon name="check-circle-outline" size={42} color="#a8a8a8" />
          </Text>
        );
      case 'ongoing':
        return (
          <Text style={[styles.siteStatusText, styles.ongoingStatusColor]}>{t('schedule:onGoing').toUpperCase()}</Text>
        );
      default:
        return null;
    }
  };

  _renderMap = () => {
    const { address, coordinates, t } = this.props;

    if (!coordinates || (coordinates.longitude === 0 && coordinates.latitude === 0)) {
      return (
        <Touchable style={Platform.OS === 'ios' ? styles.addressSection : null}>
          <View style={Platform.OS === 'android' ? styles.addressSection : null}>
            <View style={styles.addressContainer}>
              <View style={styles.address}>
                <Icon name="map-marker" size={16} style={styles.addessIcon} />
                <Text style={styles.addressText}>{t('schedule:error.noLocationFound').toUpperCase()}</Text>
              </View>
            </View>
          </View>
        </Touchable>
      );
    }
    return (
      <Touchable onPress={this.props.onPressMap} style={Platform.OS === 'ios' ? styles.addressSection : null}>
        <View style={Platform.OS === 'android' ? styles.addressSection : null}>
          <View style={styles.addressContainer}>
            <View style={styles.address}>
              <Icon name="map-marker" size={16} style={styles.addessIcon} />
              <Text allowFontScaling={false} style={styles.addressText}>
                {address === '' ? t('schedule:noLocation').toUpperCase() : address}
              </Text>
            </View>
            <View style={styles.mapContainer}>
              <Image
                style={styles.coverContent}
                source={{
                  uri: `https://maps.googleapis.com/maps/api/staticmap?center=${coordinates!.latitude},${
                    coordinates!.longitude
                  }2&zoom=15&size=190x190&markers=size:mid%7Ccolor:red%7C${coordinates!.latitude},${
                    coordinates!.longitude
                  }&key=${mapsConfig.apiKeys[Platform.OS]}`
                }}
              />
            </View>
          </View>
        </View>
      </Touchable>
    );
  };

  render() {
    const {
      showMap,
      showSales,
      disableNavigation,
      dueToday,
      siteProfile,
      siteStatus,
      siteKey,
      reportScheduled,
      reportCompleted,
      featureAccessSales,
      t
    } = this.props;

    // Some styling based on site status
    const accentColor = this._renderAccentColor(siteStatus, !!dueToday);
    const Pressable: React.ComponentClass<TouchableWithoutFeedbackProps & TouchableOpacityProps> = disableNavigation
      ? View
      : Touchable;

    // Push marginTop when item contains Map / in SiteListItem details
    const siteStyle: any[] = [styles.site];
    if (showMap) {
      siteStyle.push(styles.siteWithAddress);
    }

    return (
      <Pressable
        onPress={() => (!disableNavigation ? this.handlePressSite(siteKey, siteProfile) : null)}
        activeOpacity={0.75}
      >
        <View style={[siteStyle, { borderRightColor: accentColor }]}>
          <View style={styles.siteProfile}>
            <View style={styles.siteText}>
              <View style={styles.siteTextTopLine}>
                <View style={styles.siteTextLeft}>
                  <Text
                    numberOfLines={1}
                    style={[
                      styles.siteSubtitle,
                      siteStatus === 'finished-period' || siteStatus === 'none-due' ? styles.siteSubtitleDone : {}
                    ]}
                  >
                    {siteProfile.subtitle}
                  </Text>
                </View>
                <View style={styles.siteSubtitleRight}>{this._renderSiteStatusText(siteStatus, !!dueToday)}</View>
              </View>
              <Text
                style={[
                  styles.siteName,
                  siteStatus === 'finished-period' || siteStatus === 'none-due' ? styles.siteNameDone : {}
                ]}
              >
                {siteProfile.name || siteProfile.journeyName}
              </Text>
              <View style={styles.siteStatus}>
                {reportScheduled > 0 ? (
                  <Text style={styles.siteReportsDoneText}>
                    {`${t('schedule:done').toUpperCase()}  `}
                    <Text style={styles.siteStatusTextBold}>
                      {reportCompleted || '0'}/{reportScheduled}
                    </Text>
                  </Text>
                ) : null}
                {!disableNavigation ? (
                  <Text style={styles.sitePlaceholderText}>{t('schedule:tapTodoReport').toUpperCase()}</Text>
                ) : null}
              </View>
              {showSales && featureAccessSales ? <SiteSalesSummary siteKey={siteKey} /> : null}
            </View>
          </View>
          {showMap ? this._renderMap() : null}
        </View>
      </Pressable>
    );
  }
}

type StateProps = {
  pendingSites: { [siteKey: string]: { [reportKey: string]: boolean | object } };
  featureAccessSales: boolean;
};

type OwnProps = {
  todayOnly?: boolean;
  onSitePressed?: (siteKey: string, site: Site) => void;
  onPressMap?: () => void;
  address?: string;
  coordinates?: null | Coordinates;
  disableNavigation?: boolean;
  showMap?: boolean;
  showSales?: boolean;
  dueToday: boolean;
  reportScheduled: number;
  reportCompleted: number;
  siteStatus: InjectedSiteStatus;
  siteProfile: Site | any;
  siteKey: string;
  t: any;
};

type SiteListItemProps = OwnProps & StateProps & ConnectedDispatch & WithTranslation;

const mapStateToProps: MapStateToProps<StateProps, OwnProps, RootState> = state => ({
  pendingSites: state.reportsubmit.pendingSites,
  featureAccessSales: state.featureAccess.features[enums.Features.SALES_TARGET_TRACKER]
});

const enhance = compose(withTranslation('schedule'), connect(mapStateToProps));

export default enhance(SiteListItem) as any;

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
  Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

const styles = StyleSheet.create({
  site: {
    // flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
    // alignItems: 'center',
    marginHorizontal: 12.5,
    marginBottom: 12.5,
    borderRadius: 10,
    backgroundColor: '#fff',
    // Android settings
    elevation: 5,
    // iOS settings
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: 5 },
    borderRightWidth: 9
  },
  siteWithAddress: {
    marginTop: 12.5
  },
  siteProfile: {
    // flex: 1,
    flexDirection: 'row',
    paddingHorizontal: 20,
    paddingVertical: 17.5
  },
  siteText: {
    width: '100%'
  },
  siteTextTopLine: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 3
  },
  siteTextLeft: {
    flex: 1
  },
  siteTextCenter: {
    flex: 1.15,
    alignItems: 'center'
  },
  siteTextRight: {
    flex: 1,
    alignItems: 'flex-end'
  },
  siteSubtitle: {
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 14,
    lineHeight: 20
  },
  siteSubtitleRight: {
    alignItems: 'flex-end'
  },
  siteSubtitleDone: {
    color: '#a8a8a8'
  },
  siteStatusText: {
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 14,
    lineHeight: 20
  },
  pendingStatusColor: {
    color: '#ffbf00'
  },
  draftStatusColor: {
    color: '#ffa040'
  },
  priorityStatusColor: {
    color: '#c43e00'
  },
  ongoingStatusColor: {
    color: '#3cd170'
  },
  siteStatusDoneText: {
    marginBottom: -22,
    lineHeight: 42
  },
  siteName: {
    ...fontMaker({ weight: 'SemiBold' }),
    maxWidth: 260,
    fontSize: 18,
    lineHeight: 21
  },
  siteNameDone: {
    color: '#a8a8a8'
  },
  siteStatus: {
    width: '100%',
    marginTop: 6,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  siteHoursText: {
    flex: 1,
    fontSize: 13,
    color: '#a8a8a8'
  },
  siteFlagsText: {
    flex: 1,
    fontSize: 13,
    color: '#a8a8a8'
  },
  siteReportsDoneText: {
    flex: 1,
    fontSize: 13,
    color: '#a8a8a8'
  },
  sitePlaceholderText: {
    fontSize: 14,
    color: '#a8a8a8'
  },
  siteStatusTextBold: {
    ...fontMaker({ weight: 'SemiBold' }),
    color: '#a8a8a8'
  },
  address: {
    flex: 4,
    flexDirection: 'row',
    alignItems: 'flex-start',
    paddingVertical: 5
  },
  addessIcon: {
    paddingTop: 4,
    paddingRight: 6
  },
  addressText: {
    flex: 1,
    ...fontMaker({ weight: 'Light' }),
    fontSize: 12
  },
  addressSection: {
    width: '100%',
    flex: 1,
    paddingBottom: 10,
    paddingHorizontal: 20
  },
  addressContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderTopColor: '#d7dadf',
    borderTopWidth: StyleSheet.hairlineWidth,
    paddingTop: 6
  },
  mapContainer: {
    flex: 2,
    marginLeft: 10,
    marginVertical: 8
  },
  coverContent: {
    flex: 1
  }
});
