import React, { useMemo, useCallback } from 'react';
import { View, StyleSheet, Dimensions, ListRenderItemInfo, FlatList } from 'react-native';

import Text from '../global/Text';
import { QuestionnaireQuestionInventoryNewProps } from './QuestionnaireQuestionInventoryNew';
import QuestionnaireQuestionInventoryNewCardItem from './QuestionnaireQuestionInventoryNewCardItem';

import { capsFirstLetter } from '../../utils/string';
import { InventoryQuestion } from '../../utils/classes';
import { useTranslation } from 'react-i18next';
const screen = Dimensions.get('screen');
const { width, height } = screen;

export interface QuestionnaireQuestionInventoryNewCardPropsExtra {
  styles: any;
}

export type QuestionnaireQuestionInventoryNewCardProps = QuestionnaireQuestionInventoryNewCardPropsExtra &
  Pick<
    QuestionnaireQuestionInventoryNewProps,
    | 'question'
    | 'index'
    | 'allowAuditorStockIn'
    | 'keyboardShown'
    | 'language'
    | 'query'
    | 'siteSKUs'
    | 'skuQuery'
    | 'calibratingSKU'
    | 'skuIn'
    | 'skuFinal'
    | 'skuCommentVisible'
    | 'inventoryCardRefs'
    | 'handleConfirmEditPhoto'
    | 'handleToggleSKUComment'
    | 'handleActivateImagePicker'
    | 'handleClearSKUQuery'
    | 'handleInputSKUQuery'
    | 'handleSelectChoice'
    | 'handleInputSKUInventory'
    | 'handleInventoryInputFocused'
    | 'handleInventoryInputBlurred'
    | 'scrollRef'
  >;

const QuestionnaireQuestionInventoryNewCard = (props: QuestionnaireQuestionInventoryNewCardProps) => {
  const { t } = useTranslation(['questionnaire', 'common']);
  const {
    siteSKUs,
    question,
    calibratingSKU,
    inventoryCardRefs,
    skuFinal,
    language,
    allowAuditorStockIn,
    handleInputSKUInventory,
    index,
    handleInventoryInputFocused,
    handleInventoryInputBlurred,
    skuCommentVisible,
    handleConfirmEditPhoto,
    handleToggleSKUComment,
    handleActivateImagePicker,
    skuIn,
    scrollRef,
    keyboardShown
  } = props;
  const { skuInventory: questionSKUInventory } = question;

  const filteredSKUs = Object.keys(props.question?.skuInventory || {})?.filter(
    skuKey =>
      props.siteSKUs![skuKey] &&
      props.siteSKUs![skuKey].skuDetail &&
      (props.skuQuery === '' ||
        props.siteSKUs![skuKey].skuDetail.name.toLowerCase().indexOf(props.skuQuery.toLowerCase().trim()) > -1 ||
        props.siteSKUs![skuKey].skuDetail.description.toLowerCase().indexOf(props.skuQuery.toLowerCase().trim()) > -1)
  );

  const renderSKUListEmpty = () => {
    return (
      <View style={props.styles.emptySKUContainer}>
        <Text style={props.styles.emptySKUHeaderTitle}>{t('questionnaire:question.newInventory.SKUNotFound')}</Text>
        <Text style={props.styles.emptySKUHeaderText}>{t('questionnaire:question.newInventory.contactAdmin')}</Text>
      </View>
    );
  };

  const sortedSKUs = useMemo(
    () =>
      [...filteredSKUs]?.sort((a, b) => {
        const aNum = props.siteSKUs![a].skuDetail.number.toString().toLowerCase();
        const bNum = props.siteSKUs![b].skuDetail.number.toString().toLowerCase();
        const aName = props.siteSKUs![a].skuDetail.name.toLowerCase();
        const bName = props.siteSKUs![b].skuDetail.name.toLowerCase();
        if (aNum > bNum) {
          return 1;
        }
        if (aNum < bNum) {
          return -1;
        }
        if (aName > bName) {
          return 1;
        }
        if (aName < bName) {
          return -1;
        }
        return 0;
      }),
    [filteredSKUs]
  );

  /**
   * `renderItem` func to render SKU Inventory Item card
   */
  const renderSKUItem = useCallback(
    ({ item: skuKey, index: skuIndex }: ListRenderItemInfo<string>) => {
      const skuInventory = !!siteSKUs && siteSKUs[skuKey];
      if (skuInventory) {
        const defaultQ: InventoryQuestion = {
          in: 0,
          out: 0,
          final: 0
        };
        // new sku inserted after while report is already exists
        const skuInventoryQuestion = questionSKUInventory ? questionSKUInventory[skuKey] : defaultQ;
        const startNumber = skuInventory.stock;
        const outNumber = skuInventoryQuestion.out;
        const isCalibrating = startNumber === 0 && calibratingSKU && calibratingSKU[skuKey];
        const inOrigin = skuInventory.skuDetail.inOrigin;
        const validateIn = skuInventory.skuDetail.inOrigin === 'onsite' && !isCalibrating;

        const skuItemName = skuInventory.skuDetail.name;
        const skuItemUnit = skuInventory.skuDetail.unit ? ` - ${capsFirstLetter(skuInventory.skuDetail.unit)}` : '';
        const skuItemDesc = skuInventory.skuDetail.description ? `${skuInventory.skuDetail.description}` : '';

        const skuInValue = skuIn[skuKey] || '';
        const skuFinalValue = skuFinal[skuKey] || '';
        const skuItemComment = skuInventoryQuestion.comment || '';
        const skuItemPhotos = skuInventoryQuestion.photos || [];
        const skuItemCommentVisible = skuCommentVisible ? skuCommentVisible[skuKey] : false;

        return (
          <QuestionnaireQuestionInventoryNewCardItem
            // from parent
            language={language}
            question={question}
            allowAuditorStockIn={allowAuditorStockIn}
            index={index}
            // handler
            handleInputSKUInventory={handleInputSKUInventory}
            handleInventoryInputFocused={handleInventoryInputFocused}
            handleInventoryInputBlurred={handleInventoryInputBlurred}
            handleActivateImagePicker={handleActivateImagePicker}
            handleConfirmEditPhoto={handleConfirmEditPhoto}
            handleToggleSKUComment={handleToggleSKUComment}
            // own props
            containerRef={inventoryCardRefs[skuKey]}
            inOrigin={inOrigin}
            skuKey={skuKey}
            skuIndex={skuIndex}
            startNumber={startNumber}
            outNumber={outNumber}
            validateIn={validateIn}
            skuItemName={skuItemName}
            skuItemUnit={skuItemUnit}
            skuItemDesc={skuItemDesc}
            skuInValue={skuInValue}
            skuFinalValue={skuFinalValue}
            skuItemCommentVisible={skuItemCommentVisible}
            skuItemComment={skuItemComment}
            skuItemPhotos={skuItemPhotos}
          />
        );
      }
    },
    [
      siteSKUs,
      question,
      calibratingSKU,
      inventoryCardRefs,
      skuFinal,
      language,
      allowAuditorStockIn,
      handleInputSKUInventory,
      index,
      handleInventoryInputFocused,
      handleInventoryInputBlurred,
      skuCommentVisible,
      handleConfirmEditPhoto,
      handleToggleSKUComment,
      handleActivateImagePicker,
      skuIn
    ]
  );

  /**
   * `keyExtractor` func provided to FlatList
   */
  const skuItemKeyExtractor = (skuKey: string | null, skuIndex: number) => `${skuKey}-${skuIndex}`;

  return (
    <FlatList
      ref={scrollRef}
      data={sortedSKUs}
      renderItem={renderSKUItem}
      ListEmptyComponent={renderSKUListEmpty}
      keyExtractor={skuItemKeyExtractor}
      initialNumToRender={30}
      maxToRenderPerBatch={5}
      onScrollToIndexFailed={() => {}} // do nothing, to prevent error thrown
      style={styles.list}
      contentContainerStyle={styles.listContent}
      contentInset={{ bottom: keyboardShown ? KEYBOARD_SHOWN_LIST_BOTTOM_INSET : DEFAULT_LIST_BOTTOM_INSET }}
      bounces={false}
      removeClippedSubviews={true}
      viewabilityConfig={{ waitForInteraction: true }}
    />
  );
};

const KEYBOARD_SHOWN_LIST_BOTTOM_INSET = 175;
const DEFAULT_LIST_BOTTOM_INSET = width <= 360 ? 52 : 24;

export default QuestionnaireQuestionInventoryNewCard;

const styles = StyleSheet.create({
  list: { height: height * 0.6 },
  listContent: { flexGrow: 1, backgroundColor: '#fafafa', paddingBottom: (height * 0.1) / 4 }
});
