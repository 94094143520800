import { Platform, TextStyle } from 'react-native';

type Fonts = {
  [fontFamily: string]: {
    weights: {
      [weight: string]: '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900' | 'normal' | 'bold';
    };
    styles: {
      [style: string]: 'normal' | 'italic';
    };
  };
};

const fonts: Fonts = {
  Poppins: {
    weights: {
      Light: '300',
      Regular: '400',
      SemiBold: '600',
      Bold: '700'
    },
    styles: {
      Normal: 'normal',
      Italic: 'italic'
    }
  }
};

const fontMaker = (options: { family?: string; weight?: string; style?: 'Normal' | 'Italic' }): TextStyle => {
  const { family, weight, style } = options;
  const { weights, styles } = fonts.Poppins;

  if (Platform.OS === 'android') {
    const weightAndroid = weight && weights[weight] ? weight : 'Regular';
    const styleAndroid = style && styles[style] ? style : '';
    const suffix = weightAndroid + styleAndroid;

    const outputAndroid: TextStyle = {
      fontFamily: family || `Poppins${suffix.length ? `-${suffix}` : ''}`
    };
    return outputAndroid;
  }

  const familyIOS: string = family || 'Poppins';
  const weightIOS: '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900' | 'normal' | 'bold' =
    weight && weights[weight] ? weights[weight] : weights.Regular;
  const styleIOS: 'normal' | 'italic' = style && styles[style] ? styles[style] : 'normal';

  const outputIOS: TextStyle = {
    fontFamily: familyIOS,
    fontWeight: weightIOS,
    fontStyle: styleIOS
  };
  return outputIOS;
};

export default fontMaker;
