import { ScheduledJourneyInfo } from '../../../store/reducers/schedulejourneys';

/**
 * Generate list of journeys and seperate the journeys into different categories
 * @param myJourneysScheduled raw sites retrieved from database
 * @param role the user's role including permissions map
 * @param ttranslation function
 */
function generateJourneys(myJourneysScheduled: ScheduledJourneyInfo[], t: any): any[] {
  const _tempAssigned: ScheduledJourneyInfo[] = [];
  const _tempCompleted: ScheduledJourneyInfo[] = [];
  const _tempSections: any[] = [];
  const _tempUploadPending: ScheduledJourneyInfo[] = [];

  const sectionTitle: any = {
    0: t('schedule:journeyReadyToUpload'),
    1: t('schedule:journeyAssigned'),
    2: t('schedule:journeyCompleted')
  };

  myJourneysScheduled.forEach(scheduledSite => {
    if (
      scheduledSite.isScheduledThisPeriod &&
      !scheduledSite.isCompletedToday &&
      !scheduledSite.isCompletedThisPeriod
    ) {
      _tempAssigned.push(scheduledSite);
    } else {
      _tempCompleted.push(scheduledSite);
    }
  });

  [_tempUploadPending, _tempAssigned, _tempCompleted].forEach((arr, i) => {
    if (arr && arr.length > 0) {
      _tempSections.push({ title: sectionTitle[i], data: arr });
    }
  });

  return _tempSections;
}

export default generateJourneys;
