import { action, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';

export const ADD_BROADCAST_LIMIT = '@broadcast/ADD_BROADCAST_LIMIT';

/**
 * Maximum amount of broadcast executed
 */
const addBroadcastLimit = () => action(ADD_BROADCAST_LIMIT);

export const broadcastAction = { addBroadcastLimit };

export type BroadcastAction = ActionType<typeof broadcastAction>;
export type BroadcastState = { readonly fetchLimit: number };

const initialState: BroadcastState = { fetchLimit: 20 };
export const broadcastReducer: Reducer<BroadcastState, BroadcastAction> = (state = initialState, incomingAction) => {
  switch (incomingAction.type) {
    case ADD_BROADCAST_LIMIT:
      return { ...state, fetchLimit: state.fetchLimit + 10 };
    default:
      return state;
  }
};
