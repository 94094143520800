import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import {
  Dimensions,
  LayoutAnimation,
  NativeModules,
  Platform,
  StyleSheet,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableWithoutFeedbackProps,
  View,
  ImageSourcePropType,
  TouchableNativeFeedbackProps
} from 'react-native';
import AvatarNew from './AvatarNew';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Text from '../global/Text';
import fontMaker from '../../utils/font';
import { SCREEN_WIDTH } from '../../utils/screensize';
if (Platform.OS === 'android') {
  // settings for animation to work in android
  const { UIManager } = NativeModules;
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

type LobbyQuestionnaireProps = {
  completion: number;
  onPress: () => void;
  disabled: boolean;
  language: string;
  onDelete: () => void;
  imageSrc: ImageSourcePropType | null;
  username: string;
  sectionName: string;
  ownSection: boolean;
  status: string | null;
  hasCheckedOut: boolean;
  isFocused: boolean;
  isUploading: boolean;
  isFinishedUploading: boolean | null;
  uploadProgress: number;
  t: any;
};

class LobbyQuestionnaire extends PureComponent<LobbyQuestionnaireProps> {
  constructor(props: LobbyQuestionnaireProps) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps(props: LobbyQuestionnaireProps) {
    // animate width
    if (props.isFocused) {
      const config = {
        duration: 450,
        update: {
          type: LayoutAnimation.Types.linear,
          property: 'opacity'
        }
      };
      LayoutAnimation.configureNext(config);
    }

    return null;
  }

  _renderProgressBar = (completion: number) => {
    const { disabled, status, isFinishedUploading } = this.props;
    return (
      <View
        style={[
          styles.progress,
          { width: `${completion}%` },
          (disabled && status === 'complete') || isFinishedUploading
            ? { backgroundColor: '#a8a8aa' }
            : completion === 100
            ? { backgroundColor: '#00695c' }
            : {}
        ]}
      />
    );
  };

  _renderSectionStatus = () => {
    const { hasCheckedOut, status, isFinishedUploading, t } = this.props;

    //if (!hasCheckedOut) {
    if (!status) {
      return t('lobby:status.tapToStart');
    }
    if (status === 'draft') {
      return t('lobby:status.inProgress');
    }
    //} else {
    if (status === 'complete' || isFinishedUploading) {
      return t('lobby:status.uploaded');
    }
    if (status === 'waiting_for_upload') {
      return t('lobby:status.waitingForUpload');
    }
    if (status === 'rejected') {
      return t('lobby:status.rejected');
    }
    if (status === 'approved') {
      return t('lobby:status.approved');
    }
    // }
    return null;
  };

  getNameInitials = (username: string) => {
    const selectedName = !username ? 'Member' : username;

    return selectedName
      .split(' ')
      .map(n => n[0])
      .join('')
      .substring(0, 2);
  };

  handleNavQuestionnaire = () => {
    this.props.onPress();
  };

  handleShowActionSheet = () => {
    this.props.onDelete();
  };

  render() {
    const {
      imageSrc,
      sectionName,
      username,
      disabled,
      completion,
      hasCheckedOut,
      status,
      ownSection,
      isUploading,
      isFinishedUploading,
      uploadProgress
    } = this.props;
    return (
      <View style={styles.rootShadow}>
        <Touchable
          delayPressIn={0}
          style={styles.card}
          // eslint-disable-next-line no-empty
          onPress={disabled ? () => null : this.handleNavQuestionnaire}
          background={Platform.OS === 'android' ? TouchableNativeFeedback.Ripple('#a8a8aa', false) : undefined}
        >
          <View style={Platform.OS === 'android' ? styles.card : {}}>
            {this._renderProgressBar(completion)}
            <View style={styles.content}>
              <View style={styles.details}>
                <View style={[styles.avatarContainer, styles.avatarBorder]}>
                  {completion === 0 && !username ? (
                    <View style={styles.iconContainer}>
                      <Icon style={styles.icon} name="plus" size={38} color="#535353" />
                    </View>
                  ) : !imageSrc ? (
                    <View style={[styles.avatar, styles.leadBorder]}>
                      <View style={styles.avatarBackground}>
                        <Text style={[styles.avatarName, completion === 100 ? styles.completed : {}]}>
                          {this.getNameInitials(username)}
                        </Text>
                      </View>
                    </View>
                  ) : (
                    <AvatarNew source={imageSrc} name={this.getNameInitials(username)} type="questionnaire" />
                  )}
                </View>
                <View style={styles.textContainer}>
                  <Text
                    numberOfLines={1}
                    ellipsizeMode="tail"
                    style={[styles.name, completion === 100 ? styles.completed : {}]}
                  >
                    {sectionName.toUpperCase()}
                  </Text>
                  {isUploading && isFinishedUploading !== null && uploadProgress < 1 ? (
                    Platform.OS === 'ios' ? (
                      <View style={{ marginVertical: 6 }}>
                        {/* <ProgressViewIOS progressTintColor="#3cd070" progress={uploadProgress} /> */}
                        <></>
                      </View>
                    ) : (
                      // <ProgressBarAndroid styleAttr="Horizontal" color="#3cd070" progress={0.8} />
                      <></>
                    )
                  ) : (
                    <Text
                      numberOfLines={1}
                      ellipsizeMode="tail"
                      style={[
                        styles.status,
                        isFinishedUploading
                          ? styles.completed
                          : status === 'draft'
                          ? styles.draft
                          : status === 'rejected'
                          ? styles.rejected
                          : status === 'approved'
                          ? styles.approved
                          : status === 'complete'
                          ? styles.completed
                          : completion === 100
                          ? styles.waitingForUpload
                          : {}
                      ]}
                    >
                      {this._renderSectionStatus()}
                    </Text>
                  )}
                </View>
              </View>
              <View style={[styles.percentageContainer, completion < 100 && ownSection ? { marginRight: 0 } : {}]}>
                {((status === 'complete' && hasCheckedOut) || isFinishedUploading) && (
                  <Icon name="check" size={32} color="white" />
                )}
                {!['complete', 'rejected', 'approved'].includes(status) && (
                  <Text style={[styles.percentageText, completion === 100 ? styles.completed : {}]}>{completion}%</Text>
                )}
              </View>
              {!hasCheckedOut && status === 'draft' ? (
                <View
                  style={[styles.optionsContainerView, status === 'draft' && ownSection ? {} : { display: 'none' }]}
                >
                  <Touchable onPress={this.handleShowActionSheet} delayPressIn={0}>
                    <View style={styles.optionsContainer}>
                      <Icon name="dots-vertical" size={24} color={completion < 100 ? '#535353' : '#FFFFFF'} />
                    </View>
                  </Touchable>
                </View>
              ) : null}
            </View>
          </View>
        </Touchable>
      </View>
    );
  }
}

const { width } = Dimensions.get('window');

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps & TouchableNativeFeedbackProps> =
  Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

const styles = StyleSheet.create({
  rootShadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 4
    },
    shadowOpacity: 0.25,
    shadowRadius: 6
  },
  card: {
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH,
    marginVertical: 6,
    borderRadius: 6,
    borderTopLeftRadius: 100 / 2,
    borderBottomLeftRadius: 100 / 2,
    backgroundColor: '#fff',
    overflow: 'hidden'
  },
  progress: {
    position: 'absolute',
    top: '-5%',
    flex: 1,
    height: '110%',
    backgroundColor: '#439889'
  },
  content: {
    flex: 1,
    flexDirection: 'row',
    marginLeft: 8,
    marginVertical: 6
  },
  details: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexBasis: '70%'
  },
  avatarContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    // marginVertical: 6,
    width: 56,
    height: 56,
    borderRadius: 56 / 2,
    overflow: 'visible',
    backgroundColor: 'transparent',
    padding: 1
  },
  avatarBorder: {
    borderWidth: 2,
    borderColor: '#3cd070',
    zIndex: 2
  },
  avatarImage: {
    zIndex: 1,
    width: 46,
    height: 46,
    borderRadius: 46 / 2,
    resizeMode: 'cover'
  },
  iconContainer: {
    position: 'absolute',
    top: Platform.OS === 'ios' ? 3 : 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
    alignContent: 'center'
  },
  icon: {
    textAlign: 'center'
  },
  textContainer: {
    marginLeft: 10,
    marginTop: 10,
    flex: 1
  },
  name: { ...fontMaker({ weight: 'SemiBold' }), fontSize: 16, lineHeight: 24, color: '#1b2133' },
  status: { ...fontMaker({ weight: 'Bold' }), fontSize: 12, lineHeight: 14, color: '#535353' },
  draft: {
    color: '#ffa040'
  },
  percentageContainer: {
    flex: 2,
    minWidth: 47,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 16
  },
  percentageText: {
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 18,
    color: '#1b2133',
    lineHeight: 24
  },
  optionsContainerView: {
    flex: 2,
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: '100%',
    height: '100%'
  },
  optionsContainer: {
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginTop: Platform.OS === 'ios' ? 3 : 0,
    paddingRight: 8,
    width: width * 0.1,
    height: '100%'
  },
  completed: {
    color: '#fff'
  },
  waitingForUpload: {
    color: '#FFB844',
    fontSize: 12,
    fontWeight: '600'
  },
  rejected: {
    backgroundColor: '#FC4C5E',
    color: '#FFFFFF',
    fontWeight: '600',
    fontSize: 10,
    height: 24,
    overflow: 'hidden',
    width: 67,
    borderRadius: 20,
    paddingTop: 5,
    paddingRight: 8,
    paddingBottom: 4,
    paddingLeft: 8
  },
  approved: {
    backgroundColor: '#55DA6A',
    height: 24,
    fontWeight: '600',
    fontSize: 10,
    overflow: 'hidden',
    width: 67,
    borderRadius: 20,
    paddingTop: 5,
    paddingRight: 8,
    paddingBottom: 4,
    paddingLeft: 8
  },
  avatar: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 6,
    width: 56,
    height: 56,
    borderRadius: 56 / 2,
    overflow: 'visible',
    backgroundColor: 'transparent',
    padding: 1
  },
  avatarBackground: {
    backgroundColor: '#439889',
    height: 46,
    width: 46,
    borderRadius: 46 / 2,
    padding: 2,
    justifyContent: 'center',
    alignContent: 'center'
  },
  avatarName: {
    ...fontMaker({ weight: 'Bold' }),
    fontSize: 22,
    lineHeight: 26,
    textAlign: 'center',
    color: '#1b2133'
  },
  leadBorder: {
    borderWidth: 2,
    borderColor: '#3cd070',
    zIndex: 2
  }
});

const enhance = compose(withTranslation());

export default enhance(LobbyQuestionnaire as any);
