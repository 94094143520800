import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  Platform,
  Dimensions,
  StyleSheet,
  TouchableOpacity,
  TouchableNativeFeedback,
  TouchableWithoutFeedbackProps,
  Animated,
  Keyboard,
  EmitterSubscription,
  ViewStyle
} from 'react-native';
import Text from './Text';
import theme from '../../constants/theme';
import fontMaker from '../../utils/font';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../utils/screensize';
import { toastActions, ToastState as ToastStore } from '../../store/reducers/toast';
import { ConnectedDispatch } from '../../typing/types';
import { RootState } from '../../store/reducers';
import getStatusBarHeight from '../../helpers/getStatusBarHeight';

// Toast component controlled by Redux
export class Toast extends React.Component<ToastProps, ToastState> {
  animatedValue: Animated.Value;
  keyboardDidShowListener: EmitterSubscription | undefined;
  keyboardDidHideListener: EmitterSubscription | undefined;

  state = {
    _keyboardShown: false
  };

  constructor(props: ToastProps) {
    super(props);
    this.animatedValue = new Animated.Value(0);
  }

  componentDidMount() {
    if (!this.props.toast.top) {
      this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this._keyboardDidShow);
      this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this._keyboardDidHide);
    }
  }
  componentDidUpdate(prevProps: ToastProps) {
    if (this.props.toast.show && !prevProps.toast.show) {
      if (this.props.toast.duration === 0) {
        this.show();
      } else {
        this.showTimed(this.props.toast.duration);
      }
    } else if (!this.props.toast.show && prevProps.toast.show) {
      this.close();
    }
  }

  componentWillUnmount() {
    if (this.keyboardDidShowListener) {
      this.keyboardDidShowListener.remove();
    }
    if (this.keyboardDidHideListener) {
      this.keyboardDidHideListener.remove();
    }
  }

  _keyboardDidShow = () => {
    this.setState({ _keyboardShown: true });
  };

  _keyboardDidHide = () => {
    this.setState({ _keyboardShown: false });
  };

  handleClose = () => {
    this.props.dispatch(toastActions.closeToast());
  };

  show() {
    Animated.timing(this.animatedValue, {
      toValue: 1,
      duration: 350,
      useNativeDriver: true
    }).start();
  }

  showTimed(duration: number) {
    Animated.timing(this.animatedValue, {
      toValue: 1,
      duration: 350,
      useNativeDriver: true
    }).start(() => this.closeTimed(duration));
  }

  close() {
    Animated.timing(this.animatedValue, {
      toValue: 0,
      duration: 350,
      useNativeDriver: true
    }).start();
  }

  closeTimed(duration: number) {
    setTimeout(this.handleClose, duration);
  }

  render() {
    const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
      Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

    const finalPosition = this.props.toast.top ? -70 : 70;
    const animation = this.animatedValue.interpolate({
      inputRange: [0, 0.3, 1],
      outputRange: [finalPosition, 10, 0]
    });

    return (
      <Animated.View
        style={[
          styles.toastRoot,
          this.state._keyboardShown ? styles.toastHidden : styles.toastShown,
          this.props.toast.top ? styles.toastTop : styles.toastBottom,
          {
            backgroundColor: this.props.toast.backgroundColor,
            transform: [{ translateY: animation }]
          }
        ]}
      >
        <Text style={styles.toastText}>{this.props.toast.message}</Text>
        <Touchable onPress={this.handleClose} style={styles.button}>
          <Text
            style={[
              styles.buttonText,
              {
                color: this.props.toast.color ? this.props.toast.color : theme.styles.textPrimary.color
              }
            ]}
          >
            OK
          </Text>
        </Touchable>
      </Animated.View>
    );
  }
}

type ToastState = {
  _keyboardShown: boolean;
};

type ToastProps = StateProps & ConnectedDispatch;

type StateProps = {
  toast: ToastStore;
  language: 'en' | 'id';
};

const mapStateToProps = (state: RootState): StateProps => ({
  toast: state.toast,
  language: state.firebase.profile.language || 'en'
});

export default compose(connect(mapStateToProps))(Toast) as any;

const styles = StyleSheet.create({
  toastRoot: {
    height: 70,
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH,
    top: -70,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  toastHidden: {
    zIndex: -100
  },
  toastShown: {
    zIndex: 1000
  },
  toastBottom: {
    bottom: 0
  },
  toastText: { flex: 9, marginLeft: 20, color: 'white', fontSize: 14 },
  button: {
    flex: 1,
    paddingVertical: 8,
    paddingHorizontal: 10
  },
  buttonText: {
    ...fontMaker({ weight: 'SemiBold' })
  }
});
