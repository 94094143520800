import React from 'react';
import { useDispatch } from 'react-redux';
import { reportActions } from '../../store/reducers/report';

import { Platform, StyleSheet, TouchableOpacity, LayoutAnimation, ViewStyle } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

/**
 * Component for the burger menu button to toggle the drawer
 */
const QuestionnaireDrawerButton = () => {
  const dispatch = useDispatch();

  // Toggle the redux state as the navigator we need is in a child component
  const handleToggleDrawer = () => {
    LayoutAnimation.configureNext(
      LayoutAnimation.create(120, LayoutAnimation.Types.linear, LayoutAnimation.Properties.scaleXY)
    );
    dispatch(reportActions.toggleDrawerVisibility());
  };

  return (
    <TouchableOpacity onPress={() => handleToggleDrawer()}>
      <Icon name="view-list" size={32} color="#fff" style={styles.icon} />
    </TouchableOpacity>
  );
};

export default QuestionnaireDrawerButton;

const styles = StyleSheet.create({
  icon: {
    paddingTop: 2,
    paddingHorizontal: 16
  }
});
