import { RouteProp } from '@react-navigation/native';

import { ReportSectionIndex, EmailTarget, Site, User } from '../../../utils/classes';
import { InjectedSite } from '../../../typing/types';
import { HomeStackParamList, ScheduleStackParamList } from '../../../routes';

import retrieveMemberEmails from './retrieveMemberEmails';

/**
 * Get email targets from the auditor (single or team audit)
 */
export default function getEmailTargets(
  auth: any,
  profile: User,
  selectedSite: Site & InjectedSite,
  sections: ReportSectionIndex[],
  route: RouteProp<HomeStackParamList | ScheduleStackParamList, 'SubmitReport'>,
  journey?: any
) {
  let emailTargets = selectedSite.emailTargets || [];
  if (journey) {
    emailTargets = journey.emailTargets || [];
  }
  let emails = emailTargets
    .filter(email => email)
    .map(
      (email): EmailTarget => ({
        email,
        enabled: true,
        status: 'unsent',
        setByAdmin: true
      })
    );
  if (emailTargets.indexOf(profile.email) === -1) {
    emails.push({
      email: profile.email,
      enabled: true,
      status: 'unsent',
      setByAdmin: false
    });
  }
  if (selectedSite.isMultiSite) {
    emails = emails.concat(retrieveMemberEmails(auth, sections, route));
  }
  return emails;
}
