import React, { useState, useEffect, useRef } from 'react';

import { Platform, StyleSheet, TouchableNativeFeedback, TouchableOpacity, View, ScrollView } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import theme from '../../constants/theme';
import Text from './Text';
import { SCREEN_WIDTH } from '../../utils/screensize';

export type Option = {
  label: string;
  value: string;
};

type SwitchProps = {
  active: boolean;
  onChange: () => void;
};

const Switch = (props: SwitchProps) => {
  const { active, onChange } = props;

  return (
    <TouchableOpacity style={[styles.switchContainer, active && styles.switchContainerActive]} onPress={onChange}>
      <View style={[styles.switch, active && styles.switchActive]} />
    </TouchableOpacity>
  );
};

export default Switch;

const width = SCREEN_WIDTH;

const styles = StyleSheet.create({
  switchContainer: {
    height: 20,
    width: 40,
    backgroundColor: theme.colors.light,
    borderRadius: 20
  },
  switchContainerActive: {
    backgroundColor: '#3cd070'
  },
  switch: {
    height: 16,
    width: 16,
    borderRadius: 16,
    marginTop: 2,
    marginLeft: 2,
    backgroundColor: 'white',
    zIndex: 1
  },
  switchActive: {
    marginLeft: 40 - 16 - 2
  }
});
