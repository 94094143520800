import React from 'react';
import { Provider } from 'react-redux';
import firebase from 'firebase';
import * as serviceWorker from './serviceWorker';

import { name as AppName } from './app.json';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { AppRegistry, View, ActivityIndicator, Platform, StyleSheet } from 'react-native';
import { enableScreens } from 'react-native-screens';
import 'react-native-gesture-handler';
import { PersistGate } from 'redux-persist/integration/react';

import { reduxfbConfig } from './constants/firebase';
import { store, persistor } from './store/store';
import App from './App';

// require('react-native-vector-icons/fonts');
// import { freshChatNotifInit } from './utils/localnotification';

// Use prebuilt version of RNVI in dist folder
import Icon from 'react-native-vector-icons/dist/MaterialCommunityIcons';

// Generate required css
import iconFont from './assets/fonts/MaterialCommunityIcons.ttf';
const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: MaterialCommunityIcons;
}`;

// Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

document.head.appendChild(style);

// Inject stylesheet

import './i18n';

if (Platform.OS === 'ios') {
  enableScreens();
}

class PersistLoader extends React.Component {
  render() {
    return (
      <View style={{ justifyContent: 'center' }}>
        <ActivityIndicator size="large" color="#3cd070" />
      </View>
    );
  }
}

const Audit = () => {
  const rrfProps = {
    firebase,
    config: reduxfbConfig,
    dispatch: store.dispatch
  };

  //set init
  // freshChatNotifInit();

  return (
    <View>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <View style={{ height: 100 }}>
              <App />
            </View>
          </ReactReduxFirebaseProvider>
        </PersistGate>
      </Provider>
    </View>
  );
};

// const styles = StyleSheet.create({
//   app: {
//     marginHorizontal: 'auto',
//     maxWidth: 500
//   }
// });

serviceWorker.register({});

// console.disableYellowBox = true;
AppRegistry.registerComponent('Audit', () => Audit);
AppRegistry.runApplication(AppName, {
  rootTag: document.getElementById('root')
});
