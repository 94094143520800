import { select, put, takeLeading, cancel, call } from 'redux-saga/effects';
import { Task } from 'redux-saga';

// redux
import * as actions from './journeySchedule.action';
import getUserJourneySchedule, { getUserJourneyScheduleProps } from './utils/getUserJourneySchedule';

// types
import { RootState } from '../../reducers';
import { UserJourneyScheduleInfo } from './journeySchedule.reducer';

// actionTypes
import * as types from './journeySchedule.actionTypes';

function* fetchJourneySchedule(action: ReturnType<typeof actions.fetchJourneyScheduleRequest>): Generator {
  const { siteKey, site, startDate, endDate, organizationID } = action.payload;

  const stateSelector = (state: RootState) => state;
  const stateCurrent: RootState = ((yield select(stateSelector)) as unknown) as RootState;

  const { journey, firebase } = stateCurrent;
  const { auth } = firebase;
  const journeyReportSummary = journey.reportSummary;

  try {
    const params: getUserJourneyScheduleProps = {
      organizationKey: organizationID,
      siteKey,
      site,
      journey,
      startDate,
      endDate,
      auth
    };

    yield put(actions.setIsLoading(siteKey, true));
    let getData = null;
    // @ts-ignore
    const { data } = yield call(getUserJourneySchedule, params);

    // temporary handle to disable if questionnaire is already done
    const newData = data?.map((s: any) => {
      const qIndexID = s.questionnaireDetails.key;
      const isDone = (journeyReportSummary[siteKey] || []).findIndex(q => q.qIndex === qIndexID) !== -1;
      if (isDone) {
        s.isDoneToday = true;
      }
      return s;
    });
    getData = newData;
    yield put(actions.setJourneyScheduleData(siteKey, getData as UserJourneyScheduleInfo[]));
    yield put(actions.setIsLoading(siteKey, false));
  } catch (error) {
    // handle error
  } finally {
    yield put(actions.setIsLoading(siteKey, false));
  }
}

function* cancelWorkerSaga(task: Task) {
  yield cancel(task);
}

export default function* journeySchedule() {
  const fetchJourneyScheduleSaga = yield takeLeading(types.FETCH_JOURNEY_SCHEDULE_REQUEST, fetchJourneySchedule);
  yield takeLeading(types.CANCEL_FETCH, cancelWorkerSaga, fetchJourneyScheduleSaga);
}
