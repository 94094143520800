import React from 'react';

import { Dimensions, StyleSheet, Animated, View, StyleProp, Platform, StatusBar } from 'react-native';
import Text from '../global/Text';
import theme from '../../constants/theme';
import { round } from '../../utils/number';

import Tooltip from 'react-native-walkthrough-tooltip';
import TooltipContent from '../global/tutorial/TooltipContent';
import { SCREEN_WIDTH } from '../../utils/screensize';

// Progress bar for questionnaires. Takes 2 props: current index and total number of questions.
export class QuestionnaireProgress extends React.Component<QuestionnaireProgressProps> {
  // Bug: Android scaleX 0 value glitches out. Set to 0.001 temporarily.
  state = {
    animation: new Animated.Value(0.001),
    isTooltipFocused: false,
    initValueTooltip: this.props.completed,
    showProgressBar: false
  };

  componentDidMount() {
    const progress = Math.min(Math.max((this.props.completed * 1.0) / this.props.total, 0), 1);
    Animated.spring(this.state.animation, {
      toValue: progress,
      bounciness: 0,
      useNativeDriver: true
    }).start();
  }

  componentDidUpdate() {
    const progress = Math.min(Math.max((this.props.completed * 1.0) / this.props.total, 0), 1);
    Animated.spring(this.state.animation, {
      toValue: progress,
      bounciness: 0,
      useNativeDriver: true
    }).start();

    if (
      this.props.isTutorialMode &&
      !this.props.isShowQuestionTutorialModal &&
      this.state.initValueTooltip !== this.props.completed &&
      !this.state.isTooltipFocused
    ) {
      this.setState({ showProgressBar: true, isTooltipFocused: true });
    }
  }

  handleHideProgressBar = () => {
    this.setState({ showProgressBar: false });
  };

  render() {
    const { completed, total } = this.props;
    const progress = Math.min(Math.max((this.props.completed * 1.0) / this.props.total, 0), 1);
    const innerWidth = SCREEN_WIDTH > 500 ? 600 : 250;

    const progressBarCompleteStyle: StyleProp<any> = [
      styles.progressBarComplete,
      {
        transform: [
          {
            translateX: this.state.animation.interpolate({
              inputRange: [0, 1],
              outputRange: [innerWidth / -2, 0]
            })
          },
          {
            scaleX: this.state.animation
          }
        ],
        width: innerWidth
      },
      theme.styles.backgroundPrimary
    ];
    const textStyle = {
      color: progress < 0.5 ? theme.colors.primary : '#fff'
    };
    if (completed === total) {
      progressBarCompleteStyle.push({ borderRadius: 20 });
    }

    return (
      <Tooltip
        isVisible={this.state.showProgressBar && this.props.isTutorialMode}
        topAdjustment={Platform.OS === 'android' ? -StatusBar.currentHeight! : 0}
        placement="bottom"
        content={
          <TooltipContent
            text={
              this.props.language === 'id'
                ? 'Progress bar akan berubah ketika mengisi jawaban'
                : 'The progress bar will change when filling in the answers'
            }
          />
        }
        onClose={this.handleHideProgressBar}
      >
        <View style={styles.progressContainer}>
          <View
            style={styles.progressBar}
            ref={node => {
              if (node) {
                node.style.setProperty('overflow', 'hidden', 'important');
              }
            }}
          >
            <Animated.View style={progressBarCompleteStyle} />
            <Text allowFontScaling={false} testID={'percentage-text'} style={[styles.progressText, textStyle]}>
              {round(progress * 100, 1)}%
            </Text>
          </View>
        </View>
      </Tooltip>
    );
  }
}

type QuestionnaireProgressProps = {
  completed: number;
  total: number;
  language: string;
  isTutorialMode?: boolean;
  isShowQuestionTutorialModal?: boolean;
};

export default QuestionnaireProgress;

const styles = StyleSheet.create({
  progressContainer: {
    alignItems: 'center',
    marginHorizontal: 10
  },
  progressBar: {
    width: SCREEN_WIDTH > 500 ? 600 : 250,
    height: 15,
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    marginBottom: 5,
    borderRadius: 200,
    overflow: 'hidden'
  },
  progressBarComplete: {
    height: 15,
    maxWidth: SCREEN_WIDTH > 500 ? 600 : 250,
    backgroundColor: '#3cd070',
    borderRadius: 200
  },
  progressText: {
    position: 'absolute',
    width: SCREEN_WIDTH > 500 ? 600 : 250,
    fontSize: 12,
    lineHeight: 15,
    textAlign: 'center'
  }
});
