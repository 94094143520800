import React from 'react';
import { StyleSheet, View } from 'react-native';
import ConfirmModal from '../../global/ConfirmModal';
import SiteCalendar from './Calendar/SiteCalendar';
// component
import Outlet from './Outlet/Outlet';
import Reports from './Reports/Reports';
import SiteNavigatorHeader from './SiteNavigatorHeader';
import SiteScheduleFooterModal from './SiteScheduleFooterModal';
// types
import { SiteNewContainerProps } from './type';

const SiteNewContainer = (props: SiteNewContainerProps) => {
  const {
    isGPSMocked,
    isOffline,
    locationPermissionGranted,
    selectedTab,
    featureAccessAdhoc,
    featureAccessGeo,
    roleAccessAdhoc,
    hasCheckedIn,
    isAllowCalibration,
    isAdhoc,
    isBusy,
    isFocused,
    isLeader,
    isScheduleSelected,
    language,
    profileColor,
    modalConfirmVisible,
    modalConfig,
    reportScheduled,
    reportCompleted,
    selectedDateCalendar,
    siteReport,
    selectedSchedule,
    showFooter,
    status,
    scheduleOptions,
    selectedSite,
    siteSchedule,
    siteReports,
    getSingleSiteSchedule,
    handleMakeupReport,
    handleSelectSchedule,
    handleDeleteDraftReport,
    handleSelectReportingType,
    onCalibrateLocation,
    onConfirmCheckIn,
    onCheckIn,
    onReviewPendingUpload,
    setActiveTab,
    setShowFooter,
    setModalConfirmVisible,
    setSelectedDateCalendar,
    isLoadingSchedule
  } = props;
  const renderBasedOnSelectedTab = () => {
    switch (selectedTab) {
      case 'calendar':
        return (
          <SiteCalendar
            todaySchedules={scheduleOptions}
            selectedDate={selectedDateCalendar}
            siteReports={siteReports}
            handleSelectSchedule={handleSelectSchedule}
            setSelectedDate={setSelectedDateCalendar}
          />
        );
      case 'outlet':
        return (
          <Outlet
            locationPermissionGranted={locationPermissionGranted}
            isAllowCalibration={isAllowCalibration}
            isOffline={isOffline}
            isGPSMocked={isGPSMocked}
            scheduleOptions={scheduleOptions}
            selectedSite={selectedSite}
            siteSchedule={siteSchedule}
            featureAccessGeo={featureAccessGeo}
            reportCompleted={reportCompleted}
            reportScheduled={reportScheduled}
            selectedSchedule={selectedSchedule!}
            getSingleSiteSchedule={getSingleSiteSchedule}
            handleSelectSchedule={handleSelectSchedule}
            onCalibrateLocation={onCalibrateLocation}
            setShowFooter={setShowFooter}
            isLoadingSchedule={isLoadingSchedule}
          />
        );
      case 'reports':
        return <Reports selectedSite={selectedSite} />;
      default:
        return (
          <Outlet
            locationPermissionGranted={locationPermissionGranted}
            isOffline={isOffline}
            isAllowCalibration={isAllowCalibration}
            isGPSMocked={isGPSMocked}
            scheduleOptions={scheduleOptions}
            selectedSite={selectedSite}
            siteSchedule={siteSchedule}
            featureAccessGeo={featureAccessGeo}
            reportCompleted={reportCompleted}
            reportScheduled={reportScheduled}
            selectedSchedule={selectedSchedule!}
            getSingleSiteSchedule={getSingleSiteSchedule}
            handleSelectSchedule={handleSelectSchedule}
            onCalibrateLocation={onCalibrateLocation}
            setShowFooter={setShowFooter}
            isLoadingSchedule={isLoadingSchedule}
          />
        );
    }
  };

  return (
    <View style={styles.root}>
      <>
        <SiteNavigatorHeader />
        {renderBasedOnSelectedTab()}
      </>

      <SiteScheduleFooterModal
        featureAccessAdhoc={featureAccessAdhoc}
        roleAccessAdhoc={roleAccessAdhoc}
        hasCheckedIn={hasCheckedIn}
        isMultisite={selectedSite?.isMultiSite}
        isOffline={isOffline}
        isAdhoc={isAdhoc}
        isBusy={isBusy}
        isFocused={isFocused}
        isLeader={isLeader}
        isScheduleSelected={isScheduleSelected}
        isGPSMocked={isGPSMocked}
        locationPermissionGranted={locationPermissionGranted}
        profileColor={profileColor}
        siteReport={siteReport}
        selectedSchedule={selectedSchedule}
        showFooter={showFooter}
        status={status}
        selectedSite={selectedSite}
        selectedDateCalendar={selectedDateCalendar}
        handleMakeupReport={handleMakeupReport}
        handleDeleteDraftReport={handleDeleteDraftReport}
        handleSelectReportingType={handleSelectReportingType}
        onCalibrateLocation={onCalibrateLocation}
        onConfirmCheckIn={onConfirmCheckIn}
        onCheckIn={onCheckIn}
        onReviewPendingUpload={onReviewPendingUpload}
        setActiveTab={setActiveTab}
        setShowFooter={setShowFooter}
      />

      <ConfirmModal
        isVisible={modalConfirmVisible}
        language={language}
        title={modalConfig.title}
        subtitle={modalConfig.subtitle}
        options={modalConfig.options}
        onCloseModal={() => setModalConfirmVisible(!modalConfirmVisible)}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  root: { backgroundColor: '#EFEEED', flex: 1 }
});

export default SiteNewContainer;
