import * as Common from 'nimbly-common';
import moment, { Moment } from 'moment';

const currentPeriodMonth = () => {
  return moment.parseZone().startOf('month');
};

const prevPeriodMonth = () => {
  return moment
    .parseZone()
    .subtract(1, 'month')
    .startOf('month');
};

function currentPeriodStartDate(orgSchedule: any) {
  const now = moment.parseZone();
  let start;
  if (orgSchedule && orgSchedule.periodStart) {
    switch (orgSchedule.periodUnit) {
      case 'week':
        start =
          now.isoWeekday() >= orgSchedule.periodStart
            ? now.startOf('isoWeek')
            : now.subtract(1, 'week').startOf('isoWeek');
        return start.isoWeekday(orgSchedule.periodStart);
      case 'month':
        start =
          now.date() >= orgSchedule.periodStart ? now.startOf('month') : now.subtract(1, 'month').startOf('month');
        return start.date(orgSchedule.periodStart);
      case 'year':
        start = now.date() >= orgSchedule.periodStart ? now.startOf('year') : now.subtract(1, 'year').startOf('year');
        return start.date(orgSchedule.periodStart);
      default:
        return null;
    }
  }
  return null;
}
function currentPeriodEndDate(orgSchedule: any) {
  const start: any = currentPeriodStartDate(orgSchedule);
  if (orgSchedule) {
    switch (orgSchedule.periodUnit) {
      case 'week':
        return start.add(7, 'days').subtract(1, 'ms');
      case 'month':
        return start.add(start.daysInMonth(), 'days').subtract(1, 'ms');
      case 'year':
        return start.add(1, 'year').subtract(1, 'ms');
      default:
        return null;
    }
  }
  return null;
}

export const getDatesByPeriod = (
  periodType: Common.enums.PeriodTypes | 'ytd' | 'today' | null,
  orgSchedule: Common.OrganizationSchedule,
  date: any = null,
  previous: boolean = false
): { startDate: Moment | null; endDate: Moment | null } => {
  switch (periodType) {
    case 'today':
      return {
        startDate: moment(),
        endDate: moment()
      };
    case Common.enums.PeriodTypes.PREVIOUS:
      const periodUnit = orgSchedule.periodUnit as Common.PeriodUnit;
      return {
        startDate: currentPeriodStartDate(orgSchedule)?.subtract(1, periodUnit) || null,
        endDate: currentPeriodEndDate(orgSchedule)?.subtract(1, periodUnit) || null
      };
    case 'current':
      return {
        startDate: currentPeriodStartDate(orgSchedule),
        endDate: currentPeriodEndDate(orgSchedule)
      };
    case Common.enums.PeriodTypes.LAST_7:
      return {
        startDate: moment().subtract(6, 'days'),
        endDate: moment()
      };
    case Common.enums.PeriodTypes.LAST_28:
      return {
        startDate: moment().subtract(27, 'days'),
        endDate: moment()
      };
    case Common.enums.PeriodTypes.LAST_30:
      return {
        startDate: moment().subtract(29, 'days'),
        endDate: moment()
      };
    case Common.enums.PeriodTypes.LAST_90:
      return {
        startDate: moment().subtract(89, 'days'),
        endDate: moment()
      };
    case Common.enums.PeriodTypes.LAST_6_M:
      return {
        startDate: moment().subtract(30 * 6 - 1, 'days'),
        endDate: moment()
      };
    case Common.enums.PeriodTypes.LAST_12_M:
      return {
        startDate: moment().subtract(30 * 12 - 1, 'days'),
        endDate: moment()
      };
    case Common.enums.PeriodTypes.LAST_YEAR:
      return {
        startDate: moment()
          .subtract(1, 'year')
          .startOf('year'),
        endDate: moment()
          .subtract(1, 'year')
          .endOf('year')
      };
    case Common.enums.PeriodTypes.MTD:
      if (previous) {
        return {
          startDate: prevPeriodMonth(),
          endDate: moment.parseZone().subtract(1, 'month')
        };
      }
      return {
        startDate: currentPeriodMonth(),
        endDate: moment.parseZone()
      };
    case Common.enums.PeriodTypes.CUSTOM:
      if (previous) {
        const customDate = moment(date.customStart);
        const diff = customDate.diff(date.customEnd, 'd');
        const prevCustomStartDate = moment(date.customStart).subtract(Math.abs(diff), 'd');
        const prevCustomEndDate = moment(date.customStart).subtract(1, 'ms');
        return {
          startDate: prevCustomStartDate,
          endDate: prevCustomEndDate
        };
      }
      return {
        startDate: date.customStart,
        endDate: date.customEnd
      };

    default:
      return {
        startDate: moment.parseZone().subtract(1, 'week'),
        endDate: moment.parseZone()
      };
  }
};
