import { action, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';

export const CHANGE_COLOR = '@profileTheme/CHANGE_COLOR';

export type ProfileThemeState = {
  // new nimbly primary color #0ABA26
  color: '#3cd070' | '#a6192e';
  colorDarker: '#00AB4E' | '#820c1d';
};

const setProfileColor = (color: '#3cd070' | '#a6192e') => action(CHANGE_COLOR, { color });

export const profileThemeActions = { setProfileColor };

export type ProfileThemeAction = ActionType<typeof profileThemeActions>;

const initialState: ProfileThemeState = {
  color: '#3cd070',
  colorDarker: '#00AB4E'
};

export const profilethemeReducer: Reducer<ProfileThemeState, ProfileThemeAction> = (
  state = initialState,
  incomingAction
) => {
  switch (incomingAction.type) {
    case CHANGE_COLOR: {
      if (incomingAction.payload.color === '#a6192e') {
        return {
          color: incomingAction.payload.color,
          colorDarker: '#820c1d'
        };
      } else if (incomingAction.payload.color === '#3cd070') {
        return {
          color: incomingAction.payload.color,
          colorDarker: '#00AB4E'
        };
      }
    }
    default:
      return state;
  }
};
