import React from 'react';
import Tooltip from 'react-native-walkthrough-tooltip';
import {
  StyleSheet,
  View,
  TouchableWithoutFeedbackProps,
  TouchableOpacity,
  Platform,
  TextInput,
  TouchableNativeFeedback,
  StatusBar
} from 'react-native';

import Text from '../global/Text';
import { QuestionnaireQuestionProps } from './QuestionnaireQuestion';
import { questionTextInvalid } from '../../utils/report';
import theme from '../../constants/theme';

import QuestionnaireQuestionTutorialModal from './QuestionnaireQuestionTutorialModal';
import TooltipContent from '../global/tutorial/TooltipContent';
import { SCREEN_WIDTH } from '../../utils/screensize';
import { useTranslation } from 'react-i18next';
export type QuestionnaireQuestionOpenPropsExtra = {
  styles: any;
};

export type QuestionnaireQuestionOpenProps = Pick<
  QuestionnaireQuestionProps,
  | 'question'
  | 'index'
  | 'currentConditionIndex'
  | 'language'
  | 'commentInputRef'
  | 'currentQuestionIndex'
  | 'isTutorialMode'
  | 'isShowTooltipNA'
  | 'isShowQuestionTutorialModal'
  | 'tutorialQuestionType'
  | 'handleInputComment'
  | 'handleSelectChoice'
  | 'handleTextInputFocused'
  | 'handleTextInputBlurred'
  | 'handleShowTutorialQuestionModal'
  | 'handleChangeTutorialQuestionType'
  | 'handleShowTooltipNA'
  | 'handleDismissTutorialQuestionModal'
  | 'handleDismissTutorialMode'
>;

const QuestionnaireQuestionOpen = (props: QuestionnaireQuestionOpenProps & QuestionnaireQuestionOpenPropsExtra) => {
  const { t } = useTranslation(['questionnaire', 'common']);
  const [comment, setComment] = React.useState<string>(props.question.comment);
  React.useEffect(() => {
    if (props.isTutorialMode && props.index === props.currentQuestionIndex) {
      props.handleChangeTutorialQuestionType(props.question.type);
      props.handleShowTutorialQuestionModal(true);
    }
  }, [props.index, props.currentQuestionIndex]);

  React.useEffect(() => {
    setComment(props.question.comment);
  }, [props.question.comment]);
  // temporary fix to handle input flicker and moving cursor in RN 0.63.3
  // TODO: revert this once the bug is handled, or there is a permanent fix
  const handleInputComment = (text: string) => {
    setComment(text);
    props.handleInputComment(props.index, text, props.currentConditionIndex!);
  };

  return (
    <View>
      <QuestionnaireQuestionTutorialModal
        handleDismissTutorialQuestionModal={props.handleDismissTutorialQuestionModal}
        handleDismissTutorialMode={props.handleDismissTutorialMode}
        isShowQuestionTutorialModal={props.isShowQuestionTutorialModal}
        tutorialQuestionType={props.tutorialQuestionType}
        language={props.language}
      />
      {!props.question.answerRequired ? (
        <Tooltip
          isVisible={props.isShowTooltipNA}
          topAdjustment={Platform.OS === 'android' ? -StatusBar.currentHeight! : 0}
          placement="bottom"
          content={<TooltipContent text={t('questionnaire:tutorial.notRequired')} />}
          onClose={() => props.handleShowTooltipNA(false)}
        >
          <View style={[props.styles.choicesList, { width: SCREEN_WIDTH }]}>
            <Touchable
              onPress={() => props.handleSelectChoice(props.index, 'not-applicable', props.currentConditionIndex!)}
              testID="open-na"
            >
              <View
                style={
                  props.question.answer === 'not-applicable'
                    ? [props.styles.choice, props.styles.choiceSelected]
                    : props.styles.choice
                }
              >
                <View style={props.styles.choiceFlag}>
                  <View style={props.styles.noFlag} />
                </View>
                <Text
                  style={
                    props.question.answer === 'not-applicable'
                      ? [props.styles.choiceText, props.styles.choiceSelectedText]
                      : props.styles.choiceText
                  }
                >
                  N/A
                </Text>
              </View>
            </Touchable>
          </View>
        </Tooltip>
      ) : null}

      <View
        style={[
          props.styles.openAnswerInputContainer,
          props.question.answer === 'not-applicable' && props.styles.commentDisabled,
          {
            borderColor: questionTextInvalid(props.question, t) ? theme.colors.secondary : 'rgba(0, 0, 0, 0.05)'
          }
        ]}
      >
        <TextInput
          style={props.styles.openAnswerInput}
          maxLength={2500}
          multiline={true}
          numberOfLines={8}
          editable={props.question.answer !== 'not-applicable'}
          onChangeText={handleInputComment}
          value={comment}
          placeholder={
            props.question.answer === 'not-applicable'
              ? `${t('questionnaire:question.binary.notApplicable')}`
              : t('questionnaire:question.open.typeAnswer')
          }
          ref={props.commentInputRef}
          onFocus={() =>
            props.handleTextInputFocused(
              typeof props.currentConditionIndex! === 'number' ? props.currentConditionIndex! : props.index
            )
          }
          onBlur={() =>
            props.handleTextInputBlurred(
              typeof props.currentConditionIndex! === 'number' ? props.currentConditionIndex! : props.index
            )
          }
          testID="open-input"
        />
      </View>
      {!questionTextInvalid(props.question, t) ? (
        <View style={props.styles.validationContainer}>
          <Text style={props.styles.textValidation}>&zwnj;</Text>
        </View>
      ) : (
        <View style={props.styles.validationContainer}>
          <Text style={props.styles.textValidation}> {questionTextInvalid(props.question, t)} </Text>
        </View>
      )}
    </View>
  );
};

export default QuestionnaireQuestionOpen;

const styles = StyleSheet.create({});

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
  Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;
