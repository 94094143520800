import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FirebaseReducer } from 'react-redux-firebase';

import CalibrationContainer from './CalibrationContainer';
import { Organization, User } from '../../utils/classes';
import { RootState } from '../../store/reducers';
import { calibrationActions, MapLocation } from '../../store/reducers/calibration';
import { InjectedSite } from '../../typing/types';
import { siteActions } from '../../store/reducers/site/site';

export type CalibrationProps = {
  auth: FirebaseReducer.AuthState;
  profile: FirebaseReducer.Profile<User>;
  organization: Organization;
  hasNotch: boolean;
  selectedSite: InjectedSite;
  selectedSiteKey: string;
  gpsLocation: MapLocation;
  pinLocation: MapLocation;
  onSetGPSLocation: (location: MapLocation) => void;
  onSetPinLocation: (location: MapLocation) => void;
  onModifySite: (siteKey: string, siteValue: InjectedSite) => void;
  onResetMap: () => void;
};

const Calibration = () => {
  const auth = useSelector<RootState, FirebaseReducer.AuthState>(state => state.firebase.auth);
  const profile = useSelector<RootState, FirebaseReducer.Profile<User>>(state => state.firebase.profile);
  const organization = useSelector<RootState, Organization | null | undefined>(
    state => state.organization.myOrganization
  );
  const hasNotch = useSelector<RootState, boolean>(state => state.account.hasNotch);
  const selectedSite = useSelector<RootState, InjectedSite>(state => state.site.selectedSite as InjectedSite);
  const selectedSiteKey = useSelector<RootState, string>(state => state.site.selectedSiteKey!);
  const gpsLocation = useSelector<RootState, MapLocation>(state => state.calibration.gpsLocation);
  const pinLocation = useSelector<RootState, MapLocation>(state => state.calibration.pinLocation);
  const dispatch = useDispatch();
  const handleSetGPSLocation = (location: MapLocation) => {
    dispatch(calibrationActions.setGPSLocation(location));
  };
  const handleSetPinLocation = (location: MapLocation) => {
    dispatch(calibrationActions.setPinLocation(location));
  };
  const handleModifySite = (siteKey: string, siteValue: InjectedSite) => {
    dispatch(siteActions.modifySite(siteKey, siteValue));
  };
  const handleResetMap = () => {
    dispatch(calibrationActions.reset());
  };

  return (
    <CalibrationContainer
      auth={auth}
      profile={profile}
      organization={organization!}
      hasNotch={hasNotch}
      selectedSite={selectedSite}
      selectedSiteKey={selectedSiteKey}
      gpsLocation={gpsLocation}
      pinLocation={pinLocation}
      onSetGPSLocation={handleSetGPSLocation}
      onSetPinLocation={handleSetPinLocation}
      onModifySite={handleModifySite}
      onResetMap={handleResetMap}
    />
  );
};

export default Calibration;
