import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { LanguageType } from '../../constants/Languages';
import { SCREEN_WIDTH, widthPercentageToDP as wp } from '../../utils/screensize';
import Text from '../global/Text';
import VideoPicker from './VideoPicker';

// eslint-disable-next-line @typescript-eslint/no-var-requires
// eslint-disable-next-line @typescript-eslint/no-var-requires
const PlayIcon = require('../../assets/icons/play-video.png');
const VideoIcon = require('../../assets/icons/take-video.png');

type AddVideoProps = {
  source: string | undefined;
  language: LanguageType;
  isValid?: boolean;
  onRecordVideo: () => void;
  onPlaybackVideo: (source: string) => void;
} & WithTranslation;

// Simple placeholder to add and preview photo
// Also functions as a button to activate the camera
export class AddVideo extends React.Component<AddVideoProps> {
  state = {
    width: 0,
    height: 0
  };

  handleImageLoaded = () => {
    if (this.props.source && this.props.source !== 'failed' && typeof this.props.source === 'string') {
      Image.getSize(
        this.props.source,
        (imageWidth, imageHeight) => {
          this.setState({
            width: imageWidth,
            height: imageHeight
          });
        },
        err => {
          // eslint-disable-next-line no-console
          console.log(err);
        }
      );
    }
  };

  handlePressNew = () => this.props.onRecordVideo!();

  handlePressEdit = (source: string) => {
    if (source) {
      this.props.onPlaybackVideo(source);
    }
  };

  render() {
    const { source, isValid, t } = this.props;
    return !source ? (
      <VideoPicker onComplete={this.props.onRecordVideo} testID="new-video">
        <View style={[styles.portraitBox, !isValid ? styles.invalid : null]}>
          <Image source={VideoIcon.default} style={styles.icon} />
          <Text allowFontScaling={false} style={styles.subtitle}>
            {t('questionnaire:addVideo')}
          </Text>
        </View>
      </VideoPicker>
    ) : (
      <Touchable onPress={() => this.handlePressEdit(source)} testID={'edit-video'}>
        <View style={this.state.width <= this.state.height ? styles.portraitBoxVideo : styles.landscapeBox}>
          {source && source !== 'failed' ? (
            <React.Fragment>
              <Text allowFontScaling={false} style={styles.subtitle}>
                {source.split('/').pop()}
              </Text>
              <View style={styles.videoOverlay} />
            </React.Fragment>
          ) : null}
        </View>
      </Touchable>
    );
  }
}

const Touchable: any = TouchableOpacity;

const styles = StyleSheet.create({
  portraitBox: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 80,
    height: (62 / 3) * 4 + 4,
    marginVertical: 10,
    marginHorizontal: 2,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: '#efeeed',
    backgroundColor: '#efeeed'
  },
  portraitBoxVideo: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 200,
    height: (62 / 3) * 4 + 4,
    marginVertical: 10,
    marginHorizontal: 2,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: '#efeeed',
    backgroundColor: '#efeeed'
  },
  landscapeBox: {
    justifyContent: 'center',
    alignItems: 'center',
    width: (62 / 3) * 4 + 4,
    height: 80,
    marginVertical: 10,
    marginHorizontal: 2,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: '#efeeed',
    backgroundColor: '#efeeed'
  },
  icon: {
    marginBottom: SCREEN_WIDTH > 500 ? wp(0.1) : wp(2),
    height: SCREEN_WIDTH > 500 ? wp(1.8) : wp(8),
    width: SCREEN_WIDTH > 500 ? wp(1.8) : wp(8)
  },
  subtitle: {
    textAlign: 'center',
    fontSize: SCREEN_WIDTH > 500 ? wp(0.8) : wp(3)
  },
  videoOverlay: {
    position: 'absolute',
    borderRadius: 2,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    // backgroundColor: '#000',
    opacity: 0.5
  },
  playButtonContainer: {
    position: 'absolute',
    borderRadius: 2,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: wp(6),
    zIndex: 10
  },
  playButtonIcon: {
    width: '100%',
    height: '100%',
    overflow: 'visible'
  },
  invalid: {
    borderColor: '#ff6f00'
  },
  photo: {
    flex: 1,
    width: '100%',
    borderRadius: 2
  },
  innerBox: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  errorText: {
    marginTop: 5,
    fontSize: 12,
    lineHeight: 12.5,
    textAlign: 'center',
    color: '#FA6161'
  },
  progressText: {
    marginTop: 5,
    fontSize: 12,
    lineHeight: 12.5,
    textAlign: 'center',
    color: '#3cd070'
  }
});

export default withTranslation(['questionnaire'])(AddVideo);
