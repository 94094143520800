import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

import SetPassword from '../components/setpassword/SetPassword';

/**
 * Page for new users to set their password
 */
const SetPasswordPage = (props: any) => {
  return (
    <View style={styles.root}>
      <SetPassword navigation={props.navigation} />
    </View>
  );
};

export default React.memo(SetPasswordPage);

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#efeeed'
  }
});
