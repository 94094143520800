import React, { useEffect, useState } from 'react';
import { KeyboardAvoidingView, Platform, ScrollView, StyleSheet, View } from 'react-native';
import { connect, useSelector } from 'react-redux';
import { AUTH_MODE } from '../../constants/Constants';
import { LanguageType } from '../../constants/Languages';
import theme from '../../constants/theme';
import { RootState } from '../../store/reducers';
import ConfirmModal, { ModalConfig } from '../global/ConfirmModal';
import Auth from './Auth';
import LandingHeader from './LandingHeader';
import LandingLanguageModal from './LandingLanguageModal';
import LandingLoading from './LandingLoading';
import LandingVersion from './LandingVersion';
import ModalNotifRequestAccess from './utils/ModalNotifRequestAccess';

export type LandingContainerProps = {
  /** True if component is still loading (waiting for auth and profile) */
  _isLoading: boolean;
  /** True if loading timed out */
  _isTimedOut: boolean;
  /** True to show concurrent login modal */
  _showModal: boolean;
  /** Config for the concurrent login modal */
  _modalConfig: ModalConfig;
  /** Language to be used throughout the app */
  language: LanguageType;
  /** The redux Firebase auth object */
  auth: any;
  /** The redux Firebase user profile object */
  profile: any;
  /** Database version for check and forced update (e.g. '1.20.0') */
  latestVersion: string;
  /** uuid of the device */
  uuid: string;
  /** True if the device has notches (e.g. iPhone X) */
  hasNotch: boolean;
  /** Event - language switcher is clicked */
  onLanguageChange: (language: LanguageType) => void;
  /** Event - modal is dismissed */
  onDismissModal: () => void;
  /** Get - color of the profile organization */
  profileColor: string;
};

const LandingContainer = (props: LandingContainerProps) => {
  const scrollRef: React.RefObject<ScrollView> = React.createRef();
  const [showModalRequestAccess, setShowModalRequestAccess] = useState<boolean>(false);
  const authMode = useSelector<RootState, AUTH_MODE>(state => state.landing.authMode);

  const handleScrollDown = () => {
    if (scrollRef && scrollRef?.current) {
      scrollRef?.current!.scrollToEnd({ animated: true });
    }
  };

  useEffect(() => {
    if (showModalRequestAccess) {
      setTimeout(() => {
        setShowModalRequestAccess(false);
      }, 2500);
    }
  }, [showModalRequestAccess]);

  return (
    <View
      style={[
        styles.landingRoot,
        theme.styles.backgroundPrimary,
        props.hasNotch ? styles.landingNotch : null,
        { backgroundColor: props.profileColor }
      ]}
    >
      <LandingLanguageModal onLanguageChange={props.onLanguageChange} />
      <ConfirmModal
        isVisible={props._showModal}
        language={props.language}
        title={props._modalConfig.title}
        subtitle={props._modalConfig.subtitle}
        options={props._modalConfig.options}
        onCloseModal={props.onDismissModal}
      />
      <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : undefined} enabled={true}>
        <ScrollView
          ref={scrollRef}
          style={styles.landingScroll}
          contentContainerStyle={styles.landingContainer}
          keyboardShouldPersistTaps="always"
        >
          <ModalNotifRequestAccess
            isVisible={showModalRequestAccess}
            onCloseModalRequest={() => setShowModalRequestAccess(false)}
          />
          <LandingHeader language={props.language} onLanguageChange={props.onLanguageChange} />
          {Object.values(AUTH_MODE).includes(authMode) ||
          props._isTimedOut ||
          (!props._isLoading && props.auth.isLoaded && props.auth.isEmpty) ? (
            <ScrollView
              style={styles.landingScroll}
              contentContainerStyle={styles.landingContainer}
              keyboardShouldPersistTaps="always"
            >
              <Auth setShowModalRequestAccess={setShowModalRequestAccess} onScrollDown={handleScrollDown} />
            </ScrollView>
          ) : (
            <LandingLoading language={props.language} />
          )}
        </ScrollView>
      </KeyboardAvoidingView>
      <LandingVersion />
    </View>
  );
};

const mapStateToProps = (state: RootState) => ({
  profileColor: state.profiletheme.color
});

const styles = StyleSheet.create({
  landingRoot: {
    flex: 1,
    position: 'fixed',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden'
    // marginHorizontal: 'auto',
    // maxWidth: SCREEN_WIDTH
  },

  landingNotch: {
    paddingTop: 24,
    paddingBottom: 12
  },
  landingScroll: {
    flex: 1
  },
  landingContainer: {
    alignItems: 'center',
    paddingBottom: 30
  },
  textLogin: {
    fontSize: 15,
    color: '#fff'
  }
});

export default connect(mapStateToProps)(LandingContainer);
