import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { StyleSheet, ScrollView, TouchableOpacity, View } from 'react-native';
import { enums } from 'nimbly-common';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../../utils/screensize';

// redux
import { RootState } from '../../../store/reducers';
import { setActiveTab } from '../../../store/reducers/site/site';

// utils
import { SiteTabs } from '../../../utils/classes';
import Text from '../../global/Text';
import fontMaker from '../../../utils/font';

// type
import { SelectedSite } from './type';

const SiteNavigatorHeader = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();

  // redux state
  const activeTab = useSelector<RootState, SiteTabs>(state => state.site.activeTab);
  const featureAccessPromotion = useSelector<RootState, boolean>(
    state => state.featureAccess.features[enums.Features.CUSTOMIZED_LOCALIZED_MARKETING]
  );
  const selectedSite = useSelector<RootState, SelectedSite>(state => state.site.selectedSite!);

  // set active tab to redux state
  const setSiteActiveTab = (tab: SiteTabs) => {
    dispatch(setActiveTab(tab));
  };

  const renderTabs = useMemo(() => {
    // site tab options
    const tabs: { value: Partial<SiteTabs>; label: string }[] = [
      { value: 'outlet', label: t('outlet') },
      { value: 'reports', label: t('reports') },
      { value: 'calendar', label: t('calendar') }
      // { value: 'sales', label: t('sales') },
      // { value: 'messages', label: t('messagesBoard') },
      // { value: 'promotions', label: t('promotions') }
    ];

    if (selectedSite?.isMultiSite) {
      // remove calendar if multisite
      tabs.splice(2, 1);
    }

    if (!featureAccessPromotion) {
      // remove promotion tab if doesn't have feature access
      tabs.splice(tabs.length - 1, 1);
    }

    const getActive = (tabVal: SiteTabs) => {
      if (activeTab === tabVal) {
        return true;
      }
      return false;
    };

    return tabs.map(option => (
      <TouchableOpacity
        style={[styles.button, getActive(option.value) ? styles.activeButton : {}]}
        onPress={() => setSiteActiveTab(option.value as SiteTabs)}
        key={`tab-${option.value}`}
      >
        <Text style={[styles.label, getActive(option.value) ? styles.activeLabel : {}]}>{option.label}</Text>
      </TouchableOpacity>
    ));
  }, [activeTab, setActiveTab, featureAccessPromotion]);

  useEffect(() => {
    return () => {
      setSiteActiveTab('outlet');
    };
  }, []);

  return (
    <>
      <ScrollView
        horizontal={true}
        style={styles.container}
        contentContainerStyle={styles.scrollContent}
        showsHorizontalScrollIndicator={false}
      >
        {renderTabs}
      </ScrollView>
      {/* for shadowing purpose */}
    </>
  );
};

const styles = StyleSheet.create({
  shadowContainer: {
    height: 3,
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    width: '100%'
  },
  scrollContent: {
    paddingLeft: 5,
    paddingRight: 20,
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  },
  container: {
    paddingHorizontal: 10,
    height: 55,
    maxHeight: 55,
    backgroundColor: '#ffffff',
    marginHorizontal: 'auto',
    width: SCREEN_WIDTH
  },
  activeButton: {
    borderBottomColor: '#574FCF',
    borderBottomWidth: 2
  },
  button: {
    paddingHorizontal: 12,
    paddingVertical: 10,
    backgroundColor: '#ffffff',
    marginLeft: 15,
    borderBottomColor: '#fff',
    borderBottomWidth: 2
  },
  activeLabel: {
    color: '#574FCF',
    ...fontMaker({ weight: 'SemiBold' })
  },
  label: {
    fontSize: 14,
    lineHeight: 19,
    color: '#25282B'
  }
});

export default SiteNavigatorHeader;
