import React from 'react';
import { TouchableOpacity, StyleSheet, View, Dimensions, Image, Linking } from 'react-native';

import Text from '../global/Text';

type Props = {
  videoId: string;
  title: string;
};

export const TutorialItem = (props: Props) => {
  const { videoId, title } = props;

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() =>
        Linking.openURL(`https://www.youtube.com/embed/${videoId}?rel=0&autoplay=1&showinfo=1&controls=1&fullscreen=1`)
      }
      testID={`button-tutorial-${videoId}`}
    >
      <View style={styles.thumbnail}>
        <Image source={require('../../assets/icons/play-button.png').default} style={styles.playButton} />
      </View>
      {/* <Text style={styles.text}>{title}</Text> */}
    </TouchableOpacity>
  );
};
const styles = StyleSheet.create({
  playButton: {
    width: 40,
    height: 40
  },
  thumbnail: {
    width: 80,
    height: 60,

    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    color: '#535353',
    marginLeft: 10,
    maxWidth: Dimensions.get('window').width - 80 - 10 - 25
  },
  container: {
    borderBottomWidth: 1,
    borderBottomColor: '#EFEEED',

    paddingVertical: 20,
    paddingHorizontal: 10,

    flexDirection: 'row',
    alignItems: 'center'
  }
});

export default TutorialItem;
