export function padZeroes(n: any, width: number) {
  const z = '0';
  n += '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

export function hasMatchingString(query: string, content: string) {
  let regex: any;
  let isRegex = true;

  if (query && content) {
    try {
      // special characters can cause regex to throw error
      regex = new RegExp(query, 'ig');
    } catch (e) {
      isRegex = false;
    }
    return isRegex ? regex.test(content) : content.toLowerCase().indexOf(query.toLowerCase()) > -1;
  } else {
    return false;
  }
}
