import React from 'react';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack/src/types';

import { StyleSheet, View } from 'react-native';
import SubmitReport from '../components/submitreport/SubmitReport';
import { HomeStackParamList, ScheduleStackParamList } from '../routes';

export type SubmitReportPageProps = {
  navigation: StackNavigationProp<HomeStackParamList | ScheduleStackParamList, 'SubmitReport'>;
  route: RouteProp<HomeStackParamList | ScheduleStackParamList, 'SubmitReport'>;
};

const SubmitReportPage = (props: SubmitReportPageProps) => {
  return (
    <View style={styles.root}>
      <SubmitReport navigation={props.navigation} route={props.route} />
    </View>
  );
};

export default SubmitReportPage;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#fff'
  }
});
