import React, { PureComponent } from 'react';
import {
  Platform,
  LayoutAnimation,
  NativeModules,
  StyleSheet,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableWithoutFeedbackProps,
  View
} from 'react-native';

import Text from '../global/Text';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import fontMaker from '../../utils/font';
import { Question } from '../../utils/classes';
import getCategoryAnswersSummary from './utils/getCategoryAnswersSummary';
import { TFunction } from 'i18next';

if (Platform.OS === 'android') {
  // settings for animation to work in android
  const { UIManager } = NativeModules;
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
  }
}

class QuestionnaireDrawerCategory extends PureComponent<
  QuestionnaireDrawerCategoryProps,
  QuestionnaireDrawerCategoryState
> {
  state: QuestionnaireDrawerCategoryState = {
    expanded: false
  };

  componentDidMount() {
    this.handleExpandCategory();
  }

  handleExpandCategory = () => {
    this.setState({
      expanded: this.props.expanded
    });
  };

  handlePressCategory = () => {
    const config = {
      duration: 400,
      update: {
        type: 'spring',
        springDamping: 0.6
      }
    };
    LayoutAnimation.configureNext(config);
    this.setState(state => ({
      expanded: !state.expanded
    }));
  };

  _renderSummary = () => {
    const { questions, title, language, t } = this.props;
    const reportCategorySummary = getCategoryAnswersSummary(questions, title);

    if (reportCategorySummary.isFound) {
      const summaryAnswers = (reportCategorySummary.summary && reportCategorySummary.summary.answers) || {
        red: 0,
        yellow: 0,
        green: 0,
        weightedScore: 0
      };

      return (
        <View style={summaryStyles.container}>
          {summaryAnswers.weightedScore !== -1 && (
            <View style={summaryStyles.scoreSummaryContainer}>
              <Text>{`${this.props.t('questionnaire:drawer.score')}: ${summaryAnswers.weightedScore.toFixed(
                0
              )}%`}</Text>
            </View>
          )}
          <View style={[styles.flex, styles.flexRowCentered]}>
            <View style={[summaryStyles.dot, summaryStyles.red]} />
            <Text>{summaryAnswers.red}</Text>
          </View>
          <View style={[styles.flex, styles.flexRowCentered]}>
            <View style={[summaryStyles.dot, summaryStyles.yellow]} />
            <Text>{summaryAnswers.yellow}</Text>
          </View>
          <View style={[styles.flex, styles.flexRowCentered]}>
            <View style={[summaryStyles.dot, summaryStyles.green]} />
            <Text>{summaryAnswers.green}</Text>
          </View>
          {/* below line is to maintain dot spacing even when there's no question with score type  */}
          {summaryAnswers.weightedScore === -1 && <View style={summaryStyles.scoreSummaryContainer} />}
        </View>
      );
    }
  };

  render() {
    const { expanded } = this.state;
    const { title } = this.props;
    const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
      Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

    return (
      <View>
        <Touchable onPress={this.handlePressCategory} testID="category-drawer">
          <View style={styles.categoryContainer}>
            <View style={styles.flex}>
              <Text style={styles.categoryText}>{title}</Text>
              {this._renderSummary()}
            </View>
            <Icon
              testID={'expanded-icon'}
              name={expanded ? 'chevron-up' : 'chevron-down'}
              size={26}
              style={[styles.icon, styles.text]}
            />
          </View>
        </Touchable>
        {expanded ? this.props.children : null}
      </View>
    );
  }
}

type QuestionnaireDrawerCategoryState = {
  expanded: boolean;
};

type QuestionnaireDrawerCategoryProps = {
  key: string;
  title: string;
  children: JSX.Element[] | any;
  expanded: boolean;
  isVisible: boolean;
  isFocused: boolean;
  questions: Question[];
  language: string;
  t: TFunction;
};

const styles = StyleSheet.create({
  categoryContainer: {
    flex: 1,
    height: 70,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#efeeed',
    paddingHorizontal: 14,
    borderBottomColor: 'silver',
    borderBottomWidth: StyleSheet.hairlineWidth
  },
  text: { color: '#535353' },
  textHighlight: { color: '#009e43' },
  icon: { marginLeft: 20 },
  categoryText: {
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 16,
    color: '#535353'
  },
  flex: { flex: 1 },
  flexRowCentered: { flexDirection: 'row', alignItems: 'center' }
});

const summaryStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    marginTop: 10
  },
  scoreSummaryContainer: {
    flex: 2,
    flexDirection: 'row',
    alignItems: 'center'
  },
  dot: {
    height: 15,
    width: 15,
    borderRadius: 7.5,
    marginRight: 8
  },
  green: {
    backgroundColor: '#3cd070'
  },
  yellow: {
    backgroundColor: '#ffbf00'
  },
  red: {
    backgroundColor: '#ff2323'
  }
});

export default QuestionnaireDrawerCategory;
