import firebase from 'firebase';
import { createAsyncAction, createReducer, ActionType } from 'typesafe-actions';
import { put, call } from 'redux-saga/effects';

import { Department, DepartmentIndex } from 'nimbly-common';
import { DepartmentMap } from '../../../utils/classes';
import { apiProdURL } from '../../../constants/api';

export type DepartmentIndexWithMap = DepartmentIndex & {
  userMap: { [uid: string]: number };
  siteMap: { [siteKey: string]: boolean };
  questionnaireMap: { [questionnaireKey: string]: boolean };
};

export const FETCH_DEPARTMENTS_REQUEST = '@department/FETCH_DEPARTMENTS_REQUEST';
export const FETCH_DEPARTMENTS_SUCCESS = '@department/FETCH_DEPARTMENTS_SUCCESS';
export const FETCH_DEPARTMENTS_FAILURE = '@department/FETCH_DEPARTMENTS_FAILURE';

export const FETCH_DEPARTMENT_MAP_REQUEST = '@department/FETCH_DEPARTMENT_MAP_REQUEST';
export const FETCH_DEPARTMENT_MAP_SUCCESS = '@department/FETCH_DEPARTMENT_MAP_SUCCESS';
export const FETCH_DEPARTMENT_MAP_FAILURE = '@department/FETCH_DEPARTMENT_MAP_FAILURE';

export const FETCH_DEPARTMENT_INDEXES_REQUEST = '@department/FETCH_DEPARTMENT_INDEXES_REQUEST';
export const FETCH_DEPARTMENT_INDEXES_SUCCESS = '@department/FETCH_DEPARTMENT_INDEXES_SUCCESS';
export const FETCH_DEPARTMENT_INDEXES_FAILURE = '@department/FETCH_DEPARTMENT_INDEXES_FAILURE';

export const fetchDepartmentsAsync = createAsyncAction(
  FETCH_DEPARTMENTS_REQUEST,
  FETCH_DEPARTMENTS_SUCCESS,
  FETCH_DEPARTMENTS_FAILURE
)<undefined | number, Department[], Error>();

export const fetchDepartmentMapAsync = createAsyncAction(
  FETCH_DEPARTMENT_MAP_REQUEST,
  FETCH_DEPARTMENT_MAP_SUCCESS,
  FETCH_DEPARTMENT_MAP_FAILURE
)<undefined | number, DepartmentMap, Error>();

export const fetchAllDepartmentIndexAsync = createAsyncAction(
  FETCH_DEPARTMENT_INDEXES_REQUEST,
  FETCH_DEPARTMENT_INDEXES_SUCCESS,
  FETCH_DEPARTMENT_INDEXES_FAILURE
)<undefined | number, { [key: string]: DepartmentIndexWithMap }, Error>();

export const departmentActions = { fetchDepartmentsAsync, fetchDepartmentMapAsync, fetchAllDepartmentIndexAsync };

export function* fetchDepartmentsSaga(action: ReturnType<typeof fetchDepartmentsAsync.request>): Generator {
  const tryCount = action.payload || 1;
  try {
    const idToken = (yield firebase.auth().currentUser!.getIdToken()) as string;
    const request = () =>
      fetch(`${apiProdURL}/departments`, {
        method: 'GET',
        headers: {
          authorization: idToken
        }
      });
    const response = (yield call(request)) as Body;
    const body = (yield response.json()) as any;
    yield put(fetchDepartmentsAsync.success(body.data));
  } catch (err) {
    // Retry up to 3 times before throwing a failure
    if (tryCount >= 3) {
      yield put(fetchDepartmentsAsync.request(tryCount + 1));
    } else {
      yield put(fetchDepartmentsAsync.failure(err));
    }
  }
}

export function* fetchDepartmentMapSaga(action: ReturnType<typeof fetchDepartmentMapAsync.request>): Generator {
  const tryCount = action.payload || 1;
  try {
    const idToken = (yield firebase.auth().currentUser!.getIdToken()) as string;
    const request = () =>
      fetch(`${apiProdURL}/departments/department-map`, {
        method: 'GET',
        headers: {
          authorization: idToken
        }
      });
    const response = (yield call(request)) as Body;
    const body = (yield response.json()) as any;
    yield put(fetchDepartmentMapAsync.success(body.data));
  } catch (err) {
    // Retry up to 3 times before throwing a failure
    if (tryCount >= 3) {
      yield put(fetchDepartmentMapAsync.request(tryCount + 1));
    } else {
      yield put(fetchDepartmentMapAsync.failure(err));
    }
  }
}

export function* fetchAllDepartmentIndexSaga(
  action: ReturnType<typeof fetchAllDepartmentIndexAsync.request>
): Generator {
  const tryCount = action.payload || 1;
  try {
    const idToken = (yield firebase.auth().currentUser!.getIdToken()) as string;
    const request = () =>
      fetch(`${apiProdURL}/departments/index`, {
        method: 'GET',
        headers: {
          authorization: idToken
        }
      });
    const response = (yield call(request)) as Body;
    const body = (yield response.json()) as any;

    const output: { [key: string]: DepartmentIndexWithMap } = {};

    body.data.forEach((department: DepartmentIndex) => {
      const departmentKey: string = department.departmentID;
      if (!output.hasOwnProperty(departmentKey)) {
        const userMap: { [uid: string]: number } = {};
        const siteMap: { [siteKey: string]: boolean } = {};
        const questionnaireMap: { [questionnaireKey: string]: boolean } = {};
        department.users.forEach((user: { uid: string; level: number }) => {
          userMap[user.uid] = user.level;
        });
        department.sites.forEach((site: { id: string }) => {
          siteMap[site.id] = true;
        });
        department.questionnaires.forEach((questionnaire: { id: string }) => {
          questionnaireMap[questionnaire.id] = true;
        });
        const departmentWithMap: DepartmentIndexWithMap = Object.assign(
          { userMap, siteMap, questionnaireMap },
          department
        );
        output[departmentKey] = departmentWithMap;
      }
    });
    yield put(fetchAllDepartmentIndexAsync.success(output));
  } catch (err) {
    // Retry up to 3 times before throwing a failure
    if (tryCount >= 3) {
      yield put(fetchAllDepartmentIndexAsync.request(tryCount + 1));
    } else {
      yield put(fetchAllDepartmentIndexAsync.failure(err));
    }
  }
}

export type DepartmentAction = ActionType<typeof departmentActions>;
export type DepartmentState = {
  readonly isLoadingList: boolean;
  readonly lastUpdatedList: number | null;
  readonly errorMessageList: string | null;
  readonly departments: { [deptKey: string]: Department } | null | undefined;
  readonly isLoadingMap: boolean;
  readonly lastUpdatedMap: number | null;
  readonly errorMessageMap: string | null;
  readonly departmentMap: DepartmentMap | null | undefined;
  readonly isLoadingAllIndex: boolean;
  readonly lastUpdatedAllIndex: number | null;
  readonly errorMessageAllIndex: string | null;
  readonly allDepartmentIndex: { [key: string]: DepartmentIndexWithMap } | null | undefined;
};

const initialState: DepartmentState = {
  isLoadingList: false,
  lastUpdatedList: null,
  errorMessageList: null,
  departments: undefined,
  isLoadingMap: false,
  lastUpdatedMap: null,
  errorMessageMap: null,
  departmentMap: undefined,
  isLoadingAllIndex: false,
  lastUpdatedAllIndex: null,
  errorMessageAllIndex: null,
  allDepartmentIndex: undefined
};

export const deparmentReducer = createReducer<DepartmentState, DepartmentAction>(initialState)
  .handleAction(fetchDepartmentsAsync.request, state => ({
    ...state,
    isLoadingList: true,
    errorMessageList: null
  }))
  .handleAction(fetchDepartmentsAsync.failure, (state, action) => ({
    ...state,
    isLoadingList: false,
    errorMessageList: action.payload.message
  }))
  .handleAction(fetchDepartmentsAsync.success, (state, action) => ({
    ...state,
    isLoadingList: false,
    lastUpdatedList: Date.now(),
    departments: action.payload.reduce((map: { [deptKey: string]: Department }, obj: Department) => {
      map[obj.departmentID!] = obj;
      return map;
    }, {})
  }))
  .handleAction(fetchDepartmentMapAsync.request, state => ({
    ...state,
    isLoadingMap: true,
    errorMessageMap: null
  }))
  .handleAction(fetchDepartmentMapAsync.failure, (state, action) => ({
    ...state,
    isLoadingMap: false,
    errorMessageMap: action.payload.message
  }))
  .handleAction(fetchDepartmentMapAsync.success, (state, action) => ({
    ...state,
    isLoadingMap: false,
    lastUpdatedMap: Date.now(),
    departmentMap: action.payload
  }))
  .handleAction(fetchAllDepartmentIndexAsync.request, state => ({
    ...state,
    isLoadingAllIndex: true,
    errorMessageAllIndex: null
  }))
  .handleAction(fetchAllDepartmentIndexAsync.failure, (state, action) => ({
    ...state,
    isLoadingAllIndex: false,
    errorMessageAllIndex: action.payload.message
  }))
  .handleAction(fetchAllDepartmentIndexAsync.success, (state, action) => ({
    ...state,
    isLoadingAllIndex: false,
    lastUpdatedAllIndex: Date.now(),
    allDepartmentIndex: action.payload
  }));
