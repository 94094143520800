/**
 * Sleep implemented in TS. Must be called in conjunction with await.
 * @param {number} duration - The duration to sleep, in ms
 * @return Promise that resolves after duration
 */

function sleep(duration: number) {
  return new Promise(resolve => setTimeout(resolve, duration));
}

export { sleep };
