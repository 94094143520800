import Bluebird from 'bluebird';
import moment from 'moment';
import { getFirebase } from 'react-redux-firebase';
import * as nimblyAPI from '../constants/api';
import { getServerTime } from './getServerTime';

type SectionMeta = {
  section: number;
  uid: string;
};

/**
 * @description Upload each photo in the question.
 * If `sectionData` is provided will upload to report section's folder
 */
const uploadReportSelfie = (
  documentURI: string,
  organizationKey: string,
  siteKey: string,
  reportKey: string,
  sectionData: SectionMeta | false,
  cancelHandler: () => any,
  isTutorial?: boolean,
  onSuccess?: (ref: string) => any,
  journey?: any,
  profile?
): Promise<string> => {
  return new Bluebird(async (resolve: (T: any) => void, reject: (E: Error) => any, onCancel: (A?: any) => any) => {
    if (typeof documentURI === 'string') {
      return resolve(documentURI);
    } else {
      const formData = new FormData();
      const firebase = getFirebase();
      const timestamp = moment(getServerTime()).unix();

      let fileExt = (documentURI as File).name.split('.').pop();
      const blob = (documentURI as File).slice(0, (documentURI as File).size, (documentURI as File).type);

      const newNameFile = new File([blob], `${timestamp}.${fileExt}`, {
        type: (documentURI as File).type
      });
      let fileRef = '';

      formData.append('file', newNameFile);
      const type = (documentURI as File).type.split('/')[1];
      const token = (await firebase.auth().currentUser?.getIdToken()) as string;

      fileRef = `/report/${profile.organization}/${siteKey}/selfieSignatures/${reportKey}`;

      if (journey) {
        fileRef = `/reportJourney/${profile.organization}/${journey.code}/${siteKey}/selfieSignatures/${reportKey}`;
      }

      return fetch(`${nimblyAPI.NIMBLY_API_UPLOAD}path=${fileRef}&fileType=${type}`, {
        method: 'POST',
        headers: {
          Authorization: token
        },
        body: formData
      })
        .then(async res => {
          if (res?.status === 200) {
            const json: any = await res.json();
            return resolve(json.data);
          } else {
            return reject(new Error('Fail to upload'));
          }
        })
        .catch((error: any) => {
          return reject(error);
        });
    }
  }).then((res: string) => {
    const selectedRef = res ? res : '';
    return selectedRef;
  });
};

export default uploadReportSelfie;
