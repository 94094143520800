import update from 'immutability-helper';

import { ActionType } from 'typesafe-actions';

import * as actions from './siteDataset.action';
import * as actionTypes from './siteDataset.actionTypes';

import * as Classes from '../../../utils/classes';
import { PopulatedSiteSKU, PopulatedSitePromotion } from '../../../typing/types';

export type SiteData = {
  readonly profile?: Classes.Site | null;
  readonly report: Classes.SiteReport | null;
  readonly summary?: Classes.ReportIndex | null;
  readonly schedule?: Classes.Schedule | null;
  readonly latestInventory?: Classes.SiteInventory | null;
  readonly siteSKU?: { [skuKey: string]: PopulatedSiteSKU } | null;
  readonly promotion?: { [skuKey: string]: PopulatedSitePromotion } | null;
  readonly siteQuestionnaireSchedule?: Classes.SiteQuestionnaireSchedule | null;
};

export type SiteDatasetState = {
  readonly [siteKey: string]: SiteData;
};

export type SiteDatasetAction = ActionType<typeof actions>;

const initialState: SiteDatasetState = {};

const siteDatasetReducer = (state = initialState, action: SiteDatasetAction) => {
  switch (action.type) {
    case actionTypes.SET_SITE_DATA:
      return update(state, {
        [action.payload.siteKey]: (siteData: SiteData) =>
          update(siteData || {}, {
            [action.payload.dataType]: { $set: action.payload.data }
          })
      });

    default:
      return state;
  }
};

export default siteDatasetReducer;
