import { createStore, applyMiddleware } from 'redux';
import { getFirebase } from 'react-redux-firebase';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'remote-redux-devtools';
import { persistStore, persistReducer, PersistConfig } from 'redux-persist';
// import FilesystemStorage from 'redux-persist/es/storage/session';
import localforage from 'localforage';

import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/database';
import '@firebase/storage';
import '@firebase/functions';
import '@firebase/firestore';
// import '@firebase/messaging';

import { rootReducer, RootState } from './reducers';
import { rootSaga } from './sagas';
import { fbConfig } from '../constants/firebase';

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(fbConfig);
}

// Initial state.
const initialState = {};

// Create enhancer

// Middlewares
const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk.withExtraArgument({ getFirebase: getFirebase }), sagaMiddleware];

const persistVersion = 1;

const rootPersistConfig: PersistConfig<any> = {
  version: persistVersion,
  key: 'root',
  storage: localforage,
  whitelist: ['reportcache', 'siteschedule', 'reportsubmit'],
  timeout: 0
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const store = createStore(persistedReducer, initialState, applyMiddleware(...middlewares));
sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

if (module.hot) {
  module.hot.accept(() => {
    const nextRootReducer = require('./reducers').rootReducer;
    store.replaceReducer(nextRootReducer);
  });
}

export { store, persistor, persistVersion };
