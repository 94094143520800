import React from 'react';
import { Platform, StyleSheet, TouchableOpacity, View, TouchableNativeFeedback } from 'react-native';
import moment from 'moment';

import Text from '../../../../global/Text';
import fontMaker from '../../../../../utils/font';
import theme from '../../../../../constants/theme';

type IssueFilterCalendarProps = {
  /** Start date chosen for filter */
  startDate: string | null;
  /** End date chosen for filter */
  endDate: string | null;
  /** Current month chosen */
  currentMonth: number;
  /** Handle select chosen start and end date*/
  handleSelectDate: (date: string) => void;
};

/**
 * Issue Filter Calendar generates the view for each day (i.e. 1 2 ... 30 31).
 */
const SiteReportFilterCalendar = (props: IssueFilterCalendarProps) => {
  const now = moment().parseZone().subtract(4, 'hours');
  const monthStart = moment(moment(props.currentMonth).startOf('month')).local();
  const monthEnd = moment(moment(monthStart).endOf('month')).local();
  const startDate = moment(moment(monthStart).startOf('month').isoWeekday(1)).local();

  const endDate = moment(moment(monthEnd).isoWeekday(7)).local();
  // Day of month i.e. 1 2 ... 30 31
  const dateFormat = 'D';
  const rows = [];

  let days = [];
  const day = startDate;
  let formattedDate = '';

  while (day.isSameOrBefore(endDate)) {
    for (let i = 0; i < 7; i += 1) {
      formattedDate = moment(startDate).format(dateFormat);
      if (day.isSame(props.currentMonth, 'month')) {
        const sameStart = day.isSame(props.startDate!, 'day');
        const sameEnd = day.isSame(props.endDate!, 'day');
        const date = day.format('YYYY-MM-DD');
        const inBetween = moment(date).isBetween(moment(props.startDate!), moment(props.endDate!), 'day');
        days.push(
          <View style={styles.dateCell} key={day.toISOString()}>
            <Touchable onPress={() => props.handleSelectDate(date)}>
              <View
                style={[
                  styles.dateBg,
                  formattedDate ? styles.dateBorder : {},
                  sameStart || sameEnd ? styles.dateBgSelected : {},
                  inBetween ? styles.dateBgBetween : {}
                ]}
              >
                <Text
                  style={[
                    styles.dateText,
                    sameStart || sameEnd
                      ? styles.dateTextSelected
                      : day.isSame(now, 'day')
                      ? styles.dateCurrent
                      : styles.dateFuture
                  ]}
                >
                  {formattedDate}
                </Text>
              </View>
            </Touchable>
          </View>
        );
      } else {
        days.push(<View style={styles.dateCell} key={day.toISOString()} />);
      }
      day.add(1, 'days');
    }
    rows.push(
      <View style={[styles.dateRow]} key={day.toISOString()}>
        {days}
      </View>
    );
    days = [];
  }

  // make default calendar week row to 6
  while (rows.length < 5) {
    rows.push(
      <View style={styles.dateRow} key={`${rows.length}-${monthEnd.toISOString()}`}>
        <View style={styles.dateCell}>
          <View style={styles.dateBg} />
        </View>
      </View>
    );
  }

  return (
    <View style={styles.dateContainer} testID="siteReportFilterCalendar-container">
      {rows}
    </View>
  );
};

const Touchable: any = TouchableOpacity;

const styles = StyleSheet.create({
  dateContainer: {
    width: '100%'
  },
  dateRow: {
    flexDirection: 'row',
    // justifyContent: 'space-between',
    paddingVertical: 3
  },
  dateCell: {
    justifyContent: 'space-around',
    alignItems: 'center',
    flex: 1
  },
  dateBg: {
    height: 30,
    width: 37,
    alignItems: 'center',
    justifyContent: 'center'
  },
  dateBorder: { borderRadius: 5, borderColor: '#EAE9F9', borderStyle: 'solid', borderWidth: 1 },
  dateBgSelected: {
    backgroundColor: '#574FCF',
    borderRadius: 5,
    borderColor: '#574FCF',
    borderStyle: 'solid',
    borderWidth: 1,
    zIndex: 1
  },
  dateBgBetween: {
    backgroundColor: '#938cfa',
    width: 62,
    zIndex: -100,
    borderRadius: 5,
    borderColor: '#938cfa',
    borderStyle: 'solid',
    borderWidth: 1
  },
  dateTextSelected: {
    color: '#fff'
  },
  dateText: {
    fontSize: 11
  },
  dateCurrent: {
    color: '#3cd070',
    ...fontMaker({ weight: 'normal' })
  },
  dateFuture: {
    color: '#574FCF',
    ...fontMaker({ weight: 'normal' })
  }
});

export default SiteReportFilterCalendar;
