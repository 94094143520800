const generalSelectTheme = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#5049B2',
    primary75: '#716bc3',
    primary50: '#948fd2',
    primary25: '#b7b3e1'
  }
});

const noOptionsMessage = provided => ({
  ...provided,
  textAlign: 'left',
  fontSize: '13px',
  padding: '4px',
  color: '#a8a8aa'
});

const customStyles = stylesObj => provided => ({
  ...provided,
  ...stylesObj
});

const sectionsSelectStyles = {
  noOptionsMessage,
  menu: customStyles({
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '23px',
    marginTop: 3
  }),
  option: customStyles({
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '23px',
    paddingTop: 6,
    paddingBottom: 6,
    minHeight: 30,
    maxHeight: 30
  }),
  container: customStyles({
    minHeight: 40,
    maxHeight: 343
  }),
  placeholder: customStyles({
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '23px',
    color: '#a8a8aa',
    minHeight: 20,
    maxHeight: 20
  }),
  control: customStyles({
    width: 343,
    height: 40,
    borderRadius: 2
  }),
  input: customStyles({
    fontFamily: 'Poppins',
    fontWeight: 400,
    color: '#535353'
  }),
  singleValue: customStyles({
    fontFamily: 'Poppins',
    fontSize: '14px',
    lineHeight: '23px',
    fontWeight: 400,
    color: '#535353',
    top: '0',
    transform: 'translate(0, 0)'
  }),
  indicatorSeparator: customStyles({
    backgroundColor: '#ffffff'
  })
};

export { generalSelectTheme, sectionsSelectStyles };
