import moment, { Moment } from 'moment';

function padZeroes(val: number) {
  return val < 10 ? `0${val}` : `${val}`;
}

export const parseDate = (dateMoment: Moment) => {
  const date = dateMoment.format('DD MMM YYYY');
  const hour = dateMoment.hour();
  const minutes = dateMoment.minute();
  const timePeriod = hour >= 0 && hour < 12 ? 'am' : 'pm';
  const parsedHour = hour > 12 ? hour - 12 : hour;
  const hourString = parsedHour >= 10 ? parsedHour.toString() : `0${parsedHour}`;
  const minutesString = minutes >= 10 ? minutes.toString() : `0${minutes}`;

  return {
    date,
    hour: hourString,
    minutes: minutesString,
    timePeriod
  };
};

export const calculateTimeDuration = (timeIn: string, timeOut: string) => {
  let totalSeconds = moment.duration(moment(timeOut).diff(moment(timeIn))).asSeconds();
  const hours = Math.floor(totalSeconds / (60 * 60));
  totalSeconds %= 60 * 60;
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.round(totalSeconds % 60);

  return `${padZeroes(hours)}:${padZeroes(minutes)}:${padZeroes(seconds)}`;
};
