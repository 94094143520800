import React, { useRef } from 'react';
import {
  Dimensions,
  Image,
  Platform,
  StyleSheet,
  TextInput,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableWithoutFeedbackProps,
  View
} from 'react-native';

import Text from '../global/Text';

import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import fontMaker from '../../utils/font';
import { useTranslation } from 'react-i18next';
import { SCREEN_WIDTH } from '../../utils/screensize';
// import * as ART from '@react-native-community/art';
// const { Surface, Group, Shape } = ART;
const { width } = Dimensions.get('screen');

const cardWidth = SCREEN_WIDTH > 500 ? 400 : width * 0.5;
const penX = 14;

type SignatureCardProps = {
  language: string;
  hasError: boolean;
  imageLink: string;
  isSigned: boolean;
  isBusy: boolean;
  name: string;
  position: string;
  signatureValidation: boolean;
  handleDrawOptions: () => any;
  handleDrawSignature: () => any;
  handleTextInput: (key: string) => (v: string) => any;
};

const SignatureCard = (props: SignatureCardProps) => {
  const nextInput: any = React.createRef();
  const { t } = useTranslation(['submitReport', 'common']);
  const { isSigned, language, imageLink, name, position, hasError, signatureValidation, isBusy } = props;

  const validateBorderColor = signatureValidation ? '#A0A4A8' : '#FFD6DA';

  return (
    <View style={[styles.root, hasError ? styles.error : {}, { borderColor: validateBorderColor }]}>
      <View style={styles.option}>
        {!isSigned ? (
          <Text>&zwnj;</Text>
        ) : (
          <Touchable onPress={props.handleDrawOptions}>
            <Icon name="dots-horizontal" size={20} color="#535353" />
          </Touchable>
        )}
      </View>
      <View style={[styles.body, Platform.OS === 'android' ? styles.cardDimension : {}]}>
        {!isSigned ? (
          <Touchable onPress={props.handleDrawSignature} style={styles.touchable} disabled={isBusy}>
            <View style={[styles.placeholderContainer, Platform.OS === 'android' ? styles.touchable : {}]}>
              <Text style={styles.placeholderText}>{t('submitReport:signatureCard.tapSign')}</Text>
            </View>
          </Touchable>
        ) : (
          <View style={styles.signatureContainer}>
            <Image source={{ uri: imageLink }} style={styles.signature} />
          </View>
        )}
        <View style={styles.surfaceContainer}>
          {/* <Surface width={cardWidth} height={cardWidth / 4}>
            <ART.Text
              x={penX}
              y={cardWidth / 4 - 20}
              fill={'#c43e00'}
              font={`11px "Helvetica Neue", "Helvetica", Arial`}
            >
              x
            </ART.Text>
            <Group x={penX} y={cardWidth / 4 - 1}>
              <Shape
                d={`H${cardWidth - 2 * penX} Z`}
                stroke={'#535353'}
                strokeWidth={1}
                strokeCap={'butt'}
                strokeDash={[10, 7]}
                strokeJoin={'miter'}
              />
            </Group>
          </Surface> */}
        </View>
      </View>
      <View style={styles.buttonContainer}>
        <TextInput
          style={[styles.buttonText, hasError && !name ? styles.textError : {}]}
          placeholderTextColor={hasError && !name ? '#c43e00' : '#c5c5c5'}
          multiline={false}
          autoCorrect={false}
          autoCapitalize="words"
          returnKeyType="next"
          textContentType="name"
          placeholder={t('submitReport:signatureCard.enterName')}
          value={name}
          onChangeText={props.handleTextInput('name')}
          onSubmitEditing={() => nextInput.current.focus()}
          blurOnSubmit={false}
          editable={!isBusy}
        />
        <TextInput
          ref={nextInput}
          placeholderTextColor={hasError && !position ? '#c43e00' : '#c5c5c5'}
          style={[styles.buttonText, hasError && !position ? styles.textError : {}]}
          multiline={false}
          autoCorrect={false}
          autoCapitalize="words"
          returnKeyType="done"
          textContentType="jobTitle"
          placeholder={t('submitReport:signatureCard.enterPosition')}
          value={position}
          onChangeText={props.handleTextInput('position')}
          blurOnSubmit={true}
          editable={!isBusy}
        />
      </View>
    </View>
  );
};

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
  Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

const styles = StyleSheet.create({
  root: {
    borderWidth: 1,
    borderColor: '#A0A4A8',
    borderRadius: 4,
    width: cardWidth,
    marginVertical: 14,
    marginHorizontal: 8,
    backgroundColor: '#fff',
    minHeight: 190,

    justifyContent: 'space-between'
  },
  cardDimension: {
    width: cardWidth,
    height: cardWidth / 4
  },
  error: {
    borderColor: '#c43e00',
    borderWidth: 1
  },
  textError: {
    borderBottomWidth: StyleSheet.hairlineWidth,
    borderColor: '#c43e00'
  },
  option: {
    flex: 1,
    alignItems: 'flex-end',
    minHeight: 30,
    paddingVertical: 4,
    paddingHorizontal: 10
  },
  body: {
    width: 500,
    minHeight: cardWidth / 4,
    overflow: 'hidden'
  },
  touchable: {
    width: '100%',
    height: cardWidth / 4,
    backgroundColor: 'transparent',
    zIndex: 2
  },
  placeholderContainer: {
    width: SCREEN_WIDTH > 500 ? 400 : 300,
    bottom: 28 - 20,
    padding: 20,
    backgroundColor: 'transparent',
    position: 'absolute'
  },
  placeholderText: {
    fontSize: SCREEN_WIDTH > 500 ? 20 : 15,
    lineHeight: 29,
    color: '#c5c5c5',
    textAlign: 'center'
  },
  surfaceContainer: {
    position: 'absolute',
    zIndex: 1
  },
  signatureContainer: {
    position: 'absolute',
    zIndex: 1
  },
  signature: {
    width: cardWidth,
    height: cardWidth / 4,
    resizeMode: 'contain'
  },
  buttonContainer: {
    flex: 2,
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'center',
    paddingHorizontal: 14,
    paddingBottom: 2
  },
  buttonText: {
    width: '100%',
    paddingVertical: 4,
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 13,
    color: '#535353'
  }
});

export default SignatureCard;
