function round(num: number, precision = 0) {
  const shift = (n: number, p: number) => {
    const numArray = `${n}`.split('e');
    return +`${numArray[0]}e${numArray[1] ? +numArray[1] + p : p}`;
  };
  return shift(Math.round(shift(num, +precision)), -precision);
}

// TODO: Deprecate this, replaced by `isValidNumber`
function countIsInvalid(num: string, float = true) {
  const parsedText = parseFloat(num);
  if (num.indexOf('-') > 0 || (num.match(/\-/g) || []).length > 1) {
    return true;
  } else if (isNaN(parsedText)) {
    return true;
  } else if (!float) {
    return num.indexOf('.') > -1;
  } else if (num.indexOf('.') > -1 && (num.match(/\./g) || []).length > 1) {
    return true;
  }
  return false;
}

function isValidNumber(num: string, { float = true, negative = false } = {}) {
  const isInitialNegative = negative && num === '-';
  const parsedText = parseFloat(num);

  if (isInitialNegative) {
    return true;
  }

  if (isNaN(parsedText)) {
    return false;
  }

  if (!float) {
    return num.indexOf('.') < 0;
  }

  if (num.indexOf('.') > -1 && (num.match(/\./g) || []).length > 1) {
    return false;
  }

  return true;
}

function numberWithCommas(text: string | number) {
  const parts = text.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export { round, countIsInvalid, numberWithCommas, isValidNumber };
