import { action, ActionType, createReducer } from 'typesafe-actions';
import { Reducer, AnyAction } from 'redux';

export const RECEIVE_INCOMING = '@notification/RECEIVE_INCOMING_NOTIFICATION';
export const CLEAR_INCOMING = '@notification/RCLEAR_INCOMING_NOTIFICATION';
const NOTIFICATION_MESSAGE = '@notification/NOTIFICATION_MESSAGE';

export const setNotificationModal = (notificationModal: boolean) => action(NOTIFICATION_MESSAGE, { notificationModal });

/**
 * Sends questionnaire details from App to Redux
 * @param payload
 */
const receiveIncomingNotification = (payload: { siteKey: string }) => action(RECEIVE_INCOMING, payload);

/**
 * Clears out the redux after navigating to Site
 */
const clearIncomingNotification = () => action(CLEAR_INCOMING);

export const notificationActions = { receiveIncomingNotification, clearIncomingNotification };

export type NotificationAction = ActionType<typeof notificationActions>;
export interface NotificationOptions {
  scheduleKey?: string;
  // allow to write new property for future use case
  [key: string]: any;
}

export type NotificationPayload = {
  type: 'issue' | 'site' | 'schedule' | 'notification';
  key: string;
  title: string;
  value: string;
  options: string;
} | null;

export type NotificationState = {
  readonly payload: NotificationPayload;
  notificationModal: boolean;
};

const initialState: NotificationState = {
  payload: null,
  notificationModal: false
};

export const notificationReducer: Reducer<NotificationState, AnyAction> = (state = initialState, incomingAction) => {
  switch (incomingAction.type) {
    case RECEIVE_INCOMING:
      return {
        ...state,
        payload: incomingAction.payload
      };
    case CLEAR_INCOMING:
      return {
        ...state,
        payload: null
      };
    case NOTIFICATION_MESSAGE:
      return {
        ...state,
        notificationModal: incomingAction.payload.notificationModal
      };
    default:
      return state;
  }
};
