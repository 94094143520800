import 'firebase/database';
import 'firebase/storage';
import isArray from 'lodash/isArray';
import { Organization } from 'nimbly-common';
import React, { useEffect, useState } from 'react';
import { Alert, Platform } from 'react-native';
import ImagePicker, { Image as ImageProps } from 'react-native-image-crop-picker';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
// redux
import { companyActions } from '../../store/reducers/company/company';
import { toastActions } from '../../store/reducers/toast';
// utils
import { errorLogger } from '../../utils/errorLogger';
// components
import CreateCompanyContainer from './CreateCompanyContainer';
// types
import { CreateCompanyProps } from './type';
import { useTranslation } from 'react-i18next';

export function CreateCompany(props: CreateCompanyProps) {
  // redux state
  const language = useSelector<RootState>(state => state.firebase.profile.language);
  const selectedSiteKey = useSelector<RootState, string | null>(state => state.site.selectedSiteKey);
  const selectedReportKey = useSelector<RootState, string | null>(state => state.report.selectedReportKey);
  const auth = useSelector<RootState, any>(state => state.firebase.auth);
  const profile = useSelector<RootState, any>(state => state.firebase.profile);
  const organization = useSelector<RootState, Organization>(state => state.organization.myOrganization!);
  const errorCompany = useSelector<RootState, string | null>(state => state.company.error);
  const isLoading = useSelector<RootState, boolean>(state => state.company.isLoading);
  const hasNoth = useSelector<RootState, boolean>(state => state.account.hasNotch);

  // state
  const [companyName, setCompanyName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [website, setWebsite] = useState<string>('');
  const [established, setEstablished] = useState<string>('');
  const [strength, setStrength] = useState<string>('');
  const [strengths, setStrengths] = useState<string[]>([]);
  const [weakness, setWeakness] = useState<string>('');
  const [weaknesses, setWeaknesses] = useState<string[]>([]);
  const [noOfEmployees, setNoOfEmployees] = useState<string>('');
  const [logoPath, setLogoPath] = useState<string>('');
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const { navigation } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation(['submitReport', 'common']);

  //error toast
  useEffect(() => {
    if (errorCompany) {
      dispatch(toastActions.createToast(errorCompany, true));
    }
  }, [errorCompany]);

  const handleNavCapturePhoto = () => {};

  const handlePhotoCaptured = async (temporaryPhotoURI: string) => {};

  const activateGallery = async () => {
    const isHighRes = organization!.highResPhoto || false;

    let options = {};

    if (Platform.OS === 'ios') {
      options = {
        mediaType: 'photo',
        // set image max dimension/compression (currently iOS only) uses 70-150kb
        compressImageMaxWidth: isHighRes ? 2500 : 800,
        compressImageMaxHeight: isHighRes ? 2500 : 800,
        // compression range 0 - 1 (best)
        compressImageQuality: isHighRes ? 0.8 : 0.5,
        // writeTempFile write temporary files with '/private/' path (iOS only)
        writeTempFile: true
      };
    } else {
      options = {
        mediaType: 'photo',
        // set image max dimension/compression (currently iOS only) uses 70-150kb
        compressImageMaxWidth: isHighRes ? 1600 : 800,
        compressImageMaxHeight: isHighRes ? 1600 : 800,
        // compression range 0 - 1 (best)
        compressImageQuality: isHighRes ? 1 : 0.8,
        // writeTempFile write temporary files with '/private/' path (iOS only)
        writeTempFile: true
      };
    }
    try {
      const hasPermission = await checkPermission();
      if (!hasPermission) {
        return;
      }

      const image: ImageProps | ImageProps[] = await ImagePicker.openPicker(options);
      const imagePath = isArray(image) ? image[0].path : image.path;

      handlePhotoCaptured(imagePath);
    } catch (err) {
      // On cancelling photo, remove the _editingPhotoIndex
      errorLogger(
        auth.uid,
        profile.organization,
        selectedSiteKey || '',
        selectedReportKey || '',
        err,
        'CreateCompany.tsx'
      );
    }
  };

  const checkPermission = async () => {};

  const handleConfirmEditPhoto = () => {
    Alert.alert(
      language === 'en' ? 'Delete Photo' : 'Change Foto',
      language === 'en' ? 'Delete this photo?' : 'Hapus foto ini?',
      [
        {
          text: language === 'en' ? 'Delete' : 'Hapus',
          onPress: () => {
            setLogoPath('');
          },
          style: 'destructive'
        },
        {
          text: t('common:cancel'),
          style: 'cancel'
        }
      ]
    );
  };

  const handleSave = async () => {
    try {
      setSaveLoading(true);

      // handle if user forget to hit enter when input strength and weakness
      let weaknessesValue = weaknesses;
      if (!weaknesses.length && weakness) {
        weaknessesValue = [weakness];
      }

      let strengthsValue = strengths;
      if (!strengths.length && strength) {
        strengthsValue = [strength];
      }

      const payload = {
        name: companyName,
        logo: '',
        description,
        website,
        established: Number(established) || 0,
        noOfEmployees: Number(noOfEmployees) || 0,
        strength: strengthsValue,
        weakness: weaknessesValue
      };

      if (!companyName) {
        dispatch(toastActions.createToast(language === 'en' ? 'Complete required field' : 'Lengkapi form', true));
        return;
      }

      await dispatch(companyActions.createCompanyAsync.request(payload));

      setSaveLoading(false);

      navigation.pop();
    } catch (err) {
      setSaveLoading(false);
      errorLogger(auth.uid, profile.organization, '', '', err, 'Questionnaire.tsx/handleSave');
    }
  };

  return (
    <CreateCompanyContainer
      hasNotch={props.hasNotch}
      isLoading={isLoading}
      saveLoading={saveLoading}
      companyName={companyName}
      logoPath={logoPath}
      language={language}
      description={description}
      weakness={weakness}
      website={website}
      established={established}
      noOfEmployees={noOfEmployees}
      weaknesses={weaknesses}
      strength={strength}
      strengths={strengths}
      activateGallery={activateGallery}
      handleConfirmEditPhoto={handleConfirmEditPhoto}
      handleSave={handleSave}
      handleNavCapturePhoto={handleNavCapturePhoto}
      setCompanyName={setCompanyName}
      setWebsite={setWebsite}
      setWeakness={setWeakness}
      setDescription={setDescription}
      setEstablished={setEstablished}
      setNoOfEmployees={setNoOfEmployees}
      setWeaknesses={setWeaknesses}
      setStrength={setStrength}
      setStrengths={setStrengths}
    />
  );
}

export default CreateCompany;
