import React from 'react';
import {
  ActivityIndicator,
  Dimensions,
  Keyboard,
  NativeMethodsMixinStatic,
  StyleSheet,
  findNodeHandle
} from 'react-native';
import Swiper from 'react-native-swiper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { connect } from 'react-redux';
import unitOfMeasures from '../../constants/unitOfMeasure';
import { RootState } from '../../store/reducers';
import { reportActions } from '../../store/reducers/report';
import { ConnectedDispatch } from '../../typing/types';
import { OrganizationSKU, Question, SKUInventory, SiteSKU } from '../../utils/classes';

import QuestionnaireSwiperContainer from './QuestionnaireSwiperContainer';

const screen = Dimensions.get('screen');
const { width } = screen;

export class QuestionnaireSwiper extends React.Component<QuestionnaireSwiperProps> {
  state: {
    _showModal: any;
    _keyboardShown: boolean;
    _initialQuestionIndex: number;
    inventoryIn: { [index: number]: string };
    inventoryFinal: { [index: number]: string };
    skuIn: { [key: string]: string };
    skuFinal: { [key: string]: string };
    _skuCommentVisible: { [key: string]: boolean };
    query: string;
    isScrolling: boolean;
  };

  swiper: any;
  scrolls: { [index: number]: React.RefObject<any> } = {};

  commentInputs: { [index: number]: React.RefObject<any> } = {};
  numberInputs: { [index: number]: React.RefObject<any> } = {};
  competitorAnalysisInputs: { [key: string]: React.RefObject<any> } = {};
  inventoryCards: { [key: string]: React.RefObject<any> } = {};

  constructor(props: QuestionnaireSwiperProps) {
    super(props);
    const inventoryIn: { [index: number]: string } = {};
    const inventoryFinal: { [index: number]: string } = {};
    const skuIn: { [key: string]: string } = {};
    const skuFinal: { [key: string]: string } = {};
    const _skuCommentVisible: { [key: string]: boolean } = {};
    props.questions.forEach((question, index) => {
      this.scrolls[index] = React.createRef();
      if (question.type === 'inventory') {
        inventoryIn[index] = question.inventory && question.inventory.in > 0 ? question.inventory.in.toString() : '';
        inventoryFinal[index] =
          question.inventory && question.inventory.final > 0 ? question.inventory.final.toString() : '';
      } else if (question.type === 'inventory-v2' && question.skuInventory && props.siteSKUs) {
        Object.keys(question.skuInventory).forEach(skuKey => {
          this.inventoryCards[skuKey] = React.createRef();
          if (question.skuInventory && question.skuInventory[skuKey]) {
            skuIn[skuKey] = question.skuInventory[skuKey].in > 0 ? question.skuInventory![skuKey].in.toString() : '0';
            if (
              question.skuInventory[skuKey].final > 0 ||
              (question.skuInventory[skuKey].final === 0 && question.skuInventory[skuKey].isFilledByUser)
            ) {
              skuFinal[skuKey] = question.skuInventory[skuKey].final.toString();
            } else if (props.siteSKUs && props.siteSKUs[skuKey]) {
              const stock = props.siteSKUs[skuKey].stock;
              props.dispatch(reportActions.inputAnswerSKUInventory(index, skuKey, stock, 'final'));
              skuFinal[skuKey] = stock.toString();
            } else {
              skuFinal[skuKey] = '';
            }
            _skuCommentVisible[skuKey] = question.skuInventory[skuKey].comment ? true : false;
          }
        });
      } else if (question.type === 'competitor') {
        const competitorAnalysisTypes = [
          'company',
          'form',
          'promotionTitle',
          'promotionDetail',
          'promotionDisplayLocation',
          'productName',
          'productPriceFrom',
          'productPriceTo',
          'productDescription'
        ];
        competitorAnalysisTypes.forEach(type => {
          this.competitorAnalysisInputs[`${index}_${type}`] = React.createRef();
        });
      } else {
        this.commentInputs[index] = React.createRef();
        if (question.type === 'number' || question.type === 'range-flag') {
          this.numberInputs[index] = React.createRef();
        }
      }
    });
    this.swiper = React.createRef();
    this.state = {
      _showModal: null,
      _keyboardShown: false,
      _initialQuestionIndex: props.currentQuestionIndex != null ? props.currentQuestionIndex : 0,
      inventoryIn,
      inventoryFinal,
      skuIn,
      skuFinal,
      _skuCommentVisible,
      query: '',
      isScrolling: false
    };
  }

  static getDerivedStateFromProps(props: QuestionnaireSwiperProps, state: QuestionnaireSwiperState) {
    if (!state._showModal) {
      const modalStates: any = {};
      props.questions.forEach((question, index) => {
        if (question.type === 'binary' || question.type === 'binary-with-red-flag') {
          modalStates[index] = false;
        }
      });
      return {
        _showModal: modalStates
      };
    }
    return {};
  }

  componentDidUpdate(prevProps: QuestionnaireSwiperProps) {
    let swipes: number;

    if (
      prevProps.drawerQuestionIndex === null &&
      this.props.drawerQuestionIndex !== null &&
      this.props.currentQuestionIndex !== this.props.drawerQuestionIndex &&
      !this.state.isScrolling
    ) {
      this.setState({
        isScrolling: true
      });

      const hasUpcomingQuestions =
        this.props.questions.length > this.props.drawerQuestionIndex + 1 || this.props.drawerQuestionIndex > 0;

      if (hasUpcomingQuestions) {
        swipes = this.props.drawerQuestionIndex - this.props.currentQuestionIndex!;
        this.scrollSwiper(swipes);
      }
    }
  }

  handleQuestionIndexChange = (index: number) => {
    Keyboard.dismiss();
    this.props.dispatch(reportActions.setQuestionIndex(index));
    this.setState({
      isScrolling: false
    });
    return this.props.onQuestionIndexChanged();
  };

  handleInputSKUQuery = (text: string) => {
    this.setState({ query: text });
    this.props.onSKUQueryChanged(text);
  };

  handleClearSKUQuery = () => {
    this.setState({ query: '' });
    this.props.onSKUQueryChanged('');
  };

  scrollSwiper = (swipeNumber: number) => {
    if (this.swiper.current && this.swiper.current.scrollBy) {
      this.swiper.current.scrollBy(swipeNumber);
    }
  };

  handleNavReference = (url: string) => this.props.onNavReference(url);

  handleNavRecordVideo = (currentConditionIndex?: number | string, props?: any) =>
    this.props.onNavRecordVideo(currentConditionIndex!, props!);

  handleNavPlaybackVideo = (uri: string, videoIndex: number, currentConditionIndex?: number | string) =>
    this.props.onNavPlaybackVideo(uri, videoIndex, currentConditionIndex);

  handleNavCreateCompany = () => this.props.onNavCreateCompany();

  // Scroll down to relevant textinput on focus
  handleTextInputFocused = (index: number) => {
    const textInput: NativeMethodsMixinStatic = this.commentInputs[index].current;
    const scroll = this.scrolls[index].current;
    if (textInput && scroll) {
      textInput.measureLayout(
        findNodeHandle(scroll)!,
        (x, y) => {
          this.setState({ _keyboardShown: true }, () => {
            if (scroll.scrollTo) {
              setTimeout(() => scroll.scrollTo({ x: 0, y: 0, animated: true }), 0);
            }
          });
        },
        () => ({})
      );
    } else {
      this.setState({ _keyboardShown: true });
    }
  };

  // On textinput blur, scroll back up
  handleTextInputBlurred = (index: number) => {
    const scroll = this.scrolls[index].current;
    this.setState({ _keyboardShown: false }, () => {
      if (scroll) {
        setTimeout(() => scroll.scrollTo({ x: 0, y: 0, animated: true }), 0);
      }
    });
  };

  // Scroll down to relevant number textinput on focus
  handleNumberInputFocused = (index: number) => {
    const numberInput: NativeMethodsMixinStatic = this.numberInputs[index].current;
    const scroll = this.scrolls[index].current;
    if (numberInput && scroll) {
      numberInput.measureLayout(
        findNodeHandle(scroll)!,
        (x, y) => {
          this.setState({ _keyboardShown: true }, () => {
            if (scroll.scrollTo) {
              setTimeout(() => scroll.scrollTo({ x: 0, y: 0, animated: true }), 0);
            }
          });
        },
        () => ({})
      );
    } else {
      this.setState({ _keyboardShown: true });
    }
  };

  // On number textinput blur, scroll back up
  handleNumberInputBlurred = (index: number) => {
    const scroll = this.scrolls[index].current;
    this.setState({ _keyboardShown: false }, () => {
      if (scroll) {
        setTimeout(() => scroll.scrollTo({ x: 0, y: 0, animated: true }), 0);
      }
    });
  };

  handleInventoryInputFocused = async (
    questionIndex: number,
    skuKey: string,
    type: 'in' | 'final' | 'comment',
    skuItemIndex: number
  ) => {
    const { questions, siteSKUs } = this.props;
    const card: NativeMethodsMixinStatic = this.inventoryCards[skuKey].current;
    const scroll = this.scrolls[questionIndex].current;
    if (card && scroll) {
      card.measureLayout(
        findNodeHandle(scroll)!,
        (x, y) => {
          this.setState({ _keyboardShown: true }, () => {
            if (scroll.scrollToIndex) {
              setTimeout(() => scroll.scrollToIndex({ viewPosition: 0, index: skuItemIndex }));
            }
          });
        },
        () => ({})
      );
    } else {
      this.setState({ _keyboardShown: true });
    }
    const currentQuestion = questions[questionIndex];
    if (currentQuestion.type === 'inventory-v2') {
      if (type === 'in' && this.state.skuIn[skuKey] === '0') {
        this.setState((prevState: { skuIn: { [key: string]: string } }) => {
          const skuIn = prevState.skuIn;
          skuIn[skuKey] = '';
          return { skuIn };
        });
      } else if (
        type === 'final' &&
        siteSKUs![skuKey] &&
        this.state.skuFinal[skuKey] === siteSKUs![skuKey].stock.toString()
      ) {
        this.setState((prevState: { skuFinal: { [key: string]: string } }) => {
          const skuFinal = prevState.skuFinal;
          skuFinal[skuKey] = '';
          return { skuFinal };
        });
      }
    }
  };

  handleInventoryInputBlurred = async (
    questionIndex: number,
    skuKey: string,
    type: 'in' | 'final' | 'comment',
    skuItemIndex: number
  ) => {
    const { questions, siteSKUs } = this.props;
    const card: NativeMethodsMixinStatic = this.inventoryCards[skuKey].current;
    const scroll = this.scrolls[questionIndex].current;
    if (card && scroll) {
      card.measureLayout(
        findNodeHandle(scroll)!,
        (x, y) => {
          this.setState({ _keyboardShown: false }, () => {
            if (scroll.scrollToIndex) {
              setTimeout(() => scroll.scrollToIndex({ viewPosition: 0.5, index: skuItemIndex }));
            }
          });
        },
        () => ({})
      );
    } else {
      this.setState({ _keyboardShown: false });
    }
    const currentQuestion = questions[questionIndex];
    if (currentQuestion.type === 'inventory-v2') {
      if (type === 'in' && this.state.skuIn[skuKey] === '') {
        this.setState((prevState: { skuIn: { [key: string]: string } }) => {
          const skuIn = prevState.skuIn;
          skuIn[skuKey] = '0';
          return { skuIn };
        });
      } else if (type === 'final' && siteSKUs![skuKey] && this.state.skuFinal[skuKey] === '') {
        this.setState((prevState: { skuFinal: { [key: string]: string } }) => {
          const skuFinal = prevState.skuFinal;
          skuFinal[skuKey] = siteSKUs![skuKey].stock.toString();
          return { skuFinal };
        });
      }
    }
  };

  handleSelectChoice = (index: number, choice: string, condition?: number | string) =>
    this.props.onSelectChoice(index, choice, condition!);

  handleSelectWeightedChoice = (index: number, choice: string, condition?: number | string) =>
    this.props.onSelectWeightedChoice(index, choice, condition!);

  // Scroll down to relevant competitor analysis input on focus
  handleCompetitorAnalysisInputFocused = (index: number, type: string) => {
    const competitorAnalysisInput: NativeMethodsMixinStatic = this.competitorAnalysisInputs[`${index}_${type}`].current;
    const scroll = this.scrolls[index].current;
    if (competitorAnalysisInput && scroll) {
      competitorAnalysisInput.measureLayout(
        findNodeHandle(scroll)!,
        (x, y) => {
          this.setState({ _keyboardShown: true }, () => {
            if (scroll.scrollTo) {
              setTimeout(() => scroll.scrollTo({ x: 0, y: y - 5, animated: true }), 0);
            }
          });
        },
        () => ({})
      );
    } else {
      this.setState({ _keyboardShown: true });
    }
  };

  // On competitor analysis input blur, scroll back up
  handleCompetitorAnalysisInputBlurred = (index: number) => {
    const scroll = this.scrolls[index].current;
    this.setState({ _keyboardShown: false }, () => {
      if (scroll) {
        setTimeout(() => scroll.scrollTo({ x: 0, y: 0, animated: true }), 0);
      }
    });
  };

  handleSelectChecklist = (index: number, choice: string, condition?: string | number) =>
    this.props.onSelectChecklist(index, choice, condition!);

  handleInputAnswer = (index: number, text: string, condition?: string | number) =>
    this.props.onInputAnswer(index, text, condition!);

  handleInputComment = (index: number, text: string, condition?: string | number) =>
    this.props.onInputComment(index, text, condition);

  handleInputScore = (index: number, value: number, condition?: string | number) => {
    this.props.onInputScore(index, Math.max(0, value), condition!);
  };
  handleInputDecimal = (index: number, text: string, condition?: string | number) =>
    this.props.onInputDecimal(index, text, condition!);

  handleInputInventory = (index: number, text: string, type: 'in' | 'out' | 'final', question: Question) => {
    const modifiedText: string = text.replace(/,/gi, '.');
    this.setState(
      (prevState: { inventoryIn: { [index: number]: string }; inventoryFinal: { [index: number]: string } }) => {
        if (type === 'in') {
          const inventoryIn = prevState.inventoryIn;
          inventoryIn[index] = modifiedText;
          return { inventoryIn };
        } else if (type === 'final') {
          const inventoryFinal = prevState.inventoryFinal;
          inventoryFinal[index] = modifiedText;
          return { inventoryFinal };
        } else {
          return null;
        }
      }
    );
    return this.props.onInputInventory(index, modifiedText, type, question);
  };

  handleInputSKUInventory = (
    index: number,
    skuKey: string,
    text: string,
    type: 'in' | 'out' | 'final' | 'photos' | 'comment',
    question: Question
  ) => {
    const { siteSKUs } = this.props;

    const skuProfile = siteSKUs && siteSKUs[skuKey] ? siteSKUs[skuKey].skuDetail : null;

    const isFloat = skuProfile && skuProfile.unit && unitOfMeasures[skuProfile.unit].float;
    if (type === 'comment') {
      return this.props.onInputSKUInventory(index, skuKey, text, type, question);
    } else if (/^\d+(\.|,)?\d?\d?$/.test(text) || text === '') {
      let modifiedText: string = text.replace(/,/gi, '.');
      if (!isFloat) {
        const dotIndex = modifiedText.indexOf('.');
        if (dotIndex > -1) {
          modifiedText = modifiedText.slice(0, dotIndex);
        }
      }
      this.setState((prevState: { skuIn: { [sku: string]: string }; skuFinal: { [skuIn: string]: string } }) => {
        if (type === 'in') {
          const skuIn = prevState.skuIn;
          skuIn[skuKey] = modifiedText;
          return { skuIn };
        } else if (type === 'final') {
          const skuFinal = prevState.skuFinal;
          skuFinal[skuKey] = modifiedText;
          return { skuFinal };
        } else {
          return null;
        }
      });
      return this.props.onInputSKUInventory(index, skuKey, modifiedText, type, question);
    }
  };

  handleToggleSKUComment = (skuKey: string) => {
    this.setState((prevState: { _skuCommentVisible: { [skuKey: string]: boolean } }) => {
      prevState._skuCommentVisible[skuKey] = !prevState._skuCommentVisible[skuKey];
      return {
        _skuCommentVisible: prevState._skuCommentVisible
      };
    });
  };

  handleConfirmEditPhoto = (photoIndex: number, skuKey?: string, currentConditionIndex?: number | string, props: any) =>
    this.props.onConfirmEditPhoto(photoIndex, skuKey, currentConditionIndex, props);

  handleActivateImagePicker = (currentConditionIndex?: number | string, props?: any) =>
    this.props.onActivateImagePicker(currentConditionIndex, props);

  handleToggleModal = (index: number) => {
    this.setState({
      _showModal: {
        ...this.state._showModal,
        [index]: !this.state._showModal![index]
      }
    });
  };

  handleSetRemedies = (index: number, text: string, currentConditionIndex?: number | string) =>
    this.props.onSetRemedies(index, text, currentConditionIndex);

  // eslint-disable-next-line max-len
  handleInputCompetitorAnalysis = (index: number, text: string, type: string) =>
    this.props.onInputCompetitorAnalysis(index, text, type);

  render() {
    const { questions } = this.props;

    return (
      <Swiper
        loop={false}
        scrollEnabled={false}
        showsButtons={false}
        showsPagination={false}
        nextButton={<Icon size={25} style={styles.buttonText} name="chevron-right" />}
        prevButton={<Icon size={25} style={styles.buttonText} name="chevron-left" />}
        loadMinimal={true}
        loadMinimalSize={20}
        loadMinimalLoader={<ActivityIndicator size="small" color="#3cd070" />}
        style={styles.swiper}
        index={this.state._initialQuestionIndex}
        onIndexChanged={this.handleQuestionIndexChange}
        ref={this.swiper}
      >
        {questions.map((question, index) => {
          return (
            <QuestionnaireSwiperContainer
              key={index}
              language={this.props.language}
              query={this.state.query}
              questions={this.props.questions}
              question={question}
              skuQuery={this.props.skuQuery}
              index={index}
              showModal={this.state._showModal}
              keyboardShown={this.state._keyboardShown}
              editingPhotoIndex={this.props.editingPhotoIndex}
              allowAuditorStockIn={this.props.allowAuditorStockIn}
              currentQuestionIndex={this.props.currentQuestionIndex}
              currentInventory={this.props.currentInventory}
              hasVideoAttachment={this.props.hasVideoAttachment}
              allowRecommendation={this.props.allowRecommendation}
              inventoryIn={this.state.inventoryIn}
              inventoryFinal={this.state.inventoryFinal}
              isShowQuestionTutorialModal={this.props.isShowQuestionTutorialModal}
              isShowTooltipNA={this.props.isShowTooltipNA}
              isTutorialMode={this.props.isTutorialMode}
              calibratingSKU={this.props.calibratingSKU}
              siteSKUs={this.props.siteSKUs}
              skuIn={this.state.skuIn}
              skuFinal={this.state.skuFinal}
              skuCommentVisible={this.state._skuCommentVisible}
              scrollRef={this.scrolls[index]}
              commentInputRef={this.commentInputs[index] || null}
              numberInputRef={this.numberInputs[index] || null}
              inventoryCardRefs={this.inventoryCards}
              competitorAnalysisInputRef={this.competitorAnalysisInputs}
              tutorialQuestionType={this.props.tutorialQuestionType}
              handleToggleSKUComment={this.handleToggleSKUComment}
              handleNavRecordVideo={this.handleNavRecordVideo}
              handleToggleModal={this.handleToggleModal}
              handleNavReference={this.handleNavReference}
              handleActivateImagePicker={this.handleActivateImagePicker}
              handleSetRemedies={this.handleSetRemedies}
              handleConfirmEditPhoto={this.handleConfirmEditPhoto}
              handleNavPlaybackVideo={this.handleNavPlaybackVideo}
              handleNavCreateCompany={this.handleNavCreateCompany}
              handleSelectChoice={this.handleSelectChoice}
              handleSelectWeightedChoice={this.handleSelectWeightedChoice}
              handleSelectChecklist={this.handleSelectChecklist}
              handleInputComment={this.handleInputComment}
              handleTextInputFocused={this.handleTextInputFocused}
              handleTextInputBlurred={this.handleTextInputBlurred}
              handleInputDecimal={this.handleInputDecimal}
              handleNumberInputFocused={this.handleNumberInputFocused}
              handleNumberInputBlurred={this.handleNumberInputBlurred}
              handleInputScore={this.handleInputScore}
              handleClearSKUQuery={this.handleClearSKUQuery}
              handleInputSKUQuery={this.handleInputSKUQuery}
              handleInputInventory={this.handleInputInventory}
              handleInputSKUInventory={this.handleInputSKUInventory}
              handleInventoryInputFocused={this.handleInventoryInputFocused}
              handleInventoryInputBlurred={this.handleInventoryInputBlurred}
              handleInputCompetitorAnalysis={this.handleInputCompetitorAnalysis}
              handleCompetitorAnalysisInputFocused={this.handleCompetitorAnalysisInputFocused}
              handleCompetitorAnalysisInputBlurred={this.handleCompetitorAnalysisInputBlurred}
              handleShowTutorialQuestionModal={this.props.handleShowTutorialQuestionModal}
              handleChangeTutorialQuestionType={this.props.handleChangeTutorialQuestionType}
              handleShowTooltipNA={this.props.handleShowTooltipNA}
              handleDismissTutorialQuestionModal={this.props.handleDismissTutorialQuestionModal}
              handleDismissTutorialMode={this.props.handleDismissTutorialMode}
              handleUploadDocuments={this.props.handleUploadDocuments}
              handleEditUploadFile={this.props.handleEditUploadFile}
              showCamera={this.props.showCamera}
              handleTakePhoto={this.props.handleTakePhoto}
              handleCloseCamera={this.props.handleCloseCamera}
            />
          );
        })}
      </Swiper>
    );
  }
}

type ModalState = {
  [index: number]: boolean;
};

type QuestionnaireSwiperState = {
  _showModal: null | ModalState;
};

type QuestionnaireSwiperProps = {
  isShowTooltipNA: boolean;
  allowAuditorStockIn: boolean | undefined;
  allowRecommendation: boolean | undefined;
  currentInventory: SKUInventory | null;
  calibratingSKU: { [skuKey: string]: boolean } | null;
  siteSKUs: { [sku: string]: SiteSKU & { skuDetail: OrganizationSKU } } | null | undefined;
  skuQuery: string;
  // isEditingPhoto exists purely to track the photo editing, to re-render
  editingPhotoIndex: number | null;
  questions: Question[];
  /** state for showing tutorial modal on question */
  isShowQuestionTutorialModal: boolean;
  /** active question type , for showing modal based on question type */
  tutorialQuestionType: string;
  handleUploadFile: (currentConditionIndex?: number | string, props?: any) => void;
  showCamera: boolean;
  handleTakePhoto: (currentConditionIndex?: number | string, props?: any) => void;
  handleEditUploadFile: (currentConditionIndex?: number | string, props?: any) => void;
  onNavCreateCompany: () => void;
  onQuestionIndexChanged: () => any;
  onSKUQueryChanged: (text: string) => void;
  onNavReference: (url: string) => void;
  onNavRecordVideo: (condition?: string | number, props?: any) => void;
  onNavPlaybackVideo: (uri: string, videoIndex: number, condition?: string | number) => void;
  onSelectChoice: (index: number, choice: string, condition?: string | number) => void;
  onSelectWeightedChoice: (index: number, choice: string, condition?: string | number) => void;
  onSelectChecklist: (index: number, choice: string, condition?: string | number) => void;
  onInputAnswer: (index: number, text: string, condition?: string | number) => void;
  onInputComment: (index: number, text: string, condition?: string | number) => void;
  onInputNumber: (index: number, text: string, condition?: string | number) => void;
  onInputScore: (index: number, score: number, condition?: string | number) => void;
  onInputDecimal: (index: number, score: string, condition?: string | number) => void;
  onSetRemedies: (index: number, text: string, condition?: string | number) => void;
  onInputInventory: (index: number, text: string, type: 'in' | 'out' | 'final', question: Question) => void;
  onInputSKUInventory: (
    index: number,
    skuKey: string,
    input: any,
    type: 'in' | 'out' | 'final' | 'photos' | 'comment',
    question: Question
  ) => void;
  onInputCompetitorAnalysis: (index: number, text: string, type: string) => void;
  onConfirmEditPhoto: (photoIndex: number, skuKey?: string, condition?: string | number) => void;
  onActivateImagePicker: (condition?: string | number, props?: any) => void;
  currentQuestionIndex: number | null;
  drawerQuestionIndex: number | null;
  isConnected: boolean;
  language: 'en' | 'id';
  hasVideoAttachment: boolean | undefined;
  isTutorialMode: boolean;
  /** Show or hide tutorial modal on question */
  handleShowTutorialQuestionModal: (isShow: boolean) => void;
  /** Set question type of tutorial */
  handleChangeTutorialQuestionType: (type: string) => void;

  /** Handle show or close tooltip NA answer*/
  handleShowTooltipNA: (value: boolean) => void;

  /** close tutorial modal on question */
  handleDismissTutorialQuestionModal: () => void;

  /** end tutorial mode */
  handleDismissTutorialMode: () => void;

  handleCloseCamera: () => void;
} & ConnectedDispatch;

const connectedQuestionnaireSwiper = connect((state: RootState) => {
  let selectedCalibratingSKU = state.report.selectedReportCalibratingSKU;

  if (state.tutorial.isTutorialMode) {
    selectedCalibratingSKU = { '-skuTutorial': true };
  }
  return {
    currentQuestionIndex: state.report.questionIndex,
    drawerQuestionIndex: state.report.questionIndexTransition,
    isConnected: state.network.isConnected,
    language: state.firebase.profile.language || 'en',
    calibratingSKU: selectedCalibratingSKU
  };
})(QuestionnaireSwiper);

export default connectedQuestionnaireSwiper;

const styles = StyleSheet.create({
  swiper: {
    flexGrow: 1
  },
  question: {
    flexGrow: 1,
    width
  },
  questionContainer: {
    flexGrow: 1,
    width
  },
  inventoryRowFinal: {
    marginBottom: 2.5
  },
  buttonText: {
    borderStyle: 'solid',
    borderColor: '#3CD070',
    fontSize: 45,
    paddingHorizontal: 10,
    color: '#3CD070'
  }
});
