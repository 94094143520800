import React, { useEffect } from 'react';
import firebase from 'firebase';
import { View, Image, Platform, StyleSheet, ScrollView, RefreshControl, TouchableOpacity } from 'react-native';

// constant
import { mapsConfig } from '../../../../constants/gmaps';

// types
import { OutletContainerProps } from './type';

// components
import OutletScheduleFilter from './OutletScheduleFilter';
import OutletDetail from './OutletDetail';
import ScheduledQuestionnaire from './ScheduledQuestionnaire/ScheduledQuestionnaire';

// utils
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../../../utils/screensize';
import theme from '../../../../constants/theme';

const OutletContainer = (props: OutletContainerProps) => {
  const {
    countReportToday,
    countReportYesterDay,
    featureAccessGeo,
    isAllowCalibration,
    selectedSite,
    reportCompleted,
    reportScheduled,
    scheduleOptions,
    scheduleIsLoading,
    searchFilter,
    scheduleDateString,
    handleShowMap,
    handleSelectSchedule,
    refreshPage,
    onCalibrateLocation,
    setSearchFilter
  } = props;

  useEffect(() => {
    firebase.analytics().logEvent('impression_map', { category: 'site_details_page' });
  }, []);

  return (
    <>
      <View style={styles.root}>
        <ScrollView
          contentContainerStyle={{ paddingBottom: 80 }}
          refreshControl={
            <RefreshControl onRefresh={refreshPage} tintColor={theme.colors.primary} refreshing={scheduleIsLoading} />
          }
          scrollIndicatorInsets={{ right: 1 }}
          stickyHeaderIndices={[1]}
        >
          <OutletDetail
            isAllowCalibration={isAllowCalibration}
            siteName={selectedSite?.name}
            siteAddress={selectedSite?.address}
            featureAccessGeo={featureAccessGeo}
            onCalibrateLocation={onCalibrateLocation}
          />
          <OutletScheduleFilter
            countReportToday={countReportToday}
            searchFilter={searchFilter}
            scheduleDateString={scheduleDateString}
            siteName={selectedSite?.name}
            setSearchFilter={setSearchFilter}
            reportCompleted={reportCompleted}
            reportScheduled={reportScheduled}
          />
          <ScheduledQuestionnaire
            scheduleOptions={scheduleOptions}
            searchFilter={searchFilter}
            scheduleIsLoading={scheduleIsLoading}
            handleSelectSchedule={handleSelectSchedule}
            countReportToday={countReportToday}
            countReportYesterDay={countReportYesterDay}
          />
        </ScrollView>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  image: {
    flex: 1,
    height: 100,
    width: '100%'
  },
  root: {
    height: SCREEN_HEIGHT,
    marginHorizontal: 'auto',
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH,
    backgroundColor: theme.colors.light,
    flex: 1
  },
  mapContainer: {
    width: '100%',
    height: 100,
    maxHeight: 0.2 * SCREEN_HEIGHT
  }
});

export default OutletContainer;
