import { ActionType, action } from 'typesafe-actions';
import { ThunkResult } from '../../typing/types';
import firebase from 'firebase';

export const SET_TUTORIAL_MODE = '@tutorial/SET_TUTORIAL_MODE';
export const SET_QUESTION_TYPE = '@tutorial/SET_QUESTION_TYPE';
export const SHOW_QUESTION_MODAL = '@tutorial/SHOW_TUTORIAL_QUESTION_MODAL';

export const setTutorialMode = (value: boolean) => action(SET_TUTORIAL_MODE, { value });
export const setTutorialQuestionType = (value: string) => action(SET_QUESTION_TYPE, { value });
export const showTutorialQuestionModal = (value: boolean) => action(SHOW_QUESTION_MODAL, { value });

export type TutorialState = {
  isTutorialMode: boolean;
  tutorialQuestionType: string;
  showModalTutorial: boolean;
};

const initialState: TutorialState = {
  isTutorialMode: false,
  tutorialQuestionType: '',
  showModalTutorial: false
};

export const tutorialActions = {
  setTutorialMode,
  setTutorialQuestionType,
  showTutorialQuestionModal
};

export const getStatusDoTutorial = (): ThunkResult => async (dispatch, getState) => {
  const state = getState();
  const uid = state.firebase.auth.uid;

  try {
    // hide tutorial for now, site details page not support yet
    // const userDetail: any = await // firebase.database().ref(`/user`).child(uid).once('value');
    // const clearedTutorial = userDetail.val().clearedTutorial;
    // // check the user is already do tutorial before, and skip for existing user
    // if (userDetail.val().clearedTutorial !== undefined) {
    //   dispatch(tutorialActions.setTutorialMode(!clearedTutorial));
    // } else if (userDetail.val().clearedTutorial === undefined) {
    //   await dispatch(dismissTutorialMode);
    //   await dispatch(tutorialActions.setTutorialMode(false));
    // }
  } catch (err) {
    dispatch(tutorialActions.setTutorialMode(false));
  }
};

export const dismissTutorialMode = (): ThunkResult => async (dispatch, getState) => {
  const state = getState();
  const uid = state.firebase.auth.uid;

  try {
    await dispatch(tutorialActions.setTutorialMode(false));

    const userTutorialRef = `/user/${uid}`;
    await firebase.database().ref(userTutorialRef).update({ clearedTutorial: true });
  } catch (err) {
    dispatch(tutorialActions.setTutorialMode(false));
  }
};

export const tutorialThunks = {
  getStatusDoTutorial,
  dismissTutorialMode
};

export type TutorialAction = ActionType<typeof tutorialActions>;

export function tutorialReducer(state: TutorialState = initialState, incomingAction: TutorialAction): TutorialState {
  switch (incomingAction.type) {
    case SHOW_QUESTION_MODAL: {
      return {
        ...state,
        showModalTutorial: incomingAction.payload.value
      };
    }
    case SET_TUTORIAL_MODE: {
      return {
        ...state,
        isTutorialMode: incomingAction.payload.value
      };
    }
    case SET_QUESTION_TYPE: {
      return {
        ...state,
        tutorialQuestionType: incomingAction.payload.value
      };
    }
    default:
      return state;
  }
}
