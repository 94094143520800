import i18next from 'i18next';

import { AuthFormValidation } from '../Auth';

/** Processes Firebase error codes and passes back processed form validation object
 * @param error - the error object
 */
export default function generateAuthErrorMessage(error: any, isOtp: boolean): AuthFormValidation {
  const defaultValidation: AuthFormValidation = {
    email: true,
    password: true,
    phoneNumber: true,
    emailOrPhone: true,
    code: true,
    errorText: '',
    errorTextBottom: '',
    isNeedAccessFederated: false
  };

  if (error.code) {
    switch (error.code) {
      case 'auth/invalid-email':
        defaultValidation.emailOrPhone = false;
        defaultValidation.email = false;
        defaultValidation.errorText = i18next.t('auth:validation.invalidEmail');
        return defaultValidation;
      case 'auth/email-already-in-use':
        defaultValidation.emailOrPhone = false;
        defaultValidation.email = false;
        defaultValidation.errorText = i18next.t('auth:validation.existEmail');
        return defaultValidation;
      case 'auth/weak-password':
        defaultValidation.password = false;
        defaultValidation.errorText = i18next.t('auth:validation.weakPassword');
        return defaultValidation;
      case 'auth/user-disabled':
        return defaultValidation;
      case 'auth/user-not-found':
        defaultValidation.emailOrPhone = false;
        defaultValidation.email = false;
        defaultValidation.password = false;
        defaultValidation.errorText = i18next.t('auth:validation.askPermisionNimbly');
        return defaultValidation;
      case 'auth/wrong-password':
        defaultValidation.emailOrPhone = false;
        defaultValidation.email = false;
        defaultValidation.password = false;
        defaultValidation.errorTextBottom = '';
        defaultValidation.errorText = i18next.t('auth:validation.errorAuth');
        return defaultValidation;
      case 'auth/network-request-failed':
        defaultValidation.errorText = i18next.t('auth:validation.timeout');
        return defaultValidation;
      case 'auth/too-many-requests':
        defaultValidation.errorText = i18next.t('auth:validation.tooManyRequest');
        return defaultValidation;
      case 'auth/invalid-phone-number':
        defaultValidation.emailOrPhone = false;
        defaultValidation.errorText = i18next.t('auth:validation.invalidPhone');
        return defaultValidation;
      case 'auth/invalid-verification-code':
        defaultValidation.code = false;
        defaultValidation.errorText = i18next.t('auth:error.invalidOtp');
        return defaultValidation;
      case 'auth/session-expired':
        defaultValidation.code = false;
        defaultValidation.errorText = i18next.t('auth:error.otpExpired');
        return defaultValidation;
      case 'auth/internal-error':
        defaultValidation.email = false;
        defaultValidation.phoneNumber = false;
        defaultValidation.emailOrPhone = false;
        defaultValidation.errorText = i18next.t('auth:error.phoneError');
        return defaultValidation;
      case 'auth/unknown':
        defaultValidation.emailOrPhone = false;
        if (isOtp) {
          defaultValidation.errorText = i18next.t('auth:error.otpMaxAttempt');
          return defaultValidation;
        }
        defaultValidation.errorText = i18next.t('auth:validation.defaultValidation');
        return defaultValidation;
      default:
        // eslint-disable-next-line no-case-declarations
        const errorMessage = String(error);
        if (errorMessage.search(/\bemail was not found\b/) > -1 || errorMessage.search(/\bUNAVAILABLE\b/) > -1) {
          defaultValidation.errorText = i18next.t('auth:validation.askPermisionNimbly');
          return defaultValidation;
        }

        defaultValidation.errorText = `Error: ${error.message}`;
        return defaultValidation;
    }
  } else if (error.message) {
    defaultValidation.errorText = `Error: ${error.message}`;
    return defaultValidation;
  } else {
    defaultValidation.errorText = i18next.t('auth:validation.defaultValidation');
    return defaultValidation;
  }
}
