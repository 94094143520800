import React from 'react';
import { QuestionnaireQuestionProps } from './QuestionnaireQuestion';
import { StyleSheet } from 'react-native';

import Text from '../global/Text';

import fontMaker from '../../utils/font';
import theme from '../../constants/theme';
import getRangeFlagColor from './utils/getRangeFlagColor';
import { TFunction } from 'i18next';

export type QuestionnaireQuestionScoreAnswerProps = Pick<
  QuestionnaireQuestionProps,
  'question' | 'index' | 'language'
> & { t: TFunction };

const QuestionnaireQuestionRangeFlagResult = (props: QuestionnaireQuestionScoreAnswerProps) => {
  const { t } = props;
  if (props.question.answer === 'not-applicable') {
    return <Text style={[styles.rangeFlagAnswerText, styles.rangeFlagAnswerDisabled]}>Not Applicable</Text>;
  }
  if (isNaN(Number(props.question.answer)) || props.question.answer === '') {
    return null;
  }
  const flag = getRangeFlagColor(props.question);
  let rangeFlagStyle: any;
  let answerFlag = '';
  switch (flag) {
    case 'red-flag':
      rangeFlagStyle = styles.rangeFlagAnswerTextRed;
      answerFlag = t('questionnaire:question.rangeFlag.redFlag');
      break;
    case 'yellow-flag':
      rangeFlagStyle = styles.rangeFlagAnswerTextYellow;
      answerFlag = t('questionnaire:question.rangeFlag.yellowFlag');
      break;
    case 'green-flag':
      rangeFlagStyle = styles.rangeFlagAnswerTextGreen;
      answerFlag = t('questionnaire:question.rangeFlag.greenFlag');
      break;
  }
  return (
    <Text style={styles.rangeFlagAnswerText}>
      {props.t('questionnaire:question.rangeFlag.answerWithin')}{' '}
      <Text testID={'flagResult'} style={rangeFlagStyle}>
        {answerFlag}
      </Text>{' '}
      {t('questionnaire:question.rangeFlag.range')}
    </Text>
  );
};

export default QuestionnaireQuestionRangeFlagResult;

const styles = StyleSheet.create({
  rangeFlagAnswerText: {
    marginHorizontal: 10,
    marginBottom: 15,
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 16,
    textAlign: 'center'
  },
  rangeFlagAnswerDisabled: {
    color: theme.colors.gray
  },
  rangeFlagAnswerTextGreen: {
    color: '#009e43'
  },
  rangeFlagAnswerTextYellow: {
    color: '#ffa040'
  },
  rangeFlagAnswerTextRed: {
    color: '#f93c1c'
  },
  rangeFlagAnswerLabel: {
    marginHorizontal: 10,
    ...fontMaker({ weight: 'Regular' }),
    fontSize: 16
  }
});
