import { Question, SiteSKU, OrganizationSKU } from '../../../utils/classes';
import { countIsInvalid } from '../../../utils/number';

/**
 * Check if current SKU has already been calibrated to prevent double submission
 * @param skuKey current sku key
 * @param input input data value
 * @param question selected question
 * @param type in/out/final/photos/comment
 * @param siteSKUs list of active site skus
 * @param calibratingSKU calibratin sku from props
 */
function calibrateSKU(
  skuKey: string,
  input: any,
  question: Question,
  type: 'in' | 'out' | 'final' | 'photos' | 'comment',
  siteSKUs: { [sku: string]: SiteSKU & { skuDetail: OrganizationSKU } } | null | undefined,
  calibratingSKU: { [skuKey: string]: boolean } | null
) {
  if (!siteSKUs) {
    return { status: -1 };
  }

  const skuProfile = siteSKUs[skuKey] ? siteSKUs[skuKey].skuDetail : null;
  if (!skuProfile) {
    return { status: -1 };
  }

  if (type === 'in' || type === 'final') {
    const startInventory = siteSKUs && siteSKUs[skuKey] ? siteSKUs[skuKey].stock : 0;
    const isInCalibratingMap = calibratingSKU && Object.keys(calibratingSKU).length ? !!calibratingSKU[skuKey] : false;
    const isCalibrating = startInventory === 0 && isInCalibratingMap;
    const isValid = !countIsInvalid(input);
    const parsedText = input === '' ? -1 : parseFloat(input);

    const skuInventoryQuestion = question.skuInventory![skuKey];

    let inInventory = 0;
    let finalInventory = 0;

    let realCurrentIn = -1;
    let realCurrentFinal = -1;

    switch (type) {
      case 'in':
        realCurrentIn = parsedText;
        realCurrentFinal = skuInventoryQuestion.final;

        inInventory = isValid ? parsedText : 0;
        finalInventory = skuInventoryQuestion.final === -1 ? 0 : skuInventoryQuestion.final;
        break;
      case 'final':
        realCurrentIn = skuInventoryQuestion.in;
        realCurrentFinal = parsedText;

        inInventory = skuInventoryQuestion.in === -1 ? 0 : skuInventoryQuestion.in;
        finalInventory = isValid ? parsedText : 0;
        break;
      default:
        break;
    }

    const startCountOut =
      // necessary field is being filled
      realCurrentIn >= 0 && realCurrentFinal >= 0;

    let intermediate = '0';
    if (
      (skuProfile.inOrigin === 'onsite' && startCountOut) ||
      (skuProfile.inOrigin === 'po' && !isCalibrating && startCountOut)
    ) {
      intermediate = (startInventory + inInventory - finalInventory).toFixed(2);
    } else {
      intermediate = skuInventoryQuestion.out.toFixed(2);
    }

    const inventoryOutNumber = parseFloat(intermediate);
    return {
      status: 1,
      isValid: isValid,
      inventoryOutNumber: inventoryOutNumber,
      parsedText: parsedText
    };
  } else {
    return { status: 0 };
  }
}

export default calibrateSKU;
