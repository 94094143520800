import { action, ActionType } from 'typesafe-actions';
import { Reducer } from 'redux';

import { LanguageType } from '../../constants/Languages';
import { AUTH_MODE } from '../../constants/Constants';

export const SET_AUTHMODE = '@landing/SET_AUTHMODE';
export const SET_LANGUAGE = '@landing/SET_LANGUAGE';
export const SET_MODAL_LANGUAGE_VISIBLE = '@landing/SET_MODAL_LANGUAGE_VISIBLE';
export const RESET = '@landing/RESET';

/**
 * Set the mode of the auth component
 * @param mode
 */
export const setAuthMode = (mode: AUTH_MODE) => action(SET_AUTHMODE, { mode });

/**
 * Set the selected language option
 * @param language
 */
export const setLanguage = (language: LanguageType) => action(SET_LANGUAGE, { language });

/**
 * Set the selected language option
 * @param visible
 */
export const setModalLanguageVisible = (visible: boolean) => action(SET_MODAL_LANGUAGE_VISIBLE, { visible });

/**
 * Reset landing state
 */
const reset = () => action(RESET);

export const landingActions = { setAuthMode, setLanguage, setModalLanguageVisible, reset };

export type LandingAction = ActionType<typeof landingActions>;
export type LandingState = {
  readonly authMode: AUTH_MODE;
  readonly language: LanguageType;
  readonly modalLanguageVisible: boolean;
};

const initialState: LandingState = { authMode: AUTH_MODE.LOGIN, language: 'en', modalLanguageVisible: false };

export const landingReducer: Reducer<LandingState, LandingAction> = (state = initialState, incomingAction) => {
  switch (incomingAction.type) {
    case SET_AUTHMODE:
      return {
        ...state,
        authMode: incomingAction.payload.mode
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: incomingAction.payload.language
      };
    case SET_MODAL_LANGUAGE_VISIBLE:
      return {
        ...state,
        modalLanguageVisible: incomingAction.payload.visible
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};
