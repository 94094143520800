export const FETCH_QUESTIONNAIRES_INDEX_REQUEST = 'questionnaire/FETCH_QUESTIONNAIRES_INDEX_REQUEST';
export const FETCH_QUESTIONNAIRES_INDEX_SUCCESS = 'questionnaire/FETCH_QUESTIONNAIRES_INDEX_SUCCESS';
export const FETCH_QUESTIONNAIRES_INDEX_FAILURE = 'questionnaire/FETCH_QUESTIONNAIRES_INDEX_FAILURE';

export const GET_POPULATED_QUESTIONNAIRES_REQUEST = 'questionnaire/GET_POPULATED_QUESTIONNAIRES_REQUEST';
export const GET_POPULATED_QUESTIONNAIRES_SUCCESS = 'questionnaire/GET_POPULATED_QUESTIONNAIRES_SUCCESS';
export const GET_POPULATED_QUESTIONNAIRES_FAILURE = 'questionnaire/GET_POPULATED_QUESTIONNAIRES_FAILURE';

export const FETCH_QUESTIONNAIRES_REQUEST = '@questionnaires/FETCH_QUESTIONNAIRES_REQUEST';
export const FETCH_QUESTIONNAIRES_SUCCESS = '@questionnaires/FETCH_QUESTIONNAIRES_LOADING';
export const FETCH_QUESTIONNAIRES_FAILURE = '@questionnaires/FETCH_QUESTIONNAIRES_FAILURE';

export const SET_LOADING = '@questionnaires/SET_LOADING';
