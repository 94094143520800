import { action, createReducer } from 'typesafe-actions';
import { SiteReportState } from './type';
import { ActionType } from 'typesafe-actions';
import * as actions from './siteReport.action';
import * as actionTypes from './siteReport.actionType';
import moment from 'moment';

export type SiteReportAction = ActionType<typeof actions>;

const initialState: SiteReportState = {
  reports: [],
  customStart: null,
  customEnd: null,
  startDate: moment(),
  endDate: moment(),
  sortDirection: 'desc',
  filterQuery: '',
  totalReports: 0,
  pageIndex: 1,
  siteReportLoading: false,
  error: null,
  dateType: 'today',
  customStartTemp: null,
  customEndTemp: null,
  startDateTemp: moment(),
  endDateTemp: moment(),
  sortDirectionTemp: 'desc',
  dateTypeTemp: 'today'
};

export const siteReportReducer = createReducer<SiteReportState, SiteReportAction>(initialState)
  .handleAction(actions.fetchSiteReportsAsync.request, state => ({
    ...state,
    siteReportLoading: true
  }))
  .handleAction(actions.fetchSiteReportsAsync.success, (state, action) => ({
    ...state,
    siteReportLoading: false,
    totalReports: action.payload.total,
    reports: action.payload.reports
  }))
  .handleAction(actions.fetchSiteReportsAsync.failure, (state: any, action: any) => ({
    ...state,
    siteReportLoading: false,
    error: action.payload.error
  }))
  .handleAction(actionTypes.SET_CUSTOM_DATES as any, (state: any, action: any) => ({
    ...state,
    dateType: 'custom',
    customStart: action.payload.startDate,
    customEnd: action.payload.endDate,
    startDate: null,
    endDate: null
  }))
  .handleAction(actionTypes.SET_DATES as any, (state: any, action: any) => ({
    ...state,
    dateType: action.payload.periodType,
    startDate: action.payload.startDate,
    endDate: action.payload.endDate,
    customStart: null,
    customEnd: null
  }))
  .handleAction(actionTypes.SET_FILTER_QUERY as any, (state: any, action: any) => ({
    ...state,
    filterQuery: action.payload.text
  }))
  .handleAction(actionTypes.SET_FILTERED_SORT_DIRECTION_TEMP as any, (state: any, action: any) => ({
    ...state,
    sortDirectionTemp: action.payload.sortDirection
  }))
  .handleAction(actionTypes.RESET_SITE_REPORT_FILTER as any, (state: SiteReportState) => ({
    ...state,
    customStartTemp: null,
    customEndTemp: null,
    startDateTemp: moment(),
    endDateTemp: moment(),
    sortDirectionTemp: 'desc',
    dateTypeTemp: 'today'
  }))
  .handleAction(actionTypes.SET_BACK_TEMPORARY_REPORT as any, (state: SiteReportState) => ({
    ...state,
    customStartTemp: state.customStart ? state.customStart.format('YYYY-MM-DD') : null,
    customEndTemp: state.customEnd ? state.customEnd.format('YYYY-MM-DD') : null,
    startDateTemp: state.startDate,
    endDateTemp: state.endDate,
    sortDirectionTemp: state.sortDirection,
    dateTypeTemp: state.dateType
  }))
  .handleAction(actionTypes.APPLY_SITE_REPORT_FILTER as any, (state: any) => ({
    ...state,
    startDate: state.startDateTemp,
    endDate: state.endDateTemp,
    sortDirection: state.sortDirectionTemp,
    dateType: state.dateTypeTemp,
    customEnd: state.customEndTemp ? moment.utc(state.customEndTemp) : null,
    customStart: state.customStartTemp ? moment.utc(state.customStartTemp) : null
  }))
  .handleAction(actionTypes.SET_FILTERED_END_DATE_TEMP as any, (state: any, action: any) => ({
    ...state,
    customEndTemp: action.payload.endDate,
    dateTypeTemp: 'custom',
    startDateTemp: null,
    endDateTemp: null
  }))
  .handleAction(actionTypes.SET_FILTERED_START_DATE_TEMP as any, (state: any, action: any) => ({
    ...state,
    customStartTemp: action.payload.startDate,
    dateTypeTemp: 'custom',
    startDateTemp: null,
    endDateTemp: null
  }))
  .handleAction(actionTypes.SET_FILTERED_DATES_TEMP as any, (state: any, action: any) => ({
    ...state,
    dateTypeTemp: action.payload.periodType,
    startDateTemp: action.payload.startDate,
    endDateTemp: action.payload.endDate,
    customStartTemp: null,
    customEndTemp: null
  }))
  .handleAction(actionTypes.SET_PAGE_INDEX_REPORT as any, (state: any, action: any) => ({
    ...state,
    pageIndex: action.payload.value
  }));
