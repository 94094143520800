import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Dimensions, Image, StyleSheet, View } from 'react-native';
import theme from '../../constants/theme';
import fontMaker from '../../utils/font';
import { SCREEN_WIDTH } from '../../utils/screensize';
import Button from '../global/Button';
import ConnectionStatus from '../global/ConnectionStatus';
import Text from '../global/Text';
import QuestionnaireProgress from '../questionnaire/QuestionnaireProgress';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ErrorImage = require('../../assets/img/error.png');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const NoConnection = require('../../assets/img/no-connection.gif');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Sending = require('../../assets/img/flying-email.gif');
// eslint-disable-next-line @typescript-eslint/no-var-requires
const ReportComplete = require('../../assets/img/report-complete.gif');

export type UploadReportContainerProps = {
  /** Language to be used throughout the app */
  language: string;
  /** True if the device is connected to the internet*/
  isConnected: boolean;
  /** Number of successuflly uploaded photos */
  _photosUploadedCount: number;
  /** Number of successuflly uploaded videos */
  _videosUploadedCount: number;
  /** Number of successuflly uploaded files */
  _documentsUploadedCount: number;
  /** Number of photos that will be uploaded */
  _photosTotalCount: number;
  /** Number of videos that will be uploaded */
  _videosTotalCount: number;
  /** Number of files that will be uploaded */
  _documentsTotalCount: number;
  /** True When the transitioned has finished (no more interaction) */
  _isTransitioned: boolean;
  /** True if the report is on uploading process */
  _isUploading: boolean;
  /** True if the uploaded time reaching the timeout limit */
  _isTimeout: boolean;
  /** Flag to handle button clicked only once */
  _isBusy: boolean;
  /** Get state is in tutorial mode or not*/
  isTutorialMode: boolean;
  /** Function - Upload the report including photos, videos, and signatures to firebase */
  uploadReport: () => void;
  /** Event - Handler for upload later button to postponed the upload (no internet connections) */
  handleUploadLater: () => void;

  timeRemaining: number;
};
const UploadReportContainer = (props: UploadReportContainerProps) => {
  const { isConnected, _isUploading, _isTimeout, _isBusy, isTutorialMode } = props;
  const { t } = useTranslation(['uploadReport, common']);
  if (!props._isTransitioned) {
    return (
      <View style={styles.loading}>
        <ActivityIndicator color={theme.colors.primary} size="large" />
        <Text>{t('uploadReport:container.loading')}</Text>
      </View>
    );
  }

  return (
    <View style={styles.root}>
      <ConnectionStatus />
      <View style={styles.headerContainer}>
        <Text style={styles.header}>
          {_isTimeout
            ? t('uploadReport:container.failUpload')
            : _isUploading
            ? t('uploadReport:container.uploadingCapital')
            : t('uploadReport:container.reportSaved')}
        </Text>
      </View>
      <View style={styles.imageContainer}>
        <Image
          style={styles.image}
          source={_isTimeout ? ErrorImage : _isUploading ? Sending : isConnected ? ReportComplete : NoConnection}
        />
      </View>
      <View style={[styles.card]}>
        <View style={styles.networkStatusContainer}>
          <Text style={[styles.textStatus, isConnected ? styles.textSuccess : styles.textWarning]}>
            {_isTimeout
              ? t('uploadReport:container.uploadFailed')
              : isConnected
              ? t('uploadReport:container.online')
              : t('uploadReport:container.online')}
          </Text>
        </View>
        <View style={styles.uploadProgressText}>
          {_isUploading && (
            <React.Fragment>
              <Text style={styles.textTitle}>{t('uploadReport:container.inProgress')}</Text>
              <View style={styles.progressContainer}>
                <QuestionnaireProgress
                  completed={props._photosUploadedCount + props._videosUploadedCount + props._documentsUploadedCount}
                  total={props._photosTotalCount + props._videosTotalCount + props._documentsTotalCount}
                  language={props.language}
                />
              </View>
            </React.Fragment>
          )}
        </View>
      </View>
      {_isBusy ? (
        <View style={[styles.loadingContainer, styles.tabShadow]}>
          <ActivityIndicator size="small" color="#3cd070" />
        </View>
      ) : (
        <View style={[styles.buttonContainer, styles.tabShadow]}>
          {isConnected ? (
            <Button
              disabled={_isUploading}
              style={styles.button}
              onPress={props.uploadReport}
              title={
                _isTimeout
                  ? t('uploadReport:container.retryUpload')
                  : _isUploading
                  ? t('uploadReport:container.uploading')
                  : t('uploadReport:container.uploadReport')
              }
            />
          ) : (
            <Button
              style={styles.button}
              backgroundColor="#c43e00"
              title={t('uploadReport:container.uploadLaterCapital')}
              onPress={props.handleUploadLater}
            />
          )}
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    alignItems: 'center'
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerContainer: {
    marginTop: 40
  },
  header: {
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 22,
    lineHeight: 24,
    color: '#535353'
  },
  centerText: {
    color: 'black',
    fontSize: 16
  },
  imageContainer: {
    width: Dimensions.get('window').width,
    height: Dimensions.get('window').height * 0.4
  },
  image: {
    width: '100%',
    height: '100%'
  },
  card: {
    width: 600,
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 12.5,
    marginBottom: 12.5,
    paddingVertical: 12,
    paddingHorizontal: 12,
    borderRadius: 10,
    backgroundColor: '#fff',
    // Android settings
    elevation: 5,
    // iOS settings
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: 5 }
  },
  networkStatusContainer: {
    width: '100%',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  uploadProgressText: {
    paddingVertical: 6,
    alignItems: 'center'
  },
  textStatus: {
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 16
  },
  textSuccess: {
    color: '#3cd070'
  },
  textWarning: {
    color: '#c43e00'
  },
  textTitle: {
    fontSize: 18,
    color: '#535353',
    paddingVertical: 4
  },
  textSubtitle: {
    fontSize: 14,
    color: '#535353',
    paddingVertical: 4
  },
  progressContainer: {
    paddingVertical: 12
  },
  loadingContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    minHeight: 64,
    height: Dimensions.get('window').height * 0.1,
    paddingVertical: 10,
    backgroundColor: '#fff',
    zIndex: 2
  },
  buttonContainer: {
    position: 'absolute',
    bottom: 0,
    justifyContent: 'center',
    minHeight: 64,
    // height: Dimensions.get('window').height * 0.1,
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH,
    paddingVertical: 10,
    marginHorizontal: 'auto',
    backgroundColor: '#fff',
    zIndex: 2
  },
  tabShadow: {
    borderTopWidth: 0,
    /* Elevation shadow property for bottomTabBar (Android) */
    elevation: 10,
    /* Shadow property for bottomTabBar (iOS) */
    shadowColor: '#919191',
    shadowRadius: 6,
    shadowOpacity: 0.5,
    shadowOffset: { width: 1, height: 1 }
  },
  button: {
    width: 300,
    marginHorizontal: 'auto'
  }
});

export default UploadReportContainer;
