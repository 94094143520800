import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIsFocused } from '@react-navigation/native';
import { useTranslation } from 'react-i18next';
import {
  Platform,
  StyleSheet,
  View,
  ScrollView,
  TextInput,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableWithoutFeedbackProps,
  Keyboard,
  LayoutAnimation
} from 'react-native';

import { reportActions } from '../../store/reducers/report';
import { RootState } from '../../store/reducers';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import QuestionnaireDrawerCategory from './QuestionnaireDrawerCategory';
import Text from '../global/Text';
import theme from '../../constants/theme';
import fontMaker from '../../utils/font';
import { questionComplete, questionSKUComplete } from '../../utils/report';
import { hasMatchingString } from '../../utils/file';
import { Report, ReportSection, Question, SKUInventory, SiteInventory } from '../../utils/classes';
import { InjectedSite, PopulatedSiteSKU } from '../../typing/types';
import { SKUsTutorialData } from './assets/SKUsTutorialData';
import { LanguageType } from '../../constants/Languages';

type QuestionnaireDrawerProps = {
  isVisible: boolean;
};

const QuestionnaireDrawer = (props: QuestionnaireDrawerProps) => {
  const { t } = useTranslation(['questionnaire', 'common']);
  const language = useSelector<RootState, LanguageType>(state => state.firebase.profile.language);
  const profileColor = useSelector<RootState, string>(state => state.profiletheme.color);
  const selectedSite = useSelector<RootState, InjectedSite>(state => state.site.selectedSite! as InjectedSite);
  const selectedReport = useSelector<RootState, Report | ReportSection>(state => state.report.selectedReport!);
  const selectedReportKey = useSelector<RootState, string>(state => state.report.selectedReportKey!);
  const isTutorialMode = useSelector<RootState, boolean>(state => state.tutorial.isTutorialMode);
  const siteSKUs:
    | {
        [skuKey: string]: PopulatedSiteSKU;
      }
    | null
    | undefined = isTutorialMode
    ? SKUsTutorialData
    : useSelector<RootState, any>(
        state => state.sitedata[state.site.selectedSiteKey!] && state.sitedata[state.site.selectedSiteKey!].siteSKU
      );
  const calibratingSKU: { [skuKey: string]: boolean } | null = isTutorialMode
    ? { '-skuTutorial': true }
    : useSelector<RootState, any>(state => state.report.selectedReportCalibratingSKU);
  const latestInventory: SiteInventory | null = useSelector<RootState, any>(
    state => state.firebase.data.latestInventory
  );
  const questionIndex = useSelector<RootState, number>(state => state.report.questionIndex);
  const isFocused = useIsFocused();
  const dispatch = useDispatch();

  const [query, setQuery] = React.useState('');

  const handleChangeIndex = (index: number) => {
    Keyboard.dismiss();
    LayoutAnimation.configureNext(
      LayoutAnimation.create(120, LayoutAnimation.Types.linear, LayoutAnimation.Properties.scaleXY)
    );
    if (questionIndex !== index) {
      dispatch(reportActions.setQuestionIndexWithDrawer(index));
    }
  };

  const handleTextInput = (text: string) => {
    setQuery(text);
  };

  const clearTextInput = () => {
    setQuery('');
  };

  // Retrieves the latest inventory from the Firebase data (from a selection of 2)
  const getCurrentInventory = (): SKUInventory | null => {
    if (!selectedSite.hasInventory) {
      return null;
    }
    if (!latestInventory) {
      return null;
    }
    const keys = Object.keys(latestInventory).sort();
    if (keys.length === 1 && latestInventory[selectedReportKey]) {
      return null;
    }
    if (keys.length === 2) {
      if (keys[1] === selectedReportKey) {
        return latestInventory[keys[0]];
      }
      return latestInventory[keys[1]];
    }
    return null;
  };

  const _renderQuestion = (question: Question, qIndex: number, index: number) => {
    const currentInventory = getCurrentInventory();
    const startInventory =
      currentInventory && currentInventory[question.sku] ? currentInventory[question.sku].final : 0;
    let inventoryOutNumber = 0;
    if (question.inventory) {
      const inInventory = question.inventory.in === -1 ? 0 : question.inventory.in;
      const finalInventory = question.inventory.final === -1 ? 0 : question.inventory.final;
      inventoryOutNumber = startInventory + inInventory - finalInventory;
    }
    return (
      <React.Fragment key={`qf-${index + 1}`}>
        <View testID={'question'} key={`question-${index + 1}`} style={styles.question}>
          <Touchable onPress={() => handleChangeIndex(index)} testID={`question-index-${index + 1}`}>
            <View style={styles.questionContent}>
              <View testID={`check-box-${index + 1}`} style={styles.checkbox}>
                {(question.type !== 'inventory-v2' && questionComplete(question, inventoryOutNumber)) ||
                (siteSKUs && calibratingSKU != null && questionSKUComplete(question, siteSKUs, calibratingSKU)) ? (
                  <Icon name="check" size={26} style={styles.checkMark} />
                ) : null}
              </View>
              <View style={styles.textContainer}>
                <Text
                  style={[qIndex === index ? { ...styles.textActive, color: profileColor } : styles.text]}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {' '}
                  Q{index < 10 ? `0${index + 1}` : index + 1}
                  {`: ${question.type === 'inventory' ? question.sku : question.content}`}
                </Text>
              </View>
            </View>
          </Touchable>
        </View>
        {question.isConditional &&
          question.conditionalQuestion &&
          question.conditionalQuestion.conditions[question.answer] &&
          question.conditionalQuestion.conditions[question.answer]?.map((el: any, conditionalIndex: number) => (
            <View
              testID={'questionConditional'}
              key={`questionConditional-${conditionalIndex + 1}`}
              style={styles.question}
            >
              <Touchable
                onPress={() => handleChangeIndex(index)}
                testID={`questionConditional-index-${conditionalIndex + 1}`}
              >
                <View style={styles.questionContent}>
                  <View testID={`check-box-${index + 1}`} style={styles.checkbox}>
                    {(el.type !== 'inventory-v2' && questionComplete(el, inventoryOutNumber)) ||
                    (siteSKUs && calibratingSKU != null && questionSKUComplete(el, siteSKUs, calibratingSKU)) ? (
                      <Icon name="check" size={26} style={styles.checkMark} />
                    ) : null}
                  </View>
                  <View style={styles.textContainer}>
                    <Text
                      style={[qIndex === index ? { ...styles.textActive, color: profileColor } : styles.text]}
                      numberOfLines={1}
                      ellipsizeMode="tail"
                    >
                      {' '}
                      CQ{index + 1}.{conditionalIndex < 10 ? `0${conditionalIndex + 1}` : conditionalIndex + 1}
                      {`: ${el.type === 'inventory' ? el.sku : el.content}`}
                    </Text>
                  </View>
                </View>
              </Touchable>
            </View>
          ))}
      </React.Fragment>
    );
  };

  const _renderQuestionTabs = () => {
    const { questions } = selectedReport;
    const questionCategories: string[] = [];
    const questionTabs: any = [];
    let titleCategory = '';

    // Bool to set should all categories in drawer (at initial) be expanded or not
    const expanded = questions.length <= 50;

    questions?.forEach((question, index) => {
      if (question?.category !== titleCategory) {
        if (!query || (query && hasMatchingString(query, question?.content))) {
          titleCategory = question?.category;
          questionCategories.push(titleCategory);
          questionTabs.push([_renderQuestion(question, questionIndex, index)]);
        }
      } else {
        if (!query || (query && hasMatchingString(query, question?.content))) {
          questionTabs[questionTabs.length - 1].push(_renderQuestion(question, questionIndex, index));
        }
      }
    });

    if (questionCategories.length === 0) {
      return (
        <View style={styles.emptySearch}>
          <Text>{t('questionnaire:drawer.noQuestions', { query })}</Text>
        </View>
      );
    }

    return questionCategories?.map((category, index) => (
      <QuestionnaireDrawerCategory
        key={`category-${index}`}
        title={category}
        expanded={expanded}
        isVisible={props.isVisible}
        isFocused={isFocused}
        questions={questions}
        language={language}
        t={t}
      >
        {questionTabs[index]}
      </QuestionnaireDrawerCategory>
    ));
  };

  const _renderSearchBar = () => (
    <View style={styles.searchBox}>
      <View style={styles.searchIcon}>
        <Icon name="magnify" size={26} color={theme.colors.gray} />
      </View>
      <View style={styles.searchTextContainer}>
        <TextInput
          testID={'search-box'}
          style={styles.searchTextInput}
          placeholder={t('questionnaire:drawer.searchQuestions')}
          multiline={false}
          numberOfLines={1}
          value={query}
          onChangeText={text => handleTextInput(text)}
        />
      </View>
      {query ? (
        <Touchable testID={'clear-icon'} onPress={clearTextInput}>
          <View style={styles.clearContainer}>
            <View style={styles.clearCircle}>
              <View style={styles.clearIcon}>
                <Icon name="close" size={10} color="#fff" />
              </View>
            </View>
          </View>
        </Touchable>
      ) : null}
    </View>
  );

  return (
    <View style={[styles.root, props.isVisible ? styles.rootShown : styles.rootHidden]}>
      <ScrollView keyboardShouldPersistTaps="always" keyboardDismissMode="on-drag">
        <View style={styles.searchBar}>{_renderSearchBar()}</View>
        <View style={styles.tabs}>{_renderQuestionTabs()}</View>
      </ScrollView>
    </View>
  );
};

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
  Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

export default QuestionnaireDrawer;

const styles = StyleSheet.create({
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: '#fff',
    zIndex: 100
  },
  rootShown: {
    right: '0%'
  },
  rootHidden: {
    display: 'none'
  },
  tabs: {
    flex: 1
  },
  searchBar: {
    height: 80,
    backgroundColor: theme.colors.light,
    width: '100%',
    paddingHorizontal: 14,
    paddingTop: 18,
    paddingBottom: 16,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomColor: '#fff',
    borderBottomWidth: 1
  },
  searchBox: {
    borderRadius: 4,
    backgroundColor: '#ffffff',
    width: '100%',
    height: '100%',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },
  searchIcon: {
    width: '15%',
    alignItems: 'center'
  },
  searchTextContainer: {
    width: '75%'
  },
  searchTextInput: {
    ...fontMaker({ weight: 'Light' }),
    color: theme.colors.dark,
    height: '100%'
  },
  clearContainer: {
    width: '10%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  clearCircle: {
    width: 16,
    height: 16,
    borderRadius: 16 / 2,
    backgroundColor: theme.colors.gray,
    justifyContent: 'center',
    alignItems: 'center'
  },
  clearIcon: {
    position: 'relative'
  },
  emptySearch: { margin: 16 },
  questionContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingRight: 8
  },
  question: {
    borderBottomColor: 'silver',
    borderBottomWidth: 1
  },
  spinnerContainer: {
    width: 20,
    height: 30,
    marginHorizontal: 20,
    paddingVertical: 5
  },
  checkbox: {
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: '#535353',
    width: 20,
    height: 20,
    marginHorizontal: 20,
    paddingVertical: 5
  },
  checkMark: {
    position: 'absolute',
    top: -5,
    right: -4,
    color: '#3cd070'
  },
  textContainer: {
    flex: 1,
    paddingVertical: 15,
    paddingHorizontal: 16,
    borderLeftWidth: StyleSheet.hairlineWidth,
    borderLeftColor: '#ea3c53'
  },
  text: {
    ...fontMaker({ weight: 'Light' }),
    fontSize: 16
  },
  textActive: {
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 16,
    color: '#009e43'
  }
});
