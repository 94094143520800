// @ts-ignore
import { STB, API_URL, NIMBLY_API } from '@env';

const ENV_NIMBLY_STORAGE_BUCKET = 'gs://nimbly-bucket-asia-southeast2';

// ISSUE TRACKER
const NIMBLY_API_ISSUE_BASE = `${API_URL}/issues`;
const NIMBLY_API_ISSUES = `${NIMBLY_API_ISSUE_BASE}/issues`;
const NIMBLY_API_ISSUES_MESSAGE = `${NIMBLY_API_ISSUE_BASE}/issueMessages`;

export const NIMBLY_API_ISSUES_DETAIL = `${NIMBLY_API_ISSUES}/`;
export const NIMBLY_API_ISSUES_LIST = `${NIMBLY_API_ISSUES}/`;
export const NIMBLY_API_ISSUES_CREATE = `${NIMBLY_API_ISSUES}/create`;
export const NIMBLY_API_ISSUES_COMMENT = `${NIMBLY_API_ISSUES_MESSAGE}/create`;
export const NIMBLY_API_ISSUES_UPLOAD_PHOTO = `${NIMBLY_API_ISSUES_MESSAGE}/upload`;
export const NIMBLY_API_ISSUES_UPDATE = `${NIMBLY_API_ISSUES}/update`;
export const NIMBLY_API_ISSUES_READ_STATUS = `${NIMBLY_API_ISSUES}/readStatus`;
export const NIMBLY_API_ISSUES_READ_ALL_STATUS = `${NIMBLY_API_ISSUES}/readAll`;

// QUESTIONNAIRES
const NIMBLY_API_QUESTIONNAIRES_BASE = `${API_URL}/questionnaires`;
export const NIMBLY_API_QUESTIONNAIRES = `${NIMBLY_API_QUESTIONNAIRES_BASE}`;
export const NIMBLY_API_QUESTIONNAIRES_INDEX = `${NIMBLY_API_QUESTIONNAIRES_BASE}/questionnaireIndexes`;

// USER ACTIVITIES
const NIMBLY_API_ACTIVITIES_BASE = `${API_URL}/activities`;
export const NIMBLY_API_CREATE_ACTIVITY = `${NIMBLY_API_ACTIVITIES_BASE}/`;

// PROMOTIONS
const NIMBLY_API_PROMOTION_BASE = `${API_URL}/promotions`;
export const NIMBLY_API_PROMOTION_ALL = `${NIMBLY_API_PROMOTION_BASE}/all`;

// NPS
const NIMBLY_API_NPS_BASE = `${API_URL}/users/userNPS`;
export const NIMBLY_API_NPS_IS_ELIGIBLE = `${NIMBLY_API_NPS_BASE}/eligible`;
export const NIMBLY_API_NPS_SUBMITTED = `${NIMBLY_API_NPS_BASE}/submit`;
// MESSAGE BOARD
export const NIMBLY_API_MESSAGE_BOARD = `${API_URL}/organizations/message-boards`;

// TARGET
export const NIMBLY_API_SALES_TARGET = `${API_URL}/sales/target/current`;

// REPORTS
export const NIMBLY_API_SITE_REPORTS = `${API_URL}/reports/new-summary/site/`;

// UPLOADS
export const NIMBLY_API_UPLOAD = `${API_URL}/miscellaneous/upload-file?`;
export const NIMBLY_API_REPORT = `${API_URL}/reports/app`;
export const NIMBLY_STORAGE_BUCKET = `${ENV_NIMBLY_STORAGE_BUCKET}`;

const apiProdURL = API_URL;
const MONGO_API = NIMBLY_API;
const STORYBOOK = STB || 'false';
export { apiProdURL, MONGO_API, STORYBOOK };
