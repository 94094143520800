import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { AuthFormValidation } from '../Auth';
/**
 *
 * @error auth/argument-error Thrown if handleCodeInApp is false.
 * @error auth/invalid-email Thrown if the email address is not valid.
 * @error auth/missing-android-pkg-name An Android package name must be provided
 * if the Android app is required to be installed.
 * @error auth/missing-continue-uri A continue URL must be provided in the request.
 * @error auth/missing-ios-bundle-id An iOS Bundle ID must be provided if an App Store ID is provided.
 * @error auth/invalid-continue-uri The continue URL provided in the request is invalid.
 * @error auth/unauthorized-continue-uri The domain of the continue URL is not whitelisted.
 * Whitelist the domain in the Firebase console.
 */
export default function generateSignInWithEmailLinkError(error: any): AuthFormValidation {
  const defaultValidation: AuthFormValidation = {
    email: true,
    password: true,
    phoneNumber: true,
    emailOrPhone: true,
    code: true,
    errorText: ''
  };
  if (error.code) {
    defaultValidation.emailOrPhone = false;
    defaultValidation.email = false;
    switch (error.code) {
      case 'auth/argument-error':
        defaultValidation.errorText = 'handleCodeInApp is false.';
        break;
      case 'auth/invalid-email':
        defaultValidation.errorText = 'Invalid Email Address.';
        break;
      case 'auth/missing-android-pkg-name':
        defaultValidation.errorText = 'Missing android package name.';
        break;
      case 'auth/missing-continue-uri':
        defaultValidation.errorText = 'A continue URL must be provided in the request.';
        break;
      case 'auth/missing-ios-bundle-id':
        defaultValidation.errorText = 'Missing iOS Bundle ID.';
        break;
      case 'auth/invalid-continue-uri':
        defaultValidation.errorText = 'The continue URL provided in the request is invalid.';
        break;
      case 'auth/unauthorized-continue-uri':
        defaultValidation.errorText = 'The domain of the continue URL is not whitelisted.';
        break;
      case 'auth/user-not-found':
        defaultValidation.errorText = i18next.t('auth:validation.noUserFound');
        break;
      default:
        defaultValidation.errorText = `Error: ${error.message}`;
        break;
    }
  } else if (error.message) {
    defaultValidation.errorText = `Error: ${error.message}`;
  } else {
    defaultValidation.errorText = i18next.t('auth:validation.defaultValidation');
  }
  return defaultValidation;
}
