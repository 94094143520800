import firebase from 'firebase';
import { apiProdURL } from '../constants/api';
import { PopulatedQuestionnaireIndex, ScheduleTypes } from '../typing/types';
import { SelfieSignature } from '../utils/classes';
import moment from 'moment';

interface SiteSchedules {
  etag: string;
  schedules: SiteScheduleInfo[];
}

interface SiteScheduleInfo {
  key: string;
  hasDeadline: boolean;
  startTime: number;
  endTime: number;
  isScheduledToday: boolean;
  isDoneToday: boolean;
  unfinishedDates: string[];
  isQuestionnaireExists: boolean;
  questionnaireTitle: string;
  questionnaireDetails: { key: string; value: PopulatedQuestionnaireIndex };
  scheduledDates: {
    [date: string]: {
      isComplete: boolean;
    };
  };
  totalScheduled: number;
  totalCompleted: number;
  type: ScheduleTypes;
  emailTargets: string[];
  signatures: number;
  selfieSignatures: SelfieSignature[];
  hasAdhocCustom: boolean;
  yesterdayActiveScheduleCount?: number;
  yesterdayActiveCompleteCount?: number;
}
interface UserSiteSchedulePayload {
  startDate: string;
  endDate: string;
  etag: string | null;
}
interface UserSingleSiteSchedulePayload extends UserSiteSchedulePayload {
  siteID: string;
}

export const getUserSingleSiteSchedule = async (
  payload: UserSingleSiteSchedulePayload
): Promise<SiteScheduleInfo[]> => {
  let siteSchedules: SiteScheduleInfo[] = [];
  try {
    let { siteID, startDate, endDate, etag } = payload;

    // Limit due to performance issue
    const endDateMoment = moment(endDate);
    const startDateMoment = moment(startDate);
    if (endDateMoment.diff(startDateMoment, 'months') > 1) {
      startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
      endDate = moment().add(30, 'days').format('YYYY-MM-DD');
    }

    const formattedStartDate = moment(startDate).locale('en').format('YYYY-MM-DD');
    const formattedEndDate = moment(endDate).locale('en').format('YYYY-MM-DD');
    const idToken = (await firebase.auth().currentUser!.getIdToken()) as string;
    const url = `${apiProdURL}/schedules/users/app/${siteID}/v2?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    const options = {
      method: 'GET',
      headers: { authorization: idToken }
    };
    const response = await fetch(url, options);
    if (response.status === 200) {
      const { data } = await response.json();
      siteSchedules = data as SiteScheduleInfo[];
    }
  } catch (error) {}
  return siteSchedules;
};

export const getUserScheduleSites = async (payload: UserSiteSchedulePayload): Promise<SiteScheduleInfo[]> => {
  let siteSchedules: SiteScheduleInfo[] = [];
  try {
    const { startDate, endDate } = payload;
    const idToken = (await firebase.auth().currentUser!.getIdToken()) as string;
    const url = `${apiProdURL}/schedules/users/app/v3?startDate=${startDate}&endDate=${endDate}`;
    const options = {
      method: 'GET',
      headers: { authorization: idToken }
    };
    const response = await fetch(url, options);
    if (response.status === 200) {
      const { data } = await response.json();
      siteSchedules = data as SiteScheduleInfo[];
    }
  } catch (error) {}
  return siteSchedules;
};

export type { SiteScheduleInfo, UserSiteSchedulePayload, UserSingleSiteSchedulePayload };
