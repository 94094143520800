import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { View, TextInput, StyleSheet, TouchableOpacity, Text } from 'react-native';

// utils
import fontMaker from '../../../../utils/font';
import theme from '../../../../constants/theme';

import { OutletScheduleFilterProps } from './type';
import firebase from 'firebase';

const OutletScheduleFilter = (props: OutletScheduleFilterProps) => {
  const { t } = useTranslation('site, common');
  const { setSearchFilter, searchFilter, countReportToday, scheduleDateString, siteName } = props;

  const handleClearSearchFilter = () => {
    firebase
      .analytics()
      .logEvent('click_close_search', { category: 'site_details_page', siteName: siteName || 'unknown' });
    setSearchFilter('');
  };

  const startSearch = () => {
    firebase
      .analytics()
      .logEvent('search_questionnaire', { category: 'site_details_page', siteName: siteName || 'unknown' });
  };

  return (
    <View style={styles.container}>
      <View
        style={{
          flexDirection: 'row'
        }}
      >
        <View style={styles.inputContainer}>
          <Icon name="magnify" size={16} color={theme.styles.fontColor.other} style={{ flex: 1 }} />
          <TextInput
            onTouchStart={() => startSearch()}
            placeholder={t('common:search')}
            style={{ flex: 9 }}
            value={searchFilter}
            onChangeText={v => setSearchFilter(v)}
            testID="select-schedule-search-input"
          />
          <TouchableOpacity
            style={styles.closeContainer}
            onPress={() => handleClearSearchFilter()}
            testID="select-schedule-clear-button"
          >
            <Icon name="close" size={16} color={theme.styles.fontColor.other} />
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: theme.colors.light,
    paddingHorizontal: 20,
    paddingVertical: 25
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    height: 32,
    width: '100%',
    borderRadius: 3,
    paddingHorizontal: 10
  },
  text: {
    color: theme.colors.darkgray,
    fontSize: 14,
    lineHeight: 19,
    marginTop: 10
  },
  closeContainer: { flex: 1, alignItems: 'flex-end' },
  subtitle: { fontSize: 12, lineHeight: 18 },
  textBold: { ...fontMaker({ weight: 'SemiBold' }) }
});

export default OutletScheduleFilter;
