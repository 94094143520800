import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  StyleSheet,
  View,
  Dimensions,
  Platform,
  TouchableWithoutFeedbackProps,
  TouchableOpacity,
  TouchableNativeFeedback,
  ActivityIndicator
} from 'react-native';
import { useTranslation } from 'react-i18next';
import SelectorPopup, { Option } from '../global/SelectorPopupNew';
import { QuestionnaireQuestionProps } from './QuestionnaireQuestion';
import QuestionnaireQuestionCompetitorAnalysisForm from './QuestionnaireQuestionCompetitorAnalysisForm';
import theme from '../../constants/theme';
import Button from '../global/Button';
import { RootState } from '../../store/reducers';
import { Company } from 'nimbly-common';
import { toastActions } from '../../store/reducers/toast';
const screen = Dimensions.get('screen');
const { width } = screen;

export type QuestionnaireQuestionCompetitorAnalysisProps = Pick<
  QuestionnaireQuestionProps,
  | 'question'
  | 'index'
  | 'currentQuestionIndex'
  | 'language'
  | 'query'
  | 'competitorAnalysisInputRef'
  | 'handleInputCompetitorAnalysis'
  | 'handleCompetitorAnalysisInputFocused'
  | 'handleCompetitorAnalysisInputBlurred'
  | 'handleActivateImagePicker'
  | 'handleConfirmEditPhoto'
  | 'handleNavCreateCompany'
>;

const QuestionnaireQuestionCompetitorAnalysis = (props: QuestionnaireQuestionCompetitorAnalysisProps) => {
  const { t } = useTranslation(['questionnaire', 'common']);
  const companies = useSelector((state: RootState) => state.company.companies);
  const isLoading = useSelector((state: RootState) => state.company.isLoading);
  const errorCompany = useSelector((state: RootState) => state.company.error);
  const { index, question, handleInputCompetitorAnalysis, handleNavCreateCompany } = props;

  const [companyOptions, setCompanyOptions] = useState<Option[]>([]);

  // fetch companies and put it in company options, and select the first item
  useEffect(() => {
    if (companies?.length > 0 && !isLoading) {
      const newCompanyOptions: Option[] = [];
      companies?.forEach((company: any) => {
        newCompanyOptions.push({ label: company.name, value: company.code });
      });
      setCompanyOptions(newCompanyOptions);
      handleInputCompetitorAnalysis(
        index,
        newCompanyOptions.length > 0 ? newCompanyOptions[0] : newCompanyOptions,
        'company'
      );
    }
  }, [companies, isLoading]);

  useEffect(() => {
    if (errorCompany) {
      toastActions.createToast(errorCompany, true, 2000);
    }
  }, [errorCompany]);

  //navigate to create company page
  const handleClickAddNewCompany = () => {
    handleNavCreateCompany();
  };
  if (!question.competitorAnalysis || isLoading) {
    return <ActivityIndicator color={theme.colors.primaryDark} size="large" />;
  }

  return (
    <View>
      <View style={styles.competitorSelectBarContainer}>
        <View style={styles.competitorSelectBar}>
          <SelectorPopup
            title={t('questionnaire:question.competitorAnalysis.selectCompany')}
            options={companyOptions}
            onChangeSelected={option => {
              handleInputCompetitorAnalysis(index, option, 'company');
            }}
            selected={question.competitorAnalysis.company}
            onClickAddNew={handleClickAddNewCompany}
            addNewText={t('questionnaire:question.competitorAnalysis.addNewCompany')}
          />

          <View style={styles.rowInputContainer}>
            <Button
              style={styles.button}
              color={question.competitorAnalysis.form === 'promotion' ? 'white' : 'grey'}
              backgroundColor={question.competitorAnalysis.form === 'promotion' ? '#3cd070' : theme.colors.light}
              title={'Promotion'}
              onPress={() => handleInputCompetitorAnalysis(index, 'promotion', 'form')}
            />
            <Button
              style={styles.button}
              color={question.competitorAnalysis.form === 'product' ? 'white' : 'grey'}
              backgroundColor={question.competitorAnalysis.form === 'product' ? '#3cd070' : theme.colors.light}
              title={'Product'}
              onPress={() => handleInputCompetitorAnalysis(index, 'product', 'form')}
            />
          </View>
        </View>
      </View>
      <QuestionnaireQuestionCompetitorAnalysisForm
        styles={styles}
        question={props.question}
        index={props.index}
        language={props.language}
        query={props.query}
        competitorAnalysisInputRef={props.competitorAnalysisInputRef}
        handleInputCompetitorAnalysis={props.handleInputCompetitorAnalysis}
        handleCompetitorAnalysisInputFocused={props.handleCompetitorAnalysisInputFocused}
        handleCompetitorAnalysisInputBlurred={props.handleCompetitorAnalysisInputBlurred}
        handleActivateImagePicker={props.handleActivateImagePicker}
        handleConfirmEditPhoto={props.handleConfirmEditPhoto}
      />
    </View>
  );
};

export default QuestionnaireQuestionCompetitorAnalysis;

const styles = StyleSheet.create({
  competitorSelectBarContainer: {
    flex: 1,
    width: '100%',
    zIndex: 1
  },
  competitorSelectBar: {
    width: '100%',
    height: 100,
    paddingHorizontal: 20,
    paddingBottom: 20,
    backgroundColor: '#fff',
    shadowOpacity: 0.15,
    shadowOffset: { height: 5, width: 0 }
  },
  selectBox: {
    borderRadius: 4,
    backgroundColor: theme.colors.light,
    width: '100%',
    height: 40,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 20,
    marginBottom: 10
  },
  rowInputContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  button: {
    borderRadius: 4,
    flexDirection: 'row',
    height: 40,
    alignItems: 'center',
    paddingHorizontal: 20,
    width: width / 2 - 30,
    justifyContent: 'center',
    marginBottom: 10
  }
});

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
  Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;
