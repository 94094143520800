import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';

import { Platform, View, StyleSheet, StyleProp, TextStyle } from 'react-native';
import Text from './Text';
import fontMaker from '../../utils/font';
import { RootState } from '../../store/reducers';
import { ConnectedDispatch, FirebaseConnect } from '../../typing/types';

export class NavigationTitle extends React.PureComponent<NavigationTitleProps> {
  render() {
    const { title, language, active, style, activeTintColor, defaultTintColor } = this.props;
    const parseTitle = title[language] ? title[language] : typeof title === 'object' ? title.en : title;
    return (
      <View style={styles.root}>
        <Text
          style={[styles.title, styles.text, style, { color: active ? activeTintColor : defaultTintColor }]}
          allowFontScaling={false}
        >
          {parseTitle[language] || parseTitle}
        </Text>
      </View>
    );
  }
}

type NavigationTitleProps = {
  title: { en: string; id: string; es: string; km: string; pt: string; th: string };
  language: 'en' | 'id' | 'es' | 'km' | 'pt' | 'th';
  active?: boolean;
  style?: StyleProp<TextStyle>;
  activeTintColor: string;
  defaultTintColor: string;
} & FirebaseConnect &
  ConnectedDispatch;

const mapStateToProps = (state: RootState) => ({
  language: state.firebase.profile.language || 'en'
});

export default compose(firebaseConnect(), connect(mapStateToProps))(NavigationTitle) as any;

const styles = StyleSheet.create({
  root: {
    width: '100%',
    alignItems: 'center'
  },
  text: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: Platform.OS === 'android' ? 8 : 2,
    ...fontMaker({ weight: 'SemiBold' }),
    textAlign: 'center'
  },
  title: {
    fontSize: 20,
    marginHorizontal: 16
  }
});
