import React from 'react';
import { View, FlatList } from 'react-native';
import { ActivityIndicator, StyleSheet, Dimensions } from 'react-native';

import TutorialItem from './TutorialItem';
import { TutorialsProps } from './Tutorials.type';

type TutorialsExtend = TutorialsProps & { isLoading: boolean };

export const TutorialsContainer = (props: TutorialsExtend) => {
  const { tutorials, isLoading } = props;

  return (
    <>
      <View style={styles.root}>
        {isLoading ? (
          <View style={styles.activityContainer}>
            <ActivityIndicator size="large" color="#3cd070" />
          </View>
        ) : (
          tutorials.length > 0 && (
            <FlatList
              data={tutorials}
              renderItem={({ item }: any) => (
                <TutorialItem title={item.value.title} key={item.value.videoId} videoId={item.value.videoId} />
              )}
              keyExtractor={(item: any) => item.value.videoId}
            />
          )
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  root: {},
  activityContainer: {
    marginTop: 0.3 * Dimensions.get('window').height,
    alignItems: 'center',
    justifyContent: 'center'
  }
});

export default TutorialsContainer;
