import update from 'immutability-helper';

import { ActionType, createReducer } from 'typesafe-actions';

import * as actionTypes from './siteSchedule.actionTypes';
import * as actions from './siteSchedule.action';

import { PopulatedQuestionnaireIndex, ScheduleTypes } from '../../../typing/types';
import { SelfieSignature } from '../../../utils/classes';

export type UserSingleSiteScheduleInfo = {
  key: string;
  hasDeadline: boolean;
  startTime: number;
  endTime: number;
  isScheduledToday: boolean;
  isAdhocToday: boolean;
  isDoneToday: boolean;
  unfinishedDates: string[];
  isQuestionnaireExists: boolean;
  questionnaireTitle: string;
  questionnaireDetails: { key: string; value: PopulatedQuestionnaireIndex };
  scheduledDates: {
    [date: string]: {
      isComplete: boolean;
    };
  };
  totalScheduled: number;
  totalCompleted: number;
  type: ScheduleTypes;
  emailTargets: string[];
  signatures: number;
  selfieSignatures: SelfieSignature[];
  // Mainly used to indicate whether adhoc schedule still active or not
  startDate: string;
  endDate: string;
  hasAdhocCustom: boolean;
  isYesterdayActiveSchedule: boolean; // NEW
  isYesterdayActiveScheduleDone: boolean; // NEW
  isYesterdaySchedule: boolean;
  allowAdhoc: boolean; // NEW
  hasStrictTime: boolean; // NEW
  isAdhocOnly: boolean; // NEW
  isMultiSite: boolean;
  datesCustomInfo: {
    // NEW
    customID: string;
    startDate: Date;
    endDate: Date;
  }[];
  enforceCheckIn: boolean;
  enforceCheckOut: boolean;
  siteDateTime: string;
};

export type SiteScheduleDetails = {
  singleSchedules: UserSingleSiteScheduleInfo[];
  isLoading: boolean;
  isReady: boolean;
  isEmpty: boolean;
  lastUpdated: number;
};

export type SiteScheduleAction = ActionType<typeof actions>;
export type SiteScheduleState = {
  [siteKey: string]: SiteScheduleDetails;
};

const initialState: SiteScheduleState = {};

const siteScheduleReducer = (state = initialState, action: SiteScheduleAction): SiteScheduleState => {
  switch (action.type) {
    case actionTypes.SET_IS_LOADING:
      return update(state, {
        [action.payload.siteKey]: scheduleInfo =>
          update(scheduleInfo || {}, {
            isLoading: { $set: action.payload.bool }
          })
      });
    case actionTypes.SET_IS_READY:
      return update(state, {
        [action.payload.siteKey]: scheduleInfo =>
          update(scheduleInfo || {}, {
            isReady: { $set: action.payload.bool }
          })
      });
    case actionTypes.SET_SINGLE_SITE_SCHEDULE_DATA:
      return update(state, {
        [action.payload.siteKey]: scheduleInfo =>
          update(scheduleInfo || {}, {
            singleSchedules: { $set: action.payload.scheduleInfo || [] },
            isLoading: { $set: false },
            isReady: { $set: true },
            isEmpty: { $set: !action.payload.scheduleInfo || action.payload.scheduleInfo.length === 0 },
            lastUpdated: { $set: Date.now() }
          })
      });
    case actionTypes.CLEAR_SITE_SCHEDULE_DATA:
      return update(state, {
        $unset: action.payload.siteKeys
      });

    default:
      return state;
  }
};

export default siteScheduleReducer;
