import React from 'react';
import { User } from '../../utils/classes';
import { SCREEN_WIDTH, SCREEN_HEIGHT } from '../../utils/screensize';
import { useSelector } from 'react-redux';
import { FirebaseReducer } from 'react-redux-firebase';
import {
  Modal,
  Platform,
  StyleSheet,
  TouchableNativeFeedback,
  TouchableOpacity,
  View,
  Image,
  Text
} from 'react-native';

import fontMaker from '../../utils/font';
import { RootState } from '../../store/reducers';
const closeButton = require('../../assets/icons/close-button.png');

const titleTranslation = {
  en: 'Account Frozen',
  id: 'Akun dibekukan'
};
const firstSubTranslation = {
  en: 'Your account has been deactivated by your admin.',
  id: 'Akun Anda telah dinonaktifkan oleh Admin Anda.'
};

const secondSubTranslation = {
  en: 'You will be logged out of this session. Please contact your admin for more details.',
  id: 'Anda akan keluar dari sesi ini. Hubungi Admin Anda untuk informasi lebih lanjut.'
};

const buttonTranslation = {
  en: 'Okay',
  id: 'OK'
};
const FeatureAccessModal = (props: any) => {
  const profile = useSelector<RootState, FirebaseReducer.Profile<User>>(state => state.firebase.profile);
  const { freezeAccountModalVisible, handleLogout } = props;

  return (
    <Modal animationType="fade" transparent={true} visible={freezeAccountModalVisible} onRequestClose={handleLogout}>
      <View style={styles.overlay}>
        <View style={styles.container}>
          <View style={styles.header}>
            <Touchable onPress={handleLogout}>
              <Image source={closeButton} />
            </Touchable>
          </View>
          <View style={styles.actionContainer}>
            <Text style={styles.headerTitle}> {titleTranslation[profile.language]}</Text>
            <View>
              <Text style={styles.firstSub}>{firstSubTranslation[profile.language]}</Text>
              <Text style={styles.secondSub}>{secondSubTranslation[profile.language]}</Text>
            </View>
          </View>
          <Touchable style={styles.confirmTouch} onPress={handleLogout}>
            <View style={styles.confirmButton}>
              <Text style={styles.confirmText}>{buttonTranslation[profile.language]}</Text>
            </View>
          </Touchable>
        </View>
      </View>
    </Modal>
  );
};

const Touchable: any = Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

const styles = StyleSheet.create({
  // Modal
  overlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  container: {
    alignItems: 'center',
    backgroundColor: '#fff',
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH * 0.7,
    borderRadius: 15,
    maxHeight: SCREEN_HEIGHT * 0.7,
    overflow: 'hidden'
  },
  header: {
    width: '100%',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    paddingTop: 8,
    paddingHorizontal: 12
  },
  headerTitle: {
    ...fontMaker({ weight: 'normal' }),
    fontSize: 24,
    marginBottom: 20,
    color: '#535353',
    textAlign: 'center'
  },
  headerSubtitle: {
    ...fontMaker({ weight: 'Light' }),
    textAlign: 'center',
    marginVertical: 12
  },
  actionContainer: {
    width: '80%',
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10
  },
  row: {
    width: '100%',
    paddingVertical: 15,
    paddingHorizontal: 10
  },
  option: {
    textAlign: 'center'
  },
  boldOption: {
    ...fontMaker({ weight: 'SemiBold' })
  },
  confirmButton: {
    backgroundColor: '#0ABA26',
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    ...Platform.select({
      android: {
        width: '70%',
        marginBottom: 20
      }
    })
  },
  confirmText: {
    color: 'white',
    padding: 7,
    fontSize: 18,
    ...fontMaker({ weight: '600' })
  },
  confirmTouch: {
    width: '70%',
    marginBottom: 20
  },
  firstSub: {
    textAlign: 'center',
    color: '#535353',
    lineHeight: 24
  },
  secondSub: {
    textAlign: 'center',
    color: '#535353',
    lineHeight: 24
  }
});

export default FeatureAccessModal;
