import React, { useEffect, useMemo } from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import { useTranslation } from 'react-i18next';
import { Dimensions, Image, Modal, Platform, StatusBar, StyleSheet, View } from 'react-native';
import Tooltip from 'react-native-walkthrough-tooltip';
import { SCREEN_HEIGHT, SCREEN_WIDTH, widthPercentageToDP as wp } from '../../utils/screensize';
import Button from '../global/Button';
import Text from '../global/Text';
import AddFile from './AddFile';
import AddPhoto from './AddPhoto';
import AddVideo from './AddVideo';
import { QuestionnaireSwiperContainerProps } from './QuestionnaireSwiperContainer';

const PhotoIcon = require('../../assets/icons/take-photo-disable.png');
const FileIcon = require('../../assets/icons/take-file-disable.png');
const VideoIcon = require('../../assets/icons/take-video-disable.png');

const screen = Dimensions.get('screen');
const { width } = screen;

export type QuestionnaireSwiperMediaProps = Pick<
  QuestionnaireSwiperContainerProps,
  | 'language'
  | 'question'
  | 'index'
  | 'editingPhotoIndex'
  | 'handleConfirmEditPhoto'
  | 'handleNavRecordVideo'
  | 'handleNavPlaybackVideo'
  | 'handleActivateImagePicker'
  | 'hasVideoAttachment'
  | 'isTutorialMode'
  | 'currentQuestionIndex'
  | 'isShowQuestionTutorialModal'
  | 'currentConditionIndex'
  | 'handleEditUploadFile'
  | 'handleUploadDocuments'
  | 'showCamera'
  | 'handleTakePhoto'
>;

const QuestionnaireSwiperMedia = (props: QuestionnaireSwiperMediaProps) => {
  const { t } = useTranslation(['questionnaire', 'common']);
  const [isShowTooltip, setIsShowTooltip] = React.useState(false);
  const {
    question: { answer: questionnaireAnswer }
  } = props;
  useEffect(() => {
    if (!props.isShowQuestionTutorialModal && props.isTutorialMode && props.index === props.currentQuestionIndex) {
      setIsShowTooltip(true);
    } else if (
      props.isShowQuestionTutorialModal &&
      props.isTutorialMode &&
      props.index === props.currentQuestionIndex
    ) {
      setIsShowTooltip(false);
    }
  }, [props.isShowQuestionTutorialModal, props.isTutorialMode, props.index, props.currentQuestionIndex]);

  const isNotApplicable = useMemo(() => questionnaireAnswer === 'not-applicable', [questionnaireAnswer]);
  /**
   * conditions: {
   *    'key': Question{
   *       ...,
   *       photos:
   *    }
   * }
   */
  if (
    props.question.type === 'inventory-v2' ||
    (!props.question.photoLimit && !props.question.videoLimit && !props.question.documentLimit)
  ) {
    return null;
  }

  return (
    <View style={styles.photosContainer}>
      <View style={styles.photosText}>
        <Text>{t('questionnaire:swiperMedia.selectMedia')}</Text>
        <Text style={styles.attachMinimum}>
          {props.question.photoMinimum > 0 && !isNotApplicable
            ? `(${t('questionnaire:swiperMedia.minimumPhoto')}: ${props.question.photoMinimum})`
            : null}{' '}
          {props.question.videoLimit > 0 && props.hasVideoAttachment && !isNotApplicable
            ? `(${t('questionnaire:swiperMedia.minimumVideo')}: 1)`
            : null}
        </Text>
      </View>
      <View style={styles.DocumentText}>
        {props.question.documentMinimum > 0 && !isNotApplicable ? (
          <Text style={styles.photoMinimum}>
            ({t('questionnaire:swiperMedia.minimumDocuments')}: {props.question.documentMinimum})
          </Text>
        ) : null}
      </View>

      <View style={styles.photos} testID={'media-container'}>
        {props.question.photoLimit > 0 &&
          props.question?.downloadedImage &&
          props.question?.downloadedImage.map((photo, photoIndex) =>
            photo ? (
              <AddPhoto
                key={`question-${props.index}_photo-${photoIndex + 1}`}
                imageLink={photo}
                photoIndex={photoIndex}
                newPhoto={false}
                isValid={true}
                language={props.language}
                isEditing={props.editingPhotoIndex === photoIndex}
                onActivateEdit={imageProps =>
                  props.handleConfirmEditPhoto(photoIndex, undefined, props.currentConditionIndex, imageProps)
                }
              />
            ) : (
              ''
            )
          )}

        {props.hasVideoAttachment &&
          props.question.videoLimit > 0 &&
          props.question.videos &&
          props.question.videos.map((video: string, videoIndex: number) => (
            <AddVideo
              key={`question-${props.index}_photo-${videoIndex + 1}`}
              source={video ? video : ''}
              language={props.language}
              isValid={isNotApplicable || props.question.videos?.length >= 1}
              onRecordVideo={videoProps => props.handleNavRecordVideo(props.currentConditionIndex!, videoProps)}
              onPlaybackVideo={uri => props.handleNavPlaybackVideo(uri, videoIndex, props.currentConditionIndex)}
            />
          ))}
      </View>

      <View style={styles.documentSection}>
        {props.question &&
          props.question.documents &&
          props.question.documents.map((file: string, fileIndex: number) => (
            <AddFile
              source={file}
              language={props.language}
              isValid={true}
              onUploadFile={videoProps => {
                props.handleUploadDocuments(props.currentConditionIndex, videoProps);
              }}
              onEditFile={() => props.handleEditUploadFile(fileIndex, props.currentConditionIndex)}
            />
          ))}
      </View>

      {/* <View style={styles.attachSection}>
        <Text>{t('questionnaire:swiperMedia.selectMedia')}</Text>
      </View> */}

      <View style={styles.containerAttachment} testID={'media-container'}>
        {props.question.photoLimit > 0 &&
        (!props.question.photos || props.question.photos.filter(photo => photo).length < props.question.photoLimit) ? (
          <Tooltip
            isVisible={isShowTooltip}
            topAdjustment={Platform.OS === 'android' ? -StatusBar.currentHeight! : 0}
            placement="top"
            content={<Text>{t('questionnaire:tutorial.clickIcon')}</Text>}
            onClose={() => setIsShowTooltip(false)}
          >
            <AddPhoto
              newPhoto={true}
              language={props.language}
              isTutorialMode={props.isTutorialMode}
              isValid={
                isNotApplicable ||
                props.question.photoMinimum == null ||
                props.question.photoMinimum === 0 ||
                (props.question.photoMinimum > 0 &&
                  props.question.photos &&
                  props.question.photos.length >= props.question.photoMinimum)
              }
              onActivateImagePicker={imageProps => {
                return props.handleActivateImagePicker(undefined, props.currentConditionIndex!, imageProps);
              }}
            />
          </Tooltip>
        ) : props.question.photoLimit ? (
          <View style={styles.portraitBox}>
            <Image source={PhotoIcon.default} style={styles.icon} />
            <Text allowFontScaling={false} style={styles.subtitle}>
              {t('questionnaire:addPhoto')}
            </Text>
          </View>
        ) : null}

        {props.hasVideoAttachment &&
        props.question.videoLimit > 0 &&
        (!props.question.videos || props.question.videos.filter(video => video).length < props.question.videoLimit) ? (
          <AddVideo
            source={''}
            language={props.language}
            isValid={
              isNotApplicable ||
              props.question.videoLimit == null ||
              props.question.videoLimit === 0 ||
              (props.question.videoLimit > 0 &&
                props.question.videos &&
                props.question.videos.length >= props.question.videoLimit)
            }
            onRecordVideo={videoProps => {
              props.handleNavRecordVideo(props.currentConditionIndex, videoProps);
            }}
            onPlaybackVideo={uri => null}
          />
        ) : props.question.videoLimit && props.hasVideoAttachment ? (
          <View style={styles.portraitBox}>
            <Image source={VideoIcon.default} style={styles.icon} />
            <Text allowFontScaling={false} style={styles.subtitle}>
              {t('questionnaire:addVideo')}
            </Text>
          </View>
        ) : null}

        {props?.question?.documentLimit > 0 &&
        (!props?.question?.documents ||
          props?.question?.documents.filter(video => video).length < props.question?.documentLimit) ? (
          <AddFile
            source={''}
            language={props.language}
            isValid={
              isNotApplicable ||
              props.question?.documentMinimum == null ||
              props.question?.documentMinimum === 0 ||
              (props.question?.documentMinimum > 0 &&
                props.question?.documents &&
                props.question?.documents.length >= props.question?.documentMinimum)
            }
            onUploadFile={videoProps => {
              props.handleUploadDocuments(props.currentConditionIndex, videoProps);
            }}
          />
        ) : props.question.documentLimit ? (
          <View style={styles.portraitBox}>
            <Image source={FileIcon.default} style={styles.icon} />
            <Text allowFontScaling={false} style={styles.subtitle}>
              {t('questionnaire:takeFile')}
            </Text>
          </View>
        ) : null}

        <Modal visible={props.showCamera}>
          {props.showCamera && (
            <Camera
              isImageMirror={false}
              idealFacingMode={FACING_MODES.ENVIRONMENT}
              onTakePhoto={dataUri => {
                props.handleTakePhoto(props?.currentConditionIndex, dataUri);
              }}
            />
          )}

          <View style={[styles.buttonContainer]}>
            <Button onPress={props.handleCloseCamera} style={styles.button} title={t('common:cancel')} />
          </View>
        </Modal>
      </View>
    </View>
  );
};

export default QuestionnaireSwiperMedia;

const styles = StyleSheet.create({
  photosContainer: {
    marginTop: 10,
    alignItems: 'center'
    // flexWrap: 'wrap'
  },
  subtitle: {
    textAlign: 'center',
    fontSize: SCREEN_WIDTH > 500 ? wp(0.8) : wp(3),
    color: '#C4C4C4'
  },
  portraitBox: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 100,
    height: (62 / 3) * 4 + 4,
    marginVertical: 10,
    marginHorizontal: 2,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: '#FAFAFA',
    backgroundColor: '#FAFAFA'
  },
  portraitBoxVideo: {
    width: SCREEN_WIDTH - 80,
    height: (62 / 3) * 1 + 4,
    marginVertical: 10,
    marginHorizontal: 2,
    borderRadius: 5,
    borderWidth: 2,
    borderColor: '#FAFAFA',
    backgroundColor: '#FAFAFA'
  },
  attachSection: {
    flexDirection: 'column',
    marginTop: 25
  },
  DocumentText: {
    flexDirection: 'column',
    marginBottom: 20
  },
  button: {
    width: 400,
    marginHorizontal: 'auto',
    marginTop: 15
  },
  buttonContainer: {
    minHeight: 64,
    height: SCREEN_HEIGHT * 0.1,
    width: SCREEN_WIDTH > 500 ? 768 : SCREEN_WIDTH,
    marginHorizontal: 'auto',
    paddingVertical: 12,
    backgroundColor: '#535353',
    zIndex: 2,
    bottom: 0,
    position: 'sticky',
    marginTop: -5
  },
  photosText: {
    flexDirection: 'column'
  },
  documentSection: {
    marginTop: 20
  },
  containerAttachment: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  photoMinimum: {
    textAlign: 'center'
  },
  icon: {
    marginBottom: SCREEN_WIDTH > 500 ? wp(0.1) : wp(2),
    height: SCREEN_WIDTH > 500 ? wp(1.8) : wp(8),
    width: SCREEN_WIDTH > 500 ? wp(1.8) : wp(8)
  },
  photos: {
    // width,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginHorizontal: 25
  },
  attachMinimum: {
    textAlign: 'center'
  }
});
