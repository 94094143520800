import Bluebird from 'bluebird';
import firebase from 'firebase';
import update from 'immutability-helper';
import moment from 'moment';
import { Activity, JourneyReport, JourneySchedule as Journey } from 'nimbly-common';
import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Alert, InteractionManager } from 'react-native';
import { connect } from 'react-redux';
import { firebaseConnect, FirebaseReducer } from 'react-redux-firebase';
import { compose } from 'redux';
import { UploadReportPageProps } from '../../routes/uploadreport';
import { RootState } from '../../store/reducers';
import { logActivityAsync } from '../../store/reducers/activity/activity.action';
import { companyActions, CreateProductPayload, CreatePromotionPayload } from '../../store/reducers/company/company';
import { journeyActions } from '../../store/reducers/journey/journey';
import { reportActions } from '../../store/reducers/report';
import * as reportCacheActions from '../../store/reducers/reportcache/reportcache.action';
import { removeReport } from '../../store/reducers/reportcache/reportcache.actionThunk';
import { reportsubmitActions } from '../../store/reducers/reportsubmit';
import { submitApiReportAsync } from '../../store/reducers/submitReport/submitReport.action';
import { toastActions, ToastState } from '../../store/reducers/toast';
import { tutorialActions } from '../../store/reducers/tutorial';
import { ConnectedDispatch, FirebaseConnect, InjectedSite, ScheduleOption } from '../../typing/types';
import {
  OrganizationReport,
  Question,
  Report,
  ReportQueueData,
  ReportSection,
  ReportSummary,
  SelfieSignature,
  Signature,
  Site,
  User
} from '../../utils/classes';
import { sleep } from '../../utils/sleep';
import storage from '../../utils/storage';
import { getConditionalQuestion, validateConditionalQuestion } from '../questionnaire/utils/conditionalQuestion';
import { tutorialQuestionnaireData } from '../site/Site/assets/tutorialData';
import UploadReportContainer from './UploadReportContainer';
import { isReportDeviceCheckoutTime } from '../../utils/getReportTime';
import uploadReportSelfie from '../../utils/uploadReportSelfie';
const initialState: UploadReportState = {
  /** Contains signatures (drawn) to be uploaded */
  uploadedSignatures: [],
  /** Contains signatures (photo) to be uploaded */
  uploadedSelfieSignatures: [],
  /** Contain Documents that has been uploaded */
  uploadedDocuments: [],
  /** Contain photos that has been uploaded */
  uploadedPhotos: [],
  /** Contain conditional videos that has been uploaded */
  uploadedConditionalVideos: {},
  /** Contain conditional photos that has been uploaded */
  uploadedConditionalPhotos: {},
  /** Contain conditional Documents that has been uploaded */
  uploadedConditionalDocuments: {},
  /** Contain videos that has been uploaded */
  uploadedVideos: [],
  /** Contain SKU Inventory photos that has been uploaded */
  uploadedInventoryPhotos: {},
  /** Number of successuflly uploaded Documents */
  _documentsUploadedCount: 0,
  /** Number of successuflly uploaded photos */
  _photosUploadedCount: 0,
  /** Number of successuflly uploaded videos */
  _videosUploadedCount: 0,
  /** Number of photos that will be uploaded */
  _documentsTotalCount: 0,
  /** Number of photos that will be uploaded */
  _photosTotalCount: 0,
  /** Number of videos that will be uploaded */
  _videosTotalCount: 0,
  /** True When the transitioned has finished (no more interaction) */
  _isTransitioned: false,
  /** True if the report is on uploading process */
  _isUploading: false,
  /** True if the uploaded time reaching the timeout limit */
  _isTimeout: false,
  /** True if the uploading process is cancelled */
  _isCancelled: false,
  /** Flag to handle button clicked only once */
  _isBusy: false,
  /** Estimation of how many seconds left until upload is finished */
  _timeoutValue: 0
};

/**
 * Upload Report manages the report's uploading process including upload report, upload photos, upload videos
 * and upload signatures
 *
 * It also shows screen based on the availability of the internet connections
 *
 */
class UploadReport extends React.Component<UploadReportProps, UploadReportState> {
  private state = { ...initialState };

  private uploadingTimer: any;
  private uploadingEstimationTimer: any;

  private photoPromiseArr: any[] = [];
  private documentPromiseArr: any[] = [];
  private videoPromiseArr: any[] = [];
  private signaturePromiseArr: any[] = [];
  private firebasePromiseArr: any[] = [];

  private async componentDidMount() {
    const { isConnected, navigation } = this.props;

    InteractionManager.runAfterInteractions(() => {
      this.setState({ _isTransitioned: true });
    });

    this.setInitUpload();
    navigation.addListener('blur', () => {
      this.confirmCancelUpload();
    });
  }

  uploadSignatures = (): Promise<any>[] => {
    const {
      uploadingReport: { signatures },
      uploadingReportKey,
      uploadingSiteKey,
      profile,
      isTutorialMode,
      isFocused,
      journey,
      t
    } = this.props;
    this.setState({ uploadedSignatures: signatures! });
    signatures!.forEach(({ path, isSigned }, i) => {
      if (path && isSigned) {
        const signPromise = () => {
          let fileRef = '';
          return new Bluebird((resolve: (T: any) => void, reject: (E: Error) => void, onCancel: (A?: any) => void) => {
            const fileNameReg = /[^/]*$/.exec(path);
            if (!fileNameReg) {
              return reject(new Error(t('uploadReport:error.fileName')));
            }
            const fileName = fileNameReg[0];

            let folderRef = `/report/${profile.organization}/${uploadingSiteKey}/signatures/${uploadingReportKey}/`;

            // eslint-disable-next-line max-len
            fileRef = `/report/${profile.organization}/${uploadingSiteKey}/signatures/${uploadingReportKey}/${fileName}`;

            //handle if site is in a journey
            if (journey) {
              // eslint-disable-next-line max-len
              folderRef = `/reportJourney/${profile.organization}/${journey.code}/${uploadingSiteKey}/signatures/${uploadingReportKey}/`;
              // eslint-disable-next-line max-len
              fileRef = `/reportJourney/${profile.organization}/${journey.code}/${uploadingSiteKey}/signatures/${uploadingReportKey}/${fileName}`;
            }

            if (isTutorialMode) {
              folderRef = `tutorial/report/${profile.organization}/signatures/${uploadingReportKey}/`;
              fileRef = `tutorial/report/${profile.organization}/signatures/${uploadingReportKey}/${fileName}`;
            }
            const reportRef = firebase.storage().ref(folderRef);
            const signRef = reportRef.child(fileName);

            // onCancel!(this.handleUploadCanceled);

            let contentType = 'image/png';
            if (path.includes('data:image/png')) {
              contentType = 'image/png';
            }

            return fetch(path)
              .then(res => res.blob())
              .then(blob => {
                const file = new File([blob], fileName, { type: contentType });

                return resolve(signRef.put(file, { contentType }));
              });
          }).then(uploadRes => {
            if (isFocused && !this.state._isCancelled) {
              this.setState(prevState => {
                const updatedSignatures = update(prevState.uploadedSignatures, {
                  [i]: {
                    path: { $set: fileRef }
                  }
                });
                return {
                  uploadedSignatures: updatedSignatures,
                  _photosUploadedCount: prevState._photosUploadedCount + 1
                };
              });
            }
          });
        };
        this.signaturePromiseArr.push(signPromise());
      }
    });
    return this.signaturePromiseArr;
  };

  uploadSelfieSignatures = (): Promise<any>[] => {
    const {
      uploadingReport: { selfieSignaturesFile, selfieSignatures },
      uploadingReportKey,
      uploadingSiteKey,
      profile,
      isFocused,
      journey,
      t
    } = this.props;
    this.setState({ uploadedSelfieSignatures: selfieSignatures! });
    selfieSignaturesFile!.forEach((file, i) => {
      const uploadSelfiePromise = () =>
        uploadReportSelfie(
          file,
          profile.organizationID,
          uploadingSiteKey,
          uploadingReportKey,
          false,
          false,
          false,
          undefined,
          null,
          profile
        ).then(res => {
          this.setState(prevState => {
            const updatedSelfieSignatures = update(prevState.uploadedSelfieSignatures, {
              [i]: {
                path: { $set: res.path }
              }
            });
            return {
              uploadedSelfieSignatures: updatedSelfieSignatures,
              _photosUploadedCount: prevState._photosUploadedCount + 1
            };
          });
        });
      this.signaturePromiseArr.push(uploadSelfiePromise());
    });
    return this.signaturePromiseArr;
  };

  private confirmCancelUpload = () => {
    if (this.photoPromiseArr.length) {
      this.photoPromiseArr.forEach(prom => {
        prom.cancel();
      });
      this.photoPromiseArr = [];
      clearTimeout(this.uploadingTimer);
      clearTimeout(this.uploadingEstimationTimer);
    }
    if (this.signaturePromiseArr.length) {
      this.signaturePromiseArr.forEach(prom => prom.cancel());
      this.signaturePromiseArr = [];
    }
    if (this.firebasePromiseArr.length) {
      this.firebasePromiseArr.forEach(prom => {
        prom.cancel();
      });
      this.firebasePromiseArr = [];
    }
    this.handleUploadCanceled();
  };

  private handleUploadCanceled = () => {
    this.setState({
      ...initialState,
      _isCancelled: true,
      uploadedPhotos: [],
      uploadedDocuments: [],
      uploadedVideos: [],
      uploadedInventoryPhotos: {},
      _photosUploadedCount: 0,
      _photosTotalCount: 0,
      _isUploading: false
    });
  };
  // handle generate report in tutorial mode
  private handleUploadReportTutorial = async (reportVal: Report) => {
    const { profile, uploadingSiteKey, uploadingReportKey, auth, navigation, route, profileColor } = this.props;
    const generateTutorialReport = firebase.functions!().httpsCallable('inAppTutorialGenerateReportOnCall');

    try {
      await generateTutorialReport({
        report: reportVal,
        organizationKey: profile.organization,
        questionnaire: tutorialQuestionnaireData,
        reportKey: this.props.uploadingReportKey
      });

      // Navigate back to the start
      return navigation.reset({
        index: 0,
        routes: [
          {
            name: 'Payoff',
            params: {
              profileColor: profileColor,
              originTab: route.params.originTab || 'HomeTab',
              siteName: 'Tutorial Site'
            }
          }
        ]
      });
    } catch (error) {}
  };

  private resetUploadingState = () => {
    this.setState({
      uploadedPhotos: [],
      uploadedDocuments: [],
      uploadedVideos: [],
      uploadedInventoryPhotos: {},
      _photosUploadedCount: 0,
      _photosTotalCount: 0,
      _isUploading: false
    });
  };

  private updateFirebaseNonJourney = (reportVal: Report, startTime: number) => {
    const { uploadingReportKey } = this.props;

    const summary = {
      ...this.props.uploadingReportSummary
    };

    // Custom request for team audit
    if (this.props.selectedSchedule?.isMultiSite) {
      reportVal = {
        ...reportVal,
        datetimeSubmitted: reportVal.datetimeScheduled,
        isAdhoc: false
      };

      summary.isAdhoc = false;
    }

    let callback: { resolve: Function; reject: Function } = { resolve: Promise, reject: Promise };
    const uploadReportPromise = new Promise((resolve, reject) => {
      callback.resolve = resolve;
      callback.reject = reject;
    });

    this.props.submitApiReport({
      report: reportVal,
      summary,
      reportId: uploadingReportKey,
      callback
    });

    uploadReportPromise
      .then(() => {
        this.uploadSuccess();
      })
      .then(() => {})
      .catch(err => {
        this.resetUploadingState();
        return null;
      });
  };

  private updateFirebaseJourney = async (
    reportVal: Report,
    reportRef: string,
    reportIndexRef: string,
    startTime: number
  ) => {
    const { profile, uploadingSite, uploadingSiteKey, uploadingQuestionnaireName, uploadingReportKey, auth } =
      this.props;

    const reportIndexVal = this.props.uploadingReportSummary;

    const updateReport = (cancelHander: () => any): Promise<any> => {
      return new Bluebird((resolve: (T: any) => void, reject: (E: Error) => any, onCancel: (A?: any) => any) => {
        if (onCancel) {
          onCancel(cancelHander);
        }

        return this.props.firebase.update(reportRef, reportVal).then(() => {
          return resolve('');
        });
      });
    };

    const updateReportIndex = (cancelHandler: () => any): Promise<any> => {
      return new Bluebird((resolve: (T: any) => void, reject: (E: Error) => any, onCancel: (A?: any) => any) => {
        if (onCancel) {
          onCancel(cancelHandler);
        }

        return this.props.firebase.update(reportIndexRef, reportIndexVal).then(() => {
          return resolve('');
        });
      });
    };

    const createQueue = (cancelHandler: () => any): Promise<any> => {
      return new Bluebird((resolve: (T: any) => void, reject: (E: Error) => any, onCancel: (A?: any) => any) => {
        const queueRef = `/queue/reportCompleted`;

        const reportQueueData: ReportQueueData = {
          organizationKey: profile.organization,
          siteKey: uploadingSiteKey,
          reportKey: uploadingReportKey,
          report: reportVal
        };
        if (onCancel) {
          onCancel(cancelHandler);
        }

        return this.props.firebase.push(queueRef, reportQueueData).then(() => {
          return resolve('');
        });
      });
    };

    this.firebasePromiseArr.push(updateReport(this.handleUploadCanceled));
    this.firebasePromiseArr.push(updateReportIndex(this.handleUploadCanceled));
    this.firebasePromiseArr.push(createQueue(this.handleUploadCanceled));
    await Promise.all(this.firebasePromiseArr);
    this.uploadSuccess();
  };

  private showAlertNetworkOffline = () => {
    const { navigation, route, t, profileColor } = this.props;

    return Alert.alert(
      t('uploadReport:prompt.warning'),
      t('uploadReport:error.offline'),
      [
        {
          text: 'OK',
          onPress: () =>
            navigation.reset({
              index: 0,
              routes: [
                {
                  name: 'Payoff',
                  params: {
                    profileColor: profileColor,
                    originTab: route.params.originTab || 'HomeTab',
                    siteName: 'Demo Site'
                  }
                }
              ]
            })
        }
      ],
      {
        onDismiss: () =>
          // Navigate back to the start
          navigation.reset({
            index: 0,
            routes: [
              {
                name: 'Payoff',
                params: {
                  profileColor: profileColor,
                  originTab: route.params.originTab || 'HomeTab',
                  siteName: 'Demo Site'
                }
              }
            ]
          })
      }
    );
  };

  private setInitUpload = async () => {
    const {
      uploadingSiteKey,
      uploadingReportKey,
      uploadingReport,
      auth,
      journey,
      isConnected,
      profile,
      t,
      journeyReport,
      isDummy
    } = this.props;
    const startTime = Date.now();

    if (!isConnected) {
      this.showAlertNetworkOffline();
    }

    this.setState({ _isTimeout: false, _isCancelled: false }, async () => {
      const signPromises =
        Array.isArray(uploadingReport.signatures) && uploadingReport.signatures.length ? this.uploadSignatures() : [];
      const selfiePromises =
        !!uploadingReport &&
        uploadingReport?.selfieSignatures &&
        Array.isArray(uploadingReport?.selfieSignatures) &&
        uploadingReport?.selfieSignatures.length
          ? this.uploadSelfieSignatures()
          : [];
      const total = signPromises.length + selfiePromises.length;

      if (this.state._isCancelled) {
        return;
      }

      this.setState(
        () => {
          return {
            _photosUploadedCount: total === 1 ? 0 : 0,
            _photosTotalCount: total === 0 ? 1 : total,
            _videosTotalCount: total === 0 ? 1 : 0,
            _documentsTotalCount: total === 0 ? 1 : 0,
            _isUploading: true
          };
        },
        () => {
          if (this.state._isCancelled) {
            return;
          }
          Promise.all(signPromises)
            .then(() => {
              return Promise.all(selfiePromises);
            })
            .then(() => {
              return Promise.resolve(true);
            })
            .then(() => {
              if (this.state._isCancelled) {
                return;
              }

              // eslint-disable-next-line max-nested-callbacks
              this.updateReportSummary(startTime).then(() => {
                try {
                  const now = moment().toISOString(true);

                  const uploadReportKey = `${uploadingSiteKey}/${uploadingReportKey}`;
                  const profileOrg = profile.organization;
                  let reportRef = `/report/${profileOrg}/${uploadReportKey}`;
                  let reportIndexRef = `/reportIndex/${profileOrg}/${uploadReportKey}`;
                  let reportSectionRef = `/reportSection/${profileOrg}/${uploadReportKey}`;

                  // handle if site is in a journey
                  if (journey) {
                    reportRef = `/reportJourney/${profileOrg}/${journey.code}/${uploadReportKey}`;
                    reportIndexRef = `/reportJourneyIndex/${profileOrg}/${journey.code}/${uploadReportKey}`;
                    reportSectionRef = `/reportJourneySection/${profileOrg}/${journey.code}/${uploadReportKey}`;
                  }

                  const questions = uploadingReport.questions.map(question => {
                    return {
                      ...question,
                      // Empty checklist property if checklistsV2 is present to trigger issue creation
                      checklists: question.checklistsV2?.length ? [] : question.checklists
                    };
                  });

                  // Call this.props explicitly as they have been updated since this.uploadPhotos()
                  const reportVal = {
                    ...this.props.uploadingReport,
                    datetimeSubmitted: isReportDeviceCheckoutTime(uploadingReport.datetimeOut, now),
                    signatures: this.state.uploadedSignatures,
                    questions
                  };

                  // add journey report code and journey ID to report
                  // if on journey mode
                  if (journeyReport) {
                    reportVal.journeyKey = journeyReport.journeyID;
                    reportVal.journeyReportCode = journeyReport.code;
                  }

                  // generate report tutorial mode
                  if (this.props.isTutorialMode && isConnected) {
                    this.handleUploadReportTutorial(reportVal);
                    return;
                  }

                  // Overwrite the status of the report if using dummy account
                  // Also delete all sections so that we can start a fresh report again
                  if (isDummy) {
                    Promise.all([
                      firebase.database().ref(reportRef).update({ status: 'draft' }),
                      firebase.database().ref(reportSectionRef).set(null)
                    ]);
                  }

                  if (this.state._isCancelled) {
                    return;
                  }

                  if (!journeyReport) {
                    this.updateFirebaseNonJourney(reportVal, startTime);
                  } else {
                    this.updateFirebaseJourney(reportVal, reportRef, reportIndexRef, startTime);
                  }

                  this.handleDatabaseUpdate(reportVal);
                } catch (error) {
                  this.props.createToast(t('uploadReport:error.upload'), true, 0);
                  this.confirmCancelUpload();
                  return;
                }
              });
            });
        }
      );
    });
  };

  private updateReportSummary = async (startTime: number) => {
    const { uploadingSite, uploadingSiteKey, uploadingQuestionnaireName, uploadingReportKey, auth } = this.props;

    try {
      // Update the state with uploaded photos
      const { questions: prevQuestions } = this.props.uploadingReport;
      const {
        uploadedPhotos,
        uploadedConditionalPhotos,
        uploadedInventoryPhotos,
        uploadedVideos,
        uploadedConditionalVideos,
        uploadedDocuments,
        uploadedConditionalDocuments
      } = this.state;

      /**
       * Done upload attachment
       */

      const clonedPrevQuestions = prevQuestions.slice();
      const updatedQuestions = clonedPrevQuestions.map((question, questionIndex) => {
        const updatedQuestion = { ...question };
        if (
          question.type === 'inventory-v2' &&
          question.skuInventory &&
          Object.keys(question.skuInventory).length > 0
        ) {
          Object.keys(question?.skuInventory || {}).forEach(skuKey => {
            if ((question?.skuInventory![skuKey]?.photos?.length || 0) > 0) {
              const newUpdatePhotos = update(question?.skuInventory, {
                [skuKey]: {
                  photos: {
                    $set: uploadedInventoryPhotos[skuKey] as any
                  }
                }
              });
            }
          });
          // TODO: next step handle for SKU conditional
        } else if (!!uploadedPhotos && uploadedPhotos?.[questionIndex] && uploadedPhotos?.[questionIndex].length > 0) {
          updatedQuestion.photos = uploadedPhotos[questionIndex];
        }
        if (validateConditionalQuestion(question, question.answer)) {
          const cQs: Question[] = getConditionalQuestion(question, question.answer);
          cQs.forEach((q: Question, conditionIndex: number) => {
            if (
              !!uploadedConditionalPhotos &&
              uploadedConditionalPhotos?.[questionIndex][conditionIndex] &&
              uploadedConditionalPhotos?.[questionIndex][conditionIndex].length > 0
            ) {
              q.photos = uploadedConditionalPhotos[questionIndex][conditionIndex] || [];
            }
            if (
              !!uploadedConditionalVideos &&
              uploadedConditionalVideos?.[questionIndex][conditionIndex] &&
              uploadedConditionalVideos?.[questionIndex][conditionIndex].length > 0
            ) {
              q.videos = uploadedConditionalVideos[questionIndex][conditionIndex] || [];
            }
            if (
              !!uploadedConditionalDocuments &&
              uploadedConditionalDocuments?.[questionIndex][conditionIndex] &&
              uploadedConditionalDocuments?.[questionIndex][conditionIndex].length > 0
            ) {
              q.documents = uploadedConditionalDocuments?.[questionIndex][conditionIndex] || [];
            }
          });
        }

        if (!!uploadedVideos && uploadedVideos?.[questionIndex] && uploadedVideos?.[questionIndex].length > 0) {
          updatedQuestion.videos = uploadedVideos[questionIndex];
        }

        if (
          !!uploadedDocuments &&
          uploadedDocuments?.[questionIndex] &&
          uploadedDocuments?.[questionIndex].length > 0
        ) {
          updatedQuestion.documents = uploadedDocuments?.[questionIndex];
        }
        return updatedQuestion;
      });

      this.props.setReportToUpload(
        uploadingSite,
        uploadingSiteKey,
        uploadingQuestionnaireName,
        uploadingReportKey,
        {
          ...this.props.uploadingReport,
          questions: [...updatedQuestions],
          signatures: this.state.uploadedSignatures,
          selfieSignatures: this.state.uploadedSelfieSignatures
        },
        this.props.uploadingReportSummary
      );
    } catch (err) {}
  };

  private uploadSuccess = () => {
    const {
      navigation,
      route,
      t,
      uploadingSite,
      uploadingSiteKey,
      uploadingReportKey,
      uploadingReport,
      auth,
      profileColor
    } = this.props;

    // Reset the stack to prevent bad side-effects from happening
    navigation.reset({
      index: 0,
      routes: [
        {
          name: 'Payoff',
          params: {
            profileColor: profileColor,
            originTab: route.params.originTab || 'HomeTab',
            siteName: 'Demo Site'
          }
        }
      ]
    });

    this.resetUploadingState();

    // sometimes user never reach Payoff, so we reset the upload data here
    try {
      this.props.selectReport(null, null);
      this.props.resetReportToUpload();

      if (uploadingSite.isMultiSite) {
        this.props.removePendingMulti(uploadingSiteKey!, uploadingReportKey!);
      } else {
        this.props.removePendingSingle(uploadingSiteKey!, uploadingReport.scheduleKey!, uploadingReportKey!);
      }
    } catch (error) {}
  };

  private handleUploadLater = async () => {
    const { navigation, route } = this.props;
    await this.setState({ _isBusy: true });
    await sleep(10);
    navigation.reset({
      index: 0,
      routes: [
        {
          name: 'Site'
        }
      ]
    });
  };

  private getImageDownloadURL = (imageRef: string): Promise<string> => {
    return storage().ref(imageRef).getDownloadURL();
  };

  private handleDatabaseUpdate = async (report: Report) => {
    const { uploadedPhotos } = this.state;
    // update hasSubmitQuestionnaire on journey report to true in the first questionnaire submission
    if (this.props.journeyReport) {
      const reports = this.props.journeyReport.reports || [];
      this.props.updateJourneyReport({
        ...this.props.journeyReport,
        hasSubmitQuestionnaire: true,
        reports: [...reports, this.props.uploadingReportKey]
      });
    }
    // handle case update database for competitor analysis question
    report?.questions?.forEach(async (question, index) => {
      if (question.type === 'competitor') {
        if (question.competitorAnalysis?.form === 'promotion') {
          const {
            company,
            promotionTitle,
            promotionProduct,
            promotionType,
            promotionDetail,
            promotionDisplayLocation,
            promotionStartDate,
            promotionEndDate
          } = question.competitorAnalysis;

          if (!company || !promotionType || !promotionStartDate) {
            return;
          }

          let image = '';
          if (uploadedPhotos && uploadedPhotos[index].length > 0) {
            image = await this.getImageDownloadURL(uploadedPhotos[index][0]);
          }

          const payload = {
            companyCode: company.value,
            title: promotionTitle,
            productCode: promotionProduct!.value,
            imageURL: image,
            type: promotionType.value,
            displayLocation: promotionDisplayLocation,
            startDate: promotionStartDate,
            endDate: promotionEndDate || '',
            isEndDateIndefinite: promotionEndDate === '' ? true : false,
            detail: promotionDetail,
            organizationID: this.props.profile.organization,
            createdAt: new Date().toISOString(),
            createdBy: this.props.auth.uid,
            updatedAt: new Date().toISOString(),
            updatedBy: this.props.auth.uid
          };

          this.props.createPromotion(payload);
        } else if (question.competitorAnalysis?.form === 'product') {
          const {
            company,
            productName,
            productCategory,
            productTargetMarket,
            productPriceFrom,
            productPriceTo,
            productDescription,
            productStores,
            productVariant
          } = question.competitorAnalysis;

          if (!company || !productCategory) {
            return;
          }

          let image = '';
          if (uploadedPhotos && uploadedPhotos[index].length > 0) {
            image = await this.getImageDownloadURL(uploadedPhotos[index][0]);
          }

          const payload = {
            companyCode: company.value,
            name: productName,
            image,
            description: productDescription,
            targetMarket: productTargetMarket ? [productTargetMarket] : [],
            categories: productCategory ? [productCategory] : [],
            price: { rangeTo: Number(productPriceTo), rangeFrom: Number(productPriceFrom) },
            variant: productVariant!.value,
            stores: productStores ? [productStores] : [],
            organizationID: this.props.profile.organization,
            createdAt: new Date().toISOString(),
            createdBy: this.props.auth.uid,
            updatedAt: new Date().toISOString(),
            updatedBy: this.props.auth.uid,
            disabled: false
          };

          this.props.createProduct(payload);
        }
      }
    });
  };

  private render() {
    return (
      <UploadReportContainer
        language={this.props.language}
        isConnected={this.props.isConnected}
        _photosUploadedCount={this.state._photosUploadedCount}
        _videosUploadedCount={this.state._videosUploadedCount}
        _photosTotalCount={this.state._photosTotalCount}
        _videosTotalCount={this.state._videosTotalCount}
        _documentsTotalCount={this.state._documentsTotalCount}
        _documentsUploadedCount={this.state._documentsUploadedCount}
        _isTransitioned={this.state._isTransitioned}
        _isUploading={this.state._isUploading}
        _isTimeout={this.state._isTimeout}
        _isBusy={this.state._isBusy}
        isTutorialMode={this.props.isTutorialMode}
        handleUploadLater={this.handleUploadLater}
        timeRemaining={this.state._timeoutValue}
      />
    );
  }
}

interface UploadReportState {
  uploadedSignatures: Signature[];
  uploadedSelfieSignatures: SelfieSignature[];
  uploadedPhotos: any[];
  uploadedVideos: any[];
  uploadedDocuments: any[];
  uploadedConditionalVideos: { [key: string]: any[] };
  uploadedInventoryPhotos: { [skuKey: string]: any[] };
  uploadedConditionalPhotos: { [key: string]: any[] };
  uploadedConditionalDocuments: { [key: string]: any[] };
  _photosUploadedCount: number;
  _videosUploadedCount: number;
  _documentsUploadedCount: number;
  _photosTotalCount: number;
  _videosTotalCount: number;
  _documentsTotalCount: number;
  _isTransitioned: boolean;
  _isUploading: boolean;
  _isTimeout: boolean;
  _isCancelled: boolean;
  _isBusy: boolean;
  _timeoutValue: number;
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface OwnProps {
  isFocused: boolean;
}

interface StateProps {
  language: string;
  auth: FirebaseReducer.AuthState;
  profile: FirebaseReducer.Profile<User>;
  profileColor: string;
  cacheReports: OrganizationReport;
  isConnected: boolean;
  uploadingQuestionnaireName: string;
  uploadingReport: Report;
  uploadingReportSummary: ReportSummary;
  uploadingSite: Site | InjectedSite;
  uploadingSiteKey: string;
  uploadingReportKey: string;
  toast: ToastState;
  isDummy: boolean;
  isTutorialMode: boolean;
  journey: Journey | null;
  journeyReport: JourneyReport | null;
  organizationId: string | undefined;
  selectedSchedule: Partial<ScheduleOption> | null;
}

type EnhancedProps = StateProps & DispatchProps & ConnectedDispatch & FirebaseConnect & WithTranslation;

type UploadReportProps = OwnProps & UploadReportPageProps & EnhancedProps;

const mapStateToProps = (state: RootState): StateProps => ({
  language: state.firebase.profile.language || 'en',
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  profileColor: state.profiletheme.color,
  cacheReports: state.reportcache.reports,
  isConnected: state.network.isConnected,
  uploadingQuestionnaireName: state.reportsubmit.uploadingQuestionnaireName!,
  uploadingReport: state.reportsubmit.uploadingReport!,
  uploadingReportSummary: state.reportsubmit.uploadingReportSummary!,
  uploadingSite: state.reportsubmit.uploadingSite!,
  uploadingSiteKey: state.reportsubmit.uploadingSiteKey!,
  uploadingReportKey: state.reportsubmit.uploadingReportKey!,
  toast: state.toast,
  isDummy: state.account.isDummy,
  isTutorialMode: state.tutorial.isTutorialMode,
  journey: state.journey.journey,
  journeyReport: state.journey.journeyReport,
  organizationId: state.organization.myOrganization?.organizationID,
  selectedSchedule: state.site.selectedSchedule
});

function mapDispatchToProps(dispatch: any) {
  return {
    /**
     * Creates a  toast displaying an error message
     * @param message - The text content of the toast
     * @param showDismissButton - True if the toast is dismissable
     * @param duration - How long the toast will be displayed
     */
    createToast: (message: string, showDismissButton?: boolean, duration?: number) =>
      dispatch(toastActions.createToast(message, showDismissButton, duration)),
    /**
     * Set the report object that will be uploaded on redux state
     * @param site - The Site object
     * @param siteKey - The Site key
     * @param questionnaireName - The string name of the questionnaire
     * @param reportKey - The submitted report key
     * @param report - The submitted report object
     * @param reportSummary - The report summary object of the submitted report
     */
    setReportToUpload: (
      site: Site | InjectedSite,
      siteKey: string,
      questionnaireName: string,
      reportKey: string,
      report: Report,
      reportSummary: ReportSummary
    ) =>
      dispatch(
        reportsubmitActions.setReportToUpload(site, siteKey, questionnaireName, reportKey, report, reportSummary)
      ),
    /**
     * Select a report, which saves the key of that report
     * @param key - The selected report key
     * @param value - The value of Report Object
     */
    selectReport: (key: string | null, value: Report | ReportSection | null) =>
      dispatch(reportActions.selectReport(key, value)),
    /**
     * Reset the redux state used for upload
     */
    resetReportToUpload: () => dispatch(reportsubmitActions.resetReportToUpload()),
    /**
     * Remove the Pending Multi Site
     * @param siteKey - The Site key
     * @param reportKey - The Selected Report Key
     */
    removePendingMulti: (siteKey: string, reportKey: string) =>
      dispatch(reportsubmitActions.removePendingMulti(siteKey, reportKey)),
    /**
     * Remove the Pending Single Site
     * @param siteKey - The Site Key
     * @param questionnaireKey - The Questionnaire Key
     * @param reportKey - The Selected Report Key
     */
    removePendingSingle: (siteKey: string, questionnaireKey: string, reportKey: string) =>
      dispatch(reportsubmitActions.removePendingSingle(siteKey, questionnaireKey!, reportKey)),
    /**
     * Remove the report from redux state including the cache and summary
     * @param siteKey - The Site Key
     * @param reportKey - The Report Key
     * @param backup - True to save a backup report
     */
    removeReport: (siteKey: string, reportKey: string, backup: boolean) =>
      dispatch(removeReport(siteKey, reportKey, backup)),
    /**
     * Initiates tutorial mode
     * @param bool - boolean that sets whether to initial tutorial mode or not
     */
    setTutorialMode: (bool: boolean) => dispatch(tutorialActions.setTutorialMode(bool)),
    createPromotion: (payload: CreatePromotionPayload) =>
      dispatch(companyActions.createPromotionAsync.request(payload)),
    createProduct: (payload: CreateProductPayload) => dispatch(companyActions.createProductAsync.request(payload)),
    updateJourneyReport: (payload: JourneyReport) => dispatch(journeyActions.updateJourneyReportAsync.request(payload)),
    /**
     * track user activity
     * @param data - activity data detail
     */
    logActivity: (data: Activity) => dispatch(logActivityAsync.request(data)),
    submitApiReport: (payload: any) => dispatch(submitApiReportAsync.request(payload)),
    removeCacheReportAndSummary: (siteKey: string, reportKey: string) =>
      dispatch(reportCacheActions.removeCacheReportAndSummary(siteKey, reportKey))
  };
}

const enhance = compose(
  firebaseConnect(),
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation(['uploadReport, common'])
);

export default enhance(UploadReport) as any;
