import React, { useState } from 'react';

import {
  TextInput,
  Platform,
  StyleSheet,
  TouchableNativeFeedback,
  TouchableOpacity,
  View,
  TextStyle
} from 'react-native';
import fontMaker from '../../utils/font';
import theme from '../../constants/theme';
import Text from './Text';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { SCREEN_WIDTH } from '../../utils/screensize';

export type Option = {
  label: string;
  value: string;
};

type ChipInputProps = {
  value: string;
  setValue: (value: string) => void;
  values: string[];
  setValues: (values: string[]) => void;
  placeholder: string;
};

const ChipInput = (props: ChipInputProps) => {
  const { value, values, setValue, setValues, placeholder } = props;

  const handleChange = (text: string) => {
    if (text.includes(' ')) {
      handleSubmit();
    } else {
      setValue(text);
    }
  };

  const handleSubmit = () => {
    if (!values.includes(value)) {
      setValues([...values, value]);
      setValue('');
    }
  };

  const handleRemove = (removedValue: string) => {
    setValues(values.filter(aValue => aValue !== removedValue));
  };

  return (
    <>
      <View style={[styles.inputContainer]}>
        <TextInput
          style={styles.answerInput}
          onChangeText={handleChange}
          value={value}
          placeholder={placeholder}
          onSubmitEditing={handleSubmit}
        />
      </View>
      <View style={styles.chipContainer}>
        {values.map(valueChip => (
          <View key={`chip-${valueChip}`} style={styles.chip}>
            <Text style={styles.chipText}>{valueChip} </Text>
            <Touchable
              onPress={() => {
                handleRemove(valueChip);
              }}
            >
              <Icon name="close" size={15} color={'white'} />
            </Touchable>
          </View>
        ))}
      </View>
    </>
  );
};

export default ChipInput;

const Touchable: any = Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

const width = SCREEN_WIDTH;

const styles = StyleSheet.create({
  inputContainer: {
    borderRadius: 4,
    backgroundColor: theme.colors.light,
    height: 40,
    flexDirection: 'row',
    paddingHorizontal: 10,
    marginBottom: 10
  },
  answerInput: Platform.select({
    ios: {
      ...fontMaker({ weight: 'Regular' }),
      color: '#535353',
      textAlignVertical: 'top',
      paddingTop: 0,
      paddingHorizontal: 10,
      width: '100%'
    },
    android: {
      height: 39,
      ...fontMaker({ weight: 'Regular' }),
      color: '#535353',
      textAlignVertical: 'top',
      paddingVertical: 10,
      paddingHorizontal: 10,
      width: '100%'
    }
  }) as TextStyle,
  chipContainer: {
    flexDirection: 'row',
    marginBottom: 10,
    flexWrap: 'wrap'
  },
  chip: {
    flexDirection: 'row',
    backgroundColor: '#3cd070',
    alignSelf: 'flex-start',
    alignItems: 'center',
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 20,
    marginRight: 5,
    marginBottom: 5
  },
  chipText: {
    color: 'white'
  }
});
