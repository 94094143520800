import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

import theme from '../../constants/theme';

export type QuestionnaireQuestionScoreTipsProps = {
  score: number;
  index: number;
};

export const QuestionnaireQuestionScoreTips = (props: QuestionnaireQuestionScoreTipsProps) => {
  return (
    <View
      style={[
        styles.sliderTips,
        {
          width: props.score <= 4 ? '76%' : `${92 - props.score}%`,
          justifyContent: props.score <= 4 ? 'space-between' : 'space-around'
        }
      ]}
      testID={'question-tips'}
    >
      {new Array(props.score + 2).fill('').map((s, i) => (
        <View
          key={`slider-tips-${props.index}-${i}`}
          style={[styles.sliderTip, { borderRightColor: i === 0 ? 'transparent' : theme.colors.gray }]}
        >
          <Text>&zwnj;</Text>
        </View>
      ))}
    </View>
  );
};

export default QuestionnaireQuestionScoreTips;

const styles = StyleSheet.create({
  sliderTips: {
    height: 6,
    textAlign: 'center',
    flexDirection: 'row',
    alignItems: 'center'
  },
  sliderTip: {
    borderRightColor: theme.colors.dark,
    borderRightWidth: StyleSheet.hairlineWidth
  }
});
