import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Animated, StyleSheet, View } from 'react-native';
import { useTranslation } from 'react-i18next';

import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../utils/screensize';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Text from '../global/Text';
import theme from '../../constants/theme';
import fontMaker from '../../utils/font';
import cycleAnimation from './utils/cycleAnimation';
import { LanguageType } from '../../constants/Languages';

type LandingLoadingProps = {
  profileColor: string;
  language: LanguageType;
};

/**
 * Landing loading placeholder till Firebase auth is initialized and loaded, after which it checks for a user.
 */
const LandingLoading = (props: LandingLoadingProps) => {
  const cloudAnim: Animated.Value = new Animated.Value(0);
  const scale = cloudAnim.interpolate({
    inputRange: [0, 100],
    outputRange: [1, 1.5]
  });
  const { t } = useTranslation(['landing']);

  // Cycles the animation so the cloud will zoom in and out
  useEffect(() => {
    cycleAnimation(cloudAnim);
  }, []);

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        <View style={styles.circle}>
          <Animated.View style={{ transform: [{ scale: scale }] }}>
            <Icon name="cloud-outline" size={28} color="white" />
          </Animated.View>
        </View>
        <Text
          allowFontScaling={false}
          style={[styles.headerText, theme.styles.textPrimary, { color: props.profileColor }]}
        >
          {t('landing:loading')}
        </Text>
      </View>
      <View style={styles.body}>
        <Text allowFontScaling={false} style={styles.bodyText}>
          {t('landing:welcomeMessage')}
        </Text>
      </View>
    </View>
  );
};
const styles = StyleSheet.create({
  root: {
    marginTop: 0.21 * SCREEN_HEIGHT,
    width: SCREEN_WIDTH > 500 ? 670 : 350,
    backgroundColor: '#ffffff',
    borderRadius: 7.5
  },
  header: {
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'rgba(27, 33, 51, 0.1)',
    paddingVertical: 15,
    paddingHorizontal: 40
  },
  circle: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    borderRadius: 60 / 2,
    marginBottom: 20,
    backgroundColor: '#535353'
  },
  headerText: {
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 18
  },
  body: {
    paddingVertical: 30,
    paddingHorizontal: 25
  },
  bodyText: {
    textAlign: 'center',
    color: '#525763'
  }
});

const mapStateToProps = (state: any) => ({
  profileColor: state.profiletheme.color
});

export default connect(mapStateToProps)(LandingLoading);
