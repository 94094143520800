import React from 'react';
import { useTranslation } from 'react-i18next';

import { Dimensions, StyleSheet, View } from 'react-native';
import Text from '../global/Text';
import fontMaker from '../../utils/font';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '../../utils/screensize';

import { PayOffContainerProps } from './PayoffContainer';
import { PayoffStyles } from './Payoff';

type PayoffScoresProps = Pick<PayOffContainerProps, 'flagCount' | 'scoreWeighted'>;

/** Score Section in Payoff Screen */
const PayoffScores = (props: PayoffScoresProps) => {
  const { flagCount, scoreWeighted } = props;
  const { t } = useTranslation('payoff');

  if (
    !flagCount ||
    (flagCount.red === 0 &&
      flagCount.yellow === 0 &&
      flagCount.green === 0 &&
      scoreWeighted !== null &&
      scoreWeighted > -1)
  ) {
    if (scoreWeighted! < 50) {
      return (
        <React.Fragment>
          <View style={[styles.scoreCard, styles.redScore]}>
            <Text style={styles.scoreNumber}>{scoreWeighted!.toFixed(0)}%</Text>
            <Text style={styles.scoreText}>{t('payoff:score').toUpperCase()}</Text>
          </View>
        </React.Fragment>
      );
    } else if (scoreWeighted! < 80) {
      return (
        <React.Fragment>
          <View style={[styles.scoreCard, styles.yellowScore]}>
            <Text style={styles.scoreNumber}>{scoreWeighted!.toFixed(0)}%</Text>
            <Text style={styles.scoreText}>{t('payoff:score').toUpperCase()}</Text>
          </View>
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <View style={[styles.scoreCard, styles.greenScore]}>
          <Text style={styles.scoreNumber}>{scoreWeighted!.toFixed(0)}%</Text>
          <Text style={styles.scoreText}>{t('payoff:score').toUpperCase()}</Text>
        </View>
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <View style={[styles.flagCard, styles.redScore]}>
        <Text style={styles.flagNumber}>{flagCount!.red < 10 ? `0${flagCount!.red}` : flagCount!.red}</Text>
        <Text style={styles.flagText}>{t('payoff:flags').toUpperCase()}</Text>
      </View>
      <View style={[styles.flagCard, styles.yellowScore]}>
        <Text style={styles.flagNumber}>{flagCount!.yellow < 10 ? `0${flagCount!.yellow}` : flagCount!.yellow}</Text>
        <Text style={styles.flagText}>{t('payoff:flags').toUpperCase()}</Text>
      </View>
      <View style={[styles.flagCard, styles.greenScore]}>
        <Text style={styles.flagNumber}>{flagCount!.green < 10 ? `0${flagCount!.green}` : flagCount!.green}</Text>
        <Text style={styles.flagText}>{t('payoff:flags').toUpperCase()}</Text>
      </View>
    </React.Fragment>
  );
};

const styles = StyleSheet.create({
  scoreCard: {
    marginHorizontal: 4,
    marginVertical: 10,
    width: SCREEN_WIDTH * 0.1,
    height: SCREEN_WIDTH * 0.1,
    backgroundColor: 'red',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4
  },
  flagCard: {
    marginHorizontal: 4,
    marginVertical: 10,
    width: SCREEN_WIDTH > 500 ? 660 * 0.2 : SCREEN_WIDTH * 0.2,
    height: SCREEN_WIDTH > 500 ? 660 * 0.1 : SCREEN_WIDTH * 0.2,
    backgroundColor: 'red',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4
  },
  scoreNumber: {
    ...fontMaker({ weight: 'Bold' }),
    fontSize: 28,
    lineHeight: 30,
    color: '#fff'
  },
  scoreText: {
    color: '#fff',
    fontSize: 13,
    lineHeight: 14
  },
  flagNumber: {
    ...fontMaker({ weight: 'Bold' }),
    fontSize: 24,
    lineHeight: 28,
    color: '#fff'
  },
  flagText: {
    color: '#fff',
    fontSize: 10,
    lineHeight: 12
  },
  redScore: {
    backgroundColor: '#f93c1c'
  },
  yellowScore: {
    backgroundColor: '#ffa040'
  },
  greenScore: {
    backgroundColor: '#009e43'
  }
});

export default PayoffScores;
