import React from 'react';
import {
  View,
  TouchableWithoutFeedbackProps,
  Platform,
  TouchableNativeFeedback,
  TouchableOpacity,
  StyleSheet
} from 'react-native';

import Text from '../global/Text';

import { QuestionnaireSwiperContainerProps } from './QuestionnaireSwiperContainer';
import theme from '../../constants/theme';
import { useTranslation } from 'react-i18next';

export type QuestionnaireSwiperRecommendationProps = Pick<
  QuestionnaireSwiperContainerProps,
  'question' | 'handleToggleModal' | 'allowRecommendation' | 'index' | 'language'
>;

const QuestionnaireSwiperRecommendation = (props: QuestionnaireSwiperRecommendationProps) => {
  const { t } = useTranslation(['questionnaire', 'common']);
  if (props.allowRecommendation) {
    if (
      (props.question.type === 'binary' || props.question.type === 'binary-with-red-flag') &&
      (props.question.answer === 'yellow-flag' || props.question.answer === 'red-flag') &&
      props.question.potentialRemedies
    ) {
      return (
        <View style={styles.recommendationContainer}>
          <View style={styles.recommendationTitleContainer}>
            <Text style={styles.recommendationTitleText}>{t('questionnaire:recommendation.title')}</Text>
          </View>
          <View style={styles.recommendationField}>
            {props.question.remedy ? (
              props.question.remedy.split('\n\n').map((item, remedyIndex: number) => (
                <Touchable
                  testID={`recommendation-${remedyIndex}`}
                  onPress={() => props.handleToggleModal(props.index)}
                  key={`recommendation-${props.index}-${remedyIndex}`}
                >
                  <View style={styles.recommendationBorder}>
                    <Text selectable={true} style={[styles.recommendationItem, { color: '#535353' }]}>
                      {item}
                    </Text>
                  </View>
                </Touchable>
              ))
            ) : (
              <Touchable testID={`add-recommendation`} onPress={() => props.handleToggleModal(props.index)}>
                <View style={styles.recommendationEmpty}>
                  <Text style={{ color: '#A8A8AA' }}>{t('questionnaire:recommendation.tap')}</Text>
                </View>
              </Touchable>
            )}
          </View>
        </View>
      );
    }
  }

  return null;
};

export default QuestionnaireSwiperRecommendation;

const styles = StyleSheet.create({
  recommendationContainer: {
    marginTop: 16,
    marginHorizontal: 20,
    marginBottom: 30
  },
  recommendationTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 12
  },
  recommendationTitleText: {
    fontWeight: '500'
  },
  recommendationField: {
    minHeight: 40
  },
  recommendationEmpty: {
    minHeight: 20,
    justifyContent: 'center'
  },
  recommendationBorder: {
    borderLeftColor: theme.colors.primary,
    borderLeftWidth: 2,
    marginVertical: 2
  },
  recommendationItem: {
    paddingLeft: 10,
    paddingVertical: 8
  }
});

const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
  Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;
