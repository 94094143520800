import { Questionnaire, Site, Report } from './classes';
import { InjectedSite, PopulatedQuestionnaireIndex } from '../typing/types';

export const getQuestionnaireIndexKeysFromSite = (site: Site | InjectedSite): string[] => {
  const mappedKeys: { [key: string]: boolean } = {};

  if (site.children && Array.isArray(site.children) && site.children.length > 0) {
    site.children.forEach(child => {
      if (child.assignedQuestionnaire) {
        mappedKeys[child.assignedQuestionnaire] = true;
      }
    });
  }

  const keys = Object.keys(mappedKeys);

  return keys;
};

export const isInventoryQuestionnaire = (
  site: Site | InjectedSite,
  allQuestionnaireIndex: { [key: string]: PopulatedQuestionnaireIndex }
) => {
  if (!allQuestionnaireIndex) {
    return false;
  }

  const questionnaireIndexKeys = getQuestionnaireIndexKeysFromSite(site);
  for (const key of questionnaireIndexKeys) {
    const currentQuestIndex = allQuestionnaireIndex[key];
    if (!currentQuestIndex) {
      continue;
    }
    const { questionnaire } = currentQuestIndex;
    if (!questionnaire) {
      continue;
    }
    if (questionnaire.type === 'inventory' || questionnaire.type === 'inventory-v2') {
      return true;
    }
    for (const question of questionnaire.questions) {
      if (question.type === 'inventory' || question.type === 'inventory-v2') {
        return true;
      }
    }
  }

  return false;
};

export const getSKUFromQuestionnaires = (questionnaires: { [key: string]: Questionnaire }): string[] => {
  const skuMapped: { [sku: string]: boolean } = {};
  for (const key of Object.keys(questionnaires)) {
    const { questions } = questionnaires[key];
    questions.forEach(question => {
      if (question.sku && question.type) {
        skuMapped[question.sku] = true;
      }
    });
  }

  const sku = Object.keys(skuMapped);
  return sku;
};

export const getSKUFromReport = (report: Report): string[] => {
  const { questions } = report;

  const itemSKU: { [sku: string]: boolean } = {};
  for (const question of questions || []) {
    if (question.sku) {
      itemSKU[question.sku] = true;
    }
  }

  const allSku = Object.keys(itemSKU);
  return allSku;
};
