import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityIndicator, Dimensions, Image, StyleSheet, TextStyle, View } from 'react-native';
import { useDispatch } from 'react-redux';

import { AUTH_MODE } from '../../constants/Constants';
import theme from '../../constants/theme';
import { landingActions } from '../../store/reducers/landing';
import { isEmail, isPhone } from '../../utils/validation';
import Button from '../global/Button';
import Divider from '../global/Divider';
import RoundedButton from '../global/RoundedButton';
import Text from '../global/Text';
import { AuthContainerProps } from './AuthContainer';
import AuthField from './AuthField';
import AuthFormFooter from './AuthFormFooter';
import AuthFormHeader from './AuthFormHeader';
import AuthFreezeBanner from './AuthFreezeBanner';
import ToolTips from '../tooltips/ToolTips';
import { SCREEN_WIDTH } from '../../utils/screensize';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
export type AuthFormProps = Omit<
  AuthContainerProps,
  | 'onCodeChange'
  | 'onCancelValidation'
  | 'onSubmitValidation'
  | '_twoFactorConfirmation'
  | 'visibleTutorialModal'
  | 'freezeBanner'
>;

export type AuthFormPropsExtend = AuthFormProps & {
  handleVisibleTutorialModal: any;
  onTogglePasswordVisibility: () => void;
  isPasswordVisible: boolean;
  freezeBanner: boolean;
  onCodeChange: (text: string) => void;
  onCancelValidation: () => any;
  onSubmitValidation: () => any;
  onPressResendOTP: () => void;
  onSubmitRequestFederated: () => void;
};

type firstTimeFocused = {
  login: boolean;
  activateAccount: boolean;
};

interface AuthModeEnumInstance {
  key: AUTH_MODE;
  value: string;
}
interface AuthModeEnum {
  [key: string]: AuthModeEnumInstance;
}
/**
 * The form flow for Auth.
 */
const AuthForm = (props: AuthFormPropsExtend) => {
  const { t } = useTranslation(['auth', 'common']);
  const dispatch = useDispatch();
  const MAX_COUNTDOOWN = 3;
  const cdInterval = useRef<any>();
  const [countDown, setCountDown] = useState<number>(MAX_COUNTDOOWN);
  const [_, forceUpdate] = useReducer(x => x + 1, 0);
  const phoneInputRef: React.RefObject<any> = React.createRef();
  const passwordInputRef: React.RefObject<any> = React.createRef();
  const [isActiveToolTips, setIsActiveToolTips] = useState<boolean>(false);
  const [isFirstTimeFocused, setIsFirstTimeFocused] = useState<firstTimeFocused>({
    login: false,
    activateAccount: false
  });

  const handleSubmitPressed = () => {
    if (props.authMode === AUTH_MODE.SIGN_UP) {
      if (!props._submitOnEnter) {
        phoneInputRef.current!.focus();
        return props.onFocusMovedDown();
      }
      return props.onSubmitSignup();
    } else if (props.authMode === AUTH_MODE.LOGIN) {
      if (!props._submitOnEnter && !!passwordInputRef?.current) {
        passwordInputRef.current!.focus();
        return props.onFocusMovedDown();
      }
      return props.onSubmitLogin();
    }
  };

  const _renderFormTitle = (authMode: AUTH_MODE) => {
    const text: AuthModeEnum = {
      [AUTH_MODE.CONFIRM_OTP]: t('auth:form.verifyAccount'),
      [AUTH_MODE.EMAIL_SENT]: t('auth:form.verifyAccount'),
      [AUTH_MODE.VERIFY_OTP]: t('auth:form.accountVerified')
    };
    const keyExist = authMode in text;
    return keyExist ? <Text style={styles.formTitle}>{text[authMode]}</Text> : null;
  };

  const _renderAuthHeader = (authMode: AUTH_MODE) => {
    const text: AuthModeEnum = {
      [AUTH_MODE.SIGN_UP]: t('auth:form.enterEmailPhone'),
      [AUTH_MODE.LOGIN]: t('auth:form.loginAccount'),
      [AUTH_MODE.RESET_PASSWORD]: t('auth:form.sendARequest'),
      [AUTH_MODE.CONFIRM_OTP]: t('auth:twoFactor.enterCode', { phoneNumber: props.form.emailOrPhone }),
      [AUTH_MODE.EMAIL_SENT]: t('auth:twoFactor.emailSent')
    };
    const keyExist = authMode in text;
    return keyExist ? <Text style={styles.authHeader}>{text[authMode]}</Text> : null;
  };

  const tooltipsContent = () => {
    const instructions = [t('auth:form.loginInstruction1'), t('auth:form.loginInstruction2')];
    return (
      <View style={styles.tooltipContentContainer}>
        <Text style={styles.loginInstructionTitle}>{t('auth:form.loginInstructionTitle')}</Text>
        {instructions.map((loginInstruction, i) => (
          <Text key={i} style={styles.instruction}>
            {loginInstruction}
          </Text>
        ))}
      </View>
    );
  };

  const _renderAuthField = (authMode: string) => {
    const isValidationBottomError = props.formValidation.errorTextBottom !== '' && !props.formValidation.emailOrPhone;
    switch (authMode) {
      case AUTH_MODE.LOGIN:
        return (
          <React.Fragment>
            <ToolTips isActive={isActiveToolTips} content={tooltipsContent}>
              <AuthField
                isValid={props.formValidation.emailOrPhone}
                disabled={props._isBusy}
                icon="blank"
                onFocus={() => {
                  if (!isFirstTimeFocused.login) {
                    setIsActiveToolTips(true);
                    setIsFirstTimeFocused((prevState: firstTimeFocused) => ({
                      ...prevState,
                      login: true
                    }));
                  } else {
                    setIsActiveToolTips(false);
                  }
                  props.onEmailTextInputFocused();
                }}
                onBlur={() => setIsActiveToolTips(false)}
                onChangeText={(text: string) => {
                  if (text.length > 0) {
                    setIsActiveToolTips(false);
                  }
                  props.onEmailOrPhoneChange(text);
                }}
                value={props.form.emailOrPhone}
                returnKeyType="next"
                blurOnSubmit={true}
                onSubmitEditing={handleSubmitPressed}
                placeholder={t('auth:form.emailOrPhone')}
                keyboardType="email-address"
                autoCapitalize="none"
              />
            </ToolTips>

            {isValidationBottomError && !props.formValidation.isNeedAccessFederated ? (
              <Text style={styles.errorText}>{props.formValidation.errorTextBottom}</Text>
            ) : null}

            {props.formValidation.isNeedAccessFederated && props.formValidation.errorTextBottom ? (
              <View style={styles.requestAccessContainer}>
                <Icon style={styles.iconContainer} name="alert-circle" color="#FC4C5E" size={16} />
                <Text style={styles.errorText}>{props.formValidation.errorTextBottom}</Text>
              </View>
            ) : null}

            {!props.isFederatedAuth && (
              <AuthField
                autoFocus
                ref={passwordInputRef}
                isValid={props.formValidation.password}
                isPasswordVisible={props.isPasswordVisible}
                disabled={props._isBusy}
                icon="lock"
                value={props.form.password}
                blurOnSubmit={true}
                placeholder={t('auth:form.password')}
                keyboardType="default"
                secureTextEntry={!props.isPasswordVisible}
                onFocus={props.onPasswordTextInputFocused}
                onChangeText={props.onPasswordChange}
                onSubmitEditing={handleSubmitPressed}
                onTogglePasswordVisibility={props.onTogglePasswordVisibility}
              />
            )}
          </React.Fragment>
        );
      case AUTH_MODE.SIGN_UP:
        return (
          <ToolTips isActive={isActiveToolTips} content={tooltipsContent}>
            <AuthField
              isValid={props.formValidation.emailOrPhone}
              disabled={props._isBusy}
              icon="blank"
              onFocus={() => {
                if (!isFirstTimeFocused.activateAccount) {
                  setIsActiveToolTips(true);
                  setIsFirstTimeFocused((prevState: firstTimeFocused) => ({
                    ...prevState,
                    activateAccount: true
                  }));
                } else {
                  setIsActiveToolTips(false);
                }
                props.onEmailTextInputFocused();
              }}
              onBlur={() => setIsActiveToolTips(false)}
              onChangeText={(text: string) => {
                if (text.length > 0) {
                  setIsActiveToolTips(false);
                }
                props.onEmailOrPhoneChange(text);
              }}
              value={props.form.emailOrPhone}
              returnKeyType="next"
              blurOnSubmit={true}
              placeholder={t('auth:form.emailOrPhone')}
              keyboardType="email-address"
              autoCapitalize="none"
            />
          </ToolTips>
        );
      case AUTH_MODE.CONFIRM_OTP:
        return (
          <AuthField
            isValid={props.formValidation.code}
            disabled={props._isBusy}
            icon="blank"
            onChangeText={props.onCodeChange}
            value={props.form.code}
            blurOnSubmit={true}
            placeholder={t('auth:twoFactor.smsVerification')}
            keyboardType="phone-pad"
          />
        );
      case AUTH_MODE.RESET_PASSWORD:
        return (
          <AuthField
            isValid={props.formValidation.email}
            disabled={props._isBusy}
            icon="email"
            onFocus={props.onEmailTextInputFocused}
            onChangeText={props.onEmailChange}
            value={props.form.email}
            returnKeyType="next"
            blurOnSubmit={true}
            onSubmitEditing={handleSubmitPressed}
            placeholder={t('auth:form.email')}
            keyboardType="email-address"
            autoCapitalize="none"
          />
        );
    }
  };

  const startCountdown = () => {
    if (props.authMode === AUTH_MODE.VERIFY_OTP) {
      setCountDown(MAX_COUNTDOOWN);
      const interval = setInterval(() => {
        setCountDown(prevState => prevState - 1);
      }, 1000);
      cdInterval.current = interval;
    }
    return () => clearInterval(cdInterval.current);
  };

  const stopCountdown = () => {
    if (countDown <= 0) {
      dispatch(landingActions.setAuthMode(AUTH_MODE.VERIFIED));
      clearInterval(cdInterval.current);
      forceUpdate();
    }
  };

  useEffect(startCountdown, [props.authMode]);
  useEffect(stopCountdown, [countDown]);

  const validEmailOrPhone = isEmail(props.form.emailOrPhone) || isPhone(props.form.emailOrPhone);
  return (
    <View>
      <AuthFormHeader authMode={props.authMode} language={props.language} />
      <View style={styles.authContainer}>
        {_renderFormTitle(props.authMode)}
        {_renderAuthHeader(props.authMode)}
        {_renderAuthField(props.authMode)}
        {props.hasPendingCreds && props.formValidation.errorTextBottom ? (
          <View style={styles.requestAccessContainer}>
            <Icon style={styles.iconContainer} name="alert-circle" color="#FC4C5E" size={16} />
            <Text style={styles.errorText}>{props.formValidation.errorTextBottom}</Text>
          </View>
        ) : null}
        {props.authMode === AUTH_MODE.VERIFY_OTP && (
          <React.Fragment>
            <Image source={require('../../assets/img/verified-otp.png').default} style={styles.verifyImageStyle} />
            <Text>Now lets setup your password ({countDown})</Text>
          </React.Fragment>
        )}
        {props.formValidation.errorText !== '' ? (
          <View style={styles.requestAccessContainer}>
            <Icon style={styles.iconContainer} name="alert-circle" color="#FC4C5E" size={16} />
            <Text style={styles.errorText}>{props.formValidation.errorText}</Text>
          </View>
        ) : null}
        {props.formValidation.errorText === '' && props._capsWarning ? (
          <Text style={styles.errorText}>{t('auth:form.capsLock')}</Text>
        ) : null}
        {props._isBusy ? (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size="small" color="#3cd070" />
          </View>
        ) : null}
        {!props._isBusy && props.authMode === AUTH_MODE.LOGIN && !props.formValidation.isNeedAccessFederated && (
          <Button
            disabled={!props.form.emailOrPhone || props._isBusy}
            onPress={props.onSubmitLogin}
            title={t('auth:form.login')}
            style={styles.button}
          />
        )}
        {!props._isBusy && props.formValidation.isNeedAccessFederated && props.authMode === AUTH_MODE.LOGIN && (
          <RoundedButton
            onPress={props.onSubmitRequestFederated}
            title={t('auth:form.requestButton')}
            backgroundColor="#56C768"
            style={styles.button}
            inverse={true}
          />
        )}
        {props.authMode === AUTH_MODE.SIGN_UP && props._emailSentSuccess ? (
          <Text style={{ ...(styles.emailSentNotification as TextStyle), color: 'green' }}>
            {t('auth:form.checkEmail')}
          </Text>
        ) : null}
        {props.authMode === AUTH_MODE.SIGN_UP && <div id="recaptcha-container"></div>}
        {!props._isBusy && props.authMode === AUTH_MODE.SIGN_UP && (
          <Button
            disabled={!validEmailOrPhone || props._isBusy || props._emailSentSuccess}
            onPress={props.onSubmitSignup}
            title={t('auth:form.verifyAccount')}
            style={styles.button}
          />
        )}
        {!props._isBusy && props.authMode === AUTH_MODE.RESET_PASSWORD && (
          <Button
            disabled={!props.form.email || props._isBusy}
            onPress={props.onSubmitPasswordReset}
            title={t('auth:form.submitChangePassword')}
            style={styles.button}
          />
        )}
        {!props._isBusy && props.authMode === AUTH_MODE.CONFIRM_OTP && (
          <React.Fragment>
            <Button
              disabled={!props.form.code || props._isBusy}
              onPress={props.onSubmitValidation}
              title={t('auth:twoFactor.confirmCode')}
              style={styles.button}
            />
          </React.Fragment>
        )}
        {props.freezeBanner ? <AuthFreezeBanner /> : null}

        {props.authMode === AUTH_MODE.LOGIN && !props.formValidation.isNeedAccessFederated && (
          <Text style={styles.forgotPasswordLink} onPress={props.onChangeSectionForgotPassword}>
            {t('auth:form.forgotPassword')}
          </Text>
        )}
        {props.authMode === AUTH_MODE.RESET_PASSWORD && (
          <Text style={[styles.forgotPasswordLink, styles.forgotPasswordFooter]} onPress={props.onChangeSectionLogin}>
            {t('auth:form.backToLogin')}
          </Text>
        )}
        {props.authMode === AUTH_MODE.CONFIRM_OTP && (
          <Text style={styles.resendOTP} onPress={props.onChangeSectionForgotPassword}>
            <Text>{t('auth:form.resendOTP')}</Text>
            <Text style={[styles.resendLink, theme.styles.textPrimary]} onPress={props.onPressResendOTP}>
              {t('auth:form.resendButton')}
            </Text>
          </Text>
        )}
        {![AUTH_MODE.RESET_PASSWORD, AUTH_MODE.VERIFY_OTP].includes(props.authMode) ? <Divider /> : null}

        <AuthFormFooter
          authMode={props.authMode}
          language={props.language}
          onChangeSectionLogin={() => {
            setIsActiveToolTips(false);
            props.onChangeSectionLogin();
          }}
          onChangeSectionSignup={() => {
            setIsActiveToolTips(false);
            props.onChangeSectionSignup();
          }}
          onContactWhatsAppSupport={props.onContactWhatsAppSupport}
          handleVisibleTutorialModal={props.handleVisibleTutorialModal}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  requestAccessContainer: {
    flex: 1,
    width: 250,
    flexDirection: 'row'
  },
  iconContainer: {
    marginRight: 5
  },
  authContainer: {
    width: SCREEN_WIDTH > 500 ? 670 : 350,
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRadius: 7.5,
    paddingVertical: 24
  },
  authConfirmCode: {
    paddingBottom: 20
  },
  formTitle: {
    fontSize: 24,
    lineHeight: 36,
    marginBottom: 16
  },
  authHeader: {
    marginTop: 30,
    marginHorizontal: 30,
    marginBottom: 20,
    textAlign: 'center'
  },
  resendOTP: {
    flexDirection: 'row',
    alignItems: 'flex-end'
  },
  resendLink: {
    fontWeight: '700'
  },
  errorText: {
    width: 250,
    fontSize: 12.5,
    textAlign: 'left',
    color: '#FA6161',
    marginBottom: 10
  },
  loadingContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 42.5,
    marginVertical: 10
  },
  button: {
    width: 200,
    marginVertical: 10
  },
  forgotPasswordLink: {
    opacity: 0.55,
    textAlign: 'center',
    fontWeight: '700',
    fontSize: 16,
    color: theme.colors.nimblyGreen
  },
  forgotPasswordFooter: {
    marginBottom: 30
  },
  emailSentNotification: {
    textAlign: 'center'
  },
  verifyImageStyle: {
    width: 75,
    height: 75,
    marginBottom: 24
  },
  tooltipContentContainer: {
    padding: 14
  },
  loginInstructionTitle: {
    fontWeight: 'bold',
    fontSize: 14
  },
  instruction: {
    fontSize: 14
  }
});

export default AuthForm;
