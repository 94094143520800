import { useIsFocused } from '@react-navigation/native';
import imageCompression from 'browser-image-compression';
import firebase from 'firebase';
import update from 'immutability-helper';
import { cloneDeep } from 'lodash';
import debounce from 'lodash/debounce';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActionSheetIOS,
  Alert,
  ImageStyle,
  Keyboard,
  NativeScrollEvent,
  Platform,
  ScrollView,
  TextStyle,
  ViewStyle
} from 'react-native';
import { connect } from 'react-redux';
import { firebaseConnect, FirebaseReducer } from 'react-redux-firebase';
import { compose } from 'redux';
import { ErrorCode } from '../../helpers/customError';
import { SubmitReportPageProps } from '../../routes/submitreport';
import { RootState } from '../../store/reducers';
import { reportActions } from '../../store/reducers/report';
import * as reportcacheThunks from '../../store/reducers/reportcache/reportcache.actionThunk';
import { reportsubmitActions } from '../../store/reducers/reportsubmit';
import { toastActions } from '../../store/reducers/toast';
import { FirebaseConnect, InjectedSite } from '../../typing/types';
import {
  EmailTarget,
  Organization,
  Questionnaire,
  Report,
  ReportSection,
  ReportStatus,
  ReportSummary,
  Signature,
  Site,
  User
} from '../../utils/classes';
import { errorLogger } from '../../utils/errorLogger';
import { getSKUFromReport } from '../../utils/inventory';
import { ModalConfig } from '../global/ConfirmModal';
import SubmitReportContainer from './SubmitReportContainer';
import SubmitSATReportContainer from './SubmitSATReportContainer';
import getEmailTargets from './utils/getEmailTargets';
import getInitialSignatures from './utils/getInitialSignatures';
import getReportScore from './utils/getReportScore';
import { enums } from '@nimbly-technologies/nimbly-common';
import { isReportDeviceCheckoutTime } from '../../utils/getReportTime';
type SubmitReportDispatchProps = ReturnType<typeof mapDispatchToProps>;

type StateProps = {
  /** The Redux Firebase Auth Object */
  auth: FirebaseReducer.AuthState;
  /** The User Profile Object */
  profile: FirebaseReducer.Profile<User>;
  /** The selected site */
  selectedSite: Site & InjectedSite;
  /** The selected site key */
  selectedSiteKey: string;
  /** The chosen report */
  selectedReport: Report;
  /** The chosen report key */
  selectedReportKey: string;
  /** Report saved as a cache for offline and multi site support */
  cacheSummary: ReportSummary;
  /** Language to be used throughout the app */
  language: string;
  /** The organization details */
  organization: Organization | null | undefined;
  /** True to display a toast and vice versa */
  toastDisplay: boolean;
  /** List of all questionnaires that corresponding to the site */
  allQuestionnaires: { [key: string]: Questionnaire };
  /** True if account is a dummy */
  isDummy: boolean;
  /** True if the device is connected to the internet */
  isConnected: boolean;
  /** Get - profile color of the organization */
  profileColor: string;
  /** Get - state tutorial mode or not */
  isTutorialMode: boolean;
  /** True when a report upload is in progress triggered from home screen */
  isUploading: boolean;
  /** `Report` that is set to be uploaded */
  uploadingReport: Report;
  /** Get device user information */
  deviceProfile: {
    binaryVersion: string;
    carrier: string;
    locale: string;
    phoneBrand: string;
    phoneModel: string;
    phoneOS: string;
    phoneOSVersion: string;
  } | null;
  temporaryPhotoURI: string;
  /** Get - journey state to check if report is for a journey */
  journey: any;
  irfSATEnabled: boolean;
};
type EnhancedProps = StateProps & SubmitReportDispatchProps & FirebaseConnect;
type SubmitReportProps = SubmitReportPageProps & EnhancedProps;

/**
 * Submit Report is used for submitting the report using check-out and handle the report that are going to be uploaded
 * depending on the internet connection.
 *
 * It also support additional features about the report details such as
 * - Show report overview (Report Summary that consist of number of flags and list of the top issues)
 * - Add a signature
 * - Choose targets for the email report PDF
 */

export const SubmitReport = (props: SubmitReportProps) => {
  const isFocused = useIsFocused();
  const { t } = useTranslation(['submitReport', 'common', 'profile']);
  const [email, setEmail] = useState<string>('');
  const [emailTargets, setEmailTargets] = useState<EmailTarget[]>([]);
  const [signatures, setSignatures] = useState<Signature[]>([]);
  const [selfileSignature, setSelfileSignature] = useState<Signature[]>([]);

  const [signaturesValidation, setSignaturesValidation] = useState<boolean[]>([]);
  const [selfieSignatureValidation, setSelfieSignatureValidation] = useState<boolean | null>(null);
  const [_editingSignIndex, setEditingSignIndex] = useState<number | null>(null);
  const [_isBusy, setIsBusy] = useState<boolean>(false);
  const [_domainIsValid, setDomainIsValid] = useState<boolean>(false);
  const [_emailIsValid, setEmailIsValid] = useState<boolean>(false);

  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [showTooltipSignature, setShowTooltipSignature] = useState<boolean>(false);
  const [showTooltipTarget, setShowTooltipTarget] = useState<boolean>(false);

  const [scrollProperty, setScrollProperty] = useState<any>(null);
  const [signatureCardYPosition, setSignatureCardYPosition] = useState<number>(0);
  const [isFocusedTooltip, setIsFocusesTooltip] = useState<boolean>(false);
  const [showSignatureModal, setShowSignatureModal] = useState<boolean>(false);
  const [showCamera, setShowCamera] = useState<boolean>(false);
  const [showConfirmModalSelfie, setshowConfirmModal] = useState<boolean>(false);
  const [modalConfig, setModalConfig] = useState<ModalConfig>({
    title: '',
    subtitle: '',
    // eslint-disable-next-line no-empty
    options: [{ text: t('common:cancel'), action: () => null, style: 'cancel' }]
  });

  useEffect(() => {
    if (
      isFocused &&
      props.isTutorialMode &&
      ((scrollProperty !== null && scrollProperty.contentOffset.y >= signatureCardYPosition + 200) ||
        (scrollProperty !== null && signatureCardYPosition <= scrollProperty.layoutMeasurement.height + 70)) &&
      !isFocusedTooltip
    ) {
      //show tooltip on signature card
      setShowTooltipSignature(true);
      setIsFocusesTooltip(true);
    }
  }, [isFocused, scrollProperty, signatureCardYPosition]);

  // Get Email from the user that affiliated with the site
  useEffect(() => {
    const {
      auth,
      profile,
      selectedSite,
      selectedReport: { sections },
      route,
      journey
    } = props;
    const emails = getEmailTargets(auth, profile, selectedSite, sections, route, journey);
    setEmailTargets(emails || []);
  }, []);

  // Prepare Initial Signatures
  useEffect(() => {
    setInitialSignatures();
    return Keyboard.dismiss();
  }, []);

  // Prepare the canvas as a initial signature
  const setInitialSignatures = () => {
    const { selectedReport, selectedSite } = props;
    const initialSignatures = getInitialSignatures(selectedReport, selectedSite);
    setSignatures(initialSignatures);
    setSignaturesValidation(Array(initialSignatures.length).fill(true));
  };

  useEffect(() => {
    setSignaturesValidation(prevState => {
      if (prevState.includes(false)) {
        return Array(prevState.length).fill(true);
      }
      return prevState;
    });
  }, [props.toastDisplay]);

  const scrollViewRef = useRef<ScrollView>(null);

  const scrollToEnd = useCallback(() => {
    if (scrollViewRef && scrollViewRef.current) {
      scrollViewRef.current.scrollToEnd();
    }
  }, [scrollViewRef]);

  const handleScrollToReportTargets = useCallback(() => {
    scrollToEnd();
    setShowTooltipSignature(false);
    setShowTooltipTarget(true);
  }, [scrollToEnd, setShowTooltipSignature]);

  const handleTextInputFocused = () => {
    scrollToEnd();
  };

  const handleTextInputBlur = () => {
    Keyboard.dismiss();
  };

  const handleEmailChange = (text: string) => {
    validateEmail(text);
    setEmail(text);
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const options = {
    maxSizeMB: 0.03,
    maxWidthOrHeight: 1920
  };

  const handleTakePhoto = async uri => {
    setShowCamera(false);
    const compressedFile = await imageCompression(dataURLtoFile(uri, `testing.jpg`), options);
    handleCapture(uri, compressedFile);
  };

  const handleToggleEmailTarget = (emailTarget: string) => {
    if (emailTarget && _emailIsValid && _domainIsValid) {
      setEmailTargets(prevState => {
        const newEmailTargets = cloneDeep(prevState);
        const search = newEmailTargets.findIndex(target => target.email === emailTarget);
        if (search > -1 && !newEmailTargets[search].setByAdmin) {
          newEmailTargets[search] = {
            ...newEmailTargets[search],
            enabled: !newEmailTargets[search].enabled
          };
        } else if (search === -1 || !newEmailTargets[search].setByAdmin) {
          newEmailTargets.push({
            email: emailTarget,
            enabled: true,
            status: 'unsent',
            setByAdmin: false
          });
        }
        setEmail('');
        return newEmailTargets;
      });
    }
  };

  const completeInventoryRecord = async (now: string) => {
    const {
      allQuestionnaires,
      selectedSite,
      selectedSiteKey,
      selectedReportKey,
      profile,
      selectedReport,
      isConnected
    } = props;

    let selectedReportQuestionnaire =
      allQuestionnaires && allQuestionnaires[selectedReport.questionnaire!]
        ? allQuestionnaires[selectedReport.questionnaire!]
        : null;

    if (props.isTutorialMode) {
      // do nothing
    } else {
      // This may happen because this report use questionnaire that is not the latest version
      // while populated questionnaire only calls the latest
      if (!selectedReportQuestionnaire) {
        if (!isConnected) {
          // TODO: Handle no internet no questionnaire
          // no internet
          throw new ErrorCode('custom', t('submitReport:error.internet'));
        } else {
          const questionnaireRef = firebase
            .database()
            .ref(`/questionnaire/${profile.organization}/${selectedReport.questionnaire!}`);
          const questionnaireSnap = await questionnaireRef.once('value');
          const questionnaire: Questionnaire = questionnaireSnap.val();

          if (!questionnaire) {
            // TODO: handle no questionnaire found
            throw new ErrorCode('custom', t('submitReport:error.questionnaire'));
          } else {
            selectedReportQuestionnaire = questionnaire;
          }
        }
      }

      if (selectedReportQuestionnaire.type === 'inventory') {
        const allSKU = getSKUFromReport(selectedReport);
        const baseRef = `/inventory/${profile.organization}/${selectedSiteKey}/${selectedReportKey}`;
        const inventoryUpdates = allSKU.map(sku => {
          const skuRef = `${baseRef}/${sku}`;
          return firebase.database().ref(skuRef).update({ updatedAt: now });
        });
        return inventoryUpdates;
      } else if (!selectedSite.isMultiSite) {
        // skip inventory-v2 on team audit for now
        if (
          selectedReportQuestionnaire.type === 'inventory-v2' ||
          selectedReportQuestionnaire.questions.findIndex(q => q.type === 'inventory-v2') > -1
        ) {
          const targetIndex = selectedReport.questions.findIndex(question => question.type === 'inventory-v2');
          const inventoryQuestion = selectedReport.questions[targetIndex];
          const baseRef = `/inventory/${profile.organization}/${selectedSiteKey}/${selectedReportKey}`;

          if (inventoryQuestion.skuInventory) {
            const inventoryUpdates = Object.keys(inventoryQuestion.skuInventory).map(skuKey => {
              const skuRef = `${baseRef}/${skuKey}`;
              return firebase.database().ref(skuRef).update({ updatedAt: now });
            });
            return inventoryUpdates;
          }
        }
      } else {
        return [];
      }
    }
    return [];
  };

  const handleConfirmCheckOut = debounce(() => {
    let invalidCount = validateSignature();
    const checkSelfieSignature: boolean = validateSelfieSignature();
    if (!invalidCount && checkSelfieSignature) {
      const editModalConfig: ModalConfig = {
        title: 'Check-Out',
        subtitle: t('submitReport:prompt.checkout'),
        options: [
          {
            text: t('common:yes'),
            action: () => {
              handleCheckOut();
              setshowConfirmModal(false);
            },
            style: 'destructive'
          },
          {
            text: t('common:no'),
            action: () => {
              setshowConfirmModal(false);
            },
            style: 'cancel'
          }
        ]
      };

      setModalConfig(editModalConfig);
      setshowConfirmModal(true);
    } else {
      if (invalidCount) {
        let errorMessage = t('submitReport:error.signature', { invalidCount });
        alert(errorMessage);
        props.createToast(errorMessage, true, 1);
      } else {
        let errorMessage = t('submitReport:error.selfieSignature');
        alert(errorMessage);
        props.createToast(errorMessage, true, 1);
      }
    }
  }, 250);

  const handleDrawOptions = (signIdx: number) => {
    const { language, selectedReport } = props;

    const languageOption: any = {
      en: ['Cancel', 'Edit', 'Remove'],
      id: ['Batal', 'Ubah', 'Hapus']
    };

    const editSignModalConfig: ModalConfig = {
      title: t('submitReport:prompt.editSignature'),
      subtitle: t('submitReport:prompt.signature'),
      options: [
        {
          text: t('submitReport:change'),
          action: () => {
            handleCancelDrawSign(signIdx);
            setshowConfirmModal(false);
          },
          style: 'destructive'
        },
        {
          text: t('common:delete'),
          action: () => {
            handleRemoveSign(signIdx);
            setshowConfirmModal(false);
          },
          style: 'destructive'
        },
        {
          text: t('common:no'),
          action: () => {
            setshowConfirmModal(false);
          },
          style: 'cancel'
        }
      ]
    };
    setModalConfig(editSignModalConfig);
    setshowConfirmModal(true);
  };

  const handleDeleteSelfieSignature = async () => {
    const { selectedReport } = props;
    const { selfieSignatures } = selectedReport;

    if (selectedReport && selectedReport.selfieSignatures) {
      const selfieSignaturePath = selectedReport!.selfieSignatures![0].path;

      const newSelfieSignatures = selfieSignatures;
      newSelfieSignatures![0].path = '';
      setSelfieSignatureValidation(null);

      updateReport({
        ...selectedReport,
        selfieSignatures: newSelfieSignatures
      });
    }

    return Promise.resolve(true);
  };

  const handleActivateImagePicker = () => {
    const { language, selectedReport } = props;
    const { selfieSignatures } = selectedReport;

    if (selfieSignatures && selfieSignatures!.length && selfieSignatures![0].path) {
      const editModalConfig: ModalConfig = {
        title: t('profile:editPhoto'),
        subtitle: t('profile:titlePhoto'),
        options: [
          {
            text: t('profile:delete'),
            action: () => {
              setshowConfirmModal(false);
              handleDeleteSelfieSignature().then();
            },
            style: 'destructive'
          },
          {
            text: t('common:cancel'),
            action: () => {
              setshowConfirmModal(false);
            },
            style: 'cancel'
          }
        ]
      };

      setshowConfirmModal(!showConfirmModalSelfie);
      setModalConfig(editModalConfig);
    } else {
      setShowCamera(true);
    }
  };

  const handleCapture = (uri: string, signatureFile: File) => {
    const { selectedReport } = props;

    if (selectedReport && selectedReport.selfieSignatures) {
      const selfieSignatures = selectedReport.selfieSignatures.slice();
      // const newSelfieSignatures = [{ path: uri, title: selfieSignatures[0].title || '' }];
      setSelfileSignature([{ path: uri, title: selfieSignatures[0].title || '' }]);
      updateReport({ ...selectedReport, selfieSignaturesFile: [signatureFile] });
    }
  };

  const handleCheckOut = async () => {
    const {
      allQuestionnaires,
      selectedSite,
      language,
      organization,
      selectedSiteKey,
      selectedReportKey,
      selectedReport,
      auth,
      cacheSummary,
      profile,
      isDummy
    } = props;

    setIsBusy(true);
    // Location check passed - update report
    // const serverTime = firebase.database().getServerTime();
    const now = moment().toISOString(true);
    const allEmailTargets = emailTargets.filter(target => target);
    const reportCompletedTime = isReportDeviceCheckoutTime(selectedReport.datetimeOut, now);
    const finalReport: Report = {
      ...selectedReport,
      datetimeOut: reportCompletedTime,
      datetimeUpdated: now,
      emailTargets: allEmailTargets,
      status: 'complete'
    };

    try {
      const { flagCount, scoreRaw, scoreTotal, scoreWeighted } = getReportScore(selectedReport);

      const reportSummary: ReportSummary = selectedSite.isMultiSite
        ? {
            ...cacheSummary,
            datetimeUpdated: now,
            isMakeUp: finalReport.isMakeUp,
            flagCount,
            scoreWeighted,
            scoreRaw,
            scoreTotal,
            status: 'complete'
          }
        : {
            ...cacheSummary,
            datetimeOut: reportCompletedTime,
            datetimeUpdated: now,
            isMakeUp: finalReport.isMakeUp,
            flagCount,
            scoreWeighted,
            scoreRaw,
            scoreTotal,
            status: 'complete'
          };

      if (selectedSite.hasInventory) {
        await completeInventoryRecord(now);
      }

      props.saveCheckoutTime(now);
      props.setReportEmailTargets(allEmailTargets);
      props.setReportStatus('complete');

      if (props.isTutorialMode) {
        // do nothing
      } else if (selectedSite.isMultiSite) {
        props.addPendingMulti(selectedSiteKey, selectedReportKey);
      } else {
        props.addPendingSingle(selectedSiteKey, finalReport.scheduleKey!, selectedReportKey);
      }

      props.updateCacheReport(selectedSiteKey, selectedReportKey, finalReport);
      props.updateCacheReportSummary(selectedSiteKey, selectedReportKey, reportSummary);

      // only calls action when no report is in progress
      const selectedQuestionnaireKey = selectedReport.questionnaire;

      // site with `isMultiSite` has no questionnaire name
      let selectedQuestionnaireName = '';
      if (!selectedSite.isMultiSite) {
        if (allQuestionnaires[selectedQuestionnaireKey as string]) {
          selectedQuestionnaireName = allQuestionnaires[selectedQuestionnaireKey as string].title;
        }
      }

      props.setReportToUpload(
        selectedSite,
        selectedSiteKey,
        selectedQuestionnaireName,
        selectedReportKey,
        finalReport,
        reportSummary
      );

      props.navigation.navigate('UploadReport', {
        profileColor: props.profileColor,
        originTab: props.route.params.originTab || 'HomeTab',
        siteName: props.selectedSite.name
      });

      // setIsBusy(false);
    } catch (error) {
      if (error.code && error.code === 'custom') {
        props.createToast(error.message);
        return;
      }
      if (error) {
        errorLogger(auth!.uid, profile.organization, selectedSiteKey, selectedReportKey, error, 'SubmitReport.tsx');
      }
      props.createToast(t('submitReport:error.submit'), true);
    }
  };

  const validateEmail = (target: string) => {
    const emailCheck = '^[A-Z0-9._%+-]+@[A-Z0-9.-]{2,}[.][A-Z]{2,4}$';
    const domainCheck = `^[a-zA-Z0-9.!#$%&’*+/=?^_\`{|}~-]+@${props.organization!.emailDomain}$`;
    const emailRegex = new RegExp(emailCheck, 'i');
    const domainRegex = new RegExp(domainCheck, 'i');
    setEmailIsValid(emailRegex.test(target));
    setDomainIsValid(domainRegex.test(target));
  };

  const validateSignature = () => {
    let validate = signaturesValidation;
    let index = 0;
    let invalidCount = 0;

    for (const { isSigned, name, position } of signatures) {
      if (!isSigned || !name || !position) {
        invalidCount += 1;
        validate = update(validate, {
          [index]: { $set: false }
        });
      } else {
        validate = update(validate, {
          [index]: { $set: true }
        });
      }
      index += 1;
    }
    setSignaturesValidation(validate);
    return invalidCount;
  };

  // Change signIdx index to trigger draw signature screen
  const handleDrawSignIndex = (signIdx: number) => {
    setEditingSignIndex(signIdx);
  };

  const validateSelfieSignature = () => {
    const { selfieSignatures } = props.selectedReport;
    if (!selfieSignatures || !selfieSignatures.length) {
      setSelfieSignatureValidation(true);
      return true;
    }
    if (selfieSignatures && selfieSignatures.length && selfileSignature.length) {
      if (selfileSignature[0].path) {
        setSelfieSignatureValidation(true);
        return true;
      }
    }
    setSelfieSignatureValidation(false);
    return false;
  };

  /**
   * Draw Signature Screen whenever index changes
   * As using setState makes the state _editingSignIndex late by one render in this case,
   * the solution is using useEffect with some condition to get the latest state
   * - TODO: Find better approach to get latest state from useState hooks
   */
  useEffect(() => {
    if (_editingSignIndex !== null) {
      handleDrawSign();
    }
  }, [_editingSignIndex]);

  const handleDrawSign = async () => {
    const { selectedSiteKey, selectedReportKey, auth, profile } = props;
    try {
      const sign = signatures[_editingSignIndex!];
      setShowSignatureModal(true);
    } catch (error) {
      errorLogger(auth.uid, profile.organization, selectedSiteKey, selectedReportKey, error, 'SubmitReport.tsx');
    }
  };

  const handleCancelDrawSign = async (signIdx: number) => {
    setEditingSignIndex(null);
    const { selectedReport } = props;

    try {
      const updatedSign = update(signatures, {
        [signIdx]: {
          path: { $set: '' },
          isSigned: { $set: false }
        }
      });
      await Promise.all([setSignatures(updatedSign), updateReport({ ...selectedReport, signatures: updatedSign })]);
      setEditingSignIndex(signIdx);
    } catch (error) {}
  };

  const handleRemoveSign = async (signIdx: number) => {
    const { selectedReport, auth, profile, selectedSiteKey, selectedReportKey } = props;
    setEditingSignIndex(null);
    try {
      const updatedSign = update(signatures, {
        [signIdx]: {
          path: { $set: '' },
          isSigned: { $set: false },
          name: { $set: '' },
          position: { $set: '' }
        }
      });

      setSignatures(updatedSign);
      updateReport({ ...selectedReport, signatures: updatedSign });
    } catch (error) {}
  };

  const handleSignTextInput = (i: number, key: string, v: string) => {
    const { selectedReport } = props;

    const updatedSign = update(signatures, {
      [i]: {
        [key]: { $set: v }
      }
    });
    setSignatures(updatedSign);

    updateReport({ ...selectedReport, signatures: updatedSign });
  };

  const handleScrollProperty = (nativeEvent: NativeScrollEvent) => {
    setScrollProperty(nativeEvent);
  };

  const handleShowTooltipSignature = (bool: boolean) => {
    setShowTooltipSignature(bool);
  };

  const handleChangeTooltipTarget = (bool: boolean) => {
    setShowTooltipTarget(bool);
  };

  const updateReport = (updatedReport: Report) => {
    const { selectedSiteKey, selectedReportKey } = props;
    props.updateCacheReport(selectedSiteKey, selectedReportKey, updatedReport);
    props.selectReport(selectedReportKey, updatedReport);
  };

  const updateReportSectionStatus = (selectedSiteValue: any, statusText: string) => {
    const { selectedSiteKey, selectedReportKey, selectedReport, profile } = props;
    const ref = `/reportSection/${profile.organization}/${selectedSiteKey}/${selectedReportKey}/${selectedSiteValue.sectionIdx}/${selectedSiteValue.doneBy}`;
    firebase.database().ref(ref).update({ status: statusText, datetimeUpdated: new Date() });
    updateReport(selectedReport);
  };

  const handleModalConfirmChangeSelfie = () => {
    setshowConfirmModal(false);
  };

  const handleCloseCamera = () => {
    setshowConfirmModal(false);
    setShowCamera(false);
  };

  const ReportContainerProps = {
    _isBusy: _isBusy,
    auth: props.auth,
    isUploading: isUploading,
    profile: props.profile,
    emailTargets: emailTargets,
    email: email,
    editingSignIndex: _editingSignIndex,
    _emailIsValid: _emailIsValid,
    _domainIsValid: _domainIsValid,
    navigation: props.navigation,
    selectedSite: props.selectedSite,
    modalConfig: modalConfig,
    selectedSiteKey: props.selectedSiteKey,
    selectedReport: props.selectedReport,
    selectedReportKey: props.selectedReportKey,
    signatures: signatures,
    selfieSignatures: selfileSignature.length ? selfileSignature : props.selectedReport?.selfieSignatures || null,
    signaturesValidation: signaturesValidation,
    selfieSignatureValidation: selfieSignatureValidation,
    profileColor: props.profileColor,
    language: props.language,
    organization: props.organization!,
    toastDisplay: props.toastDisplay,
    scrollViewRef: scrollViewRef,
    allQuestionnaires: props.allQuestionnaires,
    isDummy: props.isDummy,
    isConnected: props.isConnected,
    isTutorialMode: props.isTutorialMode,
    showSignatureModal: showSignatureModal,
    showTooltipSignature: showTooltipSignature,
    showTooltipTarget: showTooltipTarget,
    showCamera: showCamera,
    showModalConfirmChangeSelfie: showConfirmModalSelfie
  };

  return props.irfSATEnabled && props.selectedSite?.isMultiSite ? (
    <SubmitSATReportContainer
      {...ReportContainerProps}
      updateReportSectionStatus={updateReportSectionStatus}
      handleCloseCamera={handleCloseCamera}
      handleConfirmCheckOut={handleConfirmCheckOut}
      handleDrawSignIndex={handleDrawSignIndex}
      handleTakePhoto={handleTakePhoto}
      handleModalConfirmChangeSelfie={handleModalConfirmChangeSelfie}
      handleRemoveSign={handleRemoveSign}
      handleSignTextInput={handleSignTextInput}
      handleToggleEmailTarget={handleToggleEmailTarget}
      handleEmailChange={handleEmailChange}
      handleTextInputFocused={handleTextInputFocused}
      handleTextInputBlur={handleTextInputBlur}
      handleDrawOptions={handleDrawOptions}
      handleChangeTooltipTarget={handleChangeTooltipTarget}
      handleScrollToReportTargets={handleScrollToReportTargets}
      handleShowTooltipSignature={handleShowTooltipSignature}
      handleScrollProperty={handleScrollProperty}
      handleSignatureCardYPosition={setSignatureCardYPosition}
      handleActivateImagePicker={handleActivateImagePicker}
      setShowSignatureModal={setShowSignatureModal}
      setEditingSignIndex={setEditingSignIndex}
      setSignatures={setSignatures}
      updateReport={updateReport}
    />
  ) : (
    <SubmitReportContainer
      {...ReportContainerProps}
      handleConfirmCheckOut={handleConfirmCheckOut}
      handleDrawSignIndex={handleDrawSignIndex}
      handleTakePhoto={handleTakePhoto}
      handleModalConfirmChangeSelfie={handleModalConfirmChangeSelfie}
      handleRemoveSign={handleRemoveSign}
      handleSignTextInput={handleSignTextInput}
      handleToggleEmailTarget={handleToggleEmailTarget}
      handleEmailChange={handleEmailChange}
      handleTextInputFocused={handleTextInputFocused}
      handleTextInputBlur={handleTextInputBlur}
      handleDrawOptions={handleDrawOptions}
      handleChangeTooltipTarget={handleChangeTooltipTarget}
      handleScrollToReportTargets={handleScrollToReportTargets}
      handleShowTooltipSignature={handleShowTooltipSignature}
      handleScrollProperty={handleScrollProperty}
      handleSignatureCardYPosition={setSignatureCardYPosition}
      handleActivateImagePicker={handleActivateImagePicker}
      setShowSignatureModal={setShowSignatureModal}
      setEditingSignIndex={setEditingSignIndex}
      setSignatures={setSignatures}
      updateReport={updateReport}
    />
  );
};

export type SubmitReportStyles = {
  root: ViewStyle;
  container: ViewStyle;
  title: TextStyle;
  loading: ViewStyle;
  site: ViewStyle;
  sitePhoto: ViewStyle;
  sitePhotoImage: ImageStyle;
  sitePhotoColumn: ViewStyle;
  siteDetailsColumn: ViewStyle;
  siteName: TextStyle;
  siteSubtitle: TextStyle;
  siteText: TextStyle;
  heading: TextStyle;
  report: ViewStyle;
  reportFlags: ViewStyle;
  flagContainer: ViewStyle;
  redFlag: ViewStyle;
  yellowFlag: ViewStyle;
  greenFlag: ViewStyle;
  noFlag: ViewStyle;
  reportPoints: ViewStyle;
  reportPoint: TextStyle;
  targets: ViewStyle;
  target: ViewStyle;
  targetText: TextStyle;
  targetIcon: TextStyle;
  emailInput: TextStyle;
  validationContainer: ViewStyle;
  validationText: TextStyle;
  footer: ViewStyle;
  signButton: ViewStyle;
};

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.firebase.auth,
    deviceProfile: state.account.deviceProfile,
    profile: state.firebase.profile,
    selectedSite: state.site.selectedSite!,
    selectedSiteKey: state.site.selectedSiteKey!,
    selectedReport: state.report.selectedReport! as Report,
    selectedReportKey: state.report.selectedReportKey!,
    temporaryPhotoURI: state.report.temporaryPhotoURI,
    cacheSummary: state.reportcache.summaries![state.site.selectedSiteKey!]
      ? state.reportcache.summaries![state.site.selectedSiteKey!][state.report.selectedReportKey!]
      : {},
    language: state.firebase.profile.language || 'en',
    toastDisplay: state.toast.show,
    organization: state.organization.myOrganization,
    allQuestionnaires: state.questionnaires.data,
    isUploading: state.uploadreport.isUploading,
    uploadingReport: state.reportsubmit.uploadingReport,
    isDummy: state.account.isDummy,
    isConnected: state.network.isConnected,
    profileColor: state.profiletheme.color,
    isTutorialMode: state.tutorial.isTutorialMode,
    journey: state.journey.journey,
    irfSATEnabled: state.featureAccess.features[enums.Features.IRF_SAT]
  };
};

function mapDispatchToProps(dispatch: any) {
  return {
    /**
     * Creates a toast displaying a message based on the param
     * @param message - The text content of the toast
     * @param dismissButton - True if the toast is dismissable
     * @param duration - The duration of the toast
     */
    createToast: (message: string, dismissButton: boolean | undefined = false, duration: number | undefined = 0) =>
      dispatch(toastActions.createToast(message, dismissButton, duration)),
    /**
     * Save checkout time in the redux
     * @param time - time when auditor check-out
     */
    saveCheckoutTime: (time: string) => dispatch(reportActions.saveCheckoutTime(time)),
    /**
     * Set the email targets for the report
     * @param emailTargets - list of email target
     */
    setReportEmailTargets: (emailTargets: EmailTarget[]) => dispatch(reportActions.setReportEmailTargets(emailTargets)),
    /**
     * Set the report status
     * @param status - draft/complete/expired
     */
    setReportStatus: (status: ReportStatus) => dispatch(reportActions.setReportStatus(status)),
    /**
     * Save the report that ready to be uploaded
     * @param selectedSite - Selected site name
     * @param selectedSiteKey - Selected site key
     * @param selectedQuestionnaireName - Selected questionnaire name
     * @param selectedReportKey - Chosen report key
     * @param finalReport - latest report
     * @param reportSummary - report summary and details
     */
    setReportToUpload: (
      selectedSite: Site | InjectedSite,
      selectedSiteKey: string,
      selectedQuestionnaireName: string,
      selectedReportKey: string,
      finalReport: Report,
      reportSummary: ReportSummary
    ) =>
      dispatch(
        reportsubmitActions.setReportToUpload(
          selectedSite,
          selectedSiteKey,
          selectedQuestionnaireName,
          selectedReportKey,
          finalReport,
          reportSummary
        )
      ),
    /**
     * Save Multisite that is pending on redux
     * @param selectedSiteKey - Selected site key
     * @param selectedReportKey - Chosen report key
     */
    addPendingMulti: (selectedSiteKey: string, selectedReportKey: string) =>
      dispatch(reportsubmitActions.addPendingMulti(selectedSiteKey, selectedReportKey)),
    /**
     * Save Singlesite that is pending on redux
     * @param selectedSiteKey - Selected site key
     * @param questionnaireKey - Questionnaire key
     * @param selectedReportKey - Chosen report key
     */
    addPendingSingle: (selectedSiteKey: string, questionnaireKey: string, selectedReportKey: string) =>
      dispatch(reportsubmitActions.addPendingSingle(selectedSiteKey, questionnaireKey, selectedReportKey)),
    /**
     * Update report that already saved on the cache
     * @param selectedSiteKey - Selected site key
     * @param selectedReportKey - Chosen report key
     * @param updatedReport - The Updated Report Object
     */
    updateCacheReport: (selectedSiteKey: string, selectedReportKey: string, updatedReport: Report) =>
      dispatch(reportcacheThunks.updateCacheReport(selectedSiteKey, selectedReportKey, updatedReport)),
    /**
     * Update the report summary that already saved on the cache
     * @param selectedSiteKey - Selected site key
     * @param selectedReportKey - Chosen report key
     * @param reportSummary - The Updated Report Summary Object
     */
    updateCacheReportSummary: (selectedSiteKey: string, selectedReportKey: string, reportSummary: ReportSummary) =>
      dispatch(reportcacheThunks.updateCacheReportSummary(selectedSiteKey, selectedReportKey, reportSummary)),
    /**
     * Set camera to active to supress notifications showing
     * @param bool - flag status
     */
    setCameraActive: (bool: boolean) => dispatch(reportActions.setCameraActive(bool)),
    setCameraFormat: (format: 'photo' | 'video') => dispatch(reportActions.setCameraFormat(format)),
    setTemporaryPhotoURI: (value: string | null) => dispatch(reportActions.setTemporaryPhotoURI(value)),
    /**
     * Select the report and save the key
     * @param selectedReportKey - Chosen report key
     * @param selectedReport - Chosen Report Object
     */
    selectReport: (selectedReportKey: string | null, selectedReport: Report | ReportSection | null) =>
      dispatch(reportActions.selectReport(selectedReportKey, selectedReport))
  };
}

export default compose(firebaseConnect(), connect(mapStateToProps, mapDispatchToProps))(SubmitReport) as any;
