import { Report } from '../../../utils/classes';
import { destructConditionalQuestions } from '../../../utils/destructConditionalQuestions';
import { cloneDeep } from 'lodash';
import _renderFlagText from './renderFlagText';
import getRangeFlagColor from '../../questionnaire/utils/getRangeFlagColor';
import { QuestionTypes } from 'nimbly-common/lib/enumerators';

/**
 * Get report comments for overview details
 * @param selectedReport - The selected report
 */
export default function getReportComments(selectedReport: Report) {
  const questions = cloneDeep(selectedReport.questions);
  const comments: string[] = [];

  questions.sort((a, b) => a.score - b.score);
  let destuctQuestions = destructConditionalQuestions(questions);

  const answerFlagMap = {
    'green-flag': 'green',
    'yellow-flag': 'yellow',
    'red-flag': 'red'
  };
  destuctQuestions = destuctQuestions.map(question => {
    const newQuestion = { ...question };

    if (question.type === 'multiple-choice-score') {
      return {
        ...newQuestion,
        flag: question.flag
      };
    }

    if (question.type === 'checklist') {
      const { answers, checklistsV2 } = question;
      const answerSet = new Set(answers.map(answer => answer));

      if (checklistsV2) {
        const hasRedFlag = checklistsV2.some(checklist => checklist.hasRedFlag && answerSet.has(checklist.label));

        if (hasRedFlag)
          return {
            ...newQuestion,
            flag: 'red'
          };
      }
    }

    if (
      question.type !== 'open' &&
      question.type !== 'number' &&
      question.type !== 'inventory' &&
      question.type !== 'inventory-v2' &&
      question.type !== 'score' &&
      question.type !== 'range-flag'
    ) {
      return {
        ...newQuestion,
        flag: answerFlagMap[question.answer as keyof typeof answerFlagMap]
      };
    }

    if (question.type === 'range-flag') {
      const flag = getRangeFlagColor(question);

      return {
        ...newQuestion,
        flag: answerFlagMap[flag as keyof typeof answerFlagMap]
      };
    }

    return newQuestion;
  });
  destuctQuestions = destuctQuestions.sort((a, b) => {
    if (a.flag === 'red') {
      return -1;
    } else if (b.flag === 'red') {
      return 1;
    } else if (a.flag === 'yellow') {
      return -1;
    } else if (b.flag === 'yellow') {
      return 1;
    } else {
      return 0;
    }
  });

  destuctQuestions = destuctQuestions.filter(question => {
    if (question.flag === 'red' || question.flag === 'yellow') return true;

    return false;
  });

  // eslint-disable-next-line complexity
  destuctQuestions.forEach(question => {
    if (
      question.type === QuestionTypes.SCORE &&
      typeof question.answer === 'number' &&
      question.answer / question.score < 0.8
    ) {
      comments.push(`${question.content}: ${question.answer}/${question.score}`);
    } else if (question.type === QuestionTypes.MULTIPLE_CHOICE && question.answer !== 'not-applicable') {
      const choiceIndex: number = question.multipleChoices.indexOf(question.answer as string);
      // eslint-disable-next-line id-blacklist
      const alphabet = choiceIndex > -1 ? `${String.fromCharCode(65 + choiceIndex)}.` : '';
      comments.push(`${question.category}: ${alphabet} ${question.answer}`);
    } else if (
      question.type === QuestionTypes.RANGE_FLAG &&
      question.answer !== 'not-applicable' &&
      comments.length < 5
    ) {
      comments.push(`${question.category}: ${question.comment || _renderFlagText(getRangeFlagColor(question))}`);
    } else if (question.type === QuestionTypes.MULTIPLE_CHOICE_SCORE) {
      comments.push(`${question.category}${question.comment ? ': ' + question.comment : ''}`);
    } else if (question.type === QuestionTypes.CHECKLIST) {
      comments.push(`${question.category} : ${question.answer}`);
    } else if (
      question.type !== QuestionTypes.OPEN &&
      question.type !== QuestionTypes.NUMBER &&
      question.type !== QuestionTypes.INVENTORY &&
      question.type !== QuestionTypes.INVENTORY_V2 &&
      question.type !== QuestionTypes.SCORE &&
      question.type !== QuestionTypes.COMPETITOR &&
      question.answer !== 'yes' &&
      question.answer !== 'not-applicable' &&
      comments.length < 5
    ) {
      comments.push(
        `${question.category}${question.parentIndex ? '-Conditional' : ''}: ${
          question.comment || _renderFlagText(question.answer.toString())
        }`
      );
    }
  });

  return comments.slice(0, 5);
}
