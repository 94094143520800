import { action, ActionType } from 'typesafe-actions';
import { Reducer, AnyAction } from 'redux';

import { PreGeneratedReport } from '../../utils/classes';

export const SET_PREGENERATED_REPORT = '@payoff/SET_PREGENERATED_REPORT';
export const RESET_PREGENERATED_REPORT = '@payoff/RESET_PREGENERATED_REPORT';

export const setPreGeneratedReport = (report: PreGeneratedReport) => action(SET_PREGENERATED_REPORT, { report });

export const resetPreGeneratedReport = () => action(RESET_PREGENERATED_REPORT);

export const payoffActions = {
  setPreGeneratedReport,
  resetPreGeneratedReport
};

export type PayoffAction = ActionType<typeof payoffActions>;

type PayoffState = {
  readonly preGeneratedReport: PreGeneratedReport | null;
};
const initialState: PayoffState = {
  preGeneratedReport: null
};

export const payoffReducer: Reducer<PayoffState, PayoffAction> = (
  state = initialState,
  incomingAction: PayoffAction
) => {
  switch (incomingAction.type) {
    case SET_PREGENERATED_REPORT:
      return {
        ...state,
        preGeneratedReport: incomingAction.payload.report
      };
    case RESET_PREGENERATED_REPORT:
      return {
        ...state,
        preGeneratedReport: null
      };
    default:
      return state;
  }
};
