import React, { useMemo, useState } from 'react';
import { View, StyleSheet, ScrollView, TouchableOpacity, TouchableNativeFeedback, Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation, useRoute, RouteProp } from '@react-navigation/native';
import firebase from 'firebase';

// Components
import Text from '../../../../global/Text';
import { RootState } from '../../../../../store/reducers';
import SiteReportCalendar from './SiteReportFilterCalendarContainer';

// utils
import { useTranslation } from 'react-i18next';
import moment from 'moment';

// redux
import * as siteReportActions from '../../../../../store/reducers/siteReport/siteReport.action';
import { setActiveTab } from '../../../../../store/reducers/site/site';

// types
import * as Common from 'nimbly-common';
import { HomeStackParamList } from '../../../../../routes';

const SiteReportsFilter = () => {
  // state
  const [currentMonth, setCurrentMonth] = useState<number>(moment().valueOf());

  // reducer
  const dateType = useSelector<RootState, string>(state => state.siteReport.dateTypeTemp);
  const tempCustomStart = useSelector<RootState, string | null>(state => state.siteReport.customStartTemp);
  const tempCustomEnd = useSelector<RootState, string | null>(state => state.siteReport.customEndTemp);
  const organization = useSelector<RootState, Common.Organization | null | undefined>(
    state => state.organization.myOrganization
  );
  const tempSortDirection = useSelector<RootState, 'asc' | 'desc'>(state => state.siteReport.sortDirectionTemp);
  const route = useRoute<RouteProp<HomeStackParamList, 'ReportFilter'>>();
  const { t } = useTranslation(['site']);
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const handleApply = () => {
    firebase.analytics().logEvent('click_apply', {
      category: 'site_detail_page',
      siteName: route.params?.selectedSite?.name,
      filterName: dateType
    });
    dispatch(siteReportActions.setApplyReportFilter());
    navigation.goBack();
    dispatch(setActiveTab('reports'));
  };

  const handleSelectDate = (date: string) => {
    if (tempCustomStart && tempCustomStart === tempCustomEnd && moment(date).isAfter(moment(tempCustomStart))) {
      return dispatch(siteReportActions.setSiteReportsFilteredEndDateTemp(date));
    }
    dispatch(siteReportActions.setSiteReportFilteredStartDateTemp(date));
    dispatch(siteReportActions.setSiteReportsFilteredEndDateTemp(date));
  };

  const handleChangeSortDirection = (direction: 'asc' | 'desc') => {
    dispatch(siteReportActions.setSortDirection(direction));
  };
  const handleChangeNextMonth = () => {
    const nextMonth = moment(currentMonth).add(1, 'month').valueOf();
    setCurrentMonth(nextMonth);
  };
  const handleChangePreviousMonth = () => {
    const prevMonth = moment(currentMonth).subtract(1, 'month').valueOf();
    setCurrentMonth(prevMonth);
  };

  const renderTabs = useMemo(() => {
    const periodLabel =
      organization && organization.schedule
        ? organization.schedule.periodUnit.charAt(0) + organization.schedule.periodUnit.slice(1)
        : 'period';
    // filter tab options
    const tabs: { value: Partial<Common.enums.PeriodTypes> | 'current'; label: string }[] = [
      {
        value: Common.enums.PeriodTypes.CURRENT,
        label: t('site:siteReports.time.thisPeriod', { period: t(`site:siteReports.time.${periodLabel}`) })
      },
      {
        value: Common.enums.PeriodTypes.PREVIOUS,
        label: t('site:siteReports.time.lastPeriod', { period: t(`site:siteReports.time.${periodLabel}`) })
      },
      { value: Common.enums.PeriodTypes.LAST_30, label: t('site:siteReports.time.last-30') },
      { value: Common.enums.PeriodTypes.LAST_90, label: t('site:siteReports.time.last-90') },
      { value: Common.enums.PeriodTypes.LAST_6_M, label: t('site:siteReports.time.last-6-m') },
      { value: Common.enums.PeriodTypes.LAST_12_M, label: t('site:siteReports.time.last-12-m') },
      { value: Common.enums.PeriodTypes.LAST_YEAR, label: t('site:siteReports.time.last-year') },
      { value: Common.enums.PeriodTypes.MTD, label: t('site:siteReports.time.mtd') }
    ];

    const setActiveDateType = (value: (Common.enums.PeriodTypes & 'today') | null) => {
      dispatch(siteReportActions.setFilteredTempDates(value, organization!.schedule));
    };

    const getActive = (tabVal: Partial<Common.enums.PeriodTypes> | 'current') => {
      if (dateType === tabVal) {
        return true;
      }
      return false;
    };

    return tabs.map(t => (
      <TouchableOpacity
        style={[styles.button, getActive(t.value) ? styles.activeButton : {}]}
        onPress={() => setActiveDateType(t.value as any)}
        key={t.value}
      >
        <Text style={[styles.label, getActive(t.value) ? styles.activeLabel : {}]}>{t.label}</Text>
      </TouchableOpacity>
    ));
  }, [dateType, siteReportActions.setFilteredTempDates]);

  return (
    <View style={styles.root} testID="SiteReportsFilter-container">
      <View>
        <Text style={styles.timeTitle}>{t('site:siteReports.byTime')}</Text>
        <ScrollView horizontal={true} style={[styles.container]} showsHorizontalScrollIndicator={false}>
          {renderTabs}
        </ScrollView>
        <View style={styles.lineContainer}>
          <View style={styles.line} />
        </View>
      </View>
      <View style={styles.secondColumn}>
        <Text style={styles.title}>{t('site:siteReports.byCustom')}</Text>
        <SiteReportCalendar
          startDate={tempCustomStart}
          endDate={tempCustomEnd}
          currentMonth={currentMonth}
          handleChangeNextMonth={handleChangeNextMonth}
          handleChangePreviousMonth={handleChangePreviousMonth}
          handleSelectDate={handleSelectDate}
        />
        <View style={styles.lineContainer}>
          <View style={styles.line} />
        </View>
      </View>
      <View>
        <Text style={styles.sortTitle}>{t('site:siteReports.sortBy')}</Text>
        <View>
          <Touchable onPress={() => handleChangeSortDirection('asc')}>
            <View style={tempSortDirection === 'asc' ? styles.selectedSort : styles.sort}>
              <Text style={styles.sortText}>{t('site:siteReports.latestDate')} </Text>
            </View>
          </Touchable>
          <Touchable onPress={() => handleChangeSortDirection('desc')}>
            <View style={tempSortDirection === 'desc' ? styles.selectedSort : styles.sort}>
              <Text style={styles.sortText}>{t('site:siteReports.earliestDate')}</Text>
            </View>
          </Touchable>
        </View>
        <View style={styles.lineContainer}>
          <View style={styles.line} />
        </View>
      </View>
      <View style={styles.applyView}>
        <Touchable onPress={handleApply}>
          <View style={styles.applyButton}>
            <Text style={styles.applyText}>{t('common:apply')}</Text>
          </View>
        </Touchable>
      </View>
    </View>
  );
};
const Touchable: any = TouchableOpacity;
export default SiteReportsFilter;
const styles = StyleSheet.create({
  root: { flex: 1, backgroundColor: 'white' },
  secondColumn: { padding: 10 },
  container: {
    paddingHorizontal: 10,
    paddingVertical: 15,
    height: 63,
    maxHeight: 63,
    backgroundColor: '#ffffff',
    width: '100%'
  },
  button: {
    paddingHorizontal: 12,
    paddingVertical: 7,
    backgroundColor: '#ffffff',
    borderRadius: 5,
    borderColor: '#D8D8D8',
    borderStyle: 'solid',
    borderWidth: 1,
    marginLeft: 2
  },
  activeLabel: {
    color: '#fff'
  },
  label: {
    fontSize: 11,
    lineHeight: 18,
    color: '#535353'
  },
  activeButton: {
    backgroundColor: '#574FCF',
    borderWidth: 0
  },
  timeTitle: {
    padding: 10
  },
  title: {
    color: '#404040'
  },
  selectedSort: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: '#E6FAED',
    padding: 15
  },
  sort: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: 15
  },
  line: {
    borderBottomWidth: 1,
    borderColor: '#EFEEED',
    borderStyle: 'solid',
    width: 300
  },
  lineContainer: {
    marginTop: 10,
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  sortText: {
    fontSize: 12
  },
  sortTitle: {
    padding: 10
  },
  applyText: {
    textAlign: 'center',
    color: '#FFFFFF',
    fontSize: 14
  },
  applyView: {
    backgroundColor: 'white',

    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: 80
  },
  applyButton: {
    backgroundColor: '#56C768',
    width: 150,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    borderRadius: 19,
    marginTop: 10
  }
});
