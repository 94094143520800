import React from 'react';
import {
  Platform,
  StyleSheet,
  View,
  TouchableNativeFeedback,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  ButtonProps,
  TouchableWithoutFeedbackProps,
  TextStyle
} from 'react-native';

import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import Text from './Text';
import fontMaker from '../../utils/font';

export type AppButtonProps = {
  title: string;
  style?: StyleProp<ViewStyle>;
  icon?: string;
  backgroundColor?: string;
  fontSize?: number;
  color?: string;
  onPress?: (event?: any) => any;
  disabled?: boolean;
  accessibilityLabel?: string;
  testID?: string;
  underlayColor?: string;
} & ButtonProps;

export const AppButton: React.FunctionComponent<AppButtonProps> = props => {
  const { accessibilityLabel, backgroundColor, fontSize, color, onPress, title, disabled, testID, style, icon } = props;
  const buttonStyles: any = [styles.button];
  const textStyles: any = [styles.text];
  if (style) {
    buttonStyles.push(style);
  }
  if (backgroundColor) {
    buttonStyles.push({ backgroundColor });
  }
  if (fontSize) {
    textStyles.push({ fontSize });
    if (fontSize < 14) {
      textStyles.push({ paddingVertical: 4 });
    }
  }
  if (color) {
    textStyles.push({ color });
  }
  const accessibilityStates: any = [];
  if (disabled) {
    buttonStyles.push(styles.buttonDisabled);
    textStyles.push(styles.textDisabled);
    accessibilityStates.push('disabled');
  }
  const Touchable: React.ComponentClass<TouchableWithoutFeedbackProps> =
    Platform.OS === 'android' ? TouchableNativeFeedback : TouchableOpacity;

  return (
    <Touchable
      accessibilityRole="button"
      accessibilityLabel={accessibilityLabel}
      accessibilityStates={accessibilityStates}
      testID={testID}
      disabled={disabled}
      onPress={onPress}
    >
      <View style={[buttonStyles]}>
        {icon ? <Icon name={icon} size={16} color="white" /> : null}
        <Text allowFontScaling={false} style={textStyles}>
          {title}
        </Text>
      </View>
    </Touchable>
  );
};

export default AppButton;

const styles = StyleSheet.create({
  button: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 4,
    borderRadius: 5,
    backgroundColor: '#3cd070'
  },
  text: {
    ...fontMaker({ weight: 'SemiBold' }),
    textAlign: 'center',
    color: '#fff',
    padding: 8
  },
  buttonDisabled: {
    elevation: 0,
    backgroundColor: '#dfdfdf',
    borderColor: '#dfdfdf'
  },
  textDisabled: {
    color: '#a1a1a1'
  }
});
