import React from 'react';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';

import { StyleSheet, View } from 'react-native';
import Journey from '../components/journey/Journey';
import { HomeStackParamList, ScheduleStackParamList } from '../routes';

export type JourneyPageProps = {
  navigation: StackNavigationProp<HomeStackParamList | ScheduleStackParamList, 'Journey'>;
  route: RouteProp<HomeStackParamList | ScheduleStackParamList, 'Journey'>;
};

const JourneyPage = (props: JourneyPageProps) => {
  return (
    <View style={styles.root}>
      <Journey {...props} />
    </View>
  );
};

export default JourneyPage;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#ffffff'
  }
});
