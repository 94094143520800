import AsyncStorage from '@react-native-community/async-storage';

import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import cloneDeep from 'lodash/cloneDeep';

import * as reportcacheActions from './reportcache.action';
import { logActivityAsync } from '../../../store/reducers/activity/activity.action';

import { errorLogger } from '../../../utils/errorLogger';
import i18n from 'i18next';

// eslint-disable-next-line camelcase
import { DEPRECATED_cleanupBackupData } from '../../../utils/debugging';
import { isExpiredReport, cleanUpExpiredReports, cleanReportSectionPhotos } from '../../../utils/reportcache';

import { addPendingMulti } from '../reportsubmit';

// Typescript
import { ThunkResult } from '../../../typing/types';
import * as Classes from '../../../utils/classes';
import { currentPeriodStartDate } from '../../../utils/schedule';
import moment from 'moment';
import { ErrorCode } from '../../../helpers/customError';
import { Activity, enums } from 'nimbly-common';
import { appversion } from '../../../constants/appversion';
import getDeviceProfile from '../../../helpers/getDeviceProfile';

export const updateCacheReport =
  (siteKey: string, reportKey: string, report: Classes.Report): ThunkResult =>
  async (dispatch, getState) => {
    const { firebase } = getState();

    try {
      return dispatch(reportcacheActions.setCacheReport(siteKey, reportKey, report));
    } catch (error) {
      if (error && firebase.auth && firebase.auth.uid && firebase.profile && firebase.profile.organization) {
        errorLogger(
          firebase.auth.uid,
          firebase.profile.organization,
          siteKey,
          reportKey,
          error,
          'reportcache/updateCacheReport'
        );
      }
    }
  };

export const updateCacheReportSummary =
  (siteKey: string, reportKey: string, reportSummary: Classes.ReportSummary): ThunkResult =>
  async (dispatch, getState) => {
    const { firebase } = getState();

    try {
      dispatch(reportcacheActions.setCacheReportSummary(siteKey, reportKey, reportSummary));
    } catch (error) {
      if (error && firebase.auth && firebase.auth.uid && firebase.profile && firebase.profile.organization) {
        errorLogger(
          firebase.auth.uid,
          firebase.profile.organization,
          siteKey,
          reportKey,
          error,
          'reportcache/updateCacheReportSummary'
        );
      }
    }
  };

export const removeReport =
  (siteKey: string, reportKey: string, backup: boolean): ThunkResult =>
  async (dispatch, getState) => {
    const { firebase, reportcache } = getState();
    const { reports, summaries } = reportcache;
    try {
      const selectedReport = reports[siteKey] && reports[siteKey][reportKey];
      const selectedSummary = summaries[siteKey] && summaries[siteKey][reportKey];
      if (backup && selectedReport && selectedSummary) {
        dispatch(reportcacheActions.setBackupReport(reportKey, selectedReport, selectedSummary));
      }
      return dispatch(reportcacheActions.removeCacheReportAndSummary(siteKey, reportKey));
    } catch (error) {
      if (error && firebase.auth && firebase.auth.uid && firebase.profile && firebase.profile.organization) {
        errorLogger(
          firebase.auth.uid,
          firebase.profile.organization,
          siteKey,
          reportKey,
          error,
          'reportcache/removeUploadedReport'
        );
      }
    }
  };

export const setReportSection =
  (siteKey: string, reportKey: string, sectionIdx: number, report: Classes.ReportSection): ThunkResult =>
  async (dispatch, getState) => {
    try {
      return dispatch(reportcacheActions.setReportSectionState(siteKey, reportKey, sectionIdx, report));
    } catch (error) {
      if (error) {
        const state = getState();
        const { auth, profile } = state.firebase;
        errorLogger(auth.uid, profile.organization, siteKey, reportKey, error, 'reportcache.ts/setReportSection');
      }
    }
  };

export const removeSiteReportSections =
  (siteKey: string, reportKey: string, backup?: boolean): ThunkResult =>
  async (dispatch, getState) => {
    const {
      reportcache: { multiReports: reportSections },
      firebase
    } = getState();
    try {
      if (!backup && reportSections && reportSections[siteKey] && reportSections[siteKey][reportKey]) {
        await cleanReportSectionPhotos(reportSections[siteKey][reportKey]);
      }
      // const keys = await AsyncStorage.getAllKeys();
      // const filteredKeys = keys.filter(key => key.includes(`reportSection/${siteKey}/${reportKey}`));
      // await AsyncStorage.multiRemove(filteredKeys);
      // TODO: Handle backup
      // await backupReportSection(siteKey, reportKey);
      dispatch(reportcacheActions.removeSiteReportSectionState(siteKey));
    } catch (error) {
      if (error && firebase.auth && firebase.auth.uid && firebase.profile && firebase.profile.organization) {
        errorLogger(
          firebase.auth.uid,
          firebase.profile.organization,
          '',
          '',
          error,
          'reportcache/removeSiteReportSections'
        );
      }
    }
  };

export const removeReportSection =
  (siteKey: string, reportKey: string, sectionIdx: number): ThunkResult =>
  async (dispatch, getState) => {
    const {
      reportcache: { multiReports: reportSections },
      firebase
    } = getState();
    try {
      await cleanReportSectionPhotos(reportSections[siteKey][reportKey], sectionIdx);
      dispatch(reportcacheActions.removeReportSectionState(siteKey, reportKey, sectionIdx));

      return;
    } catch (error) {
      if (error && firebase.auth && firebase.auth.uid && firebase.profile && firebase.profile.organization) {
        errorLogger(firebase.auth.uid, firebase.profile.organization, '', '', error, 'reportcache/removeReportSection');
      }
    }
  };

export const removeBackupCache =
  (keys: string[]): ThunkResult =>
  async (dispatch, getState) => {
    const { firebase } = getState();
    try {
      const backupCaches = await AsyncStorage.multiGet(keys);

      const parsedData: [string, Classes.Report | Classes.ReportSection | Classes.ReportSummary | null][] = [];

      backupCaches.forEach(([key, valueStr]) => {
        const [backup, type, siteKey, date, sectionIdx] = key.split('/');
        if (backup === 'backup') {
          if (valueStr && valueStr !== '__invalid__') {
            const value = JSON.parse(valueStr);
            parsedData.push([key, value]);
          } else if (valueStr === '__invalid__') {
            parsedData.push([key, null]);
          } else {
            parsedData.push([key, null]);
          }
        }
      });

      await DEPRECATED_cleanupBackupData(parsedData, false);

      return;
    } catch (error) {
      if (error && firebase.auth && firebase.auth.uid && firebase.profile && firebase.profile.organization) {
        errorLogger(firebase.auth.uid, firebase.profile.organization, '', '', error, 'reportcache/removeBackupCache');
      }
    }
  };

// Updates or sets the report in the cache
export const upsertCacheReport =
  (siteKey: string, reportKey: string, report: Classes.Report, reportSummary: Classes.ReportSummary): ThunkResult =>
  async (dispatch, getState) => {
    const { firebase } = getState();

    try {
      return dispatch(reportcacheActions.setCacheReportAndSummary(siteKey, reportKey, report, reportSummary));
    } catch (error) {
      if (error && firebase.auth && firebase.auth.uid && firebase.profile && firebase.profile.organization) {
        errorLogger(
          firebase.auth.uid,
          firebase.profile.organization,
          siteKey,
          reportKey,
          error,
          'reportcache/upsertCacheReport'
        );
      }
    }
  };

export type CacheReport = {
  cacheKey: string;
  type: 'report' | 'reportIndex' | 'reportSection' | 'unavailable';
  siteKey: string;
  reportKey: string;
  value: Classes.Report | Classes.ReportSummary | Classes.ReportSection;
  isExpired: boolean | null;
  sectionIdx?: number;
};

// eslint-disable-next-line camelcase
export const DEPRECATED_getReportsFromCache =
  (organizationSchedule: Classes.OrganizationSchedule): ThunkResult =>
  async (dispatch, getState) => {
    const { firebase } = getState();

    try {
      const keys = await AsyncStorage.getAllKeys();
      const reportKeyReg = /report-|report\//i;
      const reportSummaryKeyReg = /reportIndex-|reportIndex\//i;
      const reportSectionReg = `reportSection/`;

      const cacheReportKeys: string[] = [];
      const summariesKeys: string[] = [];
      const multiReportKeys: string[] = [];
      const backupKeys: string[] = [];

      for (const k of keys) {
        if (k.includes('backup/')) {
          backupKeys.push(k);
        } else if (reportSummaryKeyReg.test(k)) {
          summariesKeys.push(k);
        } else if (k.includes(reportSectionReg)) {
          multiReportKeys.push(k);
        } else if (reportKeyReg.test(k)) {
          cacheReportKeys.push(k);
        }
      }

      if (backupKeys.length > 0) {
        await dispatch(removeBackupCache(backupKeys));
      }
      const mergedKeys = [...cacheReportKeys, ...summariesKeys, ...multiReportKeys];
      if (mergedKeys.length) {
        const cacheItems = await AsyncStorage.multiGet(mergedKeys);

        const parsedCacheReports = cacheItems.map(([key, valueStr]) => {
          const isReport = reportKeyReg.test(key);
          const isReportIndex = reportSummaryKeyReg.test(key);
          const isReportSection = key.includes('reportSection/');

          const value = JSON.parse(valueStr!);

          let cacheKey;
          let reportKey;
          let cacheSiteKey;
          let sectionIdx;
          if (!isReportSection) {
            /**
             * Format `report/{siteKey}/{reportKey}`
             */
            const keySplit = key.split('/');
            cacheKey = isReport ? 'report/' : isReportIndex ? 'reportIndex/' : '';
            reportKey = /report-|reportIndex-/i.test(key) ? key.substring(key.length - 10) : keySplit[2];
            cacheSiteKey = /report-|reportIndex-/i.test(key)
              ? key.substring(cacheKey.length, key.length - 11)
              : keySplit[1];
          } else {
            [cacheKey, cacheSiteKey, reportKey, sectionIdx] = key.split('/');
          }

          // TODO: RESTU: may need better handling for expiring multi report
          const isExpired = isExpiredReport(value, organizationSchedule);

          const cacheReport: CacheReport = {
            cacheKey,
            type: isReport
              ? 'report'
              : isReportIndex
              ? 'reportIndex'
              : isReportSection
              ? 'reportSection'
              : 'unavailable',
            siteKey: cacheSiteKey,
            reportKey: reportKey,
            value,
            sectionIdx: Number(sectionIdx),
            isExpired
          };
          return cacheReport;
        });

        const singleSite: CacheReport[] = [];
        const multiSite: CacheReport[] = [];
        const expiredReports: CacheReport[] = [];

        const cleanupInvalidCache: string[] = [];

        for (const report of parsedCacheReports) {
          if (report.type === 'unavailable' || !report.value) {
            cleanupInvalidCache.push(report.cacheKey);
            continue;
          }
          if (report.type === 'reportSection' && !report.isExpired) {
            multiSite.push(report);
          } else if (report.type !== 'reportSection' && !report.isExpired) {
            singleSite.push(report);
          } else if (report.isExpired) {
            expiredReports.push(report);
          }
        }

        if (cleanupInvalidCache.length > 0) {
          await AsyncStorage.multiRemove(cleanupInvalidCache);
        }

        dispatch(setCacheItems(singleSite));
        dispatch(setMultiCacheItems(multiSite));
        dispatch(reportcacheActions.setCacheReady());

        if (expiredReports.length > 0) {
          await cleanUpExpiredReports(expiredReports);
        }
      }
      dispatch(reportcacheActions.setCacheReady());
    } catch (error) {
      if (error && firebase.auth && firebase.auth.uid && firebase.profile && firebase.profile.organization) {
        errorLogger(firebase.auth.uid, firebase.profile.organization, '', '', error, 'reportcache/getReportFromCache');
      }
      dispatch(reportcacheActions.setCacheReady());
      // Do nothing
    }
  };

export const setCacheItems =
  (cacheItems: CacheReport[]): ThunkResult =>
  dispatch => {
    cacheItems
      .filter(({ isExpired, type }) => !isExpired && type !== 'unavailable')
      .forEach(({ type, value, siteKey, reportKey }) => {
        if (type === 'report') {
          dispatch(reportcacheActions.setCacheReport(siteKey, reportKey, value as Classes.Report));
          if (value.status === 'complete' && value.datetimeOut && value.datetimeOut.length > 0) {
            dispatch(addPendingMulti(siteKey, reportKey));
          }
        } else {
          dispatch(reportcacheActions.setCacheReportSummary(siteKey, reportKey, value as Classes.ReportSummary));
        }
      });

    return Promise.resolve();
  };

export const setMultiCacheItems =
  (multiCacheItems: CacheReport[]): ThunkResult =>
  dispatch => {
    multiCacheItems
      .filter(({ isExpired, type }) => !isExpired && type !== 'unavailable')
      .map(({ value, siteKey, reportKey: date, sectionIdx }) => {
        dispatch(reportcacheActions.setReportSectionState(siteKey, date, sectionIdx!, value as Classes.ReportSection));
      });

    return Promise.resolve();
  };

// new implementation
export const cleanLocalExpiredReports =
  (organizationSchedule: Classes.OrganizationSchedule): ThunkResult =>
  (dispatch, getState) => {
    const startDate = currentPeriodStartDate(organizationSchedule);

    if (!startDate) {
      return;
    }
    const state = getState();

    const { reportcache } = state;
    const { profile } = state.firebase;
    const { reports, summaries, multiReports, multiSummaries, backupReports, backupSummaries } = reportcache;

    if (!profile || profile.isEmpty) {
      return;
    }

    if (isEmpty(reports) || isEmpty(summaries)) {
      dispatch(reportcacheActions.setCacheReady());

      return;
    }

    const newReports = cloneDeep(reports);
    const newSummaries = cloneDeep(summaries);
    const newMultiReports = cloneDeep(multiReports);
    const newMultiSummaries = cloneDeep(multiSummaries);

    // merge summary and report to get consistent value
    const clonedSummaries = cloneDeep(summaries);
    const clonedMultiSummaries = cloneDeep(multiSummaries);
    const mergedReport = merge(newReports, clonedSummaries);
    const mergedMultiReports = merge(multiReports, clonedMultiSummaries);

    // TODO: Confirm start need to be added by 4 hours or not

    const prevEndLimit = startDate.add(4, 'hours');
    Object.keys(mergedReport).forEach(siteKey => {
      const siteReports = mergedReport[siteKey];
      Object.keys(siteReports || {}).forEach(reportKey => {
        const currentReport = siteReports[reportKey];

        const selectedDate =
          currentReport.datetimeIn || currentReport.datetimeUpdated || currentReport.datetimeScheduled;
        if (moment(selectedDate).isBefore(prevEndLimit)) {
          delete newReports[siteKey][reportKey];
          delete newSummaries[siteKey][reportKey];
        }
      });

      if (isEmpty(newReports[siteKey]) || isEmpty(newSummaries[siteKey])) {
        delete newReports[siteKey];
        delete newSummaries[siteKey];
      }
    });

    Object.keys(mergedMultiReports || {}).forEach(siteKey => {
      const siteReports = mergedMultiReports[siteKey];
      Object.keys(siteReports || {}).forEach(reportKey => {
        const reportSections = siteReports[reportKey];
        for (const sectionIdx of Object.keys(reportSections || {})) {
          const currentSection = reportSections[sectionIdx];
          const selectedDate =
            currentSection.datetimeIn || currentSection.datetimeUpdated || currentSection.datetimeScheduled;
          if (moment(selectedDate).isBefore(prevEndLimit)) {
            if (!Object.keys(newMultiReports).length) return;
            delete newMultiReports[siteKey][reportKey];

            if (!Object.keys(newMultiSummaries).length) return;
            delete newMultiSummaries[siteKey][reportKey];
            break;
          }
        }
      });
      if (isEmpty(newMultiReports[siteKey])) {
        delete newMultiReports[siteKey];
        delete newMultiSummaries[siteKey];
      }
    });

    const expiredBackupCaches: string[] = [];
    // merge summary and report to get consistent value
    const clonedBackupReports = cloneDeep(backupReports);
    const clonedBackupSummaries = cloneDeep(backupSummaries);
    const mergedBackup = merge(clonedBackupSummaries, clonedBackupReports);

    for (const reportKey of Object.keys(mergedBackup || {})) {
      const [date, unixString] = reportKey.split('_');
      // remove backup that's more than 3 days old
      if (moment().diff(unixString ? Number(unixString) : date, 'day') > 3) {
        expiredBackupCaches.push(reportKey);
      }
    }

    if (expiredBackupCaches.length) {
      dispatch(reportcacheActions.removeBackupReports(expiredBackupCaches));
    }

    dispatch(reportcacheActions.initReportCache(newReports, newSummaries, newMultiReports, newMultiSummaries));
    dispatch(reportcacheActions.setCacheReady());
  };

export const removeDraftReport =
  (siteKey: string, reportKey: string): ThunkResult<Promise<void>> =>
  async (dispatch, getState, { getFirebase }) => {
    const state = getState();

    const { network } = state;
    const { profile, auth } = state.firebase;

    const organizationKey = profile.organization;
    const language: Classes.Language = profile.language || 'en';

    if (!network.isConnected) {
      throw new ErrorCode('no-internet', i18n.t('saga:reportCache.noConnection'));
    }

    if (!reportKey) {
      errorLogger(
        auth.uid,
        organizationKey,
        siteKey,
        reportKey,
        new Error(i18n.t('saga:reportCache.noKeyReport')),
        'reportcache.actionThunk.ts/removeDraftReport'
      );

      throw new ErrorCode('unknown-error', i18n.t('saga:reportCache.systemError'));
    }

    try {
      const reportRef = `/report/${organizationKey}/${siteKey}/${reportKey}`;
      const summaryRef = `/reportIndex/${organizationKey}/${siteKey}/${reportKey}`;
      const inventoryRef = `/inventory/${organizationKey}/${siteKey}/${reportKey}`;

      const firebase = getFirebase();
      const removeFn = () =>
        Promise.all([
          firebase.database().ref(reportRef).remove(),
          firebase.database().ref(summaryRef).remove(),
          firebase.database().ref(inventoryRef).remove()
        ]);

      await removeFn();
      dispatch(reportcacheActions.removeCacheReportAndSummary(siteKey, reportKey));
    } catch (error) {
      errorLogger(auth.uid, organizationKey, siteKey, reportKey, error, 'reportcache.actionThunk.ts/removeDraftReport');

      throw new ErrorCode('internal-server', i18n.t('saga:reportCache.systemError'));
    }
  };

export const createOrUpdateReport =
  (
    siteKey: string,
    reportKey: string,
    report: Classes.Report,
    summary: Classes.ReportSummary
  ): ThunkResult<Promise<void>> =>
  async (dispatch, getState, { getFirebase }) => {
    const firebase = await getFirebase();
    const state = getState();
    const profile = state?.firebase?.profile;
    const organizationKey = profile?.organization;

    let reportRef = `/report/${organizationKey}/${siteKey}/${reportKey}`;
    let summaryRef = `/reportIndex/${organizationKey}/${siteKey}/${reportKey}`;

    try {
      // write cache report immediately to avoid waiting for database write
      dispatch(reportcacheActions.setCacheReportAndSummary(siteKey, reportKey, report, summary));

      Object.keys(report).forEach(key => (report[key] === undefined ? delete report[key] : {}));

      // use callback for non blocking when offline while still able to monitor error
      await firebase.database().ref(reportRef).update(report);
      await firebase.database().ref(summaryRef).update(summary);
    } catch (error) {
      console.log('error', error);
      throw new ErrorCode('internal-server', i18n.t('saga:reportCache.systemError'));
    }
  };
