import { cloneDeep } from 'lodash';
import moment from 'moment';

import { UploadedSiteReportSections } from '../components/lobby/Lobby';

import { SiteReportSections, ReportSections } from '../store/reducers/reportcache/reportcache.types';

import { getTotalCompleteQuestions, getFlagCount } from './report';
import { errorLogger } from './errorLogger';

import { Site, Report, ReportSectionIndex, Question, ReportSummary, SectionSummary, ReportSection } from './classes';
import { isReportDeviceCheckoutTime } from './getReportTime';
export function getAuditors(site: Site) {
  const members: { [uid: string]: boolean } = {};
  const leader = site.assignedAuditor;
  if (leader) {
    members[leader] = true;
  }
  if (site.team && site.team.length) {
    for (const member of site.team) {
      if (!members[member]) {
        members[member] = true;
      }
    }
  }
  return Object.keys(members);
}

export function getMultiSiteStatus(siteMultiReports: SiteReportSections) {
  let isDraft = false;
  let isPending = false;

  if (siteMultiReports) {
    const reportKey = Object.keys(siteMultiReports)[0];
    const reportSections = siteMultiReports[reportKey];
    if (reportKey && reportSections) {
      Object.keys(reportSections).forEach(sectionIdx => {
        if (sectionIdx && reportSections[Number(sectionIdx)]) {
          if (!reportSections[Number(sectionIdx)].datetimeOut) {
            isDraft = true;
          } else {
            isPending = true;
          }
        }
      });
    }
  }
  const status = isPending ? 'pending-upload' : isDraft ? 'draft' : null;
  return status;
}

export function getIncompleteSectionIndexes(
  reportSections: ReportSections,
  uid: string,
  organization: string,
  selectedSiteKey: string,
  selectedReportKey: string
): number[] {
  const incompleteReports = Object.keys(reportSections)
    .filter(sectionIdx => {
      const reportSection = reportSections[Number(sectionIdx)];
      if (reportSection) {
        const questions = reportSection.questions;
        const completedQuestions = getTotalCompleteQuestions(questions);
        return completedQuestions < questions.length || !reportSection.datetimeOut;
      } else {
        errorLogger(
          uid,
          organization,
          selectedSiteKey,
          selectedReportKey,
          new Error(`reportSection not found: ${sectionIdx}`),
          'multisite/getIncompleteSectionIndexes'
        );
      }
    })
    .map(Number);

  return incompleteReports;
}

export function getCompleteSections(
  reportSections: ReportSections,
  uid: string,
  organization: string,
  selectedSiteKey: string,
  selectedReportKey: string
): ReportSections {
  const completeSections: ReportSections = {};
  Object.keys(reportSections).forEach(sectionIdx => {
    const reportSection = reportSections[Number(sectionIdx)];
    if (reportSection) {
      const questions = reportSection.questions;
      const completedQuestions = getTotalCompleteQuestions(questions);
      if (completedQuestions >= questions.length && reportSection.datetimeOut) {
        completeSections[Number(sectionIdx)] = reportSection;
      }
    } else {
      errorLogger(
        uid,
        organization,
        selectedSiteKey,
        selectedReportKey,
        new Error(`reportSection not found: ${sectionIdx}`),
        'multisite/getCompleteSections'
      );
    }
  });
  return completeSections;
}

export function compileReport(
  report: Report,
  reportSummary: ReportSummary,
  draftReports: UploadedSiteReportSections
): { report: Report; reportSummary: ReportSummary } {
  // const serverTime = firebase.database().getServerTime();
  const now = moment().toISOString(true);
  const compiledReport = cloneDeep(report);
  const compiledReportSummary = cloneDeep(reportSummary);

  const sections: ReportSectionIndex[] = [];
  const sectionSummaries: SectionSummary[] = [];
  const questions: Question[] = [];
  const processingSections = Object.keys(draftReports || {});
  processingSections.forEach(numString => {
    const sectionNum = Number(numString);
    const section = draftReports[sectionNum];

    if (section) {
      for (const uid of Object.keys(section)) {
        const reportSection: ReportSection = section[uid];
        if (!['complete', 'approved', 'waiting_for_upload'].includes(reportSection.status)) {
          continue;
        }
        const sectionIndex: ReportSectionIndex = {
          siteChild: reportSection.siteChild,
          startIndex: questions.length,
          datetimeIn: reportSection.datetimeIn,
          datetimeOut: reportSection.datetimeOut,
          doneBy: uid,
          questionnaire: reportSection.questionnaire || ''
        };

        const flagCount = getFlagCount(reportSection.questions);
        const sectionSummary: SectionSummary = {
          datetimeIn: reportSection.datetimeIn,
          datetimeOut: reportSection.datetimeOut,
          datetimeUpdated: reportSection.datetimeUpdated || '',
          auditor: uid,
          status: 'complete',
          flagCount,
          siteChild: reportSection.siteChild,
          questionnaire: reportSection.questionnaire || ''
        };
        questions.push(...reportSection.questions);
        sectionSummaries.push(sectionSummary);
        sections.push(sectionIndex);
      }
    }
  });

  const datetimeOut = sections
    .filter(section => section.datetimeOut)
    .sort((a, b) => (b.datetimeOut < a.datetimeOut ? -1 : 1))[0]?.datetimeOut;
  compiledReport.datetimeOut = datetimeOut;
  compiledReport.datetimeSubmitted = isReportDeviceCheckoutTime(datetimeOut, now);
  compiledReport.questions = questions;
  compiledReport.sections = sections;

  compiledReportSummary.flagCount = getFlagCount(compiledReport.questions);
  compiledReportSummary.datetimeUpdated = now;
  compiledReportSummary.datetimeOut = datetimeOut;
  compiledReportSummary.sections = sectionSummaries;

  return { report: compiledReport, reportSummary: compiledReportSummary };
}
