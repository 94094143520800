import { CurrentLocation } from '../store/reducers/geolocation';
import { Coordinates } from './classes';

// Returns distance in km between 2 sets of coordinates
function getDistance(currentLocation: CurrentLocation, targetLocation: null | Coordinates) {
  if (!targetLocation) {
    return null;
  }
  // Radius of the earth in km
  const R = 6371;
  // Calculate distance in radians
  // missing toRad() source https://www.movable-type.co.uk/scripts/latlong.html
  const dLat = toRad(targetLocation.latitude - currentLocation.coords.latitude);
  const dLon = toRad(targetLocation.longitude - currentLocation.coords.longitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) *
      Math.sin(dLon / 2) *
      Math.cos(toRad(currentLocation.coords.latitude)) *
      Math.cos(toRad(targetLocation.latitude));
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  // Calculate final distance in km
  const distance = R * c;

  return distance;
}

function toRad(n: number) {
  return (n * Math.PI) / 180;
}

export { getDistance, toRad };
