import * as types from './featureAccess.actionTypes';
import { createAsyncAction, action } from 'typesafe-actions';
import { enums } from 'nimbly-common';
import { FeaturesTypes } from '../../reducers/featureAccess/type';

export const setType = (text: string) => action(types.SET_TYPE, { text });
export const dismissFeatureModal = () => action(types.DISMISS_FEATURE_MODAL);
export const showFeatureModal = () => action(types.SHOW_FEATURE_MODAL);
export const setOrganization = (org: string) => action(types.SET_ORG, { org });

export const fetchFeatureAccessAsync = createAsyncAction(
  types.FETCH_FEATURE_ACCESS_REQUEST,
  types.FETCH_FEATURE_ACCESS_SUCCESS,
  types.FETCH_FEATURE_ACCESS_FAILURE
)<undefined, { data: FeaturesTypes; type: enums.Package }, { error: string }>();
