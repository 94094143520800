import React, { useState, useEffect } from 'react';
import { StyleSheet, View } from 'react-native';

// components
import Text from '../global/Text';
import ProgressBar from '../questionnaire/QuestionnaireProgress';

/// utils
import getJourneyReportStatus from '../schedule/utils/getJourneyReportStatus';
import fontMaker from '../../utils/font';

// types
import { JourneyInfoProps } from './type';

const JourneyInfo = (props: JourneyInfoProps) => {
  const { journey, journeySites, journeyReport, visitedSites, language } = props;

  const [formattedTime, setFormattedTime] = useState<string>('');
  const [stateInterval, setStateInterval] = useState<any>(null);

  const renderTotalTimeDuration = (newDuration: number) => {
    const seconds = Math.round(newDuration / 1000);
    const s = Math.round(seconds % 60);
    const m = Math.round((seconds / 60) % 60);
    const h = Math.round((seconds / (60 * 60)) % 24);

    setFormattedTime(`${h}h ${m}m ${s}s`);
  };

  //format journey time total
  useEffect(() => {
    let isCancelled = false;
    const createJourneyTrack = () => {
      if (journeyReport) {
        const startTimeJourney = new Date(journeyReport.start);
        const endTimeJourney = new Date();

        if (startTimeJourney) {
          const duration = endTimeJourney.valueOf() - startTimeJourney.valueOf();

          let newDuration = duration;
          const intervalTotalTime = setInterval(() => {
            newDuration += 1000;
            if (!isCancelled) {
              //render time only on journey proses after that destroy them
              return renderTotalTimeDuration(newDuration);
            }
            return null;
          }, 1000);

          if (!isCancelled) {
            setStateInterval(intervalTotalTime);
          } else {
            clearInterval(intervalTotalTime);
          }
        }
      }
    };

    createJourneyTrack();

    return () => {
      isCancelled = true;
      clearInterval(stateInterval);
    };
  }, [journeyReport]);

  const translation = {
    timeTotal: {
      id: 'Total waktu perjalanan',
      en: 'Journey time total'
    },
    sitesVisited: {
      id: 'Lokasi yg dikunjungi',
      en: 'Sites Visited'
    },
    questionnaires: {
      id: 'Kuesioner',
      en: 'Questionnaires'
    }
  };

  return (
    <View style={styles.infoContainer} testID="journey-info-container">
      <Text style={styles.type}>{journey.type.charAt(0).toUpperCase() + journey.type.substring(1)}</Text>
      <Text style={styles.title}>{journey.journeyName}</Text>
      <View style={styles.statsContainer}>
        <View style={styles.statContainer}>
          <Text style={styles.statField}>{translation.sitesVisited[language]}</Text>
          <Text style={styles.statValue}>
            {visitedSites.length >= journeySites.length ? journeySites.length : visitedSites.length} of{' '}
            {journeySites.length}
          </Text>
        </View>
        <View style={styles.statContainer}>
          <Text style={styles.statField}>{translation.timeTotal[language]}</Text>
          <Text style={styles.statValue}>{formattedTime ? formattedTime : '0h 0m 0s'}</Text>
        </View>
        <View style={styles.statContainer}>
          <Text style={styles.statField}>{translation.questionnaires[language]}</Text>
          <Text style={styles.statValue}>{journey.questionnaires.length}</Text>
        </View>
      </View>
      <View style={styles.progressContainer}>
        <ProgressBar completed={visitedSites.length} total={journeySites.length} language="en" />
      </View>
    </View>
  );
};

export default JourneyInfo as any;

const styles = StyleSheet.create({
  infoContainer: {
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#ffffff'
  },
  type: {
    ...fontMaker({ weight: 'SemiBold' }),
    color: '#ffd070',
    fontSize: 12
  },
  title: {
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 16,
    marginVertical: 10
  },
  statsContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-evenly',
    marginVertical: 10
  },
  statContainer: {
    alignItems: 'center'
  },
  statField: {
    fontSize: 12
  },
  statValue: {
    ...fontMaker({ weight: 'SemiBold' }),
    fontSize: 16
  },
  progressContainer: {
    marginVertical: 10
  }
});
