export const SKU_ITEM_HEIGHT = 205;
export const SKU_ITEM_HALF_EXPAND_HEIGHT = 265;
export const SKU_ITEM_FULL_EXPAND_HEIGHT = 300;
export const SKU_ITEM_BORDER_HEIGHT = 1;

/**
 * Get SKU Item's height
 * based on the commentVisible and photos state
 * @param skuItemCommentVisible - state whether the comment input is visible/not
 * @param skuItemPhotos - state of item's photo
 */
const getSKUItemHeightByState = (skuItemCommentVisible: boolean, skuItemPhotos: string[]) => {
  if (skuItemCommentVisible && skuItemPhotos.length) {
    // fully expanded
    return SKU_ITEM_FULL_EXPAND_HEIGHT;
  } else if (skuItemCommentVisible || skuItemPhotos.length) {
    // helf expanded
    return SKU_ITEM_HALF_EXPAND_HEIGHT;
  } else {
    // not expanded (initial)
    return SKU_ITEM_HEIGHT;
  }
};

export default getSKUItemHeightByState;
