import { FederatedAuthentication } from '@nimbly-technologies/nimbly-common';
import AsyncStorage from '@react-native-community/async-storage';
import firebase from 'firebase';
import { isEmpty } from 'lodash';
import { Activity } from 'nimbly-common';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Linking } from 'react-native';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { AUTH_MODE } from '../../constants/Constants';
import { LanguageType } from '../../constants/Languages';
import { RootState } from '../../store/reducers';
import { accountActions } from '../../store/reducers/account/account';
import { logActivityAsync } from '../../store/reducers/activity/activity.action';
import { landingActions } from '../../store/reducers/landing';
import { toastActions } from '../../store/reducers/toast';
import { ConnectedDispatch } from '../../typing/types';
import { errorLogger } from '../../utils/errorLogger';
import { handleConnectionTimeout } from '../../utils/handleConnectionTimeout';
import { isEmail, isPhone } from '../../utils/validation';
import AuthContainer from './AuthContainer';
import detectCapsLock from './utils/detectCapsLock';
import { authenticateFederatedCredentialsPopUp } from './utils/federated.service';
import { getLogoutOkta, requestEnrollment, verifyEnrollment } from './utils/federatedApiUtils';
import { findTenantByEmailDomain } from './utils/findTenantByEmailDomain';
import generateLoginErrorMessage from './utils/generateLoginErrorValidation';
import generateSignInWithEmailLinkError from './utils/generateSignInWithEmailLinkError';

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type AuthProps = {
  /** The redux Firebase prop */
  firebase: any;
  /** Real-time connectivity status - true if there's an internet connection */
  isConnected: boolean;
  /** Mode of the auth component - Sign Up, Login, or Reset Password */
  authMode: AUTH_MODE;
  /** Catching variable for any error caused by attempting to authenticate */
  authError: any;
  /** Language to be used throughout the app */
  language: LanguageType;
  /** Boolean true if the phone has a notch */
  hasNotch: boolean;
  /** The redux Firebase user object */
  auth: any;
  /** The user profile object */
  profile: any;
  /** Event fired when the Auth component is scrolled down */
  onScrollDown: () => void;
  setShowModalRequestAccess: (isVisible: boolean) => void;
} & ConnectedDispatch &
  DispatchProps;

export type AuthFormInput = {
  /** Value of the Email input field */
  email: string;
  /** Value of the Password input field */
  password: string;
  /** Value of the Phone Number input field */
  phoneNumber: string;
  /** Value of the SMS Verification Code input field */
  code: string;
  emailOrPhone: string;
  errorTextBottom: string;
};

export type AuthFormValidation = {
  /** True if email is valid */
  email: boolean;
  /** True if password is valid */
  password: boolean;
  /** True if phone number is valid */
  phoneNumber: boolean;
  /** True if verification code is valid */
  code: boolean;
  emailOrPhone: boolean;
  /** Error text related to issues during auth - empty if there are no issues */
  errorText: string;
  isNeedAccessFederated: boolean;
};

/**
 * Forms and submission mechanism to authenticate a user
 *
 * Auth will allow the user to log in.
 * Once login/incoming user data is detected, Landing will forward the user to the next screen.
 *
 * It has 3 modes of operation:
 * - "signup" - user logs in with phone number and then completes an SMS 2FA
 * - "login" - user logs in with email
 * - "resetpassword" - user requests for a reset password email to be sent
 */
const Auth = (props: AuthProps) => {
  const { t } = useTranslation(['auth', 'common']);
  const [form, setForm] = useState<AuthFormInput>({
    email: '',
    password: '',
    phoneNumber: '+62',
    code: '',
    emailOrPhone: '',
    errorTextBottom: ''
  });
  const [formValidation, setFormValidation] = useState<AuthFormValidation>({
    email: true,
    password: true,
    phoneNumber: true,
    code: true,
    emailOrPhone: true,
    errorText: '',
    isNeedAccessFederated: false
  });
  const [_isBusy, setIsBusy] = useState<boolean>(false);
  const [_capsWarning, setCapsWarning] = useState<boolean>(false);
  const [_submitOnEnter, setSubmitOnEnter] = useState<boolean>(false);
  const [_emailSentSuccess, setEmailSentSuccess] = useState<boolean>(false);
  const [_twoFactorConfirmation, setTwoFactorConfirmation] = useState<any>(null);
  const [visibleTutorialModal, setVisibleTutorialModal] = useState<boolean>(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [freezeBanner, setFreezeBanner] = useState<boolean>(false);
  const [isFederatedAuth, setIsFederatedAuth] = useState<boolean>(true);
  const [tempAuth, setTempAuth] = useState<{ tempToken: string; tenant: FederatedAuthentication }>({
    tempToken: '',
    tenant: { domain: '', organizationID: '', tenantID: '', loginProtocol: '', loginProviderID: '' }
  });
  const [pendingCreds, setPendingCreds] = useState<any>(null);

  const clearFields = () => {
    setForm({
      email: '',
      password: '',
      phoneNumber: '+62',
      code: '',
      emailOrPhone: '',
      errorTextBottom: ''
    });
  };
  const clearFormValidation = () => {
    setFormValidation({
      email: true,
      password: true,
      phoneNumber: true,
      code: true,
      emailOrPhone: true,
      errorText: '',
      isNeedAccessFederated: false
    });
  };

  const handleEmailTextInputFocused = () => {
    setSubmitOnEnter(false);
  };

  const handlePasswordTextInputFocused = () => {
    setSubmitOnEnter(true);
  };

  const handleEmailChange = (text: string) => {
    setForm((prevState: AuthFormInput) => ({
      ...prevState,
      email: text
    }));
    setFormValidation((prevState: AuthFormValidation) => ({
      ...prevState,
      email: true,
      emailOrPhone: true,
      errorText: ''
    }));
    setFreezeBanner(false);
  };

  const handleEmailOrPhoneChange = (text: string) => {
    setForm((prevState: AuthFormInput) => ({
      ...prevState,
      emailOrPhone: text
    }));
    setFormValidation((prevState: AuthFormValidation) => ({
      ...prevState,
      emailOrPhone: true,
      errorText: ''
    }));
    setFreezeBanner(false);
  };
  const handlePhoneNumberChange = (text: string) => {
    if (text.length > 0 && text[0] === '+') {
      setForm((prevState: AuthFormInput) => ({
        ...prevState,
        phoneNumber: text
      }));
    }
    setFormValidation((prevState: AuthFormValidation) => ({
      ...prevState,
      phoneNumber: true,
      errorText: ''
    }));
  };

  const handleCodeChange = (text: string) => {
    setForm((prevState: AuthFormInput) => ({
      ...prevState,
      code: text
    }));
  };

  const handlePasswordChange = (text: string) => {
    const isUpperCase = detectCapsLock(text);
    setForm((prevState: AuthFormInput) => ({
      ...prevState,
      password: text
    }));
    setFormValidation((prevState: AuthFormValidation) => ({
      ...prevState,
      password: true,
      errorText: ''
    }));
    setCapsWarning(isUpperCase);
    setFreezeBanner(false);
  };

  const checkIsUserFederated = async (email: string) => {
    try {
      const getDomain = email.split('@').pop();
      const isEmailExist = await findTenantByEmailDomain(getDomain!, email);
      return isEmailExist;
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogin = async () => {
    props.dispatch(accountActions.isForceLogoutStatus(false));
    if (firebase.auth().currentUser) {
      firebase.auth().signOut();
    }
    setIsBusy(true);
    clearFormValidation();
    try {
      setFreezeBanner(false);
      await handleConnectionTimeout(attemptLogin());
    } catch (error) {
      if (isFederatedAuth) return;
      setFormValidation({
        ...formValidation,
        code: true,
        errorText: t('auth:error.internetConnection')
      });
    }
    setIsBusy(false);
  };

  const authenticateFederatedCredentials = async (tenant: FederatedAuthentication) => {
    if (!firebase?.auth) return;
    try {
      const getTempToken = await authenticateFederatedCredentialsPopUp(tenant, firebase);
      const getIDToken = await getTempToken.user?.getIdTokenResult();
      if (!getIDToken) {
        clearFormValidation();
        setIsBusy(false);
        return;
      }

      const checkVerify = await verifyEnrollment(getIDToken.token, tenant!.tenantID!);
      if (checkVerify === 'not-approved' || checkVerify === 'user-enrolled' || checkVerify === 'not-enrolled') {
        getLogoutOkta();
        setTempAuth({ tempToken: getIDToken!.token, tenant });
        setFormValidation((prevState: AuthFormValidation) => ({
          ...prevState,
          isNeedAccessFederated: true,
          errorTextBottom: t('auth:validation.askPermision')
        }));
      }

      setIsBusy(false);
    } catch (err: any) {
      if (err.code === 'auth/account-exists-with-different-credential') {
        const pendingCred = err.credential;
        setIsFederatedAuth(false);
        setPendingCreds(pendingCred);
        setFormValidation((prevState: AuthFormValidation) => ({
          ...prevState,
          errorTextBottom: t('auth:validation.needPassword'),
          password: false
        }));
      }
      setIsBusy(false);
    }
  };

  const authPendingCredentials = async () => {
    if (!firebase?.auth) return;
    try {
      const auth = firebase.auth();
      setIsBusy(true);
      const methods = await auth.fetchSignInMethodsForEmail(form.emailOrPhone);
      if (methods[0] === 'password') {
        setIsFederatedAuth(false);
        const result = await auth.signInWithEmailAndPassword(form.emailOrPhone, form.password);
        await result.user.linkWithCredential(pendingCreds);
        return;
      }
    } catch (error) {
      setIsBusy(false);
      handleLoginError(error);
    }
  };

  const authenticateOrganizationTenant = async (tenantData: FederatedAuthentication) => {
    if (!firebase?.auth) return;

    try {
      const auth = firebase.auth();
      auth.tenantId = tenantData?.tenantID;
      await auth.signInWithEmailAndPassword(form.emailOrPhone, form.password);
      setIsBusy(false);
    } catch (error) {
      handleLoginError(error);
      setIsBusy(false);
    }
  };

  const attemptLogin = async () => {
    let email = '';
    if (isEmail(form.emailOrPhone)) {
      email = form.emailOrPhone;
      const isEmailExist = await checkIsUserFederated(email);
      if (isEmailExist === undefined) {
        return;
      }

      if (pendingCreds) {
        await authPendingCredentials();
        return;
      }

      if (isEmailExist?.isFederated === true && isEmailExist?.isOrganizationTenant === false) {
        setIsFederatedAuth(!!isEmailExist?.isFederated);
        await authenticateFederatedCredentials(isEmailExist.federatedAuthentication as FederatedAuthentication);
        return;
      }

      setIsFederatedAuth(false);

      if (
        isEmailExist?.isFederated === false &&
        isEmailExist?.isOrganizationTenant === true &&
        isFederatedAuth === false
      ) {
        await authenticateOrganizationTenant(isEmailExist.federatedAuthentication as FederatedAuthentication);
        return;
      }

      if (!isFederatedAuth) {
        await loginEmailAndPassword(email, form.password);
      }
    } else {
      setFormValidation((prevState: AuthFormValidation) => ({
        ...prevState,
        emailOrPhone: false,
        errorTextBottom: t('auth:validation.invalidEmail')
      }));
      return;
    }

    if (isPhone(form.emailOrPhone)) {
      try {
        const userExist = firebase.functions().httpsCallable('user-exist');
        const { data } = await userExist({ phoneNumber: form.emailOrPhone });
        if (isEmpty(data.email) || isEmpty(data.phoneNumber)) {
          setFormValidation((prevState: AuthFormValidation) => ({
            ...prevState,
            emailOrPhone: false,
            errorText: t('auth:error.invalidPhone')
          }));
          return;
        } else {
          email = data.email;
          await loginEmailAndPassword(email, form.password);
        }
      } catch (error) {
        handleUserExistError(error);
      }
    }
  };
  const loginEmailAndPassword = async (email: string, password: string) => {
    if (!password) {
      setFormValidation((prevState: AuthFormValidation) => ({
        ...prevState,
        password: false,
        errorText: t('auth:error.passwordMissed')
      }));
      return;
    }

    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (error) {
      handleLoginError(error);
    }
  };
  const handleLoginError = (error: any) => {
    if (error.code && error.code === 'auth/user-disabled') {
      setFreezeBanner(true);
    }
    setFormValidation(generateLoginErrorMessage(error));
  };

  const handleAttemptEmailSignup = async () => {
    setIsBusy(true);
    try {
      await handleConnectionTimeout(attemptVerifyAccount());
    } catch (error) {
      setFormValidation((prevState: AuthFormValidation) => ({
        ...prevState,
        errorText: t('auth:error.internetConnection')
      }));
    }
    setIsBusy(false);
  };

  const sendOTP = async (phoneNumber: string) => {
    if (!phoneNumber || !isPhone(phoneNumber)) {
      props.setAuthMode(AUTH_MODE.LOGIN);
    }
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    let appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    try {
      const confirmation = await firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier);
      setTwoFactorConfirmation(confirmation);
      clearFormValidation();
      props.setAuthMode(AUTH_MODE.CONFIRM_OTP);
    } catch (error) {
      setFormValidation(generateLoginErrorMessage(error));
    }
  };
  const resendOTP = async (phoneNumber: string) => {
    if (!phoneNumber || !isPhone(phoneNumber)) {
      props.setAuthMode(AUTH_MODE.LOGIN);
    }
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    let appVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    try {
      const confirmation = await firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier);
      setTwoFactorConfirmation(confirmation);
    } catch (error) {
      setFormValidation(generateLoginErrorMessage(error));
    }
  };
  const handleResendOTP = async () => {
    const phoneNumber = form.emailOrPhone;
    if (!phoneNumber || !isPhone(phoneNumber)) {
      props.setAuthMode(AUTH_MODE.LOGIN);
      return;
    }
    const message = t('auth:twoFactor.resendCodeTo', { phoneNumber });
    props.dispatch(toastActions.createToast(message, true, 10000, true, '#FFCD66', '#000000'));
    await resendOTP(phoneNumber);
  };

  const sendEmailVerification = async (email: string) => {
    const baseURL = 'https://nimbly.page.link/openapp';
    const redirectUrl = `${baseURL}`;
    const actionCodeSettings = {
      url: redirectUrl,
      handleCodeInApp: true,
      iOS: {
        bundleId: 'com.sustainnovationgroup.sharpnsights'
      },
      android: {
        packageName: 'com.sustainnovationgroup.sharpnsights',
        installApp: true
      }
    };
    try {
      await firebase.auth().sendPasswordResetEmail(email, actionCodeSettings);
      await AsyncStorage.setItem('email', email);
      setEmailSentSuccess(true);
      clearFormValidation();
    } catch (err) {
      errorLogger(
        (!props.auth.isEmpty && props.auth.uid) || '',
        (!props.profile.isEmpty && props.profile.organization) || '',
        '',
        '',
        err,
        'Auth.tsx',
        'Try to sendSignInLinkToEmail'
      );
      setFormValidation(generateSignInWithEmailLinkError(err));
    }
  };
  const handleUserExistError = (error: any) => {
    if (error.status === 'NOT_FOUND') {
      setFormValidation((prevState: AuthFormValidation) => ({
        ...prevState,
        emailOrPhone: false,
        errorText: t('auth:error.invalidUser')
      }));
    } else {
      setFormValidation((prevState: AuthFormValidation) => ({
        ...prevState,
        emailOrPhone: false,
        errorText: `Error: ${error.message}`
      }));
    }
  };
  const attemptVerifyAccount = async () => {
    const { isConnected } = props;
    if (!isConnected) {
      setFormValidation((prevState: AuthFormValidation) => ({
        ...prevState,
        errorText: t('auth:error.offline')
      }));
    } else if (isPhone(form.emailOrPhone)) {
      try {
        const userExist = firebase.functions().httpsCallable('user-exist');
        const { data } = await userExist({ phoneNumber: form.emailOrPhone });
        if (isEmpty(data.email)) {
          setFormValidation((prevState: AuthFormValidation) => ({
            ...prevState,
            emailOrPhone: false,
            errorText: t('auth:error.invalidPhone')
          }));
        } else {
          await sendOTP(form.emailOrPhone);
        }
      } catch (error) {
        handleUserExistError(error);
      }
    } else if (isEmail(form.emailOrPhone)) {
      if (isEmpty(form.emailOrPhone)) {
        setFormValidation((prevState: AuthFormValidation) => ({
          ...prevState,
          emailOrPhone: false,
          errorText: t('auth:error.invalidEmail')
        }));
      } else {
        await sendEmailVerification(form.emailOrPhone);
      }
    } else {
      setFormValidation((prevState: AuthFormValidation) => ({
        ...prevState,
        emailOrPhone: false,
        errorText: t('auth:error.requiredEmailOrPhone')
      }));
    }
  };
  const handleValidateCode = async () => {
    setIsBusy(true);
    try {
      if (!isEmpty(_twoFactorConfirmation) && form.code) {
        const credential = firebase.auth.PhoneAuthProvider.credential(_twoFactorConfirmation.verificationId, form.code);
        await firebase.auth().signInWithCredential(credential);
        clearFields();
        clearFormValidation();
        props.setAuthMode(AUTH_MODE.VERIFY_OTP);
      }
    } catch (error) {
      setFormValidation(generateLoginErrorMessage(error));
    }
    setIsBusy(false);
  };

  const handleCancelValidation = () => {
    clearFields();
    setIsBusy(false);
    clearFormValidation();
    setEmailSentSuccess(false);
    setTwoFactorConfirmation(null);
  };

  const handleChangeSectionForgotPassword = () => {
    setFormValidation((prevState: AuthFormValidation) => ({
      ...prevState,
      email: true,
      password: true,
      errorText: ''
    }));
    props.setAuthMode(AUTH_MODE.RESET_PASSWORD);
  };

  const handleSendPasswordResetRequest = async () => {
    if (form.email) {
      setIsBusy(true);
      handleConnectionTimeout(
        props.firebase
          .auth()
          .sendPasswordResetEmail(form.email)
          .then(() => {
            clearFields();
            setIsBusy(false);
            firebase.analytics().logEvent('forgot_password__request_success');
            props.setAuthMode(AUTH_MODE.LOGIN);
            props.createToast(t('auth:success'));
          })
          .catch(error => {
            let errorMessage = error.message;
            if (error.code && error.code === 'auth/user-not-found') {
              errorMessage = t('auth:validation.noUserFound');
            }
            errorLogger(form.email || '', '', '', '', error, 'Auth.tsx', 'handleSendPasswordResetRequest').catch(() => {
              // error handler
            });
            setIsBusy(false);
            firebase.analytics().logEvent('forgot_password__request_failed');
            setFormValidation((prevState: AuthFormValidation) => ({
              ...prevState,
              errorText: errorMessage
            }));
          })
      ).catch(error => {
        firebase.analytics().logEvent('forgot_password__no_internet');
        setFormValidation((prevState: AuthFormValidation) => ({
          ...prevState,
          errorText: t('auth:error.internetConnection')
        }));
      });
    }
  };

  const handleChangeSectionSignup = () => {
    clearFormValidation();
    setEmailSentSuccess(false);
    setTwoFactorConfirmation(null);
    setFreezeBanner(false);
    props.setAuthMode(AUTH_MODE.SIGN_UP);
  };

  const handleChangeSectionLogin = () => {
    clearFormValidation();
    setEmailSentSuccess(false);
    setTwoFactorConfirmation(null);
    setFreezeBanner(false);
    props.setAuthMode(AUTH_MODE.LOGIN);
  };

  const handleWhatsAppSupport = () =>
    Linking.openURL('https://wa.me/6282123069155').catch(() => {
      props.createToast(t('auth:error.whatsapp'));
    });

  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const onSubmitRequestFederated = async () => {
    const reCheckIsFederatedUser = await checkIsUserFederated(form.emailOrPhone);

    if (!reCheckIsFederatedUser.isFederated) {
      setIsFederatedAuth(false);
      setFormValidation((prevState: AuthFormValidation) => ({
        ...prevState,
        isNeedAccessFederated: false
      }));
      return;
    }

    setIsBusy(true);

    try {
      await requestEnrollment(tempAuth.tempToken, tempAuth.tenant);
      props.setShowModalRequestAccess(true);
      clearFormValidation();
      clearFields();
      setIsBusy(false);
    } catch (e) {
      console.error(e);
      setIsBusy(false);
    }
  };

  return (
    <AuthContainer
      hasPendingCreds={!!pendingCreds}
      hasNotch={props.hasNotch}
      language={props.language}
      authMode={props.authMode}
      freezeBanner={freezeBanner}
      onSubmitRequestFederated={onSubmitRequestFederated}
      isFederatedAuth={isFederatedAuth}
      form={form}
      formValidation={formValidation}
      isPasswordVisible={isPasswordVisible}
      _isBusy={_isBusy}
      _capsWarning={_capsWarning}
      _emailSentSuccess={_emailSentSuccess}
      _submitOnEnter={_submitOnEnter}
      _twoFactorConfirmation={_twoFactorConfirmation}
      onFocusMovedDown={props.onScrollDown}
      onCodeChange={handleCodeChange}
      onCancelValidation={handleCancelValidation}
      onSubmitValidation={handleValidateCode}
      onChangeSectionSignup={handleChangeSectionSignup}
      onChangeSectionLogin={handleChangeSectionLogin}
      onChangeSectionForgotPassword={handleChangeSectionForgotPassword}
      onPhoneNumberChange={handlePhoneNumberChange}
      onEmailChange={handleEmailChange}
      onPasswordChange={handlePasswordChange}
      onEmailOrPhoneChange={handleEmailOrPhoneChange}
      onEmailTextInputFocused={handleEmailTextInputFocused}
      onPasswordTextInputFocused={handlePasswordTextInputFocused}
      onSubmitSignup={handleAttemptEmailSignup}
      onSubmitLogin={handleLogin}
      onSubmitPasswordReset={handleSendPasswordResetRequest}
      onContactWhatsAppSupport={handleWhatsAppSupport}
      visibleTutorialModal={visibleTutorialModal}
      handleVisibleTutorialModal={setVisibleTutorialModal}
      onTogglePasswordVisibility={handleTogglePasswordVisibility}
      onPressResendOTP={handleResendOTP}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  isConnected: state.network.isConnected,
  authMode: state.landing.authMode,
  authError: state.firebase.authError,
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  language: state.landing.language,
  hasNotch: state.account.hasNotch
});

const mapDispatchToProps = (dispatch: any) => ({
  setAuthMode: (authMode: AUTH_MODE) => dispatch(landingActions.setAuthMode(authMode)),
  createToast: (message: string) => dispatch(toastActions.createToast(message, true, 10000)),
  logActivity: (data: Activity) => dispatch(logActivityAsync.request(data)),
  setIsFederatedMode: (isFederated: boolean) => dispatch(landingActions.setIsFederated(isFederated))
});

export default compose(firebaseConnect(), connect(mapStateToProps, mapDispatchToProps))(Auth) as any;
