export type BaseMeasure =
  | 'kg'
  | 'gr'
  | 'ltr'
  | 'ml'
  | 'can'
  | 'btl'
  | 'scht'
  | 'pck'
  | 'pcs'
  | 'drj'
  | 'pail'
  | 'jar'
  | 'pch';
export interface UnitOfMeasureProfile {
  name: string;
  abbr: BaseMeasure;
  category: string;
  float: boolean;
}

const uom: { [key: string]: UnitOfMeasureProfile } = {
  kg: {
    name: 'kilogram',
    abbr: 'kg',
    category: 'mass',
    float: true
  },
  gr: {
    name: 'gram',
    abbr: 'gr',
    category: 'mass',
    float: true
  },
  ltr: {
    name: 'liter',
    abbr: 'ltr',
    category: 'volume',
    float: true
  },
  ml: {
    name: 'milliliter',
    abbr: 'ml',
    category: 'volume',
    float: true
  },
  can: {
    name: 'canister',
    abbr: 'can',
    category: '',
    float: true
  },
  btl: {
    name: 'bottle',
    abbr: 'btl',
    category: '',
    float: true
  },
  scht: {
    name: 'sachet',
    abbr: 'scht',
    category: '',
    float: true
  },
  pck: {
    name: 'pack',
    abbr: 'pck',
    category: '',
    float: true
  },
  pcs: {
    name: 'pieces',
    abbr: 'pcs',
    category: 'item',
    float: true
  },
  drj: {
    name: 'dirigen',
    abbr: 'drj',
    category: '',
    float: true
  },
  pail: {
    name: 'pail',
    abbr: 'pail',
    category: '',
    float: true
  },
  jar: {
    name: 'jar',
    abbr: 'jar',
    category: '',
    float: true
  },
  pch: {
    name: 'pouch',
    abbr: 'pch',
    category: '',
    float: true
  }
};

export default uom;
