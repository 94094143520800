import { DeviceEventEmitter, Platform } from 'react-native';
import _BackgroundTimer from 'react-native-background-timer';
import Geolocation from 'react-native-geolocation-service';
import firebase from 'firebase';

import { MONGO_API } from '../constants/api';

// const EventEmitter = Platform.select({
//   ios: () => NativeAppEventEmitter,
//   android: () => DeviceEventEmitter
// })!();

class BackgroundTimer {
  static backgroundTimer: any = null;
  static backgroundListener: any = null;
  static isStarted = false;
  static journeyReportID = '';
  static api = MONGO_API;

  /**
   * get user coordinate every 30 second
   * and update to database
   */
  static startJourneyTracking(journeyReportID: string) {
    this.journeyReportID = journeyReportID;
    if (!this.isStarted) {
      _BackgroundTimer.start();
      this.isStarted = true;

      const callback: any = () => {
        Geolocation.getCurrentPosition(async info => {
          try {
            const reqBody = {
              journeyReportID: this.journeyReportID,
              latitude: String(info.coords.latitude),
              longitude: String(info.coords.longitude)
            };
            const token = (await firebase.auth().currentUser!.getIdToken()) as any;
            const response = await fetch(`${this.api}/journey/trackingtime`, {
              headers: {
                authorization: token
              },
              method: 'POST',
              body: JSON.stringify(reqBody)
            });
            const body = await response.json();
            if (body.message !== 'Success') {
              throw new Error(`failed to track location ${body}`);
            }
          } catch (err) {}
        });
      };
      // this.backgroundListener = EventEmitter.addListener('backgroundTimer', () => {
      //   // send coordinates every 30 seconds
      //   this.backgroundTimer = _BackgroundTimer.setInterval(callback, 30000);
      // });
    }
  }

  static stopJourneyTracking() {
    if (this.backgroundListener) {
      this.backgroundListener.remove();
    }
    if (this.backgroundTimer) {
      _BackgroundTimer.clearInterval(this.backgroundTimer);
    }
    _BackgroundTimer.stop();
    this.isStarted = false;
  }

  static getIsStarted() {
    return this.isStarted;
  }
}

export default BackgroundTimer;
