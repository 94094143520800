import React from 'react';
import { RouteProp } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack/src/types';

import { StyleSheet, View } from 'react-native';
import Payoff from '../components/payoff/Payoff';
import { HomeStackParamList, ScheduleStackParamList } from '../routes';

export type PayoffPageProps = {
  navigation: StackNavigationProp<HomeStackParamList | ScheduleStackParamList, 'Payoff'>;
  route: RouteProp<HomeStackParamList | ScheduleStackParamList, 'Payoff'>;
};

const PayoffPage = (props: PayoffPageProps) => (
  <View style={styles.root}>
    <Payoff navigation={props.navigation} route={props.route} />
  </View>
);

export default PayoffPage;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: '#fff'
  }
});
