import React, { useEffect, useState } from 'react';
import { FlatList, StyleSheet, View, RefreshControl, ActivityIndicator, Dimensions, Platform } from 'react-native';

// types
import * as Common from 'nimbly-common';
import { SelectedSite } from '../Outlet/type';

// components
import ReportsFilter from './ReportsFilter';
import ReportsItem from './ReportsItem';
import { heightPercentageToDP } from '../../../../utils/screensize';
import Text from '../../../global/Text';
import fontMaker from '../../../../utils/font';

// utils
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';

// Constants
import theme from '../../../../constants/theme';

type ReportsContainerProps = {
  siteReports: (Common.SiteReportSummary & { questionnaire: Common.Questionnaire })[];
  isRefreshLoading: boolean;
  refreshReports: (clearList: boolean) => void;
  selectedSiteKey: string | null;
  totalReports: number;
  pageIndex: number;
  profileColor: string;
  customStart: Moment | null;
  customEnd: Moment | null;
  dateType: string;
  organization: Common.Organization | null | undefined;
  selectedSite: SelectedSite;
};

const ReportsContainer = (props: ReportsContainerProps) => {
  const { t } = useTranslation('site');
  const {
    siteReports,
    isRefreshLoading,
    refreshReports,
    totalReports,
    pageIndex,
    profileColor,
    customStart,
    customEnd,
    dateType,
    organization,
    selectedSite
  } = props;

  const [loadMore, setLoadMore] = useState<boolean>(false);

  useEffect(() => {
    if (loadMore && !isRefreshLoading) {
      setLoadMore(false);
    }
  }, [isRefreshLoading, loadMore]);

  const handleRefreshReport = () => {
    refreshReports(true);
  };

  const renderTitle = () => {
    const periodLabel =
      organization && organization.schedule
        ? organization.schedule.periodUnit.charAt(0) + organization.schedule.periodUnit.slice(1)
        : 'period';
    const titleOption: any = {
      today: t('siteReports.time.today'),
      'last-7': t('siteReports.time.last-7'),
      'last-30': t('siteReports.time.last-30'),
      current: t('siteReports.time.thisPeriod', { period: t(`siteReports.time.${periodLabel}`) }),
      previous: t('siteReports.time.lastPeriod', { period: t(`siteReports.time.${periodLabel}`) }),
      'last-90': t('siteReports.time.last-90'),
      'last-6-m': t('siteReports.time.last-6-m'),
      'last-12-m': t('siteReports.time.last-12-m'),
      mtd: t('siteReports.time.mtd'),
      'last-year': t('siteReports.time.last-year')
    };
    if (dateType === 'custom' && customEnd && customStart) {
      if (customStart.isSame(customEnd, 'date')) return customStart.format('DD MMM YYYY');
      return `${customStart.format('DD MMM YYYY')} - ${customEnd.format('DD MMM YYYY')} `;
    }
    if (dateType === 'today') {
      return `${t('siteReports.time.today')} - ${moment().format('DD MMM YYYY')}`;
    }
    return titleOption[dateType];
  };

  const _renderFooterMessage = () => {
    return (
      <React.Fragment>
        {isRefreshLoading && (
          <View style={styles.activityContainer}>
            <ActivityIndicator color={profileColor} size="large" />
          </View>
        )}
        <View style={styles.flatListFooterContainer}>
          <Text style={styles.flatListFooterText}>{t('siteReports.footer.message')}</Text>
        </View>
      </React.Fragment>
    );
  };

  const renderReportsItem = ({
    item,
    index
  }: {
    item: Common.SiteReportSummary & { questionnaire: Common.Questionnaire };
    index: number;
  }) => {
    return <ReportsItem item={item} selectedSite={selectedSite} />;
  };

  const handleLoadMore = () => {
    setLoadMore(true);
    if (isRefreshLoading) {
      return;
    }

    if (pageIndex < totalReports) {
      refreshReports(false);
    }
  };

  const renderEmptyList = (
    <View style={styles.emptyBoardContainer}>
      <Text style={styles.emptyTitle} testID="sitereport--is-empty-title">
        {t('siteReports.empty_board_title')}
      </Text>
      <Text style={styles.emptyText} testID="sitereport--is-empty-subtitle">
        {t('siteReports.empty_board_subtitle')}
      </Text>
    </View>
  );

  const optionKeyExtractor = (item: any, index: number) => `siteReport-${item.key}-${index}`;
  return (
    <View style={{ flex: 1 }} testID="siteReport-container">
      <ReportsFilter selectedSite={selectedSite} />
      <View style={styles.title}>
        <Text style={styles.textTitle}>{renderTitle()}</Text>
      </View>
      {isRefreshLoading && !loadMore ? (
        <View style={styles.activityContainer}>
          <ActivityIndicator color={profileColor} size="large" />
        </View>
      ) : (
        <FlatList
          onEndReached={handleLoadMore}
          data={siteReports || []}
          contentContainerStyle={[styles.flatListContainer, siteReports?.length === 0 ? { flex: 1 } : {}]}
          renderItem={renderReportsItem}
          keyExtractor={optionKeyExtractor}
          ListEmptyComponent={
            isRefreshLoading && Platform.OS === 'ios' ? (
              <ActivityIndicator color={profileColor} size="large" testID="siteReport--loadingContainer" />
            ) : isRefreshLoading ? null : (
              renderEmptyList
            )
          }
          refreshControl={
            <RefreshControl onRefresh={handleRefreshReport} tintColor="transparent" refreshing={isRefreshLoading} />
          }
          ListFooterComponent={siteReports?.length > 0 ? _renderFooterMessage : null}
        />
      )}
    </View>
  );
};

export default ReportsContainer;

const styles = StyleSheet.create({
  flatListContainer: {
    paddingLeft: 18,
    paddingBottom: 50,
    flexGrow: 1
  },
  emptyBoardContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  emptyText: {
    fontSize: 12,
    lineHeight: 21,
    textAlign: 'center',
    color: '#7C7C7C',
    width: 240
  },
  emptyTitle: {
    fontSize: 20,
    lineHeight: 21,
    textAlign: 'center',
    color: '#25282B',
    ...fontMaker({ weight: 'Bold' })
  },
  title: {
    marginTop: 10,
    padding: 10
  },
  textTitle: {
    ...fontMaker({ weight: 'Bold' })
  },
  activityContainer: {
    display: 'flex',
    alignContent: 'center',
    marginVertical: 24
  },
  flatListFooterContainer: {
    paddingTop: 18,
    paddingBottom: 6,
    alignItems: 'center'
  },
  flatListFooterText: {
    fontSize: 12,
    color: theme.colors.gray
  },
  footer: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 64,
    height: Dimensions.get('window').height * 0.1,
    paddingVertical: 10,
    backgroundColor: theme.colors.darkgray,
    // shadow properties
    shadowColor: '#919191',
    shadowRadius: 6,
    shadowOpacity: 0.7,
    shadowOffset: { width: 1, height: 1 },
    elevation: 3
  }
});
