import firebase from 'firebase';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { firebaseConnect } from 'react-redux-firebase';
import { compose } from 'redux';

import { Vibration } from 'react-native';
import { RootState } from '../../store/reducers';
import { payoffActions } from '../../store/reducers/payoff';
import { reportActions } from '../../store/reducers/report';
import * as reportcacheThunks from '../../store/reducers/reportcache/reportcache.actionThunk';
import { reportsubmitActions } from '../../store/reducers/reportsubmit';
import * as siteScheduleActions from '../../store/reducers/siteSchedule/siteSchedule.action';
import { tutorialThunks } from '../../store/reducers/tutorial';
import { FlagCount, Report, ReportSection } from '../../utils/classes';
import { calculateTimeDuration } from '../../utils/date';
import { errorLogger } from '../../utils/errorLogger';
import { currentPeriodEndDate, currentPeriodStartDate } from '../../utils/schedule';
import getUserSingleSiteSchedule, { getUserSingleSiteScheduleProps } from '../site/utils/getUserSingleSiteSchedule';

import { PayoffPageProps } from '../../routes/payoff';
import { UserSingleSiteScheduleInfo } from '../../store/reducers/siteSchedule/siteSchedule.reducer';
import getReportScore from '../submitreport/utils/getReportScore';
import PayOffContainer from './PayoffContainer';

/**
 * Payoff Screen shows up after the report has been succesfully uploaded to the server.
 *
 * It will show the recap of the report such as number of each flags, the score, date of visit, time visit
 * and the time duration of each report
 */
export class Payoff extends React.Component<PayoffProps, PayoffState> {
  state: PayoffState = {
    /** Number of flag for each color (red, yellow, green) */
    flagCount: null,
    /** Score result for (in integer) */
    scoreRaw: null,
    /** Score result for (in integer) */
    scoreTotal: null,
    /** Score result for (in percentage) */
    scoreWeighted: null,
    /** Date of auditor visit */
    dateVisit: null,
    /** Time of auditor visit */
    timeVisit: null,
    /** How long is the audit going on */
    timeDuration: null
  };

  // Count total number of flag / score based on the completed report
  constructor(props: PayoffProps) {
    super(props);
    const { uploadingReport, preGeneratedReport } = props;

    // preGeneratedReport comes from report payload from notification
    const selectedReport = preGeneratedReport ? preGeneratedReport : uploadingReport;

    const dateVisit = selectedReport
      ? moment.parseZone(selectedReport.datetimeIn).format('DD MMMM YYYY')
      : moment().format('DD MMMM YYYY');
    const timeVisit = selectedReport
      ? moment.parseZone(selectedReport.datetimeIn).format('HH:mm A')
      : moment().format('HH:mm A');
    const timeDuration = selectedReport
      ? calculateTimeDuration(selectedReport.datetimeIn, selectedReport.datetimeOut)
      : '00:00:00';

    const { flagCount, scoreWeighted, scoreRaw, scoreTotal } = getReportScore(selectedReport as Report);

    this.state = {
      flagCount,
      scoreRaw,
      scoreTotal,
      scoreWeighted,
      dateVisit,
      timeVisit,
      timeDuration
    };
  }

  // Vibrate for celebration
  componentDidMount() {
    const {
      uploadingReportKey,
      uploadingSiteKey,
      uploadingReport,
      selectedSite,
      preGeneratedReport,
      isUploading,
      auth,
      profile,
      organization
    } = this.props;
    // On Payoff screen accessed from notification message
    // ensures action does not get called when an upload is in progress
    if (!isUploading && !preGeneratedReport && uploadingReport) {
      this.props.selectReport(null, null);
      this.props.resetReportToUpload();

      if (selectedSite && selectedSite.isMultiSite) {
        this.props.removePendingMulti(uploadingSiteKey!, uploadingReportKey!);
      } else {
        this.props.removePendingSingle(uploadingSiteKey!, uploadingReport.scheduleKey!, uploadingReportKey!);
      }
      this.props.removeReport(uploadingSiteKey!, uploadingReportKey!, true);
    }

    if (preGeneratedReport) {
      this.props.resetPreGeneratedReport();
    }

    Vibration.vibrate(500, false);

    if (this.props.isTutorialMode || preGeneratedReport) {
      // do nothing
    } else {
      firebase.analytics().logEvent('report_complete');
      /** Fetch site schedule */
      // const serverTime = firebase.database().getServerTime();
      const start = currentPeriodStartDate(organization!.schedule) || moment();
      const end = currentPeriodEndDate(organization!.schedule, start)!;

      const startDate = start.format('YYYY-MM-DD');
      const endDate = end.format('YYYY-MM-DD');

      const params: getUserSingleSiteScheduleProps = {
        auth,
        site: selectedSite!,
        siteKey: uploadingSiteKey!,
        organizationKey: profile.organization,
        startDate,
        endDate
      };
      getUserSingleSiteSchedule(params)
        .then(({ data }) => this.props.fetchSingleSiteSchedule(uploadingSiteKey!, data))
        .catch(err =>
          errorLogger(
            auth.uid,
            profile.organization,
            uploadingSiteKey!,
            uploadingReportKey!,
            err,
            'Payoff.tsx/componentDidMount/getUserSingleSiteSchedule'
          )
        );
    }
  }

  handleNavHome = () => {
    const { isTutorialMode, navigation, route } = this.props;
    if (isTutorialMode) {
      this.props.dismissTutorialMode();
    }

    if (this.props.journey) {
      return navigation.navigate('Journey', {
        profileColor: this.props.profileColor,
        originTab: 'HomeTab',
        isDueToday: true
      });
    }

    return navigation.reset({
      index: 0,
      routes: [{ name: route.params.originTab || 'HomeTab' }]
    });
  };

  render() {
    return (
      <PayOffContainer
        language={this.props.language}
        selectedSite={this.props.selectedSite!}
        journey={this.props.journey}
        flagCount={this.state.flagCount}
        scoreRaw={this.state.scoreRaw}
        scoreTotal={this.state.scoreTotal}
        scoreWeighted={this.state.scoreWeighted}
        dateVisit={this.state.dateVisit}
        timeVisit={this.state.timeVisit}
        timeDuration={this.state.timeDuration}
        handleNavHome={this.handleNavHome}
      />
    );
  }
}

type PayoffState = {
  flagCount: null | FlagCount;
  scoreRaw: null | number;
  scoreTotal: null | number;
  scoreWeighted: null | number;
  dateVisit: null | string;
  timeVisit: null | string;
  timeDuration: null | string;
};

interface WithStateProps extends ReturnType<typeof mapStateToProps> {}
interface WithDispatchProps extends ReturnType<typeof mapDispatchToProps> {}

interface PayoffProps extends PayoffPageProps, WithStateProps, WithDispatchProps {
  children: React.ReactNode;
}

const mapStateToProps = (state: RootState) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
  uploadingSiteKey: state.reportsubmit.uploadingSiteKey,
  uploadingReportKey: state.reportsubmit.uploadingReportKey,
  selectedSite: state.site.selectedSite,
  selectedSiteKey: state.site.selectedSiteKey,
  selectedSiteSchedule: state.site.selectedSiteSchedule,
  reports: state.reportcache.reports,
  preGeneratedReport: state.payoff.preGeneratedReport,
  isUploading: state.uploadreport.isUploading,
  uploadingReport: state.reportsubmit.uploadingReport,
  uploadingReportSummary: state.reportsubmit.uploadingReportSummary,
  language: state.firebase.profile.language || 'en',
  organization: state.organization.myOrganization,
  isTutorialMode: state.tutorial.isTutorialMode,
  journey: state.journey.journey,
  profileColor: state.profiletheme.color
});

export const mapDispatchToProps = (dispatch: any) => ({
  resetPreGeneratedReport: () => dispatch(payoffActions.resetPreGeneratedReport()),
  /**
   * Select a report, which saves the key of that report
   * @param key - The selected report key
   * @param value - The value of Report Object
   */
  selectReport: (key: string | null, value: Report | ReportSection | null) =>
    dispatch(reportActions.selectReport(key, value)),
  /**
   * Reset the redux state used for upload
   */
  resetReportToUpload: () => dispatch(reportsubmitActions.resetReportToUpload()),
  /**
   * Remove the Pending Multi Site
   * @param siteKey - The Site key
   * @param reportKey - The Selected Report Key
   */
  removePendingMulti: (siteKey: string, reportKey: string) =>
    dispatch(reportsubmitActions.removePendingMulti(siteKey, reportKey)),
  /**
   * Remove the Pending Single Site
   * @param siteKey - The Site Key
   * @param questionnaireKey - The Questionnaire Key
   * @param reportKey - The Selected Report Key
   */
  removePendingSingle: (siteKey: string, questionnaireKey: string, reportKey: string) =>
    dispatch(reportsubmitActions.removePendingSingle(siteKey, questionnaireKey!, reportKey)),
  /**
   * Remove the report from redux state including the cache and summary
   * @param siteKey - The Site Key
   * @param reportKey - The Report Key
   * @param backup - True to save a backup report
   */
  removeReport: (siteKey: string, reportKey: string, backup: boolean) =>
    dispatch(reportcacheThunks.removeReport(siteKey, reportKey, backup)),

  /**
   * dismiss tutorial mode and update database that inform the user already do tutorial
   */
  dismissTutorialMode: () => dispatch(tutorialThunks.dismissTutorialMode()),
  /**
   * Update Site Schedule upon report completion, prevent inacurate schedule
   */
  fetchSingleSiteSchedule: (siteKey: string, data: UserSingleSiteScheduleInfo[]) =>
    dispatch(siteScheduleActions.setSingleSiteScheduleData(siteKey, data))
});

const enhance = compose(firebaseConnect(), connect(mapStateToProps, mapDispatchToProps));

export default enhance(Payoff) as any;
