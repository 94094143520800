import firebase from 'firebase';
import isEmpty from 'lodash/isEmpty';
import { call, put } from 'redux-saga/effects';
import { ActionType, createAction, createAsyncAction, createReducer } from 'typesafe-actions';
import { apiProdURL } from '../../../constants/api';
import { UserRole, UserRoleResource, UserRoleResourceMap, UserRoleResponse } from '../../../utils/classes';

export const SET_DUMMY_STATE = '@account/SET_DUMMY_STATE';
export const SET_DEVICE_PROFILE = '@account/SET_DEVICE_PROFILE';
export const SET_DEVICE_UUID = '@account/SET_DEVICE_UUID';
export const SET_DEVICE_HAS_NOTCH = '@account/SET_DEVICE_HAS_NOTCH';
export const SET_DEVICE_API_LEVEL = '@account/SET_DEVICE_API_LEVEL';
export const SET_PROFILE_PROCESSED = '@account/SET_PROFILE_PROCESSED';
export const RESET_PROFILE = '@account/RESET_PROFILE';

export const FETCH_ROLE_REQUEST = '@account/FETCH_ROLE_REQUEST';
export const FETCH_ROLE_SUCCESS = '@account/FETCH_ROLE_SUCCESS';
export const FETCH_ROLE_FAILURE = '@account/FETCH_ROLE_FAILURE';
export const SET_LOGOUT_STATUS = '@account/SET_LOGOUT';

const dummyUid: { [uid: string]: boolean } = {
  // Nimbly Retail
  V3mY477fvWPSapCTpfUSlCJ8s4S2: true,
  // Nimbly Agro
  vSAgd6pSfBhgYM3IavBuV5MXxuE3: true,
  // Nimbly FMCG
  sokkTCW00zPvJgvRYhb2flxljQj1: true,
  // Nimbly FNB
  tnMxl7yHlpMFQolj23ZUfO54M803: true,
  // Nimbly Admin
  '40aWkEHLVTPmVwMNdfconatt8RH3': true
};

/**
 * Set the dummy state for the app for demo accounts
 * @param uid - string uid of demo account
 */
const setDummyState = createAction(SET_DUMMY_STATE, (uid: string) => ({
  isDummy: !!dummyUid[uid]
}))();

/**
 * Sets the device profile
 * @param deviceProfile - device profile from getDeviceProfile helper
 */
const setDeviceProfile = createAction(SET_DEVICE_PROFILE, (deviceProfile: DeviceProfile) => ({
  deviceProfile
}))();

/**
 * Sets the device uuid
 * @param uuid - unique identifier generated by the device
 */
const setDeviceUniqueId = createAction(SET_DEVICE_UUID, (uuid: string) => ({
  uuid
}))();

/**
 * Sets a boolean true if the device has notches on the top
 * @param hasNotch - boolean true if the device has notches on the top
 */
const setDeviceHasNotch = createAction(SET_DEVICE_HAS_NOTCH, (hasNotch: boolean) => ({
  hasNotch
}))();

/**
 * Sets the device API level for Android devices, for iOS sets -1
 * @param apiLevel - number level of Android version
 */
const setDeviceApiLevel = createAction(SET_DEVICE_API_LEVEL, (apiLevel: number) => ({
  apiLevel
}))();
const isForceLogoutStatus = createAction(SET_LOGOUT_STATUS, (isForceLogout: boolean) => ({ isForceLogout }))();

/**
 * Pure action to signal that the user profile has been processed
 */
const setProfileProcessed = createAction(SET_PROFILE_PROCESSED)();

const resetProfileState = createAction(RESET_PROFILE)();
/**
 * Saga to fetch the user role asynchronously
 */
export const fetchRoleAsync = createAsyncAction(FETCH_ROLE_REQUEST, FETCH_ROLE_SUCCESS, FETCH_ROLE_FAILURE)<
  number | undefined,
  UserRoleResponse,
  Error
>();

export function* fetchRoleSaga(action: ReturnType<typeof fetchRoleAsync.request>): Generator {
  const tryCount = action.payload || 1;
  try {
    const idToken = (yield firebase.auth().currentUser!.getIdToken()) as string;
    const request = () =>
      fetch(`${apiProdURL}/user-roles/role-for-user`, {
        method: 'GET',
        headers: {
          authorization: idToken
        }
      });
    const response = (yield call(request)) as Body;
    const body = (yield response.json()) as any;
    yield put(fetchRoleAsync.success(body.data));
  } catch (err) {
    // Retry up to 3 times before throwing a failure
    if (tryCount >= 3) {
      yield put(fetchRoleAsync.request(tryCount + 1));
    } else {
      yield put(fetchRoleAsync.failure(err));
    }
  }
}

export const accountActions = {
  setDummyState,
  setDeviceProfile,
  setDeviceUniqueId,
  setDeviceHasNotch,
  setDeviceApiLevel,
  setProfileProcessed,
  resetProfileState,
  fetchRoleAsync,
  isForceLogoutStatus
};

export type DeviceProfile = {
  binaryVersion: string;
  carrier: string;
  locale: string;
  phoneBrand: string;
  phoneModel: string;
  phoneOS: string;
  phoneOSVersion: string;
};

export type AccountAction = ActionType<typeof accountActions>;
export type AccountState = {
  readonly isLoadingRole: boolean;
  readonly lastUpdatedRole: number | null;
  readonly errorMessageRole: string | null;
  readonly role: UserRole | null | undefined;
  readonly isDummy: boolean;
  readonly deviceProfile: DeviceProfile | null;
  readonly uuid: string;
  readonly hasNotch: boolean;
  readonly apiLevel: number;
  readonly profileProcessed: boolean;
};

const initialState: AccountState = {
  isLoadingRole: false,
  lastUpdatedRole: null,
  errorMessageRole: null,
  role: undefined,
  isDummy: false,
  deviceProfile: null,
  uuid: '',
  hasNotch: false,
  apiLevel: -1,
  profileProcessed: false
};

export const accountReducer = createReducer<AccountState, AccountAction>(initialState)
  .handleAction(setDummyState, (state, incomingAction) => ({
    ...state,
    isDummy: incomingAction.payload.isDummy
  }))
  .handleAction(setDeviceProfile, (state, incomingAction) => ({
    ...state,
    deviceProfile: incomingAction.payload.deviceProfile
  }))
  .handleAction(setDeviceUniqueId, (state, incomingAction) => ({
    ...state,
    uuid: incomingAction.payload.uuid
  }))
  .handleAction(setDeviceHasNotch, (state, incomingAction) => ({
    ...state,
    hasNotch: incomingAction.payload.hasNotch
  }))
  .handleAction(setDeviceApiLevel, (state, incomingAction) => ({
    ...state,
    apiLevel: incomingAction.payload.apiLevel
  }))
  .handleAction(setProfileProcessed, state => ({
    ...state,
    profileProcessed: true
  }))
  .handleAction(resetProfileState, state => ({
    ...state,
    profileProcessed: false
  }))
  .handleAction(fetchRoleAsync.request, state => ({
    ...state,
    isLoadingRole: true,
    errorMessageRole: null
  }))
  .handleAction(fetchRoleAsync.failure, (state, incomingAction) => ({
    ...state,
    isLoadingRole: false,
    errorMessageRole: incomingAction.payload.message
  }))
  .handleAction(isForceLogoutStatus, (state, incomingAction) => ({
    ...state,
    isForceLogout: incomingAction.payload.isForceLogout
  }))
  .handleAction(fetchRoleAsync.success, (state, incomingAction) => ({
    ...state,
    isLoadingRole: false,
    lastUpdatedRole: Date.now(),
    role: isEmpty(incomingAction.payload)
      ? {
          ...incomingAction.payload,
          resourceMap: {}
        }
      : {
          ...incomingAction.payload,
          resourceMap: incomingAction.payload.resources.reduce((map: UserRoleResourceMap, obj: UserRoleResource) => {
            map[obj.resource] = {};
            obj.permission.forEach(permission => {
              map[obj.resource][permission] = true;
            });
            return map;
          }, {})
        }
  }));
