import React from 'react';
import { ScrollView } from 'react-native';

// components
import SiteCalendarHeader from './SiteCalendarHeader';
import SiteCalendarDetails from './SiteCalendarDetails';

// types
import { SiteCalendarContainerProps } from './type';

const SiteCalendarContainer = (props: SiteCalendarContainerProps) => {
  const {
    scheduleSummaries,
    selectedDate,
    setSelectedDate,
    startDate,
    endDate,
    handleSelectSchedule,
    organization,
    isConnected,
    siteReports
  } = props;

  return (
    <ScrollView style={{ flex: 1 }} scrollEventThrottle={1} nestedScrollEnabled={true}>
      <SiteCalendarHeader
        selectedDate={selectedDate}
        startDate={startDate}
        endDate={endDate}
        setSelectedDate={setSelectedDate}
        scheduleSummaries={scheduleSummaries}
        periodUnit={organization?.schedule.periodUnit || 'week'}
      />
      <SiteCalendarDetails
        date={selectedDate}
        schedules={scheduleSummaries[selectedDate]}
        handleSelectSchedule={handleSelectSchedule}
        isConnected={isConnected}
        siteReports={siteReports}
      />
    </ScrollView>
  );
};

export default SiteCalendarContainer;
