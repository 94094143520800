import firebase from 'firebase';

import { NIMBLY_API_CREATE_ACTIVITY } from '../../../constants/api';
import * as actions from './activity.action';
import { put } from 'redux-saga/effects';

export function* logActivityActionSaga(action: ReturnType<typeof actions.logActivityAsync.request>): Generator {
  const activityData = action.payload;
  const idToken = (yield firebase.auth().currentUser!.getIdToken()) as string;

  try {
    yield fetch(`${NIMBLY_API_CREATE_ACTIVITY}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: idToken
      },
      body: JSON.stringify(activityData)
    });
    put(actions.logActivityAsync.success());
  } catch (error) {
    put(actions.logActivityAsync.failure(error));
  }
}
