/**
 * Get the distance in km for approved check-in based on precision type
 * @param geoPrecision - precision type based on the organization settings
 */
export default function getDistanceTreshold(geoPrecision: 'low' | 'medium' | 'high' | 'none') {
  let threshold = 1;
  switch (geoPrecision) {
    case 'none':
      threshold = 999999999;
      break;
    case 'high':
      threshold = 1 * 2;
      break;
    case 'medium':
      threshold = 2.5 * 2;
      break;
    case 'low':
      threshold = 10 * 2;
      break;
    default:
      threshold = 1;
      break;
  }
  return threshold;
}
